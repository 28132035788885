import React from 'react';
import * as vitafyUtils from '../../util/vitafyUtil';

const Services = (props) => {
  const { services } = props;
  return (
    <>
      <h4 className='text-lg mb-5'>Services</h4>
      {services && services.map((service, index) =>
        <div className='bg-bgColor py-2 px-3 inline-block mr-3 mb-3' key={index}>
          <p className='body-text text-sm text-titleColor'>{vitafyUtils.capitalizeFirstLetter(service)}</p>
        </div>
      )}
      {services && services.length < 1 && 
        <div className="body-text">No information available</div> 
      }
    </>
  );
}

export default Services;