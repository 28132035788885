/**
 * @author Slesha Tuladhar <slesha@noveltytechnology.com>
 */

import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';

class SearchAutocomplete extends Component {
  onSuggestionSelected = (event, { suggestion }) => {
    this.props.autosuggestInputs.onSuggestionSelected(event, { suggestion });
  };
  render() {
    return (
      <Autosuggest
        suggestions={this.props.autosuggestInputs.suggestions}
        onSuggestionsFetchRequested={
          this.props.autosuggestInputs.onSuggestionsFetchRequested
        }
        onSuggestionsClearRequested={
          this.props.autosuggestInputs.onSuggestionsClearRequested
        }
        onSuggestionSelected={this.onSuggestionSelected}
        getSuggestionValue={
          this.props.autosuggestInputs.getSuggestionDisplayName
        }
        renderSuggestion={this.props.autosuggestInputs.renderSuggestion}
        shouldRenderSuggestions={
          this.props.autosuggestInputs.shouldRenderSuggestions
        }
        inputProps={this.props.autosuggestInputs.inputProps}
        iconType={this.props.iconType}
        renderInputComponent={this.props.autosuggestInputs.renderInputComponent}
      // renderInputComponent={
      //   this.props.iconType === 'dpc'
      //     ? renderDpcInputComponent
      //     : renderLocationInputComponent
      // }
      />
    );
  }
}

export default SearchAutocomplete;
