import React, { Component } from "react";
import { ReactTitle } from "react-meta-tags";

import { Header, Footer, Value, ClientStory, CallToAction } from "../../common";
import Slider from "./landingSlider";
import WhoWeHelp from "./WhoWeHelp";
import Cta from "./Cta";
import LatestNews from "./LatestNews";
import * as service from "../../service";
import { Loader, RequestDemo } from "../../common";
import commonConstants from "../../constants/commonConstant";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      whoWeHelp: {},
      newWhoWeHelp: {},
      keyValues: {},
      partners: [],
      latestNews: [],
      clientStories: [],
      slider: [],
      seo: {},
    };
    this.newRequestDemoModal = React.createRef();
  }

  componentDidMount() {
    this.getContent();
    this.getPartners();
    this.getLatestNews();
    this.getClientStories();
  }

  getContent = () => {
    this.setState({ loading: true });

    service
      .getHomeContent()
      .then((result) => {
        if (result.status === "success") {
          this.setState({
            slider: result.data.find((item) => item.slug === "slider"),
            whoWeHelp: result.data.find(
              (item) => item.slug === "who-we-help-home"
            ),
            newWhoWeHelp: result.data.find(
              (item) => item.slug === "new-who-we-help"
            ),
            keyValues: result.data.find((item) => item.slug === "key-values"),
            seo: result.data.find((item) => item.slug === "home-page-seo")
              ?.metadata?.seo,
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.log("Error in get homeContent", error);
        this.setState({ loading: false, error: true });
      });
  };

  getPartners = () => {
    this.setState({ loading: true });

    service
      .getPartners()
      .then((result) => {
        if (result.status === "success") {
          this.setState({
            partners: result.data.find((item) => item.slug === "partners"),
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.log("Error in get homeContent", error);
        this.setState({ loading: false, error: true });
      });
  };

  getLatestNews = () => {
    this.setState({ loading: true });

    service
      .getLatestNews()
      .then((result) => {
        if (result.status === "success") {
          this.setState({
            latestNews: result.data,
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.log("Error in get homeContent", error);
        this.setState({ loading: false, error: true });
      });
  };

  getClientStories = () => {
    this.setState({ loading: true });
    service
      .getClientStories()
      .then((result) => {
        if (result.status === "success") {
          this.setState({
            clientStories: result.data,
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.log("Error in get homeContent", error);
        this.setState({ loading: false, error: true });
      });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <ReactTitle
          title={
            this.state.seo?.metadata?.page_title ||
            commonConstants.SEO.DEFAULT_PAGE_TITLE
          }
        />

        <Header />

        <Slider
          slides={
            this.state.slider.metadata &&
            this.state.slider.metadata.slider_repeater
          }
          newRequestDemoModal={this.newRequestDemoModal}
        />

        <Value
          title={this.state.keyValues.content}
          values={
            this.state.keyValues.metadata &&
            this.state.keyValues.metadata.vitafy_values
          }
          bgColor={true}
        />

        <WhoWeHelp data={this.state.newWhoWeHelp} />

        {/* <Cta />   */}
        <CallToAction
          title="vCare + Marketplace = Guaranteed Savings"
          description="Whether you have a high deductible plan or no plan at all, we guarantee savings on your next medical procedure. Contact us today to find out."
          actionButtonText="Learn More"
          actionButtonLink="/vcare"
          // requestDemo={true}
        />

        <div className="divider"></div>

        <ClientStory
          clientStories={this.state.clientStories}
          partners={
            this.state.partners.metadata &&
            this.state.partners.metadata.partners_images
          }
          visibility={
            this.state.partners.metadata &&
            this.state.partners.metadata.entire_section_visibility
          }
          bgColor={false}
        />

        {/* {this.state.latestNews && this.state.latestNews.length > 0 ? (
          <LatestNews news={this.state.latestNews.slice(0, 2)} />
        ) : null} */}
        <RequestDemo formModal={this.newRequestDemoModal} />

        <Footer />
      </>
    );
  }
}

export default Home;
