/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { PHASE, QUESTION_TYPE } from '../../constants/questionaire';
import { getInformationFormDefaultValues } from '../../util/vitafyUtil';

const initialState = {
  questionaire: null,
  questionMap: null, // question map by questionId
  activeQuestion: null,
  respondent: getInformationFormDefaultValues(),
  phase: PHASE.GET_STARTED,
  questionnaireCode: '',
  // questionnaireCode: 'Membership_Eligibility',
  questionnaireResponseId: '',
};

export const slice = createSlice({
  name: 'questionaire',
  initialState,
  reducers: {
    // Changing the state directly (with mutation) in reducer
    // Redux Toolkit uses Immer under the hood (takes care of immutablility)
    setQuestionaire: (state, action) => {
      state.questionaire = action.payload;

      const questions =
        action.payload?.questions?.sort(
          (a, b) => Number(a.sequence) - Number(b.sequence)
        ) || [];

      let map = {};
      let firstQuestion = {};
      questions.forEach((item, index, arr) => {
        if (index === 0) {
          firstQuestion = item;
        }
        map[item.id] = item;

        // Order added as S.N. for each question
        map[item.id].order = index + 1;

        // nextQuestionId is added to facilitate prev/next action,
        // prevQuestionId is added when the user proceeds by click next
        map[item.id].nextQuestionId =
          map[item.id].nextQuestionId || arr[index + 1]?.id;

        // Original nextQuestionId (set in the db) is preserved.
        // `nextQuestionId` is sent or not sent in the payload based on original nextQuestionId
        // map[item.id].originalNextQuestionId =
        //   map[item.id].nextQuestionId || undefined;
      });

      state.questionMap = map;
      state.activeQuestion = firstQuestion;
    },

    setActiveQuestion: (state, action) => {
      state.activeQuestion = action.payload;
    },

    setQuestionMap: (state, action) => {
      state.questionMap = action.payload;
    },

    setPhase: (state, action) => {
      state.phase = action.payload;
    },

    setRespondent: (state, action) => {
      state.respondent = action.payload;
    },

    setQuestionnaireCode: (state, action) => {
      state.questionnaireCode = action.payload;
    },

    setQuestionnaireResponseId: (state, action) => {
      state.questionnaireResponseId = action.payload;
    },
    setQuestionaireAnswers: (state, action) => {
      const { payload } = action;

      if (!payload) return;

      const { answers, _id } = payload;
      const questionMap = JSON.parse(JSON.stringify(state.questionMap));
      let activeQuestion = null;

      if (answers) {
        answers.forEach(({ question, answer }, index) => {
          const nextQuestionId = answer[0]?.nextQuestionId;
          const questionId = question.id;
          if (questionMap?.[questionId]) {
            // Sets question answer
            if (question.type === QUESTION_TYPE.AGREE_DISAGREE) {
              questionMap[questionId].answer = answer?.reduce((prev, curr) => {
                prev[curr.option.value] = {
                  text: curr.option.value,
                  value: curr.value,
                  isRequired: curr.option.isRequired,
                };
                return prev;
              }, {});
              // Sets enableAction value to enable checkbox control
              questionMap[questionId].enableAction = answer?.reduce(
                (prev, curr) => {
                  prev[curr.option.value] = true;
                  return prev;
                },
                {}
              );
            } else {
              questionMap[questionId].answer = answer[0];
            }
          }
          if (questionMap?.[nextQuestionId]) {
            // Sets previous question id in current question, in order to handle prev click question navigation
            questionMap[nextQuestionId].prevQuestionId = questionId;
          }
          if (index + 1 === answers.length) {
            // Sets last or recently answered questions - next question id, as active question
            activeQuestion = questionMap[nextQuestionId];
          }
        });
        // Sets questionnaireResponseId for edit mode
        state.questionnaireResponseId = _id;
      }

      if (activeQuestion) {
        state.activeQuestion = activeQuestion;
      }

      state.questionMap = questionMap;
    },
    resetAll: () => initialState,
  },
});

// Actions
export const {
  setQuestionaire,
  setActiveQuestion,
  setQuestionMap,
  setPhase,
  setRespondent,
  setQuestionnaireCode,
  setQuestionnaireResponseId,
  resetAll,
  setQuestionaireAnswers,
} = slice.actions;

// Selectors
export const selectQuestionaire = (state) => state.questionaire.questionaire;
export const selectQuestions = (state) => state.questionaire.questions;
export const selectActiveQuestion = (state) =>
  state.questionaire.activeQuestion;
export const selectPhase = (state) => state.questionaire.phase;
export const selectQuestionMap = (state) => state.questionaire.questionMap;
export const selectRespondent = (state) => state.questionaire.respondent;
export const selectQuestionnaireCode = (state) =>
  state.questionaire.questionnaireCode;
export const selectQuestionnaireResponseId = (state) =>
  state.questionaire.questionnaireResponseId;

// Reducer
export default slice.reducer;
