import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import MDInput from '../md-components/mdInput';

const FormTextField = ({ name, label, showHelperText, icon, ...others }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <MDInput
          {...others}
          {...field}
          id={name}
          label={label}
          icon={icon || ''}
          error={!!errors[name]}
        />
      )}
    />
  );
};

FormTextField.defaultProps = {
  showHelperText: false,
  required: false,
};

export default FormTextField;
