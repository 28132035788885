import * as Yup from 'yup';

import regex from '../constants/regex';
import validationMessage from '../constants/validationMessage';

import { validateDob, validateMaskedInput } from '../util/momentUtil';

const { INVALID, REQUIRED } = validationMessage;

export const getPersonalInfoSchema = ({ emailRequired = false }) => {
  return Yup.object().shape({
    firstName: Yup.string().nullable().trim().required(),
    middleName: Yup.string().nullable(),
    lastName: Yup.string().nullable().trim().required(),
    dob: Yup.string()
      .nullable()
      .required()
      .test('checkIfDobIsValid', INVALID, (value) => {
        if (!value) return true;
        return validateDob(value);
      }),
    lastSSN: Yup.string()
      .nullable()
      .test('checkIfSSNValid', INVALID, (value) => validateMaskedInput(value)),
    gender: Yup.string(),
    race: Yup.string(),
    email: emailRequired
      ? Yup.string().trim().email().required(REQUIRED)
      : Yup.string().nullable().email(),
    phone: Yup.string()
      .nullable()
      .required()
      .test('checkIfPhoneValid', INVALID, (value) =>
        validateMaskedInput(value)
      ),
    addressLine1: Yup.string().trim().required(),
    addressLine2: Yup.string().nullable(),
    city: Yup.string().trim().required(),
    state: Yup.string().trim().required(),
    zip: Yup.string().required().matches(regex.ZIP, {
      excludeEmptyString: false,
      message: INVALID,
    }),
    lat: Yup.string().nullable(),
    lng: Yup.string().nullable(),
    hasInsurance: Yup.boolean(),
    insuranceName: Yup.string()
      .nullable()
      .when('hasInsurance', {
        is: (hasInsurance) => !!hasInsurance,
        then: Yup.string().trim().required(),
      }),
    insuranceMemberId: Yup.string().nullable(),
    groupNumber: Yup.string().nullable(),
  });
};
