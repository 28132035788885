import React from 'react';
import moment from 'moment';
import * as vitafyUtils from '../../util/vitafyUtil';
import * as momentUtils from '../../util/momentUtil';

const PlanInfo = (props) => {
  const { chosenPricingPlan, paymentInterval, isBasicPlan } = props;

  const subscriptionCost = paymentInterval === 'monthly' ?
    vitafyUtils.formatCurrency(chosenPricingPlan.monthly_cost, true, 0) : vitafyUtils.formatCurrency(chosenPricingPlan.annual_cost, true, 0);
  const nextMonthCost = paymentInterval === 'monthly' ?
    vitafyUtils.formatCurrency(chosenPricingPlan.monthly_cost, true, 0) : vitafyUtils.formatCurrency(chosenPricingPlan.annual_cost * 12, true, 0);

  const getDateAfterThirtyDays = () => {
    const laterDate = moment().add(1, 'months').format('MM/DD/YYYY');
    const formattedDate = momentUtils.momentViewFormat(laterDate);
    return formattedDate;
  }

  return (
    <div className={`bg-bgPrimaryLight mt-4 px-4 py-4 border-l-4 border-primary mx-0 md:mx-5 mb-1 md:mb-3 `}>
      <h5 className='body-text text-titleColor text-xl font-bold mb-2'>{vitafyUtils.capitalizeFirstLetter(chosenPricingPlan.plan)} Plan</h5>
      <p className='body-text text-titleColor'>You are enrolling into a <strong>{vitafyUtils.capitalizeFirstLetter(chosenPricingPlan.plan)} Plan</strong> with {paymentInterval === 'monthly' ? 'monthly' : 'annual'} subscription for
    <strong> {subscriptionCost}/month</strong> and {!isBasicPlan && <span><strong>{vitafyUtils.formatCurrency(chosenPricingPlan.one_time_setup_fee, true, 0, 0)}</strong> one time setup fee. </span>}
      Your next payment will be billed for the amount of <strong>{nextMonthCost}</strong> on {getDateAfterThirtyDays()} which is 30 days from today.</p>
    </div>
  );
}

export default PlanInfo;