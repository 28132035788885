import React, { useState, useEffect, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import * as lookupService from '../../../../service/lookup-service';
import { store } from '../../../../store';
import { _sortBy } from '../../../../util/lodashUtil';

const SelectRaceInput = () => {
  const { watch, setValue } = useFormContext();

  const [raceOptions, setRaceOptions] = useState([]);

  // Context Store
  const state = useContext(store);
  const { globalState } = state;
  const { groupCode } = globalState;

  const race = watch('race');

  useEffect(() => {
    const fetchRaceOptions = async (tenantGroupCode) => {
      try {
        const response = await lookupService.fetchLookupData({
          category: 'RACE',
          tenantGroupCode,
        });
        setRaceOptions(_sortBy(response.data, 'name'));
      } catch (err) {
        console.log('Error fetching race options from lookup', err);
      }
    };

    if (groupCode) {
      fetchRaceOptions(groupCode);
    }
  }, [groupCode]);

  return (
    <>
      <p className="body-text text-sm mb-1 mt-2">Race</p>
      <select
        name="race"
        className="w-full border bg-white rounded px-3 py-2 outline-none cursor-pointer"
        value={race}
        onChange={(e) => setValue('race', e.target.value)}
        placeholder="Select your race"
      >
        <option className="py-1 cursor-pointer" value="">
          Select your race
        </option>
        {raceOptions?.map((item) => (
          <option
            className="py-1 cursor-pointer"
            key={item.name}
            value={item.name}
          >
            {item.name}
          </option>
        ))}
      </select>
    </>
  );
};

export default SelectRaceInput;
