import React, { createRef, Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClipboardCheck,
  faSignInAlt,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { showToast } from "../../service/toasterService";
import { Modal } from "../../common";
import logo from "../../assets/images/logo.svg";
import RequestDemoForm from "./RequestDemoForm";
import * as service from "../../service";
import { getPortalLink } from "../../util/miscellaneousUtil";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      navScrolled: false,

      // request demo form
      sending: false,
      name: "",
      email: "",
      phone: "",
      profession: "",
      message: "",
    };
    this.requestDemoModal = createRef();
  }

  componentDidMount() {
    window.addEventListener("scroll", this.listenScrollEvent);
    this.prev = window.scrollY;

    // this.requestDemoModal.current.toggle();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenScrollEvent);
  }

  listenScrollEvent = (e) => {
    const window = e.currentTarget;
    this.prev = window.scrollY;
    if (window.scrollY > 150) {
      this.setState((prevState) => ({
        navScrolled: true,
      }));
    } else {
      this.setState((prevState) => ({
        navScrolled: false,
      }));
    }
  };

  handleMenuClick = () => {
    this.setState((prevState) => ({
      menuOpen: !prevState.menuOpen,
    }));
  };

  handleFullNameChange = (e) => {
    this.setState({ name: e.target.value });
  };
  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });
  };
  handlePhoneNumberChange = (value) => {
    this.setState({ phone: value });
  };
  handleTypeChange = (e) => {
    this.setState({ profession: e.target.value });
  };
  handleMessageChange = (e) => {
    this.setState({ message: e.target.value });
  };
  openModal = () => {
    this.setState({
      name: "",
      email: "",
      phone: "",
      profession: "",
      message: "",
    });
    this.requestDemoModal.current.toggle();
  };
  closeModal = () => {
    this.requestDemoModal.current.toggle();
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const dataToSend = {
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      profession: this.state.profession,
      message: this.state.message,
    };

    this.setState({ sending: true });

    service
      .submitContactForm(dataToSend)
      .then((result) => {
        this.setState({ sending: false });
        if (result.data === "success") {
          showToast("success", "Thank you for reaching out!");
          //reset the form
          this.setState({
            name: "",
            email: "",
            phone: "",
            profession: "",
            message: "",
          });
        } else {
          showToast("error", "Oops. Something went wrong.");
        }
      })
      .catch((error) => {
        console.log("Error in post request demo", error);
        this.setState({ sending: false });
      });
  };

  render() {
    const currentRoute = this.props.location.pathname;

    return (
      <>
        <nav
          className={`transition duration-500 ease-in-out fixed flex items-center justify-between px-4 py-3 top-0 w-full z-40  ${
            !this.state.navScrolled
              ? currentRoute === "/"
                ? "bg-transparent"
                : "bg-white shadow"
              : "bg-white shadow"
          }`}
        >
          <NavLink exact to="/" className="z-50">
            <img
              src={logo}
              className="block w-35 xxl:w-40"
              alt="vitafy-health-logo"
            />
          </NavLink>

          <div className="absolute w-full hidden left-0 text-center xxl:text-left xxl:left-18/100 xl:block  z-40">
            <Links currentRoute={currentRoute} />
          </div>

          <div className="items-center hidden xl:flex z-50">
            <SecondaryLinks {...this.props} openModal={this.openModal} />
          </div>

          {/* Hamburger icon */}
          <div
            className="block xl:hidden  cursor-pointer"
            onClick={this.handleMenuClick}
          >
            <div
              className={`menuBar ${
                !this.state.menuOpen ? null : "menuOnBar1"
              }`}
            ></div>
            <div
              className={`menuBar ${
                !this.state.menuOpen ? null : "menuOnBar2"
              }`}
            ></div>
            <div
              className={`menuBar ${
                !this.state.menuOpen ? null : "menuOnBar3"
              }`}
            ></div>
          </div>
        </nav>

        {/* mobile overlay nav */}
        <div
          className={`w-full h-screen bg-bgColor fixed z-30 px-4 pt-24 ${
            this.state.menuOpen ? `block` : `hidden`
          } xl:hidden`}
        >
          <Links />
          <SecondaryLinks openModal={this.openModal} />
        </div>

        {/* Request a Demo Modal */}
        <Modal
          modalTitle="Request a demo"
          ref={this.requestDemoModal}
          iconName={faUserPlus}
        >
          <RequestDemoForm
            sending={this.state.sending}
            name={this.state.name}
            handleFullNameChange={this.handleFullNameChange}
            email={this.state.email}
            handleEmailChange={this.handleEmailChange}
            phone={this.state.phone}
            handlePhoneNumberChange={this.handlePhoneNumberChange}
            profession={this.state.profession}
            handleTypeChange={this.handleTypeChange}
            message={this.state.message}
            handleMessageChange={this.handleMessageChange}
            closeModal={this.closeModal}
            handleSubmit={this.handleSubmit}
          />
        </Modal>
      </>
    );
  }
}

const Links = ({ currentRoute }) => {
  return (
    <div>
      <NavLink
        exact={true}
        // activeClassName='text-secondary'
        to="/marketplace"
        className={
          currentRoute === "/marketplace"
            ? "nav-link text-secondary"
            : "nav-link"
        }
      >
        Marketplace
      </NavLink>
      <NavLink
        exact={true}
        // activeClassName='text-secondary'
        to="/providers"
        className={
          currentRoute === "/providers" ? "nav-link text-secondary" : "nav-link"
        }
      >
        Providers
      </NavLink>
      <NavLink
        exact={true}
        // activeClassName='text-secondary'
        to="/vcare"
        className={
          currentRoute === "/vcare" ? "nav-link text-secondary" : "nav-link"
        }
      >
        vCare
      </NavLink>
      <NavLink
        exact={true}
        // activeClassName='text-secondary'
        to="/community"
        className={
          currentRoute === "/community" ? "nav-link text-secondary" : "nav-link"
        }
      >
        Community
      </NavLink>
    </div>
  );
};

const SecondaryLinks = (props) => {
  const portalLink = getPortalLink();

  return (
    <>
      {/* <NavLink
        exact={true}
        to='/member/enroll'
        className='text-bodyColor xl:text-titleColor outline-none no-underline font-heebo font-bold text-xl xl:text-sm block xl:inline mt-10 xl:mt-0 mb-3 xl:mb-0 hover:text-primary'
      >
        <FontAwesomeIcon
          icon={faClipboardCheck}
          className='inline-block mr-1 text-primary'
        />{' '}
        Enroll Now
      </NavLink>
      <div className='opacity-50 mx-2 hidden xl:inline'>|</div> */}

      <a
        href={`${portalLink}`}
        target="_blank"
        className="text-bodyColor xl:text-titleColor outline-none no-underline font-heebo font-bold text-xl xl:text-sm block xl:inline xl:mr-5 mb-5 xl:mb-0 hover:text-primary"
      >
        <FontAwesomeIcon
          icon={faSignInAlt}
          className="inline-block mr-1 text-primary"
        />{" "}
        Login
      </a>

      <RequestDemo {...props} />
    </>
  );
};

const RequestDemo = (props) => {
  return (
    <button className="small-button py-2" onClick={props.openModal}>
      Request A Demo
    </button>
  );
};

export default withRouter(Header);
