import React  from 'react';
import { Modal, Checkbox } from '../../../common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMapMarkerAlt,
  faHeadset,
  faTrash,
  faCheckCircle,
  faHospitalAlt,
  faUserShield,
  faUserMinus,
  faUserPlus,
  faPencilAlt,
  faEye,
} from '@fortawesome/free-solid-svg-icons';
import AddDpcForm from './AddDpcForm';
import AddPlan from './AddPlan';
import * as vitafyUtils from '../../../util/vitafyUtil';
import placeholder from '../../../assets/images/placeholder-hospital.png';
import { useForm } from 'react-hook-form';
import routeAliasConstant from '../../../constants/routeAliasConstant';
import { parseHtml } from '../../../util';

const PlanInformation = (props) => {
  const { register, handleSubmit, setValue, clearError } = useForm();
  const {
    client,
    agentInfo,
    directPrimaryCare,
    additionalCoverage,
    careNavigation,
    selectedDpc,
    dpcSearchList,
    openModalName,
    additionalCoverageList,
    dpcLoading,
    planInformationMonthlyCost,
    comingFrom,
    vCareMonthlyCost,
    // enrollmentContent from cosmic
    enrollmentContent,
    enrollmentFromDPC,
    vcareCosmicContent
  } = props;
  const formBuilder = () => {
    register({ name: 'totalMembers' });
    // register({ name: 'iAgreeVitafy' }, { required: true });
    register(
      { name: 'iAgreeSedera' },
      { required: additionalCoverage?.isActive ? true : false }
    );
    register({ name: 'paymentMethod' });
    setValue([
      { totalMembers: client.totalMembers },
      // { iAgreeVitafy: client.iAgreeVitafy ? true : false },
      { iAgreeSedera: client.iAgreeSedera ? true : false },
      { paymentMethod: 'card' },
    ]);
  };
  React.useEffect(() => {
    formBuilder();
  }, [client.totalMembers]);
  React.useLayoutEffect(() => {
    formBuilder();
  }, [
    client.totalMembers,
    directPrimaryCare,
    careNavigation,
    additionalCoverage,
  ]);
  const handleInputChange = (e, field) => {
    let value =
      e.target && (e.target.value || e.target.value === '')
        ? e.target.value
        : e;
    if (
      (e.target ? e.target.validity.valid : e) ||
      value === '' ||
      value === 0 ||
      field === 'iAgreeVitafy' ||
      field === 'iAgreeSedera'
    ) {
      setValue(field, value);
      if (field === 'paymentMethod') {
        client.paymentDetail[field] = value;
      } else {
        client[field] = value;
        props.updateFields(field, value);
      }
      clearError(field);
    }
  };
  const openAddModal = (planName) => {
    props.openAddModal(planName);
  };
  const removePlan = (planName) => {
    props.removePlan(planName);
  };
  const getTotalCost = (total, item) => {
    return total + item;
  };
  const clientPlan = [directPrimaryCare, careNavigation, additionalCoverage];
  const monthlyCost =
    clientPlan.filter((x) => x && x.isActive).length > 0
      ? clientPlan
        .filter((x) => x && x.isActive)
        .map((plan) => (plan.cost || plan.cost == 0 ? +plan.cost : 0))
        .reduce(getTotalCost) + parseInt(vCareMonthlyCost)
      : parseInt(vCareMonthlyCost);

  const formSubmit = async (formValues) => {
    formValues.clientPlan = clientPlan.map((x) => {
      x.isRegistered = x.isActive ? true : false;
      return x;
    });
    formValues.agentClientPlan = agentInfo?.agentId
      ? formValues.clientPlan
      : null;
    formValues.monthlyCost = enrollmentFromDPC ? planInformationMonthlyCost : monthlyCost;
    formValues.totalAmount = formValues.monthlyCost;
    // return;
    props.submit(3, formValues);
  };

  return (
    <form ref={props.planInfoRef} onSubmit={handleSubmit(formSubmit)}>
      <div className='container mx-auto px-4'>
        <h4 className='font-normal'>
          Welcome{' '}
          <strong>{client && vitafyUtils.getDisplayName(client)},</strong>
        </h4>
        {comingFrom !== routeAliasConstant.VCARE ?
          <>
            {client && client.id ? (
              <p className='body-text'>
                Your healthcare advisor,{' '}
                <strong>
                  {client?.salesAgent ||
                    (agentInfo ? vitafyUtils.getFullName(agentInfo) : null)}
              ,{' '}
                </strong>{' '}
            has selected the following healthcare solution for you. If you have
            any questions, please contact{' '}
                {client?.salesAgent ||
                  (agentInfo ? vitafyUtils.getFullName(agentInfo) : null)}{' '}
            at{' '}
                {agentInfo &&
                  agentInfo.phone &&
                  vitafyUtils.getPhoneDisplayText(agentInfo.phone, agentInfo?.countryCode)}
            .
              </p>
            ) : (
                <p className='body-text'>
                  Select a healthcare solution that is right for you. If you have
            questions or need a consultation, please contact{' '}
                  {agentInfo ? vitafyUtils.getFullName(agentInfo) : null} at{' '}
                  {agentInfo &&
                    agentInfo.phone &&
                    vitafyUtils.getPhoneDisplayText(agentInfo.phone, agentInfo?.countryCode)}
            .
                </p>
              )}
          </>
          :
          <div
            className='body-text'
            dangerouslySetInnerHTML={{
              __html: parseHtml(vcareCosmicContent && vcareCosmicContent.welcome_text),
            }}
          />
        }

        <div className='divider-line'></div>

        {comingFrom !== routeAliasConstant.VCARE &&
          <div className='flex flex-wrap flex-row items-center mb-4'>
            {/* DPC Description starts here */}
            <div className='w-full sm:w-3/5 mb-3 sm:mb-0'>
              <div className='flex'>
                <div className='w-20 pr-12'>
                  <FontAwesomeIcon
                    icon={faHospitalAlt}
                    className='text-primary text-4xl ml-4 mt-1'
                  />
                </div>
                <div className='flex-grow'>
                  <h4 className='text-xl'>Direct Primary Care</h4>
                  <p className='body-text'>
                    {enrollmentContent.direct_primary_care}
                  </p>
                </div>
              </div>
            </div>
            {/* DPC Description ends here */}
            {/* Add DPC starts here */}
            <div className='w-full sm:w-2/5'>
              <div
                className={`h-32 mb-6 rounded-md  px-6  ${directPrimaryCare?.referenceId
                  ? 'bg-white shadow-md relative'
                  : null
                  }`}
              >
                {directPrimaryCare?.referenceId ? (
                  <>
                    <div className={`absolute top-0 left-0 right-0 px-4 pt-2 ${enrollmentFromDPC ? 'hidden' : ''}`}>
                      <div className='h-8 flex justify-between'>
                        <div>
                          {' '}
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className='text-primary'
                          />
                        </div>
                        {!client.id && (
                          <div>
                            <FontAwesomeIcon
                              icon={faPencilAlt}
                              className='text-gray-400 hover:text-red-500 cursor-pointer'
                              onClick={() => openAddModal('directPrimaryCare')}
                            />
                          &nbsp;
                            <FontAwesomeIcon
                              icon={faTrash}
                              className='text-gray-400 hover:text-red-500 cursor-pointer'
                              onClick={() => removePlan('directPrimaryCare')}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='flex h-full justify-center items-center -mx-2'>
                      <div className='w-1/4 px-2'>
                        <img
                          src={
                            selectedDpc && selectedDpc.imageUrl
                              ? selectedDpc.imageUrl
                              : placeholder
                          }
                          className='w-full'
                          alt='dpc-logo'
                        />
                      </div>
                      <div className='w-3/4 px-2'>
                        <p className='font-heebo font-bold text-titleColor text-base'>
                          {selectedDpc ? selectedDpc.providerName : null}
                        </p>
                        <div className='flex'>
                          <div className='w-6 pr-2'>
                            <FontAwesomeIcon
                              icon={faMapMarkerAlt}
                              className='text-primary'
                            />
                          </div>
                          <div className='flex-grow'>
                            <p className='font-heebo text-sm text-titleColor'>
                              {selectedDpc && selectedDpc.address && (
                                <>
                                  {selectedDpc.address.addressLine1} <br />
                                  {selectedDpc.address.city},{' '}
                                  {selectedDpc.address.state}{' '}
                                  {selectedDpc.address.zip}
                                </>
                              )}
                            </p>
                          </div>
                        </div>
                        <a
                          href={`https://vitafymed.com/direct-primary-care/${vitafyUtils.replaceLowerCase(
                            selectedDpc.providerName,
                            ' ',
                            '-'
                          )}/${selectedDpc.npi}`}
                          target='_blank'
                          className='no-underline text-secondary focus:outline-none'
                        >
                          <FontAwesomeIcon
                            icon={faEye}
                            className='text-primary'
                          />
                          <span className='font-heebo text-sm'>
                            &nbsp; View detail
                        </span>
                        </a>
                      </div>
                    </div>
                  </>
                ) : (
                    <div
                      className={`flex h-full items-center ${!client.id ? '' : 'hidden'
                        }`}
                    >
                      <button
                        className='mid-button block mx-auto'
                        onClick={() => openAddModal('directPrimaryCare')}
                        type='button'
                      >
                        Add DPC
                  </button>
                    </div>
                  )}
              </div>
            </div>
            {/* Add DPC ends here */}
          </div>
        }

        <div className='flex flex-wrap flex-row items-center mb-4'>
          {/* vCare Description starts here */}
          <div className='w-full sm:w-3/5 sm:pr-6 mb-3 sm:mb-0'>
            <div className='flex'>
              <div className='w-20 pr-12'>
                <FontAwesomeIcon
                  icon={faHeadset}
                  className='text-primary text-4xl ml-4 mt-1'
                />
              </div>
              <div className='flex-grow'>
                <h4 className='text-xl'>vCare</h4>
                <p className='body-text'>
                  {comingFrom !== routeAliasConstant.VCARE ?
                    enrollmentContent.vcare
                    :
                    vcareCosmicContent && vcareCosmicContent.description_text
                  }
                </p>
              </div>
            </div>
          </div>
          {/* vCare Description ends here */}
          {/* vCare phone number starts here */}
          <div className='w-full sm:w-2/5'>
            <div className='h-32 bg-white mb-6 rounded-md shadow-md px-6'>
              <div className='flex h-full justify-center items-center -mx-2'>
                <div className='w-1/4 px-2'>
                  <FontAwesomeIcon
                    icon={faHeadset}
                    className='text-primary text-4xl block mx-auto mb-2'
                  />
                </div>
                <div className='w-3/4 px-2 mr-2'>
                  <a
                    href='tel:8664848239'
                    className='font-heebo font-bold text-titleColor text-xl sm:text-2xl block text-right'
                  >
                    (866) 4-VITAFY{' '}
                    <span className='block font-normal text-base'>
                      (866) 484-8239
                    </span>
                  </a>
                  <p className='font-heebo text-xs text-bodyColor text-right'>
                    (Included)
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* vCare phone number ends here */}
        </div>

        {/* Additional Coverge not needed for DPC Enrollment */}
        {!enrollmentFromDPC && comingFrom !== routeAliasConstant.VCARE ? (
          <div className='flex flex-wrap flex-row items-center'>
            {/* Additional Coverage starts here */}
            <div className='w-full sm:w-3/5 mb-3 sm:mb-0'>
              <div className='flex'>
                <div className='w-20 pr-12'>
                  <FontAwesomeIcon
                    icon={faUserShield}
                    className='text-primary text-4xl ml-4 mt-1'
                  />
                </div>
                <div className='flex-grow'>
                  <h4 className='text-xl'>Additional Coverage</h4>
                  <p className='body-text'>
                    {enrollmentContent.additional_coverage}
                  </p>
                </div>
              </div>
            </div>
            {/* Additional Coverage ends here */}

            {/* Add Plan starts here */}
            <div className='w-full sm:w-2/5'>
              <div
                className={`h-32 mb-6 rounded-md  px-6 ${additionalCoverage?.referenceId
                  ? 'bg-white shadow-md relative'
                  : null
                  }`}
              >
                {additionalCoverage?.referenceId ? (
                  <>
                    <div className='absolute top-0 left-0 right-0 px-4 pt-2'>
                      <div className='h-8 flex justify-between'>
                        <div>
                          {' '}
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className='text-primary'
                          />
                        </div>
                        {!client.id && (
                          <div>
                            <FontAwesomeIcon
                              icon={faPencilAlt}
                              className='text-gray-400 hover:text-red-500 cursor-pointer'
                              onClick={() => openAddModal('additionalCoverage')}
                            />
                            &nbsp;
                            <FontAwesomeIcon
                              icon={faTrash}
                              className='text-gray-400 hover:text-red-500 cursor-pointer'
                              onClick={() => removePlan('additionalCoverage')}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='flex h-full justify-center items-center -mx-2'>
                      <div className='w-1/4 px-2'>
                        <img
                          src='https://sedera.com/wp-content/uploads/2020/03/Sedera-Blues-Logo-Vertical-1-1.png'
                          alt='sedera-img'
                        />
                      </div>
                      <div className='w-3/4 px-2'>
                        <p className='font-heebo font-bold text-titleColor text-2.5xl sm:text-xl  text-center'>
                          {additionalCoverage?.planDescription}
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                    <div
                      className={`flex h-full items-center ${!client.id ? '' : 'hidden'
                        }`}
                    >
                      <button
                        className='mid-button block mx-auto'
                        onClick={() => openAddModal('additionalCoverage')}
                        type='button'
                      >
                        Add Plan
                    </button>
                    </div>
                  )}
              </div>
            </div>
            {/* Add Plan ends here */}
          </div>
        ) : null}

        <div className='divider-line'></div>
        {/* Estimated number of members & Estimated cost per member */}
        <div className='flex flex-row flex-wrap justify-center sm:justify-between sm:flex-row-reverse'>
          {/* Estimated cost per member */}
          <div>
            <h6 className='text-bodyColor font-normal'>
              Estimated cost per member
            </h6>
            <h4 className='text-3xl mt-2'>
              {vitafyUtils.currencyFormat(enrollmentFromDPC ? planInformationMonthlyCost : monthlyCost)}/month*
            </h4>
          </div>

          {/* Estimated number of members */}
          <div className='mb-8 sm:mb-0'>
            <h6 className='text-bodyColor font-normal'>
              Estimated # of members
            </h6>
            <div className='flex w-48 -mx-1 mt-2'>
              <div className='w-2/5 px-1'>
                <button
                  className='group h-full w-full bg-white border rounded-md shadow-sm flex justify-center items-center cursor-pointer hover:bg-secondary focus:outline-none'
                  onClick={() =>
                    handleInputChange(
                      client.totalMembers > 1 ? +client.totalMembers - 1 : 1,
                      'totalMembers'
                    )
                  }
                  type='button'
                >
                  <FontAwesomeIcon
                    icon={faUserMinus}
                    className='text-titleColor block group-hover:text-white'
                  />
                </button>
              </div>
              <div className='w-3/5 px-1'>
                <input
                  className='appearance-none block w-full bg-white font-heebo text-lg text-titleColor border border-extended rounded py-3 px-4 leading-tight focus:outline-none'
                  type='number'
                  value={
                    client && (client.totalMembers || client.totalMembers === 0)
                      ? client.totalMembers
                      : ''
                  }
                  style={{ textIndent: '6px' }}
                  onChange={(e) => handleInputChange(e, 'totalMembers')}
                  name='totalMembers'
                  disabled
                />
              </div>
              <div className='w-2/5 px-1'>
                <button
                  className='group h-full w-full bg-white border rounded-md shadow-sm flex justify-center items-center cursor-pointer hover:bg-secondary focus:outline-none'
                  onClick={() =>
                    handleInputChange(
                      client.totalMembers || client.totalMembers === 0
                        ? +client.totalMembers + 1
                        : 1,
                      'totalMembers'
                    )
                  }
                  type='button'
                >
                  <FontAwesomeIcon
                    icon={faUserPlus}
                    className='text-titleColor block group-hover:text-white'
                  />
                </button>
              </div>
            </div>
          </div>

          {/* Note */}
          <p className='body-text mt-3 md:mt-6 text-sm'>
            {comingFrom !== routeAliasConstant.VCARE ?
              <> <span>Disclaimer:</span> {enrollmentContent.price_disclaimer}</>
              :
              vcareCosmicContent && vcareCosmicContent.disclaimer
            }
          </p>
        </div>
        <div className='divider-line'></div>

        {/* <Checkbox
          checked={client.iAgreeVitafy ? true : false}
          setChecked={(e) => handleInputChange(e, 'iAgreeVitafy')}
          className='inline'
        >
          <p className='font-heebo text-base text-titleColor mb-3 select-none inline-block'>
            I agree with{' '}
            <a
              href='https://vitafymed.com/terms-of-use'
              target='_blank'
              className='no-underline font-bold text-secondary focus:outline-none'
            >
              {' '}
              Vitafy terms & policy
            </a>
          </p>
        </Checkbox> */}
        {additionalCoverage?.isActive && (
          <Checkbox
            checked={client.iAgreeSedera ? true : false}
            setChecked={(e) => handleInputChange(e, 'iAgreeSedera')}
          >
            <p className='font-heebo text-base text-titleColor'>
              I agree with{' '}
              <a
                // href='https://vitafymed.com/terms-of-use'
                // target='_blank'
                className='no-underline font-bold text-secondary focus:outline-none'
              >
                {' '}
                Sedera terms & policy
              </a>
            </p>
          </Checkbox>
        )}
      </div>

      {/* Modal for Add DPC */}
      <Modal
        modalTitle='Add DPC'
        ref={props.directPrimaryCareModal}
        iconName={faHospitalAlt}
      >
        <AddDpcForm
          directPrimaryCare={directPrimaryCare}
          dpcSearchList={dpcSearchList}
          openModalName={openModalName}
          searchDpc={props.searchDpc}
          savePlan={props.savePlan}
          dpcLoading={dpcLoading}
          searchProviders={props.searchProviders}
        />
      </Modal>

      {/* Modal for Add Plan */}
      <Modal
        modalTitle='Add Plan'
        ref={props.additionalCoverageModal}
        iconName={faUserShield}
      >
        <AddPlan
          additionalCoverage={additionalCoverage}
          additionalCoverageList={additionalCoverageList}
          openModalName={openModalName}
          savePlan={props.savePlan}
          sedera={enrollmentContent.sedera_content}
        />
      </Modal>
    </form>
  );
};

export default PlanInformation;
