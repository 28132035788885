import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { parseHtml } from '../../util';

const WhoWeHelp = ({ data }) => {
  const history = useHistory();

  return (
    <>
      <div className='divider'></div>
      <div className='container mx-auto px-4'>
        <div className='flex flex-wrap flex-row-reverse md:flex-row items-center w-full overflow-hidden md:-mx-6 lg:-mx-12'>
          <div className='w-full md:w-1/2 md:px-6 lg:px-12'>
            <h2 className='heading'> Who We Help</h2>
            <img
              src="https://imgix.cosmicjs.com/798c1720-2b0f-11eb-8245-31a0d5eed227-pyramid-lg.png"
              alt="who-we-help"
              className='block mx-auto md:hidden px-8 sm:px-20'
            />
            <div
              className='body-text text-md mt-10 mb-6 md:mt-0 md:mb-8 lg:mb-0'
              dangerouslySetInnerHTML={{
                __html: parseHtml(data.content),
              }}
            />
            <button
              className='big-button  shadow transition duration-500 ease-in-out  hover:shadow-md focus:outline-none'
              onClick={() => history.push(data?.metadata?.action_button_link ? data.metadata.action_button_link : '/who-we-help')}
            >
              {data?.metadata?.action_button_text}
              <FontAwesomeIcon
                icon={faArrowRight}
                className='inline-block ml-2 '
              />
            </button>
          </div>
          <div className='w-full md:w-1/2  md:px-6 lg:px-12 hidden md:block'>
            <img
              src={data?.metadata?.image?.url}
              alt="who-we-help"
              className='hidden md:block xl:pr-20'
            />
          </div>
        </div>
      </div>

      <div className='divider'></div>
    </>
  );
}


export default WhoWeHelp;


// const WhoWeHelp = ({ image, list }) => {
//   const history = useHistory();

//   const display =
//     list &&
//     list.map((item, index) => {
//       return (
//         <div key={index}>
//           <Fade left>
//             <div>
//               <h4>{item.title}</h4>
//               <p className='body-text'>{item.body}</p>
//               <Link
//                 className='link'
//                 to={{
//                   pathname: '/who-we-help',
//                   state: {
//                     scrollTo: item.title,
//                   },
//                 }}
//               >
//                 <span className='link-hover'>Learn more </span>{' '}
//                 <FontAwesomeIcon icon={faArrowRight} />
//               </Link>
//               {/* <a
//                 href='#'
//                 className='link'
//                 onClick={() => history.push('/whowehelp')}
//               >
//                 <span className='link-hover'>Learn more </span>{' '}
//                 <FontAwesomeIcon icon={faArrowRight} />
//               </a> */}
//               {index !== list.length - 1 ? (
//                 <div className='divider-line'></div>
//               ) : null}
//             </div>
//           </Fade>
//         </div>
//       );
//     });

//   return (
//     <div
//       className='bg-no-repeat bg-right bg-no-img'
//       style={{ backgroundImage: `url(${image})`, backgroundSize: '55%' }}
//     >
//       {/*   */}
//       <div className='divider'></div>

//       <div className='container mx-auto relative overflow-hidden px-4'>
//         <div className='mx-auto '>
//           <h2 className='heading'> Who We Help</h2>
//           <div className='w-full lg:w-2/5'>{display}</div>
//         </div>
//         {/* <img
//           src={image}
//           className='absolute top-0 left-1/2 hidden lg:block max-w-4xl'
//           alt='who-we-help-illustration'
//         /> */}
//       </div>

//       <div className='divider'></div>
//     </div>
//   );
// };
