import React, { useMemo, useContext } from 'react';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faVenusMars,
  faBirthdayCake,
  faEnvelope,
  faMobileAlt,
  faMapMarkerAlt,
  faUserEdit,
  faTrash,
  faTint,
} from '@fortawesome/free-solid-svg-icons';

import { ListItem } from '../../../common';

import * as vitafyUtils from '../../../util/vitafyUtil';
import * as miscellaneousUtil from '../../../util/miscellaneousUtil';
import * as momentUtils from '../../../util/momentUtil';
import { checkEnrollmentTenantSetting } from '../../../util/tenantSettingUtil';
import { store } from '../../../store';
import commonConstants from '../../../constants/commonConstant';

const { TENANT_SETTINGS_CODE } = commonConstants;
const { BLOOD_GROUP } = TENANT_SETTINGS_CODE;

const ClientDisplay = ({
  fullName,
  email,
  phone,
  countryCode,
  relationship,
  gender,
  dob,
  address,
  bloodGroup,
  isPrimary = false,
  whiteBg,
  isEditable,
  onEditClick,
  onDeleteClick,
}) => {
  // Context Store
  const contextStore = useContext(store);
  const { globalState } = contextStore;
  const { tenantSettings } = globalState;

  const formattedDob = dob ? momentUtils.momentViewFormat(dob) : 'N/A';

  const showBloodGroup = useMemo(() => {
    return checkEnrollmentTenantSetting(BLOOD_GROUP, {
      tenantSettings,
    });
  }, [tenantSettings]);

  return (
    <div
      className={`w-full relative rounded-md ${
        whiteBg ? 'bg-bgColor' : 'bg-white'
      } py-6 px-6 border-2 ${
        isPrimary && 'border-dedicatedDpcPrimary border-opacity-100'
      }`}
    >
      {isEditable && (
        <>
          <FontAwesomeIcon
            data-tip
            data-for="edit"
            className="text-bodyColor text-sm cursor-pointer absolute hover:text-dedicatedDpcPrimary opacity-50 hover:opacity-100"
            icon={faUserEdit}
            style={{ right: '2.5rem', top: '1rem' }}
            onClick={onEditClick}
          />
          <ReactTooltip id="edit" type="dark">
            <span className="body-text text-sm text-white">Edit</span>
          </ReactTooltip>

          <FontAwesomeIcon
            data-tip
            data-for="delete"
            className="text-bodyColor text-sm cursor-pointer absolute hover:text-dedicatedDpcPrimary opacity-50 hover:opacity-100"
            icon={faTrash}
            style={{ right: '1rem', top: '1rem' }}
            onClick={onDeleteClick}
          />
          <ReactTooltip id="delete" type="dark">
            <span className="body-text text-sm text-white">Delete</span>
          </ReactTooltip>
        </>
      )}
      {/* Name and Image */}
      <div className="flex items-center">
        <div className="w-12 md:w-18 h-12 md:h-18 rounded-full overflow-hidden mr-4">
          <img
            src={miscellaneousUtil.createImageFromInitials(
              72,
              fullName,
              '#333',
              whiteBg ? '#fff' : '#F4F5F7'
            )}
            alt="applicant"
            className="w-full h-full object-cover object-center"
          />
        </div>
        <div>
          <h6 className="text-base md:text-lg">{fullName}</h6>
          <p className="body-text text-sm">{relationship}</p>
        </div>
      </div>
      <hr
        className={`w-full h-1.5px my-3 md:my-6 rounded-full ${
          isPrimary ? 'bg-dedicatedPrimary' : 'bg-bodyColor opacity-25'
        }`}
      />
      {/* Other Details */}
      <ListItem
        icon={faVenusMars}
        text={gender || 'N/A'}
        iconClassName="text-titleColor text-sm mr-1"
        textClassName="text-bodyColor text-sm"
        containerClassName="mb-1"
        iconWrapperClassName="mr-2"
        address={false}
        addressDoubleLine={false}
      />
      {showBloodGroup && (
        <ListItem
          icon={faTint}
          text={bloodGroup || 'N/A'}
          iconClassName="text-titleColor text-sm mr-1"
          textClassName="text-bodyColor text-sm"
          containerClassName="mb-1"
          iconWrapperClassName="mr-2"
          address={false}
          addressDoubleLine={false}
        />
      )}
      <ListItem
        icon={faBirthdayCake}
        text={formattedDob}
        iconClassName="text-titleColor text-sm mr-1"
        textClassName="text-bodyColor text-sm"
        containerClassName="mb-1"
        iconWrapperClassName="mr-2"
        address={false}
        addressDoubleLine={false}
      />
      <ListItem
        icon={faMobileAlt}
        text={vitafyUtils.getPhoneDisplayText(phone, countryCode)}
        iconClassName="text-titleColor text-sm mr-1"
        textClassName="text-bodyColor text-sm"
        containerClassName="mb-1"
        iconWrapperClassName="mr-2"
        address={false}
        addressDoubleLine={false}
      />
      <ListItem
        icon={faEnvelope}
        text={email}
        iconClassName="text-titleColor text-sm mr-1"
        textClassName="text-bodyColor text-sm"
        containerClassName="mb-1"
        iconWrapperClassName="mr-2"
        address={false}
        addressDoubleLine={false}
        truncateText={true}
        textChar={30}
      />
      <ListItem
        icon={faMapMarkerAlt}
        text={address}
        iconClassName="text-titleColor text-sm mr-1"
        textClassName="text-bodyColor text-sm"
        containerClassName="mb-1"
        iconWrapperClassName="mr-2"
        address={true}
        addressDoubleLine={true}
      />
    </div>
  );
};

export default ClientDisplay;
