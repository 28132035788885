import React, { Component } from 'react';

import * as appointmentService from '../../../service/appointmentService';

import { ButtonLoader } from '../../../common';
import { Confirmation, MultiStepFormWrapper } from '../common';
import AppointmentForm from './AppointmentForm';

import { tenantCodeEnum } from '../../../constants/tenantCodeConstant';

class RequestAppointment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        tenantCode: '',
        requestedDate: new Date(),
      },
      info: {},
      isSubmitting: false,
      showConfirmation: false,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    const { providerDetail, tenantCode } = this.props;
    this.setState((prevState) => ({
      info: providerDetail,
      data: {
        ...prevState.data,
        tenantCode: tenantCode,
      },
    }));
    if (
      tenantCode === tenantCodeEnum.HCM ||
      tenantCode === tenantCodeEnum.HCMNR
    ) {
      this.props.history.push('/404');
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLoading !== this.props.isLoading) {
      this.setState({ isLoading: this.props.isLoading });
    } else if (prevProps.providerDetail !== this.props.providerDetail) {
      this.setState({ info: this.props.providerDetail });
    }
  }

  triggerFormSubmit = () => {
    this.formRef.current.dispatchEvent(
      new Event('submit', { cancelable: true })
    );
  };

  handleFormSubmit = (formValues) => {
    formValues.tenantCode = this.state.data.tenantCode;
    formValues.providerId = this.state.info.providerId;
    formValues.isCheckedIn = false; // isCheckedIn flag set to true for member check-in only
    this.setState({ isSubmitting: true });
    appointmentService
      .requestAppointment(formValues)
      .finally(() => this.setState({ isSubmitting: false }))
      .then((response) => {
        if (response.status === 'success') {
          this.setState((prevState) => ({
            data: {
              ...prevState.data,
              email: response.data.email,
            },
            showConfirmation: true,
          }));
        }
      })
      .catch((error) =>
        console.log('Error while requesting appointment', error)
      );
  };

  render() {
    return (
      <>
        <MultiStepFormWrapper displayHeader={false}>
          {!this.state.showConfirmation ? (
            <AppointmentForm
              data={this.state.data}
              formRef={this.formRef}
              submit={this.handleFormSubmit}
            />
          ) : (
            <Confirmation
              title="Appointment Requested Successfully"
              email={this.state.data.email}
              displayName={this.state.info.displayName}
            />
          )}
        </MultiStepFormWrapper>
        {!this.state.showConfirmation && (
          <div className="container px-4 mx-auto mt-6 mb-16">
            <div className="flex justify-end dedicated-container-margin">
              <button
                className={`mid-button w-full md:w-auto mb-2 md:mb-0 flex justify-center items-center
                  bg-dedicatedDpcSecondary hover:bg-dedicatedDpcHovered ${
                    this.state.isSubmitting && 'btn-disabled'
                  }`}
                onClick={this.triggerFormSubmit}
              >
                {this.state.isSubmitting ? (
                  <div className="inline mr-2">
                    <ButtonLoader />
                  </div>
                ) : null}
                Request Appointment
              </button>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default RequestAppointment;
