import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import withSizes from 'react-sizes';

import { FormTextField } from '../../../../common';
import { store } from '../../../../store';
import { getTextFromLangDict } from '../../../../util';

const InsuranceForm = () => {
  const { watch } = useFormContext();

  // Context Store
  const state = useContext(store);
  const { globalState } = state;
  const { groupCode, langDict, tenantCode } = globalState;

  const hasInsurance = watch('hasInsurance');

  return (
    <>
      {hasInsurance && (
        <>
          <h5 className="dedicated-form-title mb-3">
            {getTextFromLangDict(langDict, {
              key: '_ENTER_INSURANCE_INFORMATION',
              groupCode,
              tenantCode,
            })}
          </h5>
          <div className="flex flex-wrap -mx-4">
            <div className="w-full px-4">
              <FormTextField
                name="insuranceName"
                label={hasInsurance ? 'Insurance Name *' : 'Insurance Name'}
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-4">
            <div className="w-full md:w-1/2 px-4">
              <FormTextField name="insuranceMemberId" label="Member Id" />
            </div>
            <div className="w-full md:w-1/2 px-4">
              <FormTextField name="groupNumber" label="Group Number" />
            </div>
          </div>
          <div className="form-section-divider-md"></div>
        </>
      )}
    </>
  );
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 768,
});

export default withSizes(mapSizesToProps)(InsuranceForm);
