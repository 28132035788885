import React, { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { store } from '../../store';

import { tenantCodeEnum } from '../../constants/tenantCodeConstant';

const DedicatedTenantFooter = () => {
  const state = useContext(store);
  const { globalState } = state;
  const { tenantCode } = globalState;

  const hideTermsLink = useMemo(
    () =>
      [
        tenantCodeEnum.VCARE,
        tenantCodeEnum.VSUITE,
        tenantCodeEnum.GLOBEX,
      ].includes(tenantCode?.toUpperCase()),
    [tenantCode]
  );

  return (
    <div className="w-100 bg-bgColor">
      <div className="container mx-auto flex flex-wrap justify-between items-center py-3 px-4">
        <p className="w-full body-text lg:w-3/4 text-sm mb-2 lg:mb-0 text-center lg:text-left">
          &copy; {new Date().getFullYear()} copyright Vitafy LLC. Powered by{' '}
          <a
            href="https://vitafyhealth.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="font-bold outline-none no-underline"
          >
            Vitafy360
          </a>
          {tenantCode?.toUpperCase() === tenantCodeEnum.CHC && (
            <>
              {' '}
              and{' '}
              <a
                href="https://bmt.vitafyhealth.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="font-bold outline-none no-underline"
              >
                BMT
              </a>
            </>
          )}
        </p>

        {!hideTermsLink && (
          <div className="w-full lg:w-1/4 flex justify-center lg:block lg:text-right">
            <Link
              to={`/${tenantCode?.toLowerCase()}/agreement`}
              target="_blank"
              className="no-underline body-text text-sm font-bold hover:text-dedicatedDpcSecondary"
            >
              Terms of Agreement
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default DedicatedTenantFooter;
