import React, { useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import withSizes from 'react-sizes';
import {
  faBirthdayCake,
  faIdCard,
  faUser,
} from '@fortawesome/free-solid-svg-icons';

import {
  FormTextField,
  FormMaskedDateInput,
  FormMaskedLast4SSNInput,
  FormGenderInput,
  FormErrorMessage,
} from '../../../common';

import { store } from '../../../store';
import commonConstants from '../../../constants/commonConstant';
import { MemberCheckInSchema } from '../../../schemas/appointment';
import { getTextFromLangDict } from '../../../util';

const { MEMBERSHIP_REGISTRATION_TYPES } = commonConstants;

const defaultValues = {
  firstName: '',
  lastName: '',
  dob: '',
  lastSSN: '',
  gender: '',
};

const SearchForm = ({
  errorObj,
  submit,
  searchFormRef,
  isMobile,
  whiteBg = true,
}) => {
  const { noMemberFound, multipleMembersFound } = errorObj;

  const methods = useForm({
    resolver: yupResolver(MemberCheckInSchema),
    defaultValues,
  });
  const { handleSubmit } = methods;

  // Context Store
  const state = useContext(store);
  const { globalState } = state;
  const { tenantId, groupCode, tenantCode, langDict } = globalState;

  const formSubmit = (formValues) => {
    const payload = {
      firstName: formValues.firstName.toLowerCase(),
      lastName: formValues.lastName.toLowerCase(),
      dob: formValues.dob,
      gender: formValues.gender,
      type: MEMBERSHIP_REGISTRATION_TYPES.individual.value,
      tenantId,
      tenantCode,
      tenantGroupCode: groupCode,
    };

    submit(payload);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(formSubmit)} ref={searchFormRef}>
        <h4 className="heading text-xl md:text-2xl mb-6">
          {getTextFromLangDict(langDict, {
            key: '_FIND_MEMBER',
            groupCode,
            tenantCode,
          })}
        </h4>
        <p className="body-text text-sm text-titleColor mb-4">
          {getTextFromLangDict(langDict, {
            key: '_FIND_MEMBER _SUBTITLE',
            groupCode,
            tenantCode,
          })}
        </p>

        <div className="flex flex-wrap -mx-4">
          <div className="w-full md:w-1/2 px-4">
            <FormTextField
              name="firstName"
              label="First Name *"
              icon={isMobile ? '' : faUser}
            />
          </div>
          <div className="w-full md:w-1/2 px-4">
            <FormTextField name="lastName" label="Last Name *" />
          </div>
        </div>
        <div className="flex flex-wrap -mx-4">
          <div className="w-full md:w-1/2 px-4">
            <FormMaskedDateInput
              name="dob"
              label="Date of Birth *"
              icon={isMobile ? '' : faBirthdayCake}
            />
          </div>
          <div className="w-full md:w-1/2 px-4">
            <FormMaskedLast4SSNInput
              name="lastSSN"
              label="Last Four SSN"
              icon={isMobile ? '' : faIdCard}
            />
          </div>
        </div>
        <FormGenderInput name="gender" whiteBg={whiteBg} />
      </form>

      {/* Error/Warning Message */}
      {noMemberFound && (
        <FormErrorMessage
          type="error"
          message={getTextFromLangDict(langDict, {
            key: '_NO_MEMBER_FOUND',
            groupCode,
            tenantCode,
          })}
        />
      )}
      {multipleMembersFound && (
        <FormErrorMessage
          type="warning"
          message={getTextFromLangDict(langDict, {
            key: '_MULTIPLE_MEMBERS_FOUND',
            groupCode,
            tenantCode,
          })}
        />
      )}
    </FormProvider>
  );
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 768,
});

export default withSizes(mapSizesToProps)(SearchForm);
