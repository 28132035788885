import * as httpService from './httpService';
import APICONSTANTS from '../constants';

// Depreciated
export function requestAppointment(data) {
  return httpService
    .post(`${APICONSTANTS.APPOINTMENT}`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function createAppointment(data) {
  return httpService
    .post(`${APICONSTANTS.APPOINTMENTS}`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}
