import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MDTextArea } from '../../common';
import {
  selectActiveQuestion,
  setActiveQuestion,
} from '../../stores/questionaire';
import { _cloneDeep } from '../../util/lodashUtil';

const TextInput = () => {
  const dispatch = useDispatch();

  const activeQuestion = useSelector(selectActiveQuestion);
  const answer = activeQuestion?.answer;

  const onInputChange = (event) => {
    const updatedQuestion = _cloneDeep(activeQuestion);

    updatedQuestion.answer = {
      value: event.target.value,
    };
    dispatch(setActiveQuestion(updatedQuestion));
  };

  const label = activeQuestion?.metadata?.label || 'Enter your answer';

  return (
    <div>
      <MDTextArea
        id="note"
        label={label}
        className="w-full"
        value={answer?.value || ''}
        inputChange={onInputChange}
      />
    </div>
  );
};

export default TextInput;
