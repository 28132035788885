import React from 'react';
import Slider from 'react-slick';
import withSizes from 'react-sizes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileAlt, faEnvelope, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { parseHtml } from '../../util';
import * as vitafyUtils from '../../util/vitafyUtil';
import avatarPlaceholderSquare from '../../assets/images/avatar-placeholder-square.png';

const AgentSlider = (props) => {
  const { agentList } = props;

  const settings = {
    className: 'agentSlider',
    dots: false,
    arrows: props.isIPadPro ? false : true,
    infinite: true,
    speed: 500,
    autoplay: false,
    slidesToShow: props.isMobile ? 1 : (props.isIPad ? 2 : 3),
    slidesToScroll: 1,
    appendDots: (dots) => <ul style={{ marginBottom: props.isMobile ? '5px' : '-30px' }}> {dots} </ul>,
  };

  return (
    <div className='bg-bgColor'>
      <div className="divider"></div>
      <div className='container mx-auto px-4'>
        <Slider {...settings}>
          {agentList && agentList.map((agent, index) =>
            <div className='focus:outline-none px-2' key={index}>
              <Card
                detail={agent}
              />
            </div>
          )}
        </Slider>
      </div>
      <div className="divider"></div>
    </div>
  );
}


const Card = (props) => {
  const { detail } = props;
  return (
    <div className='px-4 py-6 bg-white rounded-md shadow-md'>
      <div className='flex items-start'>
        <div className='w-20 h-20 rounded-full overflow-hidden' >
          <img
            src={detail.metadata?.agent_image?.url ? detail.metadata.agent_image.url : avatarPlaceholderSquare}
            alt="dpc"
            className='w-full h-full object-cover object-center block'
            onError={e => { e.target.onerror = null; e.target.src = `${avatarPlaceholderSquare}` }}
          />
        </div>
        <div className='ml-4'>
          <h4 className='body-text text-titleColor font-bold text-xl mb-0'>{detail.title}</h4>
          <p className='body-text text-titleColor text-sm'>{detail.metadata?.company_name}</p>
          <p className='body-text text-sm'>{detail.metadata?.city}, {detail.metadata?.state}</p>
        </div>
      </div>
      <div className='w-full my-8 h-24 overflow-hidden'>
        <div
          className={`body-text text-titleColor`}
          dangerouslySetInnerHTML={{
            __html: parseHtml(detail.content, 140),
          }}
        />
      </div>
      {detail.metadata && detail.metadata.phone &&
        <a href={`tel:${vitafyUtils.unmaskPhone(detail.metadata.phone)}`} className='inline-block mr-1 p-2 rounded-md cursor-pointer hover:bg-bgColor no-underline focus:outline-none'>
          <FontAwesomeIcon
            icon={faMobileAlt}
            className='text-primary text-lg'
          />
        </a>
      }
      {detail.metadata && detail.metadata.email &&
        <a href={detail.metadata.email} className='inline-block mr- p-2 rounded-md cursor-pointer hover:bg-bgColor  no-underline focus:outline-none'>
          <FontAwesomeIcon
            icon={faEnvelope}
            className='text-primary text-lg'
          />
        </a>
      }
      {detail.metadata && detail.metadata.website &&
        <a href={detail.metadata.website} target='_blank' className='inline-block mr- p-2 rounded-md cursor-pointer hover:bg-bgColor  no-underline focus:outline-none'>
          <FontAwesomeIcon
            icon={faGlobe}
            className='text-primary text-lg'
          />
        </a>
      }
    </div>
  );
}


const mapSizesToProps = ({ width }) => ({
  isMobile: width < 760,
  isIPad: width < 1020,
  isIPadPro: width < 1200
})

export default withSizes(mapSizesToProps)(AgentSlider);