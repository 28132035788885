import React, { Component } from "react";
import { ReactTitle } from "react-meta-tags";
import { FirstContent } from "../../common";
import * as service from "../../service";
import { Loader, ClientStory } from "../../common";
import { Header, Footer } from "../../common";
import Client from "./Client";
import commonConstants from "../../constants/commonConstant";

class WhoWeHelp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      firstContent: {},
      clients: [],
      clientStories: [],
      partners: [],
      seo: {},
    };
  }

  componentDidMount() {
    this.getContent();
    this.getClientStories();
    this.getPartners();
  }

  getContent = () => {
    this.setState({ loading: true });

    service
      .getWhoWeHelpContent()
      .then((result) => {
        if (result.status === "success") {
          this.setState({
            firstContent: result.data.find(
              (item) => item.slug === "who-we-help"
            ),
            clients: result.data.find(
              (item) => item.slug === "who-we-help-list"
            ),
            seo: result.data.find(
              (item) => item.slug === "who-we-help-page-seo"
            )?.metadata?.seo,
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.log("Error in get whoWeHelpContent", error);
        this.setState({ loading: false, error: true });
      });
  };

  getPartners = () => {
    this.setState({ loading: true });

    service
      .getPartners()
      .then((result) => {
        if (result.status === "success") {
          this.setState({
            partners: result.data.find((item) => item.slug === "partners"),
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.log("Error in get homeContent", error);
        this.setState({ loading: false, error: true });
      });
  };

  getClientStories = () => {
    this.setState({ loading: true });

    service
      .getClientStories()
      .then((result) => {
        if (result.status === "success") {
          this.setState({
            clientStories: result.data,
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.log("Error in get homeContent", error);
        this.setState({ loading: false, error: true });
      });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }
    return (
      <>
        <ReactTitle
          title={
            this.state.seo?.metadata?.page_title ||
            commonConstants.SEO.DEFAULT_PAGE_TITLE
          }
        />

        <Header />

        <FirstContent
          title={this.state.firstContent && this.state.firstContent.title}
          text={this.state.firstContent && this.state.firstContent.content}
          image={
            this.state.firstContent.metadata &&
            this.state.firstContent.metadata.banner_image.url
          }
          tagline={
            this.state.firstContent.metadata &&
            this.state.firstContent.metadata.tagline
          }
          taglineVisibility={
            this.state.firstContent.metadata &&
            this.state.firstContent.metadata.tagline_visibility
          }
        />

        <Client
          clients={
            this.state.clients.metadata &&
            this.state.clients.metadata.who_we_help_repeater
          }
          scrollTo={this.props.location.state}
        />

        <ClientStory
          clientStories={this.state.clientStories}
          partners={
            this.state.partners.metadata &&
            this.state.partners.metadata.partners_images
          }
          bgColor={false}
        />

        <Footer />
      </>
    );
  }
}

export default WhoWeHelp;
