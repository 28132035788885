import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import MaskedNumberInput from '../MaskedNumberInput';

const FormMaskedDateInput = ({
  helperText,
  name,
  label,
  showHelperText,
  icon,
  ...others
}) => {
  const {
    control,
    formState: { errors },
    setValue,
    clearErrors,
  } = useFormContext();

  const onSetInput = (value) => {
    setValue(name, value);
    clearErrors(name);
  };

  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <MaskedNumberInput
            {...others}
            {...field}
            id={name}
            label={label}
            icon={icon || ''}
            input={field.value}
            error={!!errors[name]}
            setInput={onSetInput}
          />
        )}
      />
      {showHelperText && (
        <p class="text-xs text-bodyColor -mt-1">{helperText}</p>
      )}
    </>
  );
};

FormMaskedDateInput.defaultProps = {
  showHelperText: false,
  required: false,
  helperText: '',
};

export default FormMaskedDateInput;
