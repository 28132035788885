import React from 'react';
import noResultImg from '../../assets/images/no-result-without-fill.png';

const EmptySearchResult = ({
  marginTopRem = '2rem',
  imageWidthPx = '80px',
  text = 'No result found',
  subText = ''
}) => {
  return (
    <div className='flex flex-col justify-center items-center w-full' style={{ marginTop: marginTopRem, minHeight: "200px" }}>
      <img
        src={noResultImg}
        alt='no-result'
        style={{
          width: imageWidthPx,
          height: 'auto',
        }}
      />
      <p className='body-text mt-4 text-center'>{text}</p>
      {subText &&
        <p className='body-text text-sm mt-1 text-center'>{subText}</p>
      }
    </div>
  );
}

export default EmptySearchResult;