/**
 * @author Sunil Rajbahak <sunil@noveltytechnology.com>
 */

import * as httpService from './httpService';
import APICONSTANTS from '../constants';

export function fetchServices(tenantId, params) {
  return httpService
    .get(`${APICONSTANTS.TENANTS}/${tenantId}/services`, params)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function fetchTenantByGroupCode(queryParams) {
  let params = {};

  if (queryParams?.groupCode) {
    params.groupCode = queryParams?.groupCode;
  }

  if (queryParams?.tenantCode) {
    params.code = queryParams?.tenantCode;
  }

  return httpService
    .get(`${APICONSTANTS.TENANTS}/web`, params)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}
/**
 * params:
 *    recommended: true -> one of the offering will have `recommended` property set to true
 *    type: 'membership'
 *    numberOfEmployee (for Company)
 *    numberOfDependent (for Individual)
 *    dependentType (for individual)
 *    clientId -> to get recommended offering for a specific client
 */
export function fetchMembershipOfferings(tenantId, params) {
  return httpService
    .get(`${APICONSTANTS.TENANTS}/${tenantId}/membership-offerings`, params)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function fetchAncillaryOfferings(tenantId) {
  const queryParams = {
    offset: 0,
    limit: 999,
    status: 'active',
    visibility: 'public',
  };

  return httpService
    .get(`${APICONSTANTS.TENANTS}/${tenantId}/ancillary-offerings`, queryParams)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}
