import React from 'react';

const MultiStepFormWrapper = ({
  header,
  children,
  displayHeader = true,
  encloseInContainer = true,
  align = 'center', // left/center/right
}) => {
  return (
    <>
      {encloseInContainer ? (
        <div className="container px-4 mx-auto">
          <div className="dedicated-lp-form-wrapper">
            {displayHeader && (
              <div
                className={`bg-bgColor py-2 md:py-6 px-3 md:px-6 flex justify-${align} rounded-t-lg`}
              >
                {header}
              </div>
            )}
            <div className="px-3 md:px-6 py-4 md:py-6">{children}</div>
          </div>
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default MultiStepFormWrapper;
