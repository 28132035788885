import React from 'react';
import withSizes from 'react-sizes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faEnvelope,
  faMobileAlt,
  faMapMarkerAlt,
  faLock
} from '@fortawesome/free-solid-svg-icons';
import { useForm } from "react-hook-form";
import {
  MaskedPhoneInput,
  MDInput,
  PlaceAutocomplete,
  ButtonLoader
} from '../../common';
import * as vitafyUtils from '../../util/vitafyUtil';
import commonConstants from "../../constants/commonConstant";

const SmartAdvisorInformation = (props) => {

  const {
    register,
    errors,
    setValue,
    clearError,
    handleSubmit,
    getValues,
  } = useForm();

  const {
    data,
    address,
    formRef,
    chosenPricingPlan,
    paymentInterval
  } = props;

  const formBuilder = () => {
    register({ name: "firstName" }, { required: true });
    register({ name: "lastName" }, { required: true });
    register({ name: "phone" },
      {
        validate: (value) => {
          if (!value) return true;
          return !value.includes("_");
        },
        required: true
      });
    register(
      { name: "email" },
      {
        required: true,
        pattern: /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/i,
      });
    register({ name: "password" }, { required: true });
    register({ name: "confirmPassword" }, { required: true });
    register({ name: "zip", required: true });
    register({ name: "accountRep" }, { required: false });
    setValue([
      { firstName: data.firstName },
      { lastName: data.lastName },
      { phone: data.phone },
      { email: data.email ? data.email : "" },
      { password: data.password },
      { confirmPassword: data.confirmPassword },
      { zip: data.zip },
      { accountRep: data.accountRep }
    ]);
  };

  React.useEffect(() => {
    formBuilder();
  }, []);

  const handleInputChange = (e, field) => {
    let value = e.target ? e.target.value : e;
    if ((e.target ? e.target.validity.valid : e) || value === "") {
      setValue(field, value);
      if (commonConstants.ADDRESS_FIELDS.indexOf(field) > -1) {
        address[field] = value;
      } else {
        data[field] = value;
      }
      clearError(field);
      // handleRemoveErrorMessage();
    }
  };

  const setGoogleAutocompleteAddress = (addr) => {
    for (const field in addr) {
      if (addr.hasOwnProperty(field)) {
        address[field] = addr[field];
        const element = addr[field];
        setValue(field, element);
        clearError(field);
      }
    }
  };

  const formSubmit = (formValues) => {
    formValues.phone = formValues.phone
      ? vitafyUtils.unmaskPhone(formValues.phone)
      : null;
    console.log(formValues);
    // props.submit(formValues);
  }

  return (
    <>
      <h4 className={`font-heebo text-titleColor font-bold text-xl mt-10 mb-6`}>Smart Advisor Information</h4>
      <form ref={formRef} onSubmit={handleSubmit(formSubmit)}>
        <div className='flex flex-wrap -mx-4'>
          <div className='w-full lg:w-1/2 px-4'>
            <MDInput
              id="firstName"
              label="First Name *"
              icon={!props.isMobile ? faUser : null}
              name="firstName"
              value={data?.firstName ? data.firstName : ""}
              inputChange={(e) => handleInputChange(e, "firstName")}
              error={errors.firstName}
            />
          </div>
          <div className='w-full lg:w-1/2 px-4'>
            <MDInput
              id="lastName"
              label="Last Name *"
              name="lastName"
              value={data?.lastName ? data.lastName : ""}
              inputChange={(e) => handleInputChange(e, "lastName")}
              error={errors.lastName}
            />
          </div>
        </div>
        <div className='flex flex-wrap -mx-4'>
          <div className='w-full lg:w-3/5 px-4'>
            <MDInput
              id="email"
              label="Email *"
              icon={!props.isMobile ? faEnvelope : null}
              name="email"
              value={data?.email ? data.email : ""}
              inputChange={(e) => handleInputChange(e, "email")}
              error={errors.email}
            />
          </div>
          <div className='w-full lg:w-2/5 px-4'>
            <MaskedPhoneInput
              input={data?.phone ? data.phone : null}
              setInput={(e) => handleInputChange(e, "phone")}
              name="phone"
              label="Phone *"
              icon={!props.isMobile ? faMobileAlt : null}
              error={errors.phone}
            />
          </div>
        </div>
        <div className='flex flex-wrap -mx-4'>
          <div className='w-full lg:w-1/2 px-4'>
            <MDInput
              id="password"
              label="Password *"
              icon={!props.isMobile ? faLock : null}
              name="password"
              value={data?.password ? data.password : ""}
              inputChange={(e) => handleInputChange(e, "password")}
              error={errors.password}
            />
          </div>
          <div className='w-full lg:w-1/2 px-4'>
            <MDInput
              id="confirmPassword"
              label="Confirm Password *"
              name="confirmPassword"
              value={data?.confirmPassword ? data.confirmPassword : ""}
              inputChange={(e) => handleInputChange(e, "confirmPassword")}
              error={errors.confirmPassword}
            />
          </div>
        </div>
        <div className='flex flex-wrap -mx-4'>
          <div className='w-full lg:w-1/2  px-4'>
            <MDInput
              id="zip"
              label="Zip *"
              name="zip"
              type='number'
              icon={!props.isMobile ? faMapMarkerAlt : null}
              value={address && address.zip ? address.zip : ""}
              inputChange={(e) => handleInputChange(e, "zip")}
              error={errors.zip}
            />
          </div>
          <div className='w-full lg:w-1/2 px-4'>
            <MDInput
              id="accountRep"
              label="Account Rep"
              name="accountRep"
              value={data?.accountRep ? data.accountRep : ""}
              inputChange={(e) => handleInputChange(e, "accountRep")}
              error={errors.accountRep}
            />
          </div>
        </div>
      </form>
    </>
  );
}


const mapSizesToProps = ({ width }) => ({
  isMobile: width < 768,
})

export default withSizes(mapSizesToProps)(SmartAdvisorInformation);