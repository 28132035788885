import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  AGREE_DISAGREE_VALUE,
  PHASE,
  QUESTION_TYPE,
} from '../../constants/questionaire';
import {
  selectActiveQuestion,
  selectPhase,
  selectQuestionaire,
  selectQuestionMap,
  selectQuestionnaireResponseId,
  selectRespondent,
  setActiveQuestion,
  setPhase,
  setQuestionMap,
  setQuestionnaireResponseId,
} from '../../stores/questionaire';
import { _cloneDeep } from '../../util/lodashUtil';
import { momentIsValid } from '../../util/momentUtil';
import {
  formatQuestionaireResponseSavePayload,
  formatQuestionaireResponseUpdatePayload,
} from '../../util/questionaire';
import * as questionnaireAPI from '../../service/questionaireService';

const usePrevNextControl = ({ handleQuestionnaireSubmitSuccess }) => {
  const dispatch = useDispatch();

  const activeQuestion = useSelector(selectActiveQuestion);
  const questionMap = useSelector(selectQuestionMap);
  const phase = useSelector(selectPhase);
  const questionaire = useSelector(selectQuestionaire);
  const respondent = useSelector(selectRespondent);
  const questionnaireResponseId = useSelector(selectQuestionnaireResponseId);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onPrevClick = () => {
    if (!activeQuestion.prevQuestionId) {
      dispatch(setPhase(PHASE.GET_STARTED));
      return;
    }

    if (phase === PHASE.INELIGIBLE) {
      dispatch(setPhase(PHASE.QUESTION));
      return;
    }

    dispatch(setActiveQuestion(questionMap[activeQuestion.prevQuestionId]));
  };

  const handleTransitionToNextQuestion = () => {
    const questionType = activeQuestion.type;
    const nextQuestionId =
      activeQuestion.answer?.nextQuestionId || activeQuestion.nextQuestionId;

    if (!nextQuestionId) {
      handleQuestionnaireSubmitSuccess();
      return;
    }

    if (
      (questionType === QUESTION_TYPE.RADIO && activeQuestion.answer?.isExit) ||
      (questionType === QUESTION_TYPE.CHECKBOX &&
        activeQuestion.answer?.some((item) => !!item.isExit))
    ) {
      dispatch(setPhase(PHASE.INELIGIBLE));
      return;
    }

    const updatedQuestionMap = _cloneDeep(questionMap);
    updatedQuestionMap[nextQuestionId].prevQuestionId = activeQuestion.id;

    if (activeQuestion.answer) {
      updatedQuestionMap[activeQuestion.id].answer = _cloneDeep(
        activeQuestion.answer
      );
    }

    if (activeQuestion.enableAction) {
      updatedQuestionMap[activeQuestion.id].enableAction = _cloneDeep(
        activeQuestion.enableAction
      );
    }

    dispatch(setQuestionMap(updatedQuestionMap));
    dispatch(setActiveQuestion(updatedQuestionMap[nextQuestionId]));
  };

  const saveQuestionaireResponse = async () => {
    const payload = formatQuestionaireResponseSavePayload({
      activeQuestion,
      questionaire,
      respondent,
    });

    try {
      setIsSubmitting(true);
      const response = await questionnaireAPI.saveQuestionaireResponse(payload);

      if (response?.data?.id) {
        dispatch(setQuestionnaireResponseId(response.data.id));

        handleTransitionToNextQuestion();
      } else {
        console.log(
          "Error while saving questionaire: Didn't receive questionnaire response id"
        );
      }
    } catch (err) {
      console.log('Error while saving questionaire', err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const editQuestionaireResponse = async () => {
    const payload = formatQuestionaireResponseUpdatePayload({
      activeQuestion,
      respondent,
    });

    try {
      setIsSubmitting(true);
      await questionnaireAPI.updateQuestionaireResponse(
        questionnaireResponseId,
        payload
      );

      handleTransitionToNextQuestion();
    } catch (err) {
      console.log('Error while updating questionaire', err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onNextClick = () => {
    const isEditMode = !!questionnaireResponseId;

    if (isEditMode) {
      editQuestionaireResponse();
    } else {
      saveQuestionaireResponse();
    }
  };

  const isNextButtonEnabled = () => {
    const questionType = activeQuestion.type;

    if (isSubmitting) {
      return false;
    }

    if (
      questionType === QUESTION_TYPE.RADIO ||
      questionType === QUESTION_TYPE.TEXT
    ) {
      return !!activeQuestion?.answer?.value?.trim();
    }

    if (questionType === QUESTION_TYPE.DATE) {
      const dateInput = activeQuestion?.answer?.value;
      return !!dateInput && momentIsValid(activeQuestion?.answer?.value);
    }

    if (
      questionType === QUESTION_TYPE.CHECKBOX ||
      questionType === QUESTION_TYPE.ATTACHMENT
    ) {
      return activeQuestion?.answer?.length > 0;
    }

    if (questionType === QUESTION_TYPE.AGREE_DISAGREE) {
      const terms = activeQuestion?.options || [];
      const answer = activeQuestion?.answer;
      let allowToProceed = true;

      terms.forEach((item) => {
        if (
          item.isRequired &&
          (!answer ||
            !answer[item.value] ||
            answer[item.value].value !== AGREE_DISAGREE_VALUE.AGREE)
        ) {
          allowToProceed = false;
        }
      });

      return allowToProceed;
    }

    // Always enabled if questionType === QUESTION_TYPE.INFORMATION
    return true;
  };

  const showNextButton = phase !== PHASE.INELIGIBLE;
  const isLastQuestion =
    activeQuestion?.order === questionaire?.questions?.length;

  return {
    showNextButton,
    isLastQuestion,
    isNextButtonEnabled,
    onPrevClick,
    onNextClick,
    isSubmitting,
  };
};

export default usePrevNextControl;
