import React, { useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { MDTextArea, ButtonLoader } from '../../../common';
import { showToast } from '../../../service/toasterService';
import * as transactionService from '../../../service/transactionService';
import { store } from '../../../store';
import { resetAll } from '../../../stores/membership';

const InstructionForm = ({ transactionId }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const state = useContext(store);
  const { globalState } = state;
  const { tenantCode } = globalState;

  const [note, setNote] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setIsSubmitting(true);
      await transactionService.addNote(transactionId, { note });
      showToast(
        'success',
        'Instruction updated successfully for the transaction.'
      );
      setNote('');
    } catch (error) {
      console.log('Error while updating instruction for a transaction', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onHomeClick = () => {
    const homeLink = tenantCode ? `/${tenantCode.toLowerCase()}` : '/';
    history.push(homeLink);
    dispatch(resetAll());
  };

  const isSubmitDisabled = isSubmitting || !note.trim();

  return (
    <>
      <p
        className="mt-5 text-center text-dedicatedDpcSecondary cursor-pointer hover:underline"
        onClick={onHomeClick}
      >
        <FontAwesomeIcon
          icon={faHome}
          className="text-dedicatedDpcSecondary mr-2"
        />
        Go to homepage
      </p>
      <form
        onSubmit={handleSubmit}
        className="mt-6 mb-6"
        style={{ width: '90%' }}
      >
        <div className="flex flex-wrap -mx-4">
          <div className="w-full px-4">
            <MDTextArea
              id="instruction"
              label="Leave a note or instruction"
              className="w-full"
              name="instruction"
              value={note}
              inputChange={(event) => setNote(event.target.value)}
            />
          </div>
        </div>
        <div className="flex justify-end mt-1">
          <button
            className={`small-button w-full md:w-auto mb-2 md:mb-0 flex justify-center items-center bg-dedicatedDpcSecondary hover:bg-dedicatedDpcHovered
                  ${isSubmitDisabled && 'btn-disabled'}`}
            type="submit"
          >
            {isSubmitting ? (
              <div className="inline mr-2">
                <ButtonLoader width="20px" height="20px" />
              </div>
            ) : null}
            <span>Submit</span>
          </button>
        </div>
      </form>
    </>
  );
};

export default InstructionForm;
