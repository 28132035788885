import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import Checkbox from '../Checkbox';

const FormCheckbox = ({ name, label, showHelperText, icon, ...others }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <Checkbox
          {...others}
          {...field}
          id={name}
          label={label}
          icon={icon || ''}
          checked={field.value}
          setChecked={(value) => field.onChange(value)}
          error={!!errors[name]}
        />
      )}
    />
  );
};

FormCheckbox.defaultProps = {
  showHelperText: false,
};

export default FormCheckbox;
