import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import * as appointmentService from '../../../service/appointmentService';
import * as clientService from '../../../service/clientService';

import { ButtonLoader } from '../../../common';
import { MultiStepFormWrapper } from '../common';
import FormBody from './formBody';
import { formatCreateAppointmentPayload } from '../../../util/vitafyUtil';
import { getTextFromLangDict } from '../../../util';

class MemberCheckin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchData: {},
      resultData: {},
      tenantId: '',
      tenantCode: '',
      groupCode: '',
      info: {},
      showResult: false,
      showConfirmation: false,
      isSubmitting: false,
      error: {
        noMemberFound: false,
        multipleMembersFound: false,
        membershipActive: false,
        transactionSuccess: false,
      },
    };
    this.searchFormRef = React.createRef();
  }

  componentDidMount() {
    const { providerDetail, tenantCode, tenantId, groupCode, langDict } =
      this.props;
    this.setState({
      info: providerDetail,
      tenantId,
      tenantCode: tenantCode,
      tenantGroupCode: groupCode,
      langDict,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.providerDetail !== this.props.providerDetail) {
      this.setState({ info: this.props.providerDetail });
    }

    if (
      prevProps.tenantId !== this.props.tenantId ||
      prevProps.groupCode !== this.props.groupCode ||
      prevProps.tenantCode !== this.props.tenantCode ||
      prevProps.langDict !== this.props.langDict
    ) {
      this.setState((prevState) => ({
        ...prevState,
        tenantId: this.props.tenantId,
        groupCode: this.props.groupCode,
        tenantCode: this.props.tenantCode,
        langDict: this.props.langDict,
      }));
    }
  }

  triggerSearchFormSubmit = () => {
    this.searchFormRef.current.dispatchEvent(
      new Event('submit', { cancelable: true })
    );
  };

  goBackToSearchHandler = () => {
    this.setState({
      error: {
        noMemberFound: false,
        multipleMembersFound: false,
      },
      searchData: {},
      resultData: {},
      showResult: false,
    });
  };

  /**
   * Only if the search result contain single member, navigate to search result
   * In case, no member or multiple members found, display respective error and warning messages
   */
  handleSearchSubmit = (formValues) => {
    this.setState({ isSubmitting: true });

    clientService
      .checkIfClientExists(formValues)
      .finally(() => this.setState({ isSubmitting: false }))
      .then((response) => {
        const { client, exists, count, membershipActive, transactionSuccess } =
          response.data;
        this.setState(
          {
            resultData: exists ? client : {},
            error: {
              noMemberFound: !exists,
              membershipActive,
              transactionSuccess,
              multipleMembersFound: exists && count > 1,
            },
          },
          () => {
            const { noMemberFound, multipleMembersFound } = this.state.error;
            if (!noMemberFound && !multipleMembersFound)
              this.setState({ showResult: true });
          }
        );
      })
      .catch((error) =>
        console.log('Error while searching member for check-in', error)
      );
  };

  handleCheckin = () => {
    const { tenantId, tenantCode, groupCode } = this.state;

    const payload = formatCreateAppointmentPayload({
      ...this.state.resultData,
      tenantId,
      tenantCode,
      tenantGroupCode: groupCode,
    });

    this.setState({ isSubmitting: true });
    appointmentService
      .createAppointment(payload)
      .finally(() => this.setState({ isSubmitting: false }))
      .then(() => {
        this.setState({ showConfirmation: true });
      })
      .catch((error) => console.log('Error while member check-in', error));
  };

  handleRemoveErrorMessage = () => {
    this.setState({
      error: {
        noMemberFound: false,
        multipleMembersFound: false,
      },
    });
  };

  render() {
    const { langDict, groupCode, tenantCode } = this.state;

    return (
      <>
        <MultiStepFormWrapper displayHeader={false}>
          <FormBody
            showResult={this.state.showResult}
            searchFormRef={this.searchFormRef}
            searchData={this.state.searchData}
            resultData={this.state.resultData}
            info={this.state.info}
            handleSearchSubmit={this.handleSearchSubmit}
            showConfirmation={this.state.showConfirmation}
            errorObj={this.state.error}
            handleRemoveErrorMessage={this.handleRemoveErrorMessage}
          />
        </MultiStepFormWrapper>

        {!this.state.showConfirmation && (
          <div className="container px-4 mx-auto mt-6 mb-16">
            {/* Search Button for Search Record*/}
            {!this.state.showResult && (
              <div className="flex justify-end dedicated-container-margin">
                <button
                  className={`mid-button w-full md:w-auto mb-2 md:mb-0 flex justify-center items-center
                  bg-dedicatedDpcSecondary hover:bg-dedicatedDpcHovered ${
                    this.state.isSubmitting && 'btn-disabled'
                  }`}
                  onClick={this.triggerSearchFormSubmit}
                >
                  {this.state.isSubmitting ? (
                    <div className="inline mr-2">
                      <ButtonLoader />
                    </div>
                  ) : null}
                  <FontAwesomeIcon
                    icon={faSearch}
                    className="text-white mr-3"
                  />
                  {getTextFromLangDict(langDict, {
                    key: '_SEARCH',
                    groupCode,
                    tenantCode,
                  })}
                </button>
              </div>
            )}
            {/* Check-in and Go back to search buttons for Search Result*/}
            {this.state.showResult && (
              <div className="flex flex-wrap md:flex-row-reverse justify-between items-center dedicated-container-margin">
                <button
                  className={`mid-button w-full md:w-auto mb-2 md:mb-0 flex justify-center items-center
                 bg-dedicatedDpcSecondary hover:bg-dedicatedDpcHovered ${
                   this.state.isSubmitting && 'btn-disabled'
                 }`}
                  onClick={this.handleCheckin}
                >
                  {this.state.isSubmitting ? (
                    <div className="inline mr-2">
                      <ButtonLoader />
                    </div>
                  ) : null}
                  <span>
                    {getTextFromLangDict(langDict, {
                      key: '_CHECK_IN',
                      groupCode,
                      tenantCode,
                    })}
                  </span>
                </button>
                <button
                  className={`mid-button w-full md:w-auto px-4 text-dedicatedDpcSecondary bg-transparent hover:bg-gray-300
                 ${this.state.isSubmitting && 'btn-disabled'}`}
                  onClick={this.goBackToSearchHandler}
                >
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    className="text-dedicatedDpcSecondary mr-3"
                  />
                  {getTextFromLangDict(langDict, {
                    key: '_GO_BACK_TO_SEARCH',
                    groupCode,
                    tenantCode,
                  })}
                </button>
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}

export default MemberCheckin;
