import * as httpService from './httpService';
import APICONSTANTS from '../constants';

/**
 *
 * @param queryParams
 * queryparams = { limit, offset, keyword, type, tenantId, groupCode, isAdvancedSearch }
 * type = Individual/Group
 *
 */
export function fetchClients(queryParams) {
  return httpService
    .get(`${APICONSTANTS.CLIENTS}`, queryParams)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function checkIfMemberExists(queryParams) {
  return httpService
    .get(`${APICONSTANTS.CLIENTS}/${APICONSTANTS.MEMBERS}`, queryParams)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

// if (req.body.enrollmentType === 'membership') membership checkout
// else walk-in checkout
export function checkout(enrollmentType, data) {
  return httpService
    .post(`${APICONSTANTS.CLIENTS}/${enrollmentType}/checkout`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function fetchCalculatedOfferings(tenantId, queryParams) {
  return httpService
    .get(
      `${APICONSTANTS.TENANTS}/${tenantId}/membership-offerings/clients`,
      queryParams
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function addClient(data) {
  return httpService
    .post(`${APICONSTANTS.CLIENTS}`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function editClient(clientId, data) {
  return httpService
    .put(`${APICONSTANTS.CLIENTS}/${clientId}`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

// Send ids separated by comma for bulk delete
// For eg: /api/clients/id1,id2,id3
export function removeClient(clientId) {
  return httpService
    .destroy(`${APICONSTANTS.CLIENTS}`, clientId)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function saveClientEnrollments(clientId, data) {
  return httpService
    .post(`${APICONSTANTS.CLIENTS}/${clientId}/enrollments`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function saveClientAncillaryEnrollments(clientId, data) {
  return httpService
    .post(`${APICONSTANTS.CLIENTS}/${clientId}/ancillary-services`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function bulkSaveClientEnrollments(clientId, data) {
  return httpService
    .put(`${APICONSTANTS.CLIENTS}/${clientId}/bulk/enrollments`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function savePaymentInformation(clientId, data) {
  return httpService
    .post(
      `${APICONSTANTS.MERCHANT_CUSTOMERS}/${clientId}/payment-sources`,
      data
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function checkIfClientExists(data) {
  return httpService
    .post(`${APICONSTANTS.CLIENTS}/exists`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}
