import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import Select from 'react-select';
import commonConstants from '../../../../constants/commonConstant';
import { getFullName } from '../../../../util/vitafyUtil';

const { MEMBER_TYPE } = commonConstants;

const FormPrimaryMemberSelect = ({ members, isDisabled = false }) => {
  const {
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const watchedPrimaryMember = watch('primaryMember');

  const primaryMembers = useMemo(() => {
    return members
      ?.filter((item) => item.relationship === MEMBER_TYPE.PRIMARY)
      ?.map((item) => ({
        label: getFullName({
          firstName: item.firstName,
          middleName: item.middleName,
          lastName: item.lastName,
        }),
        value: item.clientId,
        clientEnrollmentId: item.clientEnrollmentId,
      }));
  }, [members]);

  return (
    <>
      <label className="body-text text-sm mb-1">Select Primary Member</label>
      <Select
        id="select-primary-member"
        isDisabled={isDisabled}
        value={{
          label: watchedPrimaryMember?.fullName || '',
          value: watchedPrimaryMember?.clientId || '',
          clientEnrollmentId: watchedPrimaryMember?.clientEnrollmentId || '',
        }}
        onChange={(option) => {
          setValue('primaryMember', {
            clientId: option?.value,
            fullName: option?.label,
            clientEnrollmentId: option?.clientEnrollmentId,
          });
          clearErrors('primaryMember');
        }}
        options={primaryMembers}
        isClearable
        instanceId="sth"
      />
      {!!errors.primaryMember && (
        <p className="text-red-600 text-sm mt-3">
          Please select primary member.
        </p>
      )}
    </>
  );
};

export default FormPrimaryMemberSelect;
