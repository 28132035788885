import React, { useEffect } from 'react';

import { Loader } from '../../common';

import commonConstants from '../../constants/commonConstant';

const { PRIVACY_POLICY } = commonConstants.VITAFY_AGREEMENTS;

const PrivacyPolicy = (props) => {
  useEffect(() => {
    window.location.href = PRIVACY_POLICY;
  }, []);

  return <Loader />;
};

export default PrivacyPolicy;
