import React, { useState, useEffect } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';

import {
  getCardExpiryText,
  getCardNumberDisplayText,
} from '../../../../util/vitafyUtil';
import { FormErrorMessage } from '../../../../common';
import { useRef } from 'react';
const { colors } = require('tailwindcss/defaultTheme');

const CARD_ELEMENT_OPTIONS = {
  iconStyle: 'solid',
  hidePostalCode: true,
  style: {
    base: {
      iconColor: colors.gray[400],
      color: colors.gray[800],
      fontSize: '18px',
      fontFamily: '"Heebo", sans-serif',
      fontSmoothing: 'antialiased',
      '::placeholder': {
        color: colors.gray[400],
      },
    },
    invalid: {
      color: colors.red[500], // Take this color from tailwindconfig (errorTxt)
      ':focus': {
        color: colors.gray[800],
      },
    },
  },
};

export const CardPayment = ({
  filledCardData,
  onChangeData,
  onAddNewCardClick,
  cardErrorMessage,
  errorMessage,
  clearError,
  isSubmitting: isSubmittingCheckout,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [inputError, setInputError] = useState('');

  const clearErrorRef = useRef(clearError);

  /**
   * Clear error on mount (needed when one switches payment methods)
   */
  useEffect(() => {
    clearErrorRef.current();
  }, []);

  const onBlur = async () => {
    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardElement);

    if (!card) {
      return;
    }

    const result = await stripe.createToken(card);
    if (result.error) {
      setInputError(result.error.message || '');
    } else {
      const { card: cardDetail } = result.token;

      onChangeData('card', {
        token: result.token.id,
        cardNumber: getCardNumberDisplayText(cardDetail?.last4 || ''),
        expiry: getCardExpiryText(
          cardDetail?.exp_month?.toString() || '',
          cardDetail?.exp_year?.toString() || ''
        ),
      });
    }
  };

  const clearInputError = () => {
    setInputError('');
    clearError();
  };

  const handleAddNewCardInfo = () => {
    onAddNewCardClick();
    setInputError('');
  };

  const renderedFilledCardInfo = (
    <div className="mt-4">
      <div className="flex justify-between items-center">
        <p className="body-text mb-3 font-semibold">
          Use recently entered Card Information
        </p>
        <button
          className={`px-4 py-2 rounded-md hover:bg-orange-200 cursor-pointer transition-all duration-150 ${
            isSubmittingCheckout && 'btn-disabled'
          }`}
          onClick={handleAddNewCardInfo}
          type="button"
        >
          <p className="text-secondary font-medium">
            <FontAwesomeIcon icon={faCreditCard} className="mr-2" />
            Edit Card
          </p>
        </button>
      </div>
      <p className="body-text mb-1">
        Card Number&nbsp; :{' '}
        <span className="text-titleColor">{filledCardData?.cardNumber}</span>
      </p>
      <p className="body-text">
        Expiration &nbsp; &nbsp; &nbsp; &nbsp;:{' '}
        <span className="text-titleColor">{filledCardData?.expiry}</span>
      </p>
    </div>
  );

  return (
    <div className="mb-10">
      {filledCardData?.token ? (
        renderedFilledCardInfo
      ) : (
        <div className="my-1 p-3 border border-solid border-gray-400 rounded-sm bg-white">
          <CardElement
            onBlur={onBlur}
            onChange={clearInputError}
            options={CARD_ELEMENT_OPTIONS}
          />
        </div>
      )}

      {!!inputError && <FormErrorMessage type="error" message={inputError} />}
      {!!cardErrorMessage && (
        <FormErrorMessage type="warning" message={cardErrorMessage} />
      )}
      {errorMessage ? (
        <FormErrorMessage type="error" message={`Error: ${errorMessage}`} />
      ) : null}
    </div>
  );
};
