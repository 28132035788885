/**
 * @author Slesha Tuladhar <slesha@noveltytechnology.com>
 */

import * as moment from 'moment';
import config from '../config';

export const momentSaveFormat = (date) => {
  return date
    ? moment(date).format(config.DATE_FORMAT_SAVE)
    : moment().format(config.DATE_FORMAT_SAVE);
};

export const momentViewFormat = (date) => {
  return date
    ? moment(date).format(config.DATE_FORMAT_VIEW)
    : moment().format(config.DATE_FORMAT_VIEW);
};

export const momentIsValid = (date) => {
  return moment(date).isValid();
};

export const momentIsSameorBefore = (date, unit = null) => {
  return moment(date).isSameOrBefore(moment(), unit);
};

export const momentIsSame = (date, unit = null) => {
  return moment(date).isSame(moment(), unit);
};

export const momentIsSameorAfter = (date) => {
  return moment(date).isSameOrAfter(moment());
};

export const dobIsValid = (date, dateFormat = config.DATE_FORMAT_VIEW) => {
  if (moment(date).isValid()) {
    const isAfterPresentDate = moment(date, dateFormat).isAfter(new Date());
    return !isAfterPresentDate;
  }
  return false;
};

export const localToUtc = (date) => {
  if (!date) return date;
  return moment.utc(date).format();
};

export const utcTime = (date) => {
  if (!date) return date;
  return moment.utc(date).format('LT');
};

export const momentIsBeforeNMonth = (date, month) => {
  if (!date) return null;
  return moment(date).isBefore(moment().subtract(month, 'months'));
};

export const validateDob = (dob, minAge = 0, maxAge = 120) => {
  let isValidDob = false;

  if (
    moment(dob, config.dateViewFormat).isBefore() &&
    moment().diff(moment(dob, config.dateViewFormat), 'years') <= maxAge
  ) {
    isValidDob = true;
  }

  if (
    minAge > 0 &&
    moment().diff(moment(dob, config.dateViewFormat), 'years') >= minAge
  ) {
    isValidDob = true;
  }

  return isValidDob;
};

export const validateMaskedInput = (value) => {
  if (!value) return true;
  return !value.includes('_');
};
