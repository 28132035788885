import React from 'react';
import { DedicatedDpcHeader, DedicatedDpcFooter } from '../common';
import { getSubDomain } from '../util/miscellaneousUtil';
import subDomainMap from '../constants/subdomainMap';

class DedicatedDpcLayout extends React.Component {
  render() {
    const themeClassName =
      subDomainMap[getSubDomain()]?.themeClass || 'theme-dpc';

    return (
      <div className={`${themeClassName} flex flex-col min-h-screen`}>
        <DedicatedDpcHeader />
        <main className="flex-1">
          {this.props.children}
          <div className="h-12"></div>
        </main>
        <DedicatedDpcFooter />
      </div>
    );
  }
}

export default DedicatedDpcLayout;
