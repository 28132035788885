import React, { useState } from 'react';
import {
  Elements,
  useStripe,
  useElements,
  CardElement,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { MaskedCurrencyInput, Checkbox } from '../../common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStripe } from '@fortawesome/free-brands-svg-icons';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { ButtonLoader } from '../../common/loader';

const stripePromise = loadStripe('pk_test_PVCDHvvNNKJKXZA2QmDNFO1V002Vn0jD6V');

const PriceListCheckout = (props) => {

  const [monthlyCostError, setMonthlyCostError] = useState(false);

  return (
    <div className="mt-8">
      <p className="body-text font-bold">Payment Summary</p>

      <div className={`w-full bg-white px-6 py-6 rounded-sm mt-5`}>
        <div className="flex justify-between items-center mb-3">
          <p className="body-text">Total Price</p>
          {props.activeOption === 'Others' ?
            <div className='w-25'>
              <MaskedCurrencyInput
                input={props.monthlyCost ? props.monthlyCost : ''}
                setInput={(e) => {
                  props.changeMonthlyCost(e.trim());
                  setMonthlyCostError(false);
                }}
                name='monthlyCost'
                label='Enter monthly cost*'
                error={monthlyCostError}
              />
            </div>
            :
            <h6 className="body-text text-2xl text-titleColor my-3">{`$${props.monthlyCost}`}</h6>
          }
        </div>

        <p className="body-text text-xl text-titleColor font-bold">
          Card Information
        </p>

        <div className="mt-4">
          <Elements stripe={stripePromise}>
            <CheckoutForm {...props} setMonthlyCostError={setMonthlyCostError} />
          </Elements>
        </div>
      </div>
    </div>
  );
};

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const { iAgreeTerms, changeIAgreeTerms, priceListFormSubmit,
    handleBackBtnPress, walkinLoading, changeWalkinLoading } = props;

  const CARD_OPTIONS = {
    iconStyle: 'solid',
    style: {
      base: {
        iconColor: '#b8b8b8',
        color: '#333',
        fontFamily: 'Heebo',
        fontSize: '20px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': { color: '#333' },
        '::placeholder': { color: '#d4d4d4' },
      },
      invalid: {
        iconColor: '#FF4136',
        color: '#ffc7ee',
      },
    },
  };


  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    changeWalkinLoading(true);
    const { token, error } = await stripe.createToken(
      elements.getElement(CardElement),
      {}
    );
    if (error) {
      console.log('[Error from Stripe]', error);
      changeWalkinLoading(false);
      return;
    }

    // check if the monthyCost is not null
    if (!props.monthlyCost) {
      props.setMonthlyCostError(true);
      changeWalkinLoading(false);
      return;
    }

    priceListFormSubmit(token);
  };

  return (
    <>
      <form onSubmit={handleSubmit} >
        <div className="border border-gray-400 px-4 py-3">
          <CardElement options={CARD_OPTIONS} />
        </div>

        <div className="mt-5 flex justify-between items-center">
          <Checkbox
            checked={iAgreeTerms}
            setChecked={(e) => changeIAgreeTerms(e)}
            className="inline"
          >
            <p className="font-heebo text-base text-titleColor mb-3 select-none inline-block">
              I agree with{' '}
              <a
                href="https://vitafymed.com/terms-of-use"
                target="_blank"
                className="no-underline font-bold text-secondary focus:outline-none"
              >
                {' '}
              terms & policy
            </a>
            </p>
          </Checkbox>

          <div className="flex items-center">
            <p className="body-text text-sm mr-1"> Powered by </p>
            <FontAwesomeIcon
              icon={faStripe}
              size="2x"
              className="block"
              style={{ color: '#6772E5' }}
            />
          </div>
        </div>

        <div className="w-full flex justify-between items-center mt-6">
          <button
            className={`group bg-gray-400 ease-out block text-bodyColor font-heebo text-lg px-8 py-3 rounded-full focus:outline-none hover:text-white hover:bg-secondary`}
            onClick={() => handleBackBtnPress()}
          >
            <FontAwesomeIcon
              icon={faArrowLeft}
              className={`text-bodyColor mr-3 group-hover:text-white `}
            />
          Back
        </button>
          <button
            className={`mid-button mt-6 mb-1 ${!iAgreeTerms || walkinLoading ? 'btn-disabled' : null} `}
            type='submit'
          //   onClick={formSubmitHandler}
          >
            <div className="flex items-center">
              {/* if loading, show the loader */}
              {walkinLoading ? (
                <div className="mr-2">
                  <ButtonLoader />
                </div>
              ) : null}
              <div>
                Checkout
              <FontAwesomeIcon
                  icon={faArrowRight}
                  className="text-white ml-3"
                />
              </div>
            </div>
          </button>
        </div>
      </form>
    </>
  );
};

export default PriceListCheckout;
