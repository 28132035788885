const offeringConstant = {
  RAPID_TEST: {
    offeringId: {
      test: '7E9A8462-05A6-4F11-AE9B-5812723D48AE',
      live: '86EC6EC8-C863-4997-88E6-39DA6DA0BA98',
    },
    offeringCode: 'COVID19RAPIDTEST',
    name: 'CoVID-19 Rapid Test',
    cost: 80,
  },
  PCR: {
    offeringId: {
      test: '4B2F133A-FE92-492D-8638-5D303E18C494',
      live: '03F605BF-03ED-46F2-AF0D-650EB2A23E27',
    },
    offeringCode: 'COVID19PCR',
    name: 'CoVID-19 PCR',
    cost: 100,
  },
  TYPE: {
    MEMBERSHIP: 'Membership',
    ANCILLARY: 'Ancillary',
  },
};

export default offeringConstant;

// 86EC6EC8-C863-4997-88E6-39DA6DA0BA98(COVID19RAPIDTEST)
// 03F605BF-03ED-46F2-AF0D-650EB2A23E27(COVID19PCR)
