import * as httpService from './httpService';
import APICONSTANTS from '../constants';

export function addNote(transactionId, data) {
  return httpService
    .patch(`${APICONSTANTS.TRANSACTIONS}/${transactionId}/notes`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}
