/**
 * @author Slesha Tuladhar <slesha@noveltytechnology.com>
 */

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMobileAlt,
  faEnvelope
} from '@fortawesome/free-solid-svg-icons';
import facebookImg from '../../assets/images/facebook.png';
import TwitterImg from '../../assets/images/twitter.png';
import LinkedInImg from '../../assets/images/linkedin.png';
import * as vitafyUtils from '../../util/vitafyUtil';
import PlaceholderImage from '../../assets/images/placeholder-avatar.png';
import DefaultImage from '../../assets/images/enrollment-default-logo.png';
import routeAliasConstant from '../../constants/routeAliasConstant';
import { parseHtml } from '../../util';

const AgentInfo = (props) => {
  const { agentInfo, comingFrom, vcareCosmicContent } = props;

  let comingFromEnrollNow = props.location.pathname === '/member/enroll' ? true : false;

  const imageSource = comingFrom === routeAliasConstant.VCARE ?
    (vcareCosmicContent && vcareCosmicContent.image ? vcareCosmicContent.image.url : DefaultImage)
    :
    (comingFromEnrollNow ? DefaultImage : agentInfo?.imageUrl ? agentInfo.imageUrl : PlaceholderImage);

  return (
    <>
      <div className='w-full pt-24 lg:pt-30 pb-10 lg:pb-10 bg-extended'>
        {/* NAME AND DESIGNATION STARTS HERE */}
        <div className='container mx-auto px-4'>
          <h1 className='font-heebo font-bold text-white text-3xl lg:text-5xl'>
            {comingFrom === routeAliasConstant.VCARE ?
              vcareCosmicContent.metadata && vcareCosmicContent.metadata.title && vcareCosmicContent.metadata.title
              :
              <span className='uppercase'>{agentInfo?.id && vitafyUtils.getFullName(agentInfo)}</span>
            }
          </h1>
          <h6 className='firstContentHeading text-base lg:text-lg mt-0 pt-0'>
            {comingFrom === routeAliasConstant.VCARE ?
              vcareCosmicContent.metadata && vcareCosmicContent.metadata.subtitle && vcareCosmicContent.metadata.subtitle
              :
              agentInfo?.id && vitafyUtils.getDisplayName(agentInfo)
            }
          </h6>
        </div>
        {/* NAME AND DESIGNATION ENDS HERE */}
      </div>
      <div className='container mx-auto py-6 lg:pt-8 lg:pb-16 flex flex-wrap flex-row lg:flex-row-reverse px-4'>
        {/* IMAGE STARTS HERE */}
        <div className='w-3/4 sm:w-2/5 lg:w-2/5  mx-auto -mt-10 sm:-mt-16 lg:-mt-32 xl:-mt-48 mb-10 lg:mb-0 lg:pl-16 xl:pl-40'>
          <div className='w-full bg-white shadow-md rounded-lg'>
            <div className='w-full pb-full rounded-t-lg relative'>
              <img
                src={imageSource}
                className='absolute w-full h-full object-cover object-center rounded-t-lg'
                alt='profile-img'
              />
            </div>
            {comingFrom !== routeAliasConstant.VCARE &&
              <>
                {agentInfo && (agentInfo.facebookUrl || agentInfo.twitterUrl || agentInfo.linkedInUrl) &&
                  <div className='py-5 flex justify-center items-center -mx-3'>
                    {agentInfo.facebookUrl &&
                      <a
                        href={agentInfo.facebookUrl}
                        className='no-underline focus:outline-none block px-3'
                      >
                        <img
                          src={facebookImg}
                          className='w-8 xl:w-10'
                          alt='facebook-link'
                        />
                      </a>
                    }
                    {agentInfo.twitterUrl &&
                      <a
                        href={agentInfo.twitterUrl}
                        className='no-underline focus:outline-none block px-3'
                      >
                        <img
                          src={TwitterImg}
                          className='w-8 xl:w-10'
                          alt='twiiter-link'
                        />
                      </a>
                    }
                    {agentInfo.linkedInUrl &&
                      <a
                        href={agentInfo.linkedInUrl}
                        className='no-underline focus:outline-none block px-3'
                      >
                        <img
                          src={LinkedInImg}
                          className='w-8 xl:w-10'
                          alt='linkedin-link'
                        />
                      </a>
                    }
                  </div>
                }
              </>
            }
          </div>
        </div>
        {/* IMAGE ENDS HERE */}

        <div className='w-full lg:w-3/5 lg:pr-0'>
          {/* ADDITIONAL INFO (PHONE/EMAIL) STARTS HERE */}
          <div className='flex flex-wrap mb-6 items-center'>
            <div className='w-full md:w-1/2 mb-4 md:mb-0'>
              <div className='flex items-center'>
                <FontAwesomeIcon
                  icon={faMobileAlt}
                  className='text-xl lg:text-2xl text-primary block'
                />
                <a href={`tel:${agentInfo.phone}`} className='no-underline focus:outline-none'>
                  <h6 className='ml-3 text-lg'>{agentInfo ? vitafyUtils.getPhoneDisplayText(agentInfo.phone, agentInfo?.countryCode) : 'N/A'}</h6>
                </a>
              </div>
            </div>
            <div className='w-full md:w-1/2 flex md:justify-end'>
              <div>
                <a
                  href={agentInfo && agentInfo.email ? `mailto:${agentInfo.email}` : ''}
                  className='block focus:outline-none no-underline'
                >
                  <div className='flex items-center'>
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className='text-xl lg:text-2xl text-primary block'
                    />
                    <h6 className='ml-3 text-lg'>{agentInfo && agentInfo.email ? agentInfo.email : 'N/A'}</h6>
                  </div>
                </a>
              </div>
            </div>
          </div>
          {/* ADDITIONAL INFO ENDS HERE */}
          <div className='divider-line mb-8'></div>
          {/* SHORT DESCRIPTION STARTS HERE */}
          <div className='font-heebo text-bodyColor text-lg'>
            {comingFrom === routeAliasConstant.VCARE ?
              <div
                dangerouslySetInnerHTML={{
                  __html: parseHtml(vcareCosmicContent.metadata && vcareCosmicContent.metadata.description),
                }}
              />
              :
              agentInfo && agentInfo.bio
            }

          </div>
          {/* SHORT DESCRIPTION ENDS HERE */}
        </div>
      </div>
    </>
  );
}

export default AgentInfo;
