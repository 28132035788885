import React, { useMemo } from 'react';

import {
  checkIsMultipleLocationRoute,
  getWebsiteInfo,
} from '../util/vitafyUtil';
import { checkIsSubDomain } from '../util/miscellaneousUtil';

import routeTypeConstant from '../constants/routeTypeConstant';

import RouterRoute from './index';
import DedicatedTenantRouterRoute from './dedicatedTenantRoute';
import DedicatedDpcRouterRoute from './dedicatedDpcRoute';
import MultipleLocationLandingRoute from './multipleLocationLandingRoute';
import { useLocation } from 'react-router-dom';
import { getSubstringBetweenChars } from '../util';

const windowHostUrl = window.location.host;
const isSubdomain = checkIsSubDomain(windowHostUrl);

const SelectRoute = () => {
  const websiteInfo = getWebsiteInfo();
  const routeType = websiteInfo?.routeType || '';
  const tenantCode = websiteInfo?.tenantCode || '';

  const location = useLocation();

  const tenantCodeURL = getSubstringBetweenChars(
    location.pathname?.substring(1)?.toUpperCase() || '',
    '/'
  );

  const renderMultipleLocationRoute = useMemo(() => {
    const isMultipleLocationsRoute = checkIsMultipleLocationRoute(
      routeType,
      tenantCode,
      tenantCodeURL
    );
    if (isMultipleLocationsRoute) {
      return true;
    }

    return false;
  }, [routeType, tenantCode, tenantCodeURL]);

  const renderDpcTenantRoute = useMemo(() => {
    if (routeType === routeTypeConstant.DPC) {
      return true;
    }

    if (
      routeType === routeTypeConstant.MULTIPLE_LOCATION &&
      tenantCode?.includes(tenantCodeURL)
    ) {
      return true;
    }

    return false;
  }, [routeType, tenantCode, tenantCodeURL]);

  const renderAdvocateTenantRoute = routeType === routeTypeConstant.ADVOCATE;

  return (
    <>
      {/* Vitafy Health website (along with dev-vitafyhealth.com) */}
      {!isSubdomain && <RouterRoute />}

      {/** DPC tenant types like ktmdpc.vitafyhealth.com (previously dedicatedDpc) */}
      {renderDpcTenantRoute && (
        <DedicatedDpcRouterRoute tenantCode={tenantCode[0].toLowerCase()} />
      )}

      {/** ADVOCATE tenant types like medishield.vitafyhealth.com (previously dedicatedTenant) */}
      {renderAdvocateTenantRoute && (
        <DedicatedTenantRouterRoute tenantCode={tenantCode[0].toLowerCase()} />
      )}

      {/** Landing page for multiple locations like seaisland.vitafyhealth.com */}
      {renderMultipleLocationRoute && <MultipleLocationLandingRoute />}
    </>
  );
};

export default SelectRoute;
