import React, { useState, useContext, useMemo } from 'react';
import withSizes from 'react-sizes';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import commonConstants from '../../../../constants/commonConstant';

import {
  selectInformationTab,
  setInformationTabValues,
} from '../../../../stores/membership';
import CompanyForm from './CompanyForm';
import IndividualForm from './IndividualForm';
import { checkIsCompany, checkIsIndividual } from '../../../../util/vitafyUtil';
import ReferringAgentSelect from './ReferringAgentSelect';
import { store } from '../../../../store';
import { getTextFromLangDict } from '../../../../util';
import {
  checkEnrollmentAffiliateVisibleTenantSetting,
  checkEnrollmentTenantSetting,
  getEnrollmentTenantSetting,
} from '../../../../util/tenantSettingUtil';
import ReferringClientSelect from './ReferringClientSelect';

const { MEMBERSHIP_REGISTRATION_TYPES, TENANT_SETTINGS_CODE } = commonConstants;

const Information = ({
  refs,
  misc,
  isMobile,
  isSmallerThanIPad,
  showReferringAgentField = true,
  onSubmit,
}) => {
  const { individualInfoFormRef, companyInfoFormRef } = refs;
  const { whiteBg } = misc;

  const dispatch = useDispatch();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const enrollmentType = queryParams.get('type');
  const referredByQueryParam = queryParams.get('referredby'); // gives referring clientId/agentId

  // Redux Store
  const informationTab = useSelector(selectInformationTab);

  // Context Store
  const state = useContext(store);
  const { globalState } = state;
  const { langDict, groupCode, isLoading, tenantCode, tenantSettings } =
    globalState;

  const { type, clientId } = informationTab;

  const [referredBy, setReferredBy] = useState({
    label: informationTab?.referredByName ?? '',
    value: informationTab?.referredById ?? '',
  });

  const isIndividual = checkIsIndividual(type);
  const isCompany = checkIsCompany(type);

  const enrollmentTypesFromSettings = getEnrollmentTenantSetting(
    TENANT_SETTINGS_CODE.CLIENT_ENROLLMENT_TYPE,
    { tenantSettings }
  );

  const showIndividualOption = enrollmentTypesFromSettings?.some(
    (item) => item === MEMBERSHIP_REGISTRATION_TYPES.individual.value
  );
  const showCompanyOption = enrollmentTypesFromSettings?.some(
    (item) => item === MEMBERSHIP_REGISTRATION_TYPES.company.value
  );

  const isAffiliateVisible = useMemo(
    () => checkEnrollmentAffiliateVisibleTenantSetting(tenantSettings),
    [tenantSettings]
  );

  // Individual button is disabled if either
  // 1. client of type 'Company' has been created or
  // 2. there is info for 'Company' from the query param or
  // 3. Only Individual option is enabled from the tenant settings
  const disableIndividualButton =
    (!!clientId && isCompany) ||
    enrollmentType?.toLowerCase() ===
      MEMBERSHIP_REGISTRATION_TYPES.company.value.toLowerCase() ||
    (showIndividualOption && !showCompanyOption);
  // Company button is disabled if either
  // 1. client of type 'Individual' has been created or
  // 2. there is info for 'Individual' from the query param or
  // 3. Only Company option is enabled from the tenant settings
  const disableCompanyButton =
    (!!clientId && isIndividual) ||
    enrollmentType?.toLowerCase() ===
      MEMBERSHIP_REGISTRATION_TYPES.individual.value.toLowerCase() ||
    (!showIndividualOption && showCompanyOption);

  const individualButtonClassName = `mid-radio-btn px-3 md:px-4 ${
    whiteBg ? 'bg-bgColor' : 'bg-white'
  } ${isIndividual && 'font-bold bg-holyCityMedSecondary'} ${
    disableIndividualButton && 'btn-disabled'
  }`;
  const companyButtonClassName = `mid-radio-btn px-3 md:px-4 ${
    whiteBg ? 'bg-bgColor' : 'bg-white'
  } ml-3 ${isCompany && 'font-bold bg-holyCityMedSecondary'} ${
    disableCompanyButton && 'btn-disabled'
  }`;

  const onChangeMembershipType = (type) => {
    dispatch(setInformationTabValues({ type }));
  };

  const isClientAffiliateEnabled = useMemo(() => {
    return checkEnrollmentTenantSetting(TENANT_SETTINGS_CODE.CLIENT_AFFILIATE, {
      tenantSettings,
    });
  }, [tenantSettings]);

  // Language Dictionary
  const referredByClientHelpText = getTextFromLangDict(langDict, {
    key: '_REFERRED_BY_CLIENT_HELP_TEXT',
    groupCode,
    tenantCode,
  });
  const referredByAgentHelpText = getTextFromLangDict(langDict, {
    key: '_REFERRED_BY_AGENT_HELP_TEXT',
    groupCode,
    tenantCode,
  });
  const individualEnrollmentOption = getTextFromLangDict(langDict, {
    key: '_MEMBERSHIP_ENROLLMENT_OPTION_INDIVIDUAL',
    groupCode,
    tenantCode,
  });
  const individualEnrollmentOptionDesktop = getTextFromLangDict(langDict, {
    key: '_MEMBERSHIP_ENROLLMENT_OPTION_INDIVIDUAL_DESKTOP',
    groupCode,
    tenantCode,
  });
  const groupEnrollmentOption = getTextFromLangDict(langDict, {
    key: '_MEMBERSHIP_ENROLLMENT_OPTION_GROUP',
    groupCode,
    tenantCode,
  });
  const groupEnrollmentOptionDesktop = getTextFromLangDict(langDict, {
    key: '_MEMBERSHIP_ENROLLMENT_OPTION_GROUP_DESKTOP',
    groupCode,
    tenantCode,
  });

  const showReferringClient =
    !!tenantSettings && isClientAffiliateEnabled && !!referredByQueryParam;
  const showReferringAgent = !!tenantSettings && !isClientAffiliateEnabled;

  if (isLoading || !langDict) {
    return <>...</>;
  }

  const getReferredByHelpText = () => {
    if (showReferringClient && referredByClientHelpText) {
      return (
        <span className="text-sm text-bodyColor mt-1">
          ({referredByClientHelpText})
        </span>
      );
    }

    if (showReferringAgent && referredByAgentHelpText) {
      return (
        <span className="text-sm text-bodyColor mt-1">
          ({referredByAgentHelpText})
        </span>
      );
    }

    return '';
  };

  const showReferredByLabel = showReferringAgent || showReferringClient;

  const renderedReferredByLabelMobile = showReferredByLabel &&
    isAffiliateVisible &&
    isSmallerThanIPad && (
      <h5 className="dedicated-form-title mb-3">
        {getTextFromLangDict(langDict, {
          key: '_REFERRED_BY',
          groupCode,
          tenantCode,
        })}{' '}
        {getReferredByHelpText()}
      </h5>
    );

  const renderedReferredByLabelDesktop = showReferredByLabel &&
    isAffiliateVisible &&
    !isSmallerThanIPad && (
      <h5 className="dedicated-form-title mb-3 md:mb-5  md:w-1/3 px-4 ml-auto md:whitespace-no-wrap">
        {getTextFromLangDict(langDict, {
          key: '_REFERRED_BY',
          groupCode,
          tenantCode,
        })}{' '}
        {getReferredByHelpText()}
      </h5>
    );

  return (
    <>
      <div className="flex">
        <h5 className="dedicated-form-title mb-3 md:mb-5">
          {getTextFromLangDict(langDict, {
            key: '_HOW_WOULD_YOU_LIKE_TO_ENROLL',
            groupCode,
            tenantCode,
          })}
        </h5>
        {renderedReferredByLabelDesktop}
      </div>

      <div className="flex flex-wrap -mx-3">
        <div
          className={`w-full px-3 ${showReferringAgentField ? 'md:w-2/3' : ''}`}
        >
          {showIndividualOption && (
            <button
              data-cy="individual-button"
              className={individualButtonClassName}
              onClick={() =>
                onChangeMembershipType(
                  commonConstants.MEMBERSHIP_REGISTRATION_TYPES.individual.value
                )
              }
            >
              {!isMobile
                ? individualEnrollmentOptionDesktop
                : individualEnrollmentOption}
            </button>
          )}

          {showCompanyOption && (
            <button
              data-cy="groupButton"
              className={companyButtonClassName}
              onClick={() =>
                onChangeMembershipType(
                  commonConstants.MEMBERSHIP_REGISTRATION_TYPES.company.value
                )
              }
            >
              {!isMobile ? groupEnrollmentOptionDesktop : groupEnrollmentOption}
            </button>
          )}
        </div>
        {/* only hide i.e className 'hidden' (not unmounting) referring agent select dropdown for GC, only hide coz need to get and tie agent from link even though its hidden*/}
        <div className="w-full mt-5 md:mt-0 md:w-1/3 px-3">
          {renderedReferredByLabelMobile}
          {showReferringAgent && (
            <div className={`${!isAffiliateVisible && 'hidden'}`}>
              <ReferringAgentSelect
                data={{
                  label: referredBy?.label,
                  value: referredBy?.value,
                }}
                onChange={(data) => {
                  setReferredBy({
                    label: data.label,
                    value: data.value,
                  });
                }}
              />
            </div>
          )}
          {showReferringClient && (
            <div className={`${!isAffiliateVisible && 'hidden'}`}>
              <ReferringClientSelect
                data={{
                  label: referredBy?.label,
                  value: referredBy?.value,
                }}
                onChange={(data) => {
                  setReferredBy({
                    label: data.label,
                    value: data.value,
                  });
                }}
              />
            </div>
          )}
        </div>
      </div>

      <div className="form-section-divider-md"></div>
      {isIndividual && (
        <IndividualForm
          individualInfoFormRef={individualInfoFormRef}
          whiteBg={whiteBg}
          onSubmit={onSubmit}
          referredBy={referredBy}
          isClientAffiliate={isClientAffiliateEnabled}
        />
      )}
      {isCompany && (
        <CompanyForm
          companyInfoFormRef={companyInfoFormRef}
          onSubmit={onSubmit}
          referredBy={referredBy}
          isClientAffiliate={isClientAffiliateEnabled}
        />
      )}
    </>
  );
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 1220,
  isSmallerThanIPad: width < 768,
});

export default withSizes(mapSizesToProps)(Information);
