/**
 * @author Slesha Tuladhar <slesha@noveltytechnology.com>
 */

const config = {
  apiBaseUrl: {
    LOCAL: 'http://localhost:3000/api',
    DEVELOPMENT: 'https://dev-api-v3.vitafyhealth.com/api',
    QA: 'https://qa-api-v3.vitafyhealth.com/api',
    UAT: 'https://uat-api-v3.vitafyhealth.com/api',
    FEATURE: 'https://vitafy-feature-api.vitafyhealth.com/api',
    PRODUCTION: 'https://api-v3.vitafyhealth.com/api',
  },
  DATE_FORMAT_VIEW: 'MM/DD/YYYY',
  TIME_DATE_FORMAT_VIEW: 'h:mm:ss A MM/DD/YYYY ',
  DATE_FORMAT_SAVE: 'YYYY-MM-DD',
  PAYMENT_ENV: 'sandbox', // production or sandbox for stripe
  STRIPE_KEY_TEST: 'pk_test_PVCDHvvNNKJKXZA2QmDNFO1V002Vn0jD6V',
};
export default config;
