import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { tenantCodeEnum } from '../../../constants/tenantCodeConstant';
import { store } from '../../../store';

import MemberCheckinIcon from '../../../assets/images/svg-white/member-check-in-white.svg';
import WalkinVideoVisitIcon from '../../../assets/images/svg-white/walk-in-video-visit-white.svg';
import RequestAppointmentIcon from '../../../assets/images/svg-white/request-appointment-white.svg';
import MembershipIcon from '../../../assets/images/svg-white/membership-white.svg';
import { resetAll } from '../../../stores/membership';
import { clearSession } from '../../../util/storageUtil';

const { BMT, BBHB } = tenantCodeEnum;

const Banner = ({ activeService }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const state = useContext(store);
  const { globalState } = state;
  const { tenantCode } = globalState;

  const SIM_CUSTOM_TEXT =
    tenantCode === tenantCodeEnum.SIM || tenantCode === tenantCodeEnum.SIML2;

  let icon, text;
  switch (activeService) {
    case 'member-check-in':
      icon = MemberCheckinIcon;
      text = `${SIM_CUSTOM_TEXT ? 'Patient Check-in' : 'Member Check-in'}`;
      break;
    case 'request-appointment':
      icon = RequestAppointmentIcon;
      text = `${
        SIM_CUSTOM_TEXT
          ? 'REQUEST APPT TO SEE A PROVIDER'
          : 'Request Appointment'
      } `;
      break;
    case 'walk-in-video-visit':
      icon = WalkinVideoVisitIcon;
      text = `${
        tenantCode === tenantCodeEnum.SIML2
          ? 'APPLICATION COVID TESTING'
          : tenantCode === tenantCodeEnum.SIM
          ? 'DRIVE UP COVID TESTING'
          : tenantCode === tenantCodeEnum.HCM ||
            tenantCode === tenantCodeEnum.HCMNR
          ? 'Walk-in or Video Visit or Drive-Thru'
          : 'Additional Services'
      }`;
      break;
    case 'membership':
      icon = MembershipIcon;
      text = `${
        tenantCode === tenantCodeEnum.HCM || tenantCode === tenantCodeEnum.HCMNR
          ? 'Membership'
          : 'Membership Enrollment'
      }`;
      break;
    default:
      console.log('Active service out of options');
  }

  const onHomeClick = () => {
    const homeLink = tenantCode ? `/${tenantCode.toLowerCase()}` : '/';
    history.push(homeLink);
    dispatch(resetAll());
    clearSession();
  };

  const bannerBgColor = [BMT, BBHB].includes(tenantCode?.toUpperCase())
    ? 'bg-dedicatedDpcPrimaryAlternate'
    : 'bg-dedicatedDpcPrimary';

  return (
    <div
      className={`dedicated-lp-inner-banner  w-full ${bannerBgColor} flex justify-center items-center`}
    >
      <FontAwesomeIcon
        icon={faHome}
        className="text-white text-xl md:text-2xl cursor-pointer"
        onClick={onHomeClick}
      />
      <FontAwesomeIcon
        icon={faChevronRight}
        className="text-white text-lg md:text-xl opacity-50 ml-4 md:ml-6 mr-4 md:mr-6"
      />
      <div className="flex items-center cursor-pointer">
        <img src={icon} alt="icon" className="h-6 md:h-8 w-auto mr-4" />
        <h1 className="font-heebo text-white font-bold text-sm md:text-xl uppercase">
          {text}
        </h1>
      </div>
    </div>
  );
};

export default Banner;
