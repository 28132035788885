/**
 * @author Ayush Bajracharya <ayush@noveltytechnology.com>
 */

import * as httpService from './httpService';
import APICONSTANTS from '../constants';

export function searchMembers(data) {
  return httpService
    .post(`${APICONSTANTS.MEMBERS}/search-member-check-in`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function memberCheckin(data) {
  return httpService
    .post(`${APICONSTANTS.MEMBERS}/check-in`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}
