/**
 * @author Slesha Tuladhar <slesha@noveltytechnology.com>
 */

import * as httpService from './httpService';
import APICONSTANTS from '../constants';

export function verifyCode(code) {
  return httpService.post(`${APICONSTANTS.AGENT_CLIENTS}/verify-code`, { code })
    .then(response => {
      return response.data;
    }).catch(error => {
      throw error;
    });
}

export function enrollmentCheckout(body) {
  return httpService.post(`${APICONSTANTS.AGENT_CLIENTS}/enrollment-checkout`, body)
    .then(response => {
      return response.data;
    }).catch(error => {
      throw error;
    });
}

export function fetchAgentByAlias(alias) {
  return httpService.get(`${APICONSTANTS.AGENTS}/${alias}`)
    .then(response => {
      return response.data;
    }).catch(error => {
      throw error;
    });
}

export function fetchPlanByType(type) {
  return httpService.get(`${APICONSTANTS.PLAN}/${type}`)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    })
}

export function fetchDPCByAlias(alias) {
  return httpService.get(`${APICONSTANTS.DPC_INFO_FETCH}/alias/${alias}`)
    .then(response => {
      return response.data;
    }).catch(error => {
      throw error;
    });
}

export function dpcEnrollmentCheckout(body) {
  return httpService.post(`${APICONSTANTS.DPC}/enrollment-checkout`, body)
    .then(response => {
      return response.data;
    }).catch(error => {
      throw error;
    });
}

// referenceId ~ tenantId
export function fetchMerchantProfileByReferenceId(referenceId) {
  return httpService.get(`merchants/${referenceId}`)
    .then(response => {
      return response.data;
    }).catch(error => {
      throw error;
    });
}

export function fetchTenantByCode(code) {
  return httpService.get(`${APICONSTANTS.TENANTS}/${code}?code=true`)
    .then(response => {
      return response.data;
    }).catch(error => {
      throw error;
    });
}

export function registerProvider(body) {
  return httpService.post(`registration`, body)
    .then(response => {
      return response.data;
    }).catch(error => {
      throw error;
    });
}

export function submitProviderRegistrationCheckout(body) {
  return httpService.put(`registration`, body)
    .then(response => {
      return response.data;
    }).catch(error => {
      throw error;
    });
}

export function checkPromoCode(promoCode) {
  return httpService.get(`${APICONSTANTS.PROMO_CODE}/${promoCode}`)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    })
}

export function pmsEnrollment(data) {
  return httpService.post(`${APICONSTANTS.PMS_ENROLLMENT}/new`, data)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    })
}

export function updateInstruction(transactionId, data) {
  return httpService.put(`${APICONSTANTS.TRANSACTIONS}/instruction/${transactionId}`, data)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    })
}

export function fetchTenantSettings(tenantId) {
  return httpService.get(`${APICONSTANTS.TENANTS}/${tenantId}/settings`)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    })
}

export function fetchAgents(queryParams) {
  return httpService.get(`${APICONSTANTS.CONTACTS}`, queryParams)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    })
}

export function fetchAgentByCode(queryParams) {
  return httpService.get(`${APICONSTANTS.CONTACTS}`, queryParams)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    })
}