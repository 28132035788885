/**
 * @author Sunil Rajbahak <sunil@noveltytechnology.com>
 */

/*global google*/

import commonConstants from '../constants/commonConstant';

/**
 * @param {geolocation} location 
 */
export function getLocation(location) {
 return new Promise((resolve, reject) => {
   if (location.coords?.longitude && Math.round(location.coords.longitude) == 85) {//To handle local development
     location.coords.zip = commonConstants.DEFAULT_LOCATION.zip;
     location.coords.city = commonConstants.DEFAULT_LOCATION.city;
     location.coords.state = commonConstants.DEFAULT_LOCATION.state;
     location.coords.lat = commonConstants.DEFAULT_LOCATION.lat;
     location.coords.lng = commonConstants.DEFAULT_LOCATION.lng;
   }
   if (location.coords) {
     if (Math.round(location.coords.longitude) != 85) {
       location.coords.lat = location.coords.latitude;
       location.coords.lng = location.coords.longitude;
 
       const geocoder = new google.maps.Geocoder(
         { fields: ['name', 'address_components', 'formatted_address', 'geometry.location'] }
       );
       const latlng = new google.maps.LatLng(location.coords.lat, location.coords.lng);
       geocoder.geocode({ 'location': latlng }, (results, status) => {
         if (status === google.maps.GeocoderStatus.OK) {
           results.map(result => {
             if (result != null) {
               result.address_components.forEach(x => {
                 if (x.types[0] === 'locality') {
                   location.coords.city = `${x.long_name}`;
                 }
                 if (x.types[0] === 'administrative_area_level_1') {
                   location.coords.state = `${x.short_name}`;
                 }
                 if (x.types[0] === 'postal_code') {
                   location.coords.zip = `${x.long_name}`;
                 }
               });
             }
           })
           let locationDisplayName = location.coords?.city ? (location.coords.city + ', ' + location.coords.state) : '';
           resolve({
             city: location.coords.city,
             state: location.coords.state,
             zip: location.coords.zip,
             latitude: location.coords.latitude,
             longitude: location.coords.longitude,
             lat: location.coords.latitude,
             lng: location.coords.longitude,
             locationName: locationDisplayName
           });
         }
       });
     }
     else {
       let locationDisplayName = location.coords?.city ? (location.coords.city + ', ' + location.coords.state) : '';
       resolve({
         city: location.coords.city,
         state: location.coords.state,
         zip: location.coords.zip,
         latitude: location.coords.lat,
         longitude: location.coords.lng,
         lat: location.coords.lat,
         lng: location.coords.lng,
         locationName: locationDisplayName
       })
     }
   }
   else {
     location.state = location.st;
     let locationDisplayName = location.city ? (location.city + ', ' + location.state) : '';
     resolve({
       city: location.city,
       state: location.state,
       zipCode: location.zip,
       latitude: location.lat,
       longitude: location.lng,
       lat: location.lat,
       lng: location.lng,
       locationName: locationDisplayName
     });
   }
 })
}
