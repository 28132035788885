/**
 * @author Slesha Tuladhar <slesha@noveltytechnology.com>
 */

// Please do not alter the position of the elements in the array
const commonConstants = {
  CLIENT_TYPES: [
    { text: 'Company', value: 'Company' },
    { text: 'Individual', value: 'Individual' },
  ],
  ADDRESS_FIELDS: [
    'addressLine1',
    'addressLine2',
    'city',
    'state',
    'zip',
    'lat',
    'lng',
  ],
  DEFAULT_LOCATION: {
    zip: 53201,
    city: 'Milwaukee',
    state: 'WI',
    st: 'WI',
    lat: 43.0113,
    lng: -87.958,
    latitude: 43.0113,
    longitude: -87.958,
    displayName: 'Milwaukee, WI',
  },
  MEMBERSHIP_REGISTRATION_TYPES: {
    individual: { text: 'Individual', value: 'Individual' },
    company: { text: 'Company', value: 'Group' },
  },
  MEMBERSHIP_OFFERING_CATEGORY: [
    { text: 'Individual & Family', value: 'Family' },
    { text: 'Company & Group', value: 'Group' },
  ],
  GENDER: {
    male: { text: 'Male', value: 'Male' },
    female: { text: 'Female', value: 'Female' },
    others: { text: 'Others', value: 'Others' },
  },
  DEPENDENT_TYPES: [
    { text: 'Spouse', value: 'Spouse' },
    { text: 'Child', value: 'Child' },
    { text: 'Others', value: 'Others' },
  ],
  OFFERING_TYPES: {
    ANCILLARY: 'Ancillary',
    MEMBERSHIP: 'Membership',
  },
  OFFERING_VISIBILITY: {
    PRIVATE: 'Private',
    PUBLIC: 'Public',
  },
  TENANT_SETTINGS_TYPE: {
    TEXT: 'Text',
    RADIO: 'Radio',
    MULTI_SELECT: 'Multi-select',
  },
  TENANT_SETTINGS_CODE: {
    CHECKOUT: 'Checkout',
    BANKING: 'Banking',
    CARD: 'Card',
    CASH: 'Cash',
    OTHERS: 'OTHERS',
    SPONSOR_ACC: 'SponsorAcc',
    COVID_PHYSICIAN: 'CovidPhysician',
    ACCEPT_INSURANCE: 'ACCEPT_INSURANCE',
    APPROVE_MEMBERSHIP: 'APPROVE_MEMBERSHIP',
    TENANT_SERVICE: 'TENANT_SERVICE',
    OTHERS_IN_GENDER: 'OTHERS_IN_GENDER',
    BLOOD_GROUP: 'BLOOD_GROUP',
    CLIENT_AFFILIATE: 'CLIENT_AFFILIATE',
    CLIENT_ENROLLMENT_TYPE: 'CLIENT_ENROLLMENT_TYPE',
    DEPENDENT_RELATIONSHIP: 'DEPENDENT_RELATIONSHIP',
    SKIP_PAYMENT_PUBLIC: 'SKIP_PAYMENT_PUBLIC',
    ANCILLARY_EMAIL: 'ANCILLARY_EMAIL',
    MEMBERSHIP_QUALIFICATION_QUESTIONAIRE:
      'MEMBERSHIP_QUALIFICATION_QUESTIONAIRE',
    INTERNATIONAL_ADDRESS: 'INTERNATIONAL_ADDRESS',
    AFFILIATE_VISIBILITY: 'AFFILIATE_VISIBILITY',
    GROUP_MEMBER_MGMT: 'GROUP_MEMBER_MGMT',
  },
  TENANT_SETTINGS_STATUS: {
    ENABLED: 'Enabled',
    DISABLED: 'Disabled',
  },
  AFFILIATE_TENANT_SETTINGS_STATUS: {
    Website: 'Website',
    Portal: 'Portal',
    Both: 'Both',
    None: 'None',
  },
  BILLING_INTERVAL: {
    MONTHLY: 'Monthly',
    ONETIME: 'One Time',
    YEARLY: 'Yearly',
    BIYEARLY: 'Bi-yearly',
  },
  BILLING_TYPE: {
    INDIVIDUAL: 'Individual',
    FAMILY: 'Family',
    GROUP: 'Group',
  },
  MEMBERSHIP_ENROLLMENT_TYPES: {
    FAMILY: 'Family',
    GROUP: 'Group',
  },
  MEMBER_TYPE: {
    PRIMARY: 'Primary',
    GROUP_MEMBER: 'Group Member',
    DEPENDENT: 'Dependent',
  },
  REFERENCE_TYPE: {
    DPC: 'DPC',
    DENTAL: 'DENTAL',
    PROVIDER: 'PROVIDER',
    TENANT: 'TENANT',
    AGENT: 'AGENT',
    NETWORK: 'NETWORK',
    ADVOCATE: 'ADVOCATE',
  },
  WITH_OWN_AGREEMENT: ['BMT', 'CHC', 'IHS', 'LEBC', 'GC'],
  NO_PAYMENT_TENANT: ['GC', 'IHS'],
  MEMBER_NEUTRAL_PRICING: ['GC'],
  SEO: {
    DEFAULT_PAGE_TITLE:
      'Vitafy Health | A free-market healthcare solution for connecting doctors and patients nationwide',
  },
  OFFERING_TYPE_PRIMARY: 'OFFERING_PRIMARY',
  OFFERING_TYPE_DEPENDENT: 'OFFERING_DEPENDENT',
  BLOOD_GROUP: {
    A_POSITIVE: 'A+',
    O_POSITIVE: 'O+',
    B_POSITIVE: 'B+',
    AB_POSITIVE: 'AB+',
    A_NEGATIVE: 'A-',
    O_NEGATIVE: 'O-',
    B_NEGATIVE: 'B-',
    AB_NEGATIVE: 'AB-',
    UNK: 'UNK',
  },
  PAGINATION_FETCH_ALL: {
    OFFSET: 0,
    LIMIT: 999,
  },
  REFERRED_BY_TYPE: {
    AGENT: 'Agent',
    CLIENT: 'Client',
  },
  MEMBERSHIP_ENROLLMENT_STEPS: {
    INFORMATION: 'INFORMATION',
    QUESTIONAIRE: 'QUESTIONAIRE',
    MEMBERSHIP: 'MEMBERSHIP',
    CHECKOUT: 'CHECKOUT',
    CONFIRMATION: 'CONFIRMATION',
  },
  VITAFY_AGREEMENTS: {
    PRIVACY_POLICY: 'https://www.vitafyhealth.com/privacy-policy',
    TERMS_OF_USE: 'https://www.vitafyhealth.com/terms-and-conditions',
  },
  DEFAULT_MAX_FILE_SIZE: 25000000, // byte // 25MB
  VERSION: '2.0.0',
};

export default commonConstants;
