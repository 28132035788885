/**
 * Simple Banner
 * Banner with title and tagline only
 * Author: ayush@noveltytechnology.com
 */

import React from 'react';

const SimpleBanner = (props) => {
  const { title, tagline, taglineVisibility, uppercase = true } = props;
  return (
    <div className='w-full pt-24 lg:pt-32 pb-12 lg:pb-12 bg-extended'>
      <div className='container mx-auto px-4'>
        {taglineVisibility ?
          <>
            <h1 className='font-heebo uppercase font-bold text-white text-3xl lg:text-5xl'>
              {title}
            </h1>
            <h6 className='firstContentHeading normal-case font-normal text-base lg:text-xl mt-0 pt-0'>
              {tagline}
            </h6>
          </>
          :
          <h1 className={`firstContentHeading text-4xl ${uppercase ? 'uppercase' : 'normal-case'}`}>{title}</h1>
        }
      </div>
    </div>
  );
}

export default SimpleBanner;