import React from 'react';
import ReactLoading from 'react-loading';

const loaderColor = '#6C7272';

const Loader = ({ hScreen = true }) => (
  <div
    className={`w-full ${
      hScreen ? 'h-screen' : ''
    } flex justify-center items-center`}
  >
    <ReactLoading
      type="bubbles"
      color={loaderColor}
      height={'10%'}
      width={'10%'}
    />
  </div>
);

export default Loader;
