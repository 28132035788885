import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { store } from '../../store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { getPortalLink } from '../../util/miscellaneousUtil';

const DedicatedTenantHeader = () => {
  const state = useContext(store);
  const { globalState } = state;
  const { isLoading, primaryLocation, langDict } = globalState;

  if (isLoading || !primaryLocation || !langDict) return null;

  const { website, code, metaData } = primaryLocation;

  const portalLink = getPortalLink();

  const loginBtnClassName = 'dedicatedDpcPrimary';

  return (
    <nav
      className={`transition duration-500 ease-in-out fixed flex items-center justify-between px-4 py-3 top-0 w-full z-40 bg-white shadow`}
    >
      <div className="container mx-auto flex justify-between items-center">
        <NavLink exact to="/" className="z-50">
          {Boolean(metaData?.imageUrl) && (
            <img
              src={metaData?.imageUrl}
              className="block h-12 xxl:h-16"
              alt="logo"
            />
          )}
        </NavLink>

        <div className="flex items-center">
          <a
            href={
              website &&
              (website.includes('https://') || website.includes('https://'))
                ? website
                : website
                ? 'https://' + website
                : ''
            }
            target="_blank"
            rel="noopener noreferrer"
            className={`block body-text text-titleColor focus:outline-none no-underline hover:text-${loginBtnClassName}`}
          >
            About
          </a>
          <div className="ml-3 mr-3 w-1px h-8 bg-bgColorDarker"></div>
          <a
            href={`${portalLink}/login?client=${code}`}
            target="_blank"
            rel="noopener noreferrer"
            className={`inline-block rounded-md body-text text-${loginBtnClassName} text-sm px-3 py-2 hover:bg-gray-200 no-underline focus:outline-none border border-${loginBtnClassName} border-opacity-100`}
          >
            <FontAwesomeIcon icon={faSignInAlt} className="inline-block mr-1" />{' '}
            Login
          </a>
        </div>
      </div>
    </nav>
  );
};

export default DedicatedTenantHeader;
