import React, { useContext } from 'react';

import { store } from '../../../store';

import serviceMap from '../../../constants/serviceMap';

import MenuButton from './MenuButton';
import MenuButtonMobile from './MenuButtonMobile';

const HelpCenterMenu = () => {
  const state = useContext(store);
  const { globalState } = state;
  const { tenantCode, primaryLocation, langDict, isLoading } = globalState;

  if (isLoading || !primaryLocation || !langDict) {
    return <>...</>;
  }

  const { services } = primaryLocation.configuration;
  const groupCode = primaryLocation?.groupCode;

  const isThreeColsLayout = services?.length === 3;

  const getServiceIcon = (code) => {
    return serviceMap[code].icon;
  };

  const getServiceTitle = (code) => {
    return (
      langDict[
        serviceMap[code]?.tenantSpecificTitle
          ?.replace('{{groupCode}}', groupCode)
          ?.replace('{{tenantCode}}', tenantCode)
      ] ??
      langDict[serviceMap[code]?.title?.replace('{{groupCode}}', groupCode)] ??
      langDict[serviceMap[code]?.defaultTitle]
    );
  };

  const getHelpText = (code, { show }) => {
    if (!show) {
      return '';
    }
    return (
      langDict[
        serviceMap[code]?.tenantSpecificDescription
          ?.replace('{{groupCode}}', groupCode)
          ?.replace('{{tenantCode}}', tenantCode)
      ] ??
      langDict[
        serviceMap[code]?.description?.replace('{{groupCode}}', groupCode)
      ] ??
      langDict[serviceMap[code]?.defaultDescription]
    );
  };

  return (
    <>
      {/* Help Center for larger screen */}
      <div
        className={`container mx-auto px-4 hidden md:block ${
          isThreeColsLayout
            ? 'sim-help-center-container-margin-top'
            : 'help-center-container-margin-top'
        }`}
      >
        <div
          className={`flex flex-wrap shadow-md ${
            isThreeColsLayout
              ? 'sim-help-center-flex-container-margin'
              : 'help-center-flex-container-margin'
          }`}
        >
          {services?.map((service) => (
            <div
              className={`${
                isThreeColsLayout ? 'w-1/3' : 'w-1/2'
              } bg-transparent`}
              key={service.code}
            >
              <MenuButton
                tenantCode={tenantCode}
                serviceCode={service.code}
                icon={getServiceIcon(service.code)}
                text={getServiceTitle(service.code)}
                helpText={getHelpText(service.code, {
                  show: service.showDescription,
                })}
                externalLink={service.externalLink}
                showDisclaimer={service.showAttention}
              />
            </div>
          ))}
        </div>
      </div>

      {/* Help Center for smaller screen */}
      <div
        className={`container mx-auto px-4 block md:hidden ${
          isThreeColsLayout
            ? 'sim-help-center-container-margin-top'
            : 'help-center-container-margin-top'
        }`}
      >
        <div className="shadow-md">
          <div className="flex flex-wrap">
            {services?.map((service) => (
              <div
                className={`${isThreeColsLayout ? 'w-full' : 'w-1/2'}`}
                key={service.code}
              >
                <MenuButtonMobile
                  tenantCode={tenantCode}
                  serviceCode={service.code}
                  icon={getServiceIcon(service.code)}
                  text={getServiceTitle(service.code)}
                  helpText={getHelpText(service.code, {
                    show: service.showDescription,
                  })}
                  externalLink={service.externalLink}
                  showDisclaimer={service.showAttention}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default HelpCenterMenu;
