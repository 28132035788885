/**
 * @author Slesha Tuladhar<slesha@noveltytechnology.com>
 */

import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
/* global google */

export class PlaceAutocomplete extends Component {
  constructor(props) {
    super(props);
    this.initialState = {
      address: {
        addressLine1: '',
        // addressLine2: '',
        city: '',
        state: '',
        zip: '',
        lat: '',
        lng: '',
      },
      query: props.addressLine1,
    };
    this.state = { ...this.initialState };
  }
  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ query: props.addressLine1 ? props.addressLine1 : '' });
  }

  handleScriptLoad = () => {
    const options = {
      types: ['geocode'],
      componentRestrictions: { country: 'us' },
      fields: ['address_components', 'formatted_address', 'geometry.location'],
    };
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById('autocomplete'),
      options
    );
    this.autocomplete.addListener('place_changed', this.handlePlaceChanged);
  };
  handlePlaceChanged = () => {
    const addressObject = this.autocomplete?.getPlace();
    const address = addressObject?.address_components;
    const addr = {
      ...this.initialState.address,
      lat: addressObject.geometry.location.lat(),
      lng: addressObject.geometry.location.lng(),
    };
    // Check if address is valid
    if (address) {
      address.map((x) => {
        if (x.types[0] == 'street_number' || x.types[0] == 'route') {
          addr.addressLine1 += `${x.long_name} `;
        }
        if (x.types[0] == 'locality') {
          addr.city = `${x.long_name}`;
        }
        if (x.types[0] == 'administrative_area_level_1') {
          addr.state = `${x.short_name}`;
        }
        if (x.types[0] == 'postal_code') {
          addr.zip = `${x.long_name}`;
        }
      });
      addr.addressLine1 = addr.addressLine1?.trim();

      this.setState(
        {
          address: { ...addr },
          query: addr.addressLine1,
        },
        () => {
          this.props.setGoogleAutocompleteAddress(this.state.address);
        }
      );
    }
  };
  handleInputChange = (e) => {
    this.setState(
      {
        query: e.target.value,
        address: { addressLine1: e.target.value },
      },
      () => {
        this.props.setGoogleAutocompleteAddress(this.state.address);
      }
    );
  };
  render() {
    return (
      // <div className="md-form">
      <div className="search-location-input">
        <div className="mdp-input-main">
          <div className="mdp-input-box">
            <input
              id="autocomplete"
              className="mdp-input bg-transparent text-titleColor font-heebo"
              placeholder="Enter location"
              value={this.state.query}
              onChange={(e) => this.handleInputChange(e)}
              style={{ textIndent: this.props.icon ? '2rem' : '' }}
            />
            <label
              htmlFor="autocomplete"
              className={`mdp-label ${this.props.icon ? 'ml-8' : null}`}
            >
              {this.props.label}
            </label>
            {this.props.icon && (
              <FontAwesomeIcon icon={this.props.icon} className="mdp-icon" />
            )}
            {this.props.error && (
              <FontAwesomeIcon
                icon={faExclamationCircle}
                className="text-red-500"
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '0px',
                  fontSize: '16px',
                }}
              />
            )}

            <div
              className={`${
                this.props.error
                  ? 'mdp-input-error-underline'
                  : 'mdp-input-underline'
              }`}
            />
          </div>
        </div>

        {/* <label style={{ fontSize: "12px", position: "absolute", top: "-20px", left: "0px" }}>Address Line 1{this.props.optional ? ' - (Optional)' : ''}</label> */}
      </div>
    );
  }
}

export default PlaceAutocomplete;
