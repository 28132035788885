import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Dropzone } from '../../common';
import commonConstants from '../../constants/commonConstant';
import {
  selectActiveQuestion,
  selectRespondent,
  setActiveQuestion,
} from '../../stores/questionaire';
import { _cloneDeep } from '../../util/lodashUtil';
import { getFullName } from '../../util/vitafyUtil';

const { defaultMaxFileSize } = commonConstants;

const FileInput = () => {
  const dispatch = useDispatch();

  const activeQuestion = useSelector(selectActiveQuestion);
  const respondent = useSelector(selectRespondent);
  const files = activeQuestion?.answer || [];

  const onFileChange = (files) => {
    const data = files.map((e) => {
      const { error, ...rest } = e;
      return rest;
    });

    const updatedQuestion = _cloneDeep(activeQuestion);

    updatedQuestion.answer = data;
    dispatch(setActiveQuestion(updatedQuestion));
  };

  const totalFileSize = files.reduce((prev, curr) => prev + curr.size, 0);

  const isFileSizeExceeded = totalFileSize > defaultMaxFileSize;

  return (
    <div>
      <Dropzone
        isFileSizeExceeded={isFileSizeExceeded}
        files={files}
        setFiles={onFileChange}
        createdBy={getFullName({
          firstName: respondent?.firstName,
          middleName: respondent?.middleName,
          lastName: respondent?.lastName,
        })}
      />
    </div>
  );
};

export default FileInput;
