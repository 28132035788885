/**
 * @author Slesha Tuladhar <slesha@noveltytechnology.com>
 */

export function escapeRegexChars(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}


// remove non alphanumeric characters from the string
export function removeNonAlphaNumericChars(str) {
  return str.replace(/[^A-Za-z0-9]/g, '');
}