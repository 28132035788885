import React, { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import withSizes from 'react-sizes';
import {
  faBuilding,
  faUser,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons';

import {
  FormTextField,
  FormAddressInput,
  FormCountryPhoneInput,
} from '../../../../common';

import { CompanySchema } from '../../../../schemas/membership';
import {
  selectInformationTab,
  setInformationTabValues,
} from '../../../../stores/membership';
import { store } from '../../../../store';
import { formatCompanyDemographicPayload } from '../../../../util/vitafyUtil';
import { getTextFromLangDict } from '../../../../util';
import commonConstants from '../../../../constants/commonConstant';
import { countryCode } from '../../../../constants/countryCode';

const { REFERRED_BY_TYPE } = commonConstants;

const defaultValues = {
  businessName: '',
  firstName: '',
  middleName: '',
  lastName: '',
  email: '',
  phone: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zip: '',
  lat: '',
  lng: '',
  countryCode: countryCode.USA,
  phoneExt: '1',
  textEnabled: false,
};

const CompanyForm = ({
  companyInfoFormRef,
  isMobile,
  onSubmit,
  referredBy,
  isClientAffiliate,
}) => {
  const methods = useForm({
    resolver: yupResolver(CompanySchema),
    defaultValues,
  });
  const { getValues, handleSubmit, reset } = methods;

  const dispatch = useDispatch();

  // Redux Store
  const informationTab = useSelector(selectInformationTab);

  // Context Store
  const state = useContext(store);
  const { globalState } = state;
  const { tenantId, groupCode, langDict, tenantCode } = globalState;

  const formSubmit = (formValues) => {
    dispatch(setInformationTabValues(getValues()));

    const payload = formatCompanyDemographicPayload({
      ...formValues,
      tenantId,
      type: informationTab.type,
      clientId: informationTab.clientId,
      clientEnrollmentId: informationTab.clientEnrollmentId,
      referredByType: isClientAffiliate
        ? REFERRED_BY_TYPE.CLIENT
        : REFERRED_BY_TYPE.AGENT,
      referredById: referredBy.value,
      referredByName: referredBy.label,
      groupCode,
    });
    onSubmit(payload);
  };

  /**
   * Prepopulate the data if exists
   */
  useEffect(() => {
    if (informationTab) {
      reset({
        businessName: informationTab.businessName ?? '',
        firstName: informationTab.firstName ?? '',
        middleName: informationTab.middleName ?? '',
        lastName: informationTab.lastName ?? '',
        email: informationTab.email ?? '',
        phone: informationTab.phone ?? '',
        addressLine1: informationTab.addressLine1 ?? '',
        addressLine2: informationTab.addressLine2 ?? '',
        city: informationTab.city ?? '',
        state: informationTab.state ?? '',
        zip: informationTab.zip ?? '',
        lat: informationTab.lat ?? '',
        lng: informationTab.lng ?? '',
        countryCode: informationTab?.countryCode ?? countryCode.USA,
        phoneExt: informationTab?.phoneExt ?? '1',
        textEnabled: informationTab?.textEnabled ?? false,
      });
    }
  }, [informationTab, reset]);

  /**
   * Update the store on unmount so that entered data are persisted
   */
  useEffect(() => {
    return () => {
      dispatch(
        setInformationTabValues({
          ...getValues(),
          referredByName: referredBy.label,
          referredById: referredBy.value,
        })
      );
    };
  }, [dispatch, getValues, referredBy]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(formSubmit)} ref={companyInfoFormRef}>
        {/* Company Information */}
        <h5 className="dedicated-form-title mb-3">
          {getTextFromLangDict(langDict, {
            key: '_ENTER_COMPANY_INFORMATION',
            groupCode,
            tenantCode,
          })}
        </h5>
        <FormTextField
          name="businessName"
          label="Company Name *"
          icon={isMobile ? '' : faBuilding}
        />
        <div className="flex flex-wrap -mx-4">
          <div className="w-full md:w-1/3 px-4">
            <FormTextField
              name="firstName"
              label="Contact First Name *"
              icon={isMobile ? '' : faUser}
            />
          </div>
          <div className="w-full md:w-1/3 px-4">
            <FormTextField name="middleName" label="Contact Middle Name" />
          </div>
          <div className="w-full md:w-1/3 px-4">
            <FormTextField name="lastName" label="Contact Last Name *" />
          </div>
        </div>

        <div className="form-section-divider-md"></div>

        {/* Company Contact Information */}
        <h5 className="dedicated-form-title mb-3">
          {getTextFromLangDict(langDict, {
            key: '_ENTER_COMPANY_CONTACT_INFORMATION',
            groupCode,
            tenantCode,
          })}
        </h5>
        <div className="flex flex-wrap -mx-4">
          <div className="w-full md:w-1/2 px-4">
            <FormTextField
              name="email"
              label="Email *"
              icon={isMobile ? '' : faEnvelope}
            />
          </div>

          <div className="flex w-full md:w-1/2 px-4">
            <FormCountryPhoneInput label="Phone *" />
          </div>
        </div>
        <FormAddressInput isRequired />
      </form>
    </FormProvider>
  );
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 768,
});

export default withSizes(mapSizesToProps)(CompanyForm);
