import React from 'react';
import { DedicatedTenantHeader, DedicatedTenantFooter } from '../common';
import { getSubDomain } from '../util/miscellaneousUtil';
import subDomainMap from '../constants/subdomainMap';

const DedicatedTenantLayout = (props) => {
  const themeClassName =
    subDomainMap[getSubDomain()]?.themeClass || 'theme-dpc';

  return (
    <div className={`${themeClassName} flex flex-col min-h-screen`}>
      <DedicatedTenantHeader />
      <main className="flex-1">
        {props.children}
        <div className="h-12"></div>
      </main>
      <DedicatedTenantFooter />
    </div>
  );
};

export default DedicatedTenantLayout;
