import React from 'react';
import { Checkbox } from '../..';
import * as vitafyUtils from '../../../util/vitafyUtil';
class AchPayment extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      requiredInput: false,
      invalidInput: false,
      isFormSubmitted: false,
      token: ''
    }
  }

  componentDidMount() {
    window.addEventListener("message", this.listen);
    this.props.clearErrorMessage();
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.listen);
  }

  listen = (event) => {
    // console.log('Received Message: ', event.data);
    if (typeof (event.data) == 'string') {
      // 1004 -> Input Required
      // 1001 -> Invalid Input
      try {
        const response = JSON.parse(event.data);
        response.errorCode &&
          this.setState({
            requiredInput: response.errorCode === '1004' ? true : false,
            invalidInput: response.errorCode === '1001' ? true : false,
            token: '',
            isFormSubmitted: false
          }, () => {
            if (!this.state.requiredInput && !this.state.invalidInput && response && response.errorCode === '0') {
              this.setState({ token: response.token })
            }
          })
      } catch (e) {
        console.log('Error while trying to parse JSON', e)
      }

    }
  };

  handleIAgreeAchChange = (e) => {
    let paymentData = [
      {
        key: 'iAgreeAchPayment',
        value: e
      }
    ]
    this.props.changePaymentData(paymentData);
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ isFormSubmitted: true });
    this.props.clearErrorMessage();
    if (!this.state.requiredInput && !this.state.invalidInput && this.state.token !== '') {
      const formValues = {
        paymentType: 'Bank',
        token: this.state.token
      }
      this.props.submit(4, formValues);
    }
  }

  render() {

    const parameters = "invalidinputevent=true&enhancedresponse=true&placeholder=Routing Number     Account Number";
    const encodedStyles = "body %7B font-family: 'Heebo', sans-serif;%0A%7D%0Ainput %7B border: 1.5px solid %23d4d4d4; height: 40px; background-color: %23fafafa; font-size: 16px; text-indent: 16px; margin-top: 10px; color: %23333;%0A%7D input:focus %7B outline: none !important; border: 1.5px solid %2300a1be; box-shadow: 0 0 3px %23b0b0b0; background-color: %23ffffff;%0A%7D select %7B border: 1.5px solid %23d4d4d4; height: 44.5px; background-color: %23fafafa; font-size: 16px; margin-top: 10px; color: %23333; text-indent: 8px;%0A%7D select:focus %7B outline: none !important; border: 1.5px solid %2300a1be; box-shadow: 0 0 3px %23b0b0b0; background-color: %23ffffff;%0A%7D select:not(:-internal-list-box) %7B overflow: visible !important;%0A%7D %23cccardlabel,%0A%23ccexpirylabel,%0A%23cccvvlabel %7B display: none;%0A%7D %23ccnumfield %7B width: 100%25; display: block; margin-bottom: 0.4rem;%0A%7D %23ccexpirymonth %7B width: 4rem; margin-right: 1rem;%0A%7D %23ccexpiryyear %7B width: 5rem; margin-right: 1rem;%0A%7D";

    return (
      <form ref={this.props.achPaymentRef} onSubmit={this.handleSubmit}>
        <div className="mt-5">
          <p className="body-text font-bold">Payment Summary</p>

          <div className={`w-full bg-white pl-2 pr-3 md:px-6 pt-2 pb-3 rounded-sm mt-5`}>
            <div className="flex justify-between items-center my-1 md:my-2 px-2">
              <p className="body-text">Total Amount</p>
              <h6 className="body-text text-xl md:text-2xl text-titleColor">
                {this.props.totalCost ? <>{vitafyUtils.currencyFormat(this.props.totalCost)}/month</> : ''}
              </h6>
            </div>

            <p className="body-text text-lg md:text-xl text-titleColor font-bold ml-2">
              Bank Information
            </p>
            <p className='ml-2 mt-2 body-text text-base'>Enter your bank information in this format: <strong>routing number/account number</strong>. <br />
              For example: 123456789/1234123412341234
            </p>

            <div className="mt-1 md:mt-2">

              <iframe
                title="iframe"
                id="tokenframe"
                name="tokenframe"
                frameBorder="0"
                scrolling="no"
                width='100%'
                height="75"
                src={`${this.props.paymentBaseUrl}/itoke/ajax-tokenizer.html?&${parameters}&css=${encodedStyles}`}
              />

              <div className="mt-1 mx-2 md:flex md:justify-between md:items-center">
                <Checkbox
                  checked={this.props.client?.payment?.iAgreeAchPayment}
                  setChecked={(e) => this.handleIAgreeAchChange(e)}
                  className="inline"
                >
                  <p className="font-heebo text-sm md:text-base text-titleColor mb-0 select-none inline-block">
                    I agree with{' '}
                    <a
                      href="https://vitafymed.com/terms-of-use"
                      target="_blank"
                      className="no-underline font-bold text-secondary focus:outline-none"
                    >
                      {' '}
                      Vitafy terms & policy
                  </a>
                  </p>
                </Checkbox>

                {this.props.cosmicContent && this.props.cosmicContent.metadata && this.props.cosmicContent.metadata.powered_by
                  && this.props.cosmicContent.metadata.powered_by.image &&
                  <div className="flex items-center mt-4 md:mt-0">
                    <p className="body-text text-sm mr-1 mt-1"> Powered by </p>
                    <a href={this.props.cosmicContent.metadata.powered_by.hyperlink} target='_blank' className='no-underline outline-none focus:outline-none' >
                      <img src={this.props.cosmicContent.metadata.powered_by.image.url} className='h-12' />
                    </a>
                  </div>
                }
              </div>

              <div className='min-h-2'>
                <p className='ml-2 mt-6 body-text text-sm text-red-600'>
                  {this.state.isFormSubmitted && this.state.requiredInput && 'Please fill all the fields'}
                  {this.state.isFormSubmitted && this.state.invalidInput && 'Please make sure that provided informations are correct'}
                  {this.props.errorMessage ? `Error: ${this.props.errorMessage}` : ''}
                </p>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }

}

export default AchPayment;