import React from 'react';
import NumberFormat from 'react-number-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const MaskedTimeInput = ({
  input,
  setInput,
  label,
  dayShift,
  changeDayShift,
  icon,
  error,
}) => {
  return (
    <div className='mdp-input-main'>
      <div className='mdp-input-box '>
        {icon && (
          <FontAwesomeIcon
            icon={icon}
            className="mdp-icon"
          />
        )}

        <div className='flex items-center'>
          <NumberFormat
            id='masked-time-input'
            format={`##:## ${dayShift === 'AM' ? 'AM' : 'PM'}`}
            mask='_'
            value={input}
            placeholder={`HH:MM ${dayShift === 'AM' ? 'AM' : 'PM'}`}
            style={{ textIndent: icon ? '2rem' : '' }}
            className='mdp-input bg-transparent text-titleColor font-heebo'
            onValueChange={(value) => setInput(value.formattedValue)}
          />
          <div className='flex items-center pr-6'>
            <div
              className={`py-1 px-2 text-base rounded-md 
                cursor-pointer mr-2 ${dayShift === 'AM'
                  ? 'bg-dedicatedDpcPrimary text-white'
                  : 'bg-gray-400 text-bodyColor'
                }`}
              onClick={() => changeDayShift('AM')}
            >
              AM
            </div>
            <div
              className={`py-1 px-2 text-base rounded-md 
                cursor-pointer  ${dayShift === 'PM'
                  ? 'bg-dedicatedDpcPrimary text-white'
                  : 'bg-gray-400 text-bodyColor'
                }`}
              onClick={() => changeDayShift('PM')}
            >
              PM
            </div>
          </div>
        </div>
        <label
          htmlFor='masked-time-input'
          className={`mdp-label ${icon ? 'ml-8' : null}`}
        >
          {label}
        </label>
        {error && (
          <FontAwesomeIcon
            icon={faExclamationCircle}
            className='text-red-500'
            style={{
              position: 'absolute',
              top: '10px',
              right: '0px',
              fontSize: '16px',
            }}
          />
        )}

        <div
          className={`${error ? 'mdp-input-error-underline' : 'mdp-input-underline'
            }`}
        />
      </div>
    </div>
  );
};

export default MaskedTimeInput;
