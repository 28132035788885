import React from 'react';
import NumberFormat from 'react-number-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const MaskedPhoneInput = React.forwardRef((props, ref) => {
  const {
    input,
    setInput,
    label,
    icon,
    error,
    format,
    placeholder,
    style,
    isFormat,
  } = props;

  return (
    <div className="mdp-input-main">
      <div className="mdp-input-box ">
        <NumberFormat
          id="masked-phone-input"
          format={isFormat ? format || '(###) ###-####' : undefined}
          mask={isFormat ? '_' : undefined}
          value={input}
          placeholder={
            isFormat ? placeholder || '(_ _ _) _ _ _-_ _ _ _' : undefined
          }
          style={{ textIndent: icon ? '2rem' : '', ...style }}
          className="mdp-input bg-transparent text-titleColor font-heebo"
          onValueChange={(value) =>
            setInput(value.formattedValue?.replace('-', ''))
          }
        />
        <label
          htmlFor="masked-phone-input"
          className={`mdp-label ${icon ? 'ml-8' : null}`}
        >
          {label}
        </label>
        {icon && <FontAwesomeIcon icon={icon} className="mdp-icon" />}
        {error && (
          <FontAwesomeIcon
            icon={faExclamationCircle}
            className="text-red-500"
            style={{
              position: 'absolute',
              top: '10px',
              right: '0px',
              fontSize: '16px',
            }}
          />
        )}

        <div
          className={`${
            error ? 'mdp-input-error-underline' : 'mdp-input-underline'
          }`}
        />
      </div>
    </div>
  );
});

MaskedPhoneInput.defaultProps = {
  isFormat: true,
};

export default MaskedPhoneInput;
