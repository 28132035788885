/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import commonConstants from '../../constants/commonConstant';
import { momentViewFormat } from '../../util/momentUtil';

import { getInformationFormDefaultValues } from '../../util/vitafyUtil';

const { MEMBERSHIP_ENROLLMENT_STEPS } = commonConstants;

const { INFORMATION } = MEMBERSHIP_ENROLLMENT_STEPS;

const DEPENDENTS = [
  {
    clientId: 'b43bdc05-a390-4bf9-814a-25d7988eac5b',
    type: 'Individual',
    firstName: 'Galena',
    middleName: '',
    lastName: 'Child',
    lastSSN: '9453',
    dob: '01/01/2010',
    gender: 'Male',
    email: 'ginedused@mailinator.com',
    phone: '1727749275',
    address: {
      addressLine1: '588 Rocky Fork Boulevard',
      addressLine2: '',
      city: 'Columbus',
      state: 'OH',
      zip: '43230',
      lat: 40.0131015,
      lng: -82.86120969999999,
    },
    tenantId: '96025b89-57d0-4626-8bea-1568dcbc2c1a',
    tenantGroupCode: 'BBHB',
    bloodGroup: 'B+',
    referredBy: {
      type: '',
    },
    relationship: 'Child',
    parentId: '5ecd5ce3-b9ee-4c64-9cea-d73d0f8e19af',
    parentName: 'Vanna Sears',
  },
];

const OFFERINGS = [
  {
    id: '6305708d4c40fb570b7aa7d2',
    tenantOfferingId: '13D5E9CE-CEE1-41C1-BE14-8102DF919FC0',
    tenantId: '7EB541E4-91A9-4DEB-BB7E-55813D3CA140',
    enrollmentCode: 'DPC',
    category: 'Individual & Family',
    name: 'Adult Membership (18-65)',
    ageBand: 'Y',
    minAge: 18,
    maxAge: 65,
    minQuantity: 0,
    maxQuantity: 0,
    price: 49.55,
    billingInterval: 'Monthly',
    billingType: 'Individual',
    registrationFee: 30,
    status: 'Active',
    visibility: 'Public',
    effectiveStartDate: '01/01/2022',
    effectiveEndDate: '',
    order: null,
  },
  {
    id: '6305708d4c40fb570b7aac1a',
    tenantOfferingId: '413481F8-1995-4CA2-870D-74D89E5F2699',
    tenantId: '7EB541E4-91A9-4DEB-BB7E-55813D3CA140',
    enrollmentCode: 'DPC',
    category: 'Individual & Family',
    name: 'Annual',
    ageBand: 'N',
    minAge: '',
    maxAge: '',
    minQuantity: '',
    maxQuantity: '',
    price: 1000,
    billingInterval: 'Yearly',
    billingType: 'Individual',
    registrationFee: 0,
    status: 'Active',
    visibility: 'Public',
    effectiveStartDate: null,
    effectiveEndDate: null,
    order: null,
  },
  {
    id: '6305708d4c40fb570b7aac1d',
    tenantOfferingId: 'C8CC0B35-83D3-4A57-9053-41A4DCEF6181',
    tenantId: '7EB541E4-91A9-4DEB-BB7E-55813D3CA140',
    enrollmentCode: 'DPC',
    category: 'Individual & Family',
    name: 'Couples',
    ageBand: 'N',
    minAge: 0,
    maxAge: 0,
    minQuantity: 2,
    maxQuantity: 2,
    price: '30',
    billingInterval: 'Monthly',
    billingType: 'Family',
    registrationFee: '0',
    status: 'Active',
    visibility: 'Public',
    effectiveStartDate: '',
    effectiveEndDate: '',
    order: null,
    isRecommended: true,
  },
  {
    id: '6305708d4c40fb570b7aac19',
    tenantOfferingId: 'D8FA090E-062E-4F4D-B6D5-6B9DE856868E',
    tenantId: '7EB541E4-91A9-4DEB-BB7E-55813D3CA140',
    enrollmentCode: 'DPC',
    category: 'Individual & Family',
    name: 'Family Membership',
    ageBand: 'N',
    minAge: 0,
    maxAge: 0,
    minQuantity: 3,
    maxQuantity: 0,
    price: '100',
    billingInterval: 'Monthly',
    billingType: 'Family',
    registrationFee: '20',
    status: 'Active',
    visibility: 'Public',
    effectiveStartDate: '',
    effectiveEndDate: '',
    order: null,
  },
  {
    id: '6305708d4c40fb570b7aac1c',
    tenantOfferingId: '2A4DAC83-6BC1-43CC-B14A-EB56E2685EDB',
    tenantId: '7EB541E4-91A9-4DEB-BB7E-55813D3CA140',
    enrollmentCode: 'DPC',
    category: 'Individual & Family',
    name: 'Individuals',
    ageBand: 'N',
    minAge: 0,
    maxAge: 0,
    minQuantity: 1,
    maxQuantity: 1,
    price: '50',
    billingInterval: 'Monthly',
    billingType: 'Individual',
    registrationFee: '0',
    status: 'Active',
    visibility: 'Public',
    effectiveStartDate: '',
    effectiveEndDate: '',
    order: null,
  },
  {
    id: '6305708d4c40fb570b7aac1b',
    tenantOfferingId: '7B586FC3-B8C2-4322-9264-ECE11EA691A2',
    tenantId: '7EB541E4-91A9-4DEB-BB7E-55813D3CA140',
    enrollmentCode: 'DPC',
    category: 'Individual & Family',
    name: 'Senior Membership (65+)',
    ageBand: 'Y',
    minAge: '66',
    maxAge: '',
    minQuantity: '',
    maxQuantity: '',
    price: 40,
    billingInterval: 'Monthly',
    billingType: 'Individual',
    registrationFee: 10,
    status: 'Active',
    visibility: 'Public',
    effectiveStartDate: null,
    effectiveEndDate: null,
    order: null,
  },
];

const SELECTED_OFFERINGS = [
  {
    id: '6305708d4c40fb570b7aac1d',
    tenantOfferingId: 'C8CC0B35-83D3-4A57-9053-41A4DCEF6181',
    tenantId: '7EB541E4-91A9-4DEB-BB7E-55813D3CA140',
    enrollmentCode: 'DPC',
    category: 'Individual & Family',
    name: 'Individual membership (with $7 co-pay) - Monthly',
    ageBand: 'N',
    minAge: 0,
    maxAge: 0,
    minQuantity: 2,
    maxQuantity: 2,
    price: '30',
    billingInterval: 'Monthly',
    billingType: 'Family',
    registrationFee: '10',
    status: 'Active',
    visibility: 'Public',
    effectiveStartDate: '',
    effectiveEndDate: '',
    order: null,
    isRecommended: true,
    offeringType: commonConstants.OFFERING_TYPE_PRIMARY,
  },
];

const OFFERINGS_COMPANY = [
  {
    id: '6305708d4c40fb570b7aa7d5',
    tenantOfferingId: '91ECDDD2-D634-40A6-90CD-F41E507C98E2',
    tenantId: '7EB541E4-91A9-4DEB-BB7E-55813D3CA140',
    enrollmentCode: 'DPC',
    category: 'Company & Group',
    name: 'Company with less than 5',
    ageBand: 'N',
    minAge: 0,
    maxAge: 0,
    minQuantity: 0,
    maxQuantity: 4,
    price: 30,
    billingInterval: 'Monthly',
    billingType: 'Individual',
    registrationFee: 8,
    status: 'Active',
    visibility: 'Public',
    effectiveStartDate: '',
    effectiveEndDate: '',
    order: null,
  },
  {
    id: '632052594dbd960a64bd68af',
    tenantOfferingId: 'f265bebb-655a-44db-895b-2ecb0136ad27',
    tenantId: '7EB541E4-91A9-4DEB-BB7E-55813D3CA140',
    enrollmentCode: 'KTMDPC',
    category: 'Company & Group',
    name: 'New One',
    minAge: 0,
    maxAge: 0,
    minQuantity: 0,
    maxQuantity: 0,
    price: 5,
    billingInterval: 'Monthly',
    billingType: 'Individual',
    registrationFee: 0,
    status: 'Active',
    visibility: 'Public',
    effectiveStartDate: '09/13/2022',
    effectiveEndDate: '',
    isRecommended: true,
  },
];

const SELECTED_OFFERINGS_COMPANY = [
  {
    id: '6305708d4c40fb570b7aa7d5',
    tenantOfferingId: '91ECDDD2-D634-40A6-90CD-F41E507C98E2',
    tenantId: '7EB541E4-91A9-4DEB-BB7E-55813D3CA140',
    enrollmentCode: 'DPC',
    category: 'Company & Group',
    name: 'Company with less than 5',
    ageBand: 'N',
    minAge: 0,
    maxAge: 0,
    minQuantity: 0,
    maxQuantity: 4,
    price: 30,
    billingInterval: 'Monthly',
    billingType: 'Individual',
    registrationFee: 8,
    status: 'Active',
    visibility: 'Public',
    effectiveStartDate: '',
    effectiveEndDate: '',
    order: null,
    isChecked: true,
    offeringType: 'OFFERING_PRIMARY',
    dependent: null,
  },
];

const NO_OF_EMPLOYEE = 50;

const initialState = {
  step: INFORMATION,
  informationTab: getInformationFormDefaultValues(),
  // dependents: DEPENDENTS,
  dependents: [],
  noOfEmployee: '',
  // noOfEmployee: NO_OF_EMPLOYEE,
  benefitStartDate: momentViewFormat(new Date()),
  // offerings: OFFERINGS,
  offerings: [],
  // selectedOfferings: SELECTED_OFFERINGS,
  selectedOfferings: [],
  payment: {
    paymentMethod: '',
    // to retain the entered card/bank information on component unmount
    tmpPaymentData: {
      card: {
        token: '',
        expiry: '',
        // Card Number, CVC, tokenSource are populated only for Authorize.Net
        cardNumber: '',
        cvc: '',
        tokenSource: '',
      },
      bank: {
        token: '',
        // Account Number, Routing Number, tokenSource are populated only for Authorize.Net
        accountNumber: '',
        routingNumber: '',
        tokenSource: '',
        accountType: '',
      },
    },
    errorMessage: '', // error message from the server
    cardErrorMessage: '', // error message specific to card set from the UI
    bankErrorMessage: '', // error message specific to bank set from the UI
    iAgreeTerms: false,
    iAgreePSATerms: false,
  },
  referredBy: null,
  referredByOptions: [],
  loadingState: '',
  isQuestionaireEnabled: false,
};

export const slice = createSlice({
  name: 'membership',
  initialState,
  reducers: {
    // Changing the state directly (with mutation) in reducer
    // Redux Toolkit uses Immer under the hood (takes care of immutablility)
    setStep: (state, action) => {
      state.step = action.payload;
    },

    setInformationTabValues: (state, action) => {
      state.informationTab = { ...state.informationTab, ...action.payload };
    },

    setLoadingState: (state, action) => {
      state.loadingState = action.payload;
    },

    addDependent: (state, action) => {
      state.dependents = [...state.dependents, action.payload];
    },

    editDependent: (state, action) => {
      state.dependents = state.dependents.map((dependent) => {
        if (dependent.clientId !== action.payload.clientId) return dependent;
        return action.payload;
      });
    },

    deleteDependent: (state, action) => {
      state.dependents = state.dependents.filter(
        (dependent) => dependent.clientId !== action.payload.clientId
      );
    },

    setBenefitStartDate: (state, action) => {
      state.benefitStartDate = action.payload;
    },

    setNoOfEmployee: (state, action) => {
      state.noOfEmployee = action.payload;
    },

    setOfferings: (state, action) => {
      state.offerings = [...action.payload];
    },

    setSelectedOfferings: (state, action) => {
      state.selectedOfferings = [...action.payload];
    },

    setPayment: (state, action) => {
      state.payment = { ...state.payment, ...action.payload };
    },

    setReferredBy: (state, action) => {
      state.referredBy = { ...action.payload };
    },

    setReferredByOptions: (state, action) => {
      state.referredByOptions = [...action.payload];
    },

    setIsQuestionaireEnabled: (state, action) => {
      state.isQuestionaireEnabled = action.payload;
    },

    resetAll: () => initialState,
  },
});

// Actions
export const {
  setStep,
  setInformationTabValues,
  addDependent,
  editDependent,
  deleteDependent,
  setLoadingState,
  setBenefitStartDate,
  setNoOfEmployee,
  setOfferings,
  setSelectedOfferings,
  setPayment,
  setReferredBy,
  setReferredByOptions,
  setIsQuestionaireEnabled,
  resetAll,
} = slice.actions;

// Selectors
export const selectStep = (state) => state.membership.step;
export const selectInformationTab = (state) => state.membership.informationTab;
export const selectDependents = (state) => state.membership.dependents;
export const selectBenefitStartDate = (state) =>
  state.membership.benefitStartDate;
export const selectNoOfEmployee = (state) => state.membership.noOfEmployee;
export const selectOfferings = (state) => state.membership.offerings;
export const selectSelectedOfferings = (state) =>
  state.membership.selectedOfferings;
export const selectPayment = (state) => state.membership.payment;
export const selectReferredBy = (state) => state.membership.referredBy;
export const selectReferredByOptions = (state) =>
  state.membership.referredByOptions;
export const selectIsQuestionaireEnabled = (state) =>
  state.membership.isQuestionaireEnabled;
export const selectLoadingState = (state) => state.membership.loadingState;

// Reducer
export default slice.reducer;
