import React from 'react';
import Tabs from './Tabs';
import CardPayment from './CardPayment';
import AchPayment from './AchPayment';
import { PAYMENT_ENVIRONMENT } from '../../../constants/payment';

class PaymentInformation extends React.Component {
  render() {
    const {
      activeTab,
      changeActiveTab,
      cardPaymentRef,
      achPaymentRef,
      client,
      submit,
      changePaymentData,
      cosmicContent,
      paymentEnvironment,
    } = this.props;
    const monthlyCost = client && client.monthlyCost;
    const totalCost =
      client.totalMembers > 0
        ? parseInt(monthlyCost) * parseInt(client.totalMembers)
        : parseInt(monthlyCost);
    const paymentBaseUrl =
      paymentEnvironment &&
      paymentEnvironment.toLowerCase() === PAYMENT_ENVIRONMENT.LIVE
        ? process.env.REACT_APP_PAYMENT_LIVE_BASE_URL
        : process.env.REACT_APP_PAYMENT_TEST_BASE_URL;

    return (
      <div className="container mx-auto px-4">
        <div className="w-full lg:pr-32">
          <Tabs activeTab={activeTab} changeTab={changeActiveTab} />
          {activeTab === 1 ? (
            <CardPayment
              cardPaymentRef={cardPaymentRef}
              client={client}
              submit={submit}
              changePaymentData={changePaymentData}
              totalCost={totalCost}
              cosmicContent={cosmicContent}
              errorMessage={this.props.cardErrorMessage}
              clearErrorMessage={this.props.clearCardErrorMessage}
              paymentBaseUrl={paymentBaseUrl}
              tempPaymentData={this.props.tempPaymentData}
              changeTempPaymentData={this.props.changeTempPaymentData}
            />
          ) : (
            <AchPayment
              achPaymentRef={achPaymentRef}
              client={client}
              submit={submit}
              changePaymentData={changePaymentData}
              totalCost={totalCost}
              cosmicContent={cosmicContent}
              errorMessage={this.props.bankErrorMessage}
              clearErrorMessage={this.props.clearBankErrorMessage}
              paymentBaseUrl={paymentBaseUrl}
            />
          )}
        </div>
      </div>
    );
  }
}

export default PaymentInformation;
