import React, { Component } from 'react';

import {
  BannerWithSearch,
  ServiceAutocomplete,
  LocationAutocomplete,
} from '../../common';

import { formatCurrency, formatPercent } from '../../util/vitafyUtil';
import * as locationUtil from '../../util/locationUtil';

import commonConstants from '../../constants/commonConstant';

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchLocation: '',
      searchZipCode: null,
      searchKeyword: '',
      searchCode: null,
      searchCity: null,
      searchState: null,
      searchPrimaryCode: null,
      selectedService: null,
      searchLatitude: null,
      searchLongitude: null,
      pageLimit: 200,
      pageOffset: 0,
      pageNeighbours: 2,
      currentPage: 1
    }
  }

  componentDidMount() {
    // Ask location permission
    navigator.geolocation.getCurrentPosition(this.setCurrentLocation, () => {//in case of location denial
      let location = commonConstants.DEFAULT_LOCATION;
      this.setState({
        searchLocation: location.city + ', ' + location.state,
        searchZipCode: location.zip,
        searchCity: location.city,
        searchState: location.state,
        searchLatitude: location.latitude,
        searchLongitude: location.longitude,
      })
    })
  }

  setCurrentLocation = async (location) => {
    let rLocation = await locationUtil.getLocation(location);
    this.setState({
      searchLocation: rLocation.locationName,
      searchZipCode: rLocation.zip,
      searchCity: rLocation.city,
      searchState: rLocation.state,
      searchLatitude: rLocation.latitude,
      searchLongitude: rLocation.longitude,
    });
  }

  handleInputChange = (e, field, clearFields = []) => {
    let value = e.target ? e.target.value : e;
    if ((e.target ? e.target.validity.valid : e) || value == '') {
      let newState = {
        [field]: value,
      };
      clearFields.map((x) => (newState[x] = null));
      this.setState((prevState) => ({
        ...prevState,
        ...newState,
      }));
    }
  };

  onSelectHandler = (
    selectedItem,
    fields = [],
    setValueFields = [],
    setSelectedItem = false
  ) => {
    let newState = {};
    setValueFields.map(
      (x, index) => (newState[x] = selectedItem[fields[index]])
    );
    this.setState((prevState) => ({
      ...prevState,
      ...newState,
      selectedService: setSelectedItem
        ? selectedItem
        : prevState.selectedService,
    }));
  };

  searchHandler = () => {
    const searchedData = {
      searchLocation: this.state.searchLocation,
      searchZipCode: this.state.searchZipCode,
      searchKeyword: this.state.searchKeyword,
      searchCode: this.state.searchCode,
      searchCity: this.state.searchCity,
      searchState: this.state.searchState,
      searchPrimaryCode: this.state.searchPrimaryCode,
      selectedService: this.state.selectedService,
      searchLatitude: this.state.searchLatitude,
      searchLongitude: this.state.searchLongitude,
      pageLimit: this.state.pageLimit,
      pageOffset: this.state.pageOffset,
      pageNeighbours: this.state.pageNeighbours,
      currentPage: this.state.currentPage
    }
    this.props.search(searchedData);
  }

  render() {
    const { title, subtitle, subtitleVisibility } = this.props.banner;
    return (
      <BannerWithSearch
        title={title}
        subtitle={subtitle}
        subtitleVisibility={subtitleVisibility}
        leftSearch={
          <ServiceAutocompleteInput
            handleInputChange={this.handleInputChange}
            onSelectHandler={this.onSelectHandler}
            searchKeyword={this.state.searchKeyword}
          />
        }
        rightSearch={
          <LocationAutocompleteInput
            handleInputChange={this.handleInputChange}
            onSelectHandler={this.onSelectHandler}
            searchLocation={this.state.searchLocation}
          />
        }
        searchResult={
          this.props.searched ?
            <SearchResult
              summaryCost={this.props.summaryCost}
              selectedService={this.state.selectedService}
              searchCity={this.state.searchCity}
              searchState={this.state.searchState}
              requestDemoClick={this.props.requestDemoClick}
              disclaimerPreviewText={this.props.disclaimerPreviewText}
              openDisclaimerModal={this.props.openDisclaimerModal}
            />
            : null
        }
        searchHandler={this.searchHandler}
        isSubmitting={this.props.isSubmitting}
        showOr={false}
      />
    );
  }
}

const ServiceAutocompleteInput = (props) => {
  return (
    <ServiceAutocomplete
      placeholder="Search by a procedure name or cpt code"
      inputRequired={false}
      className="filled-input focus:outline-none"
      onSelectHandler={(e) =>
        props.onSelectHandler(
          e,
          ['displayName', 'code', 'primaryCode'],
          ['searchKeyword', 'searchCode', 'searchPrimaryCode'],
          true
        )
      }
      handleInputChange={(e) =>
        props.handleInputChange(e, 'searchKeyword', [
          'searchCode',
          'searchPrimaryCode',
        ])
      }
      displayNameValue={props.searchKeyword}
    />
  );
}

const LocationAutocompleteInput = (props) => {
  return (
    <LocationAutocomplete
      placeholder='Search by Location'
      inputRequired={false}
      iconType='location'
      onSelectHandler={(e) =>
        props.onSelectHandler(
          e,
          ['displayName', 'zip', 'st', 'city', 'lat', 'lng'],
          [
            'searchLocation',
            'searchZipCode',
            'searchState',
            'searchCity',
            'searchLatitude',
            'searchLongitude'
          ]
        )
      }
      handleInputChange={(e) =>
        props.handleInputChange(e, 'searchLocation', [
          'searchCity',
          'searchState',
          'searchZipCode',
        ])
      }
      displayNameValue={props.searchLocation}
    />
  );
}

const SearchResult = (props) => {
  const { summaryCost, requestDemoClick, disclaimerPreviewText, openDisclaimerModal } = props;
  return (
    <div className='md:pr-18 mt-12'>
      <p className='text-center text-white'>The Vitafy cost for <span className='font-bold text-lg'>{props.selectedService?.displayName}</span> in <span className='font-bold text-lg'>{props.searchCity}, {props.searchState}</span> is <span className='font-bold text-lg'>{summaryCost?.vitafyCost && formatCurrency(summaryCost.vitafyCost, true, 0, 0)} *
      </span> which is <span className='font-bold text-lg'>{summaryCost?.savePercent && formatPercent(summaryCost.savePercent, true, 0, 0)}</span> lower than the state average. </p>
      <p className='text-center text-white'>Click <span className='underline cursor-pointer hover:text-secondary' onClick={requestDemoClick}>here</span> for more information.</p>

      <p className='text-sm text-center text-white mt-8'>{disclaimerPreviewText} (<span className='cursor-pointer underline hover:text-secondary' onClick={() => openDisclaimerModal()}>See full disclaimer</span>)</p>
    </div>
  );
}


export default Search;