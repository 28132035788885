import React from 'react';
import { Modal } from '../../common';
import { parseHtml } from '../../util';

const DisclaimerModal = (props) => {
  return (
    <Modal
      modalTitle={props.title ? props.title : ''}
      ref={props.modalRef}
      maxWidth='max-w-xl'
      marginTop='mt-32'
      hideHeader={false}
      showCloseButton={false}
      closeHandler={props.closeHandler}
    >
      <div
        className={`font-heebo text-base text-bodyColor`}
        dangerouslySetInnerHTML={{
          __html: parseHtml(props.body),
        }}
      />
    </Modal>
  );
}
export default DisclaimerModal;
