import React from 'react';
import { getSubDomain } from '../util/miscellaneousUtil';
import subDomainMap from '../constants/subdomainMap';
class MainLayout extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const themeClassName =
      subDomainMap[getSubDomain()]?.themeClass || 'theme-dpc';
    return (
      <div className={`${themeClassName} flex flex-col min-h-screen`}>
        {this.props.children}
      </div>
    );
  }
}

export default MainLayout;
