import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import logo from '../../assets/images/BMT.png';

class BmtHeader extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    // const currentRoute = this.props.location.pathname;
    return (
      <nav
        className={`transition duration-500 ease-in-out fixed flex items-center justify-between px-4 py-3 top-0 w-full z-40 bg-white shadow`}
      >
        <NavLink exact to='/' className='z-50'>
          <img
            src={logo}
            className='block w-35 xxl:w-40'
            alt='bmt-logo'
          />
        </NavLink>
      </nav>
    );
  }
}

export default BmtHeader;