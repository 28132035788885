import React from 'react';
import ReactTooltip from 'react-tooltip';

import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FileList = ({ file, handleFileDelete }) => {
  return (
    <li className="flex justify-between mb-3" key={file.id}>
      <div
        style={{ borderBottom: '1px solid #cbd5e0' }}
        className="text-left flex-grow text-sm"
      >
        <p>{file.name}</p>
      </div>
      <div className="text-right">
        <button
          data-tip
          data-for="delete"
          type="button"
          className="small-button bg-transparent p-2 text-bodyColor hover:bg-gray-300 hover:text-errorTxt"
          onClick={() => handleFileDelete(file.id)}
        >
          <FontAwesomeIcon icon={faTrashAlt} size="xs" />
        </button>
        <ReactTooltip id="delete" type="dark">
          <span className="body-text text-sm text-white">Delete file</span>
        </ReactTooltip>
      </div>
    </li>
  );
};

export default FileList;
