import React, { Component } from "react";
import {
  Header,
  Loader,
  Banner,
  ValueProp,
  CallToAction,
  Benefit,
  ClientStory,
  Footer,
} from "../../common";
import ctaModalTitle from "../../constants/ctaModalTitle";
import * as service from "../../service";
import { ReactTitle } from "react-meta-tags";
import commonConstants from "../../constants/commonConstant";

class DirectCareProviders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cosmicContent: {
        banner: {},
        valueProps: {},
        cta: {},
        clientStories: [],
        benefits: [],
        seo: {},
      },
      loading: true,
    };
  }

  componentDidMount() {
    this.getDirectCareProvidersContent();
    this.getClientStories();
  }

  getDirectCareProvidersContent = () => {
    service
      .getDirectCareProvidersContent()
      .then((response) => {
        if (response.status === "success") {
          const cosmicContent = response.data.find(
            (item) => item.slug === "direct-care-providers"
          ).metadata;
          this.setState((prevState) => ({
            cosmicContent: {
              ...prevState.cosmicContent,
              banner: cosmicContent.banner,
              valueProps: cosmicContent.value_props,
              cta: cosmicContent.cta,
              benefits: cosmicContent.benefits,
              remark: cosmicContent.remark,
              seo: cosmicContent.seo,
            },
          }));
        }
      })
      .catch((error) => {
        console.log("Error in get DPC Sales cosmic content", error);
      });
  };

  getClientStories = () => {
    service
      .getClientStories()
      .then((result) => {
        if (result.status === "success") {
          this.setState((prevState) => ({
            cosmicContent: {
              ...prevState.cosmicContent,
              clientStories: result.data,
            },
            loading: false,
          }));
        }
      })
      .catch((error) => {
        console.log("Error in get DPC Sales: Client Stories", error);
        this.setState({ loading: false });
      });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <ReactTitle
          title={
            this.state.cosmicContent.seo?.metadata?.page_title ||
            commonConstants.SEO.DEFAULT_PAGE_TITLE
          }
        />

        <Header />
        <Banner banner={this.state.cosmicContent.banner} />

        <div className="mid-divider"></div>

        <ValueProp
          title={this.state.cosmicContent.valueProps?.mission_statement}
          valuePropsTitleVisibility={false}
          values={this.state.cosmicContent.valueProps?.value_props}
          bgColor={false}
        />

        <CallToAction
          title={this.state.cosmicContent.cta?.title}
          description={this.state.cosmicContent.cta?.content}
          actionButtonText={this.state.cosmicContent.cta?.action_button_text}
          actionButtonLink="/direct-primary-care/register?plan=Professional&interval=annually"
          requestDemo={false}
        />
        <div className="divider"></div>

        <Benefit benefits={this.state.cosmicContent?.benefits} />

        <div className="divider"></div>

        <CallToAction
          title={this.state.cosmicContent.remark?.title}
          description={this.state.cosmicContent.remark?.subtitle}
          actionButtonText={this.state.cosmicContent.remark?.action_button_text}
          actionButtonLink="/"
          requestDemo={true}
          modalTitle={ctaModalTitle.CONTACT_OUR_TEAM}
        />

        <div className="divider"></div>

        <ClientStory
          clientStories={this.state.cosmicContent.clientStories}
          partners={
            this.state.cosmicContent.partners?.metadata?.partners_images
          }
          visibility={
            this.state.cosmicContent.partners?.metadata
              ?.entire_section_visibility
          }
          bgColor={false}
        />

        <Footer />
      </>
    );
  }
}

export default DirectCareProviders;
