import React, { useContext } from 'react';

import { Loader } from '../../common';

import Banner from '../dedicated-dpc-landing/Banner';
import Branches from './Branches';
import CustomerCare from '../dedicated-dpc-landing/CustomerCare';

import { store } from '../../store';

const MultipleLocationLanding = () => {
  const state = useContext(store);
  const { locations, primaryLocation, isLoading } = state.globalState;

  if(isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <Banner
        tagLine={primaryLocation?.metaData?.tagLine}
        locationCount={locations?.length}
      />
      <Branches
        locations={locations}
      />
      <div className='mid-divider mb-16 md:mb-0'></div>
      <CustomerCare
        phone={primaryLocation?.phone}
        email={primaryLocation?.website}
      />
      <div className='h-12 md:h-24'></div>
    </div>
  );
}

export default MultipleLocationLanding;