import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faMale,
  faFemale,
  faEnvelope,
  faMobileAlt,
  faMapMarkerAlt,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import * as momentUtil from "../../../util/momentUtil";
import {
  MaskedDateInput,
  MaskedPhoneInput,
  PlaceAutocomplete,
  MDInput,
  MaskedNumberInput,
} from "../../../common";
import commonConstants from "../../../constants/commonConstant";
import * as vitafyUtils from "../../../util/vitafyUtil";

const ClientInfoIndividualForm = (props) => {
  const {
    register,
    errors,
    setValue,
    clearError,
    handleSubmit,
    getValues,
  } = useForm();
  const { client, address } = props;
  const checkedGender = getValues().gender;
  const formBuilder = () => {
    register({ name: "clientType" });
    register({ name: "salesAgent" });
    register({ name: "businessName" });
    register({ name: "firstName" }, { required: true });
    register({ name: "lastName" }, { required: true });
    register(
      { name: "dob" },
      {
        validate: (value) =>
          client && client.dob
            ? momentUtil.momentIsValid(client.dob) &&
            momentUtil.momentIsSameorBefore(client.dob)
            : true,
        required: client.clientType === 'Individual' ? true : false
      }
    );
    register({ name: "last4SSN" }, {
      validate: (value) => !value || (value && value.length === 4)
    });
    register({ name: "gender" });
    register({ name: "addressLine1" });
    register({ name: "addressLine2" });
    register({ name: "city" });
    register({ name: "state" });
    register({ name: "zip" });
    register({ name: "lat" });
    register({ name: "lng" });
    register(
      { name: "email" },
      {
        required: true,
        pattern: /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/i,
      }
    );
    // register({ name: 'email' });
    register({ name: "phone" });
    register({ name: "totalMembers" });
    register({ name: "effStartDate" });
    register({ name: "note" });
    register({ name: 'referringAgent' })
    setValue([
      { clientType: client.clientType ? client.clientType : "Company" },
      { salesAgent: client.salesAgent },
      { businessName: client.businessName },
      { firstName: client.firstName },
      { lastName: client.lastName },
      { last4SSN: client.last4SSN },
      { dob: client.dob },
      { gender: client.gender },
      { addressLine1: address ? address.addressLine1 : "" },
      { addressLine2: address ? address.addressLine2 : "" },
      { city: address ? address.city : "" },
      { state: address ? address.state : "" },
      { zip: address ? address.zip : "" },
      { lat: address?.lat ? address.lat : null },
      { lng: address?.lng ? address.lng : null },
      { phone: client.phone },
      { email: client.email ? client.email : "" },
      { totalMembers: client.totalMembers },
      { effStartDate: client.effStartDate },
      { note: client.note },
      { referringAgent: client.referringAgent },
    ]);
  };
  React.useEffect(() => {
    formBuilder();
  }, []);
  React.useLayoutEffect(() => {
    formBuilder();
  }, [client.clientType]);
  const handleInputChange = (e, field) => {
    let value = e.target ? e.target.value : e;
    if ((e.target ? e.target.validity.valid : e) || value === "") {
      setValue(field, value);
      if (commonConstants.ADDRESS_FIELDS.indexOf(field) > -1) {
        address[field] = value;
      } else {
        client[field] = value;
      }
      clearError(field);
    }
  };
  const formSubmit = (formValues) => {
    formValues.agentClientId = client.agentClientId;
    formValues.id = client.id;
    formValues.address = {
      addressLine1: formValues.addressLine1,
      addressLine2: formValues.addressLine2 ? formValues.addressLine2 : null,
      city: formValues.city,
      state: formValues.state,
      zip: formValues.zip,
      lat: formValues.lat,
      lng: formValues.lng,
    };
    formValues.businessName = null;
    formValues.dob = client.dob
      ? momentUtil.momentSaveFormat(client.dob)
      : null;
    formValues.agentClientType =
      client.agentClientId || client.agent?.agentId
        ? formValues.clientType
        : null;
    // formValues.salesAgent = client.agentClientId ? client.salesAgent : client.agent?.agentId ? vitafyUtils.getDisplayName(client.agent) : null;
    formValues.salesAgent = client.agentClientId
      ? client.salesAgent
      : client.agent?.agentId
        ? vitafyUtils.getFullName(client.agent)
        : null;
    formValues.phone = formValues.phone
      ? vitafyUtils.unmaskPhone(formValues.phone)
      : null;
    props.submit(2, formValues);
  };
  const setGoogleAutocompleteAddress = (addr) => {
    for (const field in addr) {
      if (addr.hasOwnProperty(field)) {
        address[field] = addr[field];
        const element = addr[field];
        setValue(field, element);
        clearError(field);
      }
    }
  };
  return (
    <form
      ref={props.clientInfoRef}
      onSubmit={handleSubmit(formSubmit)}
      className="pt-2 py-6 md:py-3"
    >
      <div className="flex flex-wrap md:-mx-5 mb-3">
        {/* First Name */}
        <div className="w-full md:w-1/2 md:px-5 mb-3 md:mb-0">
          <MDInput
            id="first-name"
            label="First Name"
            icon={faUser}
            name="firstName"
            value={client?.firstName ? client.firstName : ""}
            inputChange={(e) => handleInputChange(e, "firstName")}
            error={errors.firstName}
          />
        </div>
        {/* Last Name */}
        <div className="w-full md:w-1/2 md:px-5">
          <MDInput
            id="last-name"
            label="Last Name"
            name="lastName"
            value={client?.lastName ? client.lastName : ""}
            inputChange={(e) => handleInputChange(e, "lastName")}
            error={errors.lastName}
          />
        </div>
      </div>

      <div className="flex flex-wrap md:-mx-5 mb-1">
        <div className="w-full md:w-1/2 md:px-5">
          <div className="flex -mx-5">
            {/* Last Four SSN */}
            <div className="w-1/2 px-5">
              <MaskedNumberInput
                input={client?.last4SSN ? client.last4SSN : ""}
                setInput={(e) => handleInputChange(e, "last4SSN")}
                name="last4SSN"
                label="Last Four SSN"
                error={errors.last4SSN}
              />
            </div>
            {/* Date of Birth */}
            <div className="w-1/2 px-5">
              <MaskedDateInput
                id="date-of-birth"
                // input={client && client.dob ? momentUtil.momentViewFormat(client.dob) : null}
                input={
                  client && client.dob
                    ? momentUtil.momentIsValid(client.dob)
                      ? momentUtil.momentViewFormat(client.dob)
                      : client.dob
                    : null
                }
                setInput={(e) => handleInputChange(e, "dob")}
                name="dob"
                label="Date of Birth"
                error={errors.dob}
              />
            </div>
          </div>
        </div>

        <div className="w-full md:w-1/2 md:px-5">
          {/* Gender */}
          <label className="form-label text-sm mb-1 -mt-3" htmlFor="date-of-birth">
            Gender
          </label>
          <div className="flex -mx-5">
            <div className="w-1/2 px-5">
              <div
                className={`h-12 rounded-md bg-white flex justify-center items-center cursor-pointer ${checkedGender?.toUpperCase() === "M" ? "relative" : null
                  } `}
                onClick={() => handleInputChange("M", "gender")}
              >
                {checkedGender?.toUpperCase() === "M" ? (
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="text-2xl text-extended absolute top-0 left-0 pl-2 pt-2"
                  />
                ) : null}

                <FontAwesomeIcon
                  icon={faMale}
                  className={`text-2xl mr-3 ${checkedGender?.toUpperCase() === "M"
                    ? "text-extended"
                    : "text-bodyColor"
                    }`}
                />
                <p
                  className={`font-heebo text-lg ${checkedGender?.toUpperCase() === "M"
                    ? "text-extended"
                    : "text-titleColor "
                    }`}
                >
                  Male
                </p>
              </div>
            </div>

            <div className="w-1/2 px-5">
              <div
                className={`h-12 rounded-md bg-white flex justify-center items-center cursor-pointer ${checkedGender?.toUpperCase() === "F" ? "relative" : null
                  } `}
                onClick={() => handleInputChange("F", "gender")}
              >
                {checkedGender?.toUpperCase() === "F" ? (
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="text-2xl text-extended absolute top-0 left-0 pl-2 pt-2"
                  />
                ) : null}

                <FontAwesomeIcon
                  icon={faFemale}
                  className={`text-2xl mr-3 ${checkedGender?.toUpperCase() === "F"
                    ? "text-extended"
                    : "text-bodyColor"
                    }`}
                />
                <p
                  className={`font-heebo text-lg ${checkedGender?.toUpperCase() === "F"
                    ? "text-extended"
                    : "text-titleColor "
                    }`}
                >
                  Female
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap md:-mx-5 mb-3">
        {/* Email */}
        <div className="w-full md:w-1/2 md:px-5 mb-3 md:mb-0">
          <MDInput
            id="email"
            label="Email"
            icon={faEnvelope}
            name="email"
            value={client?.email ? client.email : ""}
            inputChange={(e) => handleInputChange(e, "email")}
            error={errors.email}
          />
        </div>

        {/* Phone */}
        <div className="w-full md:w-1/2 md:px-5">
          <MaskedPhoneInput
            input={client && client.phone ? client.phone : null}
            setInput={(e) => handleInputChange(e, "phone")}
            name="phone"
            label="Phone"
            icon={faMobileAlt}
          />
        </div>
      </div>

      <div className="flex flex-wrap md:-mx-5 mb-1">
        {/* Address Line 1 */}
        <div className="w-full md:w-1/2 md:px-5 mb-3 md:mb-0">
          <PlaceAutocomplete
            // updateInputAddress={setAddress}
            id="individual-address"
            addressLine1={address?.addressLine1 ? address.addressLine1 : ""}
            setGoogleAutocompleteAddress={(e) =>
              setGoogleAutocompleteAddress(e)
            }
            label="Address Line 1"
            icon={faMapMarkerAlt}
          />
        </div>
        {/* Address Line 2 */}
        <div className="w-full md:w-1/2 md:px-5">
          <MDInput
            id="addressLine2"
            label="Address Line 2"
            name="addressLine2"
            value={address && address.addressLine2 ? address.addressLine2 : ""}
            inputChange={(e) => handleInputChange(e, "addressLine2")}
            error={errors.addressLine2}
          />
        </div>
      </div>

      <div className="flex flex-wrap md:-mx-5">
        {/* City */}
        <div className="w-full md:w-1/2 md:px-5 mb-6 md:mb-0">
          <MDInput
            id="city"
            label="City"
            name="city"
            value={address && address.city ? address.city : ""}
            inputChange={(e) => handleInputChange(e, "city")}
            error={errors.city}
          />
        </div>

        <div className="w-full md:w-1/2 md:px-5">
          <div className="flex -mx-5">
            {/* State */}
            <div className="w-1/2 px-5">
              <MDInput
                id="state"
                label="State"
                name="state"
                value={address && address.state ? address.state : ""}
                inputChange={(e) => handleInputChange(e, "state")}
                error={errors.state}
              />
            </div>

            <div className="w-1/2 px-5">
              {/* Zip */}
              <MDInput
                id="zip"
                label="Zip"
                name="zip"
                value={address && address.zip ? address.zip : ""}
                inputChange={(e) => handleInputChange(e, "zip")}
                error={errors.zip}
              />
            </div>
          </div>
        </div>
      </div>
      <MDInput
        id="referringAgent"
        label="Referring Agent"
        name="referringAgent"
        value={client?.referringAgent ? client.referringAgent : ''}
        inputChange={(e) => handleInputChange(e, 'referringAgent')}
        error={errors.referringAgent}
      />
    </form>
  );
};

export default ClientInfoIndividualForm;
