import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import AsyncSelect from 'react-select/async';

import commonConstants from '../../../../constants/commonConstant';

import * as enrollmentService from '../../../../service/enrollmentService';
import { store } from '../../../../store';
import {
  selectReferredByOptions,
  setReferredBy,
  setReferredByOptions,
} from '../../../../stores/membership';
import { getFullName } from '../../../../util/vitafyUtil';

const { PAGINATION_FETCH_ALL } = commonConstants;

const ReferringAgentSelect = ({ data, onChange }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(location.search);
  const referredBy = queryParams.get('referredby'); // gives referring agent code

  // Context Store
  const state = useContext(store);
  const { globalState } = state;
  const { tenantId } = globalState;

  const referredByOptions = useSelector(selectReferredByOptions);
  const totalReferredByOptions = referredByOptions?.length || 0;

  const [isDisabled, setIsDisabled] = useState(false);

  const loadOptions = (inputValue, callback) => {
    // Filter the options based on the search value
    const filteredOptions = referredByOptions.filter(
      (option) =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
    );
    // Return the filtered options
    callback(filteredOptions);
  };

  useEffect(() => {
    const fetchReferringAgents = async (tenantId) => {
      const queryParams = {
        tenantId: tenantId,
        limit: PAGINATION_FETCH_ALL.LIMIT,
        offset: PAGINATION_FETCH_ALL.OFFSET,
        sortBy: 'name',
        sortOrder: 'ASC',
      };

      try {
        const response = await enrollmentService.fetchAgents(queryParams);
        const referringAgentOptions = response.data.rows.map((item) => {
          item.displayName = getFullName({
            firstName: item.firstName || '',
            lastName: item.lastName || '',
            middleName: item.middleName || '',
          });

          // If referredBy is present in the query param, disable the field
          if (
            referredBy &&
            item.code?.toLowerCase()?.trim() ===
              referredBy?.toLowerCase()?.trim()
          ) {
            onChange({
              label: item.displayName,
              value: item.contactId,
            });
            setIsDisabled(true);
            dispatch(setReferredBy(item));
          }

          return {
            label: item.displayName.trim(),
            value: item.contactId,
          };
        });

        dispatch(setReferredByOptions(referringAgentOptions));
      } catch (error) {
        console.log('Error while fetching referring agents', error);
      }
    };

    if (tenantId && !totalReferredByOptions) {
      fetchReferringAgents(tenantId);
    }
  }, [tenantId, referredBy, onChange, totalReferredByOptions, dispatch]);

  return (
    <>
      <AsyncSelect
        isDisabled={isDisabled}
        loadOptions={loadOptions}
        defaultOptions={
          data?.value ? referredByOptions : referredByOptions?.slice(0, 5)
        }
        value={{
          label: data?.label || '',
          value: data?.value || '',
        }}
        onChange={(option) =>
          onChange({
            label: option && option.label,
            value: option && option.value,
          })
        }
        isClearable
      />
    </>
  );
};

export default React.memo(ReferringAgentSelect);
