import React from 'react';
import withSizes from 'react-sizes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Checkbox, ButtonLoader, FormErrorMessage } from '../../common';

class CardPayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      requiredInput: false,
      invalidInput: false,
      token: '',
      expiry: ''
      // isIframeLoading: true
    }
  }

  componentDidMount() {
    window.addEventListener("message", this.listen);
    // this.props.clearErrorMessage();
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.listen);
  }

  listen = (event) => {
    // console.log('Received message ' + event.data);
    if (typeof (event.data) == 'string') {

      // 1004 -> Card Number Required
      // 1005 -> CVC Required
      // 1006 -> Expiry Required

      // 1001 -> Invalid Card Number
      // 1002 -> Invalid CVC
      // 1003 -> Invalid Expiry
      try {
        const response = JSON.parse(event.data);
        response.errorCode &&
          this.setState({
            requiredInput: (response.errorCode === '1004' || response.errorCode === '1005' || response.errorCode === '1006') ? true : false,
            invalidInput: (response.errorCode === '1001' || response.errorCode === '1002' || response.errorCode === '1003') ? true : false,
            token: '',
            expiry: ''
          }, () => {
            if (!this.state.requiredInput && !this.state.invalidInput && response && response.errorCode === '0') {
              this.setState({ token: response.token, expiry: response.expiry });
              this.props.handleChangeTmpPaymentData('card', { token: response.token, expiry: response.expiry });
              this.props.handleRemoveErrorMessage();
            }
          })
      } catch (e) {
        console.log('JSON Parse error', e)
      }
    }
  };

  getFormattedCardNumber = (token) => {
    return token.slice(1, 3) + '*********' + token.slice(-4);
  }

  getFormattedExpiryDate = (expiry) => {
    let month = expiry.slice(4);
    month = month && month.length === 1 ? '0' + month : month;
    let year = expiry.slice(2, 4);
    return month + '/' + year;
  }

  handleAddNewCardInfo = () => {
    this.props.handleChangeTmpPaymentData('card', { token: '', expiry: '' })
    this.setState({
      token: '',
      expiry: ''
    })
    this.props.handleRemoveErrorMessage();
  }

  render() {
    const parameters = "formatinput=true&cardinputmaxlength=19&cardnumbernumericonly=true&useexpiry=true&usecvv=true&orientation=custom&placeholder=Card Number&placeholdercvv=CVV&placeholdermonth=MM&placeholderyear=YYYY&invalidinputevent=true&enhancedresponse=true";
    const encodedStyles = "body %7B font-family: 'Heebo', sans-serif; margin-left: 0px; %0A%7D%0Ainput %7B border: 1.5px solid %23d4d4d4; height: 40px; background-color: %23fafafa; font-size: 16px; text-indent: 16px; margin-top: 10px; color: %23333;%0A%7D input:focus %7B outline: none !important; border: 1.5px solid %2300a1be; box-shadow: 0 0 3px %23b0b0b0; background-color: %23ffffff;%0A%7D select %7B border: 1.5px solid %23d4d4d4; height: 44.5px; background-color: %23fafafa; font-size: 16px; margin-top: 10px; color: %23333; text-indent: 8px;%0A%7D select:focus %7B outline: none !important; border: 1.5px solid %2300a1be; box-shadow: 0 0 3px %23b0b0b0; background-color: %23ffffff;%0A%7D select:not(:-internal-list-box) %7B overflow: visible !important;%0A%7D %23cccardlabel,%0A%23ccexpirylabel,%0A%23cccvvlabel %7B display: none;%0A%7D %23ccnumfield %7B width: 100%25; display: block; margin-bottom: 0.4rem;%0A%7D %23ccexpirymonth %7B width: 4rem; margin-right: 1rem;%0A%7D %23ccexpiryyear %7B width: 5rem; margin-right: 1rem;%0A%7D;";

    const {
      iAgreeTerms,
      handleChangeIAgreeTerms,
      errorMessage
    } = this.props;

    return (
      <>
        <h6 className='body-text text-titleColor font-bold mb-0'>Card Information</h6>

        <div className="mt-1 md:mt-2">
          {this.props.tmpPaymentData.token && this.props.tmpPaymentData.expiry &&
            <div className='ml-2 mt-4 mb-10'>
              <div className='flex justify-between items-start'>
                <p className='body-text mb-3 font-semibold'>Use recently entered Card Information</p>
                <button className='w-32 px-4 py-2 rounded-md hover:bg-orange-200 cursor-pointer transition-all duration-150'
                  onClick={this.handleAddNewCardInfo}
                  type='button'
                >
                  <p className='text-secondary font-medium'>
                    Add New
                  </p>
                </button>
              </div>
              <p className='body-text mb-1'>Card Number&nbsp; : <span className='text-titleColor'>
                {this.getFormattedCardNumber(this.props.tmpPaymentData.token)}
              </span></p>
              <p className='body-text'>Expiration &nbsp; &nbsp; &nbsp; &nbsp;: <span className='text-titleColor'>
                {this.getFormattedExpiryDate(this.props.tmpPaymentData.expiry)}
              </span></p>
            </div>
          }

          <iframe
            style={{ display: this.props.tmpPaymentData.token && this.props.tmpPaymentData.expiry ? 'none' : 'block' }}
            title="iframe"
            id="tokenframe"
            name="tokenframe"
            frameBorder="0"
            scrolling="no"
            width='100%'
            height={this.props.isMobile ? "120" : "120"}
            src={`${this.props.paymentBaseUrl}/itoke/ajax-tokenizer.html?&${parameters}&css=${encodedStyles}`}
          />

          <div className='mt-5 mb-5'>
            <Checkbox
              checked={iAgreeTerms}
              setChecked={(e) => handleChangeIAgreeTerms(e)}
            >
              <p className='font-heebo text-base text-titleColor'>
                I agree with{' '}
                <a
                  href='https://vitafymed.com/terms-of-use'
                  target='_blank'
                  className='no-underline font-bold text-secondary focus:outline-none cursor-pointer'
                >
                  terms & policy
              </a>
              </p>
            </Checkbox>
          </div>

          {(errorMessage) &&
            <div className='mb-5'>
              <FormErrorMessage
                type='error'
                message={`Error: ${errorMessage}`}
              />
            </div>
          }

          <button
            className={`mid-button w-full flex justify-center rounded-md ${(!iAgreeTerms || this.props.isSubmitting || !this.props.tmpPaymentData.token) && 'btn-disabled'}`}
            onClick={this.props.handleSubmitCheckout}
          >
            <div className='flex items-center'>
              {this.props.isSubmitting ? (
                <div className='inline mr-2'>
                  <ButtonLoader />
                </div>
              ) : null}
            Checkout
                <FontAwesomeIcon
                icon={faArrowRight}
                className='text-white ml-3'
              />
            </div>
          </button>
        </div>
      </>
    );
  }

}

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 768,
})

export default withSizes(mapSizesToProps)(CardPayment);