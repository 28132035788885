/**
 * @author Slesha Tuladhar <slesha@noveltytechnology.com>
*/

import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import SearchAutocomplete from './searchAutocomplete';
import { _debounce } from '../util/lodashUtil';

import * as sharedACFunctions from './sharedMethods';

class ProviderAutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      suggestions: [],
      displayNameValue: this.props.displayNameValue ? this.props.displayNameValue : '',
      currentSearchKeyword: ''
    };
    this.onSuggestionsFetchRequested = _debounce(this.onSuggestionsFetchRequested.bind(this), 300);
  }
  onInputChange = (event, { newValue }) => {
    this.setState({
      displayNameValue: newValue,
    }, () => {
      this.props.handleInputChange(this.state.displayNameValue);
    });
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      displayNameValue: nextProps.displayNameValue
    })
  }
  onSuggestionsFetchRequested = async ({ value }) => {
    if (value.toLowerCase() == this.state.currentSearchKeyword.toLowerCase()) {
      this.setState({
        suggestions: this.state.list
      });
    }
    else if (value.length > 2) {
      const list = await this.search(value);
      this.setState({
        currentSearchKeyword: value,
        list
      }, () => {
        this.setState({
          suggestions: this.state.list
        });
      });
    }
  };
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };
  onSuggestionSelected = (event, { suggestion }) => {
    this.setState({
      displayNameValue: suggestion.displayName,
      selectedItem: suggestion
    }, () => {
      this.onSelectHandler();
    });
  };

  search = (value) => {
    return this.props.searchForAutoComplete(value);
  }
  onSelectHandler = () => {
    this.props.onSelectHandler(this.state.selectedItem);
  }
  shouldRenderSuggestions = value => sharedACFunctions.shouldRenderSuggestions(value, 0);
  renderSuggestion = (suggestion) => {
    return (
      <>
        <p className="mb-0">
          {suggestion.displayName}
          <br />
          <small className="text-muted">
            {suggestion.isSpecialty ? '' : <><i className="fas fa-id-card"></i> {suggestion.npi} -</>} <i className="fas fa-stethoscope"></i> {suggestion.isSpecialty ? `specialty${' in ' + (suggestion.vGroup ? suggestion.vGroup : suggestion.displayName)}`
              : this.props.providerType?.toLowerCase() != 'facility'
                ? `${(suggestion.vGroup ? suggestion.vGroup : 'located') + ' in ' + suggestion.practiceAddress.city + ', ' + suggestion.practiceAddress.state}`
                : suggestion.practiceAddress ?
                  (this.props.providerType?.toLowerCase() != 'facility' ? suggestion.vGroup : 'located') +
                  ' in ' + suggestion.practiceAddress.city + ', ' + suggestion.practiceAddress.state
                  : ''}
          </small>
        </p>
      </>
    );
  }
  renderInputComponent = (inputProps) => (
    <div className='relative'>
      <FontAwesomeIcon icon={faBuilding} className='filled-input-icon' />
      <input
        id='dpc'
        type='text'
        {...inputProps}
      />
    </div>
  );

  render() {
    const autosuggestInputs = {
      suggestions: this.state.suggestions,
      shouldRenderSuggestions: this.shouldRenderSuggestions,
      onSuggestionsFetchRequested: this.onSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.onSuggestionsClearRequested,
      onSuggestionSelected: this.onSuggestionSelected,
      getSuggestionDisplayName: sharedACFunctions.getSuggestionDisplayName,
      renderSuggestion: this.renderSuggestion,
      renderInputComponent: this.renderInputComponent,
      inputProps: {
        value: this.state.displayNameValue,
        onChange: this.onInputChange,
        placeholder: this.props.placeholder,
        required: this.props.inputRequired,
        disabled: this.props.disabled
      },
    }
    return (
      <SearchAutocomplete
        autosuggestInputs={autosuggestInputs}
        className={this.props.className}
      />
    );
  }
}

export default ProviderAutoComplete;