import React, { useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import serviceMap from '../../../constants/serviceMap';

import DisclaimerModal from './DisclaimerModal';

const MenuButtonMobile = ({
  tenantCode,
  serviceCode,
  icon,
  text,
  externalLink,
  helpText = '',
  showDisclaimer = false,
}) => {
  const history = useHistory();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const referredBy = queryParams.get('referredby'); // gives referring agent code

  const disclaimerRef = useRef();

  const handleContinue = () => {
    disclaimerRef.current.toggle();
    next();
  };

  const onServiceClick = () => {
    if (showDisclaimer) {
      disclaimerRef.current.toggle();
    } else {
      next();
    }
  };

  const next = () => {
    if (!!externalLink) {
      window.open(externalLink, '_blank', 'noreferrer');
      return;
    }

    let route = serviceMap[serviceCode]?.route?.replace(
      ':tenantCode',
      tenantCode?.toLowerCase()
    );

    if (
      referredBy &&
      serviceCode === serviceMap.SERVICES_MEMBERSHIP_ENROLLMENT.code
    ) {
      route = `${route}?referredby=${referredBy}`;
    }

    if (route) {
      history.push(route);
    }
  };

  return (
    <>
      <button
        className="help-center-menu-button h-48 relative border"
        onClick={onServiceClick}
      >
        <img
          src={icon}
          className="h-10 w-auto mb-3"
          alt="member-checkin-icon"
        />
        <h6 className="font-heebo text-titleColor text-xs uppercase">{text}</h6>
        {!!helpText && (
          <small className="px-1 py-1 text-xxs text-red-500 absolute bottom-0 left-0">
            {helpText}
          </small>
        )}
      </button>
      <DisclaimerModal
        modalRef={disclaimerRef}
        serviceCode={serviceCode}
        handleContinue={handleContinue}
        handleCancel={() => disclaimerRef.current.toggle()}
      />
    </>
  );
};

export default MenuButtonMobile;
