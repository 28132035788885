import React from 'react';
import { useHistory } from 'react-router-dom';

const Cta = (props) => {
  const {ctaList} = props;
  return (
    <div className='container mx-auto px-4'>
      <div className='flex flex-wrap -mx-4'>
        {ctaList && ctaList.map((cta, index) =>
          <div className={`w-full lg:w-1/2 px-4 ${index === 0 && 'mb-6 lg:mb-0'}`} key={index}>
            <OvelayCta
              image='https://imgix.cosmicjs.com/c0cae330-7d48-11e9-9563-5779dcb64e04-Cardiology.jpg'
              title={cta.title}
              subtitle={cta.subtitle}
              btnText={cta.action_button_text}
              btnLink={cta.action_button_link}
              handleActionBtnClick={props.handleActionBtnClick}
            />
          </div>
        )}
      </div>
    </div>
  );
}

const OvelayCta = (props) => {
  const { image, title, subtitle, btnText, btnLink, handleActionBtnClick } = props;
  let history = useHistory();
  return (
    <div
      className="overlay-cta w-full rounded-md px-10 py-12 bg-blue-100"
    // style={{
    //   backgroundImage: `linear-gradient(
    //     rgba(255, 255, 255, 0.65),
    //     rgba(255, 255, 255, 0.65)
    //   ),
    //   url(${image})`
    // }}
    >
      <h3 className='font-heebo font-bold lg:text-2xl text-3xl text-titleColor z-10'>
        {title}
      </h3>
      <p className='body-text text-lg mt-1 mb-8'>{subtitle}</p>
      {!btnLink ?
        <button
          className='small-button py-2 px-8 text-base'
          onClick={handleActionBtnClick}
        >{btnText}</button>
        :
        <button
          className='small-button py-2 px-8 text-base'
          onClick={() => history.push(btnLink)}
        >{btnText}</button>
      }

    </div>
  );
}

export default Cta;