import React from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const CustomersWeServe = ({ title, text }) => {
  const history = useHistory();
  return (
    <div className='bg-bgColor w-full'>
      <div className='divider'></div>
      <div className='container mx-auto px-4'>
        <h3 className='heading'>{title}</h3>
        <p className='body-text text-xl leading-relaxed'>{text}</p>
        <button
          className='big-button'
          onClick={() => history.push('/who-we-help')}
        >
          Learn More{' '}
          <FontAwesomeIcon icon={faArrowRight} className='inline-block ml-2 ' />
        </button>
      </div>
      <div className='divider'></div>
    </div>
  );
};

export default CustomersWeServe;
