// Get Initials from Name. For eg: 'JC' from 'John Cena'
// Credit - https://github.com/danilo95/letter-picture-like-Google-with-React/blob/master/src/Components/Utils.js
export const getInitials = (name) => {
  let initials;
  const nameSplit = name.split(' ');
  const nameLength = nameSplit.length;
  if (nameLength > 1) {
    initials =
      nameSplit[0].substring(0, 1) + nameSplit[nameLength - 1].substring(0, 1);
  } else if (nameLength === 1) {
    initials = nameSplit[0].substring(0, 1);
  } else return;

  return initials.toUpperCase();
};

// Generate profile image with initials
// Credit - https://github.com/danilo95/letter-picture-like-Google-with-React/blob/master/src/Components/Utils.js
export const createImageFromInitials = (size, name, color, bgColor) => {
  if (name == null) return;
  name = getInitials(name);

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  canvas.width = canvas.height = size;

  context.fillStyle = bgColor ? bgColor : '#ffffff';
  context.fillRect(0, 0, size, size);

  context.fillStyle = `${color}50`;
  context.fillRect(0, 0, size, size);

  context.fillStyle = color;
  context.textBaseline = 'middle';
  context.textAlign = 'center';
  context.font = `${size / 3}px Heebo`;
  context.fillText(name, size / 2, size / 2);

  return canvas.toDataURL();
};

// truncates the string adding the specified string at the end
// thisisalongemail@noveltytechnology.com -> thisisalo...
export const truncateText = (str, length, ending) => {
  if (length == null) {
    length = 25;
  }
  if (ending == null) {
    ending = '...';
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
};

export const prependHttp = (url) => {
  if (!url) return url;
  const lowerCasedUrl = url.toLowerCase();
  const prefix = 'http://';
  if (!lowerCasedUrl.match(/^[a-zA-Z]+:\/\//)) {
    return prefix + lowerCasedUrl;
  }
  return url;
};

export const checkIsSubDomain = (url) => {
  // if the subdomain is dev (dev.vitafyhealth.com), return false
  // even if there is a subdomain (technically) as the whole point of checking isSubdomain
  // is to separate the VitafyHealth website from the tenant websites
  if (url?.split('.')[0]?.toLowerCase() === 'dev') {
    return false;
  }

  // IF THERE, REMOVE WHITE SPACE FROM BOTH ENDS
  url = url.replace(new RegExp(/^\s+/), ''); // START
  url = url.replace(new RegExp(/\s+$/), ''); // END

  // IF FOUND, CONVERT BACK SLASHES TO FORWARD SLASHES
  url = url.replace(new RegExp(/\\/g), '/');

  // IF THERE, REMOVES 'http://', 'https://' or 'ftp://' FROM THE START
  url = url.replace(new RegExp(/^http\:\/\/|^https\:\/\/|^ftp\:\/\//i), '');

  // IF THERE, REMOVES 'www.' FROM THE START OF THE STRING
  url = url.replace(new RegExp(/^www\./i), '');

  // REMOVE COMPLETE STRING FROM FIRST FORWARD SLASH ON
  url = url.replace(new RegExp(/\/(.*)/), '');

  // REMOVES '.??.??' OR '.???.??' FROM END - e.g. '.CO.UK', '.COM.AU'
  if (url.match(new RegExp(/\.[a-z]{2,3}\.[a-z]{2}$/i))) {
    url = url.replace(new RegExp(/\.[a-z]{2,3}\.[a-z]{2}$/i), '');

    // REMOVES '.??' or '.???' or '.????' FROM END - e.g. '.US', '.COM', '.INFO'
  } else if (url.match(new RegExp(/\.[a-z]{2,4}$/i))) {
    url = url.replace(new RegExp(/\.[a-z]{2,4}$/i), '');
  }

  // CHECK TO SEE IF THERE IS A DOT '.' LEFT IN THE STRING
  const isSubdomain = url.match(new RegExp(/\./g)) ? true : false;

  return isSubdomain;
};

export const getSubDomain = () => {
  return window.location.host?.split('.')[0]?.toLowerCase();
};

export const getPortalLink = () => {
  let link;
  let env = process.env.REACT_APP_ENV?.toUpperCase();
  switch (env) {
    case 'LOCAL':
      link = 'http://localhost:8080';
      // link = 'https://dev-portal.vitafyhealth.com';
      break;
    case 'DEVELOPMENT':
      link = 'https://dev-app.vitafyhealth.com';
      break;
    case 'QA':
      link = 'https://qa-app.vitafyhealth.com';
      break;
    case 'UAT':
      link = 'https://uat-app.vitafyhealth.com';
      break;
    default:
      link = 'https://app.vitafyhealth.com';
  }
  return link;
};
