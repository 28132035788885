/**
 * @author Ayush Bajracharya <ayush@noveltytechnology.com>
 */

import * as httpService from './httpService';
import APICONSTANTS from '../constants';

export function walkin(data) {
  return httpService
    .post(`${APICONSTANTS.MEMBERS}/walk-in`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function covidCheckout(data) {
  return httpService
    .post(`covid/checkout`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function fetchAncillaryOfferings(tenantCode, queryParam = {}) {
  return httpService
    .get(
      `admin-settings/${APICONSTANTS.OFFERING}/ancillary/${tenantCode}`,
      queryParam
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function checkout(data) {
  return httpService
    .post(`${APICONSTANTS.PMS_ENROLLMENT}/walkin-checkout`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function checkIsMember(data) {
  return httpService
    .post(`${APICONSTANTS.MEMBERS}/check-if-exists`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}
