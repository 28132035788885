import { countryCode } from '../constants/countryCode';

export const googleAutocompleteAddressMap = (x, address, country) => {
  if (country === countryCode.USA) {
    if (x.types[0] === 'street_number' || x.types[0] === 'route') {
      address.addressLine1 += `${x.long_name} `;
    }
    if (
      x.types[0] === 'sublocality_level_1' ||
      x.types[0] === 'sublocality' ||
      x.types[0] === 'neighborhood'
    ) {
      address.addressLine2 += `${x.long_name} `;
    }
    if (x.types[0] === 'locality') {
      address.city = `${x.long_name}`;
    }
    if (x.types[0] === 'administrative_area_level_1') {
      address.state = `${x.short_name}`;
    }
    if (x.types[0] === 'postal_code') {
      address.zip = `${x.long_name}`;
    }

    return address;
  }

  if (country === countryCode.CANADA) {
    if (x.types[0] === 'street_number' || x.types[0] === 'route') {
      address.addressLine1 += `${x.long_name} `;
    }
    if (
      x.types[0] === 'sublocality_level_1' ||
      x.types[0] === 'sublocality' ||
      x.types[0] === 'neighborhood' ||
      x.types[0] === 'political'
    ) {
      address.addressLine2 += `${x.long_name} `;
    }
    if (x.types[0] === 'locality') {
      address.city = `${x.long_name}`;
    }
    if (x.types[0] === 'administrative_area_level_1') {
      address.state = `${x.short_name}`;
    }
    if (x.types[0] === 'postal_code') {
      address.zip = `${x.long_name}`;
    }

    return address;
  }

  return {
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zip: '',
    lat: '',
    lng: '',
  };
};
