/**
 * @author Slesha Tuladhar <slesha@noveltytechnology.com>
 */

import * as httpService from './httpService';
import APICONSTANTS from '../constants';

export function fetchNetworkProvider(params) {
  return httpService.get(`${APICONSTANTS.NETWORK_PROVIDER}`, params)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    })
}

export function fetchNetworkProviderById(providerId) {
  return httpService.get(`${APICONSTANTS.NETWORK_PROVIDER}/${providerId}`)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    })
}

export function searchNetworkProvider(params) {
  return httpService.post(`${APICONSTANTS.NETWORK_PROVIDER}/search`, params)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    })
}

export function findAllNetworkProvider(params) {
  return httpService.post(`${APICONSTANTS.NETWORK_PROVIDER}/findAll`, params)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    })
}

export function saveNetworkProvider(params) {
  return httpService.post(`${APICONSTANTS.NETWORK_PROVIDER}`, params)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    })
}

export function updateNetworkProvider(params) {
  return httpService.put(`${APICONSTANTS.NETWORK_PROVIDER}`, params)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    })
}

export function deleteNetworkProvider(providerId) {
  return httpService.destroy(`${APICONSTANTS.NETWORK_PROVIDER}`, providerId)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    })
}

export function searchProvider(params) {
  return httpService.get(`${APICONSTANTS.NETWORK_PROVIDER}/search`, params)
    .then(response => {
      response.data.data.providerList = mapFromEsProvider(response.data.data.providerList);
      return response.data;
    })
    .catch(error => {
      throw error;
    })
}

// Pricing is fetched from prod API
export function fetchPricingByProviderId(providerId, queryParams = {}) {
  return httpService.get(`admin-settings/${APICONSTANTS.OFFERING}/providers/${providerId}`, queryParams)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    })
}

// Associated Physician is fetched from prod API
export function fetchProviderPhysiciansByProviderId(providerId) {
  return httpService.get(`${APICONSTANTS.PROVIDER_PHYSICIANS}/${providerId}`)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    })
}

export function fetchProviderbyProviderId(providerId) {
  return httpService
    .get(`${APICONSTANTS.NETWORK_PROVIDER}/${providerId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

function mapFromEsProvider(list) {
  list = list.map(x => {
    if (x.practiceAddress) {
      x.address = {
        ...x.practiceAddress,
        addressLine1: x.practiceAddress.addressline1,
        addressLine2: x.practiceAddress.addressline2
      }
    }
    x.otherBusinessName = x.displayName;
    return x;
  })
  return list;
}

export function getProvidersForTenant(tenantId) {
  return httpService
    .get(`${APICONSTANTS.NETWORK_PROVIDER}/provider/${tenantId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function fetchMembershipOffers(providerId, params){
  return httpService
    .get(`${APICONSTANTS.NETWORK_PROVIDER}/${providerId}/membership-offers`, params)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}
