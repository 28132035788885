/**
 * @author Slesha Tuladhar <slesha@noveltytechnology.com>
 */
const apiConstants = {
  HOME: 'homes',
  CLIENT_STORY: 'client-stories',
  LATEST_NEWS: 'latest-posts',
  PARTNERS: 'partners',
  ABOUT_US: 'about-hcps',
  WHO_WE_ARE: 'who-we-are',
  OUR_TEAM: 'our-leaders',
  WHO_WE_HELP: 'who-we-helps',
  NEWSLETTER: 'user/subscribe',
  CONTACT_FORM: 'contactus',
  AGENT_CLIENTS: 'agent-clients',
  AGENTS: 'agents',
  DPC: 'dpc',
  DPC_INFO_FETCH: 'providers',
  PLAN: 'plan',
  LOOKUP: 'lookup',
  COSMIC: 'cosmic',
  CLIENTS: 'clients',
  NETWORK_PROVIDER: 'providers',
  LOCATION: 'location',
  ENROLLMENT: 'enrollment-contents',
  CUSTOMER_PAGES: 'customer-pages',
  FOOTER_CONTENTS: 'site-footer',
  CLOUD_PROVIDERS: 'cloud-providers',
  MEMBERS: 'members',
  DPC_PRICING: 'pricing-configurations',
  TENANTS: 'tenants',
  BMT: 'bmt',
  PAYMENT: 'payment',
  MARKETPLACE: 'marketplace',
  SERVICE_SEARCH: 'bundles',
  DIRECT_PRIMARY_CARE: 'direct-primary-care',
  COSTS: 'costs',
  SPONSOR: 'sponsor',
  SPONSORS: 'sponsors',
  PROVIDER_PHYSICIANS: 'provider-physicians',
  AGENT_NETWORK: 'agent-network',
  PROMO_CODE: 'promo-codes',
  PMS_ENROLLMENT: 'pms-enrollment',
  OFFERING: 'offering',
  SALES_LEAD: 'sales-lead',
  APPOINTMENT: 'appointment',
  APPOINTMENTS: 'appointments',
  DEDICATED_DPC_AGREEMENT: 'agreements',
  TRANSACTIONS: 'transactions',
  TENANT_SETTINGS: 'tenant-settings',
  TRANSPARENCY: 'transparency',
  CHC: 'chc',
  VITAFY_PRIVACY_POLICY_SLUG: 'vitafy-privacy-policy',
  VITAFY_TERMS_AND_CONDITIONS_SLUG: 'vitafy-terms-and-conditions',
  DIRECT_CARE_PROVIDERS: 'direct-care-providers',
  COMMUNITY: 'community',
  LANG_DICTIONARY: 'lang-dictionary',
  CONTACTS: 'contacts',
  MERCHANT_CUSTOMERS: 'merchant-customers',
  QUESTIONAIRES: 'questionnaires',
  QUESTIONAIRE_RESPONSES: 'questionnaire-responses',
  QUESTIONAIRE_ANSWERS:
    'respondents/:clientId/questionnaires/Membership_Eligibility',
};

export default apiConstants;
