import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons';

import { MaskedDateInput } from '../../common';
import { _cloneDeep } from '../../util/lodashUtil';
import {
  selectActiveQuestion,
  setActiveQuestion,
} from '../../stores/questionaire';

const DateInput = () => {
  const dispatch = useDispatch();

  const activeQuestion = useSelector(selectActiveQuestion);
  const answer = activeQuestion?.answer;

  const onInputChange = (value) => {
    const updatedQuestion = _cloneDeep(activeQuestion);

    updatedQuestion.answer = {
      value,
    };
    dispatch(setActiveQuestion(updatedQuestion));
  };

  const label = activeQuestion?.metadata?.label || 'Date';

  return (
    <MaskedDateInput
      label={label}
      className="w-40"
      icon={faCalendarCheck}
      input={answer?.value || ''}
      setInput={onInputChange}
    />
  );
};

export default DateInput;
