import React from 'react';
import Branch from './Branch';

const Branches = ({ locations }) => {
  return (
    <div className={`container mx-auto px-4 hcm-landing-branches-margin-top`}>
      <div className='help-center-flex-container-margin bg-transparent'>
        <div className='flex flex-wrap'>
          {locations?.map((location, index) => 
            <Branch 
              key={location.id}
              data={location}
              lastIndex={index === locations.length - 1}
            />
          )}
        </div>
        </div>
    </div>
  );
}

export default Branches;