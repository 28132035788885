import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import MaskedDateInput from '../MaskedDateInput';

const FormMaskedDateInput = ({
  name,
  label,
  showHelperText,
  icon,
  ...others
}) => {
  const {
    control,
    formState: { errors },
    setValue,
    clearErrors,
  } = useFormContext();

  const onSetInput = (value) => {
    setValue(name, value);
    clearErrors(name);
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <MaskedDateInput
          {...others}
          {...field}
          id={name}
          label={label}
          icon={icon || ''}
          input={field.value}
          error={!!errors[name]}
          setInput={onSetInput}
        />
      )}
    />
  );
};

FormMaskedDateInput.defaultProps = {
  showHelperText: false,
  required: false,
};

export default FormMaskedDateInput;
