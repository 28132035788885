import React from 'react';
import { useDropzone } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';

import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import commonConstants from '../../constants/commonConstant';
import { toBase64 } from '../../util/file';
import FileList from './FileList';
import WithSizes from 'react-sizes';

const { DEFAULT_MAX_FILE_SIZE } = commonConstants;

const Dropzone = ({
  isFileSizeExceeded,
  files,
  setFiles,
  maxSize = DEFAULT_MAX_FILE_SIZE,
  isDesktop,
  createdBy = 'System',
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (f) => {
      const newFiles = [];
      for (let index = 0; index < f.length; index += 1) {
        let error = false;
        if (maxSize && f[index].size > maxSize) {
          error = true;
        }
        const uploadFile = {
          id: uuidv4(),
          name: f[index].name,
          contentType: f[index].type,
          createdBy,
          base64: await toBase64(f[index]),
          size: f[index].size,
          error,
        };
        newFiles.push(uploadFile);
      }

      setFiles([...files, ...newFiles]);
    },
  });

  const handleFileDelete = (id) => {
    const filteredFiles = files.filter((e) => e.id !== id);
    setFiles(filteredFiles);
  };

  const filesDisplay = files?.map((file) => (
    <FileList key={file.id} file={file} handleFileDelete={handleFileDelete} />
  ));

  return (
    <div>
      <div {...getRootProps({ className: 'fileDrop' })}>
        <input {...getInputProps()} />

        <div className="bg-white px-4 py-8 cursor-pointer">
          <FontAwesomeIcon
            className="block mx-auto text-dedicatedDpcPrimary mb-2"
            icon={faUpload}
            size="2x"
          />
          <p className="body-text text-titleColor text-center">
            {isDesktop
              ? 'Drag and drop files here or click to browse'
              : 'Click here to browse'}
          </p>
        </div>
      </div>

      <div className="flex flex-wrap justify-between mt-2">
        <p className="text-bodyColor text-sm">
          * Please upload .pdf, .jpg,. jpeg, .doc, .docx, .txt only
        </p>
        <p
          className={`text-sm ${
            isFileSizeExceeded ? 'text-errorTxt' : 'text-bodyColor'
          }`}
        >
          Max size {maxSize && Math.floor(maxSize / (1000 * 1000))} MB
          {isFileSizeExceeded ? ' *' : ''}
        </p>
      </div>

      {!!files?.length && <ul className="my-10">{filesDisplay}</ul>}
    </div>
  );
};

Dropzone.defaultProps = {
  isFileSizeExceeded: false,
  maxSize: DEFAULT_MAX_FILE_SIZE,
};

const mapSizesToProps = ({ width }) => ({
  isDesktop: width > 1024,
});

export default WithSizes(mapSizesToProps)(Dropzone);
