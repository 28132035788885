import React, { useContext } from 'react';
import { FormRelationshipInput } from '../../../../common';
import { store } from '../../../../store';
import { getTextFromLangDict } from '../../../../util';
import FormPrimaryMemberSelect from './FormPrimaryMemberSelect';

const PrimaryMemberInputs = ({ members, isEditMode }) => {
  // Context Store
  const contextStore = useContext(store);
  const { globalState } = contextStore;
  const { groupCode, langDict, tenantCode } = globalState;

  return (
    <>
      <h5 className="dedicated-form-title mb-3">
        {getTextFromLangDict(langDict, {
          key: '_PRIMARY_MEMBER_INFORMATION',
          groupCode,
          tenantCode,
        })}
      </h5>
      <div className="flex flex-wrap -mx-4">
        <div className="w-full md:w-1/2 px-4 mb-4 md:mb-0">
          <FormPrimaryMemberSelect members={members} isDisabled={isEditMode} />
        </div>
        <div className="w-full md:w-1/2 px-4">
          <FormRelationshipInput whiteBg buttonClassName="md:py-2" />
        </div>
      </div>
    </>
  );
};

export default PrimaryMemberInputs;
