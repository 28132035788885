import React from "react";
import HeatMap from "../../common/heatmap";

function Stats({ stats, title, subtitle }) {
  return (
    <>
      <div className="container mx-auto px-4 overflow-hidden">
        <h3
          className={`heading-center text-center w-full ${
            subtitle ? "mb-4" : "mb-0"
          }`}
        >
          {title}
        </h3>
        {Boolean(subtitle) && (
          <p className="body-text text-lg font-normal text-center">
            {subtitle}
          </p>
        )}

        <div className="flex flex-wrap w-full mt-16 mb-10">
          {stats?.map((item) => (
            <div
              className="w-1/2 lg:w-1/4 flex flex-col items-center mb-8 lg:mb-0"
              index={item.title}
            >
              <img src={item.icon.url} alt="stats-icon" className="h-10 mb-2" />
              <h2 className="text-3xl font-semibold">{item.data}</h2>
              <h6 className="text-base font-normal text-bodyColor text-center">
                {item.caption}
              </h6>
            </div>
          ))}
        </div>
      </div>
      <HeatMap />
    </>
  );
}

export default Stats;
