import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const CashPayment = () => {
  return (
    <div className="bg-blue-100 py-3 px-6 rounded-sm shadow-sm mb-6">
      <p className="body-text text-base text-primary">
        <FontAwesomeIcon icon={faInfoCircle} className="text-primary mr-2" />
        Please Continue to Checkout.
      </p>
    </div>
  );
};

export default CashPayment;
