import React from 'react';
import Skeleton from 'react-loading-skeleton';

const SearchResultSkeleton = ({ skeletons = 2 }) => {
  return (
    <>
      {new Array(skeletons).fill(0).map((item, index) =>
        <div className={`w-full bg-cardBgColor shadow-xs ${index !== 0 && 'mt-5'}`} key={index}>
          <div className='flex justify-start items-center p-4'>
            <div className='w-16'>
              <Skeleton
                width={65}
                height={65}
                color="#202020"
                highlightColor="#444"
              />
            </div>
            <div className='ml-5'>
              <h6 className='body-text text-titleColor font-bold'>
                <Skeleton width={200} height={25} />
              </h6>
              <p className='text-sm text-secondary mt-1'>
                <Skeleton width={200} height={15} />
              </p>
            </div>
          </div>
          <hr />
          <div className='flex justify-between items-start p-4'>
            <ul className='block'>
              <Skeleton width={200} height={20} />
              <Skeleton width={200} height={20} className='mt-2' />
            </ul>
            <div className='hidden lg:flex flex-col items-center '>
              <Skeleton width={200} height={50} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SearchResultSkeleton;