import React, { useEffect, useContext } from 'react';
import { ToastContainer } from 'react-toastify';

import 'react-app-polyfill/stable';

import { store } from '../store';
import SelectRoute from '../routes/selectRoute';

import '../assets/styles/tailwind.generated.css';
// import '../assets/styles/utilities.generated.css';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import 'react-toastify/dist/ReactToastify.css';

function App() {
  const state = useContext(store);
  const { globalState } = state;
  const { primaryLocation } = globalState;

  useEffect(() => {
    const changeSiteTitleFavicon = () => {
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      const { metaData } = primaryLocation;
  
      if (metaData?.title) {
        document.title = metaData.title;
      }
      if (metaData?.faviconUrl) {
        link.href = metaData.faviconUrl;
      }
      if (metaData?.description) {
        const metaDescription = metaData.description;
        document.querySelector('meta[name="description"]').setAttribute('content', metaDescription);
      }
    }
  
    if (primaryLocation) {
      changeSiteTitleFavicon();
    }
  }, [primaryLocation]);

  return (
    <>
      <SelectRoute />

      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
    </>
  );
}

export default App;
