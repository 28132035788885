export const tenantCodeEnum = {
  BCITY: 'BCITY',
  HCM: 'WA',
  HCMNR: 'NR',
  DEMODPC: 'DEMODPC',
  KTMDPC: 'KTMDPC',
  USDPC: 'USDPC',
  BMT: 'BMT',
  RSD: 'RSD',
  VITAFYHEALTH: 'VITAFYHEALTH',
  SIM: 'SIM',
  SIML2: 'SIML2',
  CHS: 'CHS',
  CHC: 'CHC',
  GDC: 'GDC',
  IHS: 'IHS',
  LEBC: 'LEBC',
  GC: 'GC',
  SEAISLANDMEDICAL: 'seaislandmedical',
  BBHB: 'BBHB',
  MSD: 'MSD',
  VCARE: 'VCARE',
  VSUITE: 'VSUITE',
  GLOBEX: 'GLOBEX',
};
