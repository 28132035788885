import React from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';

const PaymentConfirmation = ({ isBasic }) => {
  const history = useHistory();
  return (
    <>
      <div className='w-full pt-24 lg:pt-32 pb-12 lg:pb-16 bg-extended'>
        <div className='container mx-auto px-4'>
          <h1 className='firstContentHeading normal-case'>CHECKOUT</h1>
        </div>
      </div>
      <div className="mid-divider"></div>
      <div className="inner-divider"></div>

      <div className='flex justify-center container mx-auto px-4'>
        <div className='flex flex-col items-center'>
          <FontAwesomeIcon
            icon={faCheckCircle}
            className='text-green-500 text-5xl  mb-4'
          />
          <h3 className='body-text text-green-500 text-4xl mb-10'>{!isBasic ? 'Payment' : 'Registration'} Successful</h3>

          <h4 className='body-text text-titleColor text-3xl mb-2 text-center'>Thank you for choosing Vitafy Health</h4>

          <button
            className='big-button mt-3 mb-0'
            onClick={() => history.push('/')}
          > Go to homepage
          <FontAwesomeIcon
              icon={faArrowRight}
              className='text-white ml-3'
            />
          </button>
        </div>
      </div>

      <div className="mid-divider"></div>
    </>
  );
}

export default PaymentConfirmation;