import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectActiveQuestion,
  setActiveQuestion,
} from '../../stores/questionaire';
import { _cloneDeep } from '../../util/lodashUtil';

const CheckboxInput = () => {
  const dispatch = useDispatch();

  const activeQuestion = useSelector(selectActiveQuestion);
  const options = activeQuestion?.options || [];
  const answer = activeQuestion?.answer || [];

  const onSelect = (option) => {
    const updatedQuestion = _cloneDeep(activeQuestion);

    let selectedOptions = updatedQuestion.answer?.length
      ? updatedQuestion.answer
      : [];

    const ansIndex = selectedOptions.findIndex(
      (item) => item.value === option.value
    );

    if (ansIndex !== -1) {
      selectedOptions.splice(ansIndex, 1);
      updatedQuestion.answer = selectedOptions;
    } else {
      updatedQuestion.answer = [...selectedOptions, option];
    }

    dispatch(setActiveQuestion(updatedQuestion));
  };

  return (
    <div>
      {options.map((item, index) => (
        <button
          key={item.value}
          className={`mid-radio-btn px-8 ${
            answer.some((ans) => ans.value === item.value)
              ? 'bg-holyCityMedSecondary font-medium'
              : 'bg-white'
          } ${index < options.length - 1 && 'mr-4'}`}
          type="button"
          onClick={() => onSelect(item)}
        >
          {item.value}
        </button>
      ))}

      <p className="body-text text-sm mt-4">You can choose multiple options</p>
    </div>
  );
};

export default CheckboxInput;
