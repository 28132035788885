/**
 * @author Slesha Tuladhar <slesha@noveltytechnology.com>
*/

import React, { Component } from "react";
import { _debounce, _uniqBy } from '../util/lodashUtil';
import * as locationService from '../service/location-service';
import { showToast } from "../service/toasterService";
import SearchAutocomplete from "./searchAutocomplete";
import { escapeRegexChars } from "../util/escapeRegexCharsUtil.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMapMarkerAlt
} from '@fortawesome/free-solid-svg-icons';

class LocationAutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationList: [],
      suggestions: [],
      displayNameValue: this.props.displayNameValue ? this.props.displayNameValue : '',
      selectedItem: {},
      searchByZip: this.props.searchByZip ? true : false
    };
    this.onSuggestionsFetchRequested = _debounce(
      this.onSuggestionsFetchRequested.bind(this),
      300
    );
  }
  getSuggestions = value => {
    const escapedValue = escapeRegexChars(value ? value.trim() : "");
    const regex = new RegExp("^" + escapedValue, "i");
    // filter by zipcode if the search value contains digits only, else filter by city
    const locations = this.state.locationList.filter(x =>
      /^\d+$/.test(value) ? regex.test(x.zip) : regex.test(x.displayName)
      // /^\d+$/.test(value) ? regex.test(x.zip) : regex.test(x.city)
    );
    return this.getUniqueLocations(locations);
  };
  getSuggestionDisplayName = suggestion => suggestion.displayName;
  renderSuggestion = suggestion => {
    return (
      <span>{suggestion.displayName}</span>
    );
  };
  onInputChange = (event, { newValue }) => {
    this.setState({
      displayNameValue: newValue,
    }, () => {
      this.props.handleInputChange(this.state.displayNameValue);
    });
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      displayNameValue: nextProps.displayNameValue
    })
  }
  onSuggestionsFetchRequested = async ({ value }) => {
    const locationList = await this.searchLocation(value);
    this.setState(
      {
        locationList
      },
      () => {
        this.setState({
          suggestions: this.getSuggestions(value)
        });
      }
    );
  };
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };
  onSuggestionSelected = (event, { suggestion }) => {
    this.setState({
      displayNameValue: suggestion.displayName,
      selectedItem: suggestion
    }, () => {
      this.onSelectHandler();
    });
  };
  shouldRenderSuggestions = value => {
    return value.trim().length > 1;
  };
  searchLocation = (searchKeyword) => {
    return new Promise((resolve, reject) => {
      // locationService.searchLocation({searchKeyword})
      locationService.searchLocation(searchKeyword)
        .then(response => {
          const res = response.data;
          res.map(x => (
            x.displayName = `${x.city}, ${x.st}${this.state.searchByZip ? ' ' + x.zip : ''}`
          ))
          resolve(res);
        }).catch(error => {
          console.log(error);
          showToast('error', 'Error searching locations');
          reject(error);
        })
    });
  };
  getUniqueLocations = locationArray => {
    let uniqueLocations = _uniqBy(locationArray, this.state.searchByZip ? 'zip' : 'st')
    return uniqueLocations.slice(0, 10);
  };
  onSelectHandler = () => {
    this.props.onSelectHandler(this.state.selectedItem);
  }
  renderInputComponent = (inputProps) => (
    <div className='relative'>
      <FontAwesomeIcon icon={faMapMarkerAlt} className='filled-input-icon text-dedicatedDpcPrimary' />
      <input
        id='location'
        type='text'
        {...inputProps}
      />
    </div>
  );
  render() {
    const autosuggestInputs = {
      suggestions: this.state.suggestions,
      shouldRenderSuggestions: this.shouldRenderSuggestions,
      onSuggestionsFetchRequested: this.onSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.onSuggestionsClearRequested,
      onSuggestionSelected: this.onSuggestionSelected,
      getSuggestionDisplayName: this.getSuggestionDisplayName,
      renderSuggestion: this.renderSuggestion,
      renderInputComponent: this.renderInputComponent,
      inputProps: {
        value: this.state.displayNameValue,
        onChange: this.onInputChange,
        placeholder: this.props.placeholder,
        required: this.props.inputRequired,
      }
    };
    return (
      <SearchAutocomplete
        autosuggestInputs={autosuggestInputs}
        className={this.props.className}
      />
    );
  }
}

export default LocationAutoComplete;