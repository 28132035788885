import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGlobe,
  faMobileAlt,
  faEnvelope,
  faMapMarkerAlt,
} from '@fortawesome/free-solid-svg-icons';
import facebookImg from '../../assets/images/facebook.png';
import TwitterImg from '../../assets/images/twitter.png';
import LinkedInImg from '../../assets/images/linkedin.png';
import * as vitafyUtils from '../../util/vitafyUtil';
import PlaceholderImage from '../../assets/images/placeholder-avatar.png';
import DefaultImage from '../../assets/images/enrollment-default-logo.png';

const DpcInfo = (props) => {
  const { info } = props;

  return (
    <>
      <div className='w-full pt-24 lg:pt-32 pb-12 lg:pb-20 bg-extended'>
        {/* NAME STARTS HERE */}
        <div className='container mx-auto px-4 '>
          <h1 className='firstContentHeading font-heebo font-bold text-white uppercase text-3xl lg:text-6xl w-1/2' >
            {info?.id && info.businessName}
          </h1>
        </div>
        {/* NAME ENDS HERE */}
      </div>
      <div className='container mx-auto py-10 lg:py-16 flex flex-wrap flex-row lg:flex-row-reverse px-4'>
        {/* IMAGE STARTS HERE */}
        <div className='w-3/4 sm:w-2/5 lg:w-2/5  mx-auto -mt-16 sm:-mt-20 lg:-mt-40 xl:-mt-64 mb-10 lg:mb-0 lg:pl-16 xl:pl-32 '>
          <div className='w-full bg-white shadow-md rounded-lg'>
            <div className='w-full pb-full rounded-t-lg relative'>
              <img
                src={info?.imageUrl ? info.imageUrl : PlaceholderImage}
                className='absolute w-full h-full object-cover object-center rounded-t-lg'
                alt='profile-img'
              />
            </div>
            {info && (info.facebookUrl || info.twitterUrl || info.linkedInUrl) &&
              <div className='py-5 flex justify-center items-center -mx-3'>
                {info.facebookUrl &&
                  <a
                    href={info.facebookUrl}
                    className='no-underline focus:outline-none block px-3'
                  >
                    <img
                      src={facebookImg}
                      className='w-8 xl:w-10'
                      alt='facebook-link'
                    />
                  </a>
                }
                {info.twitterUrl &&
                  <a
                    href={info.twitterUrl}
                    className='no-underline focus:outline-none block px-3'
                  >
                    <img
                      src={TwitterImg}
                      className='w-8 xl:w-10'
                      alt='twiiter-link'
                    />
                  </a>
                }
                {info.linkedInUrl &&
                  <a
                    href={info.linkedInUrl}
                    className='no-underline focus:outline-none block px-3'
                  >
                    <img
                      src={LinkedInImg}
                      className='w-8 xl:w-10'
                      alt='linkedin-link'
                    />
                  </a>
                }
              </div>
            }
          </div>
        </div>
        {/* IMAGE ENDS HERE */}

        <div className='w-full lg:w-3/5 lg:pr-0'>
          {/* ADDITIONAL INFO (WEBSITE/LOCATION/PHONE/EMAIL) STARTS HERE */}
          <div className='flex flex-wrap mb-8 items-center'>
            <div className='w-full md:w-1/2 mb-4'>
              <div className='flex items-center mb-4'>
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  className='text-xl lg:text-2xl text-primary block'
                />
                <h6 className='ml-3 text-lg'>{info?.address ? `${info.address.city}, ${info.address.state}` : 'N/A'}</h6>
              </div>
              <div className='flex items-center'>
                <FontAwesomeIcon
                  icon={faMobileAlt}
                  className='text-xl lg:text-2xl text-primary block'
                />
                <a href="tel:8664848239" className='no-underline focus:outline-none'>
                  <h6 className='ml-3 text-lg'>{info?.phone ? vitafyUtils.getPhoneDisplayText(info.phone, info?.countryCode) : 'N/A'}</h6>
                </a>
              </div>
            </div>
            <div className='w-full md:w-1/2 flex md:justify-end'>
              <div>
                <a
                  href={info && info.email ? `mailto:${info.email}` : ''}
                  className='block focus:outline-none no-underline'
                >
                  <div className='flex items-center mb-4'>
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className='text-xl lg:text-2xl text-primary block'
                    />
                    <h6 className='ml-3 text-lg'>{info?.email ? info.email : 'N/A'}</h6>
                  </div>
                </a>
                <a href={info && info.website ? info.website.includes('https://' || 'http://') ? info.website : 'http://' + info.website : ''}
                  target='_blank' className='block focus:outline-none no-underline'>
                  <div className='flex items-center'>
                    <FontAwesomeIcon
                      icon={faGlobe}
                      className='text-xl lg:text-2xl text-primary block'
                    />
                    <h6 className='ml-3 text-lg'>{info?.website ? info.website : 'N/A'}</h6>
                  </div>
                </a>
              </div>
            </div>
            {/* ADDITIONAL INFO ENDS HERE */}
          </div>
          <div className='divider-line mb-8'></div>
          {/* SHORT DESCRIPTION STARTS HERE */}
          <div className='font-heebo text-bodyColor text-lg'>
            {info && info.bio}
          </div>
          {/* SHORT DESCRIPTION ENDS HERE */}
        </div>
      </div>
    </>
  );
}

export default DpcInfo;