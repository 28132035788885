import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';

import { store } from '../../../store';
import { getTextFromLangDict, parseHtml } from '../../../util';
import { resetAll } from '../../../stores/membership';
import { clearSession } from '../../../util/storageUtil';

const Confirmation = (props) => {
  const {
    type,
    email,
    title,
    displayName,
    subtitle,
    removeHomeLink = false,
    formTopRef,
    backToHomeForWindowReload = false,
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const referredByQueryParam = queryParams.get('referredby');

  // Context Store
  const state = useContext(store);
  const { globalState } = state;
  const { langDict, groupCode, tenantCode } = globalState;

  useEffect(() => {
    if (formTopRef) {
      formTopRef.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [formTopRef]);

  const onHomeClick = () => {
    const homeLink = tenantCode
      ? `/${tenantCode.toLowerCase()}${
          referredByQueryParam ? location.search : ''
        }`
      : '/';
    history.push(homeLink);
    dispatch(resetAll());
    clearSession();
    window.location.reload();
  };

  const MEMBERSHIP_SUCCESS_MESSAGE = getTextFromLangDict(langDict, {
    key: '_SERVICE_MEMBER_ENROLLMENT_SUCCESS_MESSAGE',
    groupCode,
    tenantCode,
  })
    ?.replace('{{patientEmail}}', email)
    ?.replace('{{tenantName}}', displayName);

  const ADDITIONAL_SERVICES_SUCCESS_MESSAGE = getTextFromLangDict(langDict, {
    key: '_SERVICE_ADDITIONAL_SERVICES_SUCCESS_MESSAGE',
    groupCode,
    tenantCode,
  })
    ?.replace('{{patientEmail}}', email)
    ?.replace('{{tenantName}}', displayName);

  const MEMBER_CHECK_IN_SERVICES_SUCCESS_MESSAGE = getTextFromLangDict(
    langDict,
    {
      key: '_SERVICE_MEMBER_CHECK_IN_SUCCESS_MESSAGE',
      groupCode,
      tenantCode,
    }
  )
    ?.replace('{{patientEmail}}', email)
    ?.replace('{{tenantName}}', displayName);

  const MEMBERSHIP_SUBMIT_POST_MESSAGE = getTextFromLangDict(langDict, {
    key: '_MEMBERSHIP_SUBMIT_POST_MESSAGE',
    groupCode,
    tenantCode,
  });

  return (
    <div className="flex flex-col items-center py-6 md:py-12">
      <div className="flex items-center justify-center">
        <FontAwesomeIcon
          icon={faCheckCircle}
          className="text-holyCityMedSuccess text-3xl mr-3"
        />
        <h4 className="text-holyCityMedSuccess font-normal text-2xl mb-0">
          {title}
        </h4>
      </div>
      <h6 className="mt-6 mb-2 text-center text-base md:text-lg">{subtitle}</h6>
      {type === 'membership' && email && (
        <div
          className={`body-text text-xl leading-relaxed text-center mb-2`}
          dangerouslySetInnerHTML={{
            __html: parseHtml(MEMBERSHIP_SUCCESS_MESSAGE),
          }}
        />
      )}
      {type === 'walk-in' && (
        <div
          className="body-text text-center"
          dangerouslySetInnerHTML={{
            __html: parseHtml(ADDITIONAL_SERVICES_SUCCESS_MESSAGE),
          }}
        />
      )}
      {type !== 'membership' && type !== 'walk-in' && (
        <div
          className="body-text text-center"
          dangerouslySetInnerHTML={{
            __html: parseHtml(MEMBER_CHECK_IN_SERVICES_SUCCESS_MESSAGE),
          }}
        />
      )}

      {props.children}

      {!removeHomeLink && (
        <button
          data-cy="go-to-homepage"
          className="mid-button bg-dedicatedDpcSecondary hover:bg-dedicatedDpcHovered mt-6"
          onClick={onHomeClick}
        >
          Go to homepage
        </button>
      )}
      {backToHomeForWindowReload && (
        <button
          className="mid-button bg-dedicatedDpcSecondary hover:bg-dedicatedDpcHovered mt-6"
          onClick={onHomeClick}
        >
          Back to home
        </button>
      )}

      {type === 'membership' && MEMBERSHIP_SUBMIT_POST_MESSAGE && (
        <div className="my-2">
          <div className="divider-line my-8"></div>
          <div
            className="body-text text-sm md:text-md leading-relaxed"
            dangerouslySetInnerHTML={{
              __html: parseHtml(MEMBERSHIP_SUBMIT_POST_MESSAGE),
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Confirmation;

// For Graith care
// <p className="body-text">
//   Watch your email and/or text for a DocuSign Document to complete. This has to
//   be completed before an advocate can start working on your case. This Service
//   agreement includes Scope of Services, Terms, Conditions, and important
//   information to know what a Graith Care Advocate can provide! <br />
//   <br />
//   Last Step! <br />
//   <br />
//   After the Service Agreement has been signed and submitted this will generate
//   an easy payment link that will come to your email with multiple payment
//   options to make it easy to get going on your advocacy needs.
//   <br />
//   <br />
//   Your Advocate Match will be reaching out as soon as possible! <br />
// </p>
