import React, { useRef, useState } from 'react';
import { DedicatedFormModal, MDInput } from '../../common';
import { setSession } from '../../util/storageUtil';

const MaintenanceBypassModalForm = ({ setIsMaintenanceMode }) => {
  const inputRef = useRef();

  const [error, setError] = useState('');

  const onSubmit = (event) => {
    event.preventDefault();

    if (
      inputRef.current.value ===
      process.env.REACT_APP_MAINTENANCE_PAGE_BYPASS_CODE
    ) {
      setSession('ADMIN_ACCESS_CODE', inputRef.current.value);
      setIsMaintenanceMode(false);
    } else {
      setError('Incorrect Code');
    }
  };

  return (
    <DedicatedFormModal modalTitle="Admin Access" reset={() => {}} show>
      <form onSubmit={onSubmit}>
        <div className="w-full p-6">
          <MDInput
            ref={inputRef}
            id="access-code"
            label="Access Code"
            name="accessCode"
            onChange={() => setError('')}
            required
            error={error}
          />
          {!!error && <p className="text-errorTxt"> {error} </p>}
        </div>

        <div className="flex items-center justify-end px-2 py-3 border-t border-solid border-gray-300 rounded-b">
          <button
            className="bg-secondary hover:bg-hovered ease-out block text-white font-heebo text-lg px-6 py-3 rounded-full outine-none w-full md:w-auto focus:outline-none"
            type="submit"
          >
            Submit
          </button>
        </div>
      </form>
    </DedicatedFormModal>
  );
};

export default MaintenanceBypassModalForm;
