import React, { Component } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMapMarkerAlt,
  faStreetView,
  faFileAlt,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import {
  ListItem,
  SearchResultSkeleton,
  EmptySearchResult,
} from '../../common';
import FilterSideBar from './FilterSideBar';
import avatarPlaceholderSquare from '../../assets/images/avatar-placeholder-square.png';
import requestInquiryBgImage from '../../assets/images/bg-enrollment.jpg';
class SearchListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stickySideBar: false,
    };
  }

  render() {
    const {
      list,
      filterLocationList,
      filterRadius,
      handleChangeFilterRadius,
      handleChangeFilterLocation,
      handleClearFilters,
      showBestMatchDistance,
      resultsByShortestDistance,
      handleShowResultsByChange,
      radiusList,
      isLoading,
      isMoreLoading,
      searched,
      searchParams,
      ads,
    } = this.props;

    return (
      <div className="w-full bg-bgColor min-h-1/2screen">
        <div className="h-10"></div>
        <div className="container mx-auto px-4">
          <div className="flex -mx-3">
            <div className="w-2/6 px-3 hidden lg:block z-0">
              <FilterSideBar
                locations={filterLocationList}
                filterRadius={filterRadius}
                radiusList={radiusList}
                handleChangeFilterRadius={handleChangeFilterRadius}
                handleChangeFilterLocation={handleChangeFilterLocation}
                handleClearFilters={handleClearFilters}
                isLoading={isLoading}
              />
            </div>
            <div className="w-full md:w-2/3 md:mx-auto lg:w-4/6 px-3">
              {!(!isLoading && list && list.length < 1) && (
                <div className="bg-white shadow-sm rounded-sm px-4 py-4 mb-8">
                  {!isLoading && list && list.length > 0 && (
                    <>
                      <p className="body-text">
                        You searched {searchParams?.dpcKeyword && 'for '}
                        {searchParams?.dpcKeyword && (
                          <span className="text-titleColor font-bold">
                            {searchParams.dpcKeyword}
                          </span>
                        )}
                        {searchParams.city && ' in '}
                        {searchParams.city && (
                          <span className="text-titleColor font-bold">
                            {searchParams.city}
                          </span>
                        )}
                        {searchParams.state && (
                          <span className="text-titleColor font-bold">
                            , {searchParams.state}
                          </span>
                        )}
                      </p>
                      {showBestMatchDistance && (
                        <>
                          <hr className="my-3" />
                          <div className="group inline-block relative">
                            <button className="bg-bgColor text-gray-700  py-2 px-4 rounded inline-flex items-center focus:outline-none">
                              <span className="body-text text-sm mr-2">
                                Sort by{' '}
                                <span className="font-bold">
                                  {resultsByShortestDistance
                                    ? 'Distance'
                                    : 'Best Match'}
                                </span>
                              </span>
                              <FontAwesomeIcon
                                icon={faChevronDown}
                                className="text-sm"
                              />
                            </button>
                            <ul className="absolute right-0 hidden group-hover:block bg-white pt-2 shadow-lg rounded-md">
                              <li
                                className="cursor-pointer group hover:bg-bgColor py-2 px-5 m-0 mb-1"
                                onClick={() => handleShowResultsByChange(false)}
                              >
                                <p
                                  className={`body-text text-sm ${
                                    !resultsByShortestDistance
                                      ? 'text-secondary'
                                      : 'text-titleColor'
                                  }`}
                                >
                                  Best Match
                                </p>
                              </li>
                              <li
                                className="cursor-pointer group hover:bg-bgColor py-2 px-5 m-0"
                                onClick={() => handleShowResultsByChange(true)}
                              >
                                <p
                                  className={`body-text text-sm  ${
                                    resultsByShortestDistance
                                      ? 'text-secondary'
                                      : 'text-titleColor'
                                  }`}
                                >
                                  Distance
                                </p>
                              </li>
                            </ul>
                          </div>
                        </>
                      )}
                    </>
                  )}
                  {isLoading && <p className="body-text">Searching...</p>}
                </div>
              )}

              {!isLoading ? (
                list &&
                list.map((provider, index) => (
                  <div key={index} className={`mb-5`}>
                    <Card
                      detail={provider}
                      requestDemoClick={this.props.requestDemoClick}
                    />
                  </div>
                ))
              ) : (
                <>
                  <SearchResultSkeleton />
                </>
              )}
              {isMoreLoading && <SearchResultSkeleton skeletons={1} />}
              {searched && !isLoading && list && list.length < 1 && (
                <EmptySearchResult
                  marginTopRem="0rem"
                  text="No providers Found."
                />
              )}
            </div>
            <div className="w-2/6 px-3 hidden lg:block">
              <div
                className="bg-white w-full px-4 py-6 center-cover-background-img"
                style={{
                  backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.8)), url(${requestInquiryBgImage})`,
                }}
              >
                <h6 className="text-titleColor text-2xl font-normal">
                  Didn't find what you are looking for?
                </h6>
                <button
                  className="small-button px-4 sm:px-6 rounded-sm mt-3"
                  onClick={this.props.requestDemoClick}
                >
                  Request Inquiry
                </button>
              </div>
              <div>
                {ads &&
                  ads.map((ad, index) => (
                    <div className="mt-6" key={index}>
                      <AdvertisementCard image={ad.image?.url} link={ad.link} />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const Card = (props) => {
  const { detail, requestDemoClick } = props;
  const history = useHistory();
  return (
    <div className="w-full bg-white shadow-sm hover:shadow-md rounded-sm px-4 py-4">
      <div className="flex xl:-mx-2">
        <div className="xl:w-1/4 xl:px-2 hidden xl:block">
          <div className="w-full h-32 bg-bgColor">
            <img
              src={detail.imageUrl ? detail.imageUrl : avatarPlaceholderSquare}
              alt="dpc"
              className="object-cover h-full w-full rounded-md"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = `${avatarPlaceholderSquare}`;
              }}
            />
          </div>
        </div>
        <div className="w-full xl:w-3/4 xl:px-2">
          <div className="flex justify-start items-center">
            <div className="w-16 xl:hidden bg-bgColor">
              <img
                src={
                  detail.imageUrl ? detail.imageUrl : avatarPlaceholderSquare
                }
                alt="dpc"
                className="object-cover h-16 w-full rounded-md"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = `${avatarPlaceholderSquare}`;
                }}
              />
            </div>
            <div className="ml-3 md:ml-4 xl:ml-0">
              <h6
                className="body-text text-titleColor font-bold cursor-pointer"
                onClick={() =>
                  history.push(
                    `/${detail.seoOptimizedUrl}/${detail.providerId}`
                  )
                }
              >
                {detail.displayName}
              </h6>
              <p className="text-sm text-secondary">
                {detail.taxonomyDescription}
              </p>
            </div>
          </div>

          <hr className="my-3 xl:my-2" />
          <div className="flex justify-between items-center">
            <div>
              <ListItem
                icon={faMapMarkerAlt}
                text={detail.practiceAddress}
                address={true}
                addressDoubleLine={true}
              />
              <ListItem
                icon={faStreetView}
                text={
                  detail.distance
                    ? `${detail.distance} mi`
                    : detail.distance === 0
                    ? '1 mi'
                    : 'N/A'
                }
                containerClassName="mb-0"
              />
            </div>
            <div className="flex flex-col items-center">
              <p
                className="body-text hover:text-secondary cursor-pointer text-sm mb-1"
                onClick={() =>
                  history.push(
                    `/${detail.seoOptimizedUrl}/${detail.providerId}`
                  )
                }
              >
                View Detail
              </p>
              <button
                className="small-button px-4 sm:px-6 rounded-sm"
                onClick={requestDemoClick}
              >
                <FontAwesomeIcon
                  icon={faFileAlt}
                  className="text-white mr-2 hidden md:inline"
                />
                Request Info
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AdvertisementCard = (props) => {
  const { image, link } = props;
  return (
    <a href={link} target="_blank">
      <div className="w-full h-64">
        <img
          src={image}
          className="object-contain object-top w-full h-full cursor-pointer"
          alt="ad-image"
        />
      </div>
    </a>
  );
};

export default SearchListing;
