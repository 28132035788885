/**
 * @author Slesha Tuladhar <slesha@noveltytechnology.com>
*/

import React, { Component } from "react";
import { _debounce, _uniqBy } from '../../util/lodashUtil';
import { showToast } from "../../service/toasterService";
import SearchAutocomplete from "../searchAutocomplete";
import * as sharedACFunctions from '../sharedMethods';
import * as MarketplaceService from "../../service/marketplaceService";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faStethoscope
} from '@fortawesome/free-solid-svg-icons';

class ServiceAutocomplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      suggestions: [],
      displayNameValue: this.props.displayNameValue ? this.props.displayNameValue : '',
      selectedItem: {}
    };
    this.onSuggestionsFetchRequested = _debounce(
      this.onSuggestionsFetchRequested.bind(this),
      300
    );
  }
  onInputChange = (event, { newValue }) => {
    this.setState({
      displayNameValue: newValue,
    }, () => {
      this.props.handleInputChange(this.state.displayNameValue);
    });
  };
  onSuggestionsFetchRequested = async ({ value }) => {
    const list = await this.searchServices(value);
    this.setState(
      {
        list,
      },
      () => {
        this.setState({
          // suggestions: sharedACFunctions.getSuggestions(value, this.state.list, 10),
          suggestions: this.state.list
        });
      }
    );
  };
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };
  onSuggestionSelected = (event, { suggestion }) => {
    this.setState({
      displayNameValue: suggestion.displayName,
      selectedItem: suggestion
    }, () => {
      this.onSelectHandler();
    });
  };
  searchServices = (searchKeyword) => {
    return new Promise((resolve, reject) => {
      MarketplaceService
        .searchServices(searchKeyword)
        .then((response) => {
          const res = response.data.bundles.map(x => {
            x.displayName = x.name;
            x.procedureCodes = `${x.primaryCode ? x.primaryCode : ''}${x.secondaryCode ? ',' + x.secondaryCode : ''}`;
            return x;
          })
          resolve(res);
        })
        .catch((err) => {
          showToast("error", "Error searching services");
          console.log("Error searching services", err);
          reject(err);
        });
    });
  };
  onSelectHandler = () => {
    this.props.onSelectHandler(this.state.selectedItem);
  }
  shouldRenderSuggestions = value => sharedACFunctions.shouldRenderSuggestions(value, 1);

  renderInputComponent = (inputProps) => (
    <div className='relative'>
      <FontAwesomeIcon icon={faStethoscope} className='filled-input-icon' />
      <input
        id='service'
        type='text'
        {...inputProps}
      />
    </div>
  );

  render() {
    const autosuggestInputs = {
      suggestions: this.state.suggestions,
      shouldRenderSuggestions: this.shouldRenderSuggestions,
      onSuggestionsFetchRequested: this.onSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.onSuggestionsClearRequested,
      onSuggestionSelected: this.onSuggestionSelected,
      getSuggestionDisplayName: sharedACFunctions.getSuggestionDisplayName,
      renderSuggestion: sharedACFunctions.renderSuggestion,
      renderInputComponent: this.renderInputComponent,
      inputProps: {
        value: this.state.displayNameValue,
        onChange: this.onInputChange,
        placeholder: this.props.placeholder,
        required: this.props.inputRequired,
      }
    };

    return (
      <SearchAutocomplete
        autosuggestInputs={autosuggestInputs}
        className={this.props.className}
      />
    );
  }
}

export default ServiceAutocomplete;