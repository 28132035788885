import React, { useContext } from 'react';
import { store } from '../../../store';
import { PAYMENT_GATEWAY_TYPES } from '../../../constants/payment';
import CardConnectBankPayment from './card-connect/BankPayment';
import AuthorizeDotNetBankPayment from './authorize-dot-net/BankPayment';

const { CARD_CONNECT, AUTHORIZE_DOT_NET } = PAYMENT_GATEWAY_TYPES;

const BankPayment = ({
  filledBankData,
  onChangeData,
  onAddNewBankClick,
  bankErrorMessage,
  errorMessage,
  onClearBankError,
}) => {
  // Context Store
  const state = useContext(store);
  const { globalState } = state;
  const { merchantProfile } = globalState;

  if (merchantProfile?.gatewayType === CARD_CONNECT) {
    return (
      <CardConnectBankPayment
        clearError={onClearBankError}
        filledBankData={filledBankData}
        onChangeData={onChangeData}
        onAddNewBankClick={onAddNewBankClick}
        bankErrorMessage={bankErrorMessage}
        errorMessage={errorMessage}
      />
    );
  }

  if (merchantProfile?.gatewayType === AUTHORIZE_DOT_NET) {
    return (
      <AuthorizeDotNetBankPayment
        clearError={onClearBankError}
        filledBankData={filledBankData}
        onChangeData={onChangeData}
        onAddNewBankClick={onAddNewBankClick}
        bankErrorMessage={bankErrorMessage}
        errorMessage={errorMessage}
      />
    );
  }

  return <>Payment gateway out of options</>;
};

export default BankPayment;
