import React, { useContext } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { store } from '../store';
import { PAYMENT_ENVIRONMENT } from '../constants/payment';
import { PAYMENT_GATEWAY_TYPES } from '../constants/payment';

const { TEST } = PAYMENT_ENVIRONMENT;

export const StripeWrapper = ({ children }) => {
  const state = useContext(store);
  const { globalState } = state;
  const { merchantProfile } = globalState;

  const {
    gatewayType,
    paymentEnvironment,
    authorization_live,
    authorization_test,
  } = merchantProfile;
  const publishableKey =
    paymentEnvironment === TEST ? authorization_test : authorization_live;

  if (gatewayType === PAYMENT_GATEWAY_TYPES.STRIPE && publishableKey) {
    const stripePromise = loadStripe(publishableKey);

    return <Elements stripe={stripePromise}>{children}</Elements>;
  }

  return children;
};
