import React, { useEffect, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import withSizes from 'react-sizes';
import {
  faBirthdayCake,
  faEnvelope,
  faIdCard,
  faUser,
} from '@fortawesome/free-solid-svg-icons';

import {
  FormTextField,
  FormAddressInput,
  FormMaskedDateInput,
  FormMaskedLast4SSNInput,
  FormGenderInput,
  FormCheckbox,
} from '../../../../common';

import { FormCountryPhoneInput } from '../../../../common';
import { store } from '../../../../store';
import { formatIndividualDemographicPayload } from '../../../../util/vitafyUtil';
import { getPersonalInfoSchema } from '../../../../schemas/ancillary';
import { showToast } from '../../../../service/toasterService';
import * as clientService from '../../../../service/clientService';
import commonConstants from '../../../../constants/commonConstant';
import InsuranceForm from './InsuranceForm';
import SelectRaceInput from './SelectRaceInput';
import { useMemo } from 'react';
import { getTextFromLangDict } from '../../../../util';
import { checkEnrollmentTenantSetting } from '../../../../util/tenantSettingUtil';
import { countryCode } from '../../../../constants/countryCode';

const { MEMBERSHIP_REGISTRATION_TYPES, TENANT_SETTINGS_CODE } = commonConstants;

const defaultValues = {
  firstName: '',
  middleName: '',
  lastName: '',
  dob: '',
  lastSSN: '',
  gender: '',
  race: '',
  email: '',
  phone: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zip: '',
  lat: '',
  lng: '',
  hasInsurance: false,
  insuranceName: '',
  insuranceMemberId: '',
  groupNumber: '',
  countryCode: countryCode.USA,
  phoneExt: '1',
  textEnabled: false,
};

const PersonalInfo = ({
  data,
  personalInfoFormRef,
  isMobile,
  whiteBg = true,
  handleAddUpdateClient,
  setIsSubmitting,
}) => {
  // Context Store
  const state = useContext(store);
  const { globalState } = state;
  const { tenantId, groupCode, tenantSettings, langDict, tenantCode } =
    globalState;

  const isEmailRequired = checkEnrollmentTenantSetting(
    TENANT_SETTINGS_CODE.ANCILLARY_EMAIL,
    { tenantSettings }
  );

  const methods = useForm({
    resolver: yupResolver(
      getPersonalInfoSchema({ emailRequired: isEmailRequired })
    ),
    defaultValues,
  });
  const { getValues, handleSubmit, reset } = methods;

  const addClient = async (payload) => {
    try {
      setIsSubmitting(true);
      const response = await clientService.addClient(payload);
      handleAddUpdateClient(
        {
          ...payload,
          clientId: response.data.clientId,
          clientEnrollmentId: response.data.clientEnrollmentId,
          additionalServices: true,
        },
        {
          changeStep: true,
          isMember:
            (response.data.alreadyMember && response.data.membershipActive) ||
            false,
        }
      );
    } catch (err) {
      showToast('error', err.response.data.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const updateClient = async (clientId, clientEnrollmentId, payload) => {
    try {
      setIsSubmitting(true);
      await clientService.editClient(clientId, payload);
      handleAddUpdateClient(
        {
          ...payload,
          clientId,
          clientEnrollmentId,
        },
        {
          changeStep: true,
          isMember: data.isMember,
        }
      );
    } catch (err) {
      showToast('error', err.response.data.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const formSubmit = (formValues) => {
    const payload = formatIndividualDemographicPayload({
      ...formValues,
      clientId: data.clientId || '',
      clientEnrollmentId: data.clientEnrollmentId || '',
      type: MEMBERSHIP_REGISTRATION_TYPES.individual.value,
      tenantId,
      groupCode,
      enrollmentType: commonConstants.OFFERING_TYPES.ANCILLARY, // need for walk-in checkout while creating/updating client
      additionalServices: true, // need for walk-in checkout while creating/updating client
    });

    if (data.clientId) {
      updateClient(data.clientId, data.clientEnrollmentId, payload);
      return;
    }

    addClient(payload);
  };

  /**
   * Prepopulate the data if exists
   */
  useEffect(() => {
    if (data) {
      reset({
        firstName: data.firstName ?? '',
        middleName: data.middleName ?? '',
        lastName: data.lastName ?? '',
        dob: data.dob ?? '',
        lastSSN: data.lastSSN ?? '',
        gender: data.gender ?? '',
        email: data.email ?? '',
        phone: data.phone ?? '',
        addressLine1: data.addressLine1 ?? data.address?.addressLine1 ?? '',
        addressLine2: data.addressLine2 ?? data.address?.addressLine2 ?? '',
        city: data.city ?? data.address?.city ?? '',
        state: data.state ?? data.address?.state ?? '',
        zip: data.zip ?? data.address?.zip ?? '',
        lat: data.lat ?? data.address?.lat ?? '',
        lng: data.lng ?? data.address?.lng ?? '',
        hasInsurance: data.hasInsurance ?? false,
        insuranceName: data.insuranceName ?? '',
        insuranceMemberId: data.insuranceMemberId ?? '',
        groupNumber: data.groupNumber ?? '',
        race: data.race ?? '',
        countryCode: data?.countryCode ?? countryCode.USA,
        phoneExt: data?.phoneExt ?? '1',
        textEnabled: data?.textEnabled ?? false,
      });
    }
  }, [data, reset]);

  /**
   * Update the base class state on unmount so that entered data are persisted
   */
  useEffect(() => {
    return () => {
      handleAddUpdateClient(
        {
          ...getValues(),
        },
        { changeStep: false }
      );
    };
  }, [getValues, handleAddUpdateClient]);

  const showRaceInput = useMemo(() => {
    return (
      tenantSettings?.miscellaneous?.find((item) => item.code === 'RACE')
        ?.status === 'Enabled'
    );
  }, [tenantSettings]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(formSubmit)} ref={personalInfoFormRef}>
        {/* Personal Information */}
        <h5 className="dedicated-form-title mb-3">
          {getTextFromLangDict(langDict, {
            key: '_ENTER_YOUR_PERSONAL_INFORMATION',
            groupCode,
            tenantCode,
          })}
        </h5>
        <div className="flex flex-wrap -mx-4">
          <div className="w-full md:w-1/3 px-4">
            <FormTextField
              name="firstName"
              label="First Name *"
              icon={isMobile ? '' : faUser}
            />
          </div>
          <div className="w-full md:w-1/3 px-4">
            <FormTextField name="middleName" label="Middle Name" />
          </div>
          <div className="w-full md:w-1/3 px-4">
            <FormTextField name="lastName" label="Last Name *" />
          </div>
        </div>
        <div className="flex flex-wrap -mx-4">
          <div className="w-full md:w-1/3 px-4">
            <FormMaskedDateInput
              name="dob"
              label="Date of Birth *"
              icon={isMobile ? '' : faBirthdayCake}
            />
          </div>
          <div className="w-full md:w-1/3 px-4">
            <FormMaskedLast4SSNInput
              name="lastSSN"
              label="Last Four SSN"
              icon={isMobile ? '' : faIdCard}
            />
          </div>
          <FormGenderInput name="gender" whiteBg={whiteBg} />
        </div>

        {showRaceInput && (
          <div className="w-full md:w-1/2">
            <SelectRaceInput />
          </div>
        )}

        <div className="form-section-divider-md"></div>

        {/* Contact Information */}
        <h5 className="dedicated-form-title mb-3">
          {getTextFromLangDict(langDict, {
            key: '_ENTER_YOUR_CONTACT_INFORMATION',
            groupCode,
            tenantCode,
          })}
        </h5>
        <div className="flex flex-wrap -mx-4">
          <div className="w-full md:w-1/2 px-4">
            <FormTextField
              name="email"
              label={
                isEmailRequired ? 'Email *' : 'Email (Required for Receipt)'
              }
              icon={isMobile ? '' : faEnvelope}
            />
          </div>
          <div className="flex w-full md:w-1/2 px-4">
            <FormCountryPhoneInput allowSMS={false} label="Phone *" />
          </div>
        </div>
        <FormAddressInput isRequired />

        {/* Insurance Information */}
        {data.insuranceIncluded && (
          <>
            <div className="mt-8 mb-8">
              <FormCheckbox name="hasInsurance" label="I have insurance" />
            </div>
            <InsuranceForm />
          </>
        )}
      </form>
    </FormProvider>
  );
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 768,
});

export default withSizes(mapSizesToProps)(PersonalInfo);
