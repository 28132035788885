const K_SIZE = 40;

const greatPlaceStyle = {
  // initially any map object has left top corner at lat lng coordinates
  // it's on you to set object origin to 0,0 coordinates
  position: 'absolute',
  width: K_SIZE,
  height: K_SIZE,
  left: -K_SIZE / 2,
  top: -K_SIZE / 2,

  border: '5px solid #f44336',
  borderRadius: K_SIZE,
  backgroundColor: 'white',
  textAlign: 'center',
  color: '#3f51b5',
  fontSize: 16,
  fontWeight: 'bold',
  padding: 4,
  cursor: 'pointer'
};

const greatPlaceStyleHover = {
  ...greatPlaceStyle,
  border: '5px solid #3f51b5',
  color: '#f44336'
};

const popoverWrapper = {
  position: 'absolute',
  width: K_SIZE,
  height: K_SIZE,
  left: -K_SIZE / 2,
  top: -K_SIZE / 2,
  position: 'relative',
  display: 'inline-block',
  cursor: 'pointer'
}
const popoverWrapperHover = {
  ...popoverWrapper,
  
}

const popoverContent = {
  opacity: 0,
  visibility: 'hidden',
  position: 'absolute',
  left: '50%',
  transform: 'translate(-50%, 10px)',
  backgroundColor: '#fff',
  padding: '0.8rem',
  boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.26)',
  width: 'auto',
  borderRadius: '4px',
  minWidth: '180px',
  '&:before': {
    position: 'absolute',
    zIndex: '-1',
    content: "",
    // right: 'calc(50 % - 10px)',
    top: '-8px',
    borderStyle: 'solid',
    borderWidth: '0 10px 10px 10px',
    borderColor: 'transparent transparent #bfbfbf transparent',
    transitionDuration: '0.1s',
    transitionProperty: 'transform'
  }
}

const popoverContentHover = {
  ...popoverContent,
  zIndex: '10',
  opacity: 1,
  visibility: 'visible',
  transform: 'translate(-50%, -125%)',
  transition: 'all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97)'
}

export { greatPlaceStyle, greatPlaceStyleHover, K_SIZE, popoverWrapper, popoverWrapperHover, popoverContent, popoverContentHover };