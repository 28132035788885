import React, { Component } from 'react';
import {
  faCreditCard,
  faUniversity,
  faCheck,
  faArrowLeft
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ButtonLoader
} from '../../common';
import CardPayment from './CardPayment';
import BankPayment from './BankPayment';
import PlanInfo from './PlanInfo';
import PricingIntervalRadioInput from './PricingIntervalRadioInput';
import * as vitafyUtils from '../../util/vitafyUtil';

class PaymentSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPromoCodeInput: false
    }
  }

  showCost = () => {
    let cost = this.props.pricing[this.props.paymentInterval];
    cost = cost[this.props.chosenPricingPlan];
    if (cost === 'Free') return '$0.00'
    else return cost;
  }

  render() {
    const {
      paymentEnvironment,
      paymentInterval,
      handleChangePaymentInterval,
      paymentMethod,
      handleChangePaymentMethod,
      chosenPricingPlan,
      promoCode,
      mobileView,
      step
    } = this.props;
    const paymentBaseUrl = paymentEnvironment === 'live' ? process.env.REACT_APP_PAYMENT_LIVE_BASE_URL : process.env.REACT_APP_PAYMENT_TEST_BASE_URL;


    const totalAmountToShow = promoCode.success && promoCode.discountAmount ? parseFloat(chosenPricingPlan.one_time_setup_fee) - parseFloat(promoCode.discountAmount)
      : chosenPricingPlan.one_time_setup_fee;

    // No Payment information for basic plan. Show options to switch to 'premium' and 'professional plan'.  
    const isBasicPlan = chosenPricingPlan.plan && chosenPricingPlan.plan.toLowerCase() === 'basic';

    return (
      <div className={`w-full bg-white pb-3
        ${!mobileView ? 'rounded-sm shadow-md' : 'mt-6'} 
        ${isBasicPlan && 'py-3 hidden md:block'}
        ${!isBasicPlan && !mobileView && 'pt-0 payment-summary-neg-margin'}`}>

        {!isBasicPlan &&
          <div className={`${!mobileView && 'px-5 py-4 bg-bgColor'} flex justify-between items-end`}>
            <h4 className='font-heebo text-titleColor font-bold mb-1 text-xl'>Payment Summary</h4>
            <div className='hidden lg:block'>
              {/* Payment Interval Radio Input for desktop */}
              <PricingIntervalRadioInput
                paymentInterval={paymentInterval}
                handleChangePaymentInterval={handleChangePaymentInterval}
                monthlyId='monthly-radio'
                annuallyId='annually-radio'
                radioInputName='pricing-plan-radio'
              />
            </div>
          </div>
        }

        {/* Payment Interval Radio Input for mobile */}
        {!isBasicPlan &&
          <div className={`${!mobileView && 'mx-5'} mt-4 lg:hidden`}>
            <PricingIntervalRadioInput
              paymentInterval={paymentInterval}
              handleChangePaymentInterval={handleChangePaymentInterval}
              monthlyId='monthly-radio-mobile'
              annuallyId='annually-radio-mobile'
              radioInputName={`${mobileView ? 'pricing-plan-radio-mobile-view' : 'pricing-plan-radio-mobile'}`}
            />
          </div>
        }

        {!mobileView &&
          <PlanInfo
            chosenPricingPlan={chosenPricingPlan}
            paymentInterval={paymentInterval}
            isBasicPlan={isBasicPlan}
          />
        }

        {/* No Payment information for basic plan */}
        {!isBasicPlan && (step === 2) &&
          <div className={`${!mobileView ? 'px-5' : 'mt-5'} pb-4`}>
            <div className='flex justify-between items-center mt-2 mb-3'>
              <p className='body-text text-titleColor'>One Time Setup Fee</p>
              <p className='body-text text-titleColor'>
                {vitafyUtils.formatCurrency(chosenPricingPlan.one_time_setup_fee, true)}
              </p>
            </div>
            {/* <div className='flex justify-between items-center mt-2 mb-3'>
              <div className='flex justify-between items-center'>
                <p
                  className='body-text text-sm text-titleColor underline cursor-pointer mr-4'
                  onClick={() => this.setState({ showPromoCodeInput: true })}
                >Add Promo Code</p>
                {this.state.showPromoCodeInput &&
                  <div className='relative'>
                    <input
                      id='promo-code-input'
                      className={`w-32 rounded-sm bg-bgColor body-text text-sm py-1 px-2 text-titleColor focus:bg-white border 
                  focus:border-primary focus:outline-none ${promoCode && promoCode.checked && !promoCode.success && !promoCode.isChecking ? 'border-red-500' : 'border-bgColor'}
                  ${promoCode && promoCode.checked && promoCode.success && !promoCode.isChecking && 'btn-disabled'} ${promoCode.isChecking && 'btn-disabled'}`}
                      type="text"
                      onChange={this.props.handleChangePromoCode}
                      onBlur={(event) => this.props.handleCheckPromoCode(event.target.value)}
                    />
                    {promoCode && promoCode.checked && promoCode.success && !promoCode.isChecking &&
                      <FontAwesomeIcon
                        icon={faCheck}
                        className='text-green-500 mr-2 absolute right-0'
                        style={{ top: '50%', transform: 'translate(0, -50%)' }}
                      />
                    }
                    {promoCode.isChecking &&
                      <div
                        className='text-green-500 mr-2 absolute right-0'
                        style={{ top: '50%', transform: 'translate(0, -50%)' }}
                      >
                        <ButtonLoader
                          type='spin'
                          color='#00A1BE'
                          width='15px'
                          height='15px'
                        />
                      </div>
                    }
                  </div>
                }
              </div>
              <p className='body-text text-titleColor'>
                {promoCode && promoCode.checked && promoCode.success && promoCode.discountAmount ?
                  <>
                    -{vitafyUtils.formatCurrency(promoCode.discountAmount, true)}
                  </>
                  : null
                }
              </p>
            </div> */}
            <hr />
            <div className='flex justify-between items-center my-2'>
              <p className='body-text text-titleColor text-lg font-bold'>Due Today</p>
              <p className='body-text text-titleColor text-lg font-bold'>
                {vitafyUtils.formatCurrency(totalAmountToShow, true)}
              </p>
            </div>
            <hr />

            <section className='mt-3 mb-4'>
              <h6 className='body-text text-titleColor font-bold mb-3'>Payment Method</h6>
              <div className='flex -mx-2'>
                <div className='w-1/2 px-2'>
                  <button
                    className={`w-full py-3 flex justify-center items-center focus:outline-none rounded-sm 
                  ${paymentMethod === 'card' ? 'bg-primary shadow-md' : 'bg-bgColor'}`}
                    onClick={() => handleChangePaymentMethod('card')}
                  >
                    <FontAwesomeIcon
                      icon={faCreditCard}
                      className={`block mr-2  ${paymentMethod === 'card' ? 'text-white' : 'text-bodyColor'}`}
                    />
                    <p className={`body-text text-xs uppercase  ${paymentMethod === 'card' ? ' text-white' : 'text-bodyColor'}`}>Pay with Card</p>
                  </button>
                </div>
                <div className='w-1/2 px-2'>
                  <button
                    className={`w-full py-3 flex justify-center items-center focus:outline-none rounded-sm 
                 ${paymentMethod === 'bank' ? 'bg-primary shadow-md' : 'bg-bgColor'}`}
                    onClick={() => handleChangePaymentMethod('bank')}
                  >
                    <FontAwesomeIcon
                      icon={faUniversity}
                      className={`block mr-2  ${paymentMethod === 'bank' ? 'text-white' : 'text-bodyColor'}`}
                    />
                    <p className={`body-text text-xs uppercase  ${paymentMethod === 'bank' ? ' text-white' : 'text-bodyColor'}`}>Pay with Bank</p>
                  </button>
                </div>
              </div>
            </section>

            {paymentMethod === 'card' &&
              <CardPayment
                paymentBaseUrl={paymentBaseUrl}
                handleSubmitCheckout={this.props.handleSubmitCheckout}
                iAgreeTerms={this.props.iAgreeTerms}
                handleChangeIAgreeTerms={this.props.handleChangeIAgreeTerms}
                tmpPaymentData={this.props.tmpPaymentData.card}
                handleChangeTmpPaymentData={this.props.handleChangeTmpPaymentData}
                errorMessage={this.props.errorMessage}
                handleRemoveErrorMessage={this.props.handleRemoveErrorMessage}
                isSubmitting={this.props.isSubmitting}
              />
            }
            {paymentMethod === 'bank' &&
              <BankPayment
                paymentBaseUrl={paymentBaseUrl}
                handleSubmitCheckout={this.props.handleSubmitCheckout}
                iAgreeTerms={this.props.iAgreeTerms}
                handleChangeIAgreeTerms={this.props.handleChangeIAgreeTerms}
                tmpPaymentData={this.props.tmpPaymentData.bank}
                handleChangeTmpPaymentData={this.props.handleChangeTmpPaymentData}
                errorMessage={this.props.errorMessage}
                handleRemoveErrorMessage={this.props.handleRemoveErrorMessage}
                isSubmitting={this.props.isSubmitting}
              />
            }

            {mobileView &&
              <button
                className='mt-5 bg-white text-secondary focus:outline-none block mx-auto'
                onClick={() => this.props.handleChangeStep(1)}
              >
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  className='text-secondary mr-3'
                />
                Prev
              </button>
            }

          </div>
        }
      </div>
    );
  }
}



export default PaymentSummary;