import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectActiveQuestion,
  selectPhase,
  selectQuestionaire,
  selectQuestionnaireCode,
  selectRespondent,
  setPhase,
  setQuestionaire,
  setQuestionaireAnswers,
} from '../../stores/questionaire';
import * as questionaireAPI from '../../service/questionaireService';
import { Loader, ProgressBar } from '../../common';
import GetStarted from './GetStarted';
import PrevNextControl from './PrevNextControl';
import { PHASE } from '../../constants/questionaire';
import Question from './Question';
import Ineligible from './Ineligible';

const Questionaire = ({ onPrevClick, handleQuestionnaireSubmitSuccess }) => {
  const dispatch = useDispatch();

  const questionaire = useSelector(selectQuestionaire);
  const respondent = useSelector(selectRespondent);
  const activeQuestion = useSelector(selectActiveQuestion);
  const phase = useSelector(selectPhase);
  const questionnaireCode = useSelector(selectQuestionnaireCode);

  const [isLoading, setIsLoading] = useState(true);

  // Fetch questionaire answers and updates questionaire with answers in the store
  const fetchQuestionaireAnswers = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await questionaireAPI.fetchQuestionaireAnswers(
        respondent.clientId
      );
      if (questionaire?.questions?.length === response?.data?.answers?.length) {
        dispatch(setPhase(PHASE.SUBMIT));
      }
      dispatch(setQuestionaireAnswers(response.data));
    } catch (err) {
      console.log('Error fetching questionaire answers', err);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, respondent.clientId, questionaire]);

  // Fetch questionaire and populate the store only if not populated already
  useEffect(() => {
    const fetchQuestionaire = async (code) => {
      try {
        setIsLoading(true);
        const response = await questionaireAPI.fetchQuestionaireByCode(code);
        dispatch(setQuestionaire(response.data));
      } catch (err) {
        console.log('Error fetching questionaire', err);
      } finally {
        setIsLoading(false);
      }
    };

    if (!questionaire && !!questionnaireCode) {
      fetchQuestionaire(questionnaireCode);
    } else {
      setIsLoading(false);
    }
  }, [
    questionaire,
    questionnaireCode,
    dispatch,
    respondent.clientId,
    fetchQuestionaireAnswers,
  ]);

  useEffect(() => {
    if (questionaire && phase === PHASE.GET_STARTED) {
      fetchQuestionaireAnswers();
    }
  }, [fetchQuestionaireAnswers, phase, questionaire]);

  if (isLoading) {
    return <Loader />;
  }

  if (phase === PHASE.GET_STARTED) {
    return (
      <GetStarted
        onPrevClick={onPrevClick}
        onNextClick={() => dispatch(setPhase(PHASE.QUESTION))}
      />
    );
  }

  if (phase === PHASE.INELIGIBLE) {
    return <Ineligible />;
  }

  const currentQuestionOrder = activeQuestion?.order || 0;
  const totalQuestions = questionaire?.questions?.length
    ? questionaire.questions.length - 1
    : 0;
  const isLastQuestion =
    activeQuestion?.order === questionaire?.questions?.length;

  return (
    <div className="pt-5 pb-10">
      <div className="min-h-450px">
        {!isLastQuestion && (
          <div className="mb-12">
            <ProgressBar
              current={currentQuestionOrder}
              total={totalQuestions}
            />
          </div>
        )}

        <Question
          handleQuestionnaireSubmitSuccess={handleQuestionnaireSubmitSuccess}
        />
      </div>
      {!isLastQuestion && (
        <PrevNextControl
          handleQuestionnaireSubmitSuccess={handleQuestionnaireSubmitSuccess}
        />
      )}
    </div>
  );
};

export default Questionaire;
