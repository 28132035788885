import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { PAYMENT_TYPES } from '../../../constants/payment';

const Tabs = (props) => {
  const { activeTab, changeTab } = props;
  return (
    <div className="flex mb-8">
      {PAYMENT_TYPES.map((item, index) => (
        <div
          className={`w-40 md:w-64 ${
            index !== PAYMENT_TYPES.length - 1 ? 'mr-8' : ''
          }`}
          key={item.value}
        >
          <div
            className={`client-info-option-box h-18 md:h-20 justify-center ${
              activeTab === item.value
                ? 'border-extended shadow-focus relative'
                : null
            }`}
            onClick={() => changeTab(item.value)}
          >
            <div>
              <p
                className={`uppercase font-bold client-info-option-text-color text-sm md:text-lg mx-auto ${
                  activeTab === item.value ? 'text-extended' : null
                }`}
              >
                {item.text}
              </p>
            </div>
            {activeTab === item.value ? (
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="text-xl md:text-4xl text-extended absolute top-0 left-0 pl-2 pt-2 md:pl-3 md:pt-3"
              />
            ) : null}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Tabs;
