import React, { Component, createRef } from "react";
import { ReactTitle } from "react-meta-tags";
import {
  Header,
  ValueProp,
  Loader,
  Footer,
  Benefit,
  ClientStory,
  RequestDemoModal,
  CallToAction,
  RequestDemo,
  DisclaimerModal,
} from "../../common";
import Search from "./Search";
import Savings from "./Savings";

import * as service from "../../service";
import * as MarketplaceService from "../../service/marketplaceService";
import { showToast } from "../../service/toasterService";
import Stats from "./Stats";
import ctaModalTitle from "../../constants/ctaModalTitle";
import commonConstants from "../../constants/commonConstant";

class Marketplace extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      cosmicContent: {
        banner: {},
        valueProps: {},
        benefits: [],
        savings: {
          repeater: [],
          mobileAppLinks: {},
        },
        stats: [],
        clientStories: [],
        partners: [],
        cta: {},
        disclaimer: {},
        heatmap: {},
        seo: {},
      },
      requestFormData: {
        name: "",
        email: "",
        phone: "",
        profession: "",
        message: "",
      },
      requestFormSelectOptions: [
        { value: "Agent & Advisor" },
        { value: "Direct Primary Care" },
        { value: "Direct Specialty Care" },
        { value: "Care Advocate" },
        { value: "Employer & Group" },
        { value: "Provider" },
        { value: "Consumer" },
        { value: "Other" },
      ],
      requestDemoModal: {
        modalTitle: ctaModalTitle.REQUEST_DEMO,
      },
      isSubmitting: {
        requestform: false,
        search: false,
      },
      summaryCost: {},
      searched: false,
    };
    this.requestDemoModal = createRef();
    this.newRequestDemoModal = createRef();
    this.disclaimerModal = createRef();
  }

  componentDidMount() {
    this.getCosmicContent();
    this.getClientStories();
    this.getPartners();
  }

  getCostSummary = (searchedData) => {
    return new Promise((resolve, reject) => {
      MarketplaceService.getCostSummary(
        searchedData.searchCity,
        searchedData.searchState,
        searchedData.searchZipCode,
        searchedData.searchPrimaryCode,
        searchedData.searchCode,
        searchedData.searchLatitude,
        searchedData.searchLongitude,
        searchedData.pageLimit,
        searchedData.pageOffset
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          showToast("error", "Error while fetching cost summary");
          reject(error);
        });
    });
  };

  search = async (searchedData, pageChange = false) => {
    if (
      searchedData.searchCode &&
      searchedData.searchCity &&
      searchedData.searchZipCode &&
      searchedData.searchState &&
      searchedData.searchPrimaryCode &&
      searchedData.searchLatitude &&
      searchedData.searchLongitude
    ) {
      this.setState(
        (prevState) => ({
          ...prevState,
          isSubmitting: {
            ...prevState.isSubmitting,
            search: true,
          },
        }),
        async () => {
          this.getCostSummary(searchedData)
            .then((response) => {
              this.setState((prevState) => ({
                summaryCost: response,
                isSubmitting: {
                  ...prevState.isSubmitting,
                  search: false,
                },
                searched: true,
              }));
            })
            .catch((err) => {
              this.setState((prevState) => ({
                isSubmitting: {
                  ...prevState.isSubmitting,
                  request: false,
                },
              }));
            });
        }
      );
    } else {
      showToast("warning", "Please select both fields from dropdown");
    }
  };

  summaryCostCloseHandler = () => {
    this.setState({ summaryCost: {}, searched: false });
  };

  getCosmicContent = () => {
    this.setState({ loading: true });
    service
      .getMarketplaceCosmicContent()
      .then((response) => {
        if (response.status === "success") {
          const banner = response.data.find(
            (item) => item.slug === "banner"
          ).metadata;
          const valueProps = response.data.find(
            (item) => item.slug === "value-props"
          ).metadata;
          const benefits = response.data.find(
            (item) => item.slug === "benefits"
          ).metadata;
          const savings = response.data.find(
            (item) => item.slug === "savings"
          ).metadata;
          const cta = response.data.find(
            (item) => item.slug === "cta"
          ).metadata;
          const stats = response.data.find(
            (item) => item.slug === "statistics"
          ).metadata;
          const disclaimer = response.data.find(
            (item) => item.slug === "disclaimer"
          ).metadata;
          const heatmap = response.data.find(
            (item) => item.slug === "heatmap"
          ).metadata;
          const pageSeo = response.data.find(
            (item) => item.slug === "page-seo"
          ).metadata;
          if (valueProps) {
            this.setState((prevState) => ({
              cosmicContent: {
                ...prevState.cosmicContent,
                banner: {
                  title: banner.title,
                  subtitle: banner.tagline,
                  subtitleVisibility: banner.tagline_visibility,
                },
                valueProps: {
                  missionStatement: valueProps.mission_statement,
                  valuePropsTitle: valueProps.value_props_title,
                  valuePropsTitleVisibility:
                    valueProps.value_props_title_visibility,
                  valueProps: valueProps.value_props,
                },
                stats: stats.statistics,
                benefits: benefits.benefits,
                savings: {
                  ...prevState.savings,
                  repeater: savings.savings_repeater,
                  mobileAppLinks: savings.mobile_app_links,
                },
                cta: cta,
                disclaimer: disclaimer,
                heatmap: heatmap,
                seo: pageSeo,
              },
            }));
            this.setState({ loading: false });
          }
        }
      })
      .catch((error) => {
        console.log("Error in get marketplace cosmic content", error);
        this.setState({ loading: false });
      });
  };

  getClientStories = () => {
    this.setState({ loading: true });
    service
      .getClientStories()
      .then((result) => {
        if (result.status === "success") {
          this.setState((prevState) => ({
            cosmicContent: {
              ...prevState.cosmicContent,
              clientStories: result.data,
            },
            loading: false,
          }));
        }
      })
      .catch((error) => {
        console.log("Error in get homeContent", error);
        this.setState({ loading: false, error: true });
      });
  };

  getPartners = () => {
    this.setState({ loading: true });
    service
      .getPartners()
      .then((result) => {
        if (result.status === "success") {
          this.setState((prevState) => ({
            cosmicContent: {
              ...prevState.cosmicContent,
              partners: result.data.find((item) => item.slug === "partners"),
            },
            loading: false,
          }));
        }
      })
      .catch((error) => {
        console.log("Error in get homeContent", error);
        this.setState({ loading: false, error: true });
      });
  };

  openRequestModal = (value) => {
    if (!value) return;
    let selectedOption = "";
    if (value.includes("smart-advisors")) selectedOption = "Care Advocate";
    else if (value.includes("providers")) selectedOption = "Provider";
    else if (value.includes("employers")) selectedOption = "Employer & Group";
    else if (value.includes("consumers")) selectedOption = "Consumer";
    this.setState(
      (prevState) => ({
        requestFormData: {
          ...prevState.requestFormData,
          profession: selectedOption,
        },
        requestDemoModal: {
          ...prevState.requestDemoModal,
          modalTitle: ctaModalTitle.REQUEST_DEMO,
        },
      }),
      () => this.requestDemoModal.current.toggle()
    );
  };

  handleRequestFormSubmit = (data) => {
    this.setState((prevState) => ({
      isSubmitting: {
        ...prevState.isSubmitting,
        requestForm: true,
      },
    }));
    service
      .submitContactForm(data, true)
      .then((result) => {
        this.setState((prevState) => ({
          isSubmitting: {
            ...prevState.isSubmitting,
            requestForm: false,
          },
        }));
        if (result.data === "success") {
          showToast("success", "Thank you for reaching out!");
          //reset the form
          this.setState(
            {
              requestFormData: {
                name: "",
                email: "",
                phone: "",
                profession: "",
                message: "",
              },
            },
            () => this.requestDemoModal.current.toggle()
          );
        } else {
          showToast("error", "Oops. Something went wrong.");
        }
      })
      .catch((error) => {
        console.log("Error in post request demo", error);
        this.setState((prevState) => ({
          isSubmitting: {
            ...prevState.isSubmitting,
            requestForm: false,
          },
        }));
      });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <ReactTitle
          title={
            this.state.cosmicContent.seo?.seo?.metadata?.page_title ||
            commonConstants.SEO.DEFAULT_PAGE_TITLE
          }
        />

        <Header />

        <Search
          banner={this.state.cosmicContent.banner}
          search={this.search}
          summaryCost={this.state.summaryCost}
          closeHandler={this.summaryCostCloseHandler}
          isSubmitting={this.state.isSubmitting.search}
          searched={this.state.searched}
          requestDemoClick={() => {
            this.setState({
              requestDemoModal: {
                modalTitle: ctaModalTitle.REQUEST_PRICING,
              },
            });
            this.newRequestDemoModal.current.toggle();
          }}
          disclaimerPreviewText={
            this.state.cosmicContent?.disclaimer?.banner_disclaimer_preview
          }
          openDisclaimerModal={() => this.disclaimerModal.current.toggle()}
        />
        <div className="divider"></div>

        <ValueProp
          title={this.state.cosmicContent.valueProps?.missionStatement}
          valuePropsTitle={this.state.cosmicContent.valueProps?.valuePropsTitle}
          valuePropsTitleVisibility={
            this.state.cosmicContent.valueProps?.valuePropsTitleVisibility
          }
          values={this.state.cosmicContent.valueProps?.valueProps}
          bgColor={false}
        />

        <Savings
          sliderContent={this.state.cosmicContent.savings?.repeater}
          mobileAppLinkContent={
            this.state.cosmicContent.savings?.mobileAppLinks
          }
          openDisclaimerModal={() => this.disclaimerModal.current.toggle()}
        />

        <div className="divider"></div>

        <Stats
          title={this.state.cosmicContent?.heatmap?.title}
          subtitle={this.state.cosmicContent?.heatmap?.subtitle}
          stats={this.state.cosmicContent?.stats}
        />

        <div className="divider"></div>

        <Benefit
          benefits={this.state.cosmicContent.benefits}
          showLinks={true}
          handleLinkClick={this.openRequestModal}
        />

        <div className="divider"></div>

        <CallToAction
          title={this.state.cosmicContent.cta?.title}
          description={this.state.cosmicContent.cta?.content}
          actionButtonText={this.state.cosmicContent.cta?.action_button_text}
          actionButtonLink="/"
          requestDemo={true}
          modalTitle="Contact Us"
        />

        <div className="divider"></div>

        <ClientStory
          clientStories={this.state.cosmicContent.clientStories}
          partners={
            this.state.cosmicContent.partners?.metadata?.partners_images
          }
          // partners={[]}
          // partners={
          //   this.state.cosmicContent.partners?metadata &&
          //   this.state.partners.metadata.partners_images
          // }
          visibility={
            this.state.cosmicContent.partners?.metadata
              ?.entire_section_visibility
          }
          bgColor={false}
        />

        <Footer />

        <RequestDemoModal
          formModal={this.requestDemoModal}
          editData={this.state.requestFormData}
          options={this.state.requestFormSelectOptions}
          requestDemoModal={this.state.requestDemoModal}
          formSubmit={this.handleRequestFormSubmit}
          isSubmitting={this.state.isSubmitting.requestForm}
        />

        <RequestDemo
          formModal={this.newRequestDemoModal}
          requestDemoModal={this.state.requestDemoModal}
        />

        <DisclaimerModal
          modalRef={this.disclaimerModal}
          title={this.state.cosmicContent?.disclaimer?.modal_title}
          body={this.state.cosmicContent?.disclaimer?.modal_body}
        />
      </>
    );
  }
}

export default Marketplace;
