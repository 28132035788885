export const PAYMENT_ENVIRONMENT = {
  LIVE: 'live',
  TEST: 'test',
};

export const PAYMENT_TYPES = [
  { text: 'Pay with Card', value: 1 },
  { text: 'Pay with Bank', value: 2 },
];

export const PAYMENT_METHOD_OTHER = 'Other';

export const SKIP_PAYMENT_FOR_NOW = 'NOT_NOW';

export const PAYMENT_GATEWAY_TYPES = {
  AUTHORIZE_DOT_NET: 'Authorize.Net',
  CARD_CONNECT: 'CardConnect',
  STRIPE: 'Stripe',
};

export const AUTHORIZE_DOT_NET_ENVIRONMENT = {
  TEST: 'SANDBOX',
  LIVE: 'PRODUCTION',
};

export const PAYMENT_GATEWAY_LINKS = {
  AUTHORIZE_DOT_NET: 'https://www.authorize.net/',
  CARD_CONNECT: 'https://www.firstdata.com/',
  STRIPE: 'https://stripe.com/',
};

export const BANK_ACCOUNT_TYPES = [
  { value: 'checking', text: 'Checking' },
  { value: 'savings', text: 'Savings' },
  { value: 'businessChecking', text: 'Business Checking' },
];
