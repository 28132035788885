import React, { useState, useContext, useMemo, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import withSizes from 'react-sizes';

import { ButtonLoader } from '../../../../common';

import { getGroupMemberOrDependentSchema } from '../../../../schemas/membership';
import {
  formatGroupEnrollmentSavePayload,
  formatGroupMemberSavePayload,
  formatMembershipOfferingForSave,
  getFullName,
} from '../../../../util/vitafyUtil';
import { checkEnrollmentTenantSetting } from '../../../../util/tenantSettingUtil';
import { store } from '../../../../store';
import commonConstants from '../../../../constants/commonConstant';
import { getTextFromLangDict } from '../../../../util';
import MemberTypeRadioGroup from './MemberTypeRadioGroup';
import DemographicInputs from './DemographicInputs';
import PrimaryMemberInputs from './PrimaryMemberInputs';
import { getSession, setSession } from '../../../../util/storageUtil';
import { showToast } from '../../../../service/toasterService';
import * as clientService from '../../../../service/clientService';
import * as tenantService from '../../../../service/tenantService';
import { countryCode } from '../../../../constants/countryCode';

const {
  TENANT_SETTINGS_CODE,
  MEMBER_TYPE,
  OFFERING_TYPES,
  PAGINATION_FETCH_ALL,
} = commonConstants;

const { BLOOD_GROUP } = TENANT_SETTINGS_CODE;

const defaultValues = {
  memberType: '',
  primaryMember: {
    clientId: '',
    fullName: '',
  },
  relationship: '',
  firstName: '',
  middleName: '',
  lastName: '',
  dob: '',
  lastSSN: '',
  gender: '',
  email: '',
  phone: '',
  countryCode: countryCode.USA,
  phoneExt: '1',
  textEnabled: false,
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zip: '',
  lat: '',
  lng: '',
  bloodGroup: '',
};

const AddGroupMemberForm = ({
  data,
  primaryClient,
  members,
  isMobile,
  refreshMemberList,
  closeModal,
}) => {
  // Context Store
  const contextStore = useContext(store);
  const { globalState } = contextStore;
  const { tenantId, groupCode, langDict, tenantCode, tenantSettings } =
    globalState;

  const showBloodGroupInput = useMemo(() => {
    return checkEnrollmentTenantSetting(BLOOD_GROUP, { tenantSettings });
  }, [tenantSettings]);

  const isEditMode = !!data?.clientId;

  const methods = useForm({
    resolver: yupResolver(
      getGroupMemberOrDependentSchema({
        isBloodGroupRequired: showBloodGroupInput,
      })
    ),
    defaultValues,
  });
  const { handleSubmit, watch, reset } = methods;

  const [isSubmitting, setIsSubmitting] = useState(false);

  /**
   * Prepopulate the form on edit mode
   *
   */
  useEffect(() => {
    if (data?.clientId) {
      reset({
        memberType:
          data.relationship === MEMBER_TYPE.PRIMARY
            ? MEMBER_TYPE.GROUP_MEMBER
            : MEMBER_TYPE.DEPENDENT,
        primaryMember: {
          clientId:
            data.relationship !== MEMBER_TYPE.PRIMARY ? data.parentId : '',
          fullName:
            data.relationship !== MEMBER_TYPE.PRIMARY ? data.parentName : '',
          clientEnrollmentId:
            data.relationship !== MEMBER_TYPE.PRIMARY
              ? data.parentEnrollmentId
              : '',
        },
        relationship: data.relationship || '',
        firstName: data.firstName || '',
        middleName: data.middleName || '',
        lastName: data.lastName || '',
        dob: data.dob || '',
        lastSSN: data.lastSSN || '',
        gender: data.gender || '',
        email: data.email || '',
        phone: data.phone || '',
        bloodGroup: data.bloodGroup || '',
        addressLine1: data.address.addressLine1 || '',
        addressLine2: data.address.addressLine2 || '',
        city: data.address.city || '',
        state: data.address.state || '',
        zip: data.address.zip || '',
        lat: data.address.lat || '',
        lng: data.address.lng || '',
        countryCode: data?.countryCode || countryCode.USA,
        phoneExt: data?.phoneExt ?? '1',
        textEnabled: data?.textEnabled ?? false,
      });
    }
  }, [data, reset]);

  const updateSession = (payload, { editMode }) => {
    try {
      const groupMembers = JSON.parse(getSession('groupMembers'));
      let updatedGroupMembers = [];

      if (editMode) {
        updatedGroupMembers = groupMembers?.map((item) => {
          if (item.clientId === payload.clientId) {
            return { ...payload };
          }
          return item;
        });
      } else {
        updatedGroupMembers =
          !!groupMembers && groupMembers.length
            ? [...groupMembers, payload]
            : [payload];
      }

      setSession('groupMembers', updatedGroupMembers);
    } catch {
      console.log('Error parsing values from the session storage');
    }
  };

  const fetchOfferingForDependentofGroupMember = async (
    clientId,
    clientEnrollmentId
  ) => {
    const queryParams = {
      clientId,
      clientEnrollmentId,
      limit: PAGINATION_FETCH_ALL.LIMIT,
      offset: PAGINATION_FETCH_ALL.OFFSET,
      tenantId,
      type: OFFERING_TYPES.MEMBERSHIP,
    };
    const response = await tenantService.fetchMembershipOfferings(
      tenantId,
      queryParams
    );
    return response?.data?.rows;
  };

  const handleAddMember = async (payload, { memberType }) => {
    try {
      setIsSubmitting(true);
      const clientAddResponse = await clientService.addClient(payload);
      const clientId = clientAddResponse.data.clientId;
      const clientEnrollmentId = clientAddResponse.data.clientEnrollmentId;
      let offeringToSave = formatGroupEnrollmentSavePayload({
        ...primaryClient.offeringSelectedByPrimary,
        clientId,
        clientEnrollmentId,
      });

      // In case of primary group member, save the offering selected by group.
      // but for dependent of a primary group member, fetch the recommended offering by clientId
      // and take the first one to save (it returns single offering)
      if (memberType === MEMBER_TYPE.DEPENDENT) {
        const offerings = await fetchOfferingForDependentofGroupMember(
          clientId,
          clientEnrollmentId
        );
        if (offerings?.length) {
          offeringToSave = formatMembershipOfferingForSave({
            ...offerings[0],
            benefitStartDate:
              primaryClient.offeringSelectedByPrimary?.startDate,
            clientId,
            clientEnrollmentId,
          });
        }
      }

      await clientService.bulkSaveClientEnrollments(clientId, [offeringToSave]);
      if (memberType === MEMBER_TYPE.DEPENDENT) {
        updateSession(
          {
            ...payload,
            parentId: payload?.relationship?.primaryId ?? '',
            parentEnrollmentId:
              payload?.relationship?.primaryEnrollmentId ?? '',
            parentName: payload?.relationship?.primaryName ?? '',
            relationship: payload?.relationship?.type ?? '',
            type: payload.type,
            clientId,
            clientEnrollmentId,
          },
          { editMode: false }
        );
      } else {
        updateSession(
          {
            ...payload,
            parentId: payload?.relationship?.groupId ?? '',
            parentEnrollmentId: payload?.relationship?.groupEnrollmentId ?? '',
            parentName: payload?.relationship?.groupName ?? '',
            relationship: payload?.relationship?.type ?? '',
            type: payload.type,
            clientId,
            clientEnrollmentId,
          },
          { editMode: false }
        );
      }
      refreshMemberList();

      showToast('success', `Successfully added ${getFullName(payload)}`);
      closeModal();
    } catch (err) {
      showToast('error', err.response.data.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEditMember = async (payload, { memberType }) => {
    try {
      setIsSubmitting(true);
      const response = await clientService.editClient(
        payload.clientId,
        payload
      );
      if (memberType === MEMBER_TYPE.DEPENDENT) {
        updateSession(
          {
            ...payload,
            parentId: payload?.relationship?.primaryId ?? '',
            parentEnrollmentId:
              payload?.relationship?.primaryEnrollmentId ?? '',
            parentName: payload?.relationship?.primaryName ?? '',
            relationship: payload?.relationship?.type ?? '',
            type: payload.type,
            clientId: response.data.clientId,
            clientEnrollmentId: response.data.clientEnrollmentId,
          },
          { editMode: true }
        );
      } else {
        updateSession(
          {
            ...payload,
            parentId: payload?.relationship?.groupId ?? '',
            parentEnrollmentId: payload?.relationship?.groupEnrollmentId ?? '',
            parentName: payload?.relationship?.groupName ?? '',
            relationship: payload?.relationship?.type ?? '',
            type: payload.type,
            clientId: response.data.clientId,
            clientEnrollmentId: response.data.clientEnrollmentId,
          },
          { editMode: true }
        );
      }
      refreshMemberList();
      showToast('success', `Successfully updated ${getFullName(payload)}`);
      closeModal();
    } catch (err) {
      showToast('error', err.response.data.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onSubmit = (formValues) => {
    let payload = {
      ...formValues,
      tenantId,
      groupCode,
      tenantCode,
    };

    if (formValues.memberType === MEMBER_TYPE.GROUP_MEMBER) {
      payload = formatGroupMemberSavePayload({
        ...payload,
        parentName: primaryClient.businessName,
        parentId: primaryClient.clientId,
        parentEnrollmentId: primaryClient.clientEnrollmentId,
      });
    } else if (formValues.memberType === MEMBER_TYPE.DEPENDENT) {
      payload = formatGroupMemberSavePayload(
        {
          ...payload,
          parentName: payload.primaryMember?.fullName,
          parentId: payload.primaryMember?.clientId,
          parentEnrollmentId: payload.primaryMember?.clientEnrollmentId,
        },
        primaryClient
      );
    }

    if (isEditMode) {
      handleEditMember(
        {
          ...payload,
          clientId: data.clientId,
          clientEnrollmentId: data.clientEnrollmentId,
        },
        { memberType: formValues.memberType }
      );
    } else {
      handleAddMember(payload, { memberType: formValues.memberType });
    }
  };

  const watchedMemberType = watch('memberType');

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Modal Body */}
        <div className="modal-body">
          {/* Member Type */}
          <h5 className="dedicated-form-title mb-4">
            {getTextFromLangDict(langDict, {
              key: '_SELECT_MEMBER_TYPE',
              groupCode,
              tenantCode,
            })}
          </h5>
          <MemberTypeRadioGroup isDisabled={isEditMode} />

          {watchedMemberType && (
            <>
              <div className="form-section-divider-md"></div>
              {watchedMemberType === MEMBER_TYPE.DEPENDENT && (
                <>
                  <PrimaryMemberInputs
                    members={members}
                    isEditMode={isEditMode}
                  />
                  <div className="form-section-divider-md"></div>
                </>
              )}
              <DemographicInputs showBloodGroupInput={showBloodGroupInput} />
            </>
          )}
        </div>

        {/* Modal Footer */}
        <div className="modal-footer">
          <button
            className={`mid-button px-4 py-3 bg-transparent text-dedicatedDpcSecondary hover:bg-gray-300 ${
              isSubmitting && 'btn-disabled'
            }`}
            onClick={closeModal}
            type="button"
          >
            {getTextFromLangDict(langDict, {
              key: '_CANCEL',
              groupCode,
              tenantCode,
            })}
          </button>
          <button
            data-cy="save"
            className={`mid-button px-10 py-3 bg-dedicatedDpcSecondary hover:bg-dedicatedDpcHovered flex justify-center items-center ${
              isSubmitting && 'btn-disabled'
            }`}
            type="submit"
          >
            {isSubmitting ? (
              <div className="inline mr-2">
                <ButtonLoader />
              </div>
            ) : null}
            <span>
              {isEditMode
                ? getTextFromLangDict(langDict, {
                    key: '_UPDATE',
                    groupCode,
                    tenantCode,
                  })
                : getTextFromLangDict(langDict, {
                    key: '_SAVE',
                    groupCode,
                    tenantCode,
                  })}
            </span>
          </button>
        </div>
      </form>
    </FormProvider>
  );
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 768,
});

export default withSizes(mapSizesToProps)(AddGroupMemberForm);
