import React, { useContext } from 'react';

import { store } from '../../store';

import { Loader } from '../../common';
import Banner from './Banner';

import HelpCenterMenu from './help-center-menu';
import CustomerCare from './CustomerCare';

const DedicatedDpcLanding = (props) => {
  const state = useContext(store);
  const { globalState } = state;
  const { primaryLocation, isLoading } = globalState;

  if (isLoading || !primaryLocation) return <Loader />;

  return (
    <>
      <Banner displayName={primaryLocation.displayName} />
      <HelpCenterMenu />
      <div className="mid-divider"></div>
      <CustomerCare
        phone={primaryLocation?.phone}
        email={primaryLocation?.email}
      />
      <div className="h-24"></div>
    </>
  );
};

export default DedicatedDpcLanding;
