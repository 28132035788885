/**
 * @author Slesha Tuladhar <slesha@noveltytechnology.com>
 */

import * as httpService from './httpService';
import APICONSTANTS from '../constants';

export function fetchLookupsByType(type) {
  return httpService
    .get(`${APICONSTANTS.LOOKUP}/${type}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function fetchLookupData(queryParams) {
  return httpService
    .get(`${APICONSTANTS.LOOKUP}`, queryParams)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}
