import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Select, { components } from 'react-select';

import commonConstants from '../../../../constants/commonConstant';

import * as clientService from '../../../../service/clientService';
import { store } from '../../../../store';
import {
  selectReferredByOptions,
  setReferredByOptions,
} from '../../../../stores/membership';
import { _debounce } from '../../../../util/lodashUtil';
import { getFullName } from '../../../../util/vitafyUtil';

const { MEMBERSHIP_REGISTRATION_TYPES } = commonConstants;

const Option = (props) => {
  const { label } = props.data;
  return (
    <components.Option {...props}>
      <p>{label}</p>
      {/* <p className="text-xs text-bodyColor">{metadata?.email || ''}</p> */}
    </components.Option>
  );
};

const ReferringClientSelect = ({ data, onChange }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(location.search);
  const referredBy = queryParams.get('referredby'); // gives referring clientId

  // Context Store
  const state = useContext(store);
  const { globalState } = state;
  const { tenantId, groupCode } = globalState;

  const referredByOptions = useSelector(selectReferredByOptions);
  const totalReferredByOptions = referredByOptions?.length || 0;

  const [searchKeyword, setSearchKeyword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const fetchReferringClients = useCallback(
    async (
      dispatch,
      { keyword = '', groupCode, tenantId, referredBy = '' }
    ) => {
      setIsLoading(true);
      const queryParams = {
        tenantId,
        limit: 5,
        offset: 0,
        keyword,
        isAdvancedSearch: false,
        type: MEMBERSHIP_REGISTRATION_TYPES.individual.value,
        groupCode,
        sortBy: 'name',
        sortOrder: 'ASC',
        clientId: referredBy,
      };

      try {
        const response = await clientService.fetchClients(queryParams);

        const referringClientOptions = response.data.rows.map((item) => {
          item.displayName = getFullName({
            firstName: item.firstName || '',
            lastName: item.lastName || '',
            middleName: item.middleName || '',
          });

          const metadata = {
            email: item.email,
          };

          // If referredBy is present in the query param, disable the field
          if (!!referredBy && item.clientId === referredBy) {
            onChange({
              label: item.displayName,
              value: item.clientId,
              metadata: { ...metadata },
            });

            setIsDisabled(true);
          }

          return {
            label: item.displayName.trim(),
            value: item.clientId,
            metadata: { ...metadata },
          };
        });

        dispatch(setReferredByOptions(referringClientOptions));
      } catch (error) {
        console.log('Error while fetching referring clients', error);
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  // Populate first n clients on mount
  useEffect(() => {
    if (tenantId && groupCode && !totalReferredByOptions && !searchKeyword) {
      fetchReferringClients(dispatch, {
        groupCode,
        tenantId,
        referredBy,
      });
    }
  }, [
    tenantId,
    groupCode,
    totalReferredByOptions,
    dispatch,
    searchKeyword,
    fetchReferringClients,
    referredBy,
  ]);

  // Handle search for clients
  useEffect(() => {
    if (searchKeyword?.trim()?.length) {
      fetchReferringClients(dispatch, {
        keyword: searchKeyword,
        groupCode,
        tenantId,
      });
    } else {
      dispatch(setReferredByOptions([]));
    }
  }, [tenantId, groupCode, searchKeyword, dispatch, fetchReferringClients]);

  const onInputChange = _debounce((value) => {
    setSearchKeyword(value);
  }, 300);

  return (
    <div>
      <Select
        isLoading={isLoading}
        isDisabled={isDisabled}
        components={{ Option }}
        options={referredByOptions}
        isClearable
        value={{
          label: data?.label || '',
          value: data?.value || '',
        }}
        onChange={(option) =>
          onChange({
            label: option && option.label,
            value: option && option.value,
          })
        }
        onInputChange={onInputChange}
      />
    </div>
  );
};

export default React.memo(ReferringClientSelect);
