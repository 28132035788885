import API_CONSTANTS from '../constants';
import * as httpService from '../service/httpService';

// Depreciated
export function getHomeContent() {
  return httpService
    .getCosmicContent(`${API_CONSTANTS.COSMIC}/object/${API_CONSTANTS.HOME}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

// Depreciated
export function getPartners() {
  return httpService
    .getCosmicContent(
      `${API_CONSTANTS.COSMIC}/object/${API_CONSTANTS.PARTNERS}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

// Depreciated
export function getLatestNews() {
  return httpService
    .getCosmicContent(
      `${API_CONSTANTS.COSMIC}/object/${API_CONSTANTS.LATEST_NEWS}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

// Depreciated
export function getClientStories() {
  return httpService
    .getCosmicContent(
      `${API_CONSTANTS.COSMIC}/object/${API_CONSTANTS.CLIENT_STORY}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

// Depreciated
export function getAbout() {
  return httpService
    .getCosmicContent(
      `${API_CONSTANTS.COSMIC}/object/${API_CONSTANTS.ABOUT_US}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

// Depreciated
export function getTeam() {
  return httpService
    .getCosmicContent(
      `${API_CONSTANTS.COSMIC}/object/${API_CONSTANTS.OUR_TEAM}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

// Depreciated
export function getWhoWeAreContent() {
  return httpService
    .getCosmicContent(
      `${API_CONSTANTS.COSMIC}/object/${API_CONSTANTS.WHO_WE_ARE}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

// Depreciated
export function getWhoWeHelpContent() {
  return httpService
    .getCosmicContent(
      `${API_CONSTANTS.COSMIC}/object/${API_CONSTANTS.WHO_WE_HELP}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

// Depreciated
export function subscribeNewsletter(data) {
  return httpService
    .post(`${API_CONSTANTS.NEWSLETTER}`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

// Depreciated
export function submitContactForm(data) {
  return httpService
    .post(`${API_CONSTANTS.CONTACT_FORM}`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

// Depreciated
// get enrollment content from COSMIC
export function getEnrollmentContent() {
  return httpService
    .getCosmicContent(
      `${API_CONSTANTS.COSMIC}/object/${API_CONSTANTS.ENROLLMENT}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

// Depreciated
export function getCustomerPageContent() {
  return (
    httpService
      // .get(`${API_CONSTANTS.COSMIC}/object/${API_CONSTANTS.CUSTOMER_PAGES}`)
      .getCosmicContent(
        `${API_CONSTANTS.COSMIC}/object/${API_CONSTANTS.CUSTOMER_PAGES}`
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      })
  );
}

// Depreciated
// get footer contents (Quick Links)
export function getFooterContent() {
  return httpService
    .getCosmicContent(
      `${API_CONSTANTS.COSMIC}/object/${API_CONSTANTS.FOOTER_CONTENTS}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

// Depreciated
export function getvCareCosmicContent() {
  return httpService
    .getCosmicContent(
      `${API_CONSTANTS.COSMIC}/object/${API_CONSTANTS.VCARE_ENROLLMENT}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

// Depreciated
export function getMarketplaceCosmicContent() {
  return httpService
    .getCosmicContent(
      `${API_CONSTANTS.COSMIC}/object/${API_CONSTANTS.MARKETPLACE}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

// Depreciated
export function getDpcCosmicContent() {
  return httpService
    .getCosmicContent(
      `${API_CONSTANTS.COSMIC}/object/${API_CONSTANTS.DIRECT_PRIMARY_CARE}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

// Depreciated
export function getAgentNetworkCosmicContent() {
  return httpService
    .getCosmicContent(
      `${API_CONSTANTS.COSMIC}/object/${API_CONSTANTS.AGENT_NETWORK}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

// Depreciated
export function getBmtCosmicContent() {
  return httpService
    .getCosmicContent(`${API_CONSTANTS.COSMIC}/object/${API_CONSTANTS.BMT}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

// Depreciated
export function getDedicatedTenantCosmicContent(tenantCode) {
  return httpService
    .getCosmicContent(`${API_CONSTANTS.COSMIC}/object/${tenantCode}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

// Depreciated
export function getDirectCareProvidersContent() {
  return httpService
    .getCosmicContent(
      `${API_CONSTANTS.COSMIC}/object/${API_CONSTANTS.DIRECT_CARE_PROVIDERS}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

// Depreciated
export function getCommunityContent() {
  return httpService
    .getCosmicContent(
      `${API_CONSTANTS.COSMIC}/object/${API_CONSTANTS.COMMUNITY}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}
