/**
 * Pricings are different for member and non-member
 * User are allowed to navigate to checkout even if only service(s) with zero pricings are selected.
 * In such case, don't show card/bank/cash payment in the checkout step. Simply send the data with paymentMethod as Cash.
 * Member Found/Not Found Modal is shown only if I'm member checkbox is checked in step 1
 * No distinction between price and memberPrice for 'Others' service. Price is used for both
 */

import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import * as walkInServices from '../../../service/walkinService';
import * as clientService from '../../../service/clientService';
import * as tenantService from '../../../service/tenantService';

import * as vitafyUtils from '../../../util/vitafyUtil';

import {
  MultiStepFormWrapper,
  StepIndicator,
  PricingReflectModal,
} from '../common';
import { Loader, ButtonLoader } from '../../../common';
import FormBody from './FormBody';
import { showToast } from '../../../service/toasterService';

import { tenantCodeEnum } from '../../../constants/tenantCodeConstant';
import { getTextFromLangDict } from '../../../util';

class WalkinVideoVisit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1, // Step 1: Service, Step 2: Personal Info, Step 3: Checkout,
      data: {
        address: {},
        tenantCode: '',
        selectedServices: [],
        payment: {
          paymentMethod: null,
          paymentEnvironment: null,
        },
        iAgreeTerms: false,
        iAgreeDedicatedDpcTerms: false,
        enrollmentType: 'Ancillary',
        isMemberInput: false, // for the checkbox in step 1
        isMember: false, // stores the result got from the server
        raceOptions: [], // Race Options are fetched from lookup. This field is not required for submit. key `race` will carry the selected race
        hasInsurance: false, // for the checkbox in the checkout page. Only visible if insuranceIncluded is set to true.
        insuranceIncluded: false, // set to true if any one of the selected offering has insurance included. If true, display insurance information input in the checkout page
        transactionId: null, // for updating instruction for a given transaction. It is populated after successful checkout
      },
      services: [],
      cardErrorMessage: '',
      bankErrorMessage: '',
      errorMessage: '', // Error Message from Server,
      tmpPaymentData: {
        card: {
          token: '',
          expiry: '',
          tokenSource: '',
          cardNumber: '',
          cvc: '',
        },
        bank: {
          token: '',
          tokenSource: '',
          accountNumber: '',
          routingNumber: '',
          accountType: '',
        },
      },
      gatewayType: '',
      priceForOthers: null, // tmp holder for Other's price. The price is modified in the service list when user navigates to next page
      othersPriceInputError: false,
      isSubmitting: false,
      isLoadingServices: false,
      info: {},
      pricingReflectModalDisplayText: '', // Text is different if member found/not found
    };
    this.personalInfoFormRef = React.createRef();
    this.pricingReflectModalRef = React.createRef();
  }

  componentDidMount() {
    const {
      providerDetail,
      tenantCode,
      groupCode,
      tenantId,
      paymentEnvironment,
      langDict,
    } = this.props;

    this.setState(
      (prevState) => ({
        info: { ...providerDetail, tenantId },
        data: {
          ...prevState.data,
          tenantCode,
          tenantId,
          groupCode,
          payment: {
            ...prevState.data.payment,
            paymentEnvironment: paymentEnvironment,
          },
        },
        langDict,
      }),
      () => {
        this.fetchServices();
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.providerDetail !== this.props.providerDetail) {
      this.setState({ info: this.props.providerDetail });
    }

    if (prevProps.paymentEnvironment !== this.props.paymentEnvironment) {
      this.setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          payment: {
            ...prevState.data.payment,
            paymentEnvironment: this.props.paymentEnvironment,
          },
        },
      }));
    }

    if (
      prevProps.groupCode !== this.props.groupCode ||
      prevProps.tenantId !== this.props.tenantId ||
      prevProps.tenantCode !== this.props.tenantCode
    ) {
      this.setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          groupCode: this.props.groupCode,
          tenantId: this.props.tenantId,
          tenantCode: this.props.tenantCode,
        },
      }));
    }

    if (prevProps.langDict !== this.props.langDict) {
      this.setState({ langDict: this.props.langDict });
    }
  }

  fetchServices = () => {
    this.setState({ isLoadingServices: true });

    tenantService
      .fetchAncillaryOfferings(this.state.info.tenantId)
      .finally(() => this.setState({ isLoadingServices: false }))
      .then((response) => {
        const services = response.data.rows?.map((item) => ({
          ...item,
          // Note: price and billingInterval added to adjust old implementation
          price: item.nonMemberPrice,
          billingInterval: item.nonMemberBillingInterval,
        }));

        this.setState({ services });
      })
      .catch((error) => {
        console.log('Error while fetching the ancillary services', error);
      });
  };

  /**
   * Check if the service is present in selectedService list
   * if present, remove from the list
   * if not, append to the list
   * 1st click -> select, 2nd click -> deselect
   * if the service selected/deselected is Others, clear the tmpPrice input on deselect
   */
  handleSelectService = (service) => {
    let selectedServices = [...this.state.data.selectedServices];
    const idPresent =
      selectedServices &&
      selectedServices.some(
        (item) => item.tenantOfferingId === service.tenantOfferingId
      );
    if (idPresent) {
      selectedServices = selectedServices.filter(
        (item) => item.tenantOfferingId !== service.tenantOfferingId
      );
      if (service.name === 'Others')
        this.setState({ priceForOthers: '', othersPriceInputError: false });
    } else {
      selectedServices = [...selectedServices, service];
    }
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        selectedServices: selectedServices,
      },
    }));
  };

  changePaymentMethod = (type) => {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        payment: {
          ...prevState.data.payment,
          paymentMethod: type,
        },
      },
    }));
  };

  handleChangeTmpPaymentData = (type, data) => {
    if (!type) return;
    if (type.toLowerCase() === 'card') {
      this.setState((prevState) => ({
        tmpPaymentData: {
          ...prevState.tmpPaymentData,
          card: {
            token: data.token,
            expiry: data.expiry,
            tokenSource: data.tokenSource || '',
            cardNumber: data.cardNumber || '',
            cvc: data.cvc || '',
          },
        },
      }));
    } else if (type.toLowerCase() === 'bank') {
      this.setState((prevState) => ({
        tmpPaymentData: {
          ...prevState.tmpPaymentData,
          bank: {
            token: data.token,
            expiry: data.expiry || '',
            tokenSource: data.tokenSource || '',
            accountNumber: data.accountNumber || '',
            routingNumber: data.routingNumber || '',
            accountType: data.accountType || '',
          },
        },
      }));
    }
  };

  handleChangeIAgreeTerms = (value) => {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        iAgreeTerms: value,
      },
    }));
  };

  handleChangeIAgreeDedicatedDpcTerms = (value) => {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        iAgreeDedicatedDpcTerms: value,
      },
    }));
  };

  handleChangeIHaveNoInsurance = (value) => {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        hasNoInsurance: value,
        insuranceName: value ? '' : prevState.data.insuranceName,
        insuranceMemberId: value ? '' : prevState.data.insuranceMemberId,
        groupNumber: value ? '' : prevState.data.groupNumber,
      },
    }));
  };

  handleRemoveErrorMessage = () => {
    this.setState({ errorMessage: '' });
  };

  handleIsMemberCheckboxClick = (value) => {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        isMemberInput: value,
      },
    }));
  };

  handleCheckIsMember = (data) => {
    return new Promise((resolve, reject) => {
      const dataToSend = {
        firstName: data.firstName,
        lastName: data.lastName,
        dob: data.dob,
        ssn: data.last4SSN,
        tenantCode: data.tenantCode,
      };
      walkInServices
        .checkIsMember(dataToSend)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  showPricingReflectModal = (isMember) => {
    const displayText = isMember
      ? 'Thank you for being a member. The pricing has been updated to reflect for members.'
      : 'We could not find the corresponding member for the details you entered. The pricing has been updated to reflect for non-members.';
    this.setState(
      {
        pricingReflectModalDisplayText: displayText,
      },
      () => {
        this.pricingReflectModalRef.current.toggle();
      }
    );
  };

  handleContinueToCheckout = () => {
    this.pricingReflectModalRef.current.toggle();
    this.setState((prevState) => ({
      step: prevState.step + 1,
    }));
  };

  /**
   * Case 1: If `Others` is selected, update the price of `Others` in selectedServices by the input price (priceForOthers)
   *         In Case, the price input is empty, don't let user navigate to next page. Show required validation on the input field
   */
  handleNextClick = () => {
    const currentStep = this.state.step;
    switch (currentStep) {
      case 1:
        let selectedServices = [...this.state.data.selectedServices];
        // display insurance info input in checkout page if any of the ancillary offering has an insurnace included
        const insuranceIncluded = selectedServices.some(
          (service) => service.hasInsurance === true
        );

        this.setState((prevState) => ({
          data: {
            ...prevState.data,
            insuranceIncluded,
            hasInsurance: insuranceIncluded,
            insuranceName: insuranceIncluded
              ? prevState.data.insuranceName
              : '',
            insuranceMemberId: insuranceIncluded
              ? prevState.data.insuranceMemberId
              : '',
            groupNumber: insuranceIncluded ? prevState.data.groupNumber : '',
          },
        }));
        const isOthersPresent = selectedServices.some(
          (service) => service.name && service.name.toLowerCase() === 'others'
        );
        if (isOthersPresent) {
          const othersIndex = selectedServices.findIndex(
            (service) => service.name && service.name.toLowerCase() === 'others'
          );
          if (!this.state.priceForOthers) {
            this.setState({ othersPriceInputError: true });
            showToast('warning', 'Please enter price for Others');
            break;
          }
          // No distinction between price and memberPrice for Others
          // Keep same value on both. Price is used when
          selectedServices[othersIndex] = {
            ...selectedServices[othersIndex],
            price: parseInt(
              vitafyUtils.unformatCurrency(this.state.priceForOthers)
            ),
            memberPrice: parseInt(
              vitafyUtils.unformatCurrency(this.state.priceForOthers)
            ),
          };
          this.setState((prevState) => ({
            data: { ...prevState.data, selectedServices: selectedServices },
            step: prevState.step + 1,
          }));
        } else {
          this.setState((prevState) => ({ step: prevState.step + 1 }));
        }
        break;
      case 2:
        this.personalInfoFormRef.current.dispatchEvent(
          new Event('submit', { cancelable: true })
        );
        this.handleRemoveErrorMessage('');
        break;
      case 3:
        const totalPrice =
          this.state.data.selectedServices &&
          this.state.data.selectedServices.reduce(
            (total, current) =>
              total +
              (this.state.data.isMember ? current.memberPrice : current.price),
            0
          );
        this.setState((prevState) => ({
          data: { ...prevState.data, totalPrice: totalPrice },
        }));
        if (!totalPrice || totalPrice == 0) {
          // If the total price is zero, no need to show payment inputs. No need to validate card and bank inputs either.
          this.setState(
            (prevState) => ({
              data: {
                ...prevState.data,
                payment: { ...prevState.data.payment, paymentMethod: 'Other' },
              },
            }),
            () => {
              this.handleCheckout(this.state.data);
            }
          );
        } else {
          const { paymentMethod } = this.state.data.payment;
          const { tmpPaymentData } = this.state;
          if (paymentMethod === 'Card') {
            if (!(tmpPaymentData.card.token && tmpPaymentData.card.expiry)) {
              this.setState({
                cardErrorMessage: 'Please enter card information correctly.',
              });
              break;
            }
            this.handleFormSubmit(3, tmpPaymentData.card);
          } else if (paymentMethod === 'Bank') {
            if (!tmpPaymentData.bank.token) {
              this.setState({
                bankErrorMessage: 'Please enter bank information correctly.',
              });
              break;
            }
            this.handleFormSubmit(3, tmpPaymentData.bank);
          } else if (paymentMethod === 'Cash') {
            this.handleFormSubmit(3);
          }
        }
        break;
      default:
        console.log('Step out of the range');
    }
  };

  /**
   * Current Step will be always be 3
   */
  handleFormSubmit = (currentStep, formData) => {
    if (currentStep !== 3) {
      return;
    }

    this.setState(
      (prevState) => ({
        data: {
          ...prevState.data,
          payment: formData
            ? { ...prevState.data.payment, ...formData }
            : prevState.data.payment,
        },
      }),
      () => {
        this.handleCheckout(this.state.data);
      }
    );
  };

  handleCheckout = async (data) => {
    this.setState({ isSubmitting: true });

    const payload = vitafyUtils.formatAncillaryOfferingSavePayload({
      ...data,
      gatewayType: this.state.gatewayType,
    });

    try {
      const response = await clientService.saveClientAncillaryEnrollments(
        data.clientId,
        payload
      );
      this.setState((prevState) => ({
        ...prevState,
        step: prevState.step + 1,
        data: {
          ...prevState.data,
          transactionId: response.data.transactionId,
        },
      }));
    } catch (error) {
      console.log('Error while Walk-in Checkout', error);
      this.setState({ errorMessage: error.response?.data?.message });
    } finally {
      this.setState({ isSubmitting: false });
    }
  };

  handleAddUpdateClient = (newData, { changeStep, isMember }) => {
    // changeStep = false -> just update the state with new data
    if (!changeStep) {
      this.setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          ...newData,
        },
      }));

      return;
    }

    this.setState(
      (prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          ...newData,
          isMember,
        },
      }),
      () => {
        // Don't show modal if the user is non-member and he/she hasn't selected 'I'm a member' in step 1
        const { isMember, isMemberInput } = this.state.data;
        if (!isMemberInput && !isMember)
          this.setState((prevState) => ({
            ...prevState,
            step: prevState.step + 1,
          }));
        else this.showPricingReflectModal(isMember);
      }
    );
  };

  render() {
    const { langDict } = this.state;
    const { groupCode, tenantCode } = this.state.data;

    if (this.state.isLoadingServices) return <Loader hScreen={false} />;

    const includeDpcTerms =
      this.state.data.tenantCode.toUpperCase() === tenantCodeEnum.SIM ||
      this.state.data.tenantCode.toUpperCase() === tenantCodeEnum.KTMDPC;

    const disableNextBtn =
      this.state.isSubmitting || // Disable Next Button if the form is submitting or
      ((!this.state.data.iAgreeTerms ||
        (includeDpcTerms ? !this.state.data.iAgreeDedicatedDpcTerms : false)) &&
        this.state.step === 3) || // iAgreeCheckbox is not checked in step 3 (Checkout)
      (this.state.step === 1 && this.state.data.selectedServices.length == 0); // None of the service is selected in step 1 (Services)

    const SERVICE_STEP_TEXT = getTextFromLangDict(langDict, {
      key: '_SERVICE',
      groupCode,
      tenantCode,
    });
    const PATIENT_STEP_TEXT = getTextFromLangDict(langDict, {
      key: '_PATIENT',
      groupCode,
      tenantCode,
    });
    const CHECKOUT_STEP_TEXT = getTextFromLangDict(langDict, {
      key: '_CHECKOUT',
      groupCode,
      tenantCode,
    });

    return (
      <>
        <MultiStepFormWrapper
          header={
            <StepIndicator
              activeStep={this.state.step}
              steps={[SERVICE_STEP_TEXT, PATIENT_STEP_TEXT, CHECKOUT_STEP_TEXT]}
            />
          }
          displayHeader={this.state.step !== 4}
        >
          <FormBody
            step={this.state.step}
            data={this.state.data}
            services={this.state.services}
            handleSelectService={this.handleSelectService}
            priceForOthers={this.state.priceForOthers}
            changePriceForOthers={(value) =>
              this.setState({ priceForOthers: value })
            }
            othersPriceInputError={this.state.othersPriceInputError}
            personalInfoFormRef={this.personalInfoFormRef}
            handleChangeIAgreeTerms={this.handleChangeIAgreeTerms}
            handleChangeIAgreeDedicatedDpcTerms={
              this.handleChangeIAgreeDedicatedDpcTerms
            }
            changePaymentMethod={this.changePaymentMethod}
            cardErrorMessage={this.state.cardErrorMessage}
            clearCardErrorMessage={() =>
              this.setState({ cardErrorMessage: '' })
            }
            bankErrorMessage={this.state.bankErrorMessage}
            clearBankErrorMessage={() =>
              this.setState({ bankErrorMessage: '' })
            }
            tmpPaymentData={this.state.tmpPaymentData}
            handleChangeTmpPaymentData={this.handleChangeTmpPaymentData}
            errorMessage={this.state.errorMessage}
            handleRemoveErrorMessage={() => this.setState({ errorMessage: '' })}
            isSubmitting={this.state.isSubmitting}
            submit={this.handleFormSubmit}
            info={this.state.info}
            handleIsMemberCheckboxClick={this.handleIsMemberCheckboxClick}
            handleChangeIHaveNoInsurance={this.handleChangeIHaveNoInsurance}
            tenantSettings={this.props.tenantSettings}
            handleAddUpdateClient={this.handleAddUpdateClient}
            setIsSubmitting={(value) => this.setState({ isSubmitting: value })}
            setGatewayType={(value) => this.setState({ gatewayType: value })}
          />
        </MultiStepFormWrapper>
        {this.state.step !== 4 && (
          <div className="container px-4 mx-auto mt-6 mb-16">
            <div
              className={`flex flex-wrap md:flex-row-reverse justify-between items-center dedicated-container-margin`}
            >
              <button
                className={`mid-button w-full md:w-auto mb-2 md:mb-0 flex justify-center items-center
                  bg-dedicatedDpcSecondary hover:bg-dedicatedDpcHovered ${
                    disableNextBtn && 'btn-disabled'
                  }`}
                onClick={this.handleNextClick}
              >
                {this.state.isSubmitting ? (
                  <div className="inline mr-2">
                    <ButtonLoader />
                  </div>
                ) : null}
                <span>
                  {this.state.step === 3
                    ? getTextFromLangDict(langDict, {
                        key: '_CHECKOUT',
                        groupCode,
                        tenantCode,
                      })
                    : getTextFromLangDict(langDict, {
                        key: '_NEXT',
                        groupCode,
                        tenantCode,
                      })}
                </span>
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="text-white ml-3"
                />
              </button>
              {this.state.step !== 1 && (
                <button
                  className={`mid-button w-full md:w-auto px-4 text-dedicatedDpcSecondary bg-transparent hover:bg-gray-300
                ${
                  (this.state.step === 1 || this.state.isSubmitting) &&
                  'btn-disabled'
                }`}
                  onClick={() =>
                    this.setState((prevState) => ({ step: prevState.step - 1 }))
                  }
                >
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    className="text-dedicatedDpcSecondary mr-3"
                  />
                  {getTextFromLangDict(langDict, {
                    key: '_GO_BACK',
                    groupCode,
                    tenantCode,
                  })}
                </button>
              )}
            </div>
          </div>
        )}
        <PricingReflectModal
          modalRef={this.pricingReflectModalRef}
          handleContinueToCheckout={this.handleContinueToCheckout}
          handleCancelToCheckout={() =>
            this.pricingReflectModalRef.current.toggle()
          }
          content={this.state.pricingReflectModalDisplayText}
          isMember={this.state.data.isMember}
        />
      </>
    );
  }
}

export default WalkinVideoVisit;
