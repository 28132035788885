import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationCircle
} from '@fortawesome/free-solid-svg-icons';

const FormErrorMessage = (props) => {
  const {
    type = 'error',
    message,
  } = props;

  return (
    <div className={`w-full py-3 px-3 mt-2 flex items-start rounded ${type === 'error' ? 'bg-errorBg' : 'bg-warningBg'}`}>
      <FontAwesomeIcon
        icon={faExclamationCircle}
        className={`mt-1 mr-3 ${type === 'error' ? 'text-errorTxt' : 'text-warningTxt'}`}
      />
      <p className={`body-text ${type === 'error' ? 'text-errorTxt' : 'text-warningTxt'}`}>{message}</p>
    </div>
  );
}

export default FormErrorMessage;