import React, { useState, useRef, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import Fade from 'react-reveal/Fade';


const Client = ({ clients, scrollTo }) => {
 
  const inputRef = useRef([]);

  const scrollToMyRef = (scrollToIndex) => {
    window.scrollTo({
      left: 0,
      top:
        inputRef.current &&
        inputRef.current.length > 0 &&
        inputRef.current[scrollToIndex].offsetTop - 120,
      behavior: 'smooth',
    });
  };

  // Employers => scroll to current[2]
  // Direct Primary Care Doctors and Specialists => scroll to current[0]
  // Smart Advisors => scroll to current[3]

  useEffect(() => {

    if (scrollTo) {
      let scrollToIndex;
      if(scrollTo.scrollTo === 'Employers') scrollToIndex = 2;
      else if(scrollTo.scrollTo === 'Direct Primary Care Doctors and Specialists') scrollToIndex = 0;
      else if(scrollTo.scrollTo === 'Smart Advisors') scrollToIndex = 3;

      setTimeout(() => {
        scrollToMyRef(scrollToIndex);
      }, 200);
    }
  }, []);

  const display =
    clients &&
    clients.map((item, index) => {
      return (
        <React.Fragment key={index}>

          <Fade>
            <div ref={(el) => (inputRef.current[index] = el)} className='flex flex-wrap items-center md:-mx-10 mb-20 lg:mb-24'>
              <div
                className={`w-full md:w-1/2 md:px-10 ${
                  index % 2 === 0 ? 'md:block' : 'md:hidden'
                }`}
              >
                <img
                  src={item.image.url}
                  className='w-full mb-8 md:mb-0 rounded-md lg:pl-24'
                  alt='image'
                />
              </div>
              <div className='w-full md:w-1/2 md:px-10'>
                <h3
                  className={`heading  ${
                    index % 2 !== 0 ? 'secondary-bar' : null
                  }`}
                >
                  {item.title}
                </h3>
                <div className='font-heebo text-bodyColor'>
                  {ReactHtmlParser(item.content)}
                </div>
              </div>
              <div
                className={`w-full  hidden md:px-10 ${
                  index % 2 !== 0 ? 'md:block md:w-1/2' : 'md:hidden'
                }`}
              >
                <img
                  className='w-full rounded-md lg:pr-24'
                  src={item.image.url}
                  alt='image'
                />
              </div>
            </div>
          </Fade>

          {/* {(index === clients.length -1) ? () => setListRendered(true) : null} */}
        </React.Fragment>
      );
    });

  return (
    <>
      <div className='inner-divider hidden lg:block'></div>
      <div className='container mx-auto px-4 overflow-hidden'>{display}</div>
    </>
  );
};

export default Client;
