import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

import PaymentSummary from './PaymentSummary';
import {
  PaymentMethod,
  CardPayment,
  BankPayment,
  CashPayment,
  AgreeTerms,
} from '../../common';
import { Checkbox } from '../../../../common';

import { tenantCodeEnum } from '../../../../constants/tenantCodeConstant';
import { store } from '../../../../store';
import { getTextFromLangDict } from '../../../../util';
import { PAYMENT_METHOD_OTHER } from '../../../../constants/payment';

const Checkout = ({
  data,
  errorMessage,
  handleRemoveErrorMessage,
  tenantSettings,
  cardErrorMessage,
  bankErrorMessage,
  clearCardErrorMessage,
  clearBankErrorMessage,
  tmpPaymentData,
  handleChangeTmpPaymentData,
  handleChangeIAgreeTerms,
  handleChangeIAgreeDedicatedDpcTerms,
  isSubmitting,
  changePaymentMethod,
  setGatewayType,
}) => {
  const { isMember, selectedServices } = data;

  // Context Store
  const state = useContext(store);
  const { globalState } = state;
  const { groupCode, langDict, tenantCode, merchantProfile } = globalState;

  const { paymentMethod } = data.payment;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  // Set gateway type
  useEffect(() => {
    if (merchantProfile?.gatewayType) {
      setGatewayType(merchantProfile.gatewayType);
    }
  }, [merchantProfile]);

  const onAddNewCardClick = () => {
    handleChangeTmpPaymentData('card', {
      token: '',
      expiry: '',
      tokenSource: '',
      cardNumber: '',
      cvc: '',
    });
    clearCardErrorMessage();
    handleRemoveErrorMessage();
  };

  const onAddNewBankClick = () => {
    handleChangeTmpPaymentData('bank', {
      token: '',
      tokenSource: '',
      accountNumber: '',
      routingNumber: '',
      accountType: '',
    });
    clearBankErrorMessage();
    handleRemoveErrorMessage();
  };

  const onChangePaymentMethod = (data) => {
    changePaymentMethod(data);
    clearCardErrorMessage();
    clearBankErrorMessage();
    handleRemoveErrorMessage();
  };

  // No Payment Method (input) required if totalPrice is zero.
  const totalPrice =
    selectedServices &&
    selectedServices.reduce(
      (total, current) =>
        total + (isMember ? current.memberPrice : current.price),
      0
    );

  const includeDpcTerms =
    data.tenantCode.toUpperCase() === tenantCodeEnum.SIM ||
    data.tenantCode.toUpperCase() === tenantCodeEnum.KTMDPC;

  return (
    <>
      <PaymentSummary
        isMember={isMember}
        selectedServices={selectedServices}
        totalPrice={totalPrice}
      />
      <div className="form-section-divider-md"></div>

      {totalPrice != 0 && (
        <>
          <PaymentMethod
            data={data}
            changePaymentMethod={onChangePaymentMethod}
            tenantSettings={tenantSettings}
            includeCash={true}
            // includeBank={true} // Bank payment not enabled for Ancillary (One Time) offering
          />
          <div className="form-section-divider-md"></div>
          <h5 className="dedicated-form-title mb-2">
            {paymentMethod &&
              paymentMethod !== 'Cash' &&
              paymentMethod?.toLowerCase() !==
                PAYMENT_METHOD_OTHER?.toLowerCase() && (
                <>Please enter {paymentMethod.toLowerCase()} information</>
              )}
          </h5>
          {paymentMethod === 'Card' && (
            <CardPayment
              onClearCardError={clearCardErrorMessage}
              filledCardData={tmpPaymentData?.card}
              onChangeData={handleChangeTmpPaymentData}
              onAddNewCardClick={onAddNewCardClick}
              cardErrorMessage={cardErrorMessage}
              errorMessage={errorMessage}
              isSubmitting={isSubmitting}
            />
          )}

          {paymentMethod === 'Bank' && (
            <BankPayment
              onClearBankError={clearBankErrorMessage}
              filledBankData={tmpPaymentData?.bank}
              onChangeData={handleChangeTmpPaymentData}
              onAddNewBankClick={onAddNewBankClick}
              bankErrorMessage={bankErrorMessage}
              errorMessage={errorMessage}
              isSubmitting={isSubmitting}
            />
          )}

          {paymentMethod === 'Cash' && <CashPayment />}
        </>
      )}

      <p className="body-text text-sm font-bold text-titleColor mt-3 mb-5">
        {getTextFromLangDict(langDict, {
          key: '_NO_REFUND_IS_AVAILABLE_AFTER_ANY_TEST_OR_SERVICE_PERFORMED',
          groupCode,
          tenantCode,
        })}
      </p>

      <div className={`${includeDpcTerms && 'mb-3'}`}>
        <AgreeTerms
          iAgreeTerms={data.iAgreeTerms}
          handleChangeIAgreeTerms={handleChangeIAgreeTerms}
          removePaymentProviderLink={true}
        />
      </div>
      {includeDpcTerms && (
        <Checkbox
          checked={data.iAgreeDedicatedDpcTerms}
          setChecked={(e) => handleChangeIAgreeDedicatedDpcTerms(e)}
          className="inline"
        >
          <p className="font-heebo text-sm xl:text-base text-titleColor mb-0 select-none inline-block">
            I agree with{' '}
            <Link
              to="/agreement"
              target="_blank"
              className="no-underline font-bold text-dedicatedDpcPrimary focus:outline-none"
            >
              terms of agreement
            </Link>
          </p>
        </Checkbox>
      )}
    </>
  );
};

export default Checkout;
