import React, { useContext } from 'react';
import {
  faArrowLeft,
  faArrowRight,
  faClipboardList,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { store } from '../../store';
import { getTextFromLangDict, parseHtml } from '../../util';

const GetStarted = ({ onNextClick, onPrevClick }) => {
  const state = useContext(store);
  const { globalState } = state;
  const { langDict, groupCode, tenantCode } = globalState;

  const subtitle =
    getTextFromLangDict(langDict, {
      key: '_ELIGIBILITY_QUESTIONNAIRE_GET_STARTED_SUBTITLE',
      groupCode,
      tenantCode,
    }) || '';

  return (
    <div className="py-10">
      <div className="min-h-450px">
        <FontAwesomeIcon
          className="block text-dedicatedDpcPrimary text-4xl text-center mx-auto mb-6"
          icon={faClipboardList}
        />

        <h4 className="text-titleColor font-medium text-xl text-center mb-4">
          {getTextFromLangDict(langDict, {
            key: '_ELIGIBILITY_QUESTIONNAIRE_GET_STARTED_TITLE',
            groupCode,
            tenantCode,
          })}
        </h4>
        <div
          className={`body-text text-center`}
          dangerouslySetInnerHTML={{ __html: parseHtml(subtitle) }}
        />

        <button
          className="mid-button bg-dedicatedDpcSecondary hover:bg-dedicatedDpcHovered mx-auto mt-10 mb-4"
          type="button"
          onClick={onNextClick}
        >
          {getTextFromLangDict(langDict, {
            key: '_ELIGIBILITY_QUESTIONNAIRE_GET_STARTED_BUTTON_TEXT',
            groupCode,
            tenantCode,
          })}
          <FontAwesomeIcon className="ml-2" icon={faArrowRight} />
        </button>

        <button
          className="mid-button px-4 py-3 bg-transparent text-titleColor hover:bg-gray-300 mt-16 mx-auto"
          type="button"
          onClick={onPrevClick}
        >
          <FontAwesomeIcon
            className="text-titleColor mr-2"
            icon={faArrowLeft}
          />
          back
        </button>
      </div>
    </div>
  );
};

export default GetStarted;
