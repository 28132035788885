import React, { useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Checkbox, DedicatedFormModal } from '../../common';
import {
  selectActiveQuestion,
  selectRespondent,
  setActiveQuestion,
} from '../../stores/questionaire';
import { getTextFromLangDict, parseHtml } from '../../util';
import { _cloneDeep } from '../../util/lodashUtil';
import { AGREE_DISAGREE_VALUE } from '../../constants/questionaire';
import ScrollToBottomDetection from '../../common/ScrollToBottomDetection';
import { store } from '../../store';

const AgreeDisagreeInput = () => {
  const dispatch = useDispatch();

  const state = useContext(store);
  const { globalState } = state;
  const { langDict, groupCode, tenantCode } = globalState;

  const activeQuestion = useSelector(selectActiveQuestion);
  const respondent = useSelector(selectRespondent);
  const options = activeQuestion?.options || [];
  const answer = activeQuestion?.answer || {};

  const [modalContent, setModalContent] = useState(null);

  const onChange = ({ newValue, option }) => {
    const updatedQuestion = _cloneDeep(activeQuestion);

    if (updatedQuestion.answer && updatedQuestion.answer[option.value]) {
      updatedQuestion.answer[option.value].value = newValue
        ? AGREE_DISAGREE_VALUE.AGREE
        : AGREE_DISAGREE_VALUE.DISAGREE;
    } else {
      updatedQuestion.answer = {
        ...updatedQuestion.answer,
        [option.value]: {
          text: option.value,
          value: newValue
            ? AGREE_DISAGREE_VALUE.AGREE
            : AGREE_DISAGREE_VALUE.DISAGREE,
          isRequired: option.isRequired,
        },
      };
    }

    dispatch(setActiveQuestion(updatedQuestion));
    setModalContent(false);
  };

  const onCloseModal = () => {
    setModalContent(null);
  };

  const handleScrollBottomDetection = (value) => {
    const updatedQuestion = _cloneDeep(activeQuestion);

    if (updatedQuestion.enableAction && updatedQuestion.enableAction[value]) {
      return;
    }

    // Action (Agree or Disagree + Checkbox) is only enabled if the user scrolls to the bottom of respective modal content
    updatedQuestion.enableAction = {
      ...updatedQuestion.enableAction,
      [value]: true,
    };

    dispatch(setActiveQuestion(updatedQuestion));
  };

  const isFooterButtonEnabled =
    modalContent?.value &&
    activeQuestion.enableAction &&
    !!activeQuestion.enableAction[modalContent.value];

  const helpText = getTextFromLangDict(langDict, {
    key: '_ELIGIBILITY_QUESTIONNAIRE_AGREE_DISAGREE_HELP_TEXT',
    groupCode,
    tenantCode,
  });
  const modalHelpText = getTextFromLangDict(langDict, {
    key: '_ELIGIBILITY_QUESTIONNAIRE_AGREE_DISAGREE_MODAL_HELP_TEXT',
    groupCode,
    tenantCode,
  });

  return (
    <div>
      {options.map((item, index) => {
        const isChecked =
          answer[item.value]?.value === AGREE_DISAGREE_VALUE.AGREE;

        return (
          <Checkbox
            key={item.value}
            checked={isChecked}
            disabled={
              !(
                activeQuestion.enableAction &&
                activeQuestion.enableAction[item.value]
              )
            }
            setChecked={() => onChange({ newValue: !isChecked, option: item })}
            wrapperClassName={`${index !== 0 ? 'mt-4' : ''}`}
          >
            <div
              className="font-heebo text-sm xl:text-base text-titleColor mb-0 select-none inline-block"
              onClick={() => setModalContent(item)}
              dangerouslySetInnerHTML={{
                __html: parseHtml(item.value),
              }}
            />
          </Checkbox>
        );
      })}

      {!!helpText && (
        <p className="mt-6 mb-0 text-sm text-bodyColor">{helpText}</p>
      )}

      {!!modalContent?.metadata?.modalContent && (
        <DedicatedFormModal
          modalTitle={modalContent.metadata.modalTitle}
          reset={onCloseModal}
          show
        >
          <ScrollToBottomDetection
            containerClassName="modal-body primary-scrollbar"
            containerStyle={{ maxHeight: '65vh', overflowY: 'auto' }}
            onScrollBottomDetection={() =>
              handleScrollBottomDetection(modalContent.value)
            }
          >
            {respondent?.tenantMetadata?.imageUrl && (
              <div className="flex justify-center mt-4 mb-6">
                <img
                  src={respondent?.tenantMetadata?.imageUrl}
                  className="block h-12 xxl:h-16"
                  alt="logo"
                />
              </div>
            )}
            <div
              className="font-heebo text-sm xl:text-base text-titleColor mb-0 select-none inline-block"
              dangerouslySetInnerHTML={{
                __html: parseHtml(modalContent.metadata.modalContent),
              }}
            />
          </ScrollToBottomDetection>

          {!!modalHelpText && (
            <div className="px-6 py-2 text-sm text-bodyColor">
              * Scroll to bottom to proceed
            </div>
          )}

          <div className="modal-footer justify-end">
            <button
              className={`mid-button px-4 py-3 bg-transparent text-dedicatedDpcSecondary hover:bg-gray-300 ${
                !isFooterButtonEnabled ? 'btn-disabled' : ''
              }`}
              type="button"
              disabled={!isFooterButtonEnabled}
              onClick={() =>
                onChange({ newValue: false, option: modalContent })
              }
            >
              {modalContent?.disagreeButtonText || 'I Disagree'}
            </button>
            <button
              className={`mid-button px-10 py-3 bg-dedicatedDpcSecondary hover:bg-dedicatedDpcHovered ml-2
              ${!isFooterButtonEnabled ? 'btn-disabled' : ''}`}
              type="button"
              onClick={() => onChange({ newValue: true, option: modalContent })}
            >
              {modalContent?.agreeButtonText || 'I Agree'}
            </button>
          </div>
        </DedicatedFormModal>
      )}
    </div>
  );
};

export default AgreeDisagreeInput;
