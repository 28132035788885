import React, { useContext, useEffect, useState } from 'react';
import withSizes from 'react-sizes';
import { store } from '../../../../store';
import { getCardConnectIframeBaseUrl } from '../../../../util/vitafyUtil';
import { FormErrorMessage } from '../../../../common';

const parameters =
  'invalidinputevent=true&enhancedresponse=true&placeholder=Routing Number     Account Number';
const encodedStyles =
  "body %7B font-family: 'Heebo', sans-serif; margin-left: 0;%0A%7D%0Ainput %7B border: 1.5px solid %23d4d4d4; height: 40px; background-color: %23fafafa; font-size: 16px; text-indent: 16px; margin-top: 10px; color: %23333;%0A%7D input:focus %7B outline: none !important; border: 1.5px solid %2300a1be; box-shadow: 0 0 3px %23b0b0b0; background-color: %23ffffff;%0A%7D select %7B border: 1.5px solid %23d4d4d4; height: 44.5px; background-color: %23fafafa; font-size: 16px; margin-top: 10px; color: %23333; text-indent: 8px;%0A%7D select:focus %7B outline: none !important; border: 1.5px solid %2300a1be; box-shadow: 0 0 3px %23b0b0b0; background-color: %23ffffff;%0A%7D select:not(:-internal-list-box) %7B overflow: visible !important;%0A%7D %23cccardlabel,%0A%23ccexpirylabel,%0A%23cccvvlabel %7B display: none;%0A%7D %23ccnumfield %7B width: 100%25; display: block; margin-bottom: 0.4rem;%0A%7D %23ccexpirymonth %7B width: 4rem; margin-right: 1rem;%0A%7D %23ccexpiryyear %7B width: 5rem; margin-right: 1rem;%0A%7D";

const BankPayment = ({
  isMobile,
  clearError,
  filledBankData,
  onChangeData,
  onAddNewBankClick,
  bankErrorMessage,
  errorMessage,
  isSubmitting,
}) => {
  // Context Store
  const state = useContext(store);
  const { globalState } = state;
  const { merchantProfile } = globalState;

  const baseUrl = getCardConnectIframeBaseUrl(
    merchantProfile?.paymentEnvironment
  );

  const [validation, setValidation] = useState({
    requiredInput: false,
    errorMsg: '',
    isFormSubmitted: false,
  });

  const clearBankErrorMessage = () => {
    clearError();
  };

  const onChangeBankData = (data) => {
    onChangeData('bank', data);
  };

  const listener = (event) => {
    if (typeof event.data == 'string') {
      // 1004 -> Input Required
      // 1001 -> Invalid Input
      try {
        const response = JSON.parse(event.data);

        if (response.errorCode !== '0') {
          const inputRequired = response.errorCode === '1004';
          const inputInvalid = response.errorCode === '1001';

          setValidation((prevState) => ({
            ...prevState,
            requiredInput: inputRequired,
            errorMsg: inputInvalid ? response.errorMessage : '',
          }));
        } else {
          onChangeBankData({
            token: response.token,
          });
          clearBankErrorMessage();
        }
      } catch (e) {
        console.log('Card Connect JSON Parse error', e);
      }
    }
  };

  const getFormattedAccountNumber = (token) => {
    if (!token) return '';
    return '************' + token.slice(-4);
  };

  useEffect(() => {
    window.addEventListener('message', listener);
    clearBankErrorMessage();
  }, []);

  useEffect(() => {
    return () => {
      window.removeEventListener('message', listener);
    };
  }, []);

  return (
    <>
      <p className="mt-2 body-text text-sm">
        Enter your bank information in this format:{' '}
        <strong>routing number/account number</strong>. <br />
        For example: 123456789/1234123412341234
      </p>
      <div className="mt-1 md:mt-2">
        {filledBankData?.token && (
          <div className="mt-4 mb-10">
            <div className="flex justify-between items-center">
              <p className="body-text mb-3 font-semibold">
                Use recently entered Bank Information
              </p>
              <button
                className={`px-4 py-2 rounded-md hover:bg-orange-200 cursor-pointer transition-all duration-150 ${
                  isSubmitting && 'btn-disabled'
                }`}
                onClick={onAddNewBankClick}
                type="button"
              >
                <p className="text-secondary font-medium">Add New Account</p>
              </button>
            </div>
            <p className="body-text mb-1">
              Account Number&nbsp; :{' '}
              <span className="text-titleColor">
                {getFormattedAccountNumber(filledBankData?.token)}
              </span>
            </p>
          </div>
        )}
        <iframe
          style={{ display: filledBankData?.token ? 'none' : 'block' }}
          title="iframe"
          id="tokenframe"
          name="tokenframe"
          frameBorder="0"
          scrolling="no"
          width="100%"
          height={isMobile ? '110' : '80'}
          src={`${baseUrl}/itoke/ajax-tokenizer.html?&${parameters}&css=${encodedStyles}`}
        />
        <div className="min-h-2 mb-3">
          {/* Error/Warning Message */}
          {validation.requiredInput && (
            <FormErrorMessage
              type="warning"
              message="Please fill all the bank information."
            />
          )}
          {validation.invalidInput || bankErrorMessage ? (
            <FormErrorMessage
              type="warning"
              message={
                bankErrorMessage
                  ? bankErrorMessage
                  : 'Please make sure that the provided bank informations are correct.'
              }
            />
          ) : null}
          {errorMessage ? (
            <FormErrorMessage type="error" message={`Error: ${errorMessage}`} />
          ) : null}
        </div>
      </div>
    </>
  );
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 768,
});

export default withSizes(mapSizesToProps)(BankPayment);
