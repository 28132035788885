import React, { useEffect } from 'react';
import Select from 'react-select';
import { FlagIcon } from 'react-flag-kit';
import { Controller, useFormContext } from 'react-hook-form';
import MaskedPhoneInput from '../MaskedPhoneInput';
import { countryCodesAndphoneExt } from '../../constants/country';
import { getIndividualSchema } from '../../schemas/membership';
import { Checkbox, FormMaskedPhoneInput } from '../../common';
import { getMiscTenantSetting } from '../../util/tenantSettingUtil';
import commonConstants from '../../constants/commonConstant';
import { useContext } from 'react';
import { store } from '../../store';
import WithSizes from 'react-sizes';
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import { countryCode } from '../../constants/countryCode';

const { TENANT_SETTINGS_CODE, TENANT_SETTINGS_STATUS } = commonConstants;

const formattedSelectOptions = countryCodesAndphoneExt.map((data) => ({
  value: data.countryCode,
  label: data.countryName,
  icon: <FlagIcon code={data.countryCode} height="15px" width="21px" />,
  ...data,
}));

const CustomSingleValue = ({ data }) => <div className="ml-2">{data.icon}</div>;

const FormCountryPhoneInput = ({ label, isMobile, allowSMS = true }) => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
    clearErrors,
  } = useFormContext();
  // Context Store
  const state = useContext(store);
  const { globalState } = state;
  const { tenantSettings } = globalState;

  const selectedCountryCode = watch('countryCode');
  const selectedPhoneExt = watch('phoneExt');
  const isTextEnabled = watch('textEnabled');
  const phoneValue = watch('phone');

  const isInternationalPhoneInput =
    getMiscTenantSetting(TENANT_SETTINGS_CODE.INTERNATIONAL_ADDRESS, {
      tenantSettings,
    }) === TENANT_SETTINGS_STATUS.ENABLED;

  const onChange = (selected) => {
    setValue('countryCode', selected?.value || '');
    setValue('phoneExt', selected?.phoneExt || '');
    setValue('addressLine1', '');
    setValue('addressLine2', '');
    setValue('city', '');
    setValue('state', '');
    setValue('zip', '');
    setValue('phone', '');
  };

  const activeOption = isInternationalPhoneInput
    ? formattedSelectOptions.filter(
        (item) => item.value === selectedCountryCode
      ) || []
    : null;

  const isPhoneValid = getIndividualSchema({}).fields.phone.isValidSync(
    phoneValue
  );

  useEffect(() => {
    if (!isPhoneValid) {
      setValue('textEnabled', false);
    }
  }, [isPhoneValid, setValue]);

  // Clear error on field change
  useEffect(() => {
    if (selectedCountryCode) {
      clearErrors('countryCode');
    }
  }, [selectedCountryCode, clearErrors]);

  const onPhoneInput = (value) => {
    setValue('phone', value);
    clearErrors('phone');
  };

  return (
    <div className="flex items-center flex-wrap mb-3 w-full">
      {isInternationalPhoneInput ? (
        <div className="flex items-center w-full">
          <div>
            <Select
              menuPlacement="auto"
              menuPosition="fixed"
              value={activeOption}
              options={formattedSelectOptions}
              onChange={onChange}
              styles={{
                Input: (base) => ({ ...base, display: 'none' }),
                indicatorSeparator: () => {}, // removes the "stick"
                dropdownIndicator: (defaultStyles) => ({
                  ...defaultStyles,
                  padding: 0,
                  // display: 'none',
                  // fontSize: '50px',
                }),
                menu: (base) => ({
                  ...base,
                  width: 'max-content',
                  minWidth: '100%',
                }),
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                valueContainer: (base, state) => ({
                  ...base,
                  padding: 0,
                  flexWrap: 'nowrap',
                  // overflow: state.isFocused && 'inherit',
                }),
                control: (base, state) => ({
                  ...base,
                  width: 55,
                  background: 'transparent',
                  border: 'none',
                  borderRadius: 0,
                  marginTop: 3,
                  outline: 'none',
                  borderBottom: state.isFocused
                    ? '1px solid #000'
                    : '1px solid #dedede',
                }),
                option: (base) => ({
                  ...base,
                  width: 350,
                }),
              }}
              formatOptionLabel={(e) => (
                <div className="flex items-center">
                  {e.icon}
                  <span className="ml-2">{e.label}</span>
                </div>
              )}
              components={{
                SingleValue: CustomSingleValue,
              }}
            />
            {!!errors.countryCode && (
              <span className="text-xs text-errorTxt">
                {errors.countryCode.message}
              </span>
            )}
          </div>
          <div
            className="relative w-full"
            // style={{
            //   width: 140,
            // }}
          >
            <span className="absolute" style={{ top: 20, left: 10 }}>
              +{selectedPhoneExt}
            </span>
            <Controller
              control={control}
              name="phone"
              render={({ field }) => (
                <MaskedPhoneInput
                  {...field}
                  id="phone"
                  label={label}
                  input={field.value}
                  error={!!errors?.phone}
                  setInput={onPhoneInput}
                  isFormat={selectedCountryCode === countryCode.USA}
                  style={{
                    paddingLeft:
                      selectedPhoneExt?.length === 1
                        ? 34
                        : selectedPhoneExt?.length === 2
                        ? 44
                        : selectedPhoneExt?.length === 3
                        ? 52
                        : 64,
                  }}
                />
              )}
            />
          </div>
        </div>
      ) : (
        <div
          className="w-full"
          // style={{
          //   width: 160,
          // }}
        >
          <FormMaskedPhoneInput
            name="phone"
            label="Phone *"
            icon={isMobile ? '' : faMobileAlt}
          />
        </div>
      )}
      {allowSMS && (
        <div className="xs:mt-2">
          <Checkbox
            disabled={!phoneValue || !isPhoneValid}
            checked={isTextEnabled}
            setChecked={(e) => setValue('textEnabled', !isTextEnabled)}
            className="inline"
          >
            <p className="font-heebo text-sm text-titleColor mb-0 select-none inline-block">
              Allow Text Notifications
            </p>
          </Checkbox>
        </div>
      )}
    </div>
  );
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 768,
});

export default WithSizes(mapSizesToProps)(FormCountryPhoneInput);
