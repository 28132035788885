import React, { useContext } from 'react';

import { tenantCodeEnum } from '../../constants/tenantCodeConstant';

import { store } from '../../store';

import CommentIconHcm from '../../assets/images/hcm-svg/comment.svg';
import CommentIconBcity from '../../assets/images/bcity-svg/comment.svg';
import CommentIconDpc from '../../assets/images/dpc-svg/comment.svg';
import CommentIconRsd from '../../assets/images/rsd-svg/comment.svg';
// import CommentIconSim from '../../assets/images/sim-svg/comment.svg';

const CustomerCare = ({ email, phone }) => {
  const state = useContext(store);
  const { primaryLocation, langDict } = state.globalState;

  if (!primaryLocation || !langDict) {
    return <>...</>;
  }

  const code = primaryLocation?.code || '';

  const CommentIcon =
    code === tenantCodeEnum.HCM || code === tenantCodeEnum.HCMNR
      ? CommentIconHcm
      : code === tenantCodeEnum.BCITY
      ? CommentIconBcity
      : code === tenantCodeEnum.RSD
      ? CommentIconRsd
      : CommentIconDpc;

  return (
    <div className="container mx-auto px-4 flex justify-center">
      <div className="flex">
        <img
          src={CommentIcon}
          className="h-16 w-auto block"
          alt="comment-icon"
        />
        <div className="ml-3 md:ml-6">
          <h5 className="font-heebo text-titleColor font-bold text-xl md:text-2xl mb-2 md:mb-0">
            {langDict?._DO_YOU_HAVE_QUESTIONS}
          </h5>
          <a
            href={`tel:${phone}`}
            type="button"
            className="inline-block rounded-md body-text text-dedicatedDpcPrimary text-sm px-8 py-3 hover:bg-gray-200 mt-3 md:mt-5 no-underline focus:outline-none border border-dedicatedDpcPrimary border-opacity-100"
          >
            {langDict?._CALL}
          </a>
          <a
            href={`mailto:${email}`}
            type="button"
            className="inline-block rounded-md body-text text-dedicatedDpcPrimary text-sm px-5 py-3 hover:bg-gray-200 mt-3 md:mt-5 no-underline focus:outline-none border border-dedicatedDpcPrimary border-opacity-100 ml-2"
          >
            {langDict?._MESSAGE}
          </a>
        </div>
      </div>
    </div>
  );
};

export default CustomerCare;
