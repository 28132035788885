import { divide } from 'lodash';
import React from 'react';

const PlanInfo = () => {
  return (
    <div className={`bg-bgPrimaryLight mt-4 px-4 py-4 border-l-4 border-primary mx-5 mb-3`}>
      <h5 className='body-text text-titleColor text-xl font-bold mb-2'>Agent Signup Plan</h5>
    </div>
  );
}

export default PlanInfo;