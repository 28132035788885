import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';

import commonConstants from '../../constants/commonConstant';
import { store } from '../../store';
import { getTextFromLangDict } from '../../util';

const { GENDER, TENANT_SETTINGS_CODE, TENANT_SETTINGS_STATUS } =
  commonConstants;

const GenderRadioButton = ({ whiteBg, isSelected, type, onClick }) => (
  <button
    data-cy={`gender-${type}`}
    className={`small-radio-btn ${!whiteBg && 'bg-white'} w-full md:py-1 
       ${isSelected && 'bg-holyCityMedSecondary font-bold'}`}
    onClick={onClick}
    type="button"
  >
    {type}
  </button>
);

const FormGenderInput = ({ name, whiteBg }) => {
  const { watch, setValue } = useFormContext();

  // Context Store
  const state = useContext(store);
  const { globalState } = state;
  const { tenantSettings, langDict, groupCode } = globalState;

  if (!tenantSettings || !langDict) return <>...</>;

  const showOthers =
    tenantSettings.miscellaneous?.find(
      (item) => item.code === TENANT_SETTINGS_CODE.OTHERS_IN_GENDER
    )?.status === TENANT_SETTINGS_STATUS.ENABLED;

  const value = watch('gender');

  const handleClick = (value) => {
    setValue(name, value);
  };

  const label = getTextFromLangDict(langDict, {
    key: '_GENDER',
    groupCode,
  });

  return (
    <div className="w-full md:w-1/3 px-4">
      <p className="body-text text-sm mb-1">{label}</p>
      <div className="w-full flex align-items-center -mx-1">
        <div className={`${showOthers ? 'w-1/3' : 'w-1/2'} px-1`}>
          <GenderRadioButton
            type="Male"
            whiteBg={whiteBg}
            isSelected={value === GENDER.male.value}
            onClick={() => handleClick(GENDER.male.value)}
          />
        </div>
        <div className={`${showOthers ? 'w-1/3' : 'w-1/2'} px-1`}>
          <GenderRadioButton
            type="Female"
            whiteBg={whiteBg}
            isSelected={value === GENDER.female.value}
            onClick={() => handleClick(GENDER.female.value)}
          />
        </div>
        {showOthers && (
          <div className="w-1/3 px-1">
            <GenderRadioButton
              type="Others"
              whiteBg={whiteBg}
              isSelected={value === GENDER.others.value}
              onClick={() => handleClick(GENDER.others.value)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

FormGenderInput.defaultProps = {
  isRequired: false,
  isDisabled: false,
};

export default FormGenderInput;
