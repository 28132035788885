import routeTypeConstant from './routeTypeConstant';

const { ADVOCATE, DPC, MULTIPLE_LOCATION } = routeTypeConstant;

const subdomainMap = {
  seaislandmedical: {
    subdomain: 'seaislandmedical',
    groupCode: 'SIM',
    themeClass: 'theme-sim',
    tenantCode: ['HWY41', 'HWY17S'],
    routeType: MULTIPLE_LOCATION,
  },
  'dev-seaislandmedical': {
    subdomain: 'dev-seaislandmedical',
    groupCode: 'SIM',
    themeClass: 'theme-sim',
    tenantCode: ['HWY41', 'HWY17S'],
    routeType: MULTIPLE_LOCATION,
  },
  'qa-seaislandmedical': {
    subdomain: 'qa-seaislandmedical',
    groupCode: 'SIM',
    themeClass: 'theme-sim',
    tenantCode: ['HWY41', 'HWY17S'],
    routeType: MULTIPLE_LOCATION,
  },
  'uat-seaislandmedical': {
    subdomain: 'uat-seaislandmedical',
    groupCode: 'SIM',
    themeClass: 'theme-sim',
    tenantCode: ['HWY41', 'HWY17S'],
    routeType: MULTIPLE_LOCATION,
  },

  holycitymed: {
    subdomain: 'holycitymed',
    groupCode: 'HCM',
    themeClass: 'theme-hcm',
    tenantCode: ['WA', 'NR'],
    routeType: MULTIPLE_LOCATION,
  },
  'dev-holycitymed': {
    subdomain: 'dev-holycitymed',
    groupCode: 'HCM',
    themeClass: 'theme-hcm',
    tenantCode: ['WA', 'NR'],
    routeType: MULTIPLE_LOCATION,
  },
  'qa-holycitymed': {
    subdomain: 'qa-holycitymed',
    groupCode: 'HCM',
    themeClass: 'theme-hcm',
    tenantCode: ['WA', 'NR'],
    routeType: MULTIPLE_LOCATION,
  },
  'uat-holycitymed': {
    subdomain: 'uat-holycitymed',
    groupCode: 'HCM',
    themeClass: 'theme-hcm',
    tenantCode: ['WA', 'NR'],
    routeType: MULTIPLE_LOCATION,
  },

  ktmdpc: {
    subdomain: 'ktmdpc',
    groupCode: null,
    themeClass: 'theme-ktm',
    tenantCode: ['KTMDPC'],
    routeType: ADVOCATE,
  },
  'dev-ktmdpc': {
    subdomain: 'dev-ktmdpc',
    groupCode: null,
    themeClass: 'theme-ktm',
    tenantCode: ['KTMDPC'],
    routeType: ADVOCATE,
  },
  'qa-ktmdpc': {
    subdomain: 'qa-ktmdpc',
    groupCode: null,
    themeClass: 'theme-ktm',
    tenantCode: ['KTMDPC'],
    routeType: ADVOCATE,
  },
  'uat-ktmdpc': {
    subdomain: 'uat-ktmdpc',
    groupCode: null,
    themeClass: 'theme-ktm',
    tenantCode: ['KTMDPC'],
    routeType: ADVOCATE,
  },

  novelty: {
    subdomain: 'novelty',
    groupCode: 'NVT',
    themeClass: 'theme-ktm',
    tenantCode: ['KTM', 'CHR'],
    routeType: MULTIPLE_LOCATION,
  },
  'dev-novelty': {
    subdomain: 'dev-novelty',
    groupCode: 'NVT',
    themeClass: 'theme-ktm',
    tenantCode: ['KTM', 'CHR'],
    routeType: MULTIPLE_LOCATION,
  },
  'qa-novelty': {
    subdomain: 'qa-novelty',
    groupCode: 'NVT',
    themeClass: 'theme-ktm',
    tenantCode: ['KTM', 'CHR'],
    routeType: MULTIPLE_LOCATION,
  },
  'uat-novelty': {
    subdomain: 'uat-novelty',
    groupCode: 'NVT',
    themeClass: 'theme-ktm',
    tenantCode: ['KTM', 'CHR'],
    routeType: MULTIPLE_LOCATION,
  },

  awesomedpc: {
    subdomain: 'awesomedpc',
    groupCode: null,
    themeClass: 'theme-dpc',
    tenantCode: ['DEMODPC'],
    routeType: DPC,
  },
  'dev-awesomedpc': {
    subdomain: 'dev-awesomedpc',
    groupCode: null,
    themeClass: 'theme-dpc',
    tenantCode: ['DEMODPC'],
    routeType: DPC,
  },
  'qa-awesomedpc': {
    subdomain: 'qa-awesomedpc',
    groupCode: null,
    themeClass: 'theme-dpc',
    tenantCode: ['DEMODPC'],
    routeType: DPC,
  },
  'uat-awesomedpc': {
    subdomain: 'uat-awesomedpc',
    groupCode: null,
    themeClass: 'theme-dpc',
    tenantCode: ['DEMODPC'],
    routeType: DPC,
  },

  medishield: {
    subdomain: 'medishield',
    groupCode: null,
    themeClass: 'theme-bmt',
    tenantCode: ['BMT'],
    routeType: ADVOCATE,
  },
  'dev-medishield': {
    subdomain: 'dev-medishield',
    groupCode: null,
    themeClass: 'theme-bmt',
    tenantCode: ['BMT'],
    routeType: ADVOCATE,
  },
  'qa-medishield': {
    subdomain: 'qa-medishield',
    groupCode: null,
    themeClass: 'theme-bmt',
    tenantCode: ['BMT'],
    routeType: ADVOCATE,
  },
  'uat-medishield': {
    subdomain: 'uat-medishield',
    groupCode: null,
    themeClass: 'theme-bmt',
    tenantCode: ['BMT'],
    routeType: ADVOCATE,
  },

  medishielddirect: {
    subdomain: 'medishielddirect',
    groupCode: null,
    themeClass: 'theme-bmt',
    tenantCode: ['MSD'],
    routeType: ADVOCATE,
  },
  'dev-medishielddirect': {
    subdomain: 'dev-medishielddirect',
    groupCode: null,
    themeClass: 'theme-bmt',
    tenantCode: ['MSD'],
    routeType: ADVOCATE,
  },
  'qa-medishielddirect': {
    subdomain: 'qa-medishielddirect',
    groupCode: null,
    themeClass: 'theme-bmt',
    tenantCode: ['MSD'],
    routeType: ADVOCATE,
  },
  'uat-medishielddirect': {
    subdomain: 'uat-medishielddirect',
    groupCode: null,
    themeClass: 'theme-bmt',
    tenantCode: ['MSD'],
    routeType: ADVOCATE,
  },

  graithcare: {
    subdomain: 'graithcare',
    groupCode: null,
    themeClass: 'theme-gc',
    tenantCode: ['GC'],
    routeType: ADVOCATE,
  },
  'dev-graithcare': {
    subdomain: 'dev-graithcare',
    groupCode: null,
    themeClass: 'theme-gc',
    tenantCode: ['GC'],
    routeType: ADVOCATE,
  },
  'qa-graithcare': {
    subdomain: 'qa-graithcare',
    groupCode: null,
    themeClass: 'theme-gc',
    tenantCode: ['GC'],
    routeType: ADVOCATE,
  },
  'uat-graithcare': {
    subdomain: 'uat-graithcare',
    groupCode: null,
    themeClass: 'theme-gc',
    tenantCode: ['GC'],
    routeType: ADVOCATE,
  },

  chccare: {
    subdomain: 'chccare',
    groupCode: null,
    themeClass: 'theme-chc',
    tenantCode: ['CHC'],
    routeType: ADVOCATE,
  },
  'dev-chccare': {
    subdomain: 'dev-chccare',
    groupCode: null,
    themeClass: 'theme-chc',
    tenantCode: ['CHC'],
    routeType: ADVOCATE,
  },
  'qa-chccare': {
    subdomain: 'qa-chccare',
    groupCode: null,
    themeClass: 'theme-chc',
    tenantCode: ['CHC'],
    routeType: ADVOCATE,
  },
  'uat-chccare': {
    subdomain: 'uat-chccare',
    groupCode: null,
    themeClass: 'theme-chc',
    tenantCode: ['CHC'],
    routeType: ADVOCATE,
  },

  bbhb: {
    subdomain: 'bbhb',
    groupCode: null,
    themeClass: 'theme-bbhb',
    tenantCode: ['BBHB'],
    routeType: ADVOCATE,
  },
  'dev-bbhb': {
    subdomain: 'dev-bbhb',
    groupCode: null,
    themeClass: 'theme-bbhb',
    tenantCode: ['BBHB'],
    routeType: ADVOCATE,
  },
  'qa-bbhb': {
    subdomain: 'qa-bbhb',
    groupCode: null,
    themeClass: 'theme-bbhb',
    tenantCode: ['BBHB'],
    routeType: ADVOCATE,
  },
  'uat-bbhb': {
    subdomain: 'uat-bbhb',
    groupCode: null,
    themeClass: 'theme-bbhb',
    tenantCode: ['BBHB'],
    routeType: ADVOCATE,
  },

  boldcitydpc: {
    subdomain: 'boldcitydpc',
    groupCode: null,
    themeClass: 'theme-bcity',
    tenantCode: ['BCITY'],
    routeType: DPC,
  },
  'dev-boldcitydpc': {
    subdomain: 'dev-boldcitydpc',
    groupCode: null,
    themeClass: 'theme-bcity',
    tenantCode: ['BCITY'],
    routeType: DPC,
  },
  'qa-boldcitydpc': {
    subdomain: 'qa-boldcitydpc',
    groupCode: null,
    themeClass: 'theme-bcity',
    tenantCode: ['BCITY'],
    routeType: DPC,
  },
  'uat-boldcitydpc': {
    subdomain: 'uat-boldcitydpc',
    groupCode: null,
    themeClass: 'theme-bcity',
    tenantCode: ['BCITY'],
    routeType: DPC,
  },

  harmony: {
    subdomain: 'harmony',
    groupCode: null,
    themeClass: 'theme-dpc',
    tenantCode: ['HAR'],
    routeType: ADVOCATE,
  },
  'dev-harmony': {
    subdomain: 'dev-harmony',
    groupCode: null,
    themeClass: 'theme-dpc',
    tenantCode: ['HAR'],
    routeType: ADVOCATE,
  },
  'qa-harmony': {
    subdomain: 'qa-harmony',
    groupCode: null,
    themeClass: 'theme-dpc',
    tenantCode: ['HAR'],
    routeType: ADVOCATE,
  },
  'uat-harmony': {
    subdomain: 'uat-harmony',
    groupCode: null,
    themeClass: 'theme-dpc',
    tenantCode: ['HAR'],
    routeType: ADVOCATE,
  },

  heartcare: {
    subdomain: 'heartcare',
    groupCode: null,
    themeClass: 'theme-dpc',
    tenantCode: ['HCC'],
    routeType: ADVOCATE,
  },
  'dev-heartcare': {
    subdomain: 'dev-heartcare',
    groupCode: null,
    themeClass: 'theme-dpc',
    tenantCode: ['HCC'],
    routeType: ADVOCATE,
  },
  'qa-heartcare': {
    subdomain: 'qa-heartcare',
    groupCode: null,
    themeClass: 'theme-dpc',
    tenantCode: ['HCC'],
    routeType: ADVOCATE,
  },
  'uat-heartcare': {
    subdomain: 'uat-heartcare',
    groupCode: null,
    themeClass: 'theme-dpc',
    tenantCode: ['HCC'],
    routeType: ADVOCATE,
  },
  cardiovisioncare: {
    subdomain: 'cardiovisioncare',
    groupCode: null,
    themeClass: 'theme-cvc',
    tenantCode: ['CVC'],
    routeType: ADVOCATE,
  },
  'dev-cardiovisioncare': {
    subdomain: 'dev-cardiovisioncare',
    groupCode: null,
    themeClass: 'theme-cvc',
    tenantCode: ['CVC'],
    routeType: ADVOCATE,
  },
  'qa-cardiovisioncare': {
    subdomain: 'qa-cardiovisioncare',
    groupCode: null,
    themeClass: 'theme-cvc',
    tenantCode: ['CVC'],
    routeType: ADVOCATE,
  },
  advochoice: {
    subdomain: 'advochoice',
    groupCode: null,
    themeClass: 'theme-dpc',
    tenantCode: ['LIG'],
    routeType: ADVOCATE,
  },
  'dev-advochoice': {
    subdomain: 'dev-advochoice',
    groupCode: null,
    themeClass: 'theme-dpc',
    tenantCode: ['LIG'],
    routeType: ADVOCATE,
  },
  'qa-advochoice': {
    subdomain: 'qa-advochoice',
    groupCode: null,
    themeClass: 'theme-dpc',
    tenantCode: ['LIG'],
    routeType: ADVOCATE,
  },
  vsuite: {
    subdomain: 'vsuite',
    groupCode: null,
    themeClass: 'theme-dpc',
    tenantCode: ['VSUITE'],
    routeType: ADVOCATE,
  },
  'dev-vsuite': {
    subdomain: 'dev-vsuite',
    groupCode: null,
    themeClass: 'theme-dpc',
    tenantCode: ['VSUITE'],
    routeType: ADVOCATE,
  },
  'qa-vsuite': {
    subdomain: 'qa-vsuite',
    groupCode: null,
    themeClass: 'theme-dpc',
    tenantCode: ['VSUITE'],
    routeType: ADVOCATE,
  },
  'uat-vsuite': {
    subdomain: 'uat-vsuite',
    groupCode: null,
    themeClass: 'theme-dpc',
    tenantCode: ['VSUITE'],
    routeType: ADVOCATE,
  },
  'feature-vsuite': {
    subdomain: 'feature-vsuite',
    groupCode: null,
    themeClass: 'theme-dpc',
    tenantCode: ['VSUITE'],
    routeType: ADVOCATE,
  },
  vcare: {
    subdomain: 'vcare',
    groupCode: null,
    themeClass: 'theme-dpc',
    tenantCode: ['VCARE'],
    routeType: ADVOCATE,
  },
  'dev-vcare': {
    subdomain: 'dev-vcare',
    groupCode: null,
    themeClass: 'theme-dpc',
    tenantCode: ['VCARE'],
    routeType: ADVOCATE,
  },
  'qa-vcare': {
    subdomain: 'qa-vcare',
    groupCode: null,
    themeClass: 'theme-dpc',
    tenantCode: ['VCARE'],
    routeType: ADVOCATE,
  },
  'uat-vcare': {
    subdomain: 'uat-vcare',
    groupCode: null,
    themeClass: 'theme-dpc',
    tenantCode: ['VCARE'],
    routeType: ADVOCATE,
  },
  'globex-demo': {
    subdomain: 'globex-demo',
    groupCode: null,
    themeClass: 'theme-dpc',
    tenantCode: ['GLOBEX'],
    routeType: ADVOCATE,
  },
  'dev-globex-demo': {
    subdomain: 'dev-globex-demo',
    groupCode: null,
    themeClass: 'theme-dpc',
    tenantCode: ['GLOBEX'],
    routeType: ADVOCATE,
  },
  'qa-globex-demo': {
    subdomain: 'qa-globex-demo',
    groupCode: null,
    themeClass: 'theme-dpc',
    tenantCode: ['GLOBEX'],
    routeType: ADVOCATE,
  },
  'uat-globex-demo': {
    subdomain: 'uat-globex-demo',
    groupCode: null,
    themeClass: 'theme-dpc',
    tenantCode: ['GLOBEX'],
    routeType: ADVOCATE,
  },
  bmt: {
    subdomain: 'bmt',
    groupCode: null,
    themeClass: 'theme-bmtc',
    tenantCode: ['BMTC'],
    routeType: ADVOCATE,
  },
  'dev-bmt': {
    subdomain: 'dev-bmt',
    groupCode: null,
    themeClass: 'theme-bmtc',
    tenantCode: ['BMTC'],
    routeType: ADVOCATE,
  },
  'qa-bmt': {
    subdomain: 'qa-bmt',
    groupCode: null,
    themeClass: 'theme-bmtc',
    tenantCode: ['BMTC'],
    routeType: ADVOCATE,
  },
  'uat-bmt': {
    subdomain: 'uat-bmt',
    groupCode: null,
    themeClass: 'theme-bmtc',
    tenantCode: ['BMTC'],
    routeType: ADVOCATE,
  },
};

export default subdomainMap;
