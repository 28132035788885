import React, { useContext } from 'react';
import { store } from '../../store';
import { Loader } from '../../common';
import { parseHtml } from '../../util';

const DedicatedDpcAgreement = (props) => {
  const state = useContext(store);
  const { globalState } = state;
  const { primaryLocation, isLoading } = globalState;

  const agreementContent = primaryLocation?.metaData?.agreement;

  if (isLoading) return <Loader />;

  return (
    <div className="container px-4 mx-auto pt-48 md:pt-60">
      <div className="dedicated-lp-form-wrapper shadow-none">
        <h6 className="mb-4">Terms of Agreement</h6>
        {agreementContent && (
          <div
            className="body-text"
            dangerouslySetInnerHTML={{
              __html: parseHtml(agreementContent),
            }}
          />
        )}
      </div>
    </div>
  );
};

export default DedicatedDpcAgreement;
