import React from 'react';
import PlanInfo from './PlanInfo';

const PaymentSummary = () => {
  return (
    <div className={`w-full bg-white rounded-sm shadow-md pt-0 payment-summary-neg-margin`}>
      <div className='px-5 py-3 bg-bgColor flex justify-between items-end'>
        <h4 className='font-heebo text-titleColor font-bold mb-1 text-xl'>Payment Summary</h4>
      </div>
      <PlanInfo />
    </div>
  );
}

export default PaymentSummary;