import React, { Component } from "react";
import { MDInput } from '../index';
import { _debounce, _uniqBy } from '../../util/lodashUtil';
import { showToast } from "../../service/toasterService";
import SearchAutocomplete from "../searchAutocomplete";
import * as sharedACFunctions from '../sharedMethods';
import * as enrollmentService from "../../service/enrollmentService";

class AgentAutocomplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      suggestions: [],
      displayNameValue: this.props.displayNameValue ? this.props.displayNameValue : '',
      selectedItem: {}
    };
    this.onSuggestionsFetchRequested = _debounce(
      this.onSuggestionsFetchRequested.bind(this),
      300
    );
  }
  onInputChange = (event, { newValue }) => {
    this.setState({
      displayNameValue: newValue,
    }, () => {
      this.props.handleInputChange(this.state.displayNameValue);
    });
  };
  onSuggestionsFetchRequested = async ({ value }) => {
    const list = await this.searchServices(value);
    this.setState(
      {
        list,
      },
      () => {
        this.setState({
          // suggestions: sharedACFunctions.getSuggestions(value, this.state.list, 10),
          suggestions: this.state.list
        });
      }
    );
  };
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };
  onSuggestionSelected = (event, { suggestion }) => {
    this.setState({
      displayNameValue: suggestion.displayName,
      selectedItem: suggestion
    }, () => {
      this.onSelectHandler();
    });
  };
  searchServices = (searchKeyword) => {
    return new Promise((resolve, reject) => {
      const queryParams = {
        tenantId: this.props.tenantId,
        limit: 50,
        offset: 0,
        sortBy: 'name',
        sortOrder: 'ASC',
        keyword: searchKeyword
      }
      enrollmentService.fetchAgents(queryParams)
        .then((response) => {
          const res = response.data.rows.map(x => {
            x.displayName = `${x.firstName} ${x.middleName ? `${x.middleName} ` : ''}${x.lastName ? x.lastName : ''}`;
            x.displayName = x.displayName?.trim();
            return x;
          })
          resolve(res);
        })
        .catch(error => {
          console.log('Error while fetching referring agents', error)
          reject(error);
        });
    });
  };
  onSelectHandler = () => {
    this.props.onSelectHandler(this.state.selectedItem);
  }
  shouldRenderSuggestions = value => sharedACFunctions.shouldRenderSuggestions(value, 1);

  renderInputComponent = (inputProps) => (
    <div className='relative autocomplete-without-icon'>
      <input
        id='service'
        type='text'
        {...inputProps}
      />
    </div>
  );

  render() {
    const autosuggestInputs = {
      suggestions: this.state.suggestions,
      shouldRenderSuggestions: this.shouldRenderSuggestions,
      onSuggestionsFetchRequested: this.onSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.onSuggestionsClearRequested,
      onSuggestionSelected: this.onSuggestionSelected,
      getSuggestionDisplayName: sharedACFunctions.getSuggestionDisplayName,
      renderSuggestion: sharedACFunctions.renderSuggestion,
      renderInputComponent: this.renderInputComponent,
      inputProps: {
        value: this.state.displayNameValue,
        onChange: this.onInputChange,
        placeholder: this.props.placeholder,
        required: this.props.inputRequired,
        disabled: this.props.disabled
      }
    };

    return (
      <SearchAutocomplete
        autosuggestInputs={autosuggestInputs}
        className={this.props.className}
      />
    );
  }
}

export default AgentAutocomplete;