import React from 'react';

const Checkbox = React.forwardRef((props, ref) => {
  const {
    checked,
    setChecked,
    label,
    children,
    wrapperClassName,
    disabled = false,
  } = props;
  return (
    <>
      <div
        className={`flex justify-start items-start ${
          wrapperClassName ? wrapperClassName : ''
        }`}
      >
        <label className="inline-block  w-6 h-6 overflow-hidden">
          <div
            className={`bg-white border rounded border-gray-400 w-full h-6 flex flex-shrink-0 justify-center items-center mr-2 focus-within:titleColor mb-3 ${
              disabled ? 'opacity-50' : ''
            }`}
          >
            <input
              data-cy="checkbox"
              ref={ref}
              type="checkbox"
              className="opacity-0 absolute"
              checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
              disabled={disabled}
            />
            <svg
              className="fill-current hidden w-4 h-4 text-titleColor pointer-events-none"
              viewBox="0 0 20 20"
            >
              <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
            </svg>
          </div>
        </label>
        <div className="select-none ml-3">{label || children}</div>
      </div>
    </>
  );
});

export default Checkbox;
