import React from "react";
import { Switch, BrowserRouter } from "react-router-dom";
import AppRoute from "./appRoute";
import MainLayout from "../layouts/MainLayout";
import {
  Home,
  WhoWeAre,
  WhoWeHelp,
  Contact,
  Agent,
  CustomerPages,
  Dpc,
  Marketplace,
  DpcSearch,
  DpcLanding,
  DpcSales,
  DpcRegister,
  DpcDetail,
  SmartAdvisor,
  SmartAdvisorRegister,
  TransparencySearch,
  DirectCareProviders,
  Community,
  PrivacyPolicy,
  TermsAndConditions,
  PageNotFound,
} from "../components";
import DedicatedDpcLayout from "../layouts/DedicatedDpcLayout";
import { ScrollToTop } from "../common";

const RouterRoute = () => {
  const baseUrl = process.env.PUBLIC_URL;
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <AppRoute
          exact
          path={baseUrl + "/"}
          layout={MainLayout}
          component={Home}
        />
        <AppRoute
          sensitive
          path={baseUrl + "/who-we-are"}
          layout={MainLayout}
          component={WhoWeAre}
        />
        <AppRoute
          sensitive
          path={baseUrl + "/who-we-help"}
          layout={MainLayout}
          component={WhoWeHelp}
        />
        <AppRoute
          sensitive
          path={baseUrl + "/contact-us"}
          layout={MainLayout}
          component={Contact}
        />
        <AppRoute
          sensitive
          path={baseUrl + "/agent-enrollment"}
          layout={MainLayout}
          component={Agent}
        />
        <AppRoute
          sensitive
          path={baseUrl + "/agent/:alias"}
          layout={MainLayout}
          component={Agent}
        />
        <AppRoute
          sensitive
          path={baseUrl + "/member/:alias"}
          layout={MainLayout}
          component={Agent}
        />
        <AppRoute
          sensitive
          path={baseUrl + "/vcare/:alias"}
          layout={MainLayout}
          component={Agent}
        />
        <AppRoute
          sensitive
          path={baseUrl + "/consumer"}
          layout={MainLayout}
          component={CustomerPages}
        />
        <AppRoute
          sensitive
          path={baseUrl + "/provider"}
          layout={MainLayout}
          component={CustomerPages}
        />
        <AppRoute
          sensitive
          path={baseUrl + "/employer"}
          layout={MainLayout}
          component={CustomerPages}
        />
        <AppRoute
          sensitive
          path={baseUrl + "/vcare"}
          layout={MainLayout}
          component={CustomerPages}
        />
        <AppRoute
          sensitive
          path={baseUrl + "/advisor"}
          layout={MainLayout}
          component={CustomerPages}
        />
        <AppRoute
          sensitive
          path={baseUrl + "/dpc/:alias"}
          layout={MainLayout}
          component={Dpc}
        />
        <AppRoute
          sensitive
          path={baseUrl + "/marketplace"}
          layout={MainLayout}
          component={Marketplace}
        />
        <AppRoute
          sensitive
          path={baseUrl + "/direct-primary-care/search"}
          layout={MainLayout}
          component={DpcSearch}
        />
        <AppRoute
          sensitive
          path={baseUrl + "/partners/direct-primary-care"}
          layout={MainLayout}
          component={DpcSales}
        />
        <AppRoute
          sensitive
          path={baseUrl + "/direct-primary-care/register"}
          layout={MainLayout}
          component={DpcRegister}
        />
        <AppRoute
          sensitive
          path={baseUrl + "/direct-primary-care"}
          layout={MainLayout}
          component={DpcLanding}
        />
        <AppRoute
          sensitive
          path={baseUrl + "/providers"}
          layout={MainLayout}
          component={DirectCareProviders}
        />
        <AppRoute
          sensitive
          path={baseUrl + "/smart-advisors/register"}
          layout={MainLayout}
          component={SmartAdvisorRegister}
        />
        <AppRoute
          sensitive
          path={baseUrl + "/smart-advisors"}
          layout={MainLayout}
          component={SmartAdvisor}
        />
        <AppRoute
          sensitive
          path={baseUrl + "/:provider/:id"}
          layout={MainLayout}
          component={DpcDetail}
        />
        <AppRoute
          sensitive
          path={baseUrl + "/transparency"}
          layout={MainLayout}
          component={TransparencySearch}
        />
        <AppRoute
          sensitive
          path={baseUrl + "/community"}
          layout={MainLayout}
          component={Community}
        />
        <AppRoute
          sensitive
          path={baseUrl + "/privacy-policy"}
          layout={DedicatedDpcLayout}
          component={PrivacyPolicy}
        />
        <AppRoute
          sensitive
          path={baseUrl + "/terms-of-use"}
          layout={DedicatedDpcLayout}
          component={TermsAndConditions}
        />
        <AppRoute layout={MainLayout} component={PageNotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default RouterRoute;
