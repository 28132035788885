import React, { Component, useState, useRef } from 'react';
import { Popover, ArrowContainer } from 'react-tiny-popover'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMapMarkerAlt,
  faStreetView,
  faFileAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
  ListItem,
  SearchResultSkeleton,
  EmptySearchResult
} from '../../common';
import FilterSideBar from './FilterSideBar';
import * as vitafyUtils from '../../util/vitafyUtil';
import hospitalPlaceholder from '../../assets/images/placeholder-hospital.png';
import requestInquiryBgImage from '../../assets/images/bg-enrollment.jpg';

class SearchListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stickySideBar: false
    }
  }

  render() {
    const {
      list,
      filterLocationList,
      filterRadius,
      handleChangeFilterRadius,
      handleChangeFilterLocation,
      handleClearFilters,
      showBestMatchDistance,
      resultsByShortestDistance,
      handleShowResultsByChange,
      radiusList,
      isLoading,
      isMoreLoading,
      searched,
      searchParams,
      ads
    } = this.props;

    return (
      <div className='w-full bg-bgColor min-h-1/2screen'>
        <div className='h-10'></div>
        <div className='container mx-auto px-4'>
          <div className='flex -mx-3'>
            <div className='w-2/6 px-3 hidden lg:block z-0' >
              <FilterSideBar
                locations={filterLocationList}
                filterRadius={filterRadius}
                radiusList={radiusList}
                handleChangeFilterRadius={handleChangeFilterRadius}
                handleChangeFilterLocation={handleChangeFilterLocation}
                handleClearFilters={handleClearFilters}
                isLoading={isLoading}
              />
            </div>
            <div className='w-full md:w-2/3 md:mx-auto lg:w-4/6 px-3'>

              {!(!isLoading && list && list.length < 1) &&
                <div className='bg-white shadow-sm rounded-sm px-4 py-4 mb-8'>
                  {!isLoading && list && list.length > 0 &&
                    <>
                      <p className='body-text'>You searched for&nbsp;
                      <span className='text-titleColor font-bold'>
                          {searchParams.searchKeyword}
                        </span>
                        {searchParams.city && ' in '}
                        {searchParams.city && <span className='text-titleColor font-bold'>{searchParams.city}</span>}{searchParams.state && <span className='text-titleColor font-bold'>, {searchParams.state}</span>}</p>
                    </>
                  }
                  {isLoading && <p className='body-text'>Searching...</p>}
                </div>
              }

              {!isLoading ?
                list && list.map((provider, index) =>
                  <div key={index} className={`mb-5`}>
                    <Card
                      detail={provider}
                      requestDemoClick={this.props.requestDemoClick}
                    />
                  </div>
                ) :
                <>
                  <SearchResultSkeleton />
                </>
              }
              {isMoreLoading && <SearchResultSkeleton skeletons={1} />}
              {searched && !isLoading && list && list.length < 1 &&
                <EmptySearchResult
                  marginTopRem='0rem'
                  text="No result Found. Try searching with different parameters."
                />}
              {!searched && !isLoading &&
                <EmptySearchResult
                  marginToRem='0rem'
                  text='Please search by CPT, DRG, Description and Location.'
                />}
            </div>
            <div className='w-2/6 px-3 hidden lg:block'>
              <div className='bg-white w-full px-4 py-6 center-cover-background-img' style={{ backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.8)), url(${requestInquiryBgImage})` }}>
                <h6 className='text-titleColor text-2xl font-normal'>Didn't find what you are looking for?</h6>
                <button className='small-button px-4 sm:px-6 rounded-sm mt-3' onClick={this.props.requestDemoClick}>
                  Request Inquiry
                </button>
              </div>
              <div>
                {ads && ads.map((ad, index) =>
                  <div className='mt-6' key={index}>
                    <AdvertisementCard
                      image={ad.image?.url}
                      link={ad.link}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const Card = (props) => {
  const { detail } = props;
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  return (
    <div className='w-full bg-white shadow-sm hover:shadow-md rounded-sm px-4 py-4'>
      <div className='flex xl:-mx-2'>
        <div className='xl:w-1/4 xl:px-2 hidden xl:block'>
          <div className='w-full h-32 bg-bgColor'>
            <img
              src={detail.provider?.imageUrl ? detail.provider.imageUrl : hospitalPlaceholder}
              alt="dpc"
              className='object-cover h-full w-full rounded-md'
              onError={e => { e.target.onerror = null; e.target.src = `${hospitalPlaceholder}` }}
            />
          </div>
        </div>
        <div className='w-full xl:w-3/4 xl:px-2'>
          <div className='flex justify-start items-center'>
            <div className='w-16 xl:hidden bg-bgColor'>
              <img
                src={detail.provider?.imageUrl ? detail.provider.imageUrl : hospitalPlaceholder}
                alt="dpc"
                className='object-cover h-16 w-full rounded-md'
                onError={e => { e.target.onerror = null; e.target.src = `${hospitalPlaceholder}` }}
              />
            </div>
            <div className='ml-3 md:ml-4 xl:ml-0'>
              <h6 className='body-text text-titleColor font-bold'>
                {detail.sourceFacilityName}
              </h6>
              <p className='text-sm text-secondary'>
                {detail.provider?.providerType}
              </p>
            </div>
          </div>

          <hr className='my-3 xl:my-2' />
          <div className='flex justify-between items-center'>
            <div>
              <ListItem
                icon={faMapMarkerAlt}
                text={detail.provider?.practiceAddress}
                address={true}
                addressDoubleLine={true}
              />
              <ListItem
                icon={faStreetView}
                text={detail.distance ? `${detail.distance} mi` : detail.distance === 0 ? '1 mi' : 'N/A'}
                containerClassName='mb-0'
              />
            </div>
            <div className='flex flex-col items-end'>
              <p className='text-titleColor text-lg lg:text-xl font-bold'>
                {detail.displayCost ? vitafyUtils.formatCurrency(detail.displayCost, true, 0, 0) : '-'}
              </p>
              <Popover
                reposition={false}
                isOpen={isPopoverOpen}
                positions={['left', 'top', 'bottom', 'right']}
                padding={5}
                onClickOutside={() => setIsPopoverOpen(false)}
                content={({ position, childRect, popoverRect }) => (
                  <ArrowContainer
                    position={position}
                    childRect={childRect}
                    popoverRect={popoverRect}
                    arrowColor={'white'}
                    arrowSize={10}
                    className='popover-arrow-container'
                    arrowClassName='popover-arrow'
                  >
                    <PricePopoverContent
                      minCost={detail.minCost}
                      maxCost={detail.maxCost}
                      avgCost={detail.avgCost}
                      billedCost={detail.billedCost}
                      cashCost={detail.cashCost}
                    />
                  </ArrowContainer>
                )}
              >
                <button className='text-secondary text-sm focus:outline-none' onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                  View Details
                </button>
              </Popover>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PricePopoverContent = ({ minCost, maxCost, avgCost, billedCost, cashCost }) => {
  return (
    <div className='rounded-md bg-white shadow-lg py-4 px-3' style={{ width: '14.5rem' }}>
      <div className='flex items-center mb-1'>
        <div className='w-3/5'>
          <p className='body-text text-bodyColor text-sm'>Cash Amount</p>
        </div>
        <div className='w-2/5 flex justify-end'>
          <p className='body-text text-titleColor text-sm font-bold'>{cashCost ? vitafyUtils.formatCurrency(cashCost, true, 0, 0) : '-'}</p>
        </div>
      </div>
      <div className='flex items-center mb-1'>
        <div className='w-3/5'>
          <p className='body-text text-bodyColor text-sm'>Min - Max</p>
        </div>
        <div className='w-2/5 flex justify-end'>
          <p className='body-text text-titleColor text-sm font-bold'>
            {minCost ? vitafyUtils.formatCurrency(minCost, true, 0, 0) : '-'}
            <span className='text-bodyColor font-normal mx-2'>-</span>
            {maxCost ? vitafyUtils.formatCurrency(maxCost, true, 0, 0) : '-'}
          </p>
        </div>
      </div>
      <div className='flex items-center mb-1'>
        <div className='w-3/5'>
          <p className='body-text text-bodyColor text-sm'>Average Amount</p>
        </div>
        <div className='w-2/5 flex justify-end'>
          <p className='body-text text-titleColor text-sm font-bold'>{avgCost ? vitafyUtils.formatCurrency(avgCost, true, 0, 0) : '-'}</p>
        </div>
      </div>
      <div className='flex items-center'>
        <div className='w-3/5'>
          <p className='body-text text-bodyColor text-sm'>Billed Amount</p>
        </div>
        <div className='w-2/5 flex justify-end'>
          <p className='body-text text-titleColor text-sm font-bold'>{billedCost ? vitafyUtils.formatCurrency(billedCost, true, 0, 0) : '-'}</p>
        </div>
      </div>
    </div>
  );
}

const AdvertisementCard = (props) => {
  const { image, link } = props;
  return (
    <a href={link} target='_blank'>
      <div className='w-full h-64'>
        <img
          src={image}
          className='object-contain object-top w-full h-full cursor-pointer'
          alt='ad-image'
        />
      </div>
    </a>
  );
}

export default SearchListing;