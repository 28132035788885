import React from 'react';
import {
  SimpleBanner
} from '../../common';
import SmartAdvisorInformation from './SmartAdvisorInformation';
import PaymentSummary from './PaymentSummary';

const Checkout = (props) => {
  return (
    <>
      <SimpleBanner
        title='Smart Advisor Registration'
        uppercase={true}
      />

      <div className='container mx-auto px-4'>
        <div className='flex flex-wrap'>
          <div className="w-full md:w-1/2">
            <SmartAdvisorInformation
              data={props.data}
              address={props.address}
            // formRef={props.providerInfoFormRef}
            // submit={props.handleSubmitCheckout}
            // chosenPricingPlan={props.chosenPricingPlan}
            // paymentInterval={props.paymentInterval}
            // isSubmitting={props.isSubmitting}
            />
          </div>
          <div className="w-full md:w-1/2 md:pl-6 lg:pl-12 xl:pr-24 mt-8 md:mt-0">
            <PaymentSummary
            // paymentEnvironment={props.paymentEnvironment}
            // handleTriggerSubmit={props.handleTriggerSubmit}
            // iAgreeTerms={props.data.iAgreeTerms}
            // handleChangeIAgreeTerms={props.handleChangeIAgreeTerms}
            // tmpPaymentData={props.tmpPaymentData}
            // handleChangeTmpPaymentData={props.handleChangeTmpPaymentData}
            // errorMessage={props.errorMessage}
            // handleRemoveErrorMessage={props.handleRemoveErrorMessage}
            // paymentInterval={props.paymentInterval}
            // handleChangePaymentInterval={props.handleChangePaymentInterval}
            // isSubmitting={props.isSubmitting}
            // chosenPricingPlan={props.chosenPricingPlan}
            // paymentMethod={props.paymentMethod}
            // handleChangePaymentMethod={props.handleChangePaymentMethod}
            // handleCheckPromoCode={props.handleCheckPromoCode}
            // handleChangePromoCode={props.handleChangePromoCode}
            // promoCode={props.promoCode}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Checkout;