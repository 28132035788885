import React, { useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import PaymentSummary from './PaymentSummary';
import { AgreeTerms } from '../../common';
import { Checkbox } from '../../../../common';
import { store } from '../../../../store';
import { selectPayment, setPayment } from '../../../../stores/membership';
import commonConstants from '../../../../constants/commonConstant';
import { checkEnrollmentTenantSetting } from '../../../../util/tenantSettingUtil';
import { PAYMENT_METHOD_OTHER } from '../../../../constants/payment';
import Payment from './Payment';

const { TENANT_SETTINGS_CODE } = commonConstants;

const Checkout = ({ misc }) => {
  const { whiteBg } = misc;

  const dispatch = useDispatch();

  // Redux Store
  const payment = useSelector(selectPayment);
  const { iAgreeTerms, iAgreePSATerms } = payment;

  // Context Store
  const state = useContext(store);
  const { globalState } = state;
  const { tenantSettings, primaryLocation } = globalState;
  const psaUrl = primaryLocation?.metaData?.patientServiceAgreement;

  const skipPayment = checkEnrollmentTenantSetting(
    TENANT_SETTINGS_CODE.SKIP_PAYMENT_PUBLIC,
    { tenantSettings }
  );

  const onChangeIAgreeTerms = (value) => {
    dispatch(
      setPayment({
        iAgreeTerms: value,
      })
    );
  };

  const onChangeIAgreePSATerms = (value) => {
    dispatch(
      setPayment({
        iAgreePSATerms: value,
      })
    );
  };

  /**
   * Payment method: 'Other' in case the payment is to be skipped (driven by tenant settings)
   */
  useEffect(() => {
    if (skipPayment) {
      dispatch(setPayment({ paymentMethod: PAYMENT_METHOD_OTHER }));
    }
  }, [skipPayment, dispatch]);

  return (
    <>
      <PaymentSummary />
      <div className="form-section-divider-md"></div>

      {!skipPayment && <Payment whiteBg={whiteBg} />}

      <AgreeTerms
        iAgreeTerms={iAgreeTerms}
        handleChangeIAgreeTerms={onChangeIAgreeTerms}
      />

      {psaUrl && (
        <Checkbox
          checked={iAgreePSATerms}
          setChecked={onChangeIAgreePSATerms}
          className="inline"
        >
          <p className="font-heebo text-sm xl:text-base text-titleColor mb-0 select-none inline-block">
            I agree with the{' '}
            <a
              href={psaUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="no-underline font-bold text-dedicatedDpcPrimary focus:outline-none"
            >
              patient service agreement
            </a>
          </p>
        </Checkbox>
      )}
    </>
  );
};

export default Checkout;
