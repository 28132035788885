import React, { Component } from 'react';
import { Header, Footer, Loader, Enrollment } from '../../common';
import * as enrollmentService from '../../service/enrollmentService';
import * as memberCheckinService from '../../service/memberCheckinService';
import * as walkinService from '../../service/walkinService';
import * as dpcPricingService from '../../service/dpc-pricing-service';
import { showToast } from '../../service/toasterService';
import * as vitafyUtils from '../../util/vitafyUtil';
import * as service from '../../service';
import PhysicianList from './PhysicianList';
import Options from './Options';
import MemberCheckin from './MemberCheckin';
import PriceList from './PriceList';
import RequestAppointment from './RequestAppointment';
import DpcInfo from './DpcInfo';

class Dpc extends Component {
  getInitialState = () => {
    return {
      alias: this.props.match.params.alias,
      loading: false,
      error: false,
      dpcInfo: {},
      clientData: {
        paymentDetail: { paymentMethod: 'card' }
      },
      registrationStep: 1,
      address: {},
      directPrimaryCare: {
        isActive: false,
        planType: 'DPC',
        planDescription: undefined,
        referenceId: undefined,
        cost: undefined,
      },
      careNavigation: {
        isActive: false,
        planType: 'CN',
        planDescription: undefined,
        referenceId: undefined,
        cost: undefined,
      },
      selectedDpc: null,
      client: {},
      enrollmentContent: '',
      individualPricing: {},
      companyPricing: {},
      planInformationMonthlyCost: '0',
      submittingForm: false,
      activeOption: 1,
      // Member Check-in
      memberCheckinSearch: false,
      searchFields: {
        firstName: '',
        lastName: '',
        dob: '',
        last4SSN: '',
      },
      memberSearching: false,
      memberSearchResults: [],
      checkinSubmitting: false,
      checkinSuccessful: false,
      // Request Appointment
      editData: {
        fullName: '',
        scheduledDate: '',
        scheduledTime: '',
        email: '',
        phone: '',
        description: '',
      },
      // Walk-in or Video-visit
      enrollmentType: '',
      monthlyCost: null,
      priceListActiveOption: {},
      checkoutReady: false,
      priceListData: {},
      addressPriceList: {},
      iAgreeTerms: false,
      walkinLoading: false,
      walkinSuccessful: false,
      pricingListOptions: []
    };
  };

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.scrollToFormRef = React.createRef();

    // Since, agent and dpc enrollment are using the same <Enrollment /> component
    // and unlike dpc enrollment, agent needs more refs like for direcPrimaryModalRef and additionalCoverageModalRef
    // this ref will serve as a placeholder for those
    this.placeholderRef = React.createRef();
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.getEnrollmentContent();
    this.fetchDPCByAlias(this.state.alias);
    // this.fetchAdditionalCoverageList();
    this.fetchCareNavigator();
    // set enrollmentType to Walk-in on page load
    this.setState({ enrollmentType: 'Walk-in', monthlyCost: 100 });
  }

  getEnrollmentContent = () => {
    this.setState({ loading: true });
    service
      .getEnrollmentContent()
      .then((result) => {
        if (result.status === 'success') {
          this.setState({
            enrollmentContent: result.data.find(
              (item) => item.slug === 'enrollment-page'
            ),
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.log('Error in get homeContent', error);
        this.setState({ loading: false, error: true });
      });
  };

  fetchDPCByAlias = (alias) => {
    enrollmentService
      .fetchDPCByAlias(alias)
      .then((response) => {
        // TODO: if the dpc is not found when fetchDPCByAlias, send status: 'error' in the response from the server side
        if (response.status === 'success' && response.data !== null) {
          this.setState((prevState) => ({
            dpcInfo: response.data,
            directPrimaryCare: {
              isActive: true,
              cost: 0, // not clear about this
              planType: 'DPC',
              planDescription: response?.data?.businessName,
              referenceId: response?.data?.providerId,
              npi: response?.data?.npi,
            },
            selectedDpc: {
              address: response?.data?.address,
              displayName: response?.data?.businessName,
              email: response?.data?.email,
              imageUrl: response?.data?.imageUrl,
              npi: response?.data?.npi,
              phone: response?.data?.phone,
              providerId: response?.data?.providerId,
              providerName: response?.data?.businessName,
            },
            loading: false
          }), () => { this.fetchPricingByProviderId(this.state.dpcInfo.providerId) });
        } else {
          this.props.history.push('/404'); // there is not route linked with /404. It will redirect to PageNotFound by default
        }
      })
      .catch((error) => {
        console.log(error);
        this.props.history.push('/404'); // there is not route linked with /404. It will redirect to PageNotFound by default
      });
  };

  fetchPricingByProviderId = (providerId) => {
    dpcPricingService
      .fetchPricingByProviderId(providerId)
      .then((response) => {
        if (response.status === 'success') {
          this.setState((prevState) => ({
            pricingListOptions: response.data.filter(item => item.category === 'OTHER').sort((a, b) => a.order - b.order),
            individualPricing: response.data.filter(item => item.category === 'FAMILY'),
            companyPricing: response.data.find(item => item.category === 'GROUP'),
          }), () =>
            this.setState(prevState => ({ priceListActiveOption: prevState.pricingListOptions && prevState.pricingListOptions.length > 0 ? prevState.pricingListOptions[0] : 'Others' })));
        }
      })
      .catch((error) => {
        console.log('Error fetching price by providerId', error);
      });
  }

  fetchCareNavigator = () => {
    enrollmentService
      .fetchPlanByType('CN')
      .then((response) => {
        this.setState((prevState) => ({
          careNavigation: {
            ...prevState.careNavigation,
            isActive: true,
            planDescription: response.data.value,
          },
        }));
      })
      .catch((error) => {
        console.log('Error fetching plan by type: CN', error);
      });
  };

  enrollmentCheckout = () => {
    return new Promise((resolve, reject) => {
      enrollmentService
        .dpcEnrollmentCheckout(this.state.clientData)
        .finally(() => {
          this.setState({ submittingForm: false });
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log('error', error);
          showToast('error', error.response.data.message);
          reject(error);
        });
    });
  };
  updateFields = (field, value) => {
    this.setState((prevState) => ({
      clientData: { ...prevState.clientData, [field]: value },
    }));
  };

  handleNextPrevClick = (step, e) => {
    if (step === 3) {
      this.setState(
        (prevState) => ({
          clientData: e
            ? { ...prevState.clientData, ...e, effStartDate: new Date() }
            : { ...prevState.clientData, effStartDate: new Date() },
          submittingForm: true,
        }),
        () => {
          this.enrollmentCheckout().then((response) => {
            if (response && response.data === 'success') {
              this.setState({
                registrationStep: step,
              });
            }
          });
        }
      );
    } else {
      this.setState((prevState) => ({
        clientData: e
          ? { ...prevState.clientData, ...e }
          : prevState.clientData,
        registrationStep: step,
      }), () => { this.setPlanInformationMonthlyCost() });
    }
  };

  handleChangeActiveOption = (option) => {
    this.setState({ activeOption: option });
  };

  handleCheckinSearchSubmit = (formValues) => {
    this.setState({ memberSearching: true });
    memberCheckinService
      .searchMembers(formValues)
      .finally(() => {
        this.setState({ submittingForm: false, memberSearching: false });
      })
      .then((response) => {
        if (response.status === 'success') {
          this.setState(
            {
              memberSearchedResults: response.data.rows,
              checkinSuccessful: false,
            },
            () => {
              response.data.count > 1 &&
                showToast(
                  'warning',
                  'Multiple members found. Try narrowing the search by filling all fi'
                );
              response.data.count < 1 &&
                showToast('warning', 'No members found matching given fields');
              response.data.count === 1 &&
                this.setState({
                  memberCheckinSearch: true,
                  searchFields: {
                    firstName: '',
                    lastName: '',
                    dob: '',
                    last4SSN: '',
                  },
                });
            }
          );
        }
      })
      .catch((error) => {
        console.log('error', error);
        showToast('error', error.response.data.message);
      });
  };

  handleCheckinReturnToSearch = () => {
    this.setState({ memberCheckinSearch: false });
  };

  handleSubmitCheckin = (data) => {
    let dataToSend = { ...data };
    dataToSend.providerId = this.state.dpcInfo.providerId;

    this.setState({ checkinSubmitting: true });
    memberCheckinService
      .memberCheckin(dataToSend)
      .finally(() => {
        this.setState({ checkinSubmitting: false });
      })
      .then((response) => {
        if (response.status === 'success') {
          this.setState({ checkinSuccessful: true });
          showToast(
            'success',
            `${
            data.fromRequestAppointment
              ? 'Appointment requested successfully'
              : 'Check-in successful'
            }`
          );
          data.fromRequestAppointment &&
            this.setState({
              editData: {
                fullName: '',
                scheduledDate: '',
                scheduledTime: '',
                email: '',
                phone: '',
                description: '',
              },
            });
        }
      })
      .catch((error) => {
        console.log('error', error);
        showToast(
          'error',
          `${
          data.fromRequestAppointment
            ? 'Appointment could not be requested'
            : 'Could not create a new check-in'
          }`
        );
      });
  };

  handlePriceListActiveOptionChange = (activeOption) => {
    if (activeOption !== 'Others') {
      this.setState({ priceListActiveOption: activeOption },
        () => {
          this.setState(prevState => ({
            enrollmentType: prevState.priceListActiveOption.label,
            monthlyCost: prevState.priceListActiveOption.price // TODO: maynot be monthlyCost (can be yearly, monthly or one-time)
          }))
        })
    } else {
      this.setState({ enrollmentType: 'Others', monthlyCost: null, priceListActiveOption: activeOption })
    }
  }

  handlePriceListFormSubmit = (data) => {
    this.setState(prevState => ({
      priceListData: {
        ...prevState.priceListData,
        token: data.id,
        name: `${prevState.priceListData.firstName} ${prevState.priceListData.lastName}`,
        enrollmentType: prevState.enrollmentType,
        monthlyCost: prevState.priceListActiveOption !== 4 ? prevState.monthlyCost : prevState.monthlyCost.replace(/,/g, '').replace('$', ''),
        address: prevState.addressPriceList,
        providerId: prevState.dpcInfo.providerId
      }
    }), () => { this.handleWalkin() })
  }

  handleWalkin = () => {
    this.setState({ walkinLoading: true });
    walkinService
      .walkin(this.state.priceListData)
      .finally(() => {
        this.setState({ walkinLoading: false });
      })
      .then((response) => {
        if (response.status === 'success') {
          this.setState({ walkinSuccessful: true });
          showToast('success', 'Walk-in successful');
          this.setState({
            priceListData: {},
            addressPriceList: {},
            checkoutReady: false,
            iAgreeTerms: false
          });
        }
      })
      .catch((error) => {
        console.log('error', error);
        showToast('error', 'Error during walk-in');
      });
  }

  setPlanInformationMonthlyCost = () => {
    if (this.state.clientData.clientType === 'Individual') {
      const age = vitafyUtils.getAge(this.state.clientData.dob);
      const individualCategory = this.state.individualPricing && this.state.individualPricing.find((item) => {
        if (age >= item.lowerValue && age <= item.upperValue) return item;
      })
      this.setState({ planInformationMonthlyCost: individualCategory !== undefined ? individualCategory.price : '0' });
    } else {
      this.setState({ planInformationMonthlyCost: '200' });
      this.setState(prevState => ({
        planInformationMonthlyCost: prevState.companyPricing.price
      }))
    }
  }

  // Since, agent and dpc enrollment are using the same <Enrollment /> component
  // and much of the function that agent enrollment need to execute like searching plan, sedera (additional coverage)
  // this function will serve as a placeholder for those
  placeholderFunction = () => {
    console.log('DPC Enrollment');
  };

  render() {
    // Physician data for demo purpose
    const physicianData = [
      {
        image:
          'https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60',
        name: 'William Wesson Nields',
        post: 'General Practice',
      },
      {
        image:
          'https://images.unsplash.com/photo-1586297098710-0382a496c814?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60',
        name: 'Timothy D Wingo',
        post: 'Family Medicine',
      },
      {
        image:
          'https://images.unsplash.com/photo-1508214751196-bcfd4ca60f91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60',
        name: 'Anoma S Gamage',
        post: 'Family Medicine',
      },
    ];

    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <Header />

        <DpcInfo info={this.state.dpcInfo} {...this.props} />

        <Options
          activeOption={this.state.activeOption}
          changeActiveOption={this.handleChangeActiveOption}
        />
        <div className='inner-divider'></div>

        {this.state.activeOption === 1 && (
          <MemberCheckin
            isSearched={this.state.memberCheckinSearch}
            searchSubmit={this.handleCheckinSearchSubmit}
            returnToSearch={this.handleCheckinReturnToSearch}
            searchFields={this.state.searchFields}
            memberSearching={this.state.memberSearching}
            memberSearchedResults={this.state.memberSearchedResults}
            submitCheckin={this.handleSubmitCheckin}
            checkinSubmitting={this.state.checkinSubmitting}
            checkinSuccessful={this.state.checkinSuccessful}
          />
        )}

        {this.state.activeOption === 2 && (
          <RequestAppointment
            editData={this.state.editData}
            formSubmit={this.handleSubmitCheckin}
            checkinSubmitting={this.state.checkinSubmitting}
          />
        )}

        {this.state.activeOption === 3 &&
          <PriceList
            monthlyCost={this.state.monthlyCost}
            activeOption={this.state.priceListActiveOption}
            changeActiveOption={this.handlePriceListActiveOptionChange}
            checkoutReady={this.state.checkoutReady}
            changeCheckoutReady={() => this.setState({ checkoutReady: true })}
            handleBackBtnPress={() => this.setState({ checkoutReady: false })}
            data={this.state.priceListData}
            address={this.state.addressPriceList}
            iAgreeTerms={this.state.iAgreeTerms}
            changeIAgreeTerms={(choice) => this.setState({ iAgreeTerms: choice })}
            priceListFormSubmit={this.handlePriceListFormSubmit}
            walkinLoading={this.state.walkinLoading}
            changeWalkinLoading={(bool) => { this.setState({ walkinLoading: bool }) }}
            changeMonthlyCost={(cost) => this.setState({ monthlyCost: cost })}
            pricingListOptions={this.state.pricingListOptions}
          />
        }

        {this.state.activeOption === 4 && (
          <Enrollment
            registrationStep={this.state.registrationStep}
            address={this.state.address}
            directPrimaryCare={this.state.directPrimaryCare}
            careNavigation={this.state.careNavigation}
            selectedDpc={this.state.selectedDpc}
            planInformationMonthlyCost={this.state.planInformationMonthlyCost}
            updateFields={this.updateFields}
            enrollmentContent={
              this.state.enrollmentContent &&
              this.state.enrollmentContent.metadata
            }
            client={this.state.clientData}
            submit={this.handleNextPrevClick}
            submittingForm={this.state.submittingForm}
            scrollToFormRef={this.scrollToFormRef}
            enrollmentFromDPC={true}
            //  required for Agent Enrollment but not for DPC Enrollment
            additionalCoverage={{
              isActive: false,
              planType: 'ADDITIONALCOVERAGE',
              planDescription: undefined,
              referenceId: undefined,
              cost: undefined,
            }}
            directPrimaryCareModal={this.placeholderRef}
            additionalCoverageModal={this.placeholderRef}
            dpcSearchList={[]}
            openModalName=''
            additionalCoverageList={[]}
            openAddModal={this.placeholderFunction}
            removePlan={this.placeholderFunction}
            searchDpc={this.placeholderFunction}
            savePlan={this.placeholderFunction}
          />
        )}

        <PhysicianList physicians={physicianData} />

        <Footer />
      </>
    );
  }
}

export default Dpc;
