import React from 'react';
import { parseHtml } from '../../util';

const Banner = (props) => {
  const {
    banner,
    titleClassName = '',
    taglineClassName = '',
    encloseImage = true,  // image is enclosed inside rectangular box if set to true
    imageContainerClassName = ''
  } = props;
  return (
    <>
      <div className='w-full pt-24 lg:pt-30 pb-10 lg:pb-10 bg-extended'>
        <div className='container mx-auto px-4'>
          {banner.tagline_visibility ?
            <>
              <h1 className='font-heebo uppercase font-bold text-white text-3xl lg:text-5xl'>
                {banner.title}
              </h1>
              <h6 className='firstContentHeading normal-case font-normal text-base lg:text-xl mt-0 pt-0'>
                {banner.tagline}
              </h6>
            </>
            :
            <h1 className='firstContentHeading normal-case'>{banner.title}</h1>
          }
        </div>
      </div>
      <div className='container mx-auto py-6 lg:pt-8 lg:pb-16 flex flex-wrap flex-row lg:flex-row-reverse px-4'>
        {encloseImage ?
          <div className='w-3/4 sm:w-2/5 lg:w-2/5  mx-auto -mt-10 sm:-mt-16 lg:-mt-32 xl:-mt-48 mb-10 lg:mb-0 lg:pl-16 xl:pl-40'>
            <div className='w-full bg-white shadow-md rounded-lg'>
              <div className='w-full pb-full rounded-t-lg relative'>
                <img
                  src={banner.image && banner.image.url}
                  className='absolute w-full h-full object-cover object-center rounded-t-lg'
                  alt='profile-img'
                />
              </div>
            </div>
          </div>
          :
          <div className={`w-3/4 sm:w-2/5 lg:w-2/5  mx-auto mb-10 lg:mb-0 lg:-mt-54 xl:-mt-60 ${imageContainerClassName ? imageContainerClassName : ''}`}>
            <img
              src={banner.image && banner.image.url}
              className='w-full'
              alt='profile-img'
            />
          </div>
        }
        <div className='w-full lg:w-3/5 lg:pr-0'>
          <div className='font-heebo text-bodyColor text-lg'>
            <div
              dangerouslySetInnerHTML={{
                __html: parseHtml(banner.content),
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Banner;