import React from 'react';
import withSizes from 'react-sizes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faArrowLeft,
  faClipboard,
  faFileContract,
  faFileInvoiceDollar,
} from '@fortawesome/free-solid-svg-icons';
import ClientInformation from './clientInformation';
import PlanInformation from './planInformation';
import PaymentInformation from './paymentInformation';
import EnrollmentConfirmation from './enrollmentConfirmation';
import { ButtonLoader } from '../../common';
import routeAliasConstant from '../../constants/routeAliasConstant';

const Enrollment = (props) => {
  const {
    client,
    address,
    registrationStep,
    directPrimaryCare,
    careNavigation,
    additionalCoverage,
    agentInfo,
    selectedDpc,
    submit,
    openAddModal,
    removePlan,
    directPrimaryCareModal,
    additionalCoverageModal,
    dpcSearchList,
    openModalName,
    additionalCoverageList,
    dpcLoading,
    paymentInformationActiveTab,
    comingFrom,
    // enrollment Content from cosmic
    enrollmentContent,
    vcareCosmicContent
  } = props;
  const clientInfoRef = React.createRef();
  const planInfoRef = React.createRef();
  const cardPaymentRef = React.createRef();
  const achPaymentRef = React.createRef();
  const formSubmitHandler = () => {
    if (registrationStep === 1) {
      clientInfoRef.current.dispatchEvent(
        new Event('submit', { cancelable: true })
      );
    } else if (registrationStep === 2) {
      planInfoRef.current.dispatchEvent(
        new Event('submit', { cancelable: true })
      );
    } else if (registrationStep === 3 &&
      paymentInformationActiveTab === 1) {
      cardPaymentRef.current.dispatchEvent(
        new Event('submit', { cancelable: true })
      );
    } else if (registrationStep === 3 &&
      paymentInformationActiveTab === 2) {
      achPaymentRef.current.dispatchEvent(
        new Event('submit', { cancelable: true })
      );
    }
  };
  React.useEffect(() => { }, []);
  React.useLayoutEffect(() => { }, []);
  const disableContinue = () => {
    let disable;
    if (registrationStep === 2) {
      disable = props.submittingForm ||
        (additionalCoverage?.isActive && !client.iAgreeSedera)
        ? true
        : false;
    } else if (registrationStep === 3 && paymentInformationActiveTab === 1) {
      disable = props.submittingForm || !client.payment.iAgreeCardPayment ? true : false;
    } else if ((registrationStep === 3 && paymentInformationActiveTab === 2)) {
      disable = props.submittingForm || !client.payment.iAgreeAchPayment ? true : false;
    }
    return disable;
  };
  return (
    <>
      <div className='container mx-auto px-4' ref={props.scrollToFormRef}>
        {/* hide heading from coming from DPC */}
        {!props.enrollmentFromDPC ? (
          <h3 className='heading'>
            {comingFrom === routeAliasConstant.VCARE ?
              <>{vcareCosmicContent.metadata && vcareCosmicContent.metadata.enrollment_heading}</>
              : <>Smart Plan Enrollment</>}
          </h3>
        ) : null}
      </div>
      <div className='w-full bg-bgColor overflow-hidden'>
        {/* 3 STEP ILLUSTRATION STARTS HERE */}
        <div className='container px-4 lg:flex mx-auto'>
          <div className='w-full lg:w-1/3  py-6 lg:flex lg:justify-start lg:items-center relative'>
            <div className='overlay-overcome flex justify-center text-center lg:text-left lg:block'>
              {/* Step 1 */}
              <div className='lg:flex lg:flex-initial mr-4 lg:mr-0 lg:mb-16 step1'>
                <div
                  className={`mx-auto lg:ml-0 w-12 h-12 lg:w-16 lg:h-16 rounded-full mr-3 mb-3 lg:mb-4 shadow-lg flex justify-center items-center relative ${props.isMobile ? 'circle-horizontal' : 'circle-vertical'
                    } ${registrationStep === 1 ? 'bg-extended' : 'bg-white'}`}
                >
                  <FontAwesomeIcon
                    icon={faClipboard}
                    className={` text-2xl ${registrationStep === 1 ? 'text-white' : 'text-bodyColor'
                      }`}
                  />
                </div>
                <h6
                  className={`uppercase text-sm lg:text-xl ${registrationStep === 1
                    ? 'font-bold text-extended'
                    : 'font-normal'
                    }`}
                >
                  Client <br />
                  Information
                </h6>
              </div>
              {/* Step 2 */}
              <div className='lg:flex lg:flex-initial mr-4 lg:mr-0 lg:mb-16 step2'>
                <div
                  className={`mx-auto lg:ml-0 w-12 h-12 lg:w-16 lg:h-16 rounded-full mr-3 mb-3 lg:mb-4 shadow-lg flex justify-center items-center relative ${props.isMobile ? 'circle-horizontal' : 'circle-vertical'
                    } ${registrationStep === 2 ? 'bg-extended' : 'bg-white'}`}
                >
                  <FontAwesomeIcon
                    icon={faFileContract}
                    className={` text-2xl ${registrationStep === 2 ? 'text-white' : 'text-bodyColor'
                      }`}
                  />
                </div>
                <h6
                  className={`uppercase text-sm lg:text-xl ${registrationStep === 2
                    ? 'font-bold text-extended'
                    : 'font-normal'
                    }`}
                >
                  {comingFrom === routeAliasConstant.VCARE ?
                    <>
                      vCare <br />
                    Information
                    </>
                    :
                    <>
                      Plan <br />
                    Information
                    </>
                  }

                </h6>
              </div>
              {/* Step 3 */}
              <div className='lg:flex lg:flex-initial step3'>
                <div
                  className={`mx-auto lg:ml-0 w-12 h-12 lg:w-16 lg:h-16 rounded-full mr-3 mb-3 shadow-md flex justify-center items-center ${registrationStep === 3 ? 'bg-extended' : 'bg-white'
                    }`}
                >
                  <FontAwesomeIcon
                    icon={faFileInvoiceDollar}
                    className={` text-2xl ${registrationStep === 3 ? 'text-white' : 'text-bodyColor'
                      }`}
                  />
                </div>
                <h6
                  className={`uppercase text-sm lg:text-xl ${registrationStep === 3
                    ? 'font-bold text-extended'
                    : 'font-normal'
                    }`}
                >
                  Payment <br />
                  Information
                </h6>
              </div>
            </div>
            {/* 3 STEP ILLUSTRATION ENDS HERE */}
            <div className='overlay max-width-escape-bg '></div>
          </div>
          <div className='w-full lg:w-2/3 lg:pl-10'>
            <div className='enrollment-form-wrapper'>
              <div className='inner-divider'></div>

              {registrationStep === 1 ? (
                <ClientInformation
                  client={client}
                  address={address}
                  clientInfoRef={clientInfoRef}
                  submit={submit}
                />
              ) : null}

              {registrationStep === 2 ? (
                <PlanInformation
                  client={client}
                  directPrimaryCare={directPrimaryCare}
                  careNavigation={careNavigation}
                  additionalCoverage={additionalCoverage}
                  agentInfo={agentInfo}
                  selectedDpc={selectedDpc}
                  openAddModal={openAddModal}
                  removePlan={removePlan}
                  directPrimaryCareModal={directPrimaryCareModal}
                  additionalCoverageModal={additionalCoverageModal}
                  dpcSearchList={dpcSearchList}
                  openModalName={openModalName}
                  searchDpc={props.searchDpc}
                  savePlan={props.savePlan}
                  additionalCoverageList={additionalCoverageList}
                  planInfoRef={planInfoRef}
                  submit={submit}
                  updateFields={props.updateFields}
                  planInformationMonthlyCost={props.planInformationMonthlyCost}
                  comingFrom={props.comingFrom}
                  vCareMonthlyCost={props.vCareMonthlyCost}
                  // enrollmentContent from cosmic
                  enrollmentContent={enrollmentContent}
                  enrollmentFromDPC={props.enrollmentFromDPC}
                  searchProviders={props.searchProviders}
                  dpcLoading={dpcLoading}
                  vcareCosmicContent={vcareCosmicContent && vcareCosmicContent.metadata && vcareCosmicContent.metadata.vcare_information}
                />
              ) : null}

              {registrationStep === 3 ?
                <PaymentInformation
                  activeTab={paymentInformationActiveTab}
                  changeActiveTab={props.changePaymentInformationActiveTab}
                  cardPaymentRef={cardPaymentRef}
                  achPaymentRef={achPaymentRef}
                  client={client}
                  submit={submit}
                  changePaymentData={props.changePaymentData}
                  cosmicContent={props.paymentInfoCosmicContent}
                  cardErrorMessage={props.cardErrorMessage}
                  clearCardErrorMessage={props.clearCardErrorMessage}
                  bankErrorMessage={props.bankErrorMessage}
                  clearBankErrorMessage={props.clearBankErrorMessage}
                  tempPaymentData={props.tempPaymentData}
                  changeTempPaymentData={props.changeTempPaymentData}
                  paymentEnvironment={props.paymentEnvironment}
                />
                : null}

              {registrationStep === 4 ?
                <EnrollmentConfirmation
                  comingFrom={props.comingFrom}
                  vcareCosmicContent={vcareCosmicContent &&
                    vcareCosmicContent.metadata && vcareCosmicContent.metadata.enrollment_confirmation}
                />
                : null}

              {/* Navigation Buttons */}
              {registrationStep !== 4 ? (
                <div className='flex justify-between mt-8'>
                  <button
                    className={`group bg-gray-400 ease-out block text-bodyColor font-heebo text-lg px-8 py-3 rounded-full focus:outline-none  ${registrationStep === 1
                      ? 'cursor-not-allowed'
                      : 'hover:text-white hover:bg-secondary'
                      }`}
                    onClick={() =>
                      submit(
                        registrationStep > 1 ? registrationStep - 1 : 1,
                        null
                      )
                    }
                  >
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      className={`text-bodyColor mr-3 ${registrationStep !== 1 ? 'group-hover:text-white' : null
                        }`}
                    />
                    Back
                  </button>
                  <button
                    className={`mid-button ${registrationStep !== 1 && disableContinue()
                      ? 'btn-disabled'
                      : ''
                      }`}
                    onClick={formSubmitHandler}
                  >
                    <div className='flex items-center'>
                      {/* if loading, show the loader */}
                      {props.submittingForm ? (
                        <div className='mr-2'>
                          <ButtonLoader />
                        </div>
                      ) : null}

                      <div>
                        {registrationStep === 3 ? 'Enroll' : 'Continue'}
                        <FontAwesomeIcon
                          icon={faArrowRight}
                          className='text-white ml-3'
                        />
                      </div>
                    </div>
                  </button>
                </div>
              ) : null}

              <div className='inner-divider'></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 1024,
});

export default withSizes(mapSizesToProps)(Enrollment);
