import React from 'react';
import routeAliasConstant from '../../../constants/routeAliasConstant';
import { parseHtml } from '../../../util';

const EnrollmentConfirmation = (props) => {
  const { comingFrom, vcareCosmicContent } = props;
  return (
    <>
      <div className='container mx-auto px-4'>
        <h4 className='text-extended text-4xl'>Congratulations !</h4>
        <h4 className='text-extended font-normal'>
          You have been successfully enrolled
        </h4>

        {comingFrom !== routeAliasConstant.VCARE ?
          <p className='body-text text-lg sm:text-xl mt-6'>
            You will receive a confirmation in your email. Please click on the
            Activate link to access the portal where you can manage your membership
            and access your benefits.
              </p>
          :
          <div
            className='body-text text-lg sm:text-xl mt-6'
            dangerouslySetInnerHTML={{
              __html: parseHtml(vcareCosmicContent && vcareCosmicContent.next_steps),
            }}
          />
        }

        {comingFrom !== routeAliasConstant.VCARE ?
          <p className='body-text text-titleColor text-lg sm:text-xl mt-8 mb-20'>
            If you don't receive an email, <br /> please reach out to us at{' '}
            <span className='text-secondary'>support@vitafyhealth.com</span>
          </p>
          :
          <div
            className='body-text text-titleColor text-lg sm:text-xl mt-8 mb-20'
            dangerouslySetInnerHTML={{
              __html: parseHtml(vcareCosmicContent && vcareCosmicContent.help_text),
            }}
          />
        }

      </div>
    </>
  );
};

export default EnrollmentConfirmation;
