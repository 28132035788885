import React from 'react';

const AddPlan = (props) => {
  // const planCount = [1, 2]; // just to simulate multiple plans
  const { additionalCoverage, additionalCoverageList, openModalName } = props;
  const onSelectHandler = (plan, selectedItem, fields = [], setValueFields = []) => {
    plan.planDescription = selectedItem[fields[0]];
    plan.referenceId = selectedItem[fields[1]];
    plan.cost = fields[2] ? selectedItem[fields[2]] : 0;
    plan.isActive = true;
    props.savePlan(props[openModalName], null);
  }
  return (
    <>
      {/* SHORT DESCRIPTION STARTS HERE */}
      <div className='w-full flex flex-wrap items-center px-2 -mx-3'>
        <div className='w-full sm:w-1/5 mb-4 sm:mb-0 px-3'>
          <img
            src='https://sedera.com/wp-content/uploads/2020/03/Sedera-Blues-Logo-Vertical-1-1.png'
            alt='plan-img'
            className='w-20 sm:w-full mx-auto'
          />
        </div>
        <p className='w-full sm:w-4/5 body-text px-3 text-base text-center sm:text-left'>
          {props.sedera}
        </p>
      </div>
      {/* SHORT DESCRIPTION ENDS HERE */}
      <div className='divider-line'></div>

      {/* {planCount.map((item, index) => { */}
      {additionalCoverageList && additionalCoverageList.map(x => (
        <div
          className='flex justify-between items-center bg-bgColor w-full p-4 cursor-pointer shadow-md  hover:shadow-lg mb-5 '
          key={x.id}
        >
          <h6>{x.name}</h6>
          <button
            className='small-button block'
            onClick={() => onSelectHandler(additionalCoverage, x, ['name', 'uuid', 'value'], ['additionalCoverageDescription'])}
          >Select</button>
        </div>
      ))}
    </>
  );
};

export default AddPlan;
