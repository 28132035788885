import * as httpService from './httpService';
import APICONSTANTS from '../constants';

export function searchServices(searchKeyword) {
  return httpService.get(`${APICONSTANTS.SERVICE_SEARCH}?keyword=${searchKeyword}&reqType=marketplace`)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    })
}

export function getCostSummary(city, state, zipCode, primaryCode, bundleCode, latitude, longitude, limit, offset) {
  return httpService.get(`${APICONSTANTS.COSTS}/summary`, { city, state, zipCode, primaryCode, bundleCode, latitude, longitude, limit, offset })
    .then(response => {
      return response.data;
    }).catch(error => {
      throw error;
    })
}

export function getCostLocations(queryParams) {
  return httpService.get(`${APICONSTANTS.COSTS}/locations`, queryParams)
    .then(response => {
      return response.data;
    }).catch(error => {
      throw error;
    })
}
