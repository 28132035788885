import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { store } from '../../../store';
import { getTextFromLangDict } from '../../../util';

const AddClientCard = ({
  onAddClick,
  whiteBg,
  titleDictKey,
  buttonTextDictKey,
}) => {
  // Context Store
  const state = useContext(store);
  const { globalState } = state;
  const { groupCode, langDict, tenantCode } = globalState;

  return (
    <div
      className={`w-full rounded-md ${
        whiteBg ? 'bg-bgColor' : 'bg-white'
      }  py-2 md:py-6 px-6 h-40 md:h-80 flex flex-col justify-center items-center`}
    >
      <h6 className="font-normal text-center mb-5 text-lg md:text-xl">
        {getTextFromLangDict(langDict, {
          key: titleDictKey,
          groupCode,
          tenantCode,
        })}
      </h6>
      <button
        data-cy="addmember/dependent"
        className={`px-5 py-2 font-heebo text-base ${
          !whiteBg ? 'bg-bgColor' : 'bg-white'
        } border-2 border-dedicatedDpcPrimary border-opacity-100
        rounded-md text-dedicatedDpcPrimary focus:outline-none transition duration-100 hover:bg-gray-400`}
        onClick={onAddClick}
        type="button"
      >
        <FontAwesomeIcon
          icon={faPlus}
          className="text-dedicatedDpcPrimary mr-2"
        />
        {getTextFromLangDict(langDict, {
          key: buttonTextDictKey,
          groupCode,
          tenantCode,
        })}
      </button>
    </div>
  );
};

export default AddClientCard;
