import React, { useContext } from 'react';
import withSizes from 'react-sizes';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobileAlt, faEnvelope, faGlobe } from "@fortawesome/free-solid-svg-icons";

import { store } from '../../store';

import * as vitafyUtils from '../../util/vitafyUtil';

const DedicatedDpcFooter = (props) => {
  const state = useContext(store);
  const { globalState } = state;
  const { primaryLocation } = globalState;
  
  const phone = primaryLocation?.phone || '';
  const countryCode = primaryLocation?.countryCode || '';
  const email = primaryLocation?.email || '';
  const website = primaryLocation?.website || '';

  return (
    <div className='w-100 bg-bgColor'>
      <div className='container mx-auto flex flex-wrap justify-between items-center py-3 px-4'>
        <div className='w-full body-text lg:w-3/4 text-sm mb-3 md:mb-2 lg:mb-0 text-center lg:text-left
        flex items-center justify-center lg:justify-start'>
          {phone &&
            <a href={`tel:${phone}`} className='no-underline focus:outline-none inline-block'>
              <FontAwesomeIcon
                icon={faMobileAlt}
                className='text-sm text-dedicatedDpcPrimary inline'
              />
              <p className='ml-3 body-text text-titleColor text-sm inline'>
                {props.isMobile ? 'Call' : vitafyUtils.getPhoneDisplayText(phone, countryCode)}
              </p>
            </a>
          }
          {email &&
            <a
              href={`mailto:${email}`}
              className='no-underline focus:outline-none inline-block ml-5'>
              <FontAwesomeIcon
                icon={faEnvelope}
                className='text-sm text-dedicatedDpcPrimary inline'
              />
              <p className='ml-3 body-text text-titleColor text-sm inline'>
                {props.isMobile ? 'Email' : email}
              </p>
            </a>
          }
          {website &&
            <a
              href={website ? website.includes('https://' || 'http://') ? website : 'http://' + website : ''}
              target='_blank'
              rel="noopener noreferrer"
              className='no-underline focus:outline-none inline-block ml-5'>
              <FontAwesomeIcon
                icon={faGlobe}
                className='text-sm text-dedicatedDpcPrimary inline'
              />
              <p className='ml-3 body-text text-titleColor text-sm inline'>
                {props.isMobile ? 'Website' : website}
              </p>
            </a>
          }
        </div>

        <div className='w-full lg:w-1/4 flex justify-center lg:justify-end lg:block lg:text-right'>
          <p className='w-full body-text text-sm mb-2 lg:mb-0 text-center lg:text-right'>
            Powered by{' '}
            <a target='_blank' rel="noopener noreferrer" href='https://vitafyhealth.com/' className='font-bold outline-none no-underline'>
              Vitafy360
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 768,
})

export default withSizes(mapSizesToProps)(DedicatedDpcFooter);

