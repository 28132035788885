import React from 'react';

const PricingIntervalRadioInput = (props) => {
  const { paymentInterval, handleChangePaymentInterval, monthlyId, annuallyId, radioInputName } = props;
  return (
    <>
      <div className="inline-flex items-center mr-4" >
        <input id={annuallyId} type="radio" name={radioInputName} className="hidden"
          checked={paymentInterval === 'annually'}
          onClick={() => handleChangePaymentInterval('annually')} />
        <label for={annuallyId} className="flex items-center cursor-pointer body-text text-sm">
          <span className="w-5 h-5  inline-block mr-2 rounded-full border-2 border-grey flex-no-shrink"></span>
                Annually</label>
      </div>
      <div className="inline-flex items-center" >
        <input id={monthlyId} type="radio" name={radioInputName} className="hidden"
          checked={paymentInterval === 'monthly'}
          onClick={() => handleChangePaymentInterval('monthly')} />
        <label for={monthlyId} className="flex items-center cursor-pointer body-text text-sm">
          <span className="w-5 h-5 inline-block mr-2 rounded-full border-2 border-grey flex-no-shrink"></span>
                Monthly</label>
      </div>
    </>
  );
}

export default PricingIntervalRadioInput;