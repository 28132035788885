import React from 'react';
import { useFormContext } from 'react-hook-form';

import commonConstants from '../../../../constants/commonConstant';

const { MEMBER_TYPE } = commonConstants;

const MEMBER_TYPE_OPTIONS = [
  { text: MEMBER_TYPE.GROUP_MEMBER, value: MEMBER_TYPE.GROUP_MEMBER },
  { text: MEMBER_TYPE.DEPENDENT, value: MEMBER_TYPE.DEPENDENT },
];

const MemberTypeRadioGroup = ({ isDisabled = false }) => {
  const {
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const memberType = watch('memberType');

  const onChange = (newValue) => {
    setValue('memberType', newValue);
    clearErrors('memberType');
  };

  return (
    <>
      {MEMBER_TYPE_OPTIONS.map((item, index) => (
        <button
          data-cy={`memberType-${item.value}`}
          className={`mid-radio-btn px-3 md:px-10 ${
            memberType === item.value && 'font-bold bg-holyCityMedSecondary'
          } ${isDisabled && 'btn-disabled'} ${index !== 0 && 'ml-4'}`}
          onClick={() => onChange(item.value)}
          type="button"
          key={index}
          disabled={isDisabled}
        >
          {item.text}
        </button>
      ))}
      {!!errors.memberType && (
        <p className="text-red-600 text-sm mt-3">
          Please select the member type.
        </p>
      )}
    </>
  );
};

export default MemberTypeRadioGroup;
