import { faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import MapMarker from '../../assets/images/dpc-marker-pin.svg';
import { getAddressDisplayName } from '../../util/vitafyUtil';
import {
  popoverContent,
  popoverContentHover,
  popoverWrapper,
  popoverWrapperHover,
} from './placeHoverStyles';

class Map extends Component {
  static defaultProps = {
    center: {
      lat: 39.5,
      lng: -98.35,
    },
    zoom: 5,
    greatPlaces: [
      { id: 'A', lat: 40.955413, lng: -95.337844 },
      { id: 'B', lat: 39.724, lng: -96.337844 },
    ],
  };

  render() {
    const { title, subtitle } = this.props;

    const places =
      this.props.dpcList &&
      this.props.dpcList.map((place) => {
        const { providerId, displayName, address } = place;
        return (
          <DpcLocation
            key={providerId}
            lat={address?.lat}
            lng={address?.lng}
            displayName={displayName}
            address={address}
          />
        );
      });

    return (
      <>
        <div className="container mx-auto px-4 mb-8">
          <h3 className={`heading w-full ${subtitle ? 'mb-4' : 'mb-0'}`}>
            {title}
          </h3>
          {Boolean(subtitle) && (
            <p className="body-text text-lg font-normal">{subtitle}</p>
          )}
        </div>
        <div className="w-full relative" style={{ height: '80vh' }}>
          <div className="p-12">
            {' '}
            There seems to be an issue while diisplaying the map. Our team is
            working on it.
          </div>

          {/* <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyCo0hj8XUcjtwn3icKS041OZJy6d1_QfGc' }}
            defaultCenter={this.props.center}
            defaultZoom={this.props.zoom}
            hoverDistance={K_SIZE / 2}
          >
            {places}
          </GoogleMapReact> */}
        </div>
      </>
    );
  }
}

const DpcLocation = (props) => {
  const popoverWrapperStyle = props.$hover
    ? popoverWrapperHover
    : popoverWrapper;
  const popoverContentStyle = props.$hover
    ? popoverContentHover
    : popoverContent;
  return (
    <div style={popoverWrapperStyle}>
      <img
        src={MapMarker}
        className="popover__title cursor-pointer"
        alt="dpc-marker-pin"
      />
      <div style={popoverContentStyle}>
        <p className="body-text text-primary text-xs font-bold mb-1">
          {props.displayName}
        </p>
        <li className={`flex items-start mt-0`}>
          <div className="w-3 mt-1 mr-1">
            <FontAwesomeIcon
              icon={faLocationArrow}
              className={`text-primary`}
            />
          </div>
          <p className={`body-text text-xs`}>
            {getAddressDisplayName(props.address, true)}
          </p>
        </li>
      </div>
    </div>
  );
};

export default Map;
