import React from 'react';
import { parseHtml } from '../util';
import Fade from 'react-reveal/Fade';

const Value = ({ title, values, bgColor }) => {
  const display =
    values &&
    values.map((item, index) => {
      return (
        <React.Fragment key={index}>
          <Fade bottom>
            <div className='w-full md:w-1/3 px-4 mb-12 md:mb-0'>
              <div className='flex justify-center mb-5'>
                <img
                  src={item.image.url}
                  className='block h-16'
                  alt='value-icon'
                />
              </div>
              <div className='w-full text-center'>
                <h4
                  className={`mt-2 mb-4 ${!bgColor ? 'text-titleColor' : 'text-white'}`}
                >
                  {item.title}
                </h4>
                <p
                  className={`body-text ${!bgColor ? 'text-bodyColor' : 'text-white'
                    }`}
                >
                  {item.text}
                </p>
              </div>
            </div>
          </Fade>
        </React.Fragment>
      );
    });

  return (
    <div className={`w-full ${bgColor ? 'bg-extended' : null}`}>
      <div className={`divider ${bgColor ? 'block' : 'hidden'}`}></div>
      <div className='container mx-auto overflow-hidden px-4'>
        <Fade bottom>
          <div
            className={`mission-statement ${!bgColor ? 'text-titleColor' : 'text-white'
              }`}
            dangerouslySetInnerHTML={{ __html: parseHtml(title) }}
          />
        </Fade>
        {/* <div className='inner-divider'></div> */}
        <div
          className={`divider-line  my-12 lg:my-16 ${!bgColor ? 'bg-gray-600' : 'bg-white'
            }`}
        ></div>
        <div className='px-0 mx:px-6 flex flex-wrap md:-mx-4'>{display}</div>
      </div>
      <div className='divider'></div>
    </div>
  );
};

export default Value;
