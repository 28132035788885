import React from 'react';
import { useSelector } from 'react-redux';
import { selectInformationTab } from '../../../../stores/membership';
import { checkIsCompany, checkIsIndividual } from '../../../../util/vitafyUtil';
import CompanyMembership from './CompanyMembership';
import IndividualMembership from './IndividualMembership';

const MembershipPlan = ({ misc }) => {
  const { whiteBg } = misc;

  // Redux Store
  const informationTab = useSelector(selectInformationTab);

  const isIndividual = checkIsIndividual(informationTab.type);
  const isCompany = checkIsCompany(informationTab.type);

  return (
    <>
      {isIndividual && <IndividualMembership whiteBg={whiteBg} />}
      {isCompany && <CompanyMembership whiteBg={whiteBg} />}
    </>
  );
};

export default MembershipPlan;
