import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClock,
  faCalendarAlt,
  faEnvelope,
} from '@fortawesome/free-regular-svg-icons';
import {
  faBuilding,
  faMobileAlt,
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons';
import {
  MDInput,
  MaskedPhoneInput,
  MaskedDateInput,
  MaskedTimeInput,
} from '../../common';
import * as momentUtil from '../../util/momentUtil';
import * as vitafyUtils from '../../util/vitafyUtil';
import { ButtonLoader } from '../../common/loader';

const RequestAppointment = (props) => {
  const [dayShift, setDayShift] = useState('AM');
  const { register, errors, setValue, clearError, handleSubmit } = useForm();

  const { editData, checkinSubmitting } = props;

  const formBuilder = () => {
    register({ name: 'fullName' }, { required: true });
    register({ name: 'scheduledDate' }, { required: true });
    register({ name: 'scheduledTime' }, { required: true });
    register(
      { name: 'email' },
      {
        required: true,
        pattern: /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/i,
      }
    );
    register({ name: 'phone' });
    register({ name: 'description' });
    setValue([
      { fullName: editData.fullName },
      { scheduledDate: editData.scheduledDate },
      { scheduledTime: editData.scheduledTime },
      { email: editData.email },
      { phone: editData.phone },
      { description: editData.description },
    ]);
  };

  React.useEffect(() => {
    formBuilder();
  }, []);

  const handleInputChange = (e, field) => {
    let value = e.target ? e.target.value : e;
    if ((e.target ? e.target.validity.valid : e) || value == '') {
      setValue(field, value);
      editData[field] = value;
      clearError(field);
    }
  };

  const formSubmit = (formValues) => {
    formValues.phone = formValues.phone
      ? vitafyUtils.unmaskPhone(formValues.phone)
      : null;
    formValues.fromRequestAppointment = true; // to differentiate request appointment from member check-in

    props.formSubmit(formValues);
  };

  return (
    <>
      <div className='w-full bg-bgColor overflow-hidden'>
        <div className='container px-4 lg:flex mx-auto'>
          {/* Business Hours starts here */}
          <div className='w-full lg:w-1/3 relative'>
            <div className='inner-divider'></div>
            <div className='overlay-overcome'>
              <h3 className='heading'>Business Hours</h3>
              <div className='flex mb-3'>
                <FontAwesomeIcon
                  icon={faClock}
                  className='text-primary text-4xl block mr-4'
                />

                <div>
                  <div className='mb-4'>
                    <p className='font-heebo font-bold text-sm'>
                      Monday - Friday
                    </p>
                    <p className='font-heebo font-bold text-xl'>
                      8:00am - 8:00pm
                    </p>
                  </div>
                </div>
              </div>
              <div className='flex mb-3'>
                <FontAwesomeIcon
                  icon={faClock}
                  className='text-primary text-4xl block mr-4'
                />

                <div>
                  <div className='mb-4'>
                    <p className='font-heebo font-bold text-sm'>Saturday</p>
                    <p className='font-heebo font-bold text-xl'>
                      8:00am - 5:00pm
                    </p>
                  </div>
                </div>
              </div>
              <div className='flex '>
                <FontAwesomeIcon
                  icon={faClock}
                  className='text-primary text-4xl block mr-4'
                />

                <div>
                  <div className='mb-4'>
                    <p className='font-heebo font-bold text-sm'>Sunday</p>
                    <p className='font-heebo font-bold text-xl'>Closed</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='inner-divider'></div>

            <div className='overlay max-width-escape-bg '></div>
          </div>
          {/* Business Hours ends here */}

          {/* Request Appointment Form starts here */}
          <div className='w-full lg:w-2/3 lg:pl-10'>
            <div className='inner-divider'></div>

            <h3 className='heading mb-8'>Request Appointment</h3>

            <p className='body-text text-lg mb-8'>
              Please enter the following information to request for an
              appointment.
            </p>

            <form onSubmit={handleSubmit(formSubmit)}>
              {/* Full Name */}
              <MDInput
                id='fullName'
                label='Full Name'
                icon={faBuilding}
                name='fullName'
                value={editData?.fullName ? editData.fullName : ''}
                inputChange={(e) => handleInputChange(e, 'fullName')}
                error={errors.fullName}
              />

              <div className='flex flex-wrap md:-mx-5 mb-0'>
                {/* Preferred Date */}
                <div className='w-full md:w-1/2 md:px-5 mb-3 md:mb-0'>

                  <MaskedDateInput
                    id='scheduledDate'
                    input={
                      editData && editData.scheduledDate
                        ? momentUtil.momentIsValid(editData.scheduledDate)
                          ? momentUtil.momentViewFormat(editData.scheduledDate)
                          : editData.scheduledDate
                        : null
                    }
                    setInput={(e) => handleInputChange(e, 'scheduledDate')}
                    name='scheduledDate'
                    label='Preferred Date'
                    icon={faCalendarAlt}
                    error={errors.scheduledDate}
                  />
                </div>
                {/* Preferred Time */}
                <div className='w-full md:w-1/2 md:px-5'>
                  <MaskedTimeInput
                    id='preferredTime'
                    name='scheduledTime'
                    label='Preferred Time'
                    dayShift={dayShift}
                    changeDayShift={(shift) => setDayShift(shift)}
                    input={
                      editData && editData.scheduledTime
                        ? editData.scheduledTime
                        : null
                    }
                    setInput={(e) => handleInputChange(e, 'scheduledTime')}
                    error={errors.scheduledTime}
                  />
                </div>
              </div>

              <div className='flex flex-wrap md:-mx-5 mb-5'>
                {/* Email */}
                <div className='w-full md:w-1/2 md:px-5 mb-3 md:mb-0'>
                  <MDInput
                    id='email'
                    label='Email'
                    name='email'
                    value={editData?.email ? editData.email : ''}
                    inputChange={(e) => handleInputChange(e, 'email')}
                    icon={faEnvelope}
                    error={errors.email}
                  />
                </div>
                {/* Phone */}
                <div className='w-full md:w-1/2 md:px-5'>
                  <MaskedPhoneInput
                    input={editData && editData.phone ? editData.phone : null}
                    setInput={(e) => handleInputChange(e, 'phone')}
                    name='phone'
                    label='Phone'
                    icon={faMobileAlt}
                  />
                </div>
              </div>
              {/* Service you need */}
              <div className='w-full mb-3'>
                <MDInput
                  id='serviceNeeded'
                  label='Describe the service you need'
                  name='description'
                  value={editData?.description ? editData.description : ''}
                  inputChange={(e) => handleInputChange(e, 'description')}
                  error={errors.description}
                />
              </div>

              <button className={`mid-button mt-8 ${checkinSubmitting ? 'btn-disabled' : null}`}>
                <div className='flex items-center'>
                  <div>
                    Submit
                    {!checkinSubmitting && (
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        className='text-white ml-3'
                      />
                    )}
                  </div>
                  {checkinSubmitting ? (
                    <div className='ml-2'>
                      <ButtonLoader />
                    </div>
                  ) : null}
                </div>
              </button>
              <div className='inner-divider'></div>
            </form>
          </div>
          {/* Request Appointment Form ends here */}
        </div>
      </div>
    </>
  );
};

export default RequestAppointment;
