/**
 * @author Slesha Tuladhar <slesha@noveltytechnology.com>
 * @author Sunil Rajbahak <sunil@noveltytechnology.com>
 */
import React from 'react';

import { _cloneDeep } from './lodashUtil';

import commonConstants from '../constants/commonConstant';
import { PAYMENT_GATEWAY_TYPES } from '../constants/payment';
import routeTypeConstant from '../constants/routeTypeConstant';
import stateConstant from '../constants/stateConstant';
import subdomainMap from '../constants/subdomainMap';

import { countryCode } from '../constants/countryCode';
import offeringConstant from '../constants/offeringConstant';
import { getSubDomain } from '../util/miscellaneousUtil';
import { getSession } from './storageUtil';

const {
  MEMBERSHIP_REGISTRATION_TYPES,
  MEMBER_TYPE,
  MEMBERSHIP_ENROLLMENT_STEPS,
} = commonConstants;

export const getDisplayName = (data) => {
  const displayName = data.businessName
    ? data.businessName
    : `${data.firstName} ${data.middleName ? data.middleName + ' ' : ''}${
        data.lastName
      }`;
  return displayName ? displayName.trim() : '';
};

export const getAddressDisplayName = (address, doubleLine = false) => {
  if (!address) return 'N/A';
  if (address.addressLine1 === undefined) {
    address.addressLine1 = address.addressline1;
    address.addressLine2 = address.addressline2;
  }
  if (!doubleLine)
    return `${address.addressLine1}, ${
      address.addressLine2 ? address.addressLine2 + ', ' : ''
    } ${address.city}, ${address.state} ${address.zip}`;
  return (
    <span>
      {' '}
      {address.addressLine1 && (
        <span>
          {address.addressLine1}
          {address.addressLine2 ? `, ${address.addressLine2}` : ''}
          {address.city && <br />}
        </span>
      )}
      {address.city}
      {(address.state || address.zip) && (
        <span>
          ,{address.city && ' '}
          {!address.city && <br />}
        </span>
      )}
      {address.state}
      {address.state && ' '}
      {address.zip || address.zipCode}
    </span>
  );
};

export const getPhoneDisplayText = (phone, country = countryCode.USA) => {
  if (phone) {
    if (countryCode.USA === country) {
      const x = phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      phone = !x[2]
        ? x[1]
        : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
      return phone;
    }
    return phone;
  } else {
    return 'N/A';
  }
};

export const getAge = (dob) => {
  let age = 0;
  if (dob) {
    dob = new Date(dob);
    let today = new Date(Date.now());
    age = today.getFullYear() - dob.getFullYear();

    if (today.getMonth() <= dob.getMonth() && today.getDate() < dob.getDate()) {
      age--;
    }
  }
  return age;
};

export const getFullName = (data) => {
  const fullName = `${data.firstName} ${
    data.middleName ? data.middleName + ' ' : ''
  }${data.lastName}`;
  return fullName ? fullName.trim() : '';
};

export const currencyFormat = (value) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value);
};

export const unmaskPhone = (value) => {
  if (!value) return value;
  return value.replace(/[^\d]/g, '');
};

export const replaceLowerCase = (str, from, to, replaceForeign = false) => {
  const replaceFrom = new RegExp(from, 'g');
  let newStr = str.replace(replaceFrom, to).toLowerCase();
  if (replaceForeign) {
    newStr = newStr.replace(/[^a-zA-Z 0-9]+/g, '');
  }
  return newStr;
};

export const formatCurrency = (value, elseNull = true, min = 2, max = 2) => {
  return value || value === 0
    ? new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: min,
        maximumFractionDigits: max,
      }).format(value)
    : elseNull
    ? null
    : formatCurrency(0);
};

export const unformatCurrency = (value) => {
  return value || value === 0 ? value.toString().replace(/[^0-9.]/g, '') : '';
};

export const formatPercent = (
  value,
  elseNull = true,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2
) => {
  return value || value === 0
    ? new Intl.NumberFormat('en-US', {
        style: 'percent',
        minimumFractionDigits,
        maximumFractionDigits,
      }).format(value / 100)
    : elseNull
    ? null
    : formatPercent(0);
};

export const getDistance = (lat1, lon1, lat2, lon2, unit = 'mile') => {
  // unit: mile(default),km,nautical
  if (lat1 == lat2 && lon1 == lon2) {
    return 0;
  } else {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === 'km') {
      dist = dist * 1.609344;
    }
    if (unit === 'nautical') {
      dist = dist * 0.8684;
    }
    return +formatNumber(dist, true, 0, 0);
  }
};

export const formatNumber = (
  value,
  elseNull = true,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2
) => {
  return value || value === 0
    ? new Intl.NumberFormat('en-US', {
        minimumFractionDigits,
        maximumFractionDigits,
      }).format(value)
    : elseNull
    ? null
    : formatNumber(0);
};

export const capitalizeFirstLetter = (string) => {
  if (!string) return string;
  string = string.toLowerCase();
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const capitalizeFirstLetterOfEachWord = (string) => {
  if (!string) return string;
  return string.toLowerCase().split(' ').map(capitalizeFirstLetter).join(' ');
};

/**
 *  Converts Bold City Direct Primary Care to bold-city-direct-primary-care
 */
export const getSeoOptmizedUrl = (value) => {
  if (!value) return value;
  return value.toLowerCase().trim().split(/\s+/).join('-');
};

// Validates time input based on 12 hour range
// Output: false => error
export const validateTimeInput = (value) => {
  if (!value) return true;
  if (value.includes('_')) return false; // Incomplete input -> Error
  const hour = parseInt(value.substring(0, 2));
  if (hour < 0 || hour > 12) return false; // Hour not in (0, 12) -> Error
  const min = parseInt(value.substring(3, 5));
  if (min < 0 || min > 59) return false; // Min not in (0, 59) -> Error
  return true;
};

export const getFullStateName = (acronym) => {
  if (stateConstant[acronym] != null) {
    return stateConstant[acronym];
  }
  return acronym;
};

// snakeCase -> snake_case
// SnakeCase -> _snake_case
export function toSnakeCase(str) {
  if (!str) return str;
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}

export const cleanQueryParamsForOfferingFetch = (queryParams) => {
  if (!queryParams) return queryParams;

  const data = JSON.parse(JSON.stringify(queryParams));

  let cleanedData = (({
    tenantCode,
    tenantId,
    type,
    totalMembers,
    billingType,
    visibility,
    isActive,
    groupCode,
    tenantServiceCode,
    referenceId,
    referenceType,
    firstName,
    lastName,
    memberId,
    dob,
    tenantOfferingId,
  }) => ({
    tenantCode,
    tenantId,
    type,
    totalMembers,
    billingType,
    visibility,
    isActive,
    groupCode,
    tenantServiceCode,
    referenceId,
    referenceType,
    firstName,
    lastName,
    memberId,
    dob,
    tenantOfferingId,
  }))(data);

  const dependents = data.dependents?.map((item) => {
    return {
      firstName: item.firstName,
      middleName: item.middleName,
      lastName: item.lastName,
      dob: item.dob,
      dependentId: item.dependentId,
      memberId: item.memberId,
    };
  });

  cleanedData = {
    ...cleanedData,
    dependents: dependents?.length > 0 ? dependents : [],
  };

  return cleanedData;
};

export const getProviderContact = (provider) => {
  if (!provider) return {};
  return {
    phone:
      provider?.providerContact?.schedulingPhone ||
      provider?.providerContact?.primaryPhone ||
      provider.phone,
    email:
      provider?.providerContact?.schedulingEmail ||
      provider?.providerContact?.primaryEmail ||
      provider.email,
  };
};

export const adaptCalculatedOfferingsResponse = (data) => {
  const clonedData = _cloneDeep(data);
  const clonedSelectedServices = clonedData.data?.selectedOfferings?.map(
    (item) => ({
      ...item,
      memberOfferings: item.memberOfferings.map((m) => ({
        ...m,
        memberType: m.memberType?.toLowerCase(),
      })),
    })
  );

  return {
    status: data.success ? 'success' : 'error',
    message: data.message,
    data: {
      services: clonedData.data?.services,
      selectedServices: clonedSelectedServices,
    },
  };
};

export const formatCheckoutPayloadToRelectNewAPI = (data) => {
  return {
    ...data,
    type: data.type === 'Family' ? 'Individual' : data.type,
    contactFirstName: data.type === 'Group' ? data.firstName : undefined,
    contactMiddleName: data.type === 'Group' ? data.middleName : undefined,
    contactLastName: data.type === 'Group' ? data.lastName : undefined,
  };
};

/**
 * Get route type based on subdomain
 *
 * There are three route types currently.
 * 1. Landing page for multiple locations (For eg: seaislandmedical.vitafyhealth.com/)
 * 2. DPC (For eg: ktmdpc.vitafyhealth.com)
 * 3. ADVOCATE (For eg: medishield.vitafyhealth.com)
 *
 * @param {string} subdomain
 * @returns {string} :: 'MULTIPLE_LOCATION' | 'DPC' | 'ADVOCATE'
 */
export const getWebsiteInfo = () => {
  const subdomain = getSubDomain();
  return subdomainMap[subdomain];
};

/**
 * Check if the given route is for displaying multiple locations
 *
 * @returns true if route type is of type 'Multiple Locations;
 *          false if tenantCodeUrl is present in tenantCodeList even if route type is of type 'Multiple Locations
 *
 * @param {string} routeType
 * @param {string[]} tenantCodeList
 * @param {string} tenantCodeUrl
 */
export const checkIsMultipleLocationRoute = (
  routeType,
  tenantCodeList = [],
  tenantCodeUrl = ''
) => {
  return (
    routeType === routeTypeConstant.MULTIPLE_LOCATION &&
    !tenantCodeList?.includes(tenantCodeUrl)
  );
};

export const getInformationFormDefaultValues = () => ({
  // common to both Individual and Company
  clientId: '', // differentiates edit from add client
  type: '', // Individual / Company
  firstName: '',
  middleName: '',
  lastName: '',
  email: '',
  phone: '',
  phoneExt: '1',
  countryCode: countryCode.USA,
  textEnabled: false,
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zip: '',
  lat: '',
  lng: '',
  referredByName: '',
  referredById: '',
  // specific to Individual
  dob: '',
  lastSSN: '',
  gender: '',
  services: [],
  bloodGroup: '',
  // specific to Company
  businessName: '',
});

// export const getInformationFormDefaultValues = () => ({
//   clientId: '59b8480e-ee17-4705-a457-1321dc950406',
//   type: 'Individual',
//   firstName: 'Savannah',
//   middleName: '',
//   lastName: 'Riley',
//   email: 'sybokor@mailinator.com',
//   phone: '(179) 674-6425',
//   addressLine1: '387 White Nobel Freeway',
//   addressLine2: 'A et non ea lorem in',
//   city: 'Similique consequunt',
//   state: 'Deserunt incididunt ',
//   zip: '11010',
//   lat: '',
//   lng: '',
//   referredByName: 'Ainsley Lara',
//   referredById: '900409f6-9741-44f3-8479-0d2d9234348e',
//   dob: '01/01/1995',
//   lastSSN: '2824',
//   gender: '',
//   services: [],
//   bloodGroup: 'B+',
//   businessName: '',
// });

// export const getInformationFormDefaultValues = () => ({
//   clientId: 'a585883a-0f29-40e8-9757-7a01f34d8aeb',
//   type: 'Group',
//   firstName: 'Kirk',
//   middleName: '',
//   lastName: 'Frost',
//   email: 'kopihidoxy@mailinator.com',
//   phone: '(133) 361-2402',
//   addressLine1: '884 Oak Avenue',
//   addressLine2: 'Voluptatem ut at ali',
//   city: 'Esse quis id volupta',
//   state: 'Est quisquam ex veli',
//   zip: '87839',
//   lat: '',
//   lng: '',
//   referringAgent: '',
//   referringAgentId: '',
//   dob: '',
//   lastSSN: '',
//   gender: '',
//   services: [],
//   bloodGroup: '',
//   businessName: 'Wolfe Rosario Traders',
// });

export const formatIndividualDemographicPayload = (data) => ({
  clientId: data.clientId ?? undefined,
  clientEnrollmentId: data.clientEnrollmentId ?? undefined,
  type: data.type,
  firstName: data.firstName,
  middleName: data.middleName,
  lastName: data.lastName,
  lastSSN: data.lastSSN,
  dob: data.dob,
  gender: data.gender,
  email: data.email,
  phone: data.phone ? unmaskPhone(data.phone) : '',
  countryCode: data?.countryCode ?? countryCode.USA,
  phoneExt: data?.phoneExt ?? '1',
  textEnabled: data?.textEnabled ?? false,
  address: {
    addressLine1: data.addressLine1,
    addressLine2: data.addressLine2,
    city: data.city,
    state: data.state,
    zip: data.zip,
    lat: Number(data.lat) || '',
    lng: Number(data.lng) || '',
  },
  tenantId: data.tenantId,
  tenantGroupCode: data.groupCode,
  additionalServices: data.additionalServices ?? undefined,
  race: data.race,
  hasInsurance: data.hasInsurance,
  insuranceName: data.hasInsurance ? data.insuranceName : undefined,
  insuranceMemberId: data.hasInsurance ? data.insuranceMemberId : undefined,
  groupNumber: data.hasInsurance ? data.groupNumber : undefined,
  note: data.services?.reduce(
    (total, current) => `${total}${total && ', '}${current.label}`,
    ''
  ),
  bloodGroup: data.bloodGroup || '',
  affiliate: {
    type: data.referredById ? data.referredByType : '',
    id: data.referredById,
    name: data.referredByName,
  },
  enrollmentType: data?.enrollmentType || undefined,
  // referredBy: {
  //   type: data.referredById ? data.referredByType : '',
  //   id: data.referredById,
  //   name: data.referredByName,
  // },

  // sponsorId: 'Molestiae non do rer',
});

export const formatCompanyDemographicPayload = (data) => ({
  clientId: data.clientId ?? undefined,
  clientEnrollmentId: data.clientEnrollmentId ?? undefined,
  type: data.type,
  businessName: data.businessName,
  contactFirstName: data.firstName,
  contactMiddleName: data.middleName,
  contactLastName: data.lastName,
  email: data.email,
  phone: data.phone ? unmaskPhone(data.phone) : '',
  address: {
    addressLine1: data.addressLine1,
    addressLine2: data.addressLine2,
    city: data.city,
    state: data.state,
    zip: data.zip,
    lat: Number(data.lat) || '',
    lng: Number(data.lng) || '',
  },
  tenantId: data.tenantId,
  tenantGroupCode: data.groupCode,
  affiliate: {
    type: data.referredById ? data.referredByType : '',
    id: data.referredById,
    name: data.referredByName,
  },
  countryCode: data?.countryCode ?? countryCode.USA,
  phoneExt: data?.phoneExt ?? '1',
  textEnabled: data?.textEnabled ?? false,
  // note: 'Cumque eu anim solut',
  // sponsorId: 'Molestiae non do rer',
});

export const formatDependentPayload = (data) => ({
  ...formatIndividualDemographicPayload(data),
  relationship: {
    primaryId: data.parentId,
    primaryEnrollmentId: data.parentEnrollmentId,
    primaryName: data.parentName,
    type: data.relationship,
  },
});

export const checkIsIndividual = (type) => {
  return (
    type?.toLowerCase() ===
    MEMBERSHIP_REGISTRATION_TYPES.individual.value?.toLowerCase()
  );
};

export const checkIsCompany = (type) => {
  return (
    type?.toLowerCase() ===
    MEMBERSHIP_REGISTRATION_TYPES.company.value?.toLowerCase()
  );
};

export const getCardConnectIframeBaseUrl = (environment) => {
  return environment === 'live'
    ? process.env.REACT_APP_PAYMENT_LIVE_BASE_URL
    : process.env.REACT_APP_PAYMENT_TEST_BASE_URL;
};

export const formatMembershipOfferingForSave = (offering) => ({
  startDate: offering.benefitStartDate,
  offeringName: offering.name,
  offeringId: offering.tenantOfferingId,
  // totalCost: +offering.price,
  cost: +offering.price,
  billingInterval: offering.billingInterval,
  billingType: offering.billingType,
  registrationFee: +offering.registrationFee,
  tenantId: offering.tenantId,
  clientId: offering.clientId,
  clientEnrollmentId: offering?.clientEnrollmentId ?? undefined,
});

export const formatGroupEnrollmentSavePayload = (offering) => {
  return {
    ...offering,
    offeringId: offering?.offeringId ?? offering.tenantOfferingId,
  };
};

export const formatEnrollmentSavePayload = (
  offerings,
  { primaryIndividual, benefitStartDate, tenantId, tenantGroupCode }
) => {
  const isCompany =
    primaryIndividual.type === MEMBERSHIP_REGISTRATION_TYPES.company.value;

  return offerings.map((item) => ({
    clientId: item.dependent?.clientId || primaryIndividual.clientId,
    clientEnrollmentId:
      item.dependent?.clientEnrollmentId ||
      primaryIndividual.clientEnrollmentId,
    primaryId: item.dependent?.clientId
      ? primaryIndividual.clientId
      : undefined,
    startDate: benefitStartDate,
    offeringName: item.name,
    offeringType: offeringConstant.TYPE.MEMBERSHIP,
    offeringId: item.tenantOfferingId,
    // totalCost: +item.price,
    cost: +item.price,
    billingInterval: item.billingInterval,
    billingType: item.billingType,
    registrationFee: +item.registrationFee,
    tenantId,
    tenantGroupCode: isCompany ? tenantGroupCode : undefined,
    type: isCompany ? MEMBERSHIP_REGISTRATION_TYPES.company.value : undefined,
  }));
};

export const formatPaymentInformationSavePayload = ({
  primaryIndividual,
  payment,
  clientEnrollmentId,
  offerings,
  merchantProfile,
  tenantId,
}) => {
  const primaryOffering = offerings?.find(
    (item) => item.offeringType === commonConstants.OFFERING_TYPE_PRIMARY
  );
  const { paymentMethod, tmpPaymentData } = payment;

  const data = {
    clientEnrollmentId,
    tenantOfferingId:
      primaryOffering.tenantOfferingId || primaryOffering.offeringId,
    offeringName: primaryOffering.offeringName,
    billingInterval: primaryOffering.billingInterval,
    billingType: primaryOffering.billingType,
    tenantId,
    processTransaction: true,
    reference: {
      id: primaryIndividual.clientId,
      type: 'Client',
    },
    billingAddress: {
      businessName: primaryIndividual.businessName,
      firstName: primaryIndividual.firstName,
      middleName: primaryIndividual.middleName,
      lastName: primaryIndividual.lastName,
      email: primaryIndividual.email,
      phone: primaryIndividual.phone
        ? unmaskPhone(primaryIndividual.phone)
        : '',
      phoneExt: primaryIndividual?.phoneExt ?? '1',
      countryCode: primaryIndividual?.countryCode ?? countryCode.USA,
      textEnabled: primaryIndividual?.textEnabled ?? false,
      address: {
        addressLine1: primaryIndividual.addressLine1,
        addressLine2: primaryIndividual.addressLine2,
        city: primaryIndividual.city,
        state: primaryIndividual.state,
        zip: primaryIndividual.zip,
      },
    },
    cardDetail: {
      paymentMethod,
      paymentEnvironment: merchantProfile.paymentEnvironment,
      source: merchantProfile.gatewayType,
    },
  };

  if (merchantProfile.gatewayType === PAYMENT_GATEWAY_TYPES.CARD_CONNECT) {
    if (paymentMethod.toLowerCase() === 'card') {
      data.cardDetail.token = tmpPaymentData.card.token;
      data.cardDetail.expiry = tmpPaymentData.card.expiry;
    } else if (paymentMethod.toLowerCase() === 'bank') {
      data.cardDetail.token = tmpPaymentData.bank.token;
    }
  }

  if (merchantProfile.gatewayType === PAYMENT_GATEWAY_TYPES.AUTHORIZE_DOT_NET) {
    if (paymentMethod.toLowerCase() === 'card') {
      data.cardDetail.token = tmpPaymentData.card.token;
      data.cardDetail.tokenSource = tmpPaymentData.card.tokenSource;
    } else if (paymentMethod.toLowerCase() === 'bank') {
      data.cardDetail.token = tmpPaymentData.bank.token;
      data.cardDetail.tokenSource = tmpPaymentData.bank.tokenSource;
    }
  }

  if (merchantProfile.gatewayType === PAYMENT_GATEWAY_TYPES.STRIPE) {
    if (paymentMethod.toLowerCase() === 'card') {
      data.cardDetail.token = tmpPaymentData.card.token;
    }
  }

  return data;
};

export const formatAncillaryOfferingSavePayload = (data) => {
  const { isMember, payment } = data;
  const { paymentMethod } = payment;

  const enrollmentDetails = data.selectedServices?.map((item) => ({
    name: item.name,
    cost:
      item.name?.toLowerCase() === 'others' // if name === 'others' -> use item.price
        ? item.price // else use item.memberPrice or item.nonMemberPrice based on isMember
        : isMember
        ? item.memberPrice
        : item.nonMemberPrice,
    // descriptor: item.name,
    code: item.category,
    offeringId: item.tenantOfferingId || item.offeringId,
    inventory: item?.inventory ?? null,
  }));

  const payload = {
    services: enrollmentDetails,
    client: {
      id: data.clientId,
      clientEnrollmentId: data.clientEnrollmentId,
      firstName: data.firstName,
      lastName: data.lastName,
      middleName: data.middleName,
      email: data.email,
      phone: data.phone ? unmaskPhone(data.phone) : '',
      address: data.address,
      countryCode: data?.countryCode ?? countryCode.USA,
      textEnabled: data?.textEnabled ?? false,
      phoneExt: data?.phoneExt ?? '1',
    },
    tenantId: data.tenantId,
    tenantGroupCode: data.groupCode,
    tenantCode: data.tenantCode,
    environment: data.payment.paymentEnvironment,
    mode: data.payment.paymentMethod,
    manualCheckout: true,
    billingInterval: 'One Time',
    action: 'AddService',
    paymentDetail: {
      paymentMethod: data.payment.paymentMethod,
      paymentEnvironment: data.payment.paymentEnvironment,
      source: data.gatewayType,
    },
    hasInsurance: data.hasInsurance,
    insuranceName: data.hasInsurance ? data.insuranceName : undefined,
    insuranceMemberId: data.hasInsurance ? data.insuranceMemberId : undefined,
    groupNumber: data.hasInsurance ? data.groupNumber : undefined,
  };

  if (data.gatewayType === PAYMENT_GATEWAY_TYPES.CARD_CONNECT) {
    if (paymentMethod.toLowerCase() === 'card') {
      payload.paymentDetail.token = data.payment.token;
      payload.paymentDetail.expiry = data.payment.expiry;
    } else if (paymentMethod.toLowerCase() === 'bank') {
      payload.paymentDetail.token = data.payment?.token;
    }
  }

  if (data.gatewayType === PAYMENT_GATEWAY_TYPES.AUTHORIZE_DOT_NET) {
    if (paymentMethod.toLowerCase() === 'card') {
      payload.paymentDetail.token = data.payment?.token;
      payload.paymentDetail.tokenSource = data.payment?.tokenSource;
    } else if (paymentMethod.toLowerCase() === 'bank') {
      payload.paymentDetail.token = data.payment?.token;
      payload.paymentDetail.tokenSource = data.payment?.tokenSource;
    }
  }

  return payload;
};

export const formatCreateAppointmentPayload = (data) => {
  return {
    patient: {
      id: data.clientId,
      firstName: data.firstName,
      middleName: data.middleName,
      lastName: data.lastName,
      dob: data.dob,
      gender: data.gender,
      last4ssn: data.lastSSN,
      email: data.email,
      phone: data.phone,
      address: data.address,
      phoneExt: data?.phoneExt ?? '1',
      countryCode: data?.countryCode ?? countryCode.USA,
      textEnabled: data?.textEnabled ?? false,
    },
    tenantId: data.tenantId,
    tenantGroupCode: data.tenantGroupCode,
    status: 'CheckedIn',
  };
};

export const formatGroupMemberSavePayload = (data, primaryClient) => {
  return {
    type: MEMBERSHIP_REGISTRATION_TYPES.individual.value,
    firstName: data.firstName,
    middleName: data.middleName,
    lastName: data.lastName,
    lastSSN: data.lastSSN,
    dob: data.dob,
    gender: data.gender,
    email: data.email,
    phone: data.phone ? unmaskPhone(data.phone) : '',
    address: {
      addressLine1: data.addressLine1 || '',
      addressLine2: data.addressLine2 || '',
      city: data.city || '',
      state: data.state || '',
      zip: data.zip || '',
      lat: Number(data.lat) || '',
      lng: Number(data.lng) || '',
    },
    tenantId: data.tenantId,
    groupCode: data.groupCode,
    tenantCode: data.tenantCode,
    tenantGroupCode: data.groupCode,
    // relationship:
    //   data.memberType === MEMBER_TYPE.DEPENDENT
    //     ? data.relationship
    //     : MEMBER_TYPE.PRIMARY,
    relationship: {
      primaryId:
        data.memberType === MEMBER_TYPE.DEPENDENT ? data.parentId : null,
      primaryEnrollmentId:
        data.memberType === MEMBER_TYPE.DEPENDENT
          ? data.parentEnrollmentId
          : null,
      primaryName:
        data.memberType === MEMBER_TYPE.DEPENDENT ? data.parentName : null,
      // primaryId: data.memberType === MEMBER_TYPE.DEPENDENT ? primaryClient.clientId : null,
      // primaryEnrollmentId:data.memberType === MEMBER_TYPE.DEPENDENT ? primaryClient.clientEnrollmentId: null,
      // primaryName: data.memberType === MEMBER_TYPE.DEPENDENT ? primaryClient.businessName: null,
      groupId:
        data.memberType === MEMBER_TYPE.DEPENDENT
          ? primaryClient.clientId
          : data.parentId,
      groupEnrollmentId:
        data.memberType === MEMBER_TYPE.DEPENDENT
          ? primaryClient.clientEnrollmentId
          : data.parentEnrollmentId,
      groupName:
        data.memberType === MEMBER_TYPE.DEPENDENT
          ? primaryClient.businessName
          : data.parentName,
      type:
        data.memberType === MEMBER_TYPE.DEPENDENT
          ? data.relationship
          : MEMBER_TYPE.PRIMARY,
    },
    countryCode: data?.countryCode ?? countryCode.USA,
    phoneExt: data?.phoneExt ?? '1',
    textEnabled: data?.textEnabled ?? false,
  };
};

export const validateBankAccountType = (data) => {
  return (
    data === 'checking' || data === 'savings' || data === 'businessChecking'
  );
};

export const validateCardNumber = (data) => {
  if (!data) return false;
  return data.length >= 14 && data.length <= 16;
};

export const validateCardExpiry = (data) => {
  if (!data) return false;
  return data.length === 4;
};

export const validateCardCVC = (data) => {
  if (!data) return false;
  return data.length === 3 || data.length === 4;
};

// Use this util whenever there is a need to increase/decrease step in the membership enrollment process
export const changeMembershipStep = (
  currentStep,
  { increment = true, isQuestionaireEnabled = false }
) => {
  const { INFORMATION, QUESTIONAIRE, MEMBERSHIP, CHECKOUT, CONFIRMATION } =
    MEMBERSHIP_ENROLLMENT_STEPS;

  let steps = isQuestionaireEnabled
    ? [INFORMATION, QUESTIONAIRE, MEMBERSHIP, CHECKOUT, CONFIRMATION]
    : [INFORMATION, MEMBERSHIP, CHECKOUT, CONFIRMATION];

  const currentStepIndex = steps.findIndex((item) => item === currentStep);

  return increment ? steps[currentStepIndex + 1] : steps[currentStepIndex - 1];
};

const convertMonthToDoubleDigit = (month) => {
  if (month && month.length === 1) return `0${month}`;
  return month;
};

const convertYearToDoubleDigit = (year) => {
  if (year && year.length > 2) return year.slice(-2);
  return year;
};

export const getCardExpiryText = (expiryMonth, expiryYear) =>
  `${convertMonthToDoubleDigit(expiryMonth)}/${convertYearToDoubleDigit(
    expiryYear
  )}`;

export const getCardNumberDisplayText = (last4Digit) =>
  `XXXXXXXXXXXX${last4Digit}`;

/**
 * Determines whether to show maintenance page or not at the root of the app
 *
 */
export const showMaintenancePage = () => {
  const adminAccessCode = JSON.parse(getSession('ADMIN_ACCESS_CODE'));

  if (adminAccessCode === process.env.REACT_APP_MAINTENANCE_PAGE_BYPASS_CODE) {
    return false;
  }

  return process.env.REACT_APP_SHOW_MAINTENANCE_PAGE === 'true';
};
