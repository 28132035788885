import React from 'react';
import { Switch, BrowserRouter } from 'react-router-dom';
import AppRoute from './appRoute';
import MainLayout from '../layouts/MainLayout';
import DedicatedDpcLayout from '../layouts/DedicatedDpcLayout';
import {
  DedicatedDpcAgreement,
  MultipleLocationLanding,
  PageNotFound,
} from '../components';

const MultipleLocationLandingRoute = () => {
  const baseUrl = process.env.PUBLIC_URL;
  return (
    <BrowserRouter>
      <Switch>
        <AppRoute
          exact
          path={baseUrl + '/'}
          layout={DedicatedDpcLayout}
          component={MultipleLocationLanding}
        />
        <AppRoute
          exact
          path={baseUrl + '/agreement'}
          layout={DedicatedDpcLayout}
          component={DedicatedDpcAgreement}
        />
        <AppRoute layout={MainLayout} component={PageNotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default MultipleLocationLandingRoute;
