import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserFriends, faArrowRight } from '@fortawesome/free-solid-svg-icons';

import {
  ActionConfirmationModal,
  AddClientCard,
  Banner,
  ClientDisplay,
  MultiStepFormWrapper,
} from '../../common';
import { DedicatedFormModal, Loader } from '../../../../common';
import AddGroupMemberForm from './AddGroupMemberForm';
import {
  clearSession,
  getSession,
  setSession,
} from '../../../../util/storageUtil';
import { getFullName } from '../../../../util/vitafyUtil';
import commonConstants from '../../../../constants/commonConstant';
import { showToast } from '../../../../service/toasterService';
import * as clientService from '../../../../service/clientService';
import { store } from '../../../../store';
import { getTextFromLangDict } from '../../../../util';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetAll } from '../../../../stores/membership';

const { MEMBER_TYPE } = commonConstants;

const GroupMembers = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  // Context Store
  const contextStore = useContext(store);
  const { globalState } = contextStore;
  const { groupCode, tenantCode, langDict } = globalState;

  const [showAddMemberModal, setShowAddMemberModal] = useState(false);
  const [primaryClient, setPrimaryClient] = useState();
  const [members, setMembers] = useState([]);
  // Toggle this flag to update the local state for members with session storage
  const [updateMembersToggle, setUpdateMembersToggle] = useState(false);
  const [memberToEdit, setMemberToEdit] = useState(null);
  const [memberToDelete, setMemberToDelete] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigateToHome = () => {
    const homeLink = tenantCode ? `/${tenantCode.toLowerCase()}` : '/';
    history.push(homeLink);
    dispatch(resetAll());
    clearSession();
  };

  // Update the local state with values stored in the session storage
  useEffect(() => {
    try {
      const primaryClientData = JSON.parse(getSession('primaryClient'));
      if (primaryClientData) {
        setPrimaryClient(primaryClientData);
      } else {
        navigateToHome();
      }
    } catch {
      console.log('Error parsing values from the session storage');
    }
  }, []);

  // Update the local state with values stored in the session storage
  useEffect(() => {
    try {
      const groupMembers = JSON.parse(getSession('groupMembers'));
      if (groupMembers) {
        setMembers(groupMembers);
      }
    } catch {
      console.log('Error parsing values from the session storage');
    }
  }, [updateMembersToggle]);

  const onCloseAddGroupMemberModal = () => {
    setShowAddMemberModal(false);
    setMemberToEdit(null);
  };

  const onEditMemberClick = (member) => {
    setMemberToEdit(member);
    setShowAddMemberModal(true);
  };

  const onDeleteMemberClick = (member) => {
    setMemberToDelete(member);
  };

  const updateSession = (clientIdList) => {
    try {
      const groupMembers = JSON.parse(getSession('groupMembers'));
      let updatedGroupMembers = groupMembers?.filter(
        (item) => !clientIdList.some((id) => id === item.clientId)
      );
      setSession('groupMembers', updatedGroupMembers);
      setUpdateMembersToggle((prevState) => !prevState);
    } catch {
      console.log('Error parsing values from the session storage');
    }
  };

  const onConfirmDeleteMember = async () => {
    let clientIds = memberToDelete.clientId;

    const isPrimaryGroupMember =
      memberToDelete.relationship === MEMBER_TYPE.PRIMARY;

    if (isPrimaryGroupMember) {
      const dependentsOfPrimary = members?.filter(
        (item) => item.parentId === memberToDelete.clientId
      );
      clientIds = dependentsOfPrimary.reduce((total, current) => {
        return total + `,${current.clientId}`;
      }, clientIds);
    }

    try {
      setIsSubmitting(true);
      await clientService.removeClient(clientIds);
      updateSession(clientIds.split(',')); // 'a','b','c' -> ['a', 'b', 'c']
      showToast(
        'success',
        `Successfully deleted ${getFullName(memberToDelete)} ${
          isPrimaryGroupMember && 'and dependents'
        }`
      );
      setMemberToDelete(null); // closes the modal
    } catch (err) {
      showToast('error', err.response.data.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const showDeleteConfirmationModal = !!memberToDelete?.clientId;

  const getDeleteConfirmationModalContent = () => {
    if (!memberToDelete) return '';

    let text = getTextFromLangDict(langDict, {
      key: '_DELETE_MEMBER_CONFIRMATION',
      groupCode,
      tenantCode,
    })?.replace('{{name}}', getFullName(memberToDelete));

    if (memberToDelete.relationship === MEMBER_TYPE.PRIMARY) {
      return getTextFromLangDict(langDict, {
        key: '_DELETE_PRIMARY_MEMBER_CONFIRMATION',
        groupCode,
        tenantCode,
      })
        ?.replace('{{name}}', getFullName(memberToDelete))
        ?.replace('{{firstName}}', memberToDelete?.firstName);
    }

    return text;
  };

  const renderedHeader = (
    <div className="flex items-center">
      <FontAwesomeIcon icon={faUserFriends} />
      <h6 className="font-heebo font-bold text-sm md:text-base text-titleColor ml-2">
        {getTextFromLangDict(langDict, {
          key: '_GROUP_MEMBERS',
          groupCode,
          tenantCode,
        })}{' '}
        - {primaryClient?.businessName || ''}
      </h6>
    </div>
  );

  const encloseInContainer = true;

  if (!primaryClient) {
    return <Loader />;
  }

  return (
    <>
      <Banner activeService="membership" />
      <div className="mt-10">
        <MultiStepFormWrapper
          align="left"
          header={renderedHeader}
          displayHeader
          encloseInContainer
        >
          <div>
            <div className="flex flex-wrap -mx-3">
              {members?.map((member) => {
                const relationship =
                  member.relationship === MEMBER_TYPE.PRIMARY
                    ? member.relationship
                    : `${member.relationship} of ${member.parentName}`;
                return (
                  <div
                    className="w-full md:w-1/2 px-3 mb-4 md:mb-6"
                    key={member.clientId}
                  >
                    <ClientDisplay
                      fullName={getFullName({
                        firstName: member.firstName,
                        lastName: member.lastName,
                      })}
                      email={member.email}
                      phone={member.phone}
                      countryCode={member?.countryCode}
                      relationship={relationship}
                      gender={member.gender}
                      dob={member.dob}
                      address={
                        member.address || {
                          addressLine1: member.addressLine1,
                          addressLine2: member.addressLine2,
                          city: member.city,
                          state: member.state,
                          zip: member.zip,
                        }
                      }
                      bloodGroup={member.bloodGroup}
                      isPrimary={member.relationship === MEMBER_TYPE.PRIMARY}
                      whiteBg
                      isEditable
                      onEditClick={() => onEditMemberClick(member)}
                      onDeleteClick={() => onDeleteMemberClick(member)}
                    />
                  </div>
                );
              })}
              <div className="w-full md:w-1/2 px-3 mb-4 md:mb-6">
                <AddClientCard
                  onAddClick={() => setShowAddMemberModal(true)}
                  whiteBg
                  titleDictKey="_WOULD_YOU_LIKE_TO_ADD_MEMBER_OR_DEPENDENT"
                  buttonTextDictKey="_ADD_MEMBER_OR_DEPENDENT"
                />
              </div>
            </div>

            <p className="body-text text-sm mb-4">
              {getTextFromLangDict(langDict, {
                key: '_ADD_GROUP_MEMBERS_NOTE',
                groupCode,
                tenantCode,
              })}
            </p>
          </div>
        </MultiStepFormWrapper>
        <div
          className={`container ${
            encloseInContainer && 'px-4'
          } mx-auto mt-6 mb-16`}
        >
          <div
            className={`flex flex-wrap md:flex-row-reverse justify-between items-center ${
              encloseInContainer && 'dedicated-container-margin'
            }`}
          >
            <button
              data-cy="done"
              className="mid-button w-full md:w-auto mb-2 md:mb-0 flex justify-center items-center
                  bg-dedicatedDpcSecondary hover:bg-dedicatedDpcHovered"
              onClick={navigateToHome}
            >
              <span>
                {getTextFromLangDict(langDict, {
                  key: '_ADD_MEMBER_COMPLETION_BUTTON_TEXT',
                  groupCode,
                  tenantCode,
                })}
              </span>
              <FontAwesomeIcon
                icon={faArrowRight}
                className="text-white ml-3"
              />
            </button>
          </div>
        </div>
      </div>

      {showAddMemberModal && (
        <DedicatedFormModal
          modalTitle={getTextFromLangDict(langDict, {
            key: memberToEdit?.clientId ? '_EDIT_MEMBER' : '_ADD_MEMBER',
            groupCode,
            tenantCode,
          })}
          reset={onCloseAddGroupMemberModal}
          show
        >
          <AddGroupMemberForm
            primaryClient={primaryClient}
            members={members}
            data={memberToEdit}
            closeModal={onCloseAddGroupMemberModal}
            refreshMemberList={() =>
              setUpdateMembersToggle((prevState) => !prevState)
            }
          />
        </DedicatedFormModal>
      )}

      {showDeleteConfirmationModal && (
        <ActionConfirmationModal
          modalTitle={getTextFromLangDict(langDict, {
            key: '_DELETE_MEMBER',
            groupCode,
            tenantCode,
          })}
          modalContent={getDeleteConfirmationModalContent()}
          onConfirm={onConfirmDeleteMember}
          onCancel={() => setMemberToDelete(null)}
          isSubmitting={isSubmitting}
        />
      )}
    </>
  );
};

export default GroupMembers;
