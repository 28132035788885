import commonConstants from '../constants/commonConstant';

const {
  TENANT_SETTINGS_STATUS,
  TENANT_SETTINGS_TYPE,
  AFFILIATE_TENANT_SETTINGS_STATUS,
  TENANT_SETTINGS_CODE,
} = commonConstants;

// works with those settings which are radio-type with options 'Enabled' or 'Disabled'
export const checkEnrollmentTenantSetting = (code, { tenantSettings }) => {
  return (
    tenantSettings?.enrollment?.find((item) => item.code === code)?.status ===
      TENANT_SETTINGS_STATUS.ENABLED || false
  );
};

// outputs an array in case of multi-select types, simple string in case of radio and text types
export const getEnrollmentTenantSetting = (code, { tenantSettings }) => {
  const setting = tenantSettings?.enrollment?.find(
    (item) => item.code === code
  );
  if (!setting) return null;

  if (setting.type === TENANT_SETTINGS_TYPE.MULTI_SELECT) {
    return setting.status.split(',').map((item) => item.trim());
  }

  return setting.status;
};

// outputs an array in case of multi-select types, simple string in case of radio and text types
export const getMiscTenantSetting = (code, { tenantSettings }) => {
  const setting = tenantSettings?.miscellaneous?.find(
    (item) => item.code === code
  );
  if (!setting) return null;

  if (setting.type === TENANT_SETTINGS_TYPE.MULTI_SELECT) {
    return setting.status.split(',').map((item) => item.trim());
  }

  return setting.status;
};

export const checkEnrollmentAffiliateVisibleTenantSetting = (
  tenantSettings
) => {
  const status = tenantSettings?.enrollment?.find(
    (item) => item.code === TENANT_SETTINGS_CODE.AFFILIATE_VISIBILITY
  )?.status;

  return [
    AFFILIATE_TENANT_SETTINGS_STATUS.Both,
    AFFILIATE_TENANT_SETTINGS_STATUS.Website,
  ].includes(status);
};
