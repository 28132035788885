/**
 * @author Ayush Bajracharya <ayush@noveltytechnology.com>
 */

import * as httpService from './httpService';
import APICONSTANTS from '../constants';


export function fetchPricingByProviderId(providerId) {
  return httpService.get(`${APICONSTANTS.DPC_PRICING}/providers/${providerId}`)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    })
}

export function calculatePriceForPmsEnrollment(data) {
  return httpService.post(`${APICONSTANTS.PMS_ENROLLMENT}/calculate-price-new`, data)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    })
}
