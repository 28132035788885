/**
 * @author Slesha Tuladhar <slesha@noveltytechnology.com>
 */

import * as httpService from './httpService';
import APICONSTANTS from '../constants';

export function searchLocation(searchKeyword) {
  return httpService.get(`${APICONSTANTS.LOCATION}/${searchKeyword}`)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    })
}