import React from 'react';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonLoader } from '../../common';
import usePrevNextControl from './usePrevNextControl';

const PrevNextControl = ({ handleQuestionnaireSubmitSuccess }) => {
  const {
    showNextButton,
    isNextButtonEnabled,
    onPrevClick,
    onNextClick,
    isSubmitting,
  } = usePrevNextControl({ handleQuestionnaireSubmitSuccess });

  return (
    <div
      className={`flex items-center ${
        showNextButton ? 'justify-between' : 'justify-center'
      } `}
    >
      <button
        className={`mid-button px-4 py-3 bg-transparent text-titleColor hover:bg-gray-300 ${
          isSubmitting && 'btn-disabled'
        }`}
        type="button"
        onClick={onPrevClick}
        disabled={isSubmitting}
      >
        <FontAwesomeIcon className="text-titleColor mr-2" icon={faArrowLeft} />
        back
      </button>

      {showNextButton && (
        <button
          className={`mid-button bg-dedicatedDpcSecondary hover:bg-dedicatedDpcHovered flex justify-center items-center ${
            !isNextButtonEnabled() && 'btn-disabled'
          }`}
          type="button"
          onClick={onNextClick}
          disabled={!isNextButtonEnabled()}
        >
          {isSubmitting ? (
            <div className="inline mr-2">
              <ButtonLoader />
            </div>
          ) : null}
          <span>Next</span>
          <FontAwesomeIcon className="ml-2" icon={faArrowRight} />
        </button>
      )}
    </div>
  );
};

export default PrevNextControl;
