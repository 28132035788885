import React from 'react';
import { Switch, BrowserRouter, Route } from 'react-router-dom';
import AppRoute from './appRoute';
import MainLayout from '../layouts/MainLayout';
import DedicatedTenantLayout from '../layouts/DedicatedTenantLayout';

import { Redirect } from '../common';
import {
  DedicatedTenant,
  GroupMembers,
  DedicatedDpcAgreement,
  PageNotFound,
} from '../components';

const DedicatedTenantRoute = ({ tenantCode }) => {
  const baseUrl = process.env.PUBLIC_URL;

  return (
    <BrowserRouter>
      <Switch>
        {/* For redirecting old agent referral link to the new one */}
        <Route
          exact
          path={baseUrl + '/code/:agentCode'}
          render={(props) => (
            <Redirect
              redirectPath={`/${tenantCode}?referredby=${props.match.params.agentCode}`}
            />
          )}
        />

        <Route
          exact
          path={baseUrl + '/'}
          render={() => <Redirect redirectPath={`/${tenantCode}`} />}
        />

        <AppRoute
          path={baseUrl + '/:tenantCode/service/membership/group-members'}
          layout={DedicatedTenantLayout}
          component={GroupMembers}
        />

        <AppRoute
          exact
          path={baseUrl + '/:tenantCode/agreement'}
          layout={DedicatedTenantLayout}
          component={DedicatedDpcAgreement}
        />

        <AppRoute
          exact
          path={baseUrl + '/:tenantCode'}
          layout={DedicatedTenantLayout}
          component={DedicatedTenant}
        />
        {/* <AppRoute
          exact
          path={baseUrl + '/code/:code'}
          layout={DedicatedTenantLayout}
          component={DedicatedTenant}
        /> */}
        <AppRoute layout={MainLayout} component={PageNotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default DedicatedTenantRoute;
