import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FilledInput = ({ input, setInput, placeholder, type = 'text', id = 'filled-input', iconName }) => {
  return (
    <div className='relative'>
      <FontAwesomeIcon
        icon={iconName}
        className='filled-input-icon'
      />
      <input
        id={id}
        type={type}
        placeholder={placeholder}
        className='filled-input focus:outline-none'
        input={input}
        onChange={setInput}
      />
    </div>
  );
}

export default FilledInput;