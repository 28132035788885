import React, { useContext } from 'react';

import { Confirmation } from '../common';
import Service from './service';
import PersonalInfo from './personal-info';
import Checkout from './checkout';
import InstructionForm from './InstructionForm';
import { store } from '../../../store';
import { getTextFromLangDict } from '../../../util';

const FormBody = (props) => {
  const { step, data, info, handleAddUpdateClient, setIsSubmitting } = props;

  // Context Store
  const state = useContext(store);
  const { globalState } = state;
  const { groupCode, langDict, tenantCode } = globalState;

  return (
    <>
      {step === 1 && (
        <Service
          data={data}
          selectedServices={data.selectedServices}
          services={props.services}
          handleSelectService={props.handleSelectService}
          priceForOthers={props.priceForOthers}
          changePriceForOthers={props.changePriceForOthers}
          othersPriceInputError={props.othersPriceInputError}
          handleIsMemberCheckboxClick={props.handleIsMemberCheckboxClick}
        />
      )}
      {step === 2 && (
        <PersonalInfo
          data={data}
          personalInfoFormRef={props.personalInfoFormRef}
          handleChangeIHaveNoInsurance={props.handleChangeIHaveNoInsurance}
          handleAddUpdateClient={handleAddUpdateClient}
          setIsSubmitting={setIsSubmitting}
        />
      )}
      {step === 3 && (
        <Checkout
          data={data}
          changePaymentMethod={props.changePaymentMethod}
          handleChangeIAgreeTerms={props.handleChangeIAgreeTerms}
          handleChangeIAgreeDedicatedDpcTerms={
            props.handleChangeIAgreeDedicatedDpcTerms
          }
          cardErrorMessage={props.cardErrorMessage}
          clearCardErrorMessage={props.clearCardErrorMessage}
          bankErrorMessage={props.bankErrorMessage}
          clearBankErrorMessage={props.clearBankErrorMessage}
          tmpPaymentData={props.tmpPaymentData}
          handleChangeTmpPaymentData={props.handleChangeTmpPaymentData}
          errorMessage={props.errorMessage}
          handleRemoveErrorMessage={props.handleRemoveErrorMessage}
          isSubmitting={props.isSubmitting}
          submit={props.submit}
          tenantSettings={props.tenantSettings}
          setGatewayType={props.setGatewayType}
        />
      )}
      {step === 4 && (
        <Confirmation
          email={data.email}
          title={getTextFromLangDict(langDict, {
            key: '_ADDITIONAL_SERVICES_CONFIRMATION_TITLE',
            groupCode,
            tenantCode,
          })}
          type="walk-in"
          displayName={info.displayName}
          removeHomeLink={true}
        >
          {data.transactionId && (
            <InstructionForm transactionId={data.transactionId} />
          )}
        </Confirmation>
      )}
    </>
  );
};

export default FormBody;
