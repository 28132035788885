import React, { useContext } from 'react';
import { FormErrorMessage } from '../../../common';
import { store } from '../../../store';
import { getTextFromLangDict } from '../../../util';

const MembershipStatusMessage = ({ membershipActive, transactionSuccess }) => {
  // Context Store
  const state = useContext(store);
  const { globalState } = state;
  const { groupCode, langDict, tenantCode } = globalState;

  const membershipInactiveMessage = getTextFromLangDict(langDict, {
    key: '_MEMBERSHIP_INACTIVE',
    tenantCode,
    groupCode,
  });

  const lastTransactionFailedMessage = getTextFromLangDict(langDict, {
    key: '_LAST_TRANSACTION_FAILED',
    tenantCode,
    groupCode,
  });

  if (!membershipActive) {
    return (
      <div className="my-4">
        <FormErrorMessage type="warning" message={membershipInactiveMessage} />
      </div>
    );
  }

  // if (membershipActive && !transactionSuccess) {
  //   return (
  //     <div className="my-4">
  //       <FormErrorMessage
  //         type="warning"
  //         message={lastTransactionFailedMessage}
  //       />
  //     </div>
  //   );
  // }

  return <></>;
};

export default MembershipStatusMessage;
