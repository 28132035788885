import React from 'react';
import avatarPlaceholder from '../../assets/images/physician-avatar-bg.png';

const AssociatedPhysicians = (props) => {
  const { physicians } = props;
  return (
    <>
      <h4 className='text-lg mb-5'>Provider(s)</h4>
      <div className='flex flex-wrap -mx-2'>
        {physicians.map((physician, index) => (
          <div
            className={`w-1/2 lg:w-1/3 xl:w-1/4 px-2 xl:mb-12 lg:mb-8 mb-6
              }`}
            key={index}
          >
            <div className='w-full rounded-sm shadow'>
              <div className='w-full pb-full rounded-t-sm relative'>
                <img
                  src={physician.imageUrl ? physician.imageUrl : avatarPlaceholder}
                  className='absolute w-full h-full object-cover object-center rounded-t-lg'
                  alt='physician-profile-img'
                  onError={e => { e.target.onerror = null; e.target.src = `${avatarPlaceholder}` }}
                />
              </div>

              <div className='py-4 px-3'>
                <h6 className='body-text text-titleColor text-sm font-bold'>{physician.displayName}</h6>
                <div >
                <p className='body-text text-sm text-truncate' >{physician.taxonomyDescription}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {physicians && physicians.length < 1 &&
        <div className="body-text">No information available</div>
      }
    </>
  );
}

export default AssociatedPhysicians;