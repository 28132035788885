const countryCodesAndphoneExt = [
  {
    countryName: 'United States',
    phoneExt: '1',
    countryCode: 'US',
  },
  {
    countryName: 'Canada',
    phoneExt: '1',
    countryCode: 'CA',
  },
  {
    countryName: 'Afghanistan',
    phoneExt: '93',
    countryCode: 'AF',
  },
  {
    countryName: 'Aland Islands',
    phoneExt: '358',
    countryCode: 'AX',
  },
  {
    countryName: 'Albania',
    phoneExt: '355',
    countryCode: 'AL',
  },
  {
    countryName: 'Algeria',
    phoneExt: '213',
    countryCode: 'DZ',
  },
  {
    countryName: 'AmericanSamoa',
    phoneExt: '1684',
    countryCode: 'AS',
  },
  {
    countryName: 'Andorra',
    phoneExt: '376',
    countryCode: 'AD',
  },
  {
    countryName: 'Angola',
    phoneExt: '244',
    countryCode: 'AO',
  },
  {
    countryName: 'Anguilla',
    phoneExt: '1264',
    countryCode: 'AI',
  },
  {
    countryName: 'Antigua and Barbuda',
    phoneExt: '1268',
    countryCode: 'AG',
  },
  {
    countryName: 'Argentina',
    phoneExt: '54',
    countryCode: 'AR',
  },
  {
    countryName: 'Armenia',
    phoneExt: '374',
    countryCode: 'AM',
  },
  {
    countryName: 'Aruba',
    phoneExt: '297',
    countryCode: 'AW',
  },
  {
    countryName: 'Australia',
    phoneExt: '61',
    countryCode: 'AU',
  },
  {
    countryName: 'Austria',
    phoneExt: '43',
    countryCode: 'AT',
  },
  {
    countryName: 'Azerbaijan',
    phoneExt: '994',
    countryCode: 'AZ',
  },
  {
    countryName: 'Bahamas',
    phoneExt: '1242',
    countryCode: 'BS',
  },
  {
    countryName: 'Bahrain',
    phoneExt: '973',
    countryCode: 'BH',
  },
  {
    countryName: 'Bangladesh',
    phoneExt: '880',
    countryCode: 'BD',
  },
  {
    countryName: 'Barbados',
    phoneExt: '1246',
    countryCode: 'BB',
  },
  {
    countryName: 'Belarus',
    phoneExt: '375',
    countryCode: 'BY',
  },
  {
    countryName: 'Belgium',
    phoneExt: '32',
    countryCode: 'BE',
  },
  {
    countryName: 'Belize',
    phoneExt: '501',
    countryCode: 'BZ',
  },
  {
    countryName: 'Benin',
    phoneExt: '229',
    countryCode: 'BJ',
  },
  {
    countryName: 'Bermuda',
    phoneExt: '1441',
    countryCode: 'BM',
  },
  {
    countryName: 'Bhutan',
    phoneExt: '975',
    countryCode: 'BT',
  },
  {
    countryName: 'Bolivia, Plurinational State of',
    phoneExt: '591',
    countryCode: 'BO',
  },
  {
    countryName: 'Bosnia and Herzegovina',
    phoneExt: '387',
    countryCode: 'BA',
  },
  {
    countryName: 'Botswana',
    phoneExt: '267',
    countryCode: 'BW',
  },
  {
    countryName: 'Brazil',
    phoneExt: '55',
    countryCode: 'BR',
  },
  {
    countryName: 'British Indian Ocean Territory',
    phoneExt: '246',
    countryCode: 'IO',
  },
  {
    countryName: 'Brunei Darussalam',
    phoneExt: '673',
    countryCode: 'BN',
  },
  {
    countryName: 'Bulgaria',
    phoneExt: '359',
    countryCode: 'BG',
  },
  {
    countryName: 'Burkina Faso',
    phoneExt: '226',
    countryCode: 'BF',
  },
  {
    countryName: 'Burundi',
    phoneExt: '257',
    countryCode: 'BI',
  },
  {
    countryName: 'Cambodia',
    phoneExt: '855',
    countryCode: 'KH',
  },
  {
    countryName: 'Cameroon',
    phoneExt: '237',
    countryCode: 'CM',
  },

  {
    countryName: 'Cape Verde',
    phoneExt: '238',
    countryCode: 'CV',
  },
  {
    countryName: 'Cayman Islands',
    phoneExt: ' 345',
    countryCode: 'KY',
  },
  {
    countryName: 'Central African Republic',
    phoneExt: '236',
    countryCode: 'CF',
  },
  {
    countryName: 'Chad',
    phoneExt: '235',
    countryCode: 'TD',
  },
  {
    countryName: 'Chile',
    phoneExt: '56',
    countryCode: 'CL',
  },
  {
    countryName: 'China',
    phoneExt: '86',
    countryCode: 'CN',
  },
  {
    countryName: 'Christmas Island',
    phoneExt: '61',
    countryCode: 'CX',
  },
  {
    countryName: 'Cocos (Keeling) Islands',
    phoneExt: '61',
    countryCode: 'CC',
  },
  {
    countryName: 'Colombia',
    phoneExt: '57',
    countryCode: 'CO',
  },
  {
    countryName: 'Comoros',
    phoneExt: '269',
    countryCode: 'KM',
  },
  {
    countryName: 'Congo',
    phoneExt: '242',
    countryCode: 'CG',
  },
  {
    countryName: 'Congo, The Democratic Republic of the Congo',
    phoneExt: '243',
    countryCode: 'CD',
  },
  {
    countryName: 'Cook Islands',
    phoneExt: '682',
    countryCode: 'CK',
  },
  {
    countryName: 'Costa Rica',
    phoneExt: '506',
    countryCode: 'CR',
  },
  {
    countryName: "Cote d'Ivoire",
    phoneExt: '225',
    countryCode: 'CI',
  },
  {
    countryName: 'Croatia',
    phoneExt: '385',
    countryCode: 'HR',
  },
  {
    countryName: 'Cuba',
    phoneExt: '53',
    countryCode: 'CU',
  },
  {
    countryName: 'Cyprus',
    phoneExt: '357',
    countryCode: 'CY',
  },
  {
    countryName: 'Czech Republic',
    phoneExt: '420',
    countryCode: 'CZ',
  },
  {
    countryName: 'Denmark',
    phoneExt: '45',
    countryCode: 'DK',
  },
  {
    countryName: 'Djibouti',
    phoneExt: '253',
    countryCode: 'DJ',
  },
  {
    countryName: 'Dominica',
    phoneExt: '1767',
    countryCode: 'DM',
  },
  {
    countryName: 'Dominican Republic',
    phoneExt: '1849',
    countryCode: 'DO',
  },
  {
    countryName: 'Ecuador',
    phoneExt: '593',
    countryCode: 'EC',
  },
  {
    countryName: 'Egypt',
    phoneExt: '20',
    countryCode: 'EG',
  },
  {
    countryName: 'El Salvador',
    phoneExt: '503',
    countryCode: 'SV',
  },
  {
    countryName: 'Equatorial Guinea',
    phoneExt: '240',
    countryCode: 'GQ',
  },
  {
    countryName: 'Eritrea',
    phoneExt: '291',
    countryCode: 'ER',
  },
  {
    countryName: 'Estonia',
    phoneExt: '372',
    countryCode: 'EE',
  },
  {
    countryName: 'Ethiopia',
    phoneExt: '251',
    countryCode: 'ET',
  },
  {
    countryName: 'Falkland Islands (Malvinas)',
    phoneExt: '500',
    countryCode: 'FK',
  },
  {
    countryName: 'Faroe Islands',
    phoneExt: '298',
    countryCode: 'FO',
  },
  {
    countryName: 'Fiji',
    phoneExt: '679',
    countryCode: 'FJ',
  },
  {
    countryName: 'Finland',
    phoneExt: '358',
    countryCode: 'FI',
  },
  {
    countryName: 'France',
    phoneExt: '33',
    countryCode: 'FR',
  },
  {
    countryName: 'French Guiana',
    phoneExt: '594',
    countryCode: 'GF',
  },
  {
    countryName: 'French Polynesia',
    phoneExt: '689',
    countryCode: 'PF',
  },
  {
    countryName: 'Gabon',
    phoneExt: '241',
    countryCode: 'GA',
  },
  {
    countryName: 'Gambia',
    phoneExt: '220',
    countryCode: 'GM',
  },
  {
    countryName: 'Georgia',
    phoneExt: '995',
    countryCode: 'GE',
  },
  {
    countryName: 'Germany',
    phoneExt: '49',
    countryCode: 'DE',
  },
  {
    countryName: 'Ghana',
    phoneExt: '233',
    countryCode: 'GH',
  },
  {
    countryName: 'Gibraltar',
    phoneExt: '350',
    countryCode: 'GI',
  },
  {
    countryName: 'Greece',
    phoneExt: '30',
    countryCode: 'GR',
  },
  {
    countryName: 'Greenland',
    phoneExt: '299',
    countryCode: 'GL',
  },
  {
    countryName: 'Grenada',
    phoneExt: '1473',
    countryCode: 'GD',
  },
  {
    countryName: 'Guadeloupe',
    phoneExt: '590',
    countryCode: 'GP',
  },
  {
    countryName: 'Guam',
    phoneExt: '1671',
    countryCode: 'GU',
  },
  {
    countryName: 'Guatemala',
    phoneExt: '502',
    countryCode: 'GT',
  },
  {
    countryName: 'Guernsey',
    phoneExt: '44',
    countryCode: 'GG',
  },
  {
    countryName: 'Guinea',
    phoneExt: '224',
    countryCode: 'GN',
  },
  {
    countryName: 'Guinea-Bissau',
    phoneExt: '245',
    countryCode: 'GW',
  },
  {
    countryName: 'Guyana',
    phoneExt: '595',
    countryCode: 'GY',
  },
  {
    countryName: 'Haiti',
    phoneExt: '509',
    countryCode: 'HT',
  },
  {
    countryName: 'Holy See (Vatican City State)',
    phoneExt: '379',
    countryCode: 'VA',
  },
  {
    countryName: 'Honduras',
    phoneExt: '504',
    countryCode: 'HN',
  },
  {
    countryName: 'Hong Kong',
    phoneExt: '852',
    countryCode: 'HK',
  },
  {
    countryName: 'Hungary',
    phoneExt: '36',
    countryCode: 'HU',
  },
  {
    countryName: 'Iceland',
    phoneExt: '354',
    countryCode: 'IS',
  },
  {
    countryName: 'India',
    phoneExt: '91',
    countryCode: 'IN',
  },
  {
    countryName: 'Indonesia',
    phoneExt: '62',
    countryCode: 'ID',
  },
  {
    countryName: 'Iran, Islamic Republic of Persian Gulf',
    phoneExt: '98',
    countryCode: 'IR',
  },
  {
    countryName: 'Iraq',
    phoneExt: '964',
    countryCode: 'IQ',
  },
  {
    countryName: 'Ireland',
    phoneExt: '353',
    countryCode: 'IE',
  },
  {
    countryName: 'Isle of Man',
    phoneExt: '44',
    countryCode: 'IM',
  },
  {
    countryName: 'Israel',
    phoneExt: '972',
    countryCode: 'IL',
  },
  {
    countryName: 'Italy',
    phoneExt: '39',
    countryCode: 'IT',
  },
  {
    countryName: 'Jamaica',
    phoneExt: '1876',
    countryCode: 'JM',
  },
  {
    countryName: 'Japan',
    phoneExt: '81',
    countryCode: 'JP',
  },
  {
    countryName: 'Jersey',
    phoneExt: '44',
    countryCode: 'JE',
  },
  {
    countryName: 'Jordan',
    phoneExt: '962',
    countryCode: 'JO',
  },
  {
    countryName: 'Kazakhstan',
    phoneExt: '77',
    countryCode: 'KZ',
  },
  {
    countryName: 'Kenya',
    phoneExt: '254',
    countryCode: 'KE',
  },
  {
    countryName: 'Kiribati',
    phoneExt: '686',
    countryCode: 'KI',
  },
  {
    countryName: "Korea, Democratic People's Republic of Korea",
    phoneExt: '850',
    countryCode: 'KP',
  },
  {
    countryName: 'Korea, Republic of South Korea',
    phoneExt: '82',
    countryCode: 'KR',
  },
  {
    countryName: 'Kuwait',
    phoneExt: '965',
    countryCode: 'KW',
  },
  {
    countryName: 'Kyrgyzstan',
    phoneExt: '996',
    countryCode: 'KG',
  },
  {
    countryName: 'Laos',
    phoneExt: '856',
    countryCode: 'LA',
  },
  {
    countryName: 'Latvia',
    phoneExt: '371',
    countryCode: 'LV',
  },
  {
    countryName: 'Lebanon',
    phoneExt: '961',
    countryCode: 'LB',
  },
  {
    countryName: 'Lesotho',
    phoneExt: '266',
    countryCode: 'LS',
  },
  {
    countryName: 'Liberia',
    phoneExt: '231',
    countryCode: 'LR',
  },
  {
    countryName: 'Libyan Arab Jamahiriya',
    phoneExt: '218',
    countryCode: 'LY',
  },
  {
    countryName: 'Liechtenstein',
    phoneExt: '423',
    countryCode: 'LI',
  },
  {
    countryName: 'Lithuania',
    phoneExt: '370',
    countryCode: 'LT',
  },
  {
    countryName: 'Luxembourg',
    phoneExt: '352',
    countryCode: 'LU',
  },
  {
    countryName: 'Macao',
    phoneExt: '853',
    countryCode: 'MO',
  },
  {
    countryName: 'Macedonia',
    phoneExt: '389',
    countryCode: 'MK',
  },
  {
    countryName: 'Madagascar',
    phoneExt: '261',
    countryCode: 'MG',
  },
  {
    countryName: 'Malawi',
    phoneExt: '265',
    countryCode: 'MW',
  },
  {
    countryName: 'Malaysia',
    phoneExt: '60',
    countryCode: 'MY',
  },
  {
    countryName: 'Maldives',
    phoneExt: '960',
    countryCode: 'MV',
  },
  {
    countryName: 'Mali',
    phoneExt: '223',
    countryCode: 'ML',
  },
  {
    countryName: 'Malta',
    phoneExt: '356',
    countryCode: 'MT',
  },
  {
    countryName: 'Marshall Islands',
    phoneExt: '692',
    countryCode: 'MH',
  },
  {
    countryName: 'Martinique',
    phoneExt: '596',
    countryCode: 'MQ',
  },
  {
    countryName: 'Mauritania',
    phoneExt: '222',
    countryCode: 'MR',
  },
  {
    countryName: 'Mauritius',
    phoneExt: '230',
    countryCode: 'MU',
  },
  {
    countryName: 'Mayotte',
    phoneExt: '262',
    countryCode: 'YT',
  },
  {
    countryName: 'Mexico',
    phoneExt: '52',
    countryCode: 'MX',
  },
  {
    countryName: 'Micronesia, Federated States of Micronesia',
    phoneExt: '691',
    countryCode: 'FM',
  },
  {
    countryName: 'Moldova',
    phoneExt: '373',
    countryCode: 'MD',
  },
  {
    countryName: 'Monaco',
    phoneExt: '377',
    countryCode: 'MC',
  },
  {
    countryName: 'Mongolia',
    phoneExt: '976',
    countryCode: 'MN',
  },
  {
    countryName: 'Montenegro',
    phoneExt: '382',
    countryCode: 'ME',
  },
  {
    countryName: 'Montserrat',
    phoneExt: '1664',
    countryCode: 'MS',
  },
  {
    countryName: 'Morocco',
    phoneExt: '212',
    countryCode: 'MA',
  },
  {
    countryName: 'Mozambique',
    phoneExt: '258',
    countryCode: 'MZ',
  },
  {
    countryName: 'Myanmar',
    phoneExt: '95',
    countryCode: 'MM',
  },
  {
    countryName: 'Namibia',
    phoneExt: '264',
    countryCode: 'NA',
  },
  {
    countryName: 'Nauru',
    phoneExt: '674',
    countryCode: 'NR',
  },
  {
    countryName: 'Nepal',
    phoneExt: '977',
    countryCode: 'NP',
  },
  {
    countryName: 'Netherlands',
    phoneExt: '31',
    countryCode: 'NL',
  },
  {
    countryName: 'Netherlands Antilles',
    phoneExt: '599',
    countryCode: 'AN',
  },
  {
    countryName: 'New Caledonia',
    phoneExt: '687',
    countryCode: 'NC',
  },
  {
    countryName: 'New Zealand',
    phoneExt: '64',
    countryCode: 'NZ',
  },
  {
    countryName: 'Nicaragua',
    phoneExt: '505',
    countryCode: 'NI',
  },
  {
    countryName: 'Niger',
    phoneExt: '227',
    countryCode: 'NE',
  },
  {
    countryName: 'Nigeria',
    phoneExt: '234',
    countryCode: 'NG',
  },
  {
    countryName: 'Niue',
    phoneExt: '683',
    countryCode: 'NU',
  },
  {
    countryName: 'Norfolk Island',
    phoneExt: '672',
    countryCode: 'NF',
  },
  {
    countryName: 'Northern Mariana Islands',
    phoneExt: '1670',
    countryCode: 'MP',
  },
  {
    countryName: 'Norway',
    phoneExt: '47',
    countryCode: 'NO',
  },
  {
    countryName: 'Oman',
    phoneExt: '968',
    countryCode: 'OM',
  },
  {
    countryName: 'Pakistan',
    phoneExt: '92',
    countryCode: 'PK',
  },
  {
    countryName: 'Palau',
    phoneExt: '680',
    countryCode: 'PW',
  },
  {
    countryName: 'Palestinian Territory, Occupied',
    phoneExt: '970',
    countryCode: 'PS',
  },
  {
    countryName: 'Panama',
    phoneExt: '507',
    countryCode: 'PA',
  },
  {
    countryName: 'Papua New Guinea',
    phoneExt: '675',
    countryCode: 'PG',
  },
  {
    countryName: 'Paraguay',
    phoneExt: '595',
    countryCode: 'PY',
  },
  {
    countryName: 'Peru',
    phoneExt: '51',
    countryCode: 'PE',
  },
  {
    countryName: 'Philippines',
    phoneExt: '63',
    countryCode: 'PH',
  },
  {
    countryName: 'Pitcairn',
    phoneExt: '872',
    countryCode: 'PN',
  },
  {
    countryName: 'Poland',
    phoneExt: '48',
    countryCode: 'PL',
  },
  {
    countryName: 'Portugal',
    phoneExt: '351',
    countryCode: 'PT',
  },
  {
    countryName: 'Puerto Rico',
    phoneExt: '1939',
    countryCode: 'PR',
  },
  {
    countryName: 'Qatar',
    phoneExt: '974',
    countryCode: 'QA',
  },
  {
    countryName: 'Romania',
    phoneExt: '40',
    countryCode: 'RO',
  },
  {
    countryName: 'Russia',
    phoneExt: '7',
    countryCode: 'RU',
  },
  {
    countryName: 'Rwanda',
    phoneExt: '250',
    countryCode: 'RW',
  },
  {
    countryName: 'Reunion',
    phoneExt: '262',
    countryCode: 'RE',
  },
  {
    countryName: 'Saint Barthelemy',
    phoneExt: '590',
    countryCode: 'BL',
  },
  {
    countryName: 'Saint Helena, Ascension and Tristan Da Cunha',
    phoneExt: '290',
    countryCode: 'SH',
  },
  {
    countryName: 'Saint Kitts and Nevis',
    phoneExt: '1869',
    countryCode: 'KN',
  },
  {
    countryName: 'Saint Lucia',
    phoneExt: '1758',
    countryCode: 'LC',
  },
  {
    countryName: 'Saint Martin',
    phoneExt: '590',
    countryCode: 'MF',
  },
  {
    countryName: 'Saint Pierre and Miquelon',
    phoneExt: '508',
    countryCode: 'PM',
  },
  {
    countryName: 'Saint Vincent and the Grenadines',
    phoneExt: '1784',
    countryCode: 'VC',
  },
  {
    countryName: 'Samoa',
    phoneExt: '685',
    countryCode: 'WS',
  },
  {
    countryName: 'San Marino',
    phoneExt: '378',
    countryCode: 'SM',
  },
  {
    countryName: 'Sao Tome and Principe',
    phoneExt: '239',
    countryCode: 'ST',
  },
  {
    countryName: 'Saudi Arabia',
    phoneExt: '966',
    countryCode: 'SA',
  },
  {
    countryName: 'Senegal',
    phoneExt: '221',
    countryCode: 'SN',
  },
  {
    countryName: 'Serbia',
    phoneExt: '381',
    countryCode: 'RS',
  },
  {
    countryName: 'Seychelles',
    phoneExt: '248',
    countryCode: 'SC',
  },
  {
    countryName: 'Sierra Leone',
    phoneExt: '232',
    countryCode: 'SL',
  },
  {
    countryName: 'Singapore',
    phoneExt: '65',
    countryCode: 'SG',
  },
  {
    countryName: 'Slovakia',
    phoneExt: '421',
    countryCode: 'SK',
  },
  {
    countryName: 'Slovenia',
    phoneExt: '386',
    countryCode: 'SI',
  },
  {
    countryName: 'Solomon Islands',
    phoneExt: '677',
    countryCode: 'SB',
  },
  {
    countryName: 'Somalia',
    phoneExt: '252',
    countryCode: 'SO',
  },
  {
    countryName: 'South Africa',
    phoneExt: '27',
    countryCode: 'ZA',
  },
  {
    countryName: 'South Sudan',
    phoneExt: '211',
    countryCode: 'SS',
  },
  {
    countryName: 'South Georgia and the South Sandwich Islands',
    phoneExt: '500',
    countryCode: 'GS',
  },
  {
    countryName: 'Spain',
    phoneExt: '34',
    countryCode: 'ES',
  },
  {
    countryName: 'Sri Lanka',
    phoneExt: '94',
    countryCode: 'LK',
  },
  {
    countryName: 'Sudan',
    phoneExt: '249',
    countryCode: 'SD',
  },
  {
    countryName: 'SuricountryName',
    phoneExt: '597',
    countryCode: 'SR',
  },
  {
    countryName: 'Svalbard and Jan Mayen',
    phoneExt: '47',
    countryCode: 'SJ',
  },
  {
    countryName: 'Swaziland',
    phoneExt: '268',
    countryCode: 'SZ',
  },
  {
    countryName: 'Sweden',
    phoneExt: '46',
    countryCode: 'SE',
  },
  {
    countryName: 'Switzerland',
    phoneExt: '41',
    countryCode: 'CH',
  },
  {
    countryName: 'Syrian Arab Republic',
    phoneExt: '963',
    countryCode: 'SY',
  },
  {
    countryName: 'Taiwan',
    phoneExt: '886',
    countryCode: 'TW',
  },
  {
    countryName: 'Tajikistan',
    phoneExt: '992',
    countryCode: 'TJ',
  },
  {
    countryName: 'Tanzania, United Republic of Tanzania',
    phoneExt: '255',
    countryCode: 'TZ',
  },
  {
    countryName: 'Thailand',
    phoneExt: '66',
    countryCode: 'TH',
  },
  {
    countryName: 'Timor-Leste',
    phoneExt: '670',
    countryCode: 'TL',
  },
  {
    countryName: 'Togo',
    phoneExt: '228',
    countryCode: 'TG',
  },
  {
    countryName: 'Tokelau',
    phoneExt: '690',
    countryCode: 'TK',
  },
  {
    countryName: 'Tonga',
    phoneExt: '676',
    countryCode: 'TO',
  },
  {
    countryName: 'Trinidad and Tobago',
    phoneExt: '1868',
    countryCode: 'TT',
  },
  {
    countryName: 'Tunisia',
    phoneExt: '216',
    countryCode: 'TN',
  },
  {
    countryName: 'Turkey',
    phoneExt: '90',
    countryCode: 'TR',
  },
  {
    countryName: 'Turkmenistan',
    phoneExt: '993',
    countryCode: 'TM',
  },
  {
    countryName: 'Turks and Caicos Islands',
    phoneExt: '1649',
    countryCode: 'TC',
  },
  {
    countryName: 'Tuvalu',
    phoneExt: '688',
    countryCode: 'TV',
  },
  {
    countryName: 'Uganda',
    phoneExt: '256',
    countryCode: 'UG',
  },
  {
    countryName: 'Ukraine',
    phoneExt: '380',
    countryCode: 'UA',
  },
  {
    countryName: 'United Arab Emirates',
    phoneExt: '971',
    countryCode: 'AE',
  },
  {
    countryName: 'United Kingdom',
    phoneExt: '44',
    countryCode: 'GB',
  },

  {
    countryName: 'Uruguay',
    phoneExt: '598',
    countryCode: 'UY',
  },
  {
    countryName: 'Uzbekistan',
    phoneExt: '998',
    countryCode: 'UZ',
  },
  {
    countryName: 'Vanuatu',
    phoneExt: '678',
    countryCode: 'VU',
  },
  {
    countryName: 'Venezuela, Bolivarian Republic of Venezuela',
    phoneExt: '58',
    countryCode: 'VE',
  },
  {
    countryName: 'Vietnam',
    phoneExt: '84',
    countryCode: 'VN',
  },
  {
    countryName: 'Virgin Islands, British',
    phoneExt: '1284',
    countryCode: 'VG',
  },
  {
    countryName: 'Virgin Islands, U.S.',
    phoneExt: '1340',
    countryCode: 'VI',
  },
  {
    countryName: 'Wallis and Futuna',
    phoneExt: '681',
    countryCode: 'WF',
  },
  {
    countryName: 'Yemen',
    phoneExt: '967',
    countryCode: 'YE',
  },
  {
    countryName: 'Zambia',
    phoneExt: '260',
    countryCode: 'ZM',
  },
  {
    countryName: 'Zimbabwe',
    phoneExt: '263',
    countryCode: 'ZW',
  },
];

export { countryCodesAndphoneExt };
