import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const StepIndicator = (props) => {
  const { activeStep, steps = [] } = props;

  return (
    <div className="flex items-center">
      {steps &&
        steps.map((step, index) => (
          <React.Fragment key={index}>
            <div className={`dash ${index === 0 && 'hidden'}`}></div>
            <div className="flex flex-col md:flex-row justify-center md:justify-start items-center">
              <FontAwesomeIcon
                icon={faCheckCircle}
                className={`text-sm md:text-lg md:mr-2 mb-1 md:mb-0 ${
                  activeStep >= index + 1
                    ? 'text-holyCityMedSuccess'
                    : 'text-bodyColor opacity-75'
                }`}
              />
              <h6
                className={`font-heebo font-bold text-sm md:text-base text-center md:text-left ${
                  activeStep >= index + 1
                    ? 'text-titleColor'
                    : 'text-bodyColor opacity-75'
                }`}
              >
                {step}
              </h6>
            </div>
          </React.Fragment>
        ))}
    </div>
  );
};

export default StepIndicator;
