import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { RequestDemo } from '../common';
import { parseHtml } from '../util';

const CallToAction = (props) => {
  const {
    title,
    description,
    actionButtonText,
    actionButtonLink,
    requestDemo,
    modalTitle,
  } = props;
  const requestDemoModal = React.useRef(null);
  return (
    <div className="bg-bgColor w-full">
      <div className="divider"></div>
      <div className="container mx-auto px-4">
        <h3
          className={`heading-center text-xl md:text-2xl xl:text-3xl md:px-10 xl:px-0 w-full text-center ${
            description ? 'mb-6' : 'mb-0'
          }`}
        >
          {title}
        </h3>
        <div
          className={`body-text text-xl leading-relaxed text-center mb-2`}
          dangerouslySetInnerHTML={{ __html: parseHtml(description) }}
        />

        {requestDemo ? (
          <button
            onClick={() => requestDemoModal.current.toggle()}
            className="big-button block no-underline focus:outline-none cursor-pointer mx-auto"
          >
            {actionButtonText}
            <FontAwesomeIcon
              icon={faArrowRight}
              className="inline-block ml-2 "
            />
          </button>
        ) : (
          <>
            <div className="w-full flex justify-center mt-6">
              <a
                href={actionButtonLink}
                // target='_blank'
                className="big-button inline-block no-underline focus:outline-none cursor-pointer lg:mt-0"
              >
                {actionButtonText}
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="inline-block ml-2 "
                />
              </a>
            </div>
            {/* {routeAlias && routeAlias === routeAliasConstant.VCARE ?
              <button
                onClick={() => history.push('/vcare/enroll', { from: routeAliasConstant.VCARE })}
                className='big-button block no-underline focus:outline-none cursor-pointer mx-auto'>
                {actionButtonText}
                <FontAwesomeIcon icon={faArrowRight} className='inline-block ml-2 ' />
              </button>
              :
              <div className='w-full flex justify-center'>
                <a
                  href={actionButtonLink}
                  // target='_blank'
                  className='big-button inline-block no-underline focus:outline-none cursor-pointer lg:mt-0'
                >
                  {actionButtonText}
                  <FontAwesomeIcon icon={faArrowRight} className='inline-block ml-2 ' />
                </a>
              </div>
            } */}
          </>
        )}
      </div>
      <div className="divider"></div>
      <RequestDemo formModal={requestDemoModal} modalTitle={modalTitle} />
    </div>
  );
};

export default CallToAction;
