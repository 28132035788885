import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { store } from '../../store';

import * as vitafyUtils from '../../util/vitafyUtil';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { getPortalLink } from '../../util/miscellaneousUtil';
import { resetAll } from '../../stores/membership';

const DedicatedDpcHeader = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const state = useContext(store);
  const { globalState } = state;
  const { isLoading, primaryLocation, langDict, tenantCode } = globalState;

  if (isLoading || !primaryLocation || !langDict) return null;

  const { address, website, businessName, code, metaData } = primaryLocation;

  const portalLink = getPortalLink();

  const onHomeClick = () => {
    const homeLink = tenantCode ? `/${tenantCode.toLowerCase()}` : '/';
    history.push(homeLink);
    dispatch(resetAll());
  };

  return (
    <nav
      className={`transition duration-500 ease-in-out fixed flex-none md:flex items-center justify-between px-4 py-3 top-0 w-full z-40 bg-white shadow`}
    >
      <div className="container mx-auto flex-none md:flex justify-between items-center">
        <div className="z-50 flex justify-center items-center mb-4 md:mb-0">
          <img
            src={metaData?.imageUrl}
            className="block mr-4 h-16 md:h-16 cursor-pointer"
            alt={`${businessName}-logo`}
            onClick={onHomeClick}
          />
          {address && (
            <h6 className="body-text block sm:hidden text-xs md:text-sm text-bodyColor font-normal m-0 md:ml-3">
              {vitafyUtils.getAddressDisplayName(address, true)}
            </h6>
          )}
        </div>

        <div className="flex justify-center items-center">
          {address && (
            <h6 className="body-text hidden sm:block text-xs md:text-sm text-bodyColor font-normal m-0 md:ml-3">
              {vitafyUtils.getAddressDisplayName(address, true)}
            </h6>
          )}
          {website && (
            <>
              <div className="ml-3 mr-3 w-1px h-8 bg-bgColorDarker hidden md:block"></div>
              <a
                href={
                  website
                    ? website.includes('https://' || 'http://')
                      ? website
                      : 'http://' + website
                    : ''
                }
                target="_blank"
                rel="noopener noreferrer"
                className="block body-text text-titleColor focus:outline-none no-underline hover:text-dedicatedDpcPrimary"
              >
                {langDict._ABOUT}
              </a>
            </>
          )}
          <div className="ml-3 mr-3 w-1px h-8 bg-bgColorDarker"></div>
          <a
            href={`${portalLink}/login?client=${code}`}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block rounded-md body-text text-dedicatedDpcPrimary text-sm px-3 py-2 hover:bg-gray-200 no-underline focus:outline-none border border-dedicatedDpcPrimary border-opacity-100"
          >
            <FontAwesomeIcon icon={faSignInAlt} className="inline-block mr-1" />{' '}
            {langDict._LOGIN}
          </a>
        </div>
      </div>
    </nav>
  );
};

export default DedicatedDpcHeader;
