import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import withSizes from 'react-sizes';
import {
  faUser,
  faCalendarAlt,
  faEnvelope,
  faMobileAlt
} from '@fortawesome/free-solid-svg-icons';

import {
  MDInput,
  MDTextArea,
  MaskedDateInput,
  MaskedTimeInput,
  MaskedPhoneInput
} from '../../../common';

import * as momentUtil from "../../../util/momentUtil";
import * as vitafyUtils from "../../../util/vitafyUtil";

const AppointmentForm = (props) => {
  const {
    register,
    errors,
    setValue,
    clearError,
    handleSubmit,
    reset,
  } = useForm();
  const {
    data
  } = props;

  const [dayShift, setDayShift] = useState('AM');

  useEffect(() => {
    formBuilder();
  }, []);

  const formBuilder = () => {
    register({ name: "firstName" }, { required: true });
    register({ name: "lastName" }, { required: true });
    register(
      { name: "requestedDate" },
      {
        validate: (value) =>
          value
            ? momentUtil.momentIsSame(value, 'day') || momentUtil.momentIsSameorAfter(value, 'day')
            : true,
        required: true
      }
    );
    register({ name: 'requestedTime' },
      {
        validate: (value) => vitafyUtils.validateTimeInput(value),
        required: true
      });
    register(
      { name: "email" },
      {
        required: true,
        pattern: /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/i,
      }
    );
    register({ name: "phone" },
      {
        validate: (value) => {
          if (!value) return true;
          return !value.includes("_");
        },
        required: true
      }
    );
    register({ name: "note" }, { required: true });
    setValue([
      { firstName: data.firstName },
      { lastName: data.lastName },
      { requestedDate: data.requestedDate },
      { requestedTime: data.requestedTime },
      { email: data.email ? data.email : "" },
      { phone: data.phone },
      { note: data.note },
    ]);
  };

  const handleInputChange = (e, field) => {
    let value = e.target ? e.target.value : e;
    if ((e.target ? e.target.validity.valid : e) || value === "") {
      setValue(field, value);
      data[field] = value;
      clearError(field);
    }
  };

  const formSubmit = (formValues) => {
    formValues.firstName = formValues.firstName && formValues.firstName.trim();
    formValues.lastName = formValues.lastName && formValues.lastName.trim();
    formValues.email = formValues.email && formValues.email.trim();
    formValues.requestedDate = data.requestedDate
      ? momentUtil.momentViewFormat(data.requestedDate)
      : null;
    formValues.phone = formValues.phone
      ? vitafyUtils.unmaskPhone(formValues.phone)
      : null;

    const requestedDate = formValues.requestedDate;
    const requestedTime = formValues.requestedTime;
    if (requestedDate && requestedTime) {
      let time = requestedTime.split(' ')[0];
      let meridian = requestedTime.split(' ')[1];
      formValues.requestedDate = momentUtil.localToUtc(new Date(requestedDate.split('/')[2]//Year => MM/DD/yyyy
        , requestedDate.split('/')[0] - 1//Month
        , requestedDate.split('/')[1]//Day of the month
        , (+time.split(':')[0] - ((+time.split(':')[0] > 12) ? 12 : 0)) + +((meridian && meridian.toLowerCase() == 'pm' && +time.split(':')[0] < 12) ? 12 : 0)//Hours // time => 12:34 PM
        , +time.split(':')[1]));//Minutes
      formValues.requestedTime = momentUtil.utcTime(formValues.requestedDate);
    }
    props.submit(formValues);
  }

  return (
    <form ref={props.formRef} onSubmit={handleSubmit(formSubmit)}>
      <h4 className='heading text-xl md:text-2xl mb-6'>Appointment Form</h4>
      <p className='body-text text-sm text-titleColor mb-4'>Please enter the following information to request for an appointment.</p>
      <div className='flex flex-wrap -mx-4'>
        <div className='w-full md:w-1/2 px-4'>
          <MDInput
            id="firstName"
            label="First Name *"
            name="firstName"
            icon={props.isMobile ? '' : faUser}
            value={data?.firstName ? data.firstName : ""}
            inputChange={(e) => handleInputChange(e, "firstName")}
            error={errors.firstName}
          />
        </div>
        <div className='w-full md:w-1/2 px-4'>
          <MDInput
            id="lastName"
            label="Last Name *"
            name="lastName"
            value={data?.lastName ? data.lastName : ""}
            inputChange={(e) => handleInputChange(e, "lastName")}
            error={errors.lastName}
          />
        </div>
      </div>
      <div className='flex flex-wrap -mx-4'>
        <div className='w-full md:w-1/2 px-4'>
          <MaskedDateInput
            id="requested-date"
            input={
              data && data.requestedDate
                ? momentUtil.momentIsValid(data.requestedDate)
                  ? momentUtil.momentViewFormat(data.requestedDate)
                  : data.requestedDate
                : null
            }
            setInput={(e) => handleInputChange(e, "requestedDate")}
            name="requestedDate"
            label="Requested Date *"
            icon={props.isMobile ? '' : faCalendarAlt}
            error={errors.requestedDate}
          />
        </div>
        <div className='w-full md:w-1/2 px-4'>
          <MaskedTimeInput
            id='requested-time'
            name='requestedTime'
            label='Requested Time *'
            dayShift={dayShift}
            changeDayShift={(shift) => setDayShift(shift)}
            input={
              data && data.requestedTime
                ? data.requestedTime
                : null
            }
            setInput={(e) => handleInputChange(e, 'requestedTime')}
            error={errors.requestedTime}
          />
        </div>
      </div>
      <div className='flex flex-wrap -mx-4'>
        <div className='w-full md:w-2/3 px-4'>
          <MDInput
            id="email"
            label="Email *"
            icon={props.isMobile ? '' : faEnvelope}
            name="email"
            value={data?.email ? data.email : ""}
            inputChange={(e) => handleInputChange(e, "email")}
            error={errors.email}
          />
        </div>
        <div className='w-full md:w-1/3 px-4'>
          <MaskedPhoneInput
            input={data?.phone ? data.phone : null}
            setInput={(e) => handleInputChange(e, "phone")}
            name="phone"
            label="Phone *"
            icon={props.isMobile ? '' : faMobileAlt}
            error={errors.phone}
          />
        </div>
      </div>
      <div className='flex flex-wrap -mx-4'>
        <div className='w-full px-4'>
          <MDTextArea
            id="note"
            label="Describe the service you need *"
            className='w-full'
            name="note"
            value={data?.note ? data.note : ""}
            inputChange={(e) => handleInputChange(e, "note")}
            error={errors.note}
          />
        </div>
      </div>
    </form>
  );
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 768,
})

export default withSizes(mapSizesToProps)(AppointmentForm);