import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';

import * as vitafyUtils from '../../util/vitafyUtil';
import { parseHtml } from '../../util';

import { tenantCodeEnum } from '../../constants/tenantCodeConstant';

import facebookImg from '../../assets/images/facebook.png';
import TwitterImg from '../../assets/images/twitter.png';
import LinkedInImg from '../../assets/images/linkedin.png';
import PlaceholderImage from '../../assets/images/placeholder-avatar.png';
import { store } from '../../store';
import { useSelector } from 'react-redux';
import { selectReferredBy } from '../../stores/membership';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const { BMT, BBHB, MSD } = tenantCodeEnum;

const Banner = () => {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const referredByFromQueryParams = queryParams.get('referredby'); // gives referring agent code

  const state = useContext(store);
  const { globalState } = state;
  const { tenantCode, isLoading, primaryLocation } = globalState;

  const referredBy = useSelector(selectReferredBy);
  const isReferredByLoading = !!referredByFromQueryParams && !referredBy;

  // Workaround for the page scrolling to certain offset from the top.
  // Caused due to late loading of `referredBy`
  useEffect(() => {
    if (!isReferredByLoading) {
      window.scrollTo(0, 0);
    }
  }, [isReferredByLoading]);

  if (isLoading || !primaryLocation || isReferredByLoading) {
    return <>...</>;
  }

  const { businessName, displayName, email, phone, tagLine, metaData, note } =
    primaryLocation;
  const { imageUrl, bio, description } = metaData;

  const bannerBgColor = [BMT, BBHB, MSD].includes(tenantCode?.toUpperCase())
    ? 'bg-dedicatedDpcPrimaryAlternate'
    : 'bg-dedicatedDpcPrimary';

  const image = imageUrl || PlaceholderImage;

  // Display agent info if it is opened by referral link else, show tenant info
  const data = {
    displayName: referredBy?.displayName || displayName || businessName,
    imageUrl: referredBy?.imageUrl || image,
    tagLine,
    phone: referredBy?.phone || phone,
    formattedPhone: vitafyUtils.getPhoneDisplayText(referredBy?.phone || phone),
    email: referredBy?.email || email,
    bio: referredBy?.bio || bio || description || note,
  };

  return (
    <div className="mb-8">
      <div className={`w-full pt-24 lg:pt-32 pb-8 lg:pb-10 ${bannerBgColor}`}>
        {/* NAME AND DESIGNATION STARTS HERE */}
        <div className="container mx-auto px-4">
          <h1 className="font-heebo font-bold text-white uppercase text-2xl md:text-3xl lg:text-4.5xl">
            {data?.displayName}
          </h1>
          <h6 className="firstContentHeading normal-case text-base lg:text-2xl font-normal mt-0 pt-0">
            {data?.tagLine}
          </h6>
        </div>
        {/* NAME AND DESIGNATION ENDS HERE */}
      </div>
      <div className="container mx-auto py-5 lg:py-8 flex flex-wrap flex-row lg:flex-row-reverse px-4">
        {/* IMAGE STARTS HERE */}
        <div className="w-3/4 sm:w-2/5 lg:w-2/5  mx-auto -mt-16 sm:-mt-20 lg:-mt-40 xl:-mt-50 mb-10 lg:mb-0 lg:pl-16 xl:pl-32 hidden lg:block">
          <div className="w-full bg-white shadow-md rounded-lg">
            <div className="w-full pb-full rounded-t-lg relative mt-3">
              <img
                src={data?.imageUrl}
                className="absolute w-full h-full object-contain object-center rounded-t-lg"
                alt="profile-img"
              />
            </div>
            {metaData &&
              (metaData.facebookUrl ||
                metaData.twitterUrl ||
                metaData.linkedInUrl) && (
                <div className="py-5 flex justify-center items-center -mx-3">
                  {metaData.facebookUrl && (
                    <a
                      href={metaData.facebookUrl}
                      className="no-underline focus:outline-none block px-3"
                    >
                      <img
                        src={facebookImg}
                        className="w-8 xl:w-10"
                        alt="facebook-link"
                      />
                    </a>
                  )}
                  {metaData.twitterUrl && (
                    <a
                      href={metaData.twitterUrl}
                      className="no-underline focus:outline-none block px-3"
                    >
                      <img
                        src={TwitterImg}
                        className="w-8 xl:w-10"
                        alt="twiiter-link"
                      />
                    </a>
                  )}
                  {metaData.linkedInUrl && (
                    <a
                      href={metaData.linkedInUrl}
                      className="no-underline focus:outline-none block px-3"
                    >
                      <img
                        src={LinkedInImg}
                        className="w-8 xl:w-10"
                        alt="linkedin-link"
                      />
                    </a>
                  )}
                </div>
              )}
          </div>
        </div>
        {/* IMAGE ENDS HERE */}

        <div className="w-full lg:w-3/5 lg:pr-0">
          {/* ADDITIONAL INFO (PHONE/EMAIL) STARTS HERE */}
          <div className="flex justify-between">
            <div className="w-full flex flex-wrap mb-2 items-center">
              {data?.phone && (
                <div className="w-full lg:w-1/2 mb-4 lg:mb-0">
                  <div className="flex items-center">
                    <FontAwesomeIcon
                      icon={faMobileAlt}
                      className={`text-base md:text-2xl text-dedicatedDpcPrimary block`}
                    />
                    <a
                      href={`tel:${data.phone}`}
                      className="no-underline focus:outline-none"
                    >
                      <h6 className="ml-3 text-base md:text-lg">
                        {data?.formattedPhone}
                      </h6>
                    </a>
                  </div>
                </div>
              )}
              {data?.email && (
                <div
                  className={`w-full lg:w-1/2 flex ${
                    !!data?.phone && 'lg:justify-end'
                  }`}
                >
                  <div className="flex items-center">
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className={`text-base md:text-2xl text-dedicatedDpcPrimary block`}
                    />
                    <a
                      href={`mailto:${data.email}`}
                      className="block focus:outline-none no-underline"
                    >
                      <h6 className="ml-3 text-base md:text-lg">
                        {data.email}
                      </h6>
                    </a>
                  </div>
                </div>
              )}

              {/* ADDITIONAL INFO ENDS HERE */}
            </div>
          </div>
          <div className="divider-line mt-2 mb-6"></div>
          {/* SHORT DESCRIPTION STARTS HERE */}
          <div
            className="font-heebo text-bodyColor text-base md:text-lg"
            dangerouslySetInnerHTML={{
              __html: parseHtml(data?.bio),
            }}
          />
          {/* SHORT DESCRIPTION ENDS HERE */}
        </div>
      </div>
    </div>
  );
};

export default Banner;
