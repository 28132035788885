import React, { useContext } from 'react';
import {
  faEnvelope,
  faMobileAlt,
  faMapMarkerAlt,
  faBirthdayCake,
  faIdCard,
  faVenusMars,
} from '@fortawesome/free-solid-svg-icons';

import { ListItem } from '../../../common';

import * as vitafyUtils from '../../../util/vitafyUtil';

import avatarPlaceholderSquare from '../../../assets/images/avatar-placeholder-square.png';
import { store } from '../../../store';
import { getTextFromLangDict } from '../../../util';
import MembershipStatusMessage from './MembershipStatusMessage';

const SearchResult = ({ data, errorObj }) => {
  const { membershipActive, transactionSuccess } = errorObj;

  // Context Store
  const state = useContext(store);
  const { globalState } = state;
  const { groupCode, langDict, tenantCode } = globalState;

  let addressObj = null;
  if (data.addressLine1) {
    addressObj = {
      addressLine1: data.addressLine1,
      addressLine2: data.addressLine2,
      city: data.city,
      state: data.state,
      zip: data.zip,
    };
  } else if (data.address) {
    addressObj = data.address;
  }

  return (
    <>
      <p className="body-text text-titleColor mb-4">
        {getTextFromLangDict(langDict, {
          key: '_MEMBER_INFORMATION',
          groupCode,
          tenantCode,
        })}
      </p>

      <MembershipStatusMessage
        membershipActive={membershipActive}
        transactionSuccess={transactionSuccess}
      />

      <div className="flex flex-wrap bg-bgColor ">
        <div className="w-48 h-48 overflow-hidden mx-auto md:ml-0 mt-4 md:mt-0">
          <img
            src={data.imageUrl ? data.imageUrl : avatarPlaceholderSquare}
            alt="member-patient"
            className="w-full h-full object-cover object-center"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = `${avatarPlaceholderSquare}`;
            }}
          />
        </div>
        <div className="flex-grow md:pl-8 py-6  flex flex-col items-center md:block">
          <div>
            <h5 className="body-text text-titleColor font-bold text-lg mb-3 pl-16 md:pl-0">
              {data.firstName} {data.lastName}
            </h5>
            <div className="flex flex-wrap pl-16 md:pl-0">
              <div className="w-full md:w-1/2">
                <ListItem
                  icon={faMobileAlt}
                  text={vitafyUtils.getPhoneDisplayText(data.phone, data?.countryCode)}
                  iconClassName="text-titleColor text-sm mr-1"
                  textClassName="text-bodyColor text-sm"
                  containerClassName="mb-1"
                  iconWrapperClassName="mr-2"
                  address={false}
                  addressDoubleLine={false}
                />
                <ListItem
                  icon={faEnvelope}
                  text={data.email ? data.email : 'N/A'}
                  iconClassName="text-titleColor text-sm mr-1"
                  textClassName="text-bodyColor text-sm"
                  containerClassName="mb-1"
                  iconWrapperClassName="mr-2"
                  address={false}
                  addressDoubleLine={false}
                  truncateText={true}
                  textChar={20}
                />
                <ListItem
                  icon={faMapMarkerAlt}
                  text={addressObj}
                  iconClassName="text-titleColor text-sm mr-1"
                  textClassName="text-bodyColor text-sm"
                  containerClassName="mb-1"
                  iconWrapperClassName="mr-2"
                  address={true}
                  addressDoubleLine={true}
                />
              </div>
              <div className="w-full md:w-1/2">
                <ListItem
                  icon={faBirthdayCake}
                  text={data.dob ? data.dob : 'N/A'}
                  iconClassName="text-titleColor text-sm mr-1"
                  textClassName="text-bodyColor text-sm"
                  containerClassName="mb-1"
                  iconWrapperClassName="mr-2"
                  address={false}
                  addressDoubleLine={false}
                />
                <ListItem
                  icon={faVenusMars}
                  text={data.gender ? data.gender : 'N/A'}
                  iconClassName="text-titleColor text-sm mr-1"
                  textClassName="text-bodyColor text-sm"
                  containerClassName="mb-1"
                  iconWrapperClassName="mr-2"
                  address={false}
                  addressDoubleLine={false}
                />
                <ListItem
                  icon={faIdCard}
                  text={data.last4SSN ? data.last4SSN : 'N/A'}
                  iconClassName="text-titleColor text-sm mr-1"
                  textClassName="text-bodyColor text-sm"
                  containerClassName="mb-1"
                  iconWrapperClassName="mr-2"
                  address={false}
                  addressDoubleLine={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchResult;
