import React, { Component } from "react";
import { Header, Footer, Loader, Enrollment, RequestDemo } from "../../common";
import Info from "./Info";

import * as networkProviderService from "../../service/networkProviderService";
import ctaModalTitle from "../../constants/ctaModalTitle";
class DpcDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      detail: {
        pricings: [],
        associatedPhysicans: [],
      },
      requestDemoModalTitle: ctaModalTitle.CONTACT_US,
    };
    this.newRequestDemoModal = React.createRef();
  }

  componentDidMount() {
    const providerId = this.props.match.params?.id;
    if (!providerId) this.props.history.push("/404");
    this.fetchProviderDetail(providerId);
    this.fetchPricing(providerId);
    this.fetchAssociatedPhysicians(providerId);
  }

  fetchProviderDetail = (providerId) => {
    this.setState({ loading: true });
    networkProviderService
      .fetchNetworkProviderById(providerId)
      .then((response) => {
        if (response.status === "success") {
          const provider = response.data?.providerList.find(
            (provider) => provider.providerId == providerId
          );
          if (!provider) this.props.history.push("/404");
          this.setState((prevState) => ({
            detail: {
              ...prevState.detail,
              ...provider,
            },
          }));
        }
        this.setState({ loading: false });
      })
      .catch((error) => {
        console.log("Error while fetching provider by providerId", error);
        this.setState({ loading: false });
      });
  };

  // Pricing is fetched from prod API
  fetchPricing = (providerId) => {
    this.setState({ loading: true });
    const queryParams = {
      isPublic: true,
      isActive: true,
    };
    networkProviderService
      .fetchPricingByProviderId(providerId, queryParams)
      .then((response) => {
        if (response.status === "success") {
          // sort pricings according to age group
          const pricings =
            response.data &&
            response.data.sort((a, b) =>
              a.lowerValue > b.lowerValue
                ? 1
                : a.lowerValue < b.lowerValue
                ? -1
                : 0
            );
          this.setState((prevState) => ({
            detail: {
              ...prevState.detail,
              pricings: pricings ? pricings : [],
            },
          }));
        }
        this.setState({ loading: false });
      })
      .catch((error) => {
        console.log("Error while fetching pricing by providerId", error);
        this.setState({ loading: false });
      });
  };

  fetchAssociatedPhysicians = (providerId) => {
    this.setState({ loading: true });
    networkProviderService
      .fetchProviderPhysiciansByProviderId(providerId)
      .then((response) => {
        if (response.status === "success") {
          const physicians = response.data.providerList;
          this.setState((prevState) => ({
            detail: {
              ...prevState.detail,
              associatedPhysicans: physicians,
            },
          }));
        }
        this.setState({ loading: false });
      })
      .catch((error) => {
        console.log("Error while fetching physicians by providerId", error);
        this.setState({ loading: false });
      });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <Header />

        <Info
          detail={this.state.detail}
          openRequestDemoModal={(modalTitle) => {
            this.setState({ requestDemoModalTitle: modalTitle });
            this.newRequestDemoModal.current.toggle();
          }}
        />

        <div className="mid-divider"></div>

        <Footer />

        <RequestDemo
          formModal={this.newRequestDemoModal}
          modalTitle={this.state.requestDemoModalTitle}
        />
      </>
    );
  }
}

export default DpcDetail;
