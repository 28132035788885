/**
 * @author Slesha Tuladhar <slesha@noveltytechnology.com>
 */

import React, { Component } from 'react';
import { Header, Footer, ClientStory, Loader, Enrollment } from '../../common';
import AgentInfo from './AgentInfo';
import * as service from '../../service';
import * as enrollmentService from '../../service/enrollmentService';
import * as lookupService from '../../service/lookup-service';
import * as cloudProvidersService from '../../service/cloudProvidersService';
import * as networkProviderService from '../../service/networkProviderService';
import { showToast } from '../../service/toasterService';
import { Redirect } from 'react-router-dom';
import routeAliasConstant from '../../constants/routeAliasConstant';

class Agent extends Component {
  constructor(props) {
    super(props);
    this.initialState = {
      registrationStep: 1,
      loading: false,
      error: false,
      alias: this.props.match.params.alias,
      code: (new URLSearchParams(this.props.location.search)).get('code'),
      tokenVerified: false,
      invalidLink: false,
      expiredLink: false,
      agentInfo: {},
      clientData: {
        paymentDetail: { paymentMethod: 'card' },
        payment: {
          iAgreeCardPayment: false,
          iAgreeAchPayment: false,
        },
        totalMembers: 1
      },
      address: {},
      directPrimaryCare: {
        isActive: false,
        planType: 'DPC',
        planDescription: undefined,
        referenceId: undefined,
        cost: undefined
      },
      careNavigation: {
        isActive: false,
        planType: 'CN',
        planDescription: undefined,
        referenceId: undefined,
        cost: undefined
      },
      additionalCoverage: {
        isActive: false,
        planType: 'ADDITIONALCOVERAGE',
        planDescription: undefined,
        referenceId: undefined,
        cost: undefined
      },
      additionalCoverageList: [],
      dpcSearchList: [],
      selectedDpc: null,
      client: {},
      enrollmentContent: '',
      openModalName: '',
      submittingForm: false,
      partners: [],
      clientStories: [],
      dpcLoading: false,
      comingFrom: '',
      paymentInformationActiveTab: 1,
      cardErrorMessage: '',
      bankErrorMessage: '',
      vCareMonthlyCost: '',
      tempPaymentData: {
        token: '',
        expiry: ''
      },
      paymentEnvironment: 'sandbox',
      // for resubmitting the form when the client is created and enrolled but had some problems with payment
      existingClientId: null,
      existingEnrollmentId: null,
      vcareCosmicContent: {}
    }
    this.state = this.initialState;
    this.directPrimaryCareModal = React.createRef();
    this.additionalCoverageModal = React.createRef();
    this.scrollToFormRef = React.createRef();
  }
  componentDidMount() {
    this.fetchInitialData();
  }
  fetchInitialData = () => {
    this.setState({ loading: true });
    this.getClientStories();
    this.getEnrollmentContent(); // enrollment content from cosmic
    this.fetchPricing();
    if (this.state.code) {
      this.verifyCode(this.state.code);
    } else if (this.state.alias) {
      this.fetchAgentByAlias(this.state.alias);
      this.fetchAdditionalCoverageList();
      this.fetchCareNavigator();
    }
    if (this.props.location.pathname && this.props.location.pathname.includes('vcare')) {
      this.setState({ comingFrom: routeAliasConstant.VCARE });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState(this.initialState,
        () => this.fetchInitialData());
    }
  }
  verifyCode = (code) => {
    if (code) {
      enrollmentService.verifyCode(code)
        .finally(() => {
          this.setState({
            loading: false
          })
        }).then(response => {
          const directPrimaryCare = response.data.agentClientPlan.find(x => x.planType === 'DPC');
          const careNavigation = response.data.agentClientPlan.find(x => x.planType === 'CN');
          const additionalCoverage = response.data.agentClientPlan.find(x => x.planType === 'ADDITIONALCOVERAGE');
          this.setState({
            clientData: {
              ...response.data,
              paymentDetail: { paymentMethod: 'card' },
              clientType: response.data.agentClientType
            },
            address: { ...response.data.address },
            tokenVerified: true,
            agentInfo: { ...response.data.agent },
            directPrimaryCare: { ...directPrimaryCare },
            careNavigation: { ...careNavigation },
            additionalCoverage: { ...additionalCoverage },
            selectedDpc: { ...directPrimaryCare?.provider }
          }, () => {
            careNavigation && !careNavigation.planDescription && this.fetchCareNavigator();
          })
        }).catch(error => {
          console.log('error', error);
          showToast('error', error?.response?.data?.message);
          if (error?.response?.data?.message === "INVALID") {
            this.setState({ invalidLink: true })
          } else if (error?.response?.data?.message === "EXPIRED") {
            this.setState({ expiredLink: true })
          }
        });
    } else {
      this.setState({
        loading: false,
        invalidLink: true
      })
    }
  }
  fetchAgentByAlias = (alias) => {
    enrollmentService.fetchAgentByAlias(alias)
      .then(response => {
        if (response.status === 'success') {
          this.setState(prevState => ({
            agentInfo: response.data,
            clientData: { ...prevState.clientData, agent: response.data },
            loading: false
          }), () => {
            this.getMerchantProfileByReferenceId(this.state.agentInfo.tenantId);
          })
        }
      }).catch(error => {
        console.log(error);
        this.props.history.push('/404'); // there is not route linked with /404. It will redirect to PageNotFound by default
      })
  }
  fetchAdditionalCoverageList = () => {
    lookupService.fetchLookupsByType('ADDITIONALCOVERAGE')
      .then((response) => {
        this.setState({
          additionalCoverageList: response.data
        });
      })
      .catch((error) => {
        console.log("Error fetching lookup by type: ADDITIONALCOVERAGE", error);
      })
  }
  fetchCareNavigator = () => {
    enrollmentService.fetchPlanByType('CN')
      .then((response) => {
        this.setState(prevState => ({
          careNavigation: {
            ...prevState.careNavigation,
            isActive: true,
            planDescription: response.data.value
          }
        }));
      })
      .catch((error) => {
        console.log("Error fetching plan by type: CN", error);
      })
  }
  getMerchantProfileByReferenceId = (tenantId) => {
    enrollmentService.fetchMerchantProfileByReferenceId(tenantId)
      .then((response) => {
        if (response.status === 'success') {
          const environment = response.data && response.data.environment;
          this.setState(prevState => ({
            ...prevState,
            paymentEnvironment: environment
          }));
        }
      })
      .catch((error) => {
        console.log("Error fetching Merchant Profile by ReferenceId", error);
      })
  }
  getClientStories = () => {
    this.setState({ loading: true });
    service
      .getClientStories()
      .then((result) => {
        if (result.status === 'success') {
          this.setState({
            clientStories: result.data,
            loading: false
          });
        }
      })
      .catch((error) => {
        console.log('Error in get homeContent', error);
        this.setState({ loading: false, error: true });
      });
  };
  handleNextPrevClick = (step, e) => {
    if (step === 4) {
      this.setState(prevState => ({
        clientData: e ? {
          ...prevState.clientData,
          payment: {
            ...prevState.clientData.payment,
            ...e
          },
          effStartDate: new Date()
        }
          : { ...prevState.clientData, effStartDate: new Date() },
        submittingForm: true
      }), () => {
        this.enrollmentCheckout().then((response) => {
          if (response && response.status === 'success') {
            this.setState({
              registrationStep: step,
              existingClientId: null,
              existingEnrollmentId: null,
              tempPaymentData: {
                token: '',
                expiry: ''
              },
            })
          }
        })
      })
    } else {
      this.setState(prevState => ({
        clientData: e ? { ...prevState.clientData, ...e } : prevState.clientData,
        registrationStep: step
      }))
    }
  }
  openAddModal = (planName) => {
    this.setState(prevState => ({
      openModalName: planName,
      dpcSearchList: planName === 'directPrimaryCare' && !this.state.directPrimaryCare.referenceId ? [] : prevState.dpcSearchList
    }), () => {
      this[`${planName}Modal`].current.toggle();
    })
  }
  removePlan = (planName) => {
    this.setState({
      [planName]: { ...this.getInitialState()[planName] }
    })
  }
  searchDpc = (searchParams) => {
    this.setState({ dpcLoading: true });
    const params = {
      category: 'dpc',
      keyword: searchParams.searchKeyword ? searchParams.searchKeyword : '',
      radius: searchParams.radius ? searchParams.radius : 30,
      address: {
        city: searchParams.searchCity,
        state: searchParams.searchState,
        zip: searchParams.searchZip,
        latitude: searchParams.searchLatitude,
        longitude: searchParams.searchLongitude,
      }
    }
    cloudProvidersService.fetchEsProviders(params)
      .finally(() => {
        this.setState({ dpcLoading: false });
      })
      .then(response => {
        let res = response.data.providerList.filter(x => x.providerName);
        res.map(x => {
          x.displayName = x.providerName;
          return x;
        })
        this.setState({
          dpcSearchList: res
        })
      }).catch(err => {
        showToast('error', 'Error searching providers');
        console.log('Error searching providers', err);
      })
  }
  savePlan = (data, selectedDpc = null, selectFromDropdown = false) => {
    this.setState(prevState => ({
      [this.state.openModalName]: { ...prevState[this.state.openModalName], ...data },
      selectedDpc: selectedDpc ? selectedDpc : prevState.selectedDpc,
      dpcSearchList: selectFromDropdown ? [] : prevState.dpcSearchList
    }), () => {
      this[`${this.state.openModalName}Modal`].current.toggle();
    })
  }
  enrollmentCheckout = () => {
    return new Promise((resolve, reject) => {
      let enrollmentData = { ...this.state.clientData };
      if (this.state.existingClientId && this.state.existingEnrollmentId) {
        enrollmentData = {
          ...enrollmentData,
          existingClientId: this.state.existingClientId,
          existingEnrollmentId: this.state.existingEnrollmentId
        };
      }
      const monthlyCost = enrollmentData.monthlyCost;
      const totalAmount = parseInt(monthlyCost) * parseInt(enrollmentData.totalMembers);
      enrollmentData.totalAmount = totalAmount;
      // Only Care Navigator (vCare) in client and agentClient plans for vCare Enrollment
      if (this.state.comingFrom === routeAliasConstant.VCARE) {
        enrollmentData = {
          ...enrollmentData,
          clientPlan: enrollmentData.clientPlan.filter(item => item.planType === 'CN'),
          agentClientPlan: enrollmentData.agentClientPlan.filter(item => item.planType === 'CN')
        }
      }
      if (this.state.comingFrom === routeAliasConstant.VCARE) {
        delete enrollmentData.clientPlan;
        const transactionDescription = this.state.vcareCosmicContent.metadata &&
          this.state.vcareCosmicContent.metadata.transaction_description;
        let newClientPlan = [];
        newClientPlan = [...newClientPlan, {
          product: 'vCare',
          descriptor: transactionDescription,
          cost: totalAmount
        }]
        enrollmentData.clientPlan = newClientPlan;
      }
      enrollmentService.enrollmentCheckout(enrollmentData)
        .finally(() => {
          this.setState({ submittingForm: false });
        }).then(response => {
          resolve(response);
        }).catch(error => {
          if (error.response.data.data) {
            this.setState({
              existingClientId: error.response.data.data.clientId,
              existingEnrollmentId: error.response.data.data.newEnrollmentId
            })
          }
          if (error.response.data.message && error.response.data.message.includes('already exist')) {
            showToast('error', error.response.data.message);
          } else {
            if (enrollmentData.payment && enrollmentData.payment.paymentType.toLowerCase() === 'card') {
              this.setState({ cardErrorMessage: error.response.data.message })
            } else {
              this.setState({ bankErrorMessage: error.response.data.message })
            }
          }
          reject(error);
        });
    })
  }
  updateFields = (field, value) => {
    this.setState(prevState => ({
      clientData: { ...prevState.clientData, [field]: value }
    }));
  }
  // enrollment content from cosmic
  getEnrollmentContent = () => {
    this.setState({ loading: true });
    service
      .getEnrollmentContent()
      .then((result) => {
        if (result.status === 'success') {
          this.setState(prevState => ({
            enrollmentContent: result.data.find((item) => item.slug === 'enrollment-page'),
            vcareCosmicContent: result.data.find((item) => item.slug === 'vcare-enrollment'),
            loading: false
          }));
        }
      })
      .catch((error) => {
        console.log('Error in get homeContent', error);
        this.setState({ loading: false, error: true });
      });
  }

  searchProviders = (params, autocomplete = true) => {
    this.setState({ dpcLoading: autocomplete ? false : true });
    params.category = 'medicalNetwork';
    params.network = 'VITAFY';
    params.entityCode = 2;
    return new Promise((resolve, reject) => {
      networkProviderService.searchProvider(params)
        .finally(() => {
          this.setState({ dpcLoading: false });
        })
        .then(response => {
          if (response.status === "success") {
            response.data.providerList.map(x => {
              x.providerName = x.displayName;
              return x;
            })
            this.setState(prevState => ({
              dpcSearchList: autocomplete ? prevState.dpcSearchList : response.data.providerList
            }));
            resolve(response.data.providerList);
          }
        })
        .catch(error => {
          this.setState({ dpcLoading: false });
          console.log('error', error);
          reject(error);
        })
    })
  }

  handlePaymentDataChange = (data) => {
    data && data.map((item) => {
      this.setState(prevState => ({
        clientData: {
          ...prevState.clientData,
          payment: {
            ...prevState.clientData.payment,
            [item.key]: item.value
          }
        }
      }))
    })
  }

  fetchPricing = () => {
    lookupService.fetchLookupsByType('VCARE')
      .then(response => {
        if (response.status === 'success') {
          let pricing = response.data && response.data.find((item) => item.type === 'VCARE').value;
          this.setState({
            vCareMonthlyCost: pricing
          })
        }
      }).catch(error => {
        console.log('Error while fetching lookups by type', error);
      })
  }

  handleChangeTempPaymentData = (token, expiry) => {
    this.setState(prevState => ({
      ...prevState,
      tempPaymentData: {
        token: token,
        expiry: expiry
      }
    }))
  }

  render() {
    if (!this.state.code && !this.state.alias) {
      return <Redirect to='/page-not-found' />
    }
    if (this.state.loading) {
      return <Loader />;
    }
    return (
      <>
        <Header />
        <AgentInfo
          agentInfo={this.state.agentInfo}
          vcareCosmicContent={this.state.vcareCosmicContent}
          comingFrom={this.state.comingFrom}
          {...this.props}
        />

        <Enrollment
          registrationStep={this.state.registrationStep}
          // Client Information 
          address={this.state.address}
          // Plan Information
          directPrimaryCare={this.state.directPrimaryCare}
          careNavigation={this.state.careNavigation}
          additionalCoverage={this.state.additionalCoverage}
          agentInfo={this.state.agentInfo}
          selectedDpc={this.state.selectedDpc}
          directPrimaryCareModal={this.directPrimaryCareModal}
          additionalCoverageModal={this.additionalCoverageModal}
          dpcSearchList={this.state.dpcSearchList}
          openModalName={this.openModalName}
          additionalCoverageList={this.state.additionalCoverageList}
          openAddModal={this.openAddModal}
          removePlan={this.removePlan}
          searchDpc={this.searchDpc}
          savePlan={this.savePlan}
          updateFields={this.updateFields}
          enrollmentContent={this.state.enrollmentContent && this.state.enrollmentContent.metadata}
          vCareMonthlyCost={this.state.vCareMonthlyCost}
          // Payment Information
          paymentInformationActiveTab={this.state.paymentInformationActiveTab}
          changePaymentInformationActiveTab={(value) => {
            this.setState({ paymentInformationActiveTab: value, bankErrorMessage: '', cardErrorMessage: '' })
          }}
          cardErrorMessage={this.state.cardErrorMessage}
          clearCardErrorMessage={() => this.setState({ cardErrorMessage: '' })}
          bankErrorMessage={this.state.bankErrorMessage}
          clearBankErrorMessage={() => this.setState({ bankErrorMessage: '' })}
          changePaymentData={this.handlePaymentDataChange}
          tempPaymentData={this.state.tempPaymentData}
          changeTempPaymentData={this.handleChangeTempPaymentData}
          paymentEnvironment={this.state.paymentEnvironment}
          //common
          client={this.state.clientData}
          submit={this.handleNextPrevClick}
          submittingForm={this.state.submittingForm}
          scrollToFormRef={this.scrollToFormRef}
          dpcLoading={this.state.dpcLoading}
          enrollmentFromDPC={false}
          searchProviders={this.searchProviders}
          comingFrom={this.state.comingFrom}
          vcareCosmicContent={this.state.vcareCosmicContent}
        />
        <div className='divider'></div>
        <ClientStory
          clientStories={this.state.clientStories}
          partners={
            this.state.partners.metadata &&
            this.state.partners.metadata.partners_images
          }
          visibility={
            this.state.partners.metadata &&
            this.state.partners.metadata.entire_section_visibility
          }
          bgColor={false}
        />
        <Footer />

      </>
    );
  }
}

export default Agent;
