import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectActiveQuestion,
  setActiveQuestion,
} from '../../stores/questionaire';
import { _cloneDeep } from '../../util/lodashUtil';

const RadioInput = () => {
  const dispatch = useDispatch();

  const activeQuestion = useSelector(selectActiveQuestion);
  const options = activeQuestion?.options || [];
  const answer = activeQuestion?.answer;

  const onSelect = (option) => {
    const updatedQuestion = _cloneDeep(activeQuestion);

    updatedQuestion.answer = option;
    dispatch(setActiveQuestion(updatedQuestion));
  };

  return (
    <div>
      {options.map((item, index) => (
        <button
          key={item.value}
          className={`mid-radio-btn px-8 mb-4 ${
            item.value === answer?.value
              ? 'bg-holyCityMedSecondary font-medium'
              : 'bg-white'
          } ${index < options.length - 1 && 'mr-4'}`}
          type="button"
          onClick={() => onSelect(item)}
        >
          {item.value}
        </button>
      ))}
    </div>
  );
};

export default RadioInput;
