import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export class DedicatedPageModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: this.props.show ?? false,
    };
  }
  toggle = () => {
    this.setState(
      {
        showModal: !this.state.showModal,
      },
      () => {
        if (!this.state.showModal && this.props?.reset) {
          this.props.reset();
        }
      }
    );
  };
  render() {
    const { iconName, modalTitle, maxWidth, marginTop } = this.props;
    return (
      <>
        {this.state.showModal ? (
          <>
            <div className="justify-center items-start flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div
                className={`relative w-full sm:w-11/12 md:w-9/12 lg:w-1/2 my-4 mx-auto px-5 md:px-0 ${
                  maxWidth ? maxWidth : 'max-w-3xl'
                } ${marginTop ? marginTop : ''}`}
              >
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-center justify-between py-4 px-4 md:px-6 bg-bgColor border-b border-solid border-gray-300 rounded-t">
                    <h5 className="font-heebo text-lg font-bold text-titleColor">
                      {iconName && (
                        <FontAwesomeIcon
                          icon={iconName}
                          className="text-dedicatedDpcPrimary mr-3"
                        />
                      )}
                      {modalTitle}
                    </h5>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={this.toggle}
                    >
                      <span className="bg-transparent text-titleColor opacity-5 h-6 w-6 text-2xl md:text-3xl block outline-none focus:outline-none hover:border-dedicatedDpcPrimary">
                        ×
                      </span>
                    </button>
                  </div>
                  {/* body (content and footer) */}
                  {this.props.children}
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </>
    );
  }
}

export default DedicatedPageModal;
