import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MDInput } from '../../../../common';
import { store } from '../../../../store';
import {
  selectNoOfEmployee,
  setNoOfEmployee,
} from '../../../../stores/membership';
import { getTextFromLangDict } from '../../../../util';
import BenefitStartDateInput from './BenefitStartDateInput';

const EMPLOYEE_NUMBER_OPTIONS = [10, 25, 50, 100, 150, 500];

const CompanyMembership = ({ whiteBg }) => {
  const dispatch = useDispatch();

  // Redux Store
  const noOfEmployee = useSelector(selectNoOfEmployee);

  // Context Store
  const state = useContext(store);
  const { globalState } = state;
  const { groupCode, langDict, tenantCode } = globalState;

  const onChangeEmployeeCount = (value) => {
    dispatch(setNoOfEmployee(value));
  };

  const isInvalid = noOfEmployee && parseInt(noOfEmployee) < 1;

  const inputLabel = getTextFromLangDict(langDict, {
    key: '_ESTIMATED_NO_OF_EMPLOYEES',
    groupCode,
    tenantCode,
  });

  return (
    <>
      <h5 className="dedicated-form-title">
        {getTextFromLangDict(langDict, {
          key: '_PLEASE_CHOOSE_ESTIMATED_NUMBER_OF_MEMBERS',
          groupCode,
          tenantCode,
        })}
      </h5>
      <p className="body-text text-sm mb-6">
        {getTextFromLangDict(langDict, {
          key: '_YOU_CAN_MANAGE_YOUR_MEMBERS_FROM_THE_PORTAL_POST_ENROLLMENT',
          groupCode,
          tenantCode,
        })}
      </p>

      <div className="flex flex-wrap justify-between items-center">
        <div className="mr-1">
          {EMPLOYEE_NUMBER_OPTIONS.map((employeeNumber, index) => (
            <button
              data-cy={`employee-number-${employeeNumber}`}
              className={`small-radio-btn ${
                !whiteBg && 'bg-white'
              } py-2 px-3 md:px-3 mb-2 ${index !== 0 && 'ml-2 md:ml-3'}
            ${
              parseInt(noOfEmployee) === employeeNumber &&
              'bg-holyCityMedSecondary font-bold'
            }`}
              onClick={() => onChangeEmployeeCount(employeeNumber)}
              type="button"
              key={index}
            >
              {employeeNumber}
            </button>
          ))}
        </div>
        <div className="mb-2" style={{ width: '210px' }}>
          <MDInput
            id="totalMembers"
            label={`${inputLabel} *`}
            name="totalMembers"
            type="number"
            value={noOfEmployee ? noOfEmployee : ''}
            inputChange={(event) => onChangeEmployeeCount(event.target.value)}
            error={!!isInvalid}
          />
        </div>
      </div>

      <div className="form-section-divider-md"></div>

      <BenefitStartDateInput />
    </>
  );
};

export default CompanyMembership;
