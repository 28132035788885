import * as Yup from 'yup';

import validationMessage from '../constants/validationMessage';

import { validateDob, validateMaskedInput } from '../util/momentUtil';

const { INVALID } = validationMessage;

export const MemberCheckInSchema = Yup.object().shape({
  firstName: Yup.string().nullable().trim().required(),
  lastName: Yup.string().nullable().trim().required(),
  dob: Yup.string()
    .nullable()
    .required()
    .test('checkIfDobIsValid', INVALID, (value) => {
      if (!value) return true;
      return validateDob(value);
    }),
  lastSSN: Yup.string()
    .nullable()
    .test('checkIfSSNValid', INVALID, (value) => validateMaskedInput(value)),
  gender: Yup.string(),
});
