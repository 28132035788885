import React from 'react';

const Layout = (props) => {
  return (
    <div className="w-full bg-bgColor overflow-hidden">
      <div className="container mx-auto px-4">
        <div className="container px-4 lg:flex mx-auto">
          {/* LEFT PART */}
          <div className="w-full lg:w-1/3  py-6 lg:flex lg:justify-start lg:items-center relative">
            <div className="overlay-overcome flex justify-center text-center lg:text-left lg:block">
              <div className="inner-divider"></div>
              {props.left}
              <div className="inner-divider"></div>
            </div>

            <div className="overlay max-width-escape-bg "></div>
          </div>
          {/* LEFT PART ENDS HERE */}
          {/* RIGHT PART */}
          <div className="w-full lg:w-2/3 lg:pl-10">
            <div className="enrollment-form-wrapper">
              <div className="inner-divider mt-8"></div>

              {props.right}

              <div className="inner-divider mb-8"></div>
            </div>
          </div>
          {/* RIGHT PART ENDS HERE */}
        </div>
      </div>
    </div>
  );
};

export default Layout;
