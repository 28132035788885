/**
 * Loading Skeleton for single line; can be used for mulitple single lines as well
 */

import React from 'react';
import Skeleton from 'react-loading-skeleton';

const LineSkeleton = ({ lines = 2, width = 200, height = 25, containerClassName='my-3' }) => {
  return (
    <>
      {new Array(lines).fill(0).map((item, index) =>
        <div className={containerClassName} key={index}>
          <Skeleton width={width} height={height} />
        </div>
      )}
    </>
  );
}

export default LineSkeleton;