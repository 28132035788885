import React, { useContext } from 'react';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PrevNextControl from './PrevNextControl';
import { store } from '../../store';
import { getTextFromLangDict, parseHtml } from '../../util';

const Ineligible = () => {
  const state = useContext(store);
  const { globalState } = state;
  const { langDict, groupCode, tenantCode } = globalState;

  const content = getTextFromLangDict(langDict, {
    key: '_ELIGIBILITY_QUESTIONNAIRE_INELIGIBLE_CONTENT',
    groupCode,
    tenantCode,
  });

  return (
    <div className="py-10">
      <div className="min-h-450px">
        <FontAwesomeIcon
          className="block text-errorTxt text-4xl text-center mx-auto mb-6"
          icon={faExclamationTriangle}
        />

        <h4 className="text-titleColor font-medium text-xl text-center mb-4">
          {getTextFromLangDict(langDict, {
            key: '_ELIGIBILITY_QUESTIONNAIRE_INELIGIBLE_TITLE',
            groupCode,
            tenantCode,
          })}
        </h4>

        {!!content && (
          <div dangerouslySetInnerHTML={{ __html: parseHtml(content) }} />
        )}
      </div>

      <PrevNextControl />
    </div>
  );
};

export default Ineligible;
