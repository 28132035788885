import React, { useContext, useEffect, useState } from 'react';
import { useAcceptJs } from 'react-acceptjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';

import {
  PAYMENT_ENVIRONMENT,
  AUTHORIZE_DOT_NET_ENVIRONMENT,
} from '../../../../constants/payment';
import {
  AccountTypeSelect,
  FormErrorMessage,
  MaskedAccountNumberInput,
  MaskedRoutingNumberInput,
} from '../../../../common';
import { store } from '../../../../store';
import { validateBankAccountType } from '../../../../util/vitafyUtil';

const { TEST } = PAYMENT_ENVIRONMENT;

const BankPayment = ({
  clearError,
  filledBankData,
  onChangeData,
  onAddNewBankClick,
  bankErrorMessage,
  errorMessage,
  isSubmitting: isSubmittingCheckout,
}) => {
  // Context Store
  const state = useContext(store);
  const { globalState } = state;
  const { merchantProfile } = globalState;
  const {
    paymentEnvironment,
    clientKey_live,
    clientKey_test,
    merchantId_live,
    merchantId_test,
  } = merchantProfile;

  const environment =
    paymentEnvironment === TEST
      ? AUTHORIZE_DOT_NET_ENVIRONMENT.TEST
      : AUTHORIZE_DOT_NET_ENVIRONMENT.LIVE;
  const authData = {
    clientKey: paymentEnvironment === TEST ? clientKey_test : clientKey_live,
    apiLoginID: paymentEnvironment === TEST ? merchantId_test : merchantId_live,
  };

  const { dispatchData, loading, error } = useAcceptJs({
    environment,
    authData,
  });

  const [bankData, setBankData] = useState({
    accountNumber: '',
    routingNumber: '',
    accountType: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorRes, setErrorRes] = useState('');

  const handleChangeBankData = (name, data) => {
    setBankData((prevState) => ({ ...prevState, [name]: data }));
    setErrorRes('');
    clearError();
  };

  // Validate card data on input blur (cardNumber, expiry and CVC) and dispatch data to Authorize.Net server if validated.
  // Local validation is simply checking if the inputs are filled.
  const handleInputBlur = async () => {
    const { accountNumber, routingNumber, accountType } = bankData;

    if (
      routingNumber.length === 9 &&
      accountNumber.length >= 4 &&
      accountNumber.length <= 17 &&
      validateBankAccountType(accountType)
    ) {
      try {
        setIsSubmitting(true);
        const response = await dispatchData({ bankData });

        if (response?.messages?.resultCode === 'Ok') {
          onChangeData('bank', {
            token: response?.opaqueData?.dataValue,
            tokenSource: response?.opaqueData?.dataDescriptor,
            accountNumber,
            routingNumber,
            accountType,
          });
        }
      } catch (err) {
        setErrorRes(err?.messages?.message?.[0]?.text);
        console.log('err', err);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleAddNewCardInfo = () => {
    onAddNewBankClick();
    setErrorRes('');
    setBankData({
      accountNumber: '',
      routingNumber: '',
      accountType: '',
    });
  };

  const getFormattedAccountNumber = (data) => {
    if (!data) return '';
    return '************' + data.slice(-4);
  };

  // Initialize local state with bank information if present
  useEffect(() => {
    if (filledBankData?.token) {
      setBankData({
        accountNumber: filledBankData.accountNumber,
        routingNumber: filledBankData.routingNumber,
        accountType: filledBankData.accountType,
      });
    }
  }, [filledBankData]);

  if (loading) {
    return <>Loading...</>;
  }

  if (error) {
    return <>Error loading Authorize.Net payment gateway</>;
  }

  const renderedFilledBankInfo = (
    <div className="mt-4">
      <div className="flex justify-between items-center">
        <p className="body-text mb-3 font-semibold">
          Use recently entered Bank Information
        </p>
        <button
          className={`px-4 py-2 rounded-md hover:bg-orange-200 cursor-pointer transition-all duration-150 ${
            isSubmittingCheckout && 'btn-disabled'
          }`}
          onClick={handleAddNewCardInfo}
          type="button"
        >
          <p className="text-secondary font-medium">
            <FontAwesomeIcon icon={faCreditCard} className="mr-2" />
            Add new account
          </p>
        </button>
      </div>
      <p className="body-text mb-1">
        Account Number&nbsp; :{' '}
        <span className="text-titleColor">
          {getFormattedAccountNumber(filledBankData.accountNumber)}
        </span>
      </p>
    </div>
  );

  return (
    <div className="mb-10">
      {filledBankData?.token ? (
        renderedFilledBankInfo
      ) : (
        <div className="flex flex-wrap -mx-2">
          <div className="w-full md:w-2/5 px-2 mb-3 md:mb-0">
            <MaskedAccountNumberInput
              value={bankData.accountNumber}
              onChange={(data) =>
                handleChangeBankData('accountNumber', data.value)
              }
              onBlur={handleInputBlur}
              isDisabled={isSubmitting}
            />
          </div>
          <div className="w-full md:w-3/5 px-2">
            <div className="flex flex-wrap -mx-2">
              <div className="w-full md:w-1/2 px-2 mb-3 md:mb-0">
                <MaskedRoutingNumberInput
                  value={bankData.routingNumber}
                  onChange={(data) =>
                    handleChangeBankData('routingNumber', data.value)
                  }
                  onBlur={handleInputBlur}
                  isDisabled={isSubmitting}
                />
              </div>
              <div className="w-full md:w-1/2 px-2">
                <AccountTypeSelect
                  value={bankData.accountType}
                  onChange={(data) => handleChangeBankData('accountType', data)}
                  onBlur={handleInputBlur}
                  isDisabled={isSubmitting}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {isSubmitting && <p className="text-sm text-bodyColor">Please wait...</p>}
      {!!errorRes && <FormErrorMessage type="error" message={errorRes} />}
      {!!bankErrorMessage && (
        <FormErrorMessage type="warning" message={bankErrorMessage} />
      )}
      {errorMessage ? (
        <FormErrorMessage type="error" message={`Error: ${errorMessage}`} />
      ) : null}
    </div>
  );
};

export default BankPayment;
