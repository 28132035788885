import React, { useEffect } from 'react';

import { Loader } from '../../common';

import commonConstants from '../../constants/commonConstant';

const { TERMS_OF_USE } = commonConstants.VITAFY_AGREEMENTS;

const TermsAndConditions = (props) => {
  useEffect(() => {
    window.location.href = TERMS_OF_USE;
  }, []);

  return <Loader />;
};

export default TermsAndConditions;
