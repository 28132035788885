import React, { Component } from "react";
import { ReactTitle } from "react-meta-tags";

import {
  Header,
  Loader,
  Banner,
  ValueProp,
  CallToAction,
  ClientStory,
  Footer,
} from "../../common";
import Pricing from "./Pricing";

import * as networkProviderService from "../../service/networkProviderService";
import * as service from "../../service";

import commonConstants from "../../constants/commonConstant";

class DpcSales extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cosmicContent: {
        banner: {},
        valueProps: {},
        cta: {},
        clientStories: [],
        pricing: {
          basic: {},
          premium: {},
          professional: {},
        },
        seo: {},
      },
      loading: false,
    };
  }

  componentDidMount() {
    this.getCosmicContent();
    this.getClientStories();
  }

  searchProviders = (params, autocomplete = true) => {
    params.category = "medicalNetwork";
    params.network = "VITAFY";
    params.entityCode = 2;
    return new Promise((resolve, reject) => {
      networkProviderService
        .searchProvider(params)
        .then((response) => {
          if (response.status === "success") {
            response.data.providerList.map((x) => {
              x.providerName = x.displayName;
              return x;
            });
            resolve(response.data.providerList);
          }
        })
        .catch((error) => {
          console.log("error", error);
          reject(error);
        });
    });
  };

  getCosmicContent = () => {
    this.setState({ loading: true });
    service
      .getDpcCosmicContent()
      .then((response) => {
        if (response.status === "success") {
          const dpcSales = response.data.find(
            (item) => item.slug === "dpc-sales"
          ).metadata;
          if (dpcSales) {
            this.setState((prevState) => ({
              cosmicContent: {
                ...prevState.cosmicContent,
                banner: dpcSales.banner,
                valueProps: dpcSales.value_props,
                cta: dpcSales.cta,
                pricing: {
                  basic: dpcSales.pricing.find(
                    (item) => item.plan.toLowerCase() == "basic"
                  ),
                  premium: dpcSales.pricing.find(
                    (item) => item.plan.toLowerCase() == "premium"
                  ),
                  professional: dpcSales.pricing.find(
                    (item) => item.plan.toLowerCase() == "professional"
                  ),
                },
                seo: dpcSales.seo,
              },
            }));
          }
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        console.log("Error in get DPC Sales cosmic content", error);
        this.setState({ loading: false });
      });
  };

  getClientStories = () => {
    this.setState({ loading: true });
    service
      .getClientStories()
      .then((result) => {
        if (result.status === "success") {
          this.setState((prevState) => ({
            cosmicContent: {
              ...prevState.cosmicContent,
              clientStories: result.data,
              loading: false,
            },
          }));
        }
      })
      .catch((error) => {
        console.log("Error in get DPC Sales: Client Stories", error);
        this.setState({ loading: false });
      });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <ReactTitle
          title={
            this.state.cosmicContent.seo?.metadata?.page_title ||
            commonConstants.SEO.DEFAULT_PAGE_TITLE
          }
        />

        <Header />
        <Banner banner={this.state.cosmicContent.banner} />

        <div className="mid-divider"></div>
        <ValueProp
          title={this.state.cosmicContent.valueProps?.mission_statement}
          valuePropsTitleVisibility={false}
          values={this.state.cosmicContent.valueProps?.value_props}
          bgColor={false}
        />

        <CallToAction
          title={this.state.cosmicContent.cta?.title}
          description={this.state.cosmicContent.cta?.content}
          actionButtonText={this.state.cosmicContent.cta?.action_button_text}
          actionButtonLink="/"
          requestDemo={true}
        />
        <div className="divider"></div>

        <Pricing pricing={this.state.cosmicContent.pricing} />
        <div className="divider"></div>

        <CallToAction
          title="Still have a question?"
          description="If you still have questions and would like to talk to our DPC specialist."
          actionButtonText="Click here"
          actionButtonLink="/"
          requestDemo={true}
        />
        <div className="divider"></div>

        <ClientStory
          clientStories={this.state.cosmicContent?.clientStories}
          partners={[]}
          visibility={false}
          bgColor={false}
        />

        <Footer />
      </>
    );
  }
}

export default DpcSales;
