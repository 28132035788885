/**
 * Modal that pops up after when user clicks next from step 2 in walk-in
 * Displays info message that pricing has been reflected as per member/non-member
 */

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { Modal } from '../../../common';

const PricingReflectModal = (props) => {
  const {
    isMember,
    title,
    modalRef,
    content,
    handleContinueToCheckout,
    handleCancelToCheckout
  } = props;

  return (
    <Modal
      modalTitle={title ? title : ''}
      ref={modalRef}
      maxWidth='max-w-xl'
      marginTop='mt-32'
      hideHeader={true}
      showCloseButton={isMember}  // show Ok button only if member is found
      closeHandler={handleContinueToCheckout}
    >
      {!isMember &&
        <FontAwesomeIcon
          icon={faExclamationCircle}
          className='block mx-auto text-red-500 text-3xl md:text-4xl'
        />
      }
      <div className='mt-4 flex justify-center'>
        <p className='body-text text-lg text-titleColor text-center mb-4 inline'>{content}</p>
      </div>
      {/* show Continue and Cancel buttons if member is not found */}
      {!isMember &&
        <div className='flex items-center justify-between mt-10'>
          <button
            className='mid-button px-4 py-3 bg-transparent text-dedicatedDpcSecondary hover:bg-gray-300'
            onClick={handleCancelToCheckout}
            type='button'
          >
            Cancel
          </button>
          <button
            className='mid-button px-10 py-3 bg-dedicatedDpcSecondary hover:bg-dedicatedDpcHovered'
            onClick={handleContinueToCheckout}
            type='button'
          >
            Continue
          </button>
        </div>
      }
    </Modal>
  );
}


export default PricingReflectModal;
