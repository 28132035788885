import React from 'react';
// import GoogleMapReact from 'google-map-react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faLocationArrow } from '@fortawesome/free-solid-svg-icons';
// import { K_SIZE } from '../dpc-landing/placeHoverStyles';
// import { popoverWrapper, popoverWrapperHover, popoverContent, popoverContentHover } from '../dpc-landing/placeHoverStyles';
// import { getAddressDisplayName } from '../../util/vitafyUtil';
// import MapMarker from '../../assets/images/dpc-marker-pin.svg';

// class Map extends Component {

//   render() {
//     const center = {
//       lat: this.props.lat, // center defaults to Milwaukee, WI
//       lng: this.props.lng
//     }
//     const zoom = 5;

//     return (
//       <>
//         <div className='w-full relative' style={{ height: '14rem' }}>
//           {/* {center.lat && center.lng &&
//             <GoogleMapReact
//               bootstrapURLKeys={{ key: 'AIzaSyCo0hj8XUcjtwn3icKS041OZJy6d1_QfGc' }}
//               defaultCenter={center}
//               defaultZoom={zoom}
//               hoverDistance={K_SIZE / 2}
//             >
//               <DpcLocation
//                 lat={this.props.lat}
//                 lng={this.props.lng}
//               />
//             </GoogleMapReact>
//           } */}
//         </div>
//       </>
//     );
//   }
// }

// const DpcLocation = (props) => {
//   // const popoverWrapperStyle = props.$hover ? popoverWrapperHover : popoverWrapper;
//   // const popoverContentStyle = props.$hover ? popoverContentHover : popoverContent;
//   return (
//     <div style={popoverWrapper}>
//       <img
//         src={MapMarker}
//         className='popover__title cursor-pointer'
//         alt='dpc-marker-pin'
//       />
//       {/* <div style={popoverContentStyle}>
//         <p className='body-text text-primary text-xs font-bold mb-1'>{props.displayName}</p>
//         <li className={`flex items-start mt-0`}>
//           <div className='w-3 mt-1 mr-1'>
//             <FontAwesomeIcon icon={faLocationArrow} className={`text-primary`} />
//           </div>
//           <p className={`body-text text-xs`}>{getAddressDisplayName(props.address, true)}</p>
//         </li>
//       </div> */}
//     </div>
//   );
// }

// export default Map;

const Map = () => {
  return (
    <div className="p-12">
      {' '}
      There seems to be an issue while diisplaying the map. Our team is working
      on it.
    </div>
  );
};

export default Map;
