import React from 'react';
import Fade from 'react-reveal/Fade';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { parseHtml } from '../util';

const ValueProp = ({
  title,
  values,
  valuePropsTitle,
  valuePropsTitleVisibility,
  bgColor,
  bgColorClassName = 'bg-extended',
  btnOnClick,
  actionBtnTxt='Learn More',
  handleActionBtnClick
}) => {
  const display =
    values &&
    values.map((item, index) => {
      return (
        <React.Fragment key={index}>
          <Fade bottom>
            <div
              className={`w-full px-4 mb-12 ${values.length > 3 ? 'md:mb-12' : 'md:mb-0'
                } ${values.length !== 6 && values.length % 2 === 0
                  ? 'md:w-1/2'
                  : 'md:w-1/3'
                }`}
            >
              <div className={`flex justify-center mb-5`}>
                <img
                  src={item.metadata.image.url}
                  className='block h-12 lg:h-16'
                  alt='value-icon'
                />
              </div>
              <div className='w-full text-center'>
                <h4
                  className={`mt-2 mb-4 ${!bgColor || bgColorClassName !== 'bg-extended' ? 'text-titleColor' : 'text-white'}`}
                >
                  {item.metadata.display_title}
                </h4>
                <div
                  className={`body-text ${!bgColor || bgColorClassName !== 'bg-extended' ? 'text-bodyColor' : 'text-white'
                    }`}
                  dangerouslySetInnerHTML={{
                    __html: parseHtml(item.content),
                  }}
                />
              </div>
            </div>
          </Fade>
        </React.Fragment>
      );
    });

  return (
    <div className={`w-full ${bgColor ? `${bgColorClassName}` : ''}`}>
      <div className={`divider ${bgColor ? 'block' : 'hidden'}`}></div>
      <div className='container mx-auto overflow-hidden px-4'>
        {title &&
          <>
            <Fade bottom>
              <div
                className={`mission-statement ${!bgColor ? 'text-titleColor' : 'text-white'
                  }`}
                dangerouslySetInnerHTML={{ __html: parseHtml(title) }}
              />
            </Fade>
            <div
              className={`divider-line  my-12 lg:my-16 ${valuePropsTitleVisibility && valuePropsTitle && 'lg:mb-12'} ${!bgColor ? 'bg-gray-600' : 'bg-white'
                }`}
            ></div>
          </>
        }

        {valuePropsTitleVisibility && valuePropsTitle && (
          <Fade bottom>
            <h5 className='heading-center mx-auto w-full text-center'>
              {valuePropsTitle}
            </h5>
          </Fade>
        )}

        {values !== undefined ? (
          <div className='px-0 mx:px-6 flex flex-wrap justify-center md:-mx-4'>
            {display}
          </div>
        ) : null}
      </div>

      {btnOnClick &&
        <button
          className='big-button shadow transition duration-500 ease-in-out hover:shadow-lg focus:outline-none block mx-auto cta-button-margin-top'
          onClick={handleActionBtnClick}
        >
          {actionBtnTxt}
          <FontAwesomeIcon
            icon={faArrowRight}
            className='inline-block ml-2 '
          />
        </button>
      }

      <div className='divider'></div>
    </div>
  );
};

export default ValueProp;
