import React, { useContext, useEffect } from 'react';
import Select from 'react-select';

import { useFormContext } from 'react-hook-form';
import commonConstants from '../../constants/commonConstant';
import { store } from '../../store';
import { getTextFromLangDict } from '../../util';

const {
  A_POSITIVE,
  O_POSITIVE,
  B_POSITIVE,
  AB_POSITIVE,
  A_NEGATIVE,
  O_NEGATIVE,
  B_NEGATIVE,
  AB_NEGATIVE,
  UNK,
} = commonConstants.BLOOD_GROUP;

const BLOOD_GROUP_OPTIONS = [
  { label: A_POSITIVE, value: A_POSITIVE },
  { label: A_NEGATIVE, value: A_NEGATIVE },
  { label: B_POSITIVE, value: B_POSITIVE },
  { label: B_NEGATIVE, value: B_NEGATIVE },
  { label: AB_POSITIVE, value: AB_POSITIVE },
  { label: AB_NEGATIVE, value: AB_NEGATIVE },
  { label: O_POSITIVE, value: O_POSITIVE },
  { label: O_NEGATIVE, value: O_NEGATIVE },
  { label: UNK, value: UNK },
];

const SelectBloodGroup = () => {
  const {
    watch,
    setValue,
    formState: { errors },
    clearErrors,
  } = useFormContext();

  // Context Store
  const state = useContext(store);
  const { globalState } = state;
  const { groupCode, langDict, tenantCode } = globalState;

  const bloodGroupWatched = watch('bloodGroup');

  const onChange = (bloodGroup) => {
    setValue('bloodGroup', bloodGroup?.value || '');
  };

  const activeOption =
    BLOOD_GROUP_OPTIONS.filter((item) => item.value === bloodGroupWatched) ||
    [];

  // Clear error on field change
  useEffect(() => {
    if (bloodGroupWatched) {
      clearErrors('bloodGroup');
    }
  }, [bloodGroupWatched, clearErrors]);

  return (
    <>
      <label className="form-label text-sm md:whitespace-no-wrap">
        {getTextFromLangDict(langDict, {
          key: '_BLOOD_GROUP_INPUT_LABEL',
          groupCode,
          tenantCode,
        })}
      </label>
      <Select
        value={activeOption}
        options={BLOOD_GROUP_OPTIONS}
        onChange={onChange}
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        isClearable
      />
      {!!errors.bloodGroup && (
        <span className="text-xs text-errorTxt">
          {errors.bloodGroup.message}
        </span>
      )}
    </>
  );
};

export default SelectBloodGroup;
