import React from "react";
import ReactLoading from "react-loading";
import NumberFormat from "react-number-format";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faUser,
  faArrowRight,
  faCommentAlt,
  faChevronDown,
  faUserTie,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

// TODO: Newer RequestDemoModal has been created under common. Use that.

const RequestDemoForm = (props) => {
  return (
    <form onSubmit={props.handleSubmit}>
      {/* Full Name */}
      <div className="w-full mb-6">
        <label
          className="block font-heebo text-md text-bodyColor mb-2"
          htmlFor="full-name"
        >
          Full Name
        </label>
        <div className="relative">
          <FontAwesomeIcon icon={faUser} className="contact-form-icon" />
          <input
            id="full-name"
            type="text"
            placeholder=""
            className="text-input"
            value={props.name}
            onChange={props.handleFullNameChange}
            required
          />
        </div>
        {/* <p className='text-red-500 text-xs italic'>
          Please fill out this field.
        </p> */}
      </div>

      <div className="flex flex-wrap items-start md:mb-6 -mx-3">
        {/* Email */}
        <div className="w-full md:w-1/2 mb-6 md:mb-0 px-3">
          <label
            className="block font-heebo text-md text-bodyColor mb-2"
            htmlFor="email"
          >
            Email
          </label>
          <div className="relative">
            <FontAwesomeIcon icon={faEnvelope} className="contact-form-icon" />
            <input
              id="email"
              type="email"
              placeholder=""
              className="text-input"
              value={props.email}
              onChange={props.handleEmailChange}
              required
            />
          </div>
        </div>
        {/* Phone */}
        <div className="w-full md:w-1/2 mb-6 md:mb-0 px-3">
          <label
            className="block font-heebo text-md text-bodyColor mb-2"
            htmlFor="phone"
          >
            Phone
          </label>
          <div className="relative">
            <FontAwesomeIcon icon={faPhone} className="contact-form-icon" />
            <NumberFormat
              id="phone"
              format="(###) ###-####"
              mask="_"
              name="phone"
              value={props.phone}
              placeholder="(222) 334-2424"
              style={{ textIndent: "2.5rem" }}
              className="text-input"
              onValueChange={(value) =>
                props.handlePhoneNumberChange(value.formattedValue)
              }
            />
          </div>
        </div>
      </div>
      {/* User Type */}
      <div className="w-full mb-6">
        <label
          className="block font-heebo text-md text-bodyColor mb-2"
          htmlFor="user-type"
        >
          Type
        </label>
        <div className="relative">
          <FontAwesomeIcon icon={faUserTie} className="contact-form-icon" />
          <FontAwesomeIcon
            icon={faChevronDown}
            className="contact-form-icon-select"
            style={{ right: "20px" }}
          />
          <select
            name="type"
            className="text-input"
            value={props.profession}
            onChange={props.handleTypeChange}
          >
            <option aria-label="None" value="" />
            <option value="Agent & Advisor">Agent & Advisor</option>
            <option value="Direct Primary Care">Direct Primary Care</option>
            <option value="Direct Specialty Care">Direct Specialty Care</option>
            <option value="Care Advocate">Care Advocate</option>
            <option value="Employer & Group">Employer & Group</option>
            <option value="Provider">Provider</option>
            <option value="Consumer">Consumer</option>
            <option value="Other">Other</option>
          </select>
        </div>
        {/* <p className='text-red-500 text-xs italic'>
          Please fill out this field.
        </p> */}
      </div>
      {/* Message */}
      <div className="w-full mb-6">
        <label
          className="block font-heebo text-md text-bodyColor mb-2"
          htmlFor="message"
        >
          Message
        </label>
        <div className="relative">
          <FontAwesomeIcon icon={faCommentAlt} className="contact-form-icon" />
          <textarea
            id="message"
            rows="5"
            // type='email'
            placeholder=""
            className="text-input"
            value={props.message}
            onChange={props.handleMessageChange}
            required
          />
        </div>
        {/* <p className='text-red-500 text-xs italic'>
          Please fill out this field.
        </p> */}
      </div>
      <div className="flex items-center justify-end px-2 pt-3 border-t border-solid border-gray-300 rounded-b">
        <button
          className="text-bodyColor bg-transparent hover:bg-bgColor font-bold  px-6 py-3 text-base outline-none rounded-full focus:outline-none mr-1 mb-1 transition duration-500 ease-in-ou"
          type="button"
          onClick={props.closeModal}
        >
          Close
        </button>
        <button
          className="bg-secondary hover:bg-hovered ease-out block text-white font-heebo text-lg px-6 py-3 rounded-full outine-none w-full md:w-auto focus:outline-none"
          type="submit"
        >
          Submit
          {props.sending ? (
            <ReactLoading
              className="inline-block ml-3 pt-1"
              type="spin"
              color="white"
              height={"20px"}
              width={"20px"}
            />
          ) : (
            <FontAwesomeIcon
              icon={faArrowRight}
              className="text-white text-md ml-3"
            />
          )}
        </button>
      </div>
    </form>
  );
};

export default RequestDemoForm;
