import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const Redirect = ({ redirectPath }) => {
  const history = useHistory();

  useEffect(() => {
    if(redirectPath) {
      history.push(redirectPath)
      window.location.reload();
    }

  }, [redirectPath, history]);
  
  return (
    <p>...</p>
  )
}

export default Redirect