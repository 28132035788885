import React, { useContext } from 'react';
import { store } from '../../../store';
import { PAYMENT_GATEWAY_TYPES } from '../../../constants/payment';
import CardConnectCardPayment from './card-connect/CardPayment';
import AuthorizeDotNetCardPayment from './authorize-dot-net/CardPayment';
import { CardPayment as StripeCardPayment } from './stripe/CardPayment';

const { CARD_CONNECT, AUTHORIZE_DOT_NET, STRIPE } = PAYMENT_GATEWAY_TYPES;

const CardPayment = ({
  filledCardData,
  onChangeData,
  onAddNewCardClick,
  cardErrorMessage,
  errorMessage,
  onClearCardError,
  isSubmitting,
}) => {
  // Context Store
  const state = useContext(store);
  const { globalState } = state;
  const { merchantProfile } = globalState;

  if (merchantProfile?.gatewayType === STRIPE) {
    return (
      <StripeCardPayment
        clearError={onClearCardError}
        filledCardData={filledCardData}
        onChangeData={onChangeData}
        onAddNewCardClick={onAddNewCardClick}
        cardErrorMessage={cardErrorMessage}
        errorMessage={errorMessage}
        isSubmitting={isSubmitting}
      />
    );
  }

  if (merchantProfile?.gatewayType === AUTHORIZE_DOT_NET) {
    return (
      <AuthorizeDotNetCardPayment
        clearError={onClearCardError}
        filledCardData={filledCardData}
        onChangeData={onChangeData}
        onAddNewCardClick={onAddNewCardClick}
        cardErrorMessage={cardErrorMessage}
        errorMessage={errorMessage}
        isSubmitting={isSubmitting}
      />
    );
  }

  if (merchantProfile?.gatewayType === CARD_CONNECT) {
    return (
      <CardConnectCardPayment
        clearError={onClearCardError}
        filledCardData={filledCardData}
        onChangeData={onChangeData}
        onAddNewCardClick={onAddNewCardClick}
        cardErrorMessage={cardErrorMessage}
        errorMessage={errorMessage}
        isSubmitting={isSubmitting}
      />
    );
  }

  return <>Payment gateway out of options</>;
};

export default CardPayment;
