/**
 * @author Slesha Tuladhar <slesha@noveltytechnology.com>
 */

import * as httpService from './httpService';
import APICONSTANTS from '../constants';

export function searchEsProviders(searchKeyword, category) {
  return httpService.get(`${APICONSTANTS.CLOUD_PROVIDERS}/${searchKeyword}/${category}`)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    })
}

export function fetchEsProviders(body) {
  return httpService.post(`${APICONSTANTS.CLOUD_PROVIDERS}`, body)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    })
}

export function fetchProvidersByNpi(npi) {
  return httpService.get(`${APICONSTANTS.CLOUD_PROVIDERS}/${npi}`)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    })
}