import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { countryCode } from '../../constants/countryCode';
import { googleAutocompleteAddressMap } from '../../util/google-autocomplete-country';

/** An object for creating google places autocomplete instance */
let autoCompleteObj;

async function handlePlaceChanged(callbackFunc, autoComplete) {
  const addressObject = autoComplete.getPlace();
  callbackFunc(addressObject);
}

function handleScriptLoad(callbackFunc, selectedCountryCode) {
  const options = {
    types: ['geocode'],
    componentRestrictions: { country: selectedCountryCode || countryCode.USA },
    fields: ['address_components', 'formatted_address', 'geometry.location'],
  };
  /** Original `autoCompleteObj` object should not be mutated.
   *  If mutated, the google autocomplete will only be initialized for the latest object.
   *  This can cause issues like google autocomplete not working for multiple addresses on the same page
   */
  let autoCompleteInstance = autoCompleteObj;

  autoCompleteInstance = new window.google.maps.places.Autocomplete(
    document.getElementById('autocomplete'),
    options
  );
  autoCompleteInstance.setFields(['address_components', 'formatted_address']);
  autoCompleteInstance.addListener('place_changed', () =>
    handlePlaceChanged(callbackFunc, autoCompleteInstance)
  );
}

const FormGoogleAutocompleteInput = ({
  onPopulateFields,
  label,
  icon,
  selectedCountryCode,
}) => {
  const {
    formState: { errors },
    control,
  } = useFormContext();
  const onPopulateFieldsRef = useRef(onPopulateFields);

  const populateFields = useCallback(
    (addressObj) => {
      const address = addressObj?.address_components;
      if (!address) return;
      const rawAddress = {
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zip: '',
        lat: '',
        lng: '',
      };

      address.forEach((x) => {
        googleAutocompleteAddressMap(
          x,
          rawAddress,
          selectedCountryCode || countryCode.USA
        );
      });
      rawAddress.lat = addressObj.geometry.location.lat();
      rawAddress.lng = addressObj.geometry.location.lng();
      onPopulateFieldsRef.current(rawAddress);
    },
    [selectedCountryCode, onPopulateFieldsRef]
  );

  useEffect(() => {
    if (selectedCountryCode) {
      handleScriptLoad(populateFields, selectedCountryCode);
    }
  }, [populateFields, selectedCountryCode]);

  return (
    <>
      {/* <Script
        url={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_PLACES_AUTOCOMPLETE_API_KEY}&libraries=places`}
        onLoad={() => handleScriptLoad(populateFields, selectedCountryCode)}
      /> */}

      <Controller
        key={selectedCountryCode}
        control={control}
        name="addressLine1"
        render={({ field }) => (
          <div className="search-location-input">
            <div className="mdp-input-main">
              <div className="mdp-input-box">
                <input
                  {...field}
                  id="autocomplete"
                  className="mdp-input bg-transparent text-titleColor font-heebo"
                  placeholder="Enter location"
                  style={{ textIndent: icon ? '2rem' : '' }}
                />
                <label
                  htmlFor="autocomplete"
                  className={`mdp-label ${icon ? 'ml-8' : null}`}
                >
                  {label}
                </label>
                {icon && <FontAwesomeIcon icon={icon} className="mdp-icon" />}
                {!!errors.addressLine1 && (
                  <FontAwesomeIcon
                    icon={faExclamationCircle}
                    className="text-red-500"
                    style={{
                      position: 'absolute',
                      top: '10px',
                      right: '0px',
                      fontSize: '16px',
                    }}
                  />
                )}
                <div
                  className={`${
                    !!errors.addressLine1
                      ? 'mdp-input-error-underline'
                      : 'mdp-input-underline'
                  }`}
                />
              </div>
            </div>
          </div>
        )}
      />
    </>
  );
};

FormGoogleAutocompleteInput.defaultProps = {
  label: 'Address Line 1',
};

export default FormGoogleAutocompleteInput;
