import {
  faClipboard,
  faClipboardCheck,
  faFileInvoiceDollar,
  faFileMedical,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useRef } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useDispatch, useSelector } from 'react-redux';
import withSizes from 'react-sizes';
import commonConstants from '../../constants/commonConstant';
import { store } from '../../store';
import {
  selectInformationTab,
  selectIsQuestionaireEnabled,
  selectStep,
  setInformationTabValues,
} from '../../stores/membership';
import { selectActiveQuestion, selectPhase } from '../../stores/questionaire';
import { getTextFromLangDict } from '../../util';
import { getEnrollmentTenantSetting } from '../../util/tenantSettingUtil';
import Membership from '../dedicated-dpc-help-center/membership';

const {
  MEMBERSHIP_REGISTRATION_TYPES,
  MEMBERSHIP_ENROLLMENT_STEPS,
  TENANT_SETTINGS_CODE,
} = commonConstants;
const { INFORMATION, QUESTIONAIRE, MEMBERSHIP, CHECKOUT } =
  MEMBERSHIP_ENROLLMENT_STEPS;

const Enrollment = (props) => {
  const { isWidthLessThan768 } = props;

  const dispatch = useDispatch();

  // Redux Store
  const step = useSelector(selectStep);
  const informationTab = useSelector(selectInformationTab);
  const selectedEnrollmentType = informationTab?.type;
  const isQuestionaireEnabled = useSelector(selectIsQuestionaireEnabled);
  const activeQuestion = useSelector(selectActiveQuestion);
  const activeQuestionId = activeQuestion?.id || '';
  const phase = useSelector(selectPhase);

  // Context
  const state = useContext(store);
  const { globalState } = state;
  const { langDict, isLoading, groupCode, tenantCode, tenantSettings } =
    globalState;

  const isFirstMount = useRef(true);
  const enrollmentFormHeadingRef = useRef(null);

  const enrollmentTypesFromSettings = getEnrollmentTenantSetting(
    TENANT_SETTINGS_CODE.CLIENT_ENROLLMENT_TYPE,
    { tenantSettings }
  );

  /**
   * Pre-select 'Company' if only company is present and 'Individual' if only individual or both is present
   *
   * Run this side-effect only if type is not selected already.
   */
  useEffect(() => {
    if (!!enrollmentTypesFromSettings && !selectedEnrollmentType) {
      const isIndividualTypePresent = enrollmentTypesFromSettings?.some(
        (item) => item === MEMBERSHIP_REGISTRATION_TYPES.individual.value
      );

      dispatch(
        setInformationTabValues({
          type: isIndividualTypePresent
            ? MEMBERSHIP_REGISTRATION_TYPES.individual.value
            : MEMBERSHIP_REGISTRATION_TYPES.company.value,
        })
      );
    }
  }, [dispatch, enrollmentTypesFromSettings, selectedEnrollmentType]);

  const getDictionaryKeyForStep = () => {
    if (step === INFORMATION) return '_CLIENT_INFORMATION_PLAIN_TEXT';
    if (step === QUESTIONAIRE) return '_ELIGIBILITY_QUESTIONAIRE_PLAIN_TEXT';
    if (step === MEMBERSHIP) return '_MEMBERSHIP_PLAN_PLAIN_TEXT';
    if (step === CHECKOUT) return '_PAYMENT_INFORMATION_PLAIN_TEXT';
    return '';
  };

  // Scroll to top of enrollment form on prev/next action (for better UX)
  useEffect(() => {
    if (isFirstMount.current) {
      isFirstMount.current = false;
    } else {
      // Your useEffect code here to be run on update
      enrollmentFormHeadingRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [step, activeQuestionId, phase]);

  if (isLoading || !langDict) {
    return <>...</>;
  }

  return (
    <>
      <div className="container mx-auto px-4">
        <h3
          className="heading text-2xl md:text-3xl mb-3 md:mb-8"
          ref={enrollmentFormHeadingRef}
        >
          {getTextFromLangDict(langDict, {
            key: '_ENROLLMENT_FORM',
            groupCode,
            tenantCode,
          })}
        </h3>
      </div>
      <div className="w-full bg-bgColor overflow-hidden">
        {/* STEP ILLUSTRATION STARTS HERE */}
        <div className="container px-4 lg:flex mx-auto">
          <div className="w-full lg:w-1/3 py-6 lg:flex lg:justify-start lg:items-center relative z-0">
            <div className="overlay-overcome flex justify-center text-center lg:text-left lg:block">
              {/* Information Step */}
              <div className="lg:flex lg:flex-initial mr-4 lg:mr-0 lg:mb-16 step1">
                <div
                  className={`mx-auto lg:ml-0 w-12 h-12 lg:w-16 lg:h-16 rounded-full mr-3 mb-3 lg:mb-4 shadow-lg flex justify-center items-center relative ${
                    props.isWidthLessThan1024
                      ? 'circle-horizontal'
                      : 'circle-vertical'
                  } ${
                    step === INFORMATION ? 'bg-dedicatedDpcPrimary' : 'bg-white'
                  }`}
                >
                  <FontAwesomeIcon
                    icon={faClipboard}
                    className={` text-2xl ${
                      step === INFORMATION ? 'text-white' : 'text-bodyColor'
                    }`}
                  />
                </div>
                {!isWidthLessThan768 && (
                  <h6
                    className={`text-sm lg:text-xl ${
                      step === INFORMATION
                        ? 'font-bold text-dedicatedDpcPrimary'
                        : 'font-normal'
                    }`}
                  >
                    {ReactHtmlParser(
                      getTextFromLangDict(langDict, {
                        key: '_CLIENT_INFORMATION',
                        groupCode,
                        tenantCode,
                      })
                    )}
                  </h6>
                )}
              </div>
              {/* Questionaire Step */}
              {isQuestionaireEnabled && (
                <div className="lg:flex lg:flex-initial mr-4 lg:mr-0 lg:mb-16 step2">
                  <div
                    className={`mx-auto lg:ml-0 w-12 h-12 lg:w-16 lg:h-16 rounded-full mr-3 mb-3 lg:mb-4 shadow-lg flex justify-center items-center relative ${
                      props.isWidthLessThan1024
                        ? 'circle-horizontal'
                        : 'circle-vertical'
                    } ${
                      step === QUESTIONAIRE
                        ? 'bg-dedicatedDpcPrimary'
                        : 'bg-white'
                    }`}
                  >
                    <FontAwesomeIcon
                      icon={faClipboardCheck}
                      className={` text-2xl ${
                        step === QUESTIONAIRE ? 'text-white' : 'text-bodyColor'
                      }`}
                    />
                  </div>
                  {!isWidthLessThan768 && (
                    <h6
                      className={`text-sm lg:text-xl ${
                        step === QUESTIONAIRE
                          ? 'font-bold text-dedicatedDpcPrimary'
                          : 'font-normal'
                      }`}
                    >
                      {ReactHtmlParser(
                        getTextFromLangDict(langDict, {
                          key: '_ELIGIBILITY_QUESTIONAIRE',
                          groupCode,
                          tenantCode,
                        })
                      )}
                    </h6>
                  )}
                </div>
              )}
              {/* Membership Step */}
              <div className="lg:flex lg:flex-initial mr-4 lg:mr-0 lg:mb-16 step2">
                <div
                  className={`mx-auto lg:ml-0 w-12 h-12 lg:w-16 lg:h-16 rounded-full mr-3 mb-3 lg:mb-4 shadow-lg flex justify-center items-center relative ${
                    props.isWidthLessThan1024
                      ? 'circle-horizontal'
                      : 'circle-vertical'
                  } ${
                    step === MEMBERSHIP ? 'bg-dedicatedDpcPrimary' : 'bg-white'
                  }`}
                >
                  <FontAwesomeIcon
                    icon={faFileMedical}
                    className={` text-2xl ${
                      step === MEMBERSHIP ? 'text-white' : 'text-bodyColor'
                    }`}
                  />
                </div>
                {!isWidthLessThan768 && (
                  <h6
                    className={`text-sm lg:text-xl ${
                      step === MEMBERSHIP
                        ? 'font-bold text-dedicatedDpcPrimary'
                        : 'font-normal'
                    }`}
                  >
                    {ReactHtmlParser(
                      getTextFromLangDict(langDict, {
                        key: '_MEMBERSHIP_PLAN',
                        groupCode,
                        tenantCode,
                      })
                    )}
                  </h6>
                )}
              </div>
              {/* Checkout step */}
              <div className="lg:flex lg:flex-initial step3">
                <div
                  className={`mx-auto lg:ml-0 w-12 h-12 lg:w-16 lg:h-16 rounded-full mr-3 mb-3 shadow-md flex justify-center items-center ${
                    step === CHECKOUT ? 'bg-dedicatedDpcPrimary' : 'bg-white'
                  }`}
                >
                  <FontAwesomeIcon
                    icon={faFileInvoiceDollar}
                    className={` text-2xl ${
                      step === CHECKOUT ? 'text-white' : 'text-bodyColor'
                    }`}
                  />
                </div>
                {!isWidthLessThan768 && (
                  <h6
                    className={`text-sm lg:text-xl ${
                      step === CHECKOUT
                        ? 'font-bold text-dedicatedDpcPrimary'
                        : 'font-normal'
                    }`}
                  >
                    {ReactHtmlParser(
                      getTextFromLangDict(langDict, {
                        key: '_PAYMENT_INFORMATION',
                        groupCode,
                        tenantCode,
                      })
                    )}
                  </h6>
                )}
              </div>
            </div>

            {isWidthLessThan768 && (
              <h3 className="text-titleColor text-lg font-medium text-center">
                {getTextFromLangDict(langDict, {
                  key: getDictionaryKeyForStep(),
                  groupCode,
                  tenantCode,
                })}
              </h3>
            )}

            {/* STEP ILLUSTRATION ENDS HERE */}
            <div className="overlay max-width-escape-bg"></div>
          </div>
          <div className="w-full lg:w-2/3 lg:pl-10">
            <div className="enrollment-form-wrapper">
              <div className="inner-divider" />
              <Membership whiteBg={false} encloseInContainer={false} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapSizesToProps = ({ width }) => ({
  isWidthLessThan1024: width < 1024,
  isWidthLessThan768: width < 768,
});

export default withSizes(mapSizesToProps)(Enrollment);
