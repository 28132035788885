import React from 'react';

import Layout from './Layout';
import PriceListForm from './PriceListForm';
import PriceListCheckout from './PriceListCheckout';
import OptionBox from './OptionBox';

const PriceList = (props) => {
  return <Layout left={<LeftContent pricingListOptions={props.pricingListOptions} />} right={<RightContent {...props} />} />;
};

export default PriceList;

const LeftContent = ({ pricingListOptions }) => {
  return (
    <div className="flex flex-col lg:block">
      <h3 className="heading">Price List</h3>
      <p className="body-text text-titleColor mr-10">
        {' '}
        Patients and their DPC Doctor contract directly without added costs and
        interference from middlemen. Patients join a DPC practice as a member
        and receive ongoing primary care services.
      </p>

      {pricingListOptions && pricingListOptions.map((item, index) =>
        <div className={`flex w-full mb-5 ${index === 0 ? 'mt-12' : ''} `} key={index}>
          <p className="w-1/2 body-text font-bold text-titleColor">{item.label}</p>
          <p className="w-1/2 body-text font-bold text-titleColor">${item.price}</p>
        </div>
      )}
    </div>
  );
};

const RightContent = (props) => {

  const { activeOption, changeActiveOption, monthlyCost,
    checkoutReady, data, address, changeCheckoutReady,
    handleBackBtnPress, iAgreeTerms, changeIAgreeTerms, priceListFormSubmit,
    walkinLoading, changeWalkinLoading, changeMonthlyCost, pricingListOptions } = props;

  return (
    <>
      <div className="flex -mx-2">
        {pricingListOptions && pricingListOptions.map((item, index) =>
          <OptionBox
            option={item}
            title={item.label}
            activeOption={activeOption}
            changeActiveOption={changeActiveOption}
            key={index} />
        )}
        {/* Others */}
        <OptionBox
          option={null}
          title='Other'
          activeOption={activeOption}
          changeActiveOption={changeActiveOption}
        />
      </div>

      {!checkoutReady ? (
        <PriceListForm changeCheckoutReady={changeCheckoutReady} data={data} address={address} />
      ) : (
          <PriceListCheckout
            monthlyCost={monthlyCost}
            handleBackBtnPress={handleBackBtnPress}
            priceListFormSubmit={priceListFormSubmit}
            iAgreeTerms={iAgreeTerms}
            changeIAgreeTerms={changeIAgreeTerms}
            walkinLoading={walkinLoading}
            changeMonthlyCost={changeMonthlyCost}
            activeOption={activeOption}
            changeWalkinLoading={changeWalkinLoading}
          />
        )}
    </>
  );
};


