import React from 'react';
import withSizes from 'react-sizes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch
} from '@fortawesome/free-solid-svg-icons';


const BannerWithSearch = (props) => {
  const { title, subtitle, subtitleVisibility, searchHandler, isSubmitting, showOr = false, includeDisclaimer = false, requestDemoClick } = props;

  return (
    <div className='w-full pt-24 lg:pt-30 pb-10 lg:pb-10 bg-extended'>
      <div className='container mx-auto px-4'>
        {subtitleVisibility ?
          <>
            <h1 className='font-heebo uppercase font-bold text-white text-3xl lg:text-5xl'>
              {title}
            </h1>
            <h6 className='firstContentHeading normal-case font-normal text-base lg:text-xl mt-0 pt-0'>
              {subtitle}
            </h6>
          </>
          :
          <h1 className='firstContentHeading normal-case'>{title}</h1>
        }
        <div className='flex flex-wrap -mx-2 lg:-mx-5 mt-10 my-6'>
          <div className='px-2 lg:px-5 flex-grow mb-4 md:mb-0'>
            <div className='flex flex-wrap -mx-2'>
              <div className='w-full md:w-3/5 lg:w-2/3 px-2 flex items-center'>
                <div className='flex-grow mb-4 md:mb-0'>
                  {props.leftSearch}
                </div>
                {showOr &&
                  <div className='w-10 h-10 rounded-full hidden md:flex justify-center items-center body-text text-lg  ml-3 text-white'> OR</div>
                }
              </div>
              {showOr &&
                <div className='w-10 h-10 rounded-full flex md:hidden justify-center items-center body-text  text-lg text-white mx-auto mb-4'> OR</div>
              }
              <div className={`w-full md:w-2/5 lg:w-1/3 px-2`}>
                {props.rightSearch}
              </div>
            </div>
          </div>
          <div className='w-full md:w-1/5 px-2 lg:px-5 '>
            <button className={`mid-button w-full md:w-auto ${isSubmitting && 'btn-disabled'} mx-auto lg:ml-0`} onClick={() => searchHandler()}>
              <FontAwesomeIcon icon={faSearch} className='text-white mr-2 md:hidden lg:inline' />
              Search
            </button>
          </div>
        </div>
        {props.searchResult}
        {includeDisclaimer &&
          <div className={`${!props.isMobile && 'w-80percent'}`}>
            <p className='text-white text-xs text-justify'>
              Disclaimer: Although the information in the "Transparency" is based on the published pricing files,
              Vitafy LLC makes no representations or warranties as to the completeness or accuracy of the information.
              It is intended for the demonstration purposes. Please use the information at your discretion.
                If you have any questions or wants to learn more, click <span className='underline hover:text-secondary cursor-pointer' onClick={requestDemoClick}>here</span> to request a demo.
            </p>
          </div>
        }
      </div>
    </div>
  );
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 768,
});

export default withSizes(mapSizesToProps)(BannerWithSearch);