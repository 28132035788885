import React, { useContext } from 'react';
import { Checkbox } from '../../../common';
import AuthorizeDotNetImg from '../../../assets/images/authorize-dot-net.png';
import FirstDataImg from '../../../assets/images/first-data.png';
import StripeImg from '../../../assets/images/Stripe.svg';
import { store } from '../../../store';
import {
  PAYMENT_GATEWAY_LINKS,
  PAYMENT_GATEWAY_TYPES,
} from '../../../constants/payment';
import commonConstants from '../../../constants/commonConstant';

const { PRIVACY_POLICY, TERMS_OF_USE } = commonConstants.VITAFY_AGREEMENTS;

const AgreeTerms = ({
  iAgreeTerms,
  handleChangeIAgreeTerms,
  removePaymentProviderLink = false,
}) => {
  // Context Store
  const state = useContext(store);
  const { globalState } = state;
  const { merchantProfile } = globalState;

  if (!merchantProfile) {
    return <>...</>;
  }

  const getLink = () => {
    if (
      merchantProfile.gatewayType === PAYMENT_GATEWAY_TYPES.AUTHORIZE_DOT_NET
    ) {
      return PAYMENT_GATEWAY_LINKS.AUTHORIZE_DOT_NET;
    }

    if (merchantProfile.gatewayType === PAYMENT_GATEWAY_TYPES.CARD_CONNECT) {
      return PAYMENT_GATEWAY_LINKS.CARD_CONNECT;
    }

    if (merchantProfile.gatewayType === PAYMENT_GATEWAY_TYPES.STRIPE) {
      return PAYMENT_GATEWAY_LINKS.STRIPE;
    }

    return '';
  };

  const getImage = () => {
    if (
      merchantProfile.gatewayType === PAYMENT_GATEWAY_TYPES.AUTHORIZE_DOT_NET
    ) {
      return AuthorizeDotNetImg;
    }

    if (merchantProfile.gatewayType === PAYMENT_GATEWAY_TYPES.CARD_CONNECT) {
      return FirstDataImg;
    }

    if (merchantProfile.gatewayType === PAYMENT_GATEWAY_TYPES.STRIPE) {
      return StripeImg;
    }

    return '';
  };

  const getClassNameForImage = () => {
    if (
      merchantProfile.gatewayType === PAYMENT_GATEWAY_TYPES.AUTHORIZE_DOT_NET
    ) {
      return 'h-6';
    }

    if (merchantProfile.gatewayType === PAYMENT_GATEWAY_TYPES.CARD_CONNECT) {
      return 'h-12';
    }

    if (merchantProfile.gatewayType === PAYMENT_GATEWAY_TYPES.STRIPE) {
      return 'h-8';
    }

    return 'h-12';
  };

  return (
    <div className="flex flex-wrap justify-between items-center mb-1">
      <Checkbox
        checked={iAgreeTerms}
        setChecked={(e) => handleChangeIAgreeTerms(e)}
        className="inline"
      >
        <p className="font-heebo text-sm xl:text-base text-titleColor mb-0 select-none inline-block">
          I agree with{' '}
          <a
            href={TERMS_OF_USE}
            target="_blank"
            rel="noopener noreferrer"
            className="no-underline font-bold text-dedicatedDpcPrimary focus:outline-none"
          >
            terms of use
          </a>{' '}
          and{' '}
          <a
            href={PRIVACY_POLICY}
            target="_blank"
            rel="noopener noreferrer"
            className="no-underline font-bold text-dedicatedDpcPrimary focus:outline-none"
          >
            privacy policy
          </a>
        </p>
      </Checkbox>
      {!removePaymentProviderLink && (
        <div className="w-full md:w-auto flex justify-end items-center mt-4 md:mt-0">
          <p className="body-text text-sm mr-1 mt-1"> Powered by </p>
          <a
            href={getLink()}
            target="_blank"
            rel="noopener noreferrer"
            className="no-underline outline-none focus:outline-none"
          >
            <img
              src={getImage()}
              alt="powered-by"
              className={getClassNameForImage()}
            />
          </a>
        </div>
      )}
    </div>
  );
};

export default AgreeTerms;
