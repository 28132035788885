import React from 'react';

const Partner = ({ partners, title = '', caption = '' }) => {
  const displayPartners =
    partners &&
    partners.map((item, index) => {
      return (
        <React.Fragment key={index}>
          {item.visibility === 'On' || item.visibility === true ? (
            <div className='w-1/2 md:w-1/4 lg:px-6 mb-8'>
              <img
                src={item.image.url}
                className='w-3/5 mx-auto'
                alt='partner-image'
              />
            </div>
          ) : null}
        </React.Fragment>
      );
    });

  return (
    <>
      {title ?
        <div className='mb-8 lg:mb-12'>
          <h3 className={`heading-center text-xl md:text-2xl xl:text-3xl md:px-10 xl:px-0 w-full text-center ${caption ? 'mb-4' : 'mb-0'}`}>
            {title}
          </h3>
          {Boolean(caption) &&
            <p className='body-text text-base font-normal text-center'>{caption}</p>
          }
        </div>
        :
        <div className='divider-line my-12 lg:my-16'></div>
      }

      <div className='w-full lg:w-4/5 mx-auto'>
        <div className='flex flex-wrap lg:-mx-6 justify-center items-center'>
          {displayPartners}
        </div>
      </div>
    </>
  );
};

export default Partner;
