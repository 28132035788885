import React, { useState, useEffect, useContext, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import withSizes from 'react-sizes';
import { useSelector, useDispatch } from 'react-redux';
import {
  faBirthdayCake,
  faEnvelope,
  faIdCard,
  faUser,
} from '@fortawesome/free-solid-svg-icons';

import {
  FormTextField,
  FormAddressInput,
  FormMaskedDateInput,
  FormMaskedLast4SSNInput,
  FormGenderInput,
  FormCountryPhoneInput,
  Checkbox,
  ButtonLoader,
  FormSelectBloodGroup,
} from '../../../../common';

import { showToast } from '../../../../service/toasterService';
import * as clientService from '../../../../service/clientService';

import {
  addDependent,
  editDependent,
  selectInformationTab,
} from '../../../../stores/membership';
import RelationshipRadioGroup from './RelationshipRadioGroup';
import { getDependentSchema } from '../../../../schemas/membership';
import {
  formatDependentPayload,
  getFullName,
} from '../../../../util/vitafyUtil';
import {
  checkEnrollmentTenantSetting,
  getMiscTenantSetting,
} from '../../../../util/tenantSettingUtil';
import { _isEqual } from '../../../../util/lodashUtil';
import { store } from '../../../../store';
import commonConstants from '../../../../constants/commonConstant';
import { getTextFromLangDict } from '../../../../util';
import { countryCode } from '../../../../constants/countryCode';

const { MEMBERSHIP_REGISTRATION_TYPES, TENANT_SETTINGS_CODE, DEPENDENT_TYPES } =
  commonConstants;

const { BLOOD_GROUP } = TENANT_SETTINGS_CODE;

const defaultValues = {
  relationship: '',
  firstName: '',
  middleName: '',
  lastName: '',
  dob: '',
  lastSSN: '',
  gender: '',
  email: '',
  phone: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zip: '',
  lat: '',
  lng: '',
  bloodGroup: '',
  countryCode: countryCode.USA,
  phoneExt: '1',
  textEnabled: false,
};

const AddDependentForm = ({ data, isMobile, closeModal }) => {
  // Redux Store
  const primaryIndividual = useSelector(selectInformationTab);

  // Context Store
  const contextStore = useContext(store);
  const { globalState } = contextStore;
  const { tenantId, groupCode, langDict, tenantCode, tenantSettings } =
    globalState;

  const showBloodGroupInput = useMemo(() => {
    return checkEnrollmentTenantSetting(BLOOD_GROUP, { tenantSettings });
  }, [tenantSettings]);

  const dependentRelationshipFromSettings = useMemo(() => {
    return getMiscTenantSetting(TENANT_SETTINGS_CODE.DEPENDENT_RELATIONSHIP, {
      tenantSettings,
    });
  }, [tenantSettings]);

  const methods = useForm({
    resolver: yupResolver(
      getDependentSchema({ isBloodGroupRequired: showBloodGroupInput })
    ),
    defaultValues,
  });
  const { watch, handleSubmit, setValue, reset } = methods;

  const dispatch = useDispatch();

  const [sameAsPrimaryContact, setSameAsPrimaryContact] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isEditMode = !!data?.clientId;

  const {
    addressLine1,
    addressLine2,
    city,
    state,
    zip,
    lat,
    lng,
    email,
    phone,
    countryCode: selectedCountryCode,
    phoneExt,
    textEnabled,
  } = watch();

  useEffect(() => {
    const dependentContact = {
      addressLine1,
      addressLine2,
      city,
      state,
      zip,
      lat,
      lng,
      email,
      phone,
      countryCode: selectedCountryCode,
      phoneExt,
      textEnabled,
    };
    const primaryContact = {
      addressLine1: primaryIndividual.addressLine1,
      addressLine2: primaryIndividual.addressLine2,
      city: primaryIndividual.city,
      state: primaryIndividual.state,
      zip: primaryIndividual.zip,
      lat: primaryIndividual.lat,
      lng: primaryIndividual.lng,
      email: primaryIndividual.email,
      phone: primaryIndividual.phone,
      countryCode: primaryIndividual?.countryCode ?? countryCode.USA,
      phoneExt: primaryIndividual?.phoneExt ?? '1',
      textEnabled: primaryIndividual?.textEnabled ?? false,
    };
    if (_isEqual(dependentContact, primaryContact)) {
      setSameAsPrimaryContact(true);
    } else {
      setSameAsPrimaryContact(false);
    }
  }, [
    addressLine1,
    addressLine2,
    city,
    state,
    zip,
    lat,
    lng,
    email,
    phone,
    primaryIndividual,
    selectedCountryCode,
    phoneExt,
    textEnabled,
  ]);

  /**
   * Prepopulate the form on edit mode
   *
   */
  useEffect(() => {
    if (data?.clientId) {
      reset({
        relationship: data.relationship,
        firstName: data.firstName,
        middleName: data.middleName,
        lastName: data.lastName,
        dob: data.dob,
        lastSSN: data.lastSSN,
        gender: data.gender,
        email: data.email,
        phone: data.phone,
        bloodGroup: data.bloodGroup,
        addressLine1: data.address.addressLine1,
        addressLine2: data.address.addressLine2,
        city: data.address.city,
        state: data.address.state,
        zip: data.address.zip,
        lat: data.address.lat,
        lng: data.address.lng,
        countryCode: data?.countryCode ?? countryCode.USA,
        phoneExt: data?.phoneExt ?? '1',
        textEnabled: data?.textEnabled ?? false,
      });
    }
  }, [data, reset]);

  const prepopulateContactWithPrimary = (value) => {
    setSameAsPrimaryContact(value);
    if (!value) return;
    setValue('email', primaryIndividual.email);
    setValue('phone', primaryIndividual.phone);
    setValue('addressLine1', primaryIndividual.addressLine1);
    setValue('addressLine2', primaryIndividual.addressLine2);
    setValue('city', primaryIndividual.city);
    setValue('state', primaryIndividual.state);
    setValue('zip', primaryIndividual.zip);
    setValue('lat', primaryIndividual.lat);
    setValue('lng', primaryIndividual.lng);
    setValue('phoneExt', primaryIndividual?.phoneExt ?? '1');
    setValue('countryCode', primaryIndividual?.countryCode ?? countryCode.USA);
    setValue('textEnabled', primaryIndividual?.textEnabled ?? false);
  };

  const handleAddDependent = async (payload) => {
    try {
      setIsSubmitting(true);
      const response = await clientService.addClient(payload);
      dispatch(
        addDependent({
          ...payload,
          ...payload?.relationship,
          relationship: payload?.relationship?.type ?? '',
          clientId: response.data.clientId,
          clientEnrollmentId: response.data.clientEnrollmentId,
          type: payload.type,
          parentId: data?.primaryId ?? '',
          parentEnrollmentId: data?.primaryEnrollmentId ?? '',
          parentName: data?.primaryName ?? '',
        })
      );
      closeModal();
    } catch (err) {
      showToast('error', err.response.data.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEditDependent = async (payload) => {
    try {
      setIsSubmitting(true);
      const response = await clientService.editClient(data.clientId, payload);
      dispatch(
        editDependent({
          ...payload,
          ...payload?.relationship,
          relationship: payload?.relationship?.type ?? '',
          clientId: response.data.clientId,
          clientEnrollmentId: response.data.clientEnrollmentId,
          type: payload.type,
          parentId: data?.primaryId ?? '',
          parentEnrollmentId: data?.primaryEnrollmentId ?? '',
          parentName: data?.primaryName ?? '',
        })
      );
      closeModal();
    } catch (err) {
      showToast('error', err.response.data.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onSubmit = (formValues) => {
    const payload = formatDependentPayload({
      ...formValues,
      tenantId,
      type: MEMBERSHIP_REGISTRATION_TYPES.individual.value,
      relationship: formValues.relationship,
      clientId: data?.clientId ?? undefined,
      clientEnrollmentId: data?.clientEnrollmentId ?? undefined,
      parentId: primaryIndividual.clientId,
      parentEnrollmentId: primaryIndividual.clientEnrollmentId,
      parentName: getFullName({
        firstName: primaryIndividual.firstName,
        middleName: primaryIndividual.middleName,
        lastName: primaryIndividual.lastName,
      }),
      groupCode,
    });

    if (isEditMode) {
      handleEditDependent(payload);
    } else {
      handleAddDependent(payload);
    }
  };

  // Update the form with the relationship value without user action if there is single option to choose.
  useEffect(() => {
    const availableDependentTypes = DEPENDENT_TYPES.filter((item) =>
      dependentRelationshipFromSettings?.some(
        (r) => r.toLowerCase() === item.value.toLowerCase()
      )
    );

    if (availableDependentTypes?.length === 1) {
      setValue('relationship', 'Child');
    }
  }, [dependentRelationshipFromSettings, setValue]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Modal Body */}
        <div className="modal-body">
          {/* Relationship with Dependent */}
          <h5 className="dedicated-form-title mb-4">
            {getTextFromLangDict(langDict, {
              key: '_SELECT_THE_RELATIONSHIP_WITH_THE_PRIMARY',
              groupCode,
              tenantCode,
            })}
          </h5>
          <RelationshipRadioGroup />

          <div className="form-section-divider-md"></div>

          {/* Personal Information */}
          <h5 className="dedicated-form-title mb-3">
            {getTextFromLangDict(langDict, {
              key: '_ENTER_DEPENDENT_PERSONAL_INFORMATION',
              groupCode,
              tenantCode,
            })}
          </h5>
          <div className="flex flex-wrap -mx-4">
            <div className="w-full md:w-1/3 px-4">
              <FormTextField
                name="firstName"
                label="First Name *"
                icon={isMobile ? '' : faUser}
              />
            </div>
            <div className="w-full md:w-1/3 px-4">
              <FormTextField name="middleName" label="Middle Name" />
            </div>
            <div className="w-full md:w-1/3 px-4">
              <FormTextField name="lastName" label="Last Name *" />
            </div>
          </div>
          <div className="flex flex-wrap -mx-4">
            <div className="w-full md:w-1/3 px-4">
              <FormMaskedDateInput
                name="dob"
                label="Date of Birth *"
                icon={isMobile ? '' : faBirthdayCake}
              />
            </div>
            <div className="w-full md:w-1/3 px-4">
              <FormMaskedLast4SSNInput
                name="lastSSN"
                label="Last Four SSN"
                icon={isMobile ? '' : faIdCard}
              />
            </div>
            <FormGenderInput name="gender" whiteBg />
          </div>
          {showBloodGroupInput && (
            <div className="flex flex-wrap -mx-4">
              <div className="w-full md:w-1/3 px-4">
                <FormSelectBloodGroup />
              </div>
            </div>
          )}

          <div className="form-section-divider-md"></div>

          {/* Contact Information */}
          <div className="flex flex-wrap justify-between items-center mb-5">
            <h5 className="dedicated-form-title mb-4 md:mb-0">
              {getTextFromLangDict(langDict, {
                key: '_ENTER_DEPENDENT_CONTACT_INFORMATION',
                groupCode,
                tenantCode,
              })}
            </h5>
            <Checkbox
              checked={sameAsPrimaryContact}
              setChecked={(e) => prepopulateContactWithPrimary(e)}
              className="inline"
            >
              <p className="font-heebo text-sm  text-titleColor mb-0 select-none inline-block">
                {getTextFromLangDict(langDict, {
                  key: '_SAME_AS_PRIMARY_CONTACT',
                  groupCode,
                  tenantCode,
                })}
              </p>
            </Checkbox>
          </div>
          <div className="flex flex-wrap -mx-4 mt-2">
            <div className="w-full md:w-1/2 px-4">
              <FormTextField
                name="email"
                label="Email"
                icon={isMobile ? '' : faEnvelope}
              />
            </div>
            <div className="flex w-full md:w-1/2 px-4">
              <FormCountryPhoneInput label="Phone *" />
            </div>
          </div>
          <FormAddressInput isRequired />
        </div>

        {/* Modal Footer */}
        <div className="modal-footer">
          <button
            className={`mid-button px-4 py-3 bg-transparent text-dedicatedDpcSecondary hover:bg-gray-300 ${
              isSubmitting && 'btn-disabled'
            }`}
            onClick={closeModal}
            type="button"
          >
            {getTextFromLangDict(langDict, {
              key: '_CANCEL',
              groupCode,
              tenantCode,
            })}
          </button>
          <button
            className={`mid-button px-10 py-3 bg-dedicatedDpcSecondary hover:bg-dedicatedDpcHovered flex justify-center items-center ${
              isSubmitting && 'btn-disabled'
            }`}
            type="submit"
          >
            {isSubmitting ? (
              <div className="inline mr-2">
                <ButtonLoader />
              </div>
            ) : null}
            <span>
              {isEditMode
                ? getTextFromLangDict(langDict, {
                    key: '_UPDATE',
                    groupCode,
                    tenantCode,
                  })
                : getTextFromLangDict(langDict, {
                    key: '_SAVE',
                    groupCode,
                    tenantCode,
                  })}
            </span>
          </button>
        </div>
      </form>
    </FormProvider>
  );
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 768,
});

export default withSizes(mapSizesToProps)(AddDependentForm);
