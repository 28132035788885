import * as httpService from './httpService';
import APICONSTANTS from '../constants';

export function searchProcedures(params) {
  return httpService
    .get(`${APICONSTANTS.TRANSPARENCY}/procedures`, params)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function searchTransparency(data) {
  return httpService
    .post(`${APICONSTANTS.TRANSPARENCY}`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}