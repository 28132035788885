import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Checkbox, ButtonLoader, FormErrorMessage } from '../../common';

class BankPayment extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      requiredInput: false,
      invalidInput: false,
      isFormSubmitted: false,
      token: ''
    }
  }

  componentDidMount() {
    window.addEventListener("message", this.listen);
    // this.props.clearErrorMessage();
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.listen);
  }

  listen = (event) => {
    // console.log('Received Message: ', event.data);
    if (typeof (event.data) == 'string') {
      // 1004 -> Input Required
      // 1001 -> Invalid Input
      try {
        const response = JSON.parse(event.data);
        response.errorCode &&
          this.setState({
            requiredInput: response.errorCode === '1004' ? true : false,
            invalidInput: response.errorCode === '1001' ? true : false,
            token: '',
            isFormSubmitted: false
          }, () => {
            if (!this.state.requiredInput && !this.state.invalidInput && response && response.errorCode === '0') {
              this.setState({ token: response.token });
              this.props.handleChangeTmpPaymentData('bank', { token: response.token });
              this.props.handleRemoveErrorMessage();
            }
          })
      } catch (e) {
        console.log('Error while trying to parse JSON', e)
      }
    }
  };

  getFormattedAccountNumber = (token) => {
    return '************' + token.slice(-4);
  }

  handleAddNewBankInfo = () => {
    this.props.handleChangeTmpPaymentData('bank', { token: '' })
    this.setState({
      token: ''
    })
    this.props.handleRemoveErrorMessage();
  }

  render() {
    const parameters = "invalidinputevent=true&enhancedresponse=true&placeholder=Routing Number     Account Number";
    const encodedStyles = "body %7B font-family: 'Heebo', sans-serif;margin-left: 0px;%0A%7D%0Ainput %7B border: 1.5px solid %23d4d4d4; height: 40px; background-color: %23fafafa; font-size: 16px; text-indent: 16px; margin-top: 10px; color: %23333;%0A%7D input:focus %7B outline: none !important; border: 1.5px solid %2300a1be; box-shadow: 0 0 3px %23b0b0b0; background-color: %23ffffff;%0A%7D select %7B border: 1.5px solid %23d4d4d4; height: 44.5px; background-color: %23fafafa; font-size: 16px; margin-top: 10px; color: %23333; text-indent: 8px;%0A%7D select:focus %7B outline: none !important; border: 1.5px solid %2300a1be; box-shadow: 0 0 3px %23b0b0b0; background-color: %23ffffff;%0A%7D select:not(:-internal-list-box) %7B overflow: visible !important;%0A%7D %23cccardlabel,%0A%23ccexpirylabel,%0A%23cccvvlabel %7B display: none;%0A%7D %23ccnumfield %7B width: 100%25; display: block; margin-bottom: 0.4rem;%0A%7D %23ccexpirymonth %7B width: 4rem; margin-right: 1rem;%0A%7D %23ccexpiryyear %7B width: 5rem; margin-right: 1rem;%0A%7D";

    const {
      iAgreeTerms,
      handleChangeIAgreeTerms,
      errorMessage
    } = this.props;

    return (
      <>
        <h6 className='body-text text-titleColor font-bold mb-0'>Card Information</h6>

        <p className='mt-2 body-text text-base'>Enter your bank information in this format: <br /><strong>routing number/account number</strong>. <br />
              For example: 123456789/1234123412341234
            </p>
        {this.props.tmpPaymentData.token &&
          <div className='mt-4 mb-10'>
            <div className='flex justify-between items-start'>
              <p className='body-text mb-3 font-semibold'>Use recently entered Bank Information</p>
              <button
                className={`w-32 px-4 py-2 rounded-md hover:bg-orange-200 cursor-pointer transition-all duration-150 ${this.props.isSubmitting && 'btn-disabled'}`}
                onClick={this.handleAddNewBankInfo}
                type='button'
              >
                <p className='text-secondary font-medium'>
                  Add New
                </p>
              </button>
            </div>
            <p className='body-text mb-1'>Account Number&nbsp; : <span className='text-titleColor'>
              {this.getFormattedAccountNumber(this.props.tmpPaymentData.token)}
            </span>
            </p>
          </div>
        }
        <div>
          <iframe
            style={{ display: this.props.tmpPaymentData.token ? 'none' : 'block' }}
            title="iframe"
            id="tokenframe"
            name="tokenframe"
            frameBorder="0"
            scrolling="no"
            width='100%'
            height={this.props.isMobile ? "110" : "80"}
            src={`${this.props.paymentBaseUrl}/itoke/ajax-tokenizer.html?&${parameters}&css=${encodedStyles}`}
          />

          <div className='mt-5 mb-5'>
            <Checkbox
              checked={iAgreeTerms}
              setChecked={(e) => handleChangeIAgreeTerms(e)}
            >
              <p className='font-heebo text-base text-titleColor'>
                I agree with{' '}
                <a
                  href='https://vitafymed.com/terms-of-use'
                  target='_blank'
                  className='no-underline font-bold text-secondary focus:outline-none cursor-pointer'
                >
                  terms & policy
              </a>
              </p>
            </Checkbox>
          </div>

          {(errorMessage) &&
            <div className='mb-5'>
              <FormErrorMessage
                type='error'
                message={`Error: ${errorMessage}`}
              />
            </div>
          }

          <button
            className={`mid-button w-full flex justify-center rounded-md ${(!iAgreeTerms || this.props.isSubmitting || !this.props.tmpPaymentData.token) && 'btn-disabled'}`}
            onClick={this.props.handleSubmitCheckout}
          >
            <div className='flex items-center'>
              {this.props.isSubmitting ? (
                <div className='inline mr-2'>
                  <ButtonLoader />
                </div>
              ) : null}
            Checkout
                <FontAwesomeIcon
                icon={faArrowRight}
                className='text-white ml-3'
              />
            </div>
          </button>

        </div>
      </>
    );
  }

}

export default BankPayment;