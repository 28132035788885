import React from 'react';
import { Switch, BrowserRouter, Route } from 'react-router-dom';
import AppRoute from './appRoute';
import MainLayout from '../layouts/MainLayout';
import DedicatedDpcLayout from '../layouts/DedicatedDpcLayout';
import {
  DedicatedDpcLanding,
  DedicatedDpcHelpCenter,
  DedicatedDpcAgreement,
  GroupMembers,
  PageNotFound,
} from '../components';
import { Redirect } from '../common';

const DedicatedDpcRouterRoute = ({ tenantCode }) => {
  const baseUrl = process.env.PUBLIC_URL;

  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path={baseUrl + '/'}
          render={() => <Redirect redirectPath={`/${tenantCode}`} />}
        />

        <AppRoute
          path={baseUrl + '/:tenantCode/service/membership/group-members'}
          layout={DedicatedDpcLayout}
          component={GroupMembers}
        />

        <AppRoute
          exact
          path={baseUrl + '/:tenantCode'}
          layout={DedicatedDpcLayout}
          component={DedicatedDpcLanding}
        />

        {/* <AppRoute exact path={baseUrl + "/code/:code"} layout={DedicatedDpcLayout} component={DedicatedDpcLanding} /> */}
        <AppRoute
          path={baseUrl + '/:tenantCode/service/:service'}
          layout={DedicatedDpcLayout}
          component={DedicatedDpcHelpCenter}
        />

        <AppRoute
          exact
          path={baseUrl + '/:tenantCode/agreement'}
          layout={DedicatedDpcLayout}
          component={DedicatedDpcAgreement}
        />
        <AppRoute layout={MainLayout} component={PageNotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default DedicatedDpcRouterRoute;
