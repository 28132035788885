import axios from 'axios';
import config from '../config';

const API_BASE_URL =
  config.apiBaseUrl[process.env.REACT_APP_ENV || 'DEVELOPMENT'];
const API_BASE_URL_PROD = 'https://api-v3.vitafyhealth.com/api';

// const clientCode = 'jMt2gkPR#HMd/j';

const setHeader = (config) => {
  // if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1")
  //   return axios(Object.assign(config, { headers: { 'requestsource': 'localhost', 'clientcode': clientCode } }));
  // return axios(Object.assign(config, { headers: { 'clientcode': clientCode } }));
  return axios(
    Object.assign(config, {
      headers: { requestapp: 'vitafyhealth', requestsource: 'localhost' },
    })
  );
};

export function get(resourceName, params = {}) {
  let config = {
    method: 'get',
    url: `${API_BASE_URL}/${resourceName}`,
    params: params,
  };
  return setHeader(config);
}

export function post(resourceName, data) {
  let config = {
    method: 'post',
    url: `${API_BASE_URL}/${resourceName}`,
    data: data,
  };
  return setHeader(config);
}

export function put(resourceName, data) {
  let config = {
    method: 'put',
    url: `${API_BASE_URL}/${resourceName}`,
    data: data,
  };
  return setHeader(config);
}

export function patch(resourceName, data) {
  let config = {
    method: 'patch',
    url: `${API_BASE_URL}/${resourceName}`,
    data: data,
  };
  return setHeader(config);
}

export function destroy(resourceName, id) {
  let config = {
    method: 'delete',
    url: `${API_BASE_URL}/${resourceName}/${id}`,
  };
  return setHeader(config);
}

export function getCosmicContent(resourceName, params = {}) {
  let config = {
    method: 'get',
    url: `${API_BASE_URL}/${resourceName}`,
    params: params,
  };
  return setHeader(config);
}

// Points to Prod API
export function getProd(resourceName, params = {}) {
  let config = {
    method: 'get',
    url: `${API_BASE_URL_PROD}/${resourceName}`,
    params: params,
  };
  return setHeader(config);
}
