import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserFriends, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import {
  resetAll,
  selectBenefitStartDate,
  selectInformationTab,
  selectSelectedOfferings,
} from '../../../../stores/membership';
import { useEffect } from 'react';
import { clearSession, setSession } from '../../../../util/storageUtil';
import { store } from '../../../../store';
import { formatMembershipOfferingForSave } from '../../../../util/vitafyUtil';
import { getTextFromLangDict } from '../../../../util';

const GetStarted = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const primaryClient = useSelector(selectInformationTab);
  const selectedOfferings = useSelector(selectSelectedOfferings);
  const benefitStartDate = useSelector(selectBenefitStartDate);

  // Context Store
  const contextStore = useContext(store);
  const { globalState } = contextStore;
  const { tenantId, tenantCode, groupCode, primaryLocation, langDict } =
    globalState;

  useEffect(() => {
    if (
      primaryClient &&
      tenantId &&
      selectedOfferings?.length &&
      benefitStartDate
    ) {
      const offeringSelectedByPrimary = formatMembershipOfferingForSave({
        ...selectedOfferings[0],
        benefitStartDate,
      });

      setSession('primaryClient', {
        ...primaryClient,
        tenantId,
        offeringSelectedByPrimary,
      });
    }
  }, [primaryClient, tenantId, selectedOfferings, benefitStartDate]);

  const onAddMembersClick = () => {
    const code = primaryLocation?.code?.toLowerCase();
    history.push(`/${code}/service/membership/group-members`);
  };

  const navigateToHome = () => {
    const homeLink = tenantCode ? `/${tenantCode.toLowerCase()}` : '/';
    history.push(homeLink);
    dispatch(resetAll());
    clearSession();
    window.location.reload();
  };

  return (
    <>
      <hr className="w-full h-1px bg-bgColor my-5" />

      <FontAwesomeIcon
        icon={faUserFriends}
        className="inline-block w-12 h-12 text-dedicatedDpcPrimary"
        size="2x"
      />

      <h6 className="text-base md:text-xl">
        {getTextFromLangDict(langDict, {
          key: '_ADD_GROUP_MEMBERS_TITLE',
          groupCode,
          tenantCode,
        })}
      </h6>
      <p className="body-text text-sm mt-3 mb-6 text-center">
        {getTextFromLangDict(langDict, {
          key: '_ADD_GROUP_MEMBERS_DESCRIPTION',
          groupCode,
          tenantCode,
        })}
      </p>
      <button
        data-cy="getstarted"
        className="mid-button bg-dedicatedDpcSecondary hover:bg-dedicatedDpcHovered"
        onClick={onAddMembersClick}
      >
        {getTextFromLangDict(langDict, {
          key: '_ADD_GROUP_MEMBERS_GET_STARTED_BUTTON_TEXT',
          groupCode,
          tenantCode,
        })}
      </button>
      <button
        data-cy="addlater"
        className="mid-button px-4 py-3 bg-transparent text-dedicatedDpcSecondary hover:bg-gray-300 mt-4"
        onClick={navigateToHome}
        type="button"
      >
        {getTextFromLangDict(langDict, {
          key: '_ADD_GROUP_MEMBERS_LATER_GET_STARTED_BUTTON_TEXT',
          groupCode,
          tenantCode,
        })}
        <FontAwesomeIcon
          icon={faArrowRight}
          className="text-dedicatedDpcSecondary ml-3"
        />
      </button>
    </>
  );
};

export default GetStarted;
