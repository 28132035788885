import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { Confirmation } from '../common';
import Information from './information';
import MembershipPlan from './membership-plan';
import Checkout from './checkout';
import { selectInformationTab } from '../../../stores/membership';
import { store } from '../../../store';
import { getTextFromLangDict } from '../../../util';
import commonConstants from '../../../constants/commonConstant';
import GetStarted from './group-members/GetStarted';
import Questionaire from '../../questionaire';
import { checkEnrollmentTenantSetting } from '../../../util/tenantSettingUtil';

const {
  MEMBERSHIP_REGISTRATION_TYPES,
  MEMBERSHIP_ENROLLMENT_STEPS,
  TENANT_SETTINGS_CODE,
} = commonConstants;
const { INFORMATION, QUESTIONAIRE, MEMBERSHIP, CHECKOUT, CONFIRMATION } =
  MEMBERSHIP_ENROLLMENT_STEPS;

const StepSelection = ({
  step,
  refs,
  misc,
  onSaveDemographicInformation,
  onBackClick,
  handleQuestionnaireSubmitSuccess,
}) => {
  const { individualInfoFormRef, companyInfoFormRef } = refs;
  const { whiteBg } = misc;

  const client = useSelector(selectInformationTab);
  const isClientCompany =
    client.type === MEMBERSHIP_REGISTRATION_TYPES.company.value;

  // Context Store
  const state = useContext(store);
  const { globalState } = state;
  const { primaryLocation, langDict, groupCode, tenantCode, tenantSettings } =
    globalState;

  const isAddGroupMemberVisible = checkEnrollmentTenantSetting(
    TENANT_SETTINGS_CODE.GROUP_MEMBER_MGMT,
    { tenantSettings }
  );

  return (
    <>
      {step === INFORMATION && (
        <Information
          refs={{ individualInfoFormRef, companyInfoFormRef }}
          misc={{
            whiteBg,
          }}
          onSubmit={onSaveDemographicInformation}
        />
      )}
      {step === QUESTIONAIRE && (
        <Questionaire
          onPrevClick={onBackClick}
          handleQuestionnaireSubmitSuccess={handleQuestionnaireSubmitSuccess}
        />
      )}
      {step === MEMBERSHIP && (
        <MembershipPlan
          misc={{
            whiteBg,
          }}
        />
      )}
      {step === CHECKOUT && (
        <Checkout
          misc={{
            whiteBg,
          }}
        />
      )}
      {step === CONFIRMATION && (
        <Confirmation
          email={client.email}
          title={getTextFromLangDict(langDict, {
            key: '_MEMBERSHIP_ENROLLMENT_CONFIRMATION_TITLE',
            groupCode,
            tenantCode,
          })}
          type="membership"
          displayName={
            primaryLocation.displayName
              ? primaryLocation.displayName
              : primaryLocation.businessName
          }
          removeHomeLink={isClientCompany && isAddGroupMemberVisible}
          // formTopRef={formTopRef}
          backToHomeForWindowReload={false}
        >
          {isClientCompany && isAddGroupMemberVisible && <GetStarted />}
        </Confirmation>
      )}
    </>
  );
};

export default StepSelection;
