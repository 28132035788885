import React from 'react';
import ReactLoading from 'react-loading';

const ButtonLoader = ({ type = 'bubbles', color = '#fff', height = '30px', width = '30px' }) => {
  return (
    <>
      <ReactLoading
        type={type}
        color={color}
        height={height}
        width={width}
      />
    </>
  );
};

export default ButtonLoader;
