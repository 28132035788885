import React, { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import NumberFormat from "react-number-format";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faUser,
  faArrowRight,
  faCommentAlt,
  faChevronDown,
  faUserTie,
  faUserPlus,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { Modal } from "../../common";

import * as service from "../../service";
import { showToast } from "../../service/toasterService";
import ctaModalTitle from "../../constants/ctaModalTitle";

const RequestDemo = (props) => {
  const { register, handleSubmit, errors, setValue, clearError, getValues } =
    useForm();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    profession: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    formBuilder();
  }, []);

  const formBuilder = () => {
    register({ name: "name" }, { required: true });
    register(
      { name: "email" },
      {
        pattern:
          /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/i,
        required: true,
      }
    );
    register(
      { name: "phone" },
      {
        validate: (value) => {
          if (!value) return true;
          return !value.includes("_");
        },
        required: false,
      }
    );
    register({ name: "profession" });
    register({ name: "message" }, { required: true });
    setValue([
      { name: formData.name },
      { email: formData.email },
      { phone: formData.phone },
      { profession: formData.profession },
      { message: formData.message },
    ]);
  };

  const handleInputChange = (e, field) => {
    let value = e.target ? e.target.value : e;
    if ((e.target ? e.target.validity.valid : e) || value === "") {
      setValue(field, value);
      setFormData({
        ...formData,
        [field]: value,
      });
      clearError(field);
    }
  };

  const formSubmit = (formValues) => {
    formValues.profession = formData.profession;
    setIsSubmitting(true);
    service
      .submitContactForm(formValues)
      .then((result) => {
        if (result.data === "success") {
          showToast("success", "Thank you for reaching out!");
          //reset the form
          setFormData({
            name: "",
            email: "",
            phone: "",
            profession: "",
            message: "",
          });
          setIsSubmitting(false);
          props.formModal.current.toggle();
        } else {
          showToast("error", "Oops. Something went wrong.");
        }
      })
      .catch((error) => {
        console.log("Error in post request demo", error);
        setIsSubmitting(false);
      });
  };

  const defaultOptions = [
    { value: "Agent & Advisor" },
    { value: "Direct Primary Care" },
    { value: "Direct Specialty Care" },
    { value: "Care Advocate" },
    { value: "Employer & Group" },
    { value: "Provider" },
    { value: "Consumer" },
    { value: "Other" },
  ];

  const modalTitle = (function () {
    if (props.requestDemoModal?.modalTitle)
      return props.requestDemoModal?.modalTitle;
    if (props.modalTitle) return props.modalTitle;
    return ctaModalTitle.REQUEST_PRICING;
  })();

  return (
    <Modal modalTitle={modalTitle} ref={props.formModal}>
      <form onSubmit={handleSubmit(formSubmit)}>
        {/* Full Name */}
        <div className="w-full mb-6">
          <label
            className="block font-heebo text-md text-bodyColor mb-2"
            htmlFor="full-name"
          >
            Full Name
          </label>
          <div className="relative">
            <FontAwesomeIcon icon={faUser} className="contact-form-icon" />
            <input
              id="full-name"
              type="text"
              placeholder=""
              className="text-input"
              name="name"
              value={formData.name}
              onChange={(e) => handleInputChange(e, "name")}
              required
            />
          </div>
          {/* <p className='text-red-500 text-xs italic'>
          Please fill out this field.
        </p> */}
        </div>

        <div className="flex flex-wrap items-start md:mb-6 -mx-3">
          {/* Email */}
          <div className="w-full md:w-1/2 mb-6 md:mb-0 px-3">
            <label
              className="block font-heebo text-md text-bodyColor mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <div className="relative">
              <FontAwesomeIcon
                icon={faEnvelope}
                className="contact-form-icon"
              />
              <input
                id="email"
                placeholder=""
                className="text-input"
                name="email"
                value={formData.email}
                onChange={(e) => handleInputChange(e, "email")}
                required
              />
            </div>
            <p
              className={`text-red-500 mt-1 text-xs italic ${
                errors.email ? "d-block" : "hidden"
              }`}
            >
              Please enter proper email address.
            </p>
          </div>
          {/* Phone */}
          <div className="w-full md:w-1/2 mb-6 md:mb-0 px-3">
            <label
              className="block font-heebo text-md text-bodyColor mb-2"
              htmlFor="phone"
            >
              Phone
            </label>
            <div className="relative">
              <FontAwesomeIcon icon={faPhone} className="contact-form-icon" />
              <NumberFormat
                id="phone"
                format="(###) ###-####"
                mask="_"
                name="phone"
                value={formData.phone}
                placeholder="(222) 334-2424"
                style={{ textIndent: "2.5rem" }}
                className="text-input"
                onValueChange={(value) =>
                  handleInputChange(value.formattedValue, "phone")
                }
              />
            </div>
            <p
              className={`text-red-500 mt-1 text-xs italic ${
                errors.phone ? "d-block" : "hidden"
              }`}
            >
              Please enter phone number correctly.
            </p>
          </div>
        </div>

        {/* User Type */}
        <div className="w-full mb-6">
          <label
            className="block font-heebo text-md text-bodyColor mb-2"
            htmlFor="user-type"
          >
            Type
          </label>
          <div className="relative">
            <FontAwesomeIcon icon={faUserTie} className="contact-form-icon" />
            <FontAwesomeIcon
              icon={faChevronDown}
              className="contact-form-icon-select"
              style={{ right: "20px" }}
            />
            <select
              name="type"
              className="text-input"
              name="profession"
              value={formData.profession}
              onChange={(e) => handleInputChange(e, "profession")}
            >
              <option aria-label="None" value="" />
              {defaultOptions &&
                defaultOptions.map((item, index) => (
                  <>
                    <option
                      value={item.value}
                      key={index}
                      // selected={item.value.toLowerCase() === selectedOption.toLowerCase()}
                    >
                      {item.value}
                    </option>
                  </>
                ))}
            </select>
          </div>
          {/* <p className='text-red-500 text-xs italic'>
          Please fill out this field.
        </p> */}
        </div>
        {/* Message */}
        <div className="w-full mb-6">
          <label
            className="block font-heebo text-md text-bodyColor mb-2"
            htmlFor="message"
          >
            Message
          </label>
          <div className="relative">
            <FontAwesomeIcon
              icon={faCommentAlt}
              className="contact-form-icon"
            />
            <textarea
              id="message"
              rows="5"
              placeholder=""
              className="text-input"
              name="message"
              value={formData.message}
              onChange={(e) => handleInputChange(e, "message")}
              required
            />
          </div>
          {/* <p className='text-red-500 text-xs italic'>
          Please fill out this field.
        </p> */}
        </div>
        <div className="flex items-center justify-end px-2 pt-3 border-t border-solid border-gray-300 rounded-b">
          <button
            className="text-bodyColor bg-transparent hover:bg-bgColor font-bold  px-6 py-3 text-base outline-none rounded-full focus:outline-none mr-1 mb-1 transition duration-500 ease-in-ou"
            type="button"
            onClick={() => props.formModal.current.toggle()}
          >
            Close
          </button>
          <button
            className="bg-secondary hover:bg-hovered ease-out block text-white font-heebo text-lg px-6 py-3 rounded-full outine-none w-full md:w-auto focus:outline-none"
            type="submit"
          >
            Submit
            {isSubmitting ? (
              <ReactLoading
                className="inline-block ml-3 pt-1"
                type="spin"
                color="white"
                height={"20px"}
                width={"20px"}
              />
            ) : (
              <FontAwesomeIcon
                icon={faArrowRight}
                className="text-white text-md ml-3"
              />
            )}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default RequestDemo;
