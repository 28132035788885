import * as Yup from 'yup';
import commonConstants from '../constants/commonConstant';

import regex from '../constants/regex';
import validationMessage from '../constants/validationMessage';

import { validateDob, validateMaskedInput } from '../util/momentUtil';

const { MEMBER_TYPE } = commonConstants;
const { INVALID, BLOOD_GROUP_REQUIRED } = validationMessage;

export const getIndividualSchema = ({ isBloodGroupRequired = false }) => {
  return Yup.object().shape({
    firstName: Yup.string().nullable().trim().required(),
    middleName: Yup.string().nullable(),
    lastName: Yup.string().nullable().trim().required(),
    dob: Yup.string()
      .nullable()
      .required()
      .test('checkIfDobIsValid', INVALID, (value) => {
        if (!value) return true;
        return validateDob(value);
      }),
    lastSSN: Yup.string()
      .nullable()
      .test('checkIfSSNValid', INVALID, (value) => validateMaskedInput(value)),
    gender: Yup.string(),
    email: Yup.string().nullable().trim().email().required(),
    phone: Yup.string()
      .nullable()
      .required()
      .test('checkIfPhoneValid', INVALID, (value) =>
        validateMaskedInput(value)
      ),
    bloodGroup: isBloodGroupRequired
      ? Yup.string().required(BLOOD_GROUP_REQUIRED)
      : Yup.string().nullable(),
    addressLine1: Yup.string().trim().required(),
    addressLine2: Yup.string().nullable(),
    city: Yup.string().trim().required(),
    state: Yup.string().trim().required(),
    zip: Yup.string().required().matches(regex.ZIP, {
      excludeEmptyString: false,
      message: INVALID,
    }),
    lat: Yup.string().nullable(),
    lng: Yup.string().nullable(),
  });
};

export const CompanySchema = Yup.object().shape({
  businessName: Yup.string().nullable().trim().required(),
  firstName: Yup.string().nullable().trim().required(),
  middleName: Yup.string().nullable(),
  lastName: Yup.string().nullable().trim().required(),
  email: Yup.string().nullable().trim().email().required(),
  phone: Yup.string()
    .nullable()
    .required()
    .test('checkIfPhoneValid', INVALID, (value) => validateMaskedInput(value)),
  addressLine1: Yup.string().trim().required(),
  addressLine2: Yup.string().nullable(),
  city: Yup.string().trim().required(),
  state: Yup.string().trim().required(),
  zip: Yup.string().required().matches(regex.ZIP, {
    excludeEmptyString: false,
    message: INVALID,
  }),
  lat: Yup.string().nullable(),
  lng: Yup.string().nullable(),
});

export const getDependentSchema = ({ isBloodGroupRequired = false }) => {
  return Yup.object().shape({
    relationship: Yup.string().nullable().trim().required(),
    firstName: Yup.string().nullable().trim().required(),
    middleName: Yup.string().nullable(),
    lastName: Yup.string().nullable().trim().required(),
    dob: Yup.string()
      .nullable()
      .required()
      .test('checkIfDobIsValid', INVALID, (value) => {
        if (!value) return true;
        return validateDob(value);
      }),
    lastSSN: Yup.string()
      .nullable()
      .test('checkIfSSNValid', INVALID, (value) => validateMaskedInput(value)),
    gender: Yup.string(),
    email: Yup.string().nullable().trim().email(),
    phone: Yup.string()
      .nullable()
      .required()
      .test('checkIfPhoneValid', INVALID, (value) =>
        validateMaskedInput(value)
      ),
    bloodGroup: isBloodGroupRequired
      ? Yup.string().required(BLOOD_GROUP_REQUIRED)
      : Yup.string().nullable(),
    addressLine1: Yup.string().trim().required(),
    addressLine2: Yup.string().nullable(),
    city: Yup.string().trim().required(),
    state: Yup.string().trim().required(),
    zip: Yup.string().required().matches(regex.ZIP, {
      excludeEmptyString: false,
      message: INVALID,
    }),
    lat: Yup.string().nullable(),
    lng: Yup.string().nullable(),
  });
};

export const getGroupMemberOrDependentSchema = ({
  isBloodGroupRequired = false,
}) => {
  return Yup.object().shape({
    memberType: Yup.string().nullable().trim().required(),
    // primaryMember is required when memberType is dependent
    primaryMember: Yup.object()
      .nullable()
      .when('memberType', {
        is: (memberType) => memberType === MEMBER_TYPE.DEPENDENT,
        then: Yup.object().shape({
          clientId: Yup.string().required(),
          fullName: Yup.string().required(),
        }),
      }),
    // relationship is required when memberType is dependent
    relationship: Yup.string()
      .nullable()
      .when('memberType', {
        is: (memberType) => memberType === MEMBER_TYPE.DEPENDENT,
        then: Yup.string().trim().required(),
      }),
    firstName: Yup.string().nullable().trim().required(),
    middleName: Yup.string().nullable(),
    lastName: Yup.string().nullable().trim().required(),
    dob: Yup.string()
      .nullable()
      .test('checkIfDobIsValid', INVALID, (value) => {
        if (!value) return true;
        return validateDob(value);
      }),
    lastSSN: Yup.string()
      .nullable()
      .test('checkIfSSNValid', INVALID, (value) => validateMaskedInput(value)),
    gender: Yup.string(),
    email: Yup.string().trim().required().email(),
    phone: Yup.string()
      .nullable()
      .required()
      .test('checkIfPhoneValid', INVALID, (value) =>
        validateMaskedInput(value)
      ),
    // bloodGroup: isBloodGroupRequired
    //   ? Yup.string().required(BLOOD_GROUP_REQUIRED)
    //   : Yup.string().nullable(),
    addressLine1: Yup.string().nullable(),
    addressLine2: Yup.string().nullable(),
    city: Yup.string().nullable(),
    state: Yup.string().nullable(),
    zip: Yup.string().nullable().matches(regex.ZIP, {
      excludeEmptyString: true,
      message: INVALID,
    }),
    lat: Yup.string().nullable(),
    lng: Yup.string().nullable(),
  });
};
