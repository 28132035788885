import React, { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { PaymentMethod } from '../../common';
import { store } from '../../../../store';
import {
  selectLoadingState,
  selectPayment,
  setPayment,
} from '../../../../stores/membership';
import {
  SKIP_PAYMENT_FOR_NOW,
  PAYMENT_METHOD_OTHER,
} from '../../../../constants/payment';
import CardPayment from '../../common/CardPayment';
import BankPayment from '../../common/BankPayment';
import { LOADING_STATE } from '../../../../constants/misc';

const Payment = ({ whiteBg }) => {
  const dispatch = useDispatch();

  // Redux Store
  const payment = useSelector(selectPayment);
  const {
    paymentMethod,
    tmpPaymentData,
    cardErrorMessage,
    bankErrorMessage,
    errorMessage,
  } = payment;
  const loadingState = useSelector(selectLoadingState);

  // Context Store
  const state = useContext(store);
  const { globalState } = state;
  const { tenantSettings } = globalState;

  const onChangePaymentMethod = (value) => {
    dispatch(setPayment({ paymentMethod: value }));
  };

  const onClearCardError = () => {
    dispatch(
      setPayment({
        cardErrorMessage: '',
        errorMessage: '',
      })
    );
  };

  const onClearBankError = () => {
    dispatch(
      setPayment({
        bankErrorMessage: '',
        errorMessage: '',
      })
    );
  };

  const onChangeData = (type, data) => {
    if (type.toLowerCase() === 'card') {
      dispatch(
        setPayment({
          tmpPaymentData: {
            ...tmpPaymentData,
            card: {
              token: data.token,
              expiry: data.expiry,
              tokenSource: data.tokenSource || '',
              cardNumber: data.cardNumber || '',
              cvc: data.cvc || '',
            },
          },
        })
      );
    } else if (type.toLowerCase() === 'bank') {
      dispatch(
        setPayment({
          tmpPaymentData: {
            ...tmpPaymentData,
            bank: {
              token: data.token,
              tokenSource: data.tokenSource || '',
              accountNumber: data.accountNumber || '',
              routingNumber: data.routingNumber || '',
              accountType: data.accountType || '',
            },
          },
        })
      );
    }
  };

  const onAddNewCardClick = () => {
    dispatch(
      setPayment({
        tmpPaymentData: {
          ...tmpPaymentData,
          card: {
            token: '',
            expiry: '',
            tokenSource: '',
            cardNumber: '',
            cvc: '',
          },
        },
        cardErrorMessage: '',
        errorMessage: '',
      })
    );
  };

  const onAddNewBankClick = () => {
    dispatch(
      setPayment({
        tmpPaymentData: {
          ...tmpPaymentData,
          bank: {
            token: '',
          },
        },
        bankErrorMessage: '',
        errorMessage: '',
      })
    );
  };

  const isSubmitting = loadingState === LOADING_STATE.CHECKOUT;

  return (
    <>
      <PaymentMethod
        data={{ payment }}
        changePaymentMethod={onChangePaymentMethod}
        tenantSettings={tenantSettings}
        includeBank={true}
        includeOther={true}
        whiteBg={whiteBg}
      />
      <div className="form-section-divider-md"></div>

      {!!paymentMethod &&
        paymentMethod !== SKIP_PAYMENT_FOR_NOW &&
        paymentMethod?.toLowerCase() !==
          PAYMENT_METHOD_OTHER?.toLowerCase() && (
          <h5 className="dedicated-form-title mb-2">
            Enter {paymentMethod.toLowerCase()} information
          </h5>
        )}

      {paymentMethod === 'Card' && (
        <CardPayment
          onClearCardError={onClearCardError}
          filledCardData={tmpPaymentData?.card}
          onChangeData={onChangeData}
          onAddNewCardClick={onAddNewCardClick}
          cardErrorMessage={cardErrorMessage}
          errorMessage={errorMessage}
          isSubmitting={isSubmitting}
        />
      )}
      {paymentMethod === 'Bank' && (
        <BankPayment
          onClearBankError={onClearBankError}
          filledBankData={tmpPaymentData?.bank}
          onChangeData={onChangeData}
          onAddNewBankClick={onAddNewBankClick}
          bankErrorMessage={bankErrorMessage}
          errorMessage={errorMessage}
          isSubmitting={isSubmitting}
        />
      )}
    </>
  );
};

export default Payment;
