import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TruncateWithToolTip } from '../common';
import { parseHtml } from '../util';
import { getAddressDisplayName } from '../util/vitafyUtil';

const ListItem = (props) => {
  const {
    icon,
    text,
    iconClassName,
    textClassName,
    containerClassName = 'mb-1',
    address = false,
    addressDoubleLine = true,
    iconWrapperClassName = 'mr-3',
    truncateText = false,
    textChar = 30
  } = props;
  return (
    <li className={`flex items-start mt-0 ${containerClassName}`}>
      <div className={`flex justify-center w-4 mt-1 ${iconWrapperClassName}`}>
        <FontAwesomeIcon icon={icon} className={`text-primary ${iconClassName}`} />
      </div>
      {address ?
        <div className={`body-text text-sm ${textClassName}`}>{getAddressDisplayName(text, addressDoubleLine)}</div>
        :
        <>
          {truncateText ?
            <TruncateWithToolTip
              text={text}
              char={textChar}
              textClassName={textClassName}
            />
            :
            <div
              className={`body-text text-sm ${textClassName}`}
              dangerouslySetInnerHTML={{
                __html: parseHtml(!address ? text : getAddressDisplayName(text)),
              }}
            />
          }

        </>
      }
    </li>
  );
}

export default ListItem;