import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  faMapMarkerAlt,
  faPhoneAlt
} from '@fortawesome/free-solid-svg-icons';
import {
  ListItem
} from '../../common';
import { getSeoOptmizedUrl } from '../../util/vitafyUtil';
import avatarPlaceholderSquare from '../../assets/images/avatar-placeholder-square.png';
import * as vitafyUtils from '../../util/vitafyUtil';

const TopDpc = (props) => {
  const { dpcList } = props;
  return (
    <div className='container mx-auto px-4'>
      <h3 className='heading'>Top Direct Primary Care</h3>

      <div className='flex -mx-4 w-full top-dpc-overflow'>
        {dpcList && dpcList.map((dpc, index) =>
          <div className='w-1/4 px-4 top-dpc-card-min-width' key={index}>
            <Card detail={dpc} />
          </div>
        )}
      </div>
    </div>
  );
}

const Card = (props) => {
  const { detail } = props;
  const history = useHistory();
  const seoOptimizedUrl = getSeoOptmizedUrl(detail.displayName);
  return (
    <div className='bg-cardBgColor rounded-sm shadow-md cursor-pointer'
      onClick={() => history.push(`/${seoOptimizedUrl}/${detail.providerId}`)}
    >
      <div className='w-full'>
        <img
          src={detail.imageUrl ? detail.imageUrl : avatarPlaceholderSquare}
          className='object-contain w-full h-40'
          alt="dpc"
          onError={e => { e.target.onerror = null; e.target.src = `${avatarPlaceholderSquare}` }}
        />
      </div>
      <div className='p-4'>
        <h6 className='body-text text-titleColor font-bold text-truncate' > {detail.displayName}</h6>

        <ul className='block mt-2'>
          <ListItem
            icon={faMapMarkerAlt}
            text={detail.practiceAddress}
            containerClassName='mb-0'
            address={true}
            addressDoubleLine={true}
          />
          <ListItem
            icon={faPhoneAlt}
            text={detail.phone ? vitafyUtils.getPhoneDisplayText(detail.phone, detail?.countryCode) : 'N/A'}
            textClassName={`text-titleColor ${detail.phone && 'font-bold'}`}
            containerClassName='mb-0'
          />
        </ul>
      </div>
    </div>
  );
}

export default TopDpc;