import React, { useState, useEffect, createContext } from 'react';
import { useLocation } from 'react-router-dom';

import * as enrollmentService from './service/enrollmentService';
import * as tenantService from './service/tenantService';
import * as langDictionaryService from './service/langDictionaryService';

import tenantCodeConstant from './constants/tenantCodeConstant';
import { tenantCodeEnum } from './constants/tenantCodeConstant';
import langConstant from './constants/langConstant';

import { getSubstringBetweenChars } from './util';
import {
  checkIsMultipleLocationRoute,
  getWebsiteInfo,
} from './util/vitafyUtil';

// const { REFERENCE_TYPE } = commonConstants;

const initialState = {};
const store = createContext(initialState);
const { Provider } = store;

// const TENANT_TYPE = {
//   DPC: 'DPC',
//   AGENT: 'AGENT'
// }

// Fetch Provider Details with referenceId for these referenceTypes
// const HAS_PROVIDER = [
//   REFERENCE_TYPE.DPC, REFERENCE_TYPE.DENTAL, REFERENCE_TYPE.PROVIDER
// ];

// There is no corresponding provider for the given referenceId for these referenceTypes
// Append the necessary details to the providerDetail obj
// const HAS_NO_PROVIDER = [
//   REFERENCE_TYPE.TENANT,
//   REFERENCE_TYPE.AGENT,
//   REFERENCE_TYPE.NETWORK,
//   REFERENCE_TYPE.ADVOCATE
// ]

const StateProvider = (props) => {
  const [globalState, setGlobalState] = useState({
    locations: [],
    // for multiple location landing page, the primary location is stored
    // for single location, that location is stored. (locations.length === 1 -> single location)
    primaryLocation: null,
    providerDetail: {}, // Depreciate it. Replace it with `primaryLocation` in all the serivces modules
    isLoading: true,
    tenantCode: '',
    groupCode: '',
    tenantId: null,
    merchantProfile: {
      paymentEnvironment: '',
    },
    tenantSettings: [],
    langDict: null,
  });

  const location = useLocation();

  const websiteInfo = getWebsiteInfo();

  useEffect(() => {
    const fetchLangDictionary = async (queryParams) => {
      setGlobalState((prevState) => ({ ...prevState, isLoading: true }));
      const res = await langDictionaryService.fetchLangDictionary(
        langConstant.EN,
        queryParams
      );
      setGlobalState((prevState) => ({
        ...prevState,
        langDict: res.data,
        isLoading: false,
      }));
    };

    const fetchTenantSettings = (tenantId) => {
      enrollmentService
        .fetchTenantSettings(tenantId)
        .then((response) => {
          setGlobalState((prevState) => ({
            ...prevState,
            tenantSettings: response.data,
          }));
        })
        .catch((error) =>
          console.log('Error while fetching tenant settings', error)
        );
    };

    const fetchMerchantProfile = (tenantId) => {
      enrollmentService
        .fetchMerchantProfileByReferenceId(tenantId)
        .then((response) => {
          const profile = response.data?.gatewayDetails?.find(
            (item) => item.isPrimary === 'true'
          );

          if (profile) {
            setGlobalState((prevState) => ({
              ...prevState,
              merchantProfile: {
                paymentEnvironment: profile.environment?.toLowerCase(),
                gatewayType: profile.gatewayType,
                clientKey_live: profile.clientKey_live,
                clientKey_test: profile.clientKey_test,
                merchantId_live: profile.merchantId_live,
                merchantId_test: profile.merchantId_test,
                authorization_live: profile.authorization_live,
                authorization_test: profile.authorization_test,
              },
            }));
          }
        })
        .catch((error) =>
          console.log('Error while fetching tenant merchant profile', error)
        );
    };

    // TODO: Replace the use of `providerDetail` with `primaryLocation` inside all the service modules (Membership, walk-in, etc)
    // Populating the `providerDetail` is just a workaround so that the service modules using it functions as before.
    const populateProviderDetail = (data) => {
      if (!data) {
        return;
      }

      setGlobalState((prevState) => ({
        ...prevState,
        providerDetail: {
          headerLogo: data.metaData?.imageUrl,
          businessName: data.businessName,
          email: data.email,
          phone: data.phone,
          description: data.metaData?.description,
          tagLine: data.metaData?.tagline,
          displayName: data.displayName,
          practiceAddress: data.address,
          siteTitle: data.metaData?.title,
          siteMetaDescription: data.metaData?.description,
          faviconUrl: data.metaData?.faviconUrl,
          website: data.website,
          // serviceName: response.data.serviceName
        },
        tenantId: data.tenantId,
      }));
    };

    const fetchTenantByGroupCode = async (
      queryParams,
      { isMultipleLocationsRoute }
    ) => {
      const response = await tenantService.fetchTenantByGroupCode(queryParams);
      const locations = response.data?.rows;

      const primaryLocation =
        locations?.length === 1
          ? locations[0]
          : locations?.find((item) => !!item.isPrimary);

      // No need to populate providerDetail for multiple locations landing page
      if (!isMultipleLocationsRoute) {
        populateProviderDetail(primaryLocation);
        fetchTenantSettings(primaryLocation.tenantId);
        fetchMerchantProfile(primaryLocation.tenantId);
      }

      setGlobalState((prevState) => ({
        ...prevState,
        locations,
        primaryLocation,
        groupCode: primaryLocation.groupCode,
        isLoading: false,
      }));
    };

    if (websiteInfo) {
      setGlobalState((prevState) => ({ ...prevState, isLoading: true }));

      const { tenantCode, groupCode, routeType } = websiteInfo;
      const queryParams = {};

      const tenantCodeURL = getSubstringBetweenChars(
        location.pathname?.substring(1)?.toUpperCase() || '',
        '/'
      );

      const isMultipleLocationsRoute = checkIsMultipleLocationRoute(
        routeType,
        tenantCode,
        tenantCodeURL
      );

      if (isMultipleLocationsRoute) {
        setGlobalState((prevState) => ({ ...prevState, groupCode }));
        queryParams.groupCode = groupCode;
        fetchLangDictionary({ groupCode });
      } else {
        const code = tenantCode.find((code) => code === tenantCodeURL);
        setGlobalState((prevState) => ({
          ...prevState,
          tenantCode: code,
          groupCode,
        }));
        queryParams.tenantCode = code;
        fetchLangDictionary({ code });
      }

      fetchTenantByGroupCode(queryParams, { isMultipleLocationsRoute });
    }
  }, [websiteInfo, location.pathname]);

  return (
    <Provider value={{ globalState, setGlobalState }}>
      {props.children}
    </Provider>
  );
};

export { store, StateProvider };
