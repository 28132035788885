import * as httpService from './httpService';
import APICONSTANTS from '../constants';

export function savePartialEnrollmentToSalesLead(data) {
  return httpService.post(`${APICONSTANTS.SALES_LEAD}`, data)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    })
}

export function updatePartialEnrollmentToSalesLead(data) {
  return httpService.put(`${APICONSTANTS.SALES_LEAD}`, data)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    })
}