/** Title for call to action modal (Request a demo modal)  */
const ctaModalTitle = {
  REQUEST_DEMO: "Request a demo",
  REQUEST_PRICING: "Request pricing",
  CONTACT_US: "Contact us",
  CONTACT_OUR_TEAM: "Contact our team",
  CONTACT_FOR_MORE_DETAILS: "Contact us for more details",
  REQUEST_APPOINTMENT: "Request Appointment",
};

export default ctaModalTitle;
