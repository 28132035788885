import React, { Component } from "react";
import { ReactTitle } from "react-meta-tags";

import {
  Header,
  BannerWithSearch,
  ProviderAutoComplete,
  LocationAutocomplete,
  RequestDemo,
  Loader,
} from "../../common";
import SearchListing from "./SearchListing";

import * as networkProviderService from "../../service/networkProviderService";
import * as service from "../../service";

import { showToast } from "../../service/toasterService";
import { _debounce } from "../../util/lodashUtil";
import { getDistance, getSeoOptmizedUrl } from "../../util/vitafyUtil";

import commonConstants from "../../constants/commonConstant";

class DpcSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dpcList: [],
      dpcSearched: false,
      isSubmitting: {
        dpcSearch: false,
        moreDpc: false, // triggers loader at the bottom (for infinite scrolling)
      },
      loading: false, // triggers loader for the whole page
      continueInfiniteScroll: true,
      searchLocation: "",
      searchZipCode: null,
      searchKeyword: "",
      searchCode: null,
      searchCity: null,
      searchState: null,
      searchPrimaryCode: null,
      searchLatitude: null,
      searchLongitude: null,
      pageLimit: 10,
      pageOffset: 0,
      dpcSearchKeyword: "",
      dpcSearchLat: null,
      dpcSearchLng: null,
      searchedParamsForListing: {
        // location of searched dpc is used if the location is not provided
        dpcKeyword: null,
        city: null,
        state: null,
      },
      filter: {
        radiusList: [15, 30, 60, 120, 240], // radius to show for the filter
        radius: null,
        locations: [], // the search result will not include providers with location present in this array,
      },
      firstSearch: true, // set to true while fetching providers -> clear the location filters (but keep the radius unchanged)
      cosmicContent: {
        ads: [],
        banner: {},
        seo: {},
      },
      showBestMatchDistance: false,
      resultsByShortestDistance: true, // if false, results by best match
    };
    this.newRequestDemoModal = React.createRef();
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.getCosmicContent();
    // if it is being redirected from the dpcLanding page
    if (this.props.location.state) {
      const data = this.props.location.state;
      this.setState(
        {
          searchLocation: data.searchLocation,
          searchZipCode: data.searchZipCode,
          searchKeyword: data.searchKeyword,
          searchCode: data.searchCode,
          searchCity: data.searchCity,
          searchState: data.searchState,
          searchPrimaryCode: data.searchPrimaryCode,
          searchLatitude: data.searchLatitude,
          searchLongitude: data.searchLongitude,
          pageLimit: data.pageLimit,
          pageOffset: data.pageOffset,
          dpcSearchKeyword: data.dpcSearchKeyword,
          dpcSearchLat: data.dpcSearchLat,
          dpcSearchLng: data.dpcSearchLng,
          dpcSearched: true,
          firstSearch: true,
        },
        () => {
          this.searchHandler({ clearDpcList: true, filterWithRadius: false });
        }
      );
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  /**
   * For the infinite scrolling
   * When the the user scrolls to the bottom, fetch more providers
   */
  handleScroll = _debounce(() => {
    if (
      !this.state.dpcSearched ||
      this.state.isSubmitting.moreDpc ||
      !this.state.continueInfiniteScroll
    )
      return;
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = Math.round(windowHeight + window.pageYOffset);
    if (docHeight - windowBottom > 10) return;
    this.setState((prevState) => ({
      isSubmitting: {
        ...prevState.isSubmitting,
        moreDpc: true,
      },
    }));
    this.searchHandler({ clearDpcList: false, filterWithRadius: false });
  }, 100);

  handleInputChange = (e, field, clearFields = []) => {
    let value = e.target ? e.target.value : e;
    if ((e.target ? e.target.validity.valid : e) || value == "") {
      let newState = {
        [field]: value,
      };
      clearFields.map((x) => (newState[x] = null));
      this.setState((prevState) => ({
        ...prevState,
        ...newState,
      }));
    }
  };

  onSelectHandler = (selectedItem, fields = [], setValueFields = []) => {
    let newState = {};
    setValueFields.map(
      (x, index) => (newState[x] = selectedItem[fields[index]])
    );
    this.setState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  providerAutocompleteOnSelectHandler = (
    selectedItem,
    fields = [],
    setValueFields = []
  ) => {
    // if user selects particular provider from the autocomplete,
    // direct to the provider detail page directly
    if (selectedItem.providerId) {
      const seoOptimizedUrl = getSeoOptmizedUrl(selectedItem.displayName);
      this.props.history.push(`/${seoOptimizedUrl}/${selectedItem.providerId}`);
      return;
    }
    // if provider isn't selected from the autocomplete,
    // store the keyword
    let newState = {};
    setValueFields.map(
      (x, index) => (newState[x] = selectedItem[fields[index]])
    );
    this.setState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  /**
   * Initial Search -> Search by filling dpcKeyword and/or location and clicking search button
   * Clear the location filters if any bt keep the radius filter unchanged
   * firstSearch flag are set to true
   */
  handleInitialSearch = () => {
    this.setState(
      {
        firstSearch: true,
      },
      () => {
        this.searchHandler({
          clearDpcList: true,
          filterWithRadius: this.state.filter.radius ? true : false,
        });
      }
    );
  };

  /**
   *  Search and Filter Functionality
   *  Search with new dpckeyword or new location or both -> Reset all the filters and update this.state.filter.locations with the response's location
   *  Radius Filter only -> Update this.state.filter.locations with the response's location
   *  Location Filter -> Send the deselected location array and this.state.filter.locations stays same (only the included flag is changed)
   *  Both Radius & Location Filters -> Possible only when radius is selected followed by location.
   *                   In case, the radius is selected after locations, the location filter is cleared and it is updated with new locations
   *                   In former case, send radius along with deselected location and don't update this.state.fillter.locations with newer locations.
   * */
  searchHandler = ({ clearDpcList = false, filterWithRadius = false }) => {
    if (
      !(
        (this.state.searchLatitude && this.state.searchLongitude) ||
        this.state.dpcSearchKeyword
      )
    ) {
      showToast(
        "warning",
        "Please provide either DPC name or location to search."
      );
      return;
    }
    if (!this.state.searchLocation) this.resetLocationSearchParams();
    !this.state.isSubmitting.moreDpc &&
      this.setState((prevState) => ({
        isSubmitting: {
          ...prevState.isSubmitting,
          dpcSearch: true,
        },
        dpcSearched: true,
        searchedParamsForListing: {
          dpcKeyword: this.state.dpcSearchKeyword
            ? this.state.dpcSearchKeyword
            : null,
          city: this.state.searchLocation ? this.state.searchCity : null,
          state: this.state.searchLocation ? this.state.searchState : null,
        },
      }));
    const searchedData = {
      latitude: this.state.searchLocation ? this.state.searchLatitude : null,
      longitude: this.state.searchLocation ? this.state.searchLongitude : null,
      limit: this.state.pageLimit,
      offset: clearDpcList ? 0 : this.state.pageOffset,
      keyword: this.state.dpcSearchKeyword,
    };
    if (!this.state.searchLocation) {
      searchedData.latitude = this.state.dpcSearchLat;
      searchedData.longitude = this.state.dpcSearchLng;
    }
    if (!this.state.dpcSearchKeyword) {
      searchedData.sortBy = "distance";
      delete searchedData.keyword;
    }
    if (this.state.filter.radius) {
      searchedData.radius = parseInt(this.state.filter.radius);
    }
    if (
      this.state.filter.locations.length > 0 &&
      !this.state.firstSearch &&
      !filterWithRadius
    ) {
      let totalLocations = [...this.state.filter.locations];
      const deselectedLocations =
        totalLocations &&
        totalLocations
          .filter((location) => !location.included)
          .map((location) => location.location);
      searchedData.deselectedLocations = deselectedLocations;
    }
    if (this.state.searchLocation && this.state.dpcSearchKeyword) {
      searchedData.sortBy = this.state.resultsByShortestDistance
        ? "distance"
        : null;
    }
    this.searchProviders(searchedData)
      .then((data) => {
        const { totalProvider, providerList } = data;
        let locations = [];
        // if the searched result is filtered by location, don't update the filter locations with the reponse's locations
        if (
          filterWithRadius ||
          this.state.firstSearch ||
          this.state.filter.locations.length < 1
        ) {
          locations =
            data.locations &&
            data.locations.sort((a, b) => (a.location > b.location ? 1 : -1));
          locations = locations.map((location) => {
            return {
              ...location,
              included: true,
            };
          });
        } else {
          locations = [...this.state.filter.locations];
        }
        this.setState(
          (prevState) => ({
            continueInfiniteScroll:
              prevState.dpcList.length + providerList.length < totalProvider,
            dpcList: clearDpcList
              ? providerList
              : [...prevState.dpcList, ...providerList],
            filter: {
              ...prevState.filter,
              locations: locations,
            },
            isSubmitting: {
              ...prevState.isSubmitting,
              dpcSearch: false,
              moreDpc: false,
            },
            pageOffset: clearDpcList ? 0 : prevState.pageOffset,
            firstSearch: false,
            showBestMatchDistance:
              prevState.dpcSearchKeyword && prevState.searchLocation
                ? true
                : false,
          }),
          () => {
            this.setState((prevState) => ({
              pageOffset: prevState.continueInfiniteScroll
                ? prevState.pageOffset + prevState.pageLimit
                : prevState.pageOffset,
            }));
          }
        );
      })
      .catch((error) => {
        console.log("Error while fetching providers", error);
        this.setState((prevState) => ({
          isSubmitting: {
            ...prevState.isSubmitting,
            dpcSearch: false,
            moreDpc: false,
          },
        }));
      });
  };

  resetLocationSearchParams = () => {
    this.setState({
      searchLocation: "",
      searchZipCode: null,
      searchKeyword: "",
      searchCode: null,
      searchCity: null,
      searchState: null,
      searchPrimaryCode: null,
      searchLatitude: null,
      searchLongitude: null,
    });
  };

  searchProviders = (params) => {
    params.category = "dpcDirectory";
    params.entityCode = 2;
    return new Promise((resolve, reject) => {
      networkProviderService
        .findAllNetworkProvider(params)
        .then((response) => {
          if (response.status === "success") {
            response.data.providerList.map((x) => {
              x.providerName = x.displayName;
              if (x.practiceAddress) {
                x.lat = +x.practiceAddress.lat;
                x.lng = +x.practiceAddress.lng;
              }
              if (!params.isAutoComplete)
                x.distance = x.practiceAddress
                  ? getDistance(
                      x.practiceAddress.lat,
                      x.practiceAddress.lng,
                      params.latitude,
                      params.longitude
                    )
                  : null;
              x.seoOptimizedUrl = getSeoOptmizedUrl(x.displayName);
              return x;
            });
            // only send providerList for autocomplete
            !params.isAutoComplete
              ? resolve(response.data)
              : resolve(response.data.providerList);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  handleChangeFilterRadius = (radius) => {
    this.setState(
      (prevState) => ({
        filter: {
          ...prevState.filter,
          radius: radius,
        },
      }),
      () => {
        this.searchHandler({ clearDpcList: true, filterWithRadius: true });
      }
    );
  };

  handleChangeFilterLocation = (locationObj, selectedLocationOnly = false) => {
    let filterLocations = [...this.state.filter.locations];
    filterLocations = filterLocations.map((location) => {
      if (selectedLocationOnly) {
        return {
          ...location,
          included: location === locationObj ? true : false,
        };
      }
      return {
        ...location,
        included:
          location === locationObj ? !location.included : location.included,
      };
    });
    this.setState(
      (prevState) => ({
        filter: {
          ...prevState.filter,
          locations: filterLocations,
        },
      }),
      () => {
        this.searchHandler({ clearDpcList: true, filterWithRadius: false });
      }
    );
  };

  handleClearFilters = () => {
    let filterLocations = [...this.state.filter.locations];
    filterLocations = filterLocations.map((location) => {
      return {
        ...location,
        included: true,
      };
    });
    this.setState(
      (prevState) => ({
        filter: {
          ...prevState.filter,
          radius: null,
          locations: filterLocations,
        },
      }),
      () => {
        this.searchHandler({ clearDpcList: true, filterWithRadius: false });
      }
    );
  };

  handleShowResultsByChange = (value) => {
    this.setState(
      {
        resultsByShortestDistance: value,
      },
      () => {
        this.searchHandler({ clearDpcList: true, filterWithRadius: false });
      }
    );
  };

  getCosmicContent = () => {
    this.setState({ loading: true });
    service
      .getDpcCosmicContent()
      .then((response) => {
        if (response.status === "success") {
          const dpcSearch = response.data.find(
            (item) => item.slug === "dpc-search"
          ).metadata;
          if (dpcSearch) {
            this.setState((prevState) => ({
              cosmicContent: {
                ...prevState.cosmicContent,
                ads: dpcSearch.ads,
                banner: dpcSearch.banner,
                seo: dpcSearch.seo,
              },
            }));
          }
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        console.log("Error in get DPC Search cosmic content", error);
        this.setState({ loading: false });
      });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <ReactTitle
          title={
            this.state.cosmicContent.seo?.metadata?.page_title ||
            commonConstants.SEO.DEFAULT_PAGE_TITLE
          }
        />

        <Header />
        <BannerWithSearch
          title={this.state.cosmicContent.banner?.title}
          subtitle={this.state.cosmicContent.banner?.tagline}
          subtitleVisibility={
            this.state.cosmicContent.banner?.tagline_visibility
          }
          leftSearch={
            <ProviderAutocompleteInput
              handleInputChange={this.handleInputChange}
              onSelectHandler={this.providerAutocompleteOnSelectHandler}
              searchProviders={this.searchProviders}
              dpcSearchKeyword={this.state.dpcSearchKeyword}
            />
          }
          rightSearch={
            <LocationAutocompleteInput
              handleInputChange={this.handleInputChange}
              onSelectHandler={this.onSelectHandler}
              searchLocation={this.state.searchLocation}
            />
          }
          searchHandler={this.handleInitialSearch}
          showOr={true}
        />

        <SearchListing
          list={this.state.dpcList}
          filterLocationList={this.state.filter.locations}
          filterRadius={this.state.filter?.radius}
          radiusList={this.state.filter?.radiusList}
          handleChangeFilterRadius={this.handleChangeFilterRadius}
          handleChangeFilterLocation={this.handleChangeFilterLocation}
          handleClearFilters={this.handleClearFilters}
          showBestMatchDistance={this.state.showBestMatchDistance}
          resultsByShortestDistance={this.state.resultsByShortestDistance}
          handleShowResultsByChange={this.handleShowResultsByChange}
          isLoading={this.state.isSubmitting.dpcSearch}
          isMoreLoading={this.state.isSubmitting.moreDpc}
          searched={this.state.dpcSearched}
          searchParams={this.state.searchedParamsForListing}
          requestDemoClick={() => this.newRequestDemoModal.current.toggle()}
          ads={this.state.cosmicContent?.ads}
        />

        <RequestDemo formModal={this.newRequestDemoModal} />
      </>
    );
  }
}

const ProviderAutocompleteInput = (props) => {
  const autocompleteInputHandler = (keyword) => {
    let params = {
      isAutoComplete: true,
      keyword,
    };
    if (
      props.searchLocation?.lng &&
      Math.round(props.searchLocation.lng) != 85
    ) {
      //second condition => neglect lat long of ktm
      params = {
        ...params,
        latitude: props.searchLocation.lat,
        longitude: props.searchLocation.lng,
      };
    }
    return props.searchProviders(params);
  };
  return (
    <ProviderAutoComplete
      onSelectHandler={(e) =>
        props.onSelectHandler(
          e,
          ["displayName", "lat", "lng"],
          ["dpcSearchKeyword", "dpcSearchLat", "dpcSearchLng"]
        )
      }
      handleInputChange={(e) => props.handleInputChange(e, "dpcSearchKeyword")}
      placeholder="Search DPC Name"
      displayNameValue={props.dpcSearchKeyword}
      searchForAutoComplete={autocompleteInputHandler}
      inputRequired={false}
    />
  );
};

const LocationAutocompleteInput = (props) => {
  return (
    <LocationAutocomplete
      placeholder="Search by Location"
      inputRequired={false}
      iconType="location"
      onSelectHandler={(e) =>
        props.onSelectHandler(
          e,
          ["displayName", "zip", "st", "city", "lat", "lng"],
          [
            "searchLocation",
            "searchZipCode",
            "searchState",
            "searchCity",
            "searchLatitude",
            "searchLongitude",
          ]
        )
      }
      handleInputChange={(e) =>
        props.handleInputChange(e, "searchLocation", [
          "searchCity",
          "searchState",
          "searchZipCode",
        ])
      }
      displayNameValue={props.searchLocation}
    />
  );
};

export default DpcSearch;
