import React, { Component } from "react";
import {
  Header,
  Loader,
  Banner,
  ValueProp,
  CallToAction,
  Footer,
  Partner,
} from "../../common";
import * as service from "../../service";
import { ReactTitle } from "react-meta-tags";
import commonConstants from "../../constants/commonConstant";

class Community extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cosmicContent: {
        banner: {},
        valueProps: {},
        cta: {},
        partners: [],
        seo: {},
      },
      loading: true,
    };
  }

  componentDidMount() {
    this.getDirectCareProvidersContent();
  }

  getDirectCareProvidersContent = () => {
    service
      .getCommunityContent()
      .then((response) => {
        if (response.status === "success") {
          const cosmicContent = response.data.find(
            (item) => item.slug === "community"
          ).metadata;
          this.setState((prevState) => ({
            cosmicContent: {
              ...prevState.cosmicContent,
              banner: cosmicContent.banner,
              valueProps: cosmicContent.value_props,
              cta: cosmicContent.cta,
              partners: cosmicContent.partners?.metadata,
              seo: cosmicContent.seo,
            },
          }));
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        console.log("Error in get DPC Sales cosmic content", error);
      });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <ReactTitle
          title={
            this.state.cosmicContent.seo?.metadata?.page_title ||
            commonConstants.SEO.DEFAULT_PAGE_TITLE
          }
        />

        <Header />
        <Banner banner={this.state.cosmicContent.banner} encloseImage={false} />

        <div className="mid-divider"></div>

        <ValueProp
          title={this.state.cosmicContent.valueProps?.mission_statement}
          valuePropsTitleVisibility={false}
          values={this.state.cosmicContent.valueProps?.value_props}
          bgColor={false}
        />

        <CallToAction
          title={this.state.cosmicContent.cta?.title}
          description={this.state.cosmicContent.cta?.content}
          actionButtonText={this.state.cosmicContent.cta?.action_button_text}
          actionButtonLink="/marketplace"
          requestDemo={false}
        />
        <div className="divider"></div>
        <Partner
          partners={this.state.cosmicContent.partners?.partners_images}
          title={this.state.cosmicContent.partners?.title}
          caption={this.state.cosmicContent.partners?.caption}
        />

        <div className="divider"></div>
        <Footer />
      </>
    );
  }
}

export default Community;
