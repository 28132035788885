export const PHASE = {
  GET_STARTED: 'GET_STARTED',
  QUESTION: 'QUESTION',
  INELIGIBLE: 'INELIGIBLE',
  SUBMIT: 'SUBMIT',
};

export const QUESTION_TYPE = {
  RADIO: 'Radio',
  CHECKBOX: 'Checkbox',
  TEXT: 'Text',
  INFORMATION: 'Information',
  ATTACHMENT: 'Attachment',
  AGREE_DISAGREE: 'AgreeDisagree',
  DATE: 'Date',
  SUBMIT_CONFIRMATION: 'SubmitConfirmation',
};

export const QUESTION_STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};

export const AGREE_DISAGREE_VALUE = {
  AGREE: 'Agree',
  DISAGREE: 'Disagree',
};

export const VISIBILITY = {
  SEEN: 'Seen',
  UNSEEN: 'Unseen',
};
