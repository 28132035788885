import React, { useContext } from 'react';
import { ButtonLoader, DedicatedFormModal } from '../../../common';
import { store } from '../../../store';
import { getTextFromLangDict } from '../../../util';

const ActionConfirmationModal = ({
  modalTitle,
  modalContent,
  onConfirm,
  onCancel,
  isSubmitting,
}) => {
  // Context Store
  const state = useContext(store);
  const { globalState } = state;
  const { langDict, groupCode, tenantCode } = globalState;

  return (
    <DedicatedFormModal modalTitle={modalTitle} reset={onCancel} show>
      <div className="modal-body">
        <p>{modalContent}</p>
      </div>
      <div className="modal-footer">
        <button
          className={`mid-button px-4 py-3 bg-transparent text-dedicatedDpcSecondary hover:bg-gray-300 ${
            isSubmitting && 'btn-disabled'
          }`}
          type="button"
          onClick={onCancel}
        >
          {getTextFromLangDict(langDict, {
            key: '_CANCEL',
            groupCode,
            tenantCode,
          })}
        </button>
        <button
          className={`mid-button px-10 py-3 bg-dedicatedDpcSecondary hover:bg-dedicatedDpcHovered flex justify-center items-center ${
            isSubmitting && 'btn-disabled'
          }`}
          type="button"
          onClick={onConfirm}
        >
          {isSubmitting ? (
            <div className="inline mr-2">
              <ButtonLoader />
            </div>
          ) : null}
          <span>
            {getTextFromLangDict(langDict, {
              key: '_CONFIRM',
              groupCode,
              tenantCode,
            })}
          </span>
        </button>
      </div>
    </DedicatedFormModal>
  );
};

export default ActionConfirmationModal;
