import React from 'react';
import {
  faEnvelope,
  faMobileAlt,
  faMapMarkerAlt,
} from '@fortawesome/free-solid-svg-icons';

import { ListItem } from '../../common';

import * as vitafyUtils from '../../util/vitafyUtil';

const Branch = ({ data, lastIndex }) => {
  const baseUrl = process.env.PUBLIC_URL;

  return (
    <div className={`w-full md:w-1/2 ${lastIndex && 'mt-6 md:mt-0'}`}>
      <div
        data-cy={`branch-${data.code?.toLowerCase()}`}
        className="w-full bg-white shadow-md hover:shadow-xl hover:bg-dedicatedDpcSuccess"
      >
        <a
          href={`${baseUrl}/${data.code?.toLowerCase()}`}
          target="_blank"
          rel="noopener noreferrer"
          className="group help-center-menu-button h-auto md:h-64 py-8 md:py-0 border-b border-r cursor-pointer"
        >
          <div>
            <h6 className="font-heebo text-titleColor text-xl uppercase group-hover:text-dedicatedDpcPrimary mb-3">
              {data.metaData?.displayName}
            </h6>
            <ListItem
              icon={faMapMarkerAlt}
              text={data.address}
              iconClassName="text-titleColor text-sm mr-1"
              textClassName="text-bodyColor text-sm"
              containerClassName="mb-1"
              iconWrapperClassName="mr-2"
              address={true}
              addressDoubleLine={true}
            />
            <ListItem
              icon={faMobileAlt}
              text={vitafyUtils.getPhoneDisplayText(
                data.phone,
                data?.countryCode
              )}
              iconClassName="text-titleColor text-sm mr-1"
              textClassName="text-bodyColor text-sm"
              containerClassName="mb-1"
              iconWrapperClassName="mr-2"
              address={false}
              addressDoubleLine={false}
            />
            <ListItem
              icon={faEnvelope}
              text={data.email?.toLowerCase()}
              iconClassName="text-titleColor text-sm mr-1"
              textClassName="text-bodyColor text-sm"
              containerClassName="mb-1"
              iconWrapperClassName="mr-2"
              address={false}
              addressDoubleLine={false}
            />
          </div>
        </a>
      </div>
    </div>
  );
};

export default Branch;
