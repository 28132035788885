import React, { Component } from 'react';
import {
  Header,
  BannerWithSearch,
  ProcedureAutocomplete,
  LocationAutocomplete,
  RequestDemo,
  Loader
} from '../../common';
import SearchListing from './SearchListing';

import * as transparencyService from '../../service/transparencyService';
import * as service from '../../service';

import { showToast } from '../../service/toasterService';
import { _debounce } from '../../util/lodashUtil';
import * as vitafyUtil from '../../util/vitafyUtil';
import * as locationUtil from '../../util/locationUtil';

import commonConstants from '../../constants/commonConstant';

const DEFAULT_RADIUS = "120";
class TransparencySearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      isSearched: false,
      isSubmitting: {
        search: false,
        moreSearch: false    // triggers loader at the bottom (for infinite scrolling)
      },
      loading: false, // triggers loader for the whole page 
      continueInfiniteScroll: true,
      searchLocation: '',
      searchZipCode: null,
      searchKeyword: '',
      searchCode: null,
      searchCity: null,
      searchState: null,
      searchPrimaryCode: null,
      searchLatitude: null,
      searchLongitude: null,
      pageLimit: '100',
      pageOffset: 0,
      procedureSearchKeyword: '',
      searchedParamsForListing: { // location of searched dpc is used if the location is not provided
        keyword: null,
        city: null,
        state: null
      },
      filter: {
        radiusList: [15, 30, 60, 120, 240, 'All'], // radius to show for the filter
        radius: parseInt(DEFAULT_RADIUS),
        locations: [], // the search result will not include providers with location present in this array,
      },
      firstSearch: true, // set to true while fetching providers -> clear the location filters (but keep the radius unchanged)
      cosmicContent: {
        ads: []
      },
      showBestMatchDistance: false,
      resultsByShortestDistance: true, // if false, results by best match
      selectedProcedure: {}, // procedure selected from autocomplete
    }
    this.newRequestDemoModal = React.createRef();
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.getCosmicContent();

    // Ask location permission
    navigator.geolocation.getCurrentPosition(this.setCurrentLocation, () => {//in case of location denial
      let location = commonConstants.DEFAULT_LOCATION;
      this.setState({
        searchLocation: location.city + ', ' + location.state,
        searchZipCode: location.zip,
        searchCity: location.city,
        searchState: location.state,
        searchLatitude: location.latitude,
        searchLongitude: location.longitude,
      })
    })
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  setCurrentLocation = async (location) => {
    let rLocation = await locationUtil.getLocation(location);
    this.setState({
      searchLocation: rLocation.locationName,
      searchZipCode: rLocation.zip,
      searchCity: rLocation.city,
      searchState: rLocation.state,
      searchLatitude: rLocation.latitude,
      searchLongitude: rLocation.longitude,
    });
  }

  /**
   * For the infinite scrolling
   * When the the user scrolls to the bottom, fetch more data 
   */
  handleScroll = _debounce(() => {
    if (!this.state.isSearched || this.state.isSubmitting.moreSearch || this.state.isSubmitting.search || !this.state.continueInfiniteScroll) return;
    const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    const windowBottom = Math.round(windowHeight + window.pageYOffset);
    if (docHeight - windowBottom > 10) return;
    this.setState(prevState => ({
      isSubmitting: {
        ...prevState.isSubmitting,
        moreSearch: true
      }
    }))
    this.searchHandler({ clearList: false, filterWithRadius: false });
  }, 100);

  handleInputChange = (e, field, clearFields = []) => {
    let value = e.target ? e.target.value : e;
    if ((e.target ? e.target.validity.valid : e) || value === '') {
      let newState = {
        [field]: value,
      };
      clearFields.map((x) => (newState[x] = null));
      this.setState((prevState) => ({
        ...prevState,
        ...newState,
      }));
    }
  };

  onSelectHandler = (
    selectedItem,
    fields = [],
    setValueFields = []
  ) => {
    let newState = {};
    setValueFields.map(
      (x, index) => (newState[x] = selectedItem[fields[index]])
    );
    this.setState((prevState) => ({
      ...prevState,
      ...newState
    }));
  };

  procedureAutocompleteOnSelectHandler = (selectedItem) => {
    this.setState({
      selectedProcedure: selectedItem
    })
  }

  /** 
   * Initial Search -> Search by filling dpcKeyword and/or location and clicking search button
   * Clear the location filters if any bt keep the radius filter unchanged
   * firstSearch flag are set to true
  */
  handleInitialSearch = () => {
    this.setState({
      firstSearch: true
    }, () => {
      this.searchHandler({ clearList: true, filterWithRadius: this.state.filter.radius ? true : false });
    })
  }

  /** 
   *  Search and Filter Functionality
   *  Search with new keyword or new location or both -> Reset all the filters and update this.state.filter.locations with the response's location
   *  Radius Filter only -> Update this.state.filter.locations with the response's location
   *  Location Filter -> Send the deselected location array and this.state.filter.locations stays same (only the included flag is changed)
   *  Both Radius & Location Filters -> Possible only when radius is selected followed by location.
   *                   In case, the radius is selected after locations, the location filter is cleared and it is updated with new locations
   *                   In former case, send radius along with deselected location and don't update this.state.fillter.locations with newer locations. 
  * */
  searchHandler = ({ clearList = false, filterWithRadius = false }) => {
    if (!(this.state.searchState && !(this.state.selectedProcedure && Object.keys(this.state.selectedProcedure).length === 0 && this.state.selectedProcedure.constructor === Object))) {
      showToast('warning', 'Please enter CPT, DRG, Description and Location');
      return;
    }
    if (!this.state.searchLocation) this.resetLocationSearchParams();
    !this.state.isSubmitting.moreSearch &&
      this.setState(prevState => ({
        isSubmitting: {
          ...prevState.isSubmitting,
          search: true,
        },
        searchedParamsForListing: {
          searchKeyword: this.state.selectedProcedure?.procedureName ? this.state.selectedProcedure.procedureName : this.state.searchKeyword,
          city: this.state.searchLocation ? this.state.searchCity : null,
          state: this.state.searchLocation ? this.state.searchState : null
        },
        isSearched: true
      }))
    const reqBody = {
      searchKeyword: this.state.searchKeyword?.replaceAll('/', ' '),
      address: {
        lng: this.state.searchLongitude,
        lat: this.state.searchLatitude
      },
      drgCode: this.state.selectedProcedure?.drgCode,
      cptCode: this.state.selectedProcedure?.cptCode,
      limit: this.state.pageLimit,
      offset: clearList ? 0 : this.state.pageOffset,
      radius: this.state.filter.radius ? this.state.filter.radius.toString() : DEFAULT_RADIUS
    }
    if (this.state.filter.locations.length > 0 && !this.state.firstSearch && !filterWithRadius) {
      let totalLocations = [...this.state.filter.locations];
      const deselectedLocations = totalLocations && totalLocations.filter(location => !location.included).map(location => location.location);
      reqBody.deselectedLocations = deselectedLocations;
    }
    this.searchTransparency(reqBody)
      .then((data) => {
        const { totalCount, transparencyList } = data;
        let locations = [];
        // if the searched result is filtered by location, don't update the filter locations with the reponse's locations 
        if (filterWithRadius || this.state.firstSearch || this.state.filter.locations.length < 1) {
          locations = data.locations && data.locations.sort((a, b) => (a.location > b.location) ? 1 : -1);
          locations = locations.map(location => {
            return {
              ...location,
              included: true
            }
          });
        } else {
          locations = [...this.state.filter.locations];
        }
        this.setState(prevState => ({
          continueInfiniteScroll: prevState.list.length + transparencyList.length < totalCount,
          list: clearList ? transparencyList : [...prevState.list, ...transparencyList],
          filter: {
            ...prevState.filter,
            locations: locations
          },
          isSubmitting: {
            ...prevState.isSubmitting,
            search: false,
            moreSearch: false
          },
          pageOffset: clearList ? 0 : prevState.pageOffset,
          firstSearch: false,
        }), () => {
          this.setState(prevState => ({
            pageOffset: prevState.continueInfiniteScroll ?
              prevState.pageOffset + parseInt(prevState.pageLimit) : prevState.pageOffset
          }))
        })
      })
      .catch(error => {
        console.log('Error while fetching transparency list', error);
        this.setState(prevState => ({
          isSubmitting: {
            ...prevState.isSubmitting,
            search: false,
            moreSearch: false
          }
        }))
      })
  }

  searchTransparency = (data) => {
    return new Promise((resolve, reject) => {
      transparencyService.searchTransparency(data)
        .then(response => {
          if (response.status === "success") {
            response.data.transparencyList.map(x => {
              if (x.provider?.practiceAddress) {
                x.lat = +x.provider.practiceAddress.lat;
                x.lng = +x.provider.practiceAddress.lng;
              }
              x.distance = x.provider?.practiceAddress ? vitafyUtil.getDistance(+x.provider.practiceAddress.lat, +x.provider.practiceAddress.lng, data.address.lat, data.address.lng) : null;
              x.seoOptimizedUrl = vitafyUtil.getSeoOptmizedUrl(x.provider?.displayName);
              return x;
            });
            resolve(response.data);
          }
        })
        .catch(error => {
          reject(error);
        })
    })
  }

  resetLocationSearchParams = () => {
    this.setState({
      searchLocation: '',
      searchZipCode: null,
      searchKeyword: '',
      searchCode: null,
      searchCity: null,
      searchState: null,
      searchPrimaryCode: null,
      searchLatitude: null,
      searchLongitude: null,
    })
  }

  handleChangeFilterRadius = (radius) => {
    this.setState(prevState => ({
      filter: {
        ...prevState.filter,
        radius: radius
      }
    }), () => {
      this.searchHandler({ clearList: true, filterWithRadius: true });
    })
  }

  handleChangeFilterLocation = (locationObj, selectedLocationOnly = false) => {
    let filterLocations = [...this.state.filter.locations];
    filterLocations = filterLocations.map((location) => {
      if (selectedLocationOnly) {
        return {
          ...location,
          included: location === locationObj ? true : false
        }
      }
      return {
        ...location,
        included: location === locationObj ? !location.included : location.included
      }
    })
    this.setState(prevState => ({
      filter: {
        ...prevState.filter,
        locations: filterLocations
      },
      pageOffset: 0,
    }), () => {
      this.searchHandler({ clearList: true, filterWithRadius: false });
    })
  }

  handleClearFilters = () => {
    let filterLocations = [...this.state.filter.locations];
    filterLocations = filterLocations.map(location => {
      return {
        ...location,
        included: true
      }
    })
    this.setState(prevState => ({
      filter: {
        ...prevState.filter,
        radius: parseInt(DEFAULT_RADIUS),
        locations: filterLocations
      },
      pageOffset: 0,
    }), () => {
      this.searchHandler({ clearList: true, filterWithRadius: false });
    })
  }

  handleShowResultsByChange = (value) => {
    this.setState({
      resultsByShortestDistance: value
    }, () => {
      this.searchHandler({ clearList: true, filterWithRadius: false });
    })
  }

  getCosmicContent = () => {
    this.setState({ loading: true });
    service
      .getDpcCosmicContent()
      .then((response) => {
        if (response.status === 'success') {
          const dpcSearch = response.data.find((item) => item.slug === 'dpc-search').metadata;
          if (dpcSearch) {
            this.setState(prevState => ({
              cosmicContent: {
                ...prevState.cosmicContent,
                ads: dpcSearch.ads
              }
            }))
          }
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        console.log('Error in get DPC Search cosmic content', error);
        this.setState({ loading: false });
      });
  }

  render() {

    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <Header />
        <BannerWithSearch
          title='Transparency'
          subtitle='Search the government mandated pricing data from hospitals'
          leftSearch={
            <ProcedureAutocompleteInput
              handleInputChange={this.handleInputChange}
              onSelectHandler={this.procedureAutocompleteOnSelectHandler}
              procedureSearchKeyword={this.state.procedureSearchKeyword}
              clearSelectedItem={() => { this.setState({ selectedProcedure: {} }) }}
            />}
          rightSearch={
            <LocationAutocompleteInput
              handleInputChange={this.handleInputChange}
              onSelectHandler={this.onSelectHandler}
              searchLocation={this.state.searchLocation}
            />
          }
          searchHandler={this.handleInitialSearch}
          isSubmitting={this.state.isSubmitting.search}
          includeDisclaimer={true}
          requestDemoClick={() => this.newRequestDemoModal.current.toggle()}
        />

        <SearchListing
          list={this.state.list}
          isLoading={this.state.isSubmitting.search}
          isMoreLoading={this.state.isSubmitting.moreSearch}
          searched={this.state.isSearched}
          searchParams={this.state.searchedParamsForListing}
          searchKeyword={this.state.searchKeyword}
          selectedProcedure={this.state.selectedProcedure}

          filterLocationList={this.state.filter.locations}
          filterRadius={this.state.filter?.radius}
          radiusList={this.state.filter?.radiusList}
          handleChangeFilterRadius={this.handleChangeFilterRadius}
          handleChangeFilterLocation={this.handleChangeFilterLocation}
          handleClearFilters={this.handleClearFilters}
          showBestMatchDistance={this.state.showBestMatchDistance}
          resultsByShortestDistance={this.state.resultsByShortestDistance}
          handleShowResultsByChange={this.handleShowResultsByChange}
          requestDemoClick={() => this.newRequestDemoModal.current.toggle()}
          ads={this.state.cosmicContent?.ads}
        />

        <RequestDemo
          formModal={this.newRequestDemoModal}
        />

      </>
    );
  }
}

const ProcedureAutocompleteInput = (props) => {
  return (
    <ProcedureAutocomplete
      placeholder="Enter CPT, DRG, Description"
      inputRequired={false}
      className="filled-input focus:outline-none"
      onSelectHandler={(e) =>
        props.onSelectHandler(e)
      }
      handleInputChange={(e) =>
        props.handleInputChange(e, 'searchKeyword', [
          'searchCode',
          'searchPrimaryCode',
        ])
      }
      displayNameValue={props.searchKeyword}
      clearSelectedItem={props.clearSelectedItem}
    />
  );
}

const LocationAutocompleteInput = (props) => {
  return (
    <LocationAutocomplete
      placeholder='Search by Location'
      inputRequired={false}
      iconType='location'
      onSelectHandler={(e) =>
        props.onSelectHandler(
          e,
          ['displayName', 'zip', 'st', 'city', 'lat', 'lng'],
          [
            'searchLocation',
            'searchZipCode',
            'searchState',
            'searchCity',
            'searchLatitude',
            'searchLongitude'
          ]
        )
      }
      handleInputChange={(e) =>
        props.handleInputChange(e, 'searchLocation', [
          'searchCity',
          'searchState',
          'searchZipCode',
        ])
      }
      displayNameValue={props.searchLocation}
    />
  );
}

export default TransparencySearch;