import React, { Component } from "react";
import routeAliasConstant from "../../constants/routeAliasConstant";

import * as service from "../../service";
import {
  Header,
  Footer,
  Loader,
  FirstContent,
  ValueProp,
  CallToAction,
  Benefit,
} from "../../common";
import ctaModalTitle from "../../constants/ctaModalTitle";
import { ReactTitle } from "react-meta-tags";
import commonConstants from "../../constants/commonConstant";

class CustomerPages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      customerType: "",
      customer: {},
      routeAlias: "",

      // firstContent
      pageTitle: "",
      pageDescription: "",
      pageImage: "",
      tagline: "",
      taglineVisibility: false,

      // Mission & Value Proposition
      missionVisibility: false,
      missionStatement: "",
      valuePropsTitle: "",
      valuePropsTitleVisibility: false,
      valueProps: [],

      // CallToAction
      ctaVisibility: false,
      ctaTitle: "",
      ctaDescription: "",
      ctaActionButtonText: "",
      ctaActionButtonLink: "",

      // Benefit
      benefits: [],

      // Page SEO
      seo: {},
    };
  }

  componentDidMount() {
    this.getInitialState();
  }

  getInitialState = () => {
    let routeAlias = this.props.location.pathname;
    this.setState({ routeAlias: routeAlias });
    if (routeAlias === routeAliasConstant.PROVIDER)
      this.setState({ customerType: "provider" }, () => {
        this.getContent();
      });
    else if (routeAlias === routeAliasConstant.CONSUMER)
      this.setState({ customerType: "consumer" }, () => {
        this.getContent();
      });
    else if (routeAlias === routeAliasConstant.EMPLOYER)
      this.setState({ customerType: "employer" }, () => {
        this.getContent();
      });
    else if (routeAlias === routeAliasConstant.VCARE)
      this.setState({ customerType: "vcare" }, () => {
        this.getContent();
      });
    else if (routeAlias === routeAliasConstant.ADVISOR)
      this.setState({ customerType: "advisor" }, () => {
        this.getContent();
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.pathname !== this.props.location.pathname)
      this.getInitialState();
  }

  getContent = () => {
    this.setState({ loading: true });

    service
      .getCustomerPageContent()
      .then((result) => {
        if (result.status === "success") {
          this.setState(
            {
              customer: result.data.find(
                (item) => item.slug === this.state.customerType
              ),
            },
            () => {
              this.setState({
                // firstContent
                pageTitle: this.state.customer.title,
                pageDescription: this.state.customer.content,
                pageImage: this.state.customer.metadata.page_image.url,
                bannerVideo: this.state.customer.metadata.youtube_video_link,
                bannerVideoVisibility: this.state.customer.metadata.show_video,
                tagline: this.state.customer.metadata.tagline,
                taglineVisibility:
                  this.state.customer.metadata.tagline_visibility,
                // Mission & Value Proposition
                missionVisibility:
                  this.state.customer.metadata.mission.visibility,
                missionStatement:
                  this.state.customer.metadata.mission.mission_statement,
                valuePropsTitle:
                  this.state.customer.metadata.mission.value_props_title,
                valuePropsTitleVisibility:
                  this.state.customer.metadata.mission
                    .value_props_title_visibility,
                valueProps: this.state.customer.metadata.mission.value_props,

                // CallToAction
                ctaVisibility:
                  this.state.customer.metadata.call_to_action.visibility,
                ctaTitle: this.state.customer.metadata.call_to_action.title,
                ctaDescription:
                  this.state.customer.metadata.call_to_action.description,
                ctaActionButtonText:
                  this.state.customer.metadata.call_to_action
                    .action_button_text,
                ctaActionButtonLink:
                  this.state.customer.metadata.call_to_action
                    .action_button_link,
                // Benefits
                benefits: this.state.customer.metadata.benefits,

                // Page SEO
                seo: this.state.customer.metadata.seo,

                loading: false,
              });
            }
          );
        }
      })
      .catch((error) => {
        console.log("Error in get homeContent", error);
        this.setState({ loading: false, error: true });
      });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }
    return (
      <div>
        <ReactTitle
          title={
            this.state.seo?.metadata?.page_title ||
            commonConstants.SEO.DEFAULT_PAGE_TITLE
          }
        />

        <Header />
        <FirstContent
          title={this.state.pageTitle}
          text={this.state.pageDescription}
          image={this.state.pageImage}
          bannerVideoLink={this.state.bannerVideo}
          bannerVideoVisibility={this.state.bannerVideoVisibility}
          tagline={this.state.tagline}
          taglineVisibility={this.state.taglineVisibility}
        />

        <div className="inner-divider"></div>

        {this.state.missionVisibility ? (
          <ValueProp
            title={this.state.missionStatement}
            valuePropsTitle={this.state.valuePropsTitle}
            valuePropsTitleVisibility={this.state.valuePropsTitleVisibility}
            values={this.state.valueProps}
            bgColor={false}
          />
        ) : null}

        {this.state.ctaVisibility ? (
          <CallToAction
            title={this.state.ctaTitle}
            description={this.state.ctaDescription}
            actionButtonText={this.state.ctaActionButtonText}
            actionButtonLink={this.state.ctaActionButtonLink}
            routeAlias={this.state.routeAlias}
            requestDemo={this.state.routeAlias === routeAliasConstant.VCARE}
            modalTitle={
              this.state.routeAlias === routeAliasConstant.VCARE
                ? ctaModalTitle.CONTACT_FOR_MORE_DETAILS
                : null
            }
          />
        ) : null}

        {this.state.benefits !== undefined ? (
          <>
            <div className="divider"></div>
            <Benefit benefits={this.state.benefits} />
            <div className="divider"></div>
          </>
        ) : null}

        <Footer bgWhite={!this.state.benefits} />
      </div>
    );
  }
}

export default CustomerPages;
