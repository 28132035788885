import React from 'react';
import { removeNonAlphaNumericChars } from '../util/escapeRegexCharsUtil';

export function getSuggestions(value, list = [], limit = null, includeBusinessName=false) {
  const escapedValue = removeNonAlphaNumericChars(value ? value.trim() : '');
  const regex = new RegExp(escapedValue, 'i');
  let filteredList;
  if(includeBusinessName) filteredList = list.filter(x => regex.test(x.displayName) || regex.test(x.businessName));
  else filteredList = list.filter(x => regex.test(x.displayName));

  filteredList = limit ? filteredList.slice(0, limit) : filteredList;
  return filteredList;
}

export function getSuggestionDisplayName(suggestion) {
  return suggestion.displayName;
}

export function renderSuggestion(suggestion) {
  return (
    <span>{suggestion.displayName}</span>
  );
}

export function shouldRenderSuggestions(value, minLength = 0) {
  return value.trim().length > minLength;
}



