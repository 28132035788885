import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCreditCard
} from '@fortawesome/free-solid-svg-icons';
import * as vitafyUtils from '../../../util/vitafyUtil';
import { Checkbox } from '../../../common';
class CardPayment extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      requiredInput: false,
      invalidInput: false,
      isFormSubmitted: false,
      token: '',
      expiry: '',
      hideIframe: false
    }
  }

  componentDidMount() {
    window.addEventListener("message", this.listen);
    this.props.clearErrorMessage();
    this.setState(prevState => ({
      ...prevState,
      token: this.props.tempPaymentData.token,
      expiry: this.props.tempPaymentData.expiry,
      hideIframe: this.props.tempPaymentData.token && this.props.tempPaymentData.expiry ? true : false
    }))
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.listen);
  }

  listen = (event) => {
    if (typeof (event.data) == 'string') {

      // 1004 -> Card Number Required
      // 1005 -> CVC Required
      // 1006 -> Expiry Required

      // 1001 -> Invalid Card Number
      // 1002 -> Invalid CVC
      // 1003 -> Invalid Expiry
      try {
        const response = JSON.parse(event.data);
        response.errorCode &&
          this.setState({
            requiredInput: (response.errorCode === '1004' || response.errorCode === '1005' || response.errorCode === '1006') ? true : false,
            invalidInput: (response.errorCode === '1001' || response.errorCode === '1002' || response.errorCode === '1003') ? true : false,
            token: '',
            expiry: '',
            isFormSubmitted: false
          }, () => {
            if (!this.state.requiredInput && !this.state.invalidInput && response && response.errorCode === '0') {
              this.setState({ token: response.token, expiry: response.expiry });
              this.props.changeTempPaymentData(response.token, response.expiry);
            }
          })
      } catch (e) {
        console.log('JSON Parse error', e)
      }
    }
  };

  handleIAgreeCardChange = (e) => {
    let paymentData = [
      {
        key: 'iAgreeCardPayment',
        value: e
      }
    ]
    this.props.changePaymentData(paymentData);
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ isFormSubmitted: true });
    this.props.clearErrorMessage();
    if (!this.state.requiredInput && !this.state.invalidInput && this.state.token !== '') {
      const formValues = {
        paymentType: 'Card',
        token: this.state.token,
        expiry: this.state.expiry
      }
      this.props.submit(4, formValues);
    }
  }

  getFormattedCardNumber = (token) => {
    return token.slice(1, 3) + '*********' + token.slice(-4);
  }

  getFormattedExpiryDate = (expiry) => {
    let month = expiry.slice(4);
    month = month && month.length === 1 ? '0' + month : month;
    let year = expiry.slice(2, 4);
    return month + '/' + year;
  }

  handleAddNewCard = () => {
    this.props.changeTempPaymentData('', '');
    this.setState({
      token: '',
      expiry: '',
      hideIframe: false
    })
  }

  render() {

    const parameters = "formatinput=true&cardinputmaxlength=19&cardnumbernumericonly=true&useexpiry=true&usecvv=true&orientation=custom&placeholder=Card Number&placeholdercvv=CVV&placeholdermonth=MM&placeholderyear=YYYY&invalidinputevent=true&enhancedresponse=true";
    const encodedStyles = "body %7B font-family: 'Heebo', sans-serif;%0A%7D%0Ainput %7B border: 1.5px solid %23d4d4d4; height: 40px; background-color: %23fafafa; font-size: 16px; text-indent: 16px; margin-top: 10px; color: %23333;%0A%7D input:focus %7B outline: none !important; border: 1.5px solid %2300a1be; box-shadow: 0 0 3px %23b0b0b0; background-color: %23ffffff;%0A%7D select %7B border: 1.5px solid %23d4d4d4; height: 44.5px; background-color: %23fafafa; font-size: 16px; margin-top: 10px; color: %23333; text-indent: 8px;%0A%7D select:focus %7B outline: none !important; border: 1.5px solid %2300a1be; box-shadow: 0 0 3px %23b0b0b0; background-color: %23ffffff;%0A%7D select:not(:-internal-list-box) %7B overflow: visible !important;%0A%7D %23cccardlabel,%0A%23ccexpirylabel,%0A%23cccvvlabel %7B display: none;%0A%7D %23ccnumfield %7B width: 100%25; display: block; margin-bottom: 0.4rem;%0A%7D %23ccexpirymonth %7B width: 4rem; margin-right: 1rem;%0A%7D %23ccexpiryyear %7B width: 5rem; pointer-events: none; margin-right: 1rem;%0A%7D";

    return (
      <form ref={this.props.cardPaymentRef} onSubmit={this.handleSubmit}>
        <div className="mt-5">
          <p className="body-text font-bold">Payment Summary</p>

          <div className={`w-full bg-white pl-2 pr-3 md:px-6 pt-2 pb-4 rounded-sm mt-5`}>
            <div className="flex justify-between items-center my-1 md:my-2 px-2">
              <p className="body-text">Total Amount</p>
              <h6 className="body-text text-xl md:text-2xl text-titleColor">
                {this.props.totalCost ?
                  <>
                    {vitafyUtils.currencyFormat(this.props.totalCost)}/month
                  </>
                  : ''}
              </h6>
            </div>
            <p className="body-text text-lg md:text-xl text-titleColor font-bold ml-2">
              Card Information
            </p>
            <div className="mt-1 md:mt-2">
              {this.state.hideIframe &&
                <div className='ml-2 mt-4 mb-10'>
                  <div className='flex justify-between items-center'>
                    <p className='body-text mb-3 font-semibold'>Use recently entered Card Information</p>
                    <div className='px-4 py-2 rounded-md hover:bg-orange-200 cursor-pointer transition-all duration-150'
                      onClick={this.handleAddNewCard}
                    >
                      <p className='text-secondary font-medium'>
                        <FontAwesomeIcon icon={faCreditCard} className='mr-2' />
                        Edit Card
                      </p>
                    </div>
                  </div>
                  <p className='body-text mb-1'>Card Number&nbsp; : <span className='text-titleColor'>
                    {this.getFormattedCardNumber(this.props.tempPaymentData.token)}</span></p>
                  <p className='body-text'>Expiration &nbsp; &nbsp; &nbsp; &nbsp;: <span className='text-titleColor'>
                    {this.getFormattedExpiryDate(this.props.tempPaymentData.expiry)}</span></p>
                </div>
              }
              <iframe
                style={{ display: this.state.hideIframe ? 'none' : 'block' }}
                title="iframe"
                id="tokenframe"
                name="tokenframe"
                frameBorder="0"
                scrolling="no"
                width='100%'
                height="140"
                src={`${this.props.paymentBaseUrl}/itoke/ajax-tokenizer.html?&${parameters}&css=${encodedStyles}`}
              />

              <div className="mt-1 mx-2 md:flex md:justify-between md:items-center">
                <Checkbox
                  checked={this.props.client?.payment?.iAgreeCardPayment}
                  setChecked={(e) => this.handleIAgreeCardChange(e)}
                  className="inline"
                >
                  <p className="font-heebo text-sm md:text-base text-titleColor mb-0 select-none inline-block">
                    I agree with{' '}
                    <a
                      href="https://vitafymed.com/terms-of-use"
                      target="_blank"
                      className="no-underline font-bold text-secondary focus:outline-none"
                    >
                      {' '}
                      Vitafy terms & policy
                    </a>
                  </p>
                </Checkbox>

                {this.props.cosmicContent && this.props.cosmicContent.metadata && this.props.cosmicContent.metadata.powered_by
                  && this.props.cosmicContent.metadata.powered_by.image &&
                  <div className="flex items-center mt-4 md:mt-0">
                    <p className="body-text text-sm mr-1 mt-1"> Powered by </p>
                    <a href={this.props.cosmicContent.metadata.powered_by.hyperlink} target='_blank' className='no-underline outline-none focus:outline-none' >
                      <img src={this.props.cosmicContent.metadata.powered_by.image.url} className='h-12' />
                    </a>
                  </div>
                }
              </div>

              <div className='min-h-2'>
                <p className='ml-2 mt-6 body-text text-sm text-red-500'>
                  {/* Please fill all the fields */}
                  {this.state.isFormSubmitted && this.state.requiredInput && 'Please fill all the fields'}
                  {this.state.isFormSubmitted && this.state.invalidInput && 'Please make sure that provided informations are correct'}
                  {this.props.errorMessage ? `Error: ${this.props.errorMessage}` : ''}
                </p>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }

}

export default CardPayment;