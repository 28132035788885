import React from "react";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";
import withSizes from "react-sizes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import LandingImg1 from "../../assets/images/landing_image_doctor.jpg";
import LandingImg2 from "../../assets/images/landing_image_medical_procedure.jpg";
import LandingImg3 from "../../assets/images/landing_image_healthcare.jpg";

const LandingPageSlider = (props) => {
  const history = useHistory();
  const { slides, isMobile } = props;

  const settings = {
    className: "landingPageSlider",
    dots: true,
    lazyLoad: true,
    arrows: false,
    fade: true,
    infinite: true,
    speed: 1750,
    autoplay: true,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => <ul style={{ marginBottom: "60px" }}> {dots} </ul>,
  };

  const handleLearnMore = (index) => {
    history.push("/marketplace");
    // if (index === 0) history.push('/direct-primary-care');
    // else if (index === 1) history.push('/marketplace');
    // else props.newRequestDemoModal.current.toggle();
  };

  const display =
    slides &&
    slides.map((item, index) => {
      // if(index === 0) item.image.url = LandingImg1;
      // else if(index === 1) item.image.url = LandingImg2;
      // else item.image.url = LandingImg3;

      return (
        <div key={index} className="focus:outline-none">
          <div
            className="landingImage block"
            style={{
              backgroundImage: `url(${
                isMobile ? item.mobile_image.url : item.image.url
              })`,
            }}
          >
            <div className="container mx-auto px-4 h-full flex items-center justify-center lg:justify-start">
              <div className="pt-10">
                <p className="text-titleColor font-heebo text-lg sm:text-2xl lg:text-3xl mb-2">
                  {item.subtitle}
                </p>
                <h1 className="text-titleColor font-heebo font-bold landing-title">
                  {item.title_first_half} <br />{" "}
                  <span className="text-extended mt-2">
                    {item.title_second_half}
                  </span>
                </h1>
                <button
                  className="big-button  shadow transition duration-500 ease-in-out  hover:shadow-lg focus:outline-none"
                  onClick={() => handleLearnMore(index)}
                >
                  Learn More{" "}
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    className="inline-block ml-2 "
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    });

  return (
    <div className="w-full overflow-hidden">
      <Slider {...settings}>{display}</Slider>
    </div>
  );
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 1024,
});

export default withSizes(mapSizesToProps)(LandingPageSlider);
