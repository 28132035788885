import React from 'react';
import NumberFormat from 'react-number-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const MaskedDateInput = React.forwardRef((props, ref) => {
  const { input, setInput, label, icon, error, className } = props;

  return (
    <div className={`mdp-input-main ${className ? className : ''}`}>
      <div className="mdp-input-box ">
        <NumberFormat
          getInputRef={ref}
          id="masked-date-input"
          format="##/##/####"
          mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']}
          value={input}
          placeholder="MM/DD/YYYY"
          style={{ textIndent: icon ? '2rem' : '' }}
          className="mdp-input bg-transparent text-titleColor font-heebo text-base"
          onValueChange={(value) => setInput(value.formattedValue)}
        />
        <label
          htmlFor="masked-date-input"
          className={`mdp-label ${icon ? 'ml-8' : null}`}
        >
          {label}
        </label>
        {icon && <FontAwesomeIcon icon={icon} className="mdp-icon" />}
        {error && (
          <FontAwesomeIcon
            icon={faExclamationCircle}
            className="text-red-500"
            style={{
              position: 'absolute',
              top: '10px',
              right: '0px',
              fontSize: '16px',
            }}
          />
        )}

        <div
          className={`${
            error ? 'mdp-input-error-underline' : 'mdp-input-underline'
          }`}
        />
      </div>
    </div>
  );
});

export default MaskedDateInput;
