import React, { useContext, useEffect, useState } from 'react';
import { useAcceptJs } from 'react-acceptjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';

import {
  PAYMENT_ENVIRONMENT,
  AUTHORIZE_DOT_NET_ENVIRONMENT,
} from '../../../../constants/payment';
import {
  MaskedCardInput,
  MaskedCardExpiryInput,
  MaskedCardCVCInput,
  FormErrorMessage,
} from '../../../../common';
import { store } from '../../../../store';
import {
  validateCardCVC,
  validateCardExpiry,
  validateCardNumber,
} from '../../../../util/vitafyUtil';

const { TEST } = PAYMENT_ENVIRONMENT;

const CardPayment = ({
  filledCardData,
  onChangeData,
  onAddNewCardClick,
  cardErrorMessage,
  errorMessage,
  clearError,
  isSubmitting: isSubmittingCheckout,
}) => {
  // Context Store
  const state = useContext(store);
  const { globalState } = state;
  const { merchantProfile } = globalState;
  const {
    paymentEnvironment,
    clientKey_live,
    clientKey_test,
    merchantId_live,
    merchantId_test,
  } = merchantProfile;

  const environment =
    paymentEnvironment === TEST
      ? AUTHORIZE_DOT_NET_ENVIRONMENT.TEST
      : AUTHORIZE_DOT_NET_ENVIRONMENT.LIVE;
  const authData = {
    clientKey: paymentEnvironment === TEST ? clientKey_test : clientKey_live,
    apiLoginID: paymentEnvironment === TEST ? merchantId_test : merchantId_live,
  };

  const { dispatchData, loading, error } = useAcceptJs({
    environment,
    authData,
  });

  const [cardData, setCardData] = useState({
    cardNumber: '',
    expiry: '',
    cardCode: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorRes, setErrorRes] = useState('');

  const handleChangeCardData = (name, data) => {
    setCardData((prevState) => ({ ...prevState, [name]: data }));
    setErrorRes('');
    clearError();
  };

  // Validate card data on input blur (cardNumber, expiry and CVC) and dispatch data to Authorize.Net server if validated.
  // Local validation is simply checking if the inputs are filled.
  const handleInputBlur = async () => {
    const { cardNumber, expiry, cardCode } = cardData;
    if (
      validateCardNumber(cardNumber) &&
      validateCardExpiry(expiry) &&
      validateCardCVC(cardCode)
    ) {
      const payload = {
        cardNumber,
        month: expiry.slice(0, 2),
        year: '20' + expiry.slice(-2), // 24 -> 2024
        cardCode,
      };

      try {
        setIsSubmitting(true);
        const response = await dispatchData({ cardData: payload });

        if (response?.messages?.resultCode === 'Ok') {
          onChangeData('card', {
            token: response?.opaqueData?.dataValue,
            tokenSource: response?.opaqueData?.dataDescriptor,
            cardNumber,
            expiry,
            cvc: cardCode,
          });
        }
      } catch (err) {
        setErrorRes(err?.messages?.message?.[0]?.text);
        console.log('err', err);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const getFormattedCardNumber = (token) => {
    if (!token) return '';
    return token.slice(0, 2) + '**********' + token.slice(-4);
  };

  const getFormattedExpiry = (expiry) => {
    if (!expiry) return '';
    return expiry.slice(0, 2) + '/' + expiry.slice(-2);
  };

  const handleAddNewCardInfo = () => {
    onAddNewCardClick();
    setErrorRes('');
    setCardData({
      cardNumber: '',
      expiry: '',
      cardCode: '',
    });
  };

  // Initialize local state with card information if present
  useEffect(() => {
    if (filledCardData?.token) {
      setCardData({
        cardNumber: filledCardData.cardNumber,
        expiry: filledCardData.expiry,
        cardCode: filledCardData.cvc,
      });
    }
  }, [filledCardData]);

  if (loading) {
    return <>Loading...</>;
  }

  if (error) {
    return <>Error loading Authorize.Net payment gateway</>;
  }

  const renderedFilledCardInfo = (
    <div className="mt-4">
      <div className="flex justify-between items-center">
        <p className="body-text mb-3 font-semibold">
          Use recently entered Card Information
        </p>
        <button
          className={`px-4 py-2 rounded-md hover:bg-orange-200 cursor-pointer transition-all duration-150 ${
            isSubmittingCheckout && 'btn-disabled'
          }`}
          onClick={handleAddNewCardInfo}
          type="button"
        >
          <p className="text-secondary font-medium">
            <FontAwesomeIcon icon={faCreditCard} className="mr-2" />
            Edit Card
          </p>
        </button>
      </div>
      <p className="body-text mb-1">
        Card Number&nbsp; :{' '}
        <span className="text-titleColor">
          {getFormattedCardNumber(filledCardData?.cardNumber)}
        </span>
      </p>
      <p className="body-text">
        Expiration &nbsp; &nbsp; &nbsp; &nbsp;:{' '}
        <span className="text-titleColor">
          {getFormattedExpiry(filledCardData?.expiry)}
        </span>
      </p>
    </div>
  );

  return (
    <div className="mb-10">
      {filledCardData?.token ? (
        renderedFilledCardInfo
      ) : (
        <div className="flex flex-wrap -mx-2">
          <div className="w-full md:w-3/5 px-2 mb-3 md:mb-0">
            <MaskedCardInput
              value={cardData.cardNumber}
              onChange={(data) =>
                handleChangeCardData('cardNumber', data.value)
              }
              onBlur={handleInputBlur}
              isDisabled={isSubmitting}
            />
          </div>
          <div className="w-full md:w-2/5 px-2">
            <div className="flex -mx-2">
              <div className="w-1/2 px-2">
                <MaskedCardExpiryInput
                  value={cardData.expiry}
                  onChange={(data) =>
                    handleChangeCardData('expiry', data.value)
                  }
                  onBlur={handleInputBlur}
                  isDisabled={isSubmitting}
                />
              </div>
              <div className="w-1/2 px-2">
                <MaskedCardCVCInput
                  value={cardData.cardCode}
                  onChange={(data) =>
                    handleChangeCardData('cardCode', data.value)
                  }
                  onBlur={handleInputBlur}
                  isDisabled={isSubmitting}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {isSubmitting && <p className="text-sm text-bodyColor">Please wait...</p>}
      {!!errorRes && <FormErrorMessage type="error" message={errorRes} />}
      {!!cardErrorMessage && (
        <FormErrorMessage type="warning" message={cardErrorMessage} />
      )}
      {errorMessage ? (
        <FormErrorMessage type="error" message={`Error: ${errorMessage}`} />
      ) : null}
    </div>
  );
};

export default CardPayment;
