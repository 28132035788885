import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faGlobe,
  faMobileAlt,
  faEnvelope,
  faClipboardCheck,
} from "@fortawesome/free-solid-svg-icons";
import Map from "./Map";
import Services from "./Services";
import Pricing from "./Pricing";
import AssociatedPhysicians from "./AssociatedPhysicians";
import OverlayCta from "./OverlayCta";
import avatarPlaceholderSquare from "../../assets/images/avatar-placeholder-square.png";
import * as vitafyUtils from "../../util/vitafyUtil";
import { prependHttp } from "../../util/miscellaneousUtil";
import ctaModalTitle from "../../constants/ctaModalTitle";

const Info = (props) => {
  const { detail, openRequestDemoModal } = props;
  return (
    <>
      <div className="w-full pt-24 md:pt-32 pb-12 md:pb-12 bg-primary">
        {/* NAME AND DESIGNATION STARTS HERE */}
        <div className="container mx-auto px-4">
          <h1 className="font-heebo font-bold text-white uppercase text-3xl lg:text-4xl xl:text-4.5xl text-center md:text-left md:w-1/2 leading-tight">
            {detail.displayName}
          </h1>
          {detail.primaryTaxonomyDescription && (
            <>
              <h6 className="first-content-heading-center md:hidden text-base font-normal lg:text-2xl mt-0 pt-0 block text-center mx-auto">
                {vitafyUtils.capitalizeFirstLetter(
                  detail.primaryTaxonomyDescription
                )}
              </h6>
              <h6 className="firstContentHeading hidden md:block text-base font-normal lg:text-2xl mt-0 pt-0">
                {vitafyUtils.capitalizeFirstLetter(
                  detail.primaryTaxonomyDescription
                )}
              </h6>
            </>
          )}
        </div>
        {/* NAME AND DESIGNATION ENDS HERE */}
      </div>

      {/* Logo visible only on mobile  */}
      <div className="flex w-full justify-center md:hidden">
        <div className="inline-block bg-white rounded-t-lg shadow mt-3 p-6">
          <div className="w-40 h-40 relative mx-auto">
            <img
              src={detail.imageUrl ? detail.imageUrl : avatarPlaceholderSquare}
              className="absolute w-full h-full object-cover object-center rounded-t-lg"
              alt="profile-img"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = `${avatarPlaceholderSquare}`;
              }}
            />
          </div>
        </div>
      </div>
      {/* Logo visible only on mobile upto here */}

      <div className="container mx-auto py-6 lg:pt-8 lg:pb-2 flex px-4">
        {/* Left Column that expands to full width on smaller screens */}
        <div className="flex-grow md:pr-12">
          {/* Location, Phone, Email and Website */}
          <div className="flex flex-wrap justify-center md:justify-start items-center">
            <div className="flex items-center mr-6 md:mr-10 mb-3 lg:mb-0">
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                className="text-lg md:text-xl text-primary block"
              />
              <div className="no-underline focus:outline-none">
                <h6 className="ml-3 text-base font-normal md:font-bold">
                  {detail.practiceAddress ? (
                    <>
                      {detail.practiceAddress.city},{" "}
                      {detail.practiceAddress.state}
                    </>
                  ) : (
                    "N/A"
                  )}
                </h6>
              </div>
            </div>
            <div className="flex">
              <div className="flex items-center mr-8 md:mr-10">
                <FontAwesomeIcon
                  icon={faMobileAlt}
                  className="text-lg md:text-xl text-primary block"
                />
                <a
                  href={`tel:${vitafyUtils.unmaskPhone(detail.phone)}`}
                  className="no-underline focus:outline-none"
                >
                  <h6 className="ml-3 text-base">
                    <span className="hidden lg:block">
                      {detail.phone
                        ? vitafyUtils.getPhoneDisplayText(detail.phone, detail?.countryCode)
                        : "N/A"}
                    </span>
                    <span className="block lg:hidden font-normal md:font-bold">
                      {detail.phone ? "Call" : "N/A"}
                    </span>
                  </h6>
                </a>
              </div>
              <a
                href={`mailto:${detail.email}`}
                className="block focus:outline-none no-underline mr-8 md:mr-10"
              >
                <div className="flex items-center">
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className="text-lg md:text-xl text-primary block"
                  />
                  <h6 className="ml-3 text-base font-normal md:font-bold">
                    {detail.email ? "Email" : "N/A"}
                  </h6>
                </div>
              </a>
              <div className="flex items-center">
                <FontAwesomeIcon
                  icon={faGlobe}
                  className="text-lg md:text-xl text-primary block"
                />
                {detail.website ? (
                  <a
                    href={prependHttp(detail.website)}
                    target="_blank"
                    className="no-underline focus:outline-none"
                  >
                    <h6 className="ml-3 text-base font-normal md:font-bold">
                      Website
                    </h6>
                  </a>
                ) : (
                  <h6 className="ml-3 text-base font-normal md:font-bold">
                    N/A
                  </h6>
                )}
              </div>
            </div>
          </div>
          {/* Location, Phone, Email and Website up to here*/}
          <hr className="mt-5 mb-4" />

          <p className="body-text  text-center md:text-left">
            {detail.biography}
          </p>

          <button
            className={`mid-button w-full rounded-md mt-5 md:hidden`}
            onClick={openRequestDemoModal}
          >
            <div className="flex justify-center items-center">
              <FontAwesomeIcon
                icon={faClipboardCheck}
                className="text-white mr-3"
              />
              Request Appointment
            </div>
          </button>

          <div className="h-10"></div>

          <Services services={detail.services} />

          <div className="h-10"></div>

          <Pricing pricings={detail.pricings} />

          <div className="h-10"></div>

          <div className="md:hidden">
            <h4 className="text-lg mb-5">Location </h4>
            <Map
              lat={detail.latlng?.lat ? parseFloat(detail.latlng.lat) : null}
              lng={detail.latlng?.lon ? parseFloat(detail.latlng.lon) : null}
            />
            <p className="body-text text-sm text-titleColor mb-2 mt-4">
              {vitafyUtils.getAddressDisplayName(detail.practiceAddress, true)}
            </p>
            <div className="h-10"></div>
          </div>

          <AssociatedPhysicians physicians={detail.associatedPhysicans} />

          <div className="md:hidden mt-12">
            <OverlayCta
              openRequestDemoModal={() =>
                openRequestDemoModal(ctaModalTitle.CONTACT_US)
              }
            />
          </div>
        </div>
        {/* Left Column that expands to full width on smaller screens up to here*/}

        {/* Right Column visible on desktop only */}
        <div className="md:w-68 lg:w-75 xl:w-85 pl-6 flex-shrink-0 -mt-16 sm:-mt-20 md:-mt-64  hidden md:block">
          <div className="w-full bg-white shadow-md rounded-lg mt-6 p-8 lg:p-10 xl:p-12">
            <div className="w-full pb-full rounded-t-lg relative">
              <img
                src={
                  detail.imageUrl ? detail.imageUrl : avatarPlaceholderSquare
                }
                className="absolute w-full h-full object-cover object-center rounded-t-lg"
                alt="profile-img"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = `${avatarPlaceholderSquare}`;
                }}
              />
            </div>
          </div>

          <div className="h-6"></div>

          <button
            className={`mid-button w-full rounded-md`}
            onClick={() =>
              openRequestDemoModal(ctaModalTitle.REQUEST_APPOINTMENT)
            }
          >
            <div className="flex justify-center items-center">
              <FontAwesomeIcon
                icon={faClipboardCheck}
                className="text-white mr-3"
              />
              Request Appointment
            </div>
          </button>

          <div className="h-6"></div>

          <div className="shadow-md rounded-b-sm">
            <Map
              lat={detail.latlng?.lat ? parseFloat(detail.latlng.lat) : null}
              lng={detail.latlng?.lon ? parseFloat(detail.latlng.lon) : null}
            />
            <div className="py-4 px-4 flex items-start">
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                className="text-primary mr-2 mt-1"
              />
              <p className="body-text text-sm text-titleColor mb-2">
                {vitafyUtils.getAddressDisplayName(
                  detail.practiceAddress,
                  true
                )}
              </p>
            </div>
          </div>
          <div className="h-6"></div>
          <OverlayCta
            openRequestDemoModal={() =>
              openRequestDemoModal(ctaModalTitle.CONTACT_US)
            }
          />
        </div>
        {/* Right Column visible on desktop only up to here */}
      </div>
    </>
  );
};

export default Info;
