import React, { useContext } from 'react';
import Slider from 'react-slick';
import ReactHtmlParser from 'react-html-parser';
import Quote from '../assets/images/quote.png';
import Partner from './Partner';
import PlaceholderImage from '../assets/images/placeholder-avatar.png';
import { store } from '../store';

const ClientStory = ({
  clientStories = [],
  partners = [],
  showPartners = false,
  bgColor,
  comingFromClientPages,
  title = 'Client Story',
}) => {
  // Context Store
  const state = useContext(store);
  const { globalState } = state;
  const { langDict } = globalState;

  if (!langDict) {
    return <>...</>;
  }

  const settings = {
    className: 'clientStorySlider',
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => <ul style={{ marginBottom: '40px' }}> {dots} </ul>,
  };

  const displayClientStories = clientStories?.map((item, index) => {
    const authorImage =
      (comingFromClientPages ? item.imageUrl : item.metadata?.image?.url) ||
      PlaceholderImage;
    const authorName = comingFromClientPages ? item.authorName : item.title;
    const authorDesignation = comingFromClientPages
      ? item.designation
      : item.metadata?.designation;
    const testimonial = comingFromClientPages
      ? langDict[item.clientStory]
      : item.content;

    return (
      <div key={index} className="focus:outline-none">
        <div className="w-full flex flex-wrap lg:-mx-1">
          <div className="w-full lg:w-1/4 lg:px-1">
            {/* This image is only visible in larger screen */}
            <div className="hidden lg:block">
              <img
                className="w-32 rounded-full block mx-auto"
                src={authorImage}
                alt="author"
              />
              <h4 className="text-center mb-0 mt-4 pb-0">{authorName}</h4>
              <p className="text-center">{authorDesignation}</p>
            </div>
          </div>
          <div className="w-full lg:w-3/4 lg:px-1 lg:mt-3">
            <div className="flex flex-shrink lg:-mx-4">
              {/* This quote image is only visible in larger screen */}
              <div className="w-1/5 lg:px-4 hidden lg:block">
                <img src={Quote} className="w-16" alt="quote-icon" />
              </div>
              <div className="lg:px-4">
                {/* This quote image is only visible in smaller screen */}
                <div className="block lg:hidden">
                  <img
                    src={Quote}
                    className="w-8 mx-auto mb-4"
                    alt="quote-icon"
                  />
                </div>
                <h6 className="block font-normal text-base lg:text-xl lg:mt-4 text-center lg:text-left">
                  {ReactHtmlParser(testimonial)}
                </h6>
                {/* This image is only visible in smaller screen */}
                <div className="flex justify-center mt-8 lg:hidden">
                  {/* <img
                      className='w-12 rounded-full block ml-6 mr-6'
                      src={
                        item.metadata.image.url
                          ? item.metadata.image.url
                          : 'https://www.w3schools.com/howto/img_avatar.png'
                      }
                      alt='image'
                    /> */}
                  <div className="w-auto">
                    <h6 className="pb-0 mb-0 text-md text-center lg:text-left">
                      {authorName}
                    </h6>
                    <p className="pt-0 mt-0 text-xs text-center lg:text-left">
                      {authorDesignation}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className={`${bgColor ? 'bg-bgColor' : 'white'}`}>
      <div className="container mx-auto px-4">
        <div className={`${bgColor ? 'divider' : null}`}></div>

        <h3 className="heading text-2xl md:text-3xl mb-3 md:mb-8">{title}</h3>

        <Slider {...settings}>{displayClientStories}</Slider>

        {showPartners && <Partner partners={partners} />}

        <div className="divider"></div>
      </div>
    </div>
  );
};

export default ClientStory;
