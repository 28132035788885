import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { store } from '../../../store';
import {
  selectBenefitStartDate,
  selectDependents,
  selectInformationTab,
  selectLoadingState,
  selectNoOfEmployee,
  selectPayment,
  selectSelectedOfferings,
  selectStep,
  setInformationTabValues,
  setIsQuestionaireEnabled,
  setLoadingState,
  setOfferings,
  setPayment,
  setSelectedOfferings,
  setStep,
} from '../../../stores/membership';

import * as clientService from '../../../service/clientService';
import * as tenantService from '../../../service/tenantService';
import { showToast } from '../../../service/toasterService';

import commonConstants from '../../../constants/commonConstant';
import { LOADING_STATE } from '../../../constants/misc';

import { useMemo } from 'react';
import { ButtonLoader } from '../../../common';
import {
  setQuestionnaireCode,
  setRespondent,
} from '../../../stores/questionaire';
import { getTextFromLangDict } from '../../../util';
import { momentIsBeforeNMonth } from '../../../util/momentUtil';
import { checkEnrollmentTenantSetting } from '../../../util/tenantSettingUtil';
import {
  changeMembershipStep,
  formatEnrollmentSavePayload,
  formatPaymentInformationSavePayload,
} from '../../../util/vitafyUtil';
import { MultiStepFormWrapper, StepIndicator } from '../common';
import StepSelection from './StepSelection';

const {
  MEMBERSHIP_REGISTRATION_TYPES,
  OFFERING_TYPES,
  OFFERING_VISIBILITY,
  OFFERING_TYPE_PRIMARY,
  OFFERING_TYPE_DEPENDENT,
  BILLING_TYPE,
  MEMBERSHIP_ENROLLMENT_STEPS,
  TENANT_SETTINGS_CODE,
} = commonConstants;
const { INFORMATION, QUESTIONAIRE, MEMBERSHIP, CHECKOUT, CONFIRMATION } =
  MEMBERSHIP_ENROLLMENT_STEPS;

const Membership = ({ whiteBg, encloseInContainer }) => {
  const dispatch = useDispatch();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // One can enroll to a private offering through a special link (can be generated from the portal). The url for the link
  // must contain type ('Individual' or 'Company') and tenantOfferingId for the private offering.
  // In such case, the enrollment type switch is disabled and the offering cannot be switch either.
  const enrollmentTypeFromParams = queryParams.get('type');
  const tenantOfferingIdFromParams = queryParams.get('tenantOfferingId');

  // Redux Store
  const informationTab = useSelector(selectInformationTab);
  const data = { ...informationTab };
  const noOfEmployee = useSelector(selectNoOfEmployee);
  const dependents = useSelector(selectDependents);
  const benefitStartDate = useSelector(selectBenefitStartDate);
  const step = useSelector(selectStep);
  const payment = useSelector(selectPayment);
  const selectedOfferings = useSelector(selectSelectedOfferings);
  const loadingState = useSelector(selectLoadingState);

  // Context Store
  const state = useContext(store);
  const { globalState } = state;
  const {
    tenantId,
    merchantProfile,
    primaryLocation,
    langDict,
    groupCode,
    tenantCode,
    tenantSettings,
  } = globalState;

  // Refs
  const individualInfoFormRef = useRef();
  const companyInfoFormRef = useRef();

  const isIndividual =
    data.type === MEMBERSHIP_REGISTRATION_TYPES.individual.value;
  const isCompany = data.type === MEMBERSHIP_REGISTRATION_TYPES.company.value;

  // Set up Individual or Company enrollment type based on the query param
  useEffect(() => {
    const INDIVIDUAL_TYPE = MEMBERSHIP_REGISTRATION_TYPES.individual.value;
    const COMPANY_TYPE = MEMBERSHIP_REGISTRATION_TYPES.company.value;

    if (
      enrollmentTypeFromParams?.toLowerCase() === INDIVIDUAL_TYPE.toLowerCase()
    ) {
      dispatch(setInformationTabValues({ type: INDIVIDUAL_TYPE }));
    } else if (
      enrollmentTypeFromParams?.toLowerCase() === COMPANY_TYPE.toLowerCase()
    ) {
      dispatch(setInformationTabValues({ type: COMPANY_TYPE }));
    }
  }, [enrollmentTypeFromParams, dispatch]);

  const isQuestionaireEnabled = useMemo(() => {
    return checkEnrollmentTenantSetting(
      TENANT_SETTINGS_CODE.MEMBERSHIP_QUALIFICATION_QUESTIONAIRE,
      {
        tenantSettings,
      }
    );
  }, [tenantSettings]);

  // Set `isQuestionaireEnabled` in the store
  useEffect(() => {
    dispatch(setIsQuestionaireEnabled(isQuestionaireEnabled));
  }, [isQuestionaireEnabled, dispatch]);

  const handlePrivateOfferingFetch = async () => {
    const queryParams = {
      limit: 999,
      offset: 0,
      type: OFFERING_TYPES.MEMBERSHIP,
      clientId: data.clientId,
      clientEnrollmentId: data.clientEnrollmentId,
      tenantOfferingId: tenantOfferingIdFromParams,
    };

    try {
      dispatch(setLoadingState(LOADING_STATE.FETCH_MEMBERSHIP_OFFERINGS));
      const response = await tenantService.fetchMembershipOfferings(
        tenantId,
        queryParams
      );

      let recommendedOfferings = response.data?.rows
        ?.map((item) => ({
          ...item,
          offeringType: OFFERING_TYPE_PRIMARY,
          isRecommended: true, // Add isRecommended: true for private offering enrollment to comply with the implementation
        }))
        ?.filter((item) => !!item.isRecommended);

      dispatch(setOfferings(response.data?.rows));
      dispatch(setSelectedOfferings(recommendedOfferings));
      dispatch(
        setStep(
          changeMembershipStep(step, { increment: true, isQuestionaireEnabled })
        )
      );
    } catch (err) {
      showToast('error', err.response.data.message);
    } finally {
      dispatch(setLoadingState(''));
    }
  };

  const handleOfferingFetchForGroup = async () => {
    const queryParams = {
      recommended: true,
      limit: 999,
      offset: 0,
      visibility: OFFERING_VISIBILITY.PUBLIC,
      type: OFFERING_TYPES.MEMBERSHIP,
      clientId: data.clientId,
      clientEnrollmentId: data.clientEnrollmentId,
      numberOfEmployee: parseInt(noOfEmployee),
    };

    try {
      dispatch(setLoadingState(LOADING_STATE.FETCH_MEMBERSHIP_OFFERINGS));
      const response = await tenantService.fetchMembershipOfferings(
        tenantId,
        queryParams
      );

      const offeringsExist = response.data?.rows?.length > 0;
      if (!offeringsExist) {
        showToast(
          'error',
          'Sorry, We do not have membership plans for company. Please contact admin.'
        );
        return;
      }

      let recommendedOfferings = response.data?.rows
        ?.map((item) => ({
          ...item,
          offeringType: OFFERING_TYPE_PRIMARY,
        }))
        ?.filter((item) => !!item.isRecommended);

      dispatch(setOfferings(response.data?.rows));
      dispatch(setSelectedOfferings(recommendedOfferings));
      dispatch(
        setStep(
          changeMembershipStep(step, { increment: true, isQuestionaireEnabled })
        )
      );
    } catch (err) {
      showToast('error', err.response.data.message);
    } finally {
      dispatch(setLoadingState(''));
    }
  };

  const handleOfferingFetchForPrimaryIndividual = async () => {
    const queryParams = {
      recommended: true,
      limit: 999,
      offset: 0,
      visibility: OFFERING_VISIBILITY.PUBLIC,
      type: OFFERING_TYPES.MEMBERSHIP,
      clientId: data.clientId,
      clientEnrollmentId: data.clientEnrollmentId,
      numberOfDependent: dependents?.length,
    };

    if (dependents?.length === 1) {
      queryParams.dependentType = dependents[0].relationship;
    } else if (dependents?.length > 1) {
      queryParams.dependentType = 'Others';
    }

    try {
      dispatch(setLoadingState(LOADING_STATE.FETCH_MEMBERSHIP_OFFERINGS));
      const response = await tenantService.fetchMembershipOfferings(
        tenantId,
        queryParams
      );

      const offeringsExist = response.data?.rows?.length > 0;
      if (!offeringsExist) {
        showToast(
          'error',
          'Sorry, We do not have membership plans for individuals. Please contact admin.'
        );
        return;
      }

      let recommendedOfferings = response.data?.rows
        ?.map((item) => ({
          ...item,
          offeringType: OFFERING_TYPE_PRIMARY,
        }))
        ?.filter((item) => !!item.isRecommended);

      dispatch(setOfferings(response.data?.rows));
      dispatch(setSelectedOfferings(recommendedOfferings));

      const individualTypeOfferingForPrimary =
        recommendedOfferings[0]?.billingType === BILLING_TYPE.INDIVIDUAL;

      return {
        individualTypeOfferingForPrimary,
        recommendedOfferings,
      };
    } catch (err) {
      showToast('error', err.response.data.message);
    }
  };

  const handleOfferingFetchForDependents = async ({
    prevSelectedOfferings,
  }) => {
    const queryParams = {
      recommended: true,
      limit: 999,
      offset: 0,
      visibility: OFFERING_VISIBILITY.PUBLIC,
      type: OFFERING_TYPES.MEMBERSHIP,
    };

    const requests = dependents?.map((dependent) => {
      return tenantService
        .fetchMembershipOfferings(tenantId, {
          ...queryParams,
          clientId: dependent.clientId,
          clientEnrollmentId: dependent.clientEnrollmentId,
          dependentType: dependent.relationship,
        })
        .then((response) => {
          let selectedOffering = response.data?.rows?.find(
            (item) => !!item.isRecommended
          );

          return {
            ...selectedOffering,
            offeringType: OFFERING_TYPE_DEPENDENT,
            dependent: {
              clientId: dependent.clientId,
              clientEnrollmentId: dependent.clientEnrollmentId,
              firstName: dependent.firstName,
              middleName: dependent.middleName,
              lastName: dependent.lastName,
            },
          };
        });
    });

    try {
      dispatch(setLoadingState(LOADING_STATE.FETCH_MEMBERSHIP_OFFERINGS));
      const offeringsForDependents = await Promise.all(requests);
      dispatch(
        setSelectedOfferings([
          ...prevSelectedOfferings,
          ...offeringsForDependents,
        ])
      );
      dispatch(
        setStep(
          changeMembershipStep(step, { increment: true, isQuestionaireEnabled })
        )
      );
    } catch (err) {
      showToast('error', 'Error fetching offerings for dependents');
    } finally {
      dispatch(setLoadingState(''));
    }
  };

  /**
   * Here's how the membership offering(s) is chosen.
   *
   * 1. For private offering (through special link) -> fetch offering by tenantOfferingId for the primary client
   * 2. For group -> Fetch offering (with number of members) for the primary client
   * 3. For primary individual client, fetch the offering based on clientId and check the recommended offering.
   *    3.1. If recommended offering is of type 'Family' -> skip the offering fetch for dependent (Family billing type covers for all dependents as well)
   *    3.2. If recommended offering is of type 'Individual' -> fetch offerings for each dependents in loop with their own clientId
   */
  const handleMembershipStepSubmit = async () => {
    if (!isIndividual && !isCompany) {
      return;
    }

    if (isCompany) {
      if (!noOfEmployee) {
        showToast('warning', 'Please enter the estimated number of employees');
        return;
      }

      if (parseInt(noOfEmployee) < 0) {
        showToast(
          'warning',
          'The estimated number of employees should be greater than 0'
        );
        return;
      }
    }

    if (!benefitStartDate) {
      showToast('warning', 'Please enter the benefit start date');
      return;
    }

    if (momentIsBeforeNMonth(benefitStartDate, 1)) {
      showToast(
        'warning',
        'Benefit start date should not be earlier than one month.'
      );
      return;
    }

    const isPrivateOfferingEnrollment = !!tenantOfferingIdFromParams;

    if (isPrivateOfferingEnrollment) {
      handlePrivateOfferingFetch();
      return;
    }

    if (isCompany) {
      handleOfferingFetchForGroup();
      return;
    }

    // Need to return `recommendedOfferings` from the `handleOfferingFetchForPrimaryIndividual()` for `handleOfferingFetchForDependents()` to use
    // Can't use `selectedOfferings` even though we have set it inside `handleOfferingFetchForPrimaryIndividual()` because it'll give the previous value
    // (not the latest value, populated value).
    // If you print `selectedOfferings` here, you'll get [].
    // For explanation, visit this link.
    // https://stackoverflow.com/questions/73034587/redux-state-showing-previous-or-default-value-while-submitting-function-called
    const { individualTypeOfferingForPrimary, recommendedOfferings } =
      await handleOfferingFetchForPrimaryIndividual();

    if (!individualTypeOfferingForPrimary || !dependents?.length) {
      dispatch(
        setStep(
          changeMembershipStep(step, { increment: true, isQuestionaireEnabled })
        )
      );
      dispatch(setLoadingState(''));
      return;
    }

    // for dependents of primary individual (not for dependents of primary member of a group)
    handleOfferingFetchForDependents({
      prevSelectedOfferings: recommendedOfferings,
    });
  };

  const checkIsValidatedForCheckout = () => {
    const { paymentMethod, tmpPaymentData } = payment;
    if (!paymentMethod) {
      showToast('warning', 'Please choose your payment method');
      return false;
    }

    if (
      paymentMethod === 'Card' &&
      !(tmpPaymentData.card.token && tmpPaymentData.card.expiry)
    ) {
      dispatch(
        setPayment({
          cardErrorMessage: 'Please enter card information correctly.',
        })
      );
      return false;
    }

    if (paymentMethod === 'Bank' && !tmpPaymentData.bank.token) {
      dispatch(
        setPayment({
          bankErrorMessage: 'Please enter bank information correctly.',
        })
      );
      return false;
    }

    return true;
  };

  const handleCheckout = async () => {
    try {
      dispatch(setLoadingState(LOADING_STATE.CHECKOUT));

      const isFamilyBillingType =
        selectedOfferings?.[0]?.billingType === BILLING_TYPE.FAMILY;
      let _selectedOfferings = null;
      if (isFamilyBillingType && dependents?.length) {
        // if offering type is family all dependent should have same offering type enrolled
        _selectedOfferings = [...selectedOfferings];
        dependents.forEach((dependent) => {
          _selectedOfferings.push({
            ..._selectedOfferings[0],
            offeringType: OFFERING_TYPE_DEPENDENT,
            dependent: {
              clientId: dependent.clientId,
              clientEnrollmentId: dependent.clientEnrollmentId,
              firstName: dependent.firstName,
              middleName: dependent.middleName,
              lastName: dependent.lastName,
            },
          });
        });
      }

      const enrollmentSavePayload = formatEnrollmentSavePayload(
        _selectedOfferings?.length ? _selectedOfferings : selectedOfferings,
        {
          primaryIndividual: informationTab,
          benefitStartDate,
          tenantId,
          tenantGroupCode: groupCode,
        }
      );
      const enrollmentSaveResponse =
        await clientService.bulkSaveClientEnrollments(
          informationTab.clientId,
          enrollmentSavePayload
        );
      const clientEnrollmentId = enrollmentSaveResponse.data?.find(
        (item) => item.clientId === informationTab.clientId
      )?.clientEnrollmentId;

      const paymentInformationSavePayload = formatPaymentInformationSavePayload(
        {
          primaryIndividual: informationTab,
          payment,
          clientEnrollmentId,
          offerings: selectedOfferings,
          merchantProfile,
          tenantId,
        }
      );

      await clientService.savePaymentInformation(
        informationTab.clientId,
        paymentInformationSavePayload
      );

      dispatch(
        setStep(
          changeMembershipStep(step, { increment: true, isQuestionaireEnabled })
        )
      );
    } catch (err) {
      console.log('Error while checkout', err);

      dispatch(
        setPayment({
          errorMessage: err.response?.data?.message || 'Error during checkout',
        })
      );
    } finally {
      dispatch(setLoadingState(''));
    }
  };

  const onNextClick = () => {
    if (step === INFORMATION && !data.type) {
      showToast('warning', 'Please select Membership Type');
      return;
    }

    // Trigger respective forms' submit events
    switch (step) {
      case INFORMATION:
        if (isIndividual) {
          individualInfoFormRef.current.dispatchEvent(
            new Event('submit', { cancelable: true })
          );
        } else {
          companyInfoFormRef.current.dispatchEvent(
            new Event('submit', { cancelable: true })
          );
        }
        break;

      case MEMBERSHIP:
        handleMembershipStepSubmit();
        break;

      case CHECKOUT:
        const isValidated = checkIsValidatedForCheckout();
        isValidated && handleCheckout();
        break;

      default:
        console.log('Registration step out of range');
    }
  };

  const onBackClick = () => {
    dispatch(
      setStep(
        changeMembershipStep(step, { increment: false, isQuestionaireEnabled })
      )
    );
  };

  const saveDemographicInformation = async (payload) => {
    try {
      dispatch(setLoadingState(LOADING_STATE.DEMOGRAPHIC_INFO_SAVE));
      const response = await clientService.addClient(payload);
      dispatch(
        setInformationTabValues({
          clientId: response.data.clientId,
          clientEnrollmentId: response.data.clientEnrollmentId,
        })
      );

      if (isQuestionaireEnabled) {
        dispatch(
          setRespondent({
            ...payload,
            clientId: response.data.clientId,
            tenantMetadata: primaryLocation.metaData,
          })
        );
        dispatch(
          setQuestionnaireCode(primaryLocation.metaData?.questionnaireCode)
        );
      }

      dispatch(
        setStep(
          changeMembershipStep(step, { increment: true, isQuestionaireEnabled })
        )
      );
    } catch (err) {
      showToast('error', err.response?.data?.message);
    } finally {
      dispatch(setLoadingState(''));
    }
  };

  const updateDemographicInformation = async (payload) => {
    try {
      dispatch(setLoadingState(LOADING_STATE.DEMOGRAPHIC_INFO_SAVE));
      await clientService.editClient(payload.clientId, payload);

      if (isQuestionaireEnabled) {
        dispatch(
          setRespondent({
            ...payload,
            tenantMetadata: primaryLocation.metaData,
          })
        );
      }

      dispatch(
        setStep(
          changeMembershipStep(step, { increment: true, isQuestionaireEnabled })
        )
      );
    } catch (err) {
      showToast('error', err.response?.data?.message);
    } finally {
      dispatch(setLoadingState(''));
    }
  };

  const onSaveDemographicInformation = (payload) => {
    if (payload.clientId) {
      updateDemographicInformation(payload);
    } else {
      saveDemographicInformation(payload);
    }
  };

  const handleQuestionnaireSubmitSuccess = () => {
    dispatch(
      setStep(
        changeMembershipStep(step, { increment: true, isQuestionaireEnabled })
      )
    );
  };

  const isSubmitting = [
    LOADING_STATE.DEMOGRAPHIC_INFO_SAVE,
    LOADING_STATE.FETCH_MEMBERSHIP_OFFERINGS,
    LOADING_STATE.CHECKOUT,
  ].includes(loadingState);

  const disableNextBtn = useMemo(() => {
    if (step === CHECKOUT) {
      let isDisabled = isSubmitting || !payment?.iAgreeTerms;

      if (primaryLocation?.metaData?.patientServiceAgreement) {
        return isDisabled || !payment?.iAgreePSATerms;
      }

      return isDisabled;
    }
    return isSubmitting;
  }, [step, isSubmitting, payment, primaryLocation]);

  const INFORMATION_TEXT = getTextFromLangDict(langDict, {
    key: '_INFORMATION',
    groupCode,
    tenantCode,
  });

  const QUESTIONAIRE_TEXT = getTextFromLangDict(langDict, {
    key: '_QUESTIONAIRE',
    groupCode,
    tenantCode,
  });

  const MEMBERSHIP_TEXT = getTextFromLangDict(langDict, {
    key: '_MEMBERSHIP',
    groupCode,
    tenantCode,
  });

  const CHECKOUT_TEXT = getTextFromLangDict(langDict, {
    key: '_CHECKOUT',
    groupCode,
    tenantCode,
  });

  const renderedPrevNextButtons = (
    <div
      className={`container ${encloseInContainer && 'px-4'} mx-auto mt-6 mb-16`}
    >
      <div
        className={`flex flex-wrap md:flex-row-reverse justify-between items-center ${
          encloseInContainer && 'dedicated-container-margin'
        }`}
      >
        <button
          data-cy="onNextClick"
          className={`mid-button w-full md:w-auto mb-2 md:mb-0 flex justify-center items-center
            bg-dedicatedDpcSecondary hover:bg-dedicatedDpcHovered ${
              disableNextBtn ? 'btn-disabled' : ''
            } `}
          onClick={onNextClick}
        >
          {isSubmitting ? (
            <div className="inline mr-2">
              <ButtonLoader />
            </div>
          ) : null}
          <span>{step === CHECKOUT ? 'Checkout' : 'Next'}</span>
          <FontAwesomeIcon icon={faArrowRight} className="text-white ml-3" />
        </button>
        {step !== INFORMATION && (
          <button
            className={`mid-button w-full md:w-auto px-4 text-dedicatedDpcSecondary bg-transparent hover:bg-gray-300
              ${(step === INFORMATION || isSubmitting) && 'btn-disabled'} `}
            onClick={onBackClick}
          >
            <FontAwesomeIcon
              icon={faArrowLeft}
              className="text-dedicatedDpcSecondary mr-3"
            />
            Go back
          </button>
        )}
      </div>
    </div>
  );

  return (
    <>
      <MultiStepFormWrapper
        header={
          <StepIndicator
            activeStep={step}
            steps={
              isQuestionaireEnabled
                ? [
                    INFORMATION_TEXT,
                    QUESTIONAIRE_TEXT,
                    MEMBERSHIP_TEXT,
                    CHECKOUT_TEXT,
                  ]
                : [INFORMATION_TEXT, MEMBERSHIP_TEXT, CHECKOUT_TEXT]
            }
          />
        }
        displayHeader={step !== CONFIRMATION}
        encloseInContainer={encloseInContainer}
      >
        <StepSelection
          step={step}
          refs={{
            individualInfoFormRef,
            companyInfoFormRef,
          }}
          misc={{
            whiteBg,
          }}
          onSaveDemographicInformation={onSaveDemographicInformation}
          onBackClick={onBackClick}
          handleQuestionnaireSubmitSuccess={handleQuestionnaireSubmitSuccess}
        />
      </MultiStepFormWrapper>

      {step !== CONFIRMATION &&
        step !== QUESTIONAIRE &&
        renderedPrevNextButtons}
    </>
  );
};

Membership.defaultProps = {
  whiteBg: true,
  encloseInContainer: true,
};

export default Membership;
