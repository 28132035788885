import {
  QUESTION_TYPE,
  AGREE_DISAGREE_VALUE,
  VISIBILITY,
} from '../constants/questionaire';
import { getFullName } from './vitafyUtil';

const formatAnswers = ({ activeQuestion }) => {
  const questionType = activeQuestion.type;
  let answers = [];

  if (questionType === QUESTION_TYPE.INFORMATION) {
    answers = [
      {
        question: {
          id: activeQuestion.id,
          title: activeQuestion.title,
          question: activeQuestion.question,
          description: activeQuestion.description,
          type: activeQuestion.type,
        },
        answer: [
          {
            value: VISIBILITY.SEEN,
            nextQuestionId: activeQuestion.nextQuestionId,
          },
        ],
      },
    ];
  } else if (questionType === QUESTION_TYPE.ATTACHMENT) {
    answers = [
      {
        question: {
          id: activeQuestion.id,
          title: activeQuestion.title,
          question: activeQuestion.question,
          description: activeQuestion.description,
          type: activeQuestion.type,
        },
        answer: activeQuestion.answer.map((ans) => ({
          id: ans.id,
          name: ans.name,
          contentType: ans.contentType,
          createdBy: ans.createdBy,
          base64: ans.base64,
          size: ans.size,
          nextQuestionId: activeQuestion.nextQuestionId,
        })),
      },
    ];
  } else if (questionType === QUESTION_TYPE.AGREE_DISAGREE) {
    answers = [
      {
        question: {
          id: activeQuestion.id,
          title: activeQuestion.title,
          question: activeQuestion.question,
          description: activeQuestion.description,
          type: activeQuestion.type,
        },
        answer: activeQuestion.options.map((item) => ({
          option: item,
          value:
            activeQuestion.answer[item.value] &&
            activeQuestion.answer[item.value].value ===
              AGREE_DISAGREE_VALUE.AGREE
              ? AGREE_DISAGREE_VALUE.AGREE
              : AGREE_DISAGREE_VALUE.DISAGREE,
          nextQuestionId: activeQuestion.nextQuestionId,
        })),
      },
    ];
  } else if (Array.isArray(activeQuestion.answer)) {
    answers = [
      {
        question: {
          id: activeQuestion.id,
          title: activeQuestion.title,
          question: activeQuestion.question,
          description: activeQuestion.description,
          type: activeQuestion.type,
        },
        answer: activeQuestion.answer.map((ans) => ({
          value: ans.value,
          score: ans.score,
          sequence: ans.sequence,
          isExit: ans.isExit,
          nextQuestionId: activeQuestion.nextQuestionId,
        })),
      },
    ];
  } else {
    answers = [
      {
        question: {
          id: activeQuestion.id,
          title: activeQuestion.title,
          question: activeQuestion.question,
          description: activeQuestion.description,
          type: activeQuestion.type,
        },
        answer: [
          {
            value: activeQuestion.answer.value,
            score: activeQuestion.answer.score,
            sequence: activeQuestion.answer.sequence,
            isExit: activeQuestion.answer.isExit,
            nextQuestionId:
              activeQuestion.answer.nextQuestionId ||
              activeQuestion.nextQuestionId,
          },
        ],
      },
    ];
  }

  return answers;
};

export const formatQuestionaireResponseSavePayload = ({
  activeQuestion,
  questionaire,
  respondent,
}) => {
  return {
    questionnaire: {
      id: questionaire.id,
      code: questionaire.code,
      title: questionaire.title,
    },
    respondent: {
      id: respondent.clientId,
      name: getFullName({
        firstName: respondent.firstName,
        middleName: respondent.middleName,
        lastName: respondent.lastName,
      }),
      phone: respondent.phone,
      email: respondent.email,
      dob: respondent.dob,
      gender: respondent.gender,
      address: respondent.address,
      bloodGroup: respondent.bloodGroup,
    },
    answers: formatAnswers({ activeQuestion }),
    tenantId: respondent.tenantId,
  };
};

export const formatQuestionaireResponseUpdatePayload = ({
  activeQuestion,
  respondent,
}) => {
  const questionType = activeQuestion.type;

  const payload = {
    respondent: {
      id: respondent.clientId,
      name: getFullName({
        firstName: respondent.firstName,
        middleName: respondent.middleName,
        lastName: respondent.lastName,
      }),
      phone: respondent.phone,
      email: respondent.email,
      dob: respondent.dob,
      gender: respondent.gender,
      address: respondent.address,
      bloodGroup: respondent.bloodGroup,
    },
    tenantId: respondent.tenantId,
  };

  if (questionType === QUESTION_TYPE.SUBMIT_CONFIRMATION) {
    return { ...payload, isCompleted: true, answers: [] };
  }

  return {
    ...payload,
    answers: formatAnswers({ activeQuestion }),
  };
};
