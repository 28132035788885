import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  LineSkeleton
} from '../../common';
import {
  faChevronUp,
  faChevronDown
} from '@fortawesome/free-solid-svg-icons';

const FilterSideBar = (props) => {
  const {
    locations,
    filterRadius,
    radiusList,
    handleChangeFilterRadius,
    handleChangeFilterLocation,
    handleClearFilters,
    isLoading
  } = props;
  return (
    <div className='sticky-filter-sidebar bg-white px-4 py-4'>
      <div className='flex justify-between items-center mb-3'>
        <p className='body-text text-titleColor font-bold' >Filter</p>
        <p className='body-text text-sm text-secondary hover:text-hovered cursor-pointer' onClick={() => handleClearFilters()}>Clear all</p>
      </div>
      <hr />
      <Accordion
        title='Radius'
      >
        <RadiusFilter
          filterRadius={filterRadius}
          radiusList={radiusList}
          handleChangeFilterRadius={handleChangeFilterRadius}
        />
      </Accordion>
      <Accordion
        title='Locations'
      >
        {!isLoading ?
          <LocationFilter
            locations={locations}
            handleChangeFilterLocation={handleChangeFilterLocation}
          />
          :
          <LineSkeleton
            width='450'
            height='10'
            containerClassName='my-2'
            lines={4}
          />
        }
      </Accordion>
    </div>
  );
}

const Accordion = (props) => {
  const { title } = props;
  const [expand, setExpand] = useState(true);
  return (
    <div className='py-5 border-t border-gray-200  bg-pink'>
      <div className='flex justify-between items-center cursor-pointer'
        onClick={() => setExpand(!expand)}
      >
        <p className='body-text text-sm font-bold'>{title}</p>
        <FontAwesomeIcon icon={expand ? faChevronUp : faChevronDown} className='text-bodyColor text-sm cursor-pointer' />
      </div>
      <div className={`accordion-content ${expand && 'accordion-content-expanded'}`}>
        {props.children}
      </div>
    </div>
  );
}

const RadiusFilter = (props) => {
  const { filterRadius, radiusList, handleChangeFilterRadius } = props;
  return (
    <div className='mt-2'>
      {radiusList && radiusList.map((radius, index) =>
        <div
          onClick={() => handleChangeFilterRadius(radius)}
          className={`group px-4 py-2 bg-bgColor rounded-sm inline-block cursor-pointer mr-2 mb-2  ${filterRadius === radius ? 'border border-primary' : 'border border-bgColor'} transition-all duration-500 hover:border-primary`}
          key={index} >
          <p className={`body-text text-sm ${filterRadius === radius && 'text-primary'} transition-all duration-500 group-hover:text-primary`}> {radius} mi</p>
        </div>
      )}
    </div>
  );
}

const LocationFilter = (props) => {
  const { locations, handleChangeFilterLocation } = props;
  return (
    <div className='mt-2 h-40 overflow-y-auto primary-scrollbar'>
      {locations && locations.map((location, index) =>
        <div key={index} className='group flex justify-between items-center my-3 cursor-pointer'>
          <p
            className={`body-text text-sm transition-all duration-500 group-hover:opacity-100 group-hover:text-primary ${location.included ? 'opacity-100' : 'opacity-25'}`}
            onClick={() => handleChangeFilterLocation(location)}
          >
            {location.location} <span className='opacity-75'>({location.count})</span>
          </p>
          <p
            className='body-text text-sm text-primary mr-4 opacity-0 transition-all duration-500 group-hover:opacity-100'
            onClick={() => handleChangeFilterLocation(location, true)}
          >
            Only
          </p>
        </div>
      )}
    </div>
  );
}


export default FilterSideBar;