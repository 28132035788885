import React from 'react';
import ReactHtmlParser from 'react-html-parser';

const FirstContent = ({
  title,
  text,
  image,
  bannerVideoLink,
  bannerVideoVisibility,
  tagline,
  taglineVisibility
}) => {
  return (
    <>
      <div className='w-full pt-24 lg:pt-32 pb-12 lg:pb-20 bg-extended'>
        <div className='container mx-auto px-4'>
          {taglineVisibility ?
            <>
              <h1 className='font-heebo uppercase font-bold text-white text-3xl lg:text-5xl'>
                {title}
              </h1>
              <h6 className='firstContentHeading normal-case font-normal text-base lg:text-xl mt-0 pt-0'>
                {tagline}
              </h6>
            </>
            :
            <h1 className='firstContentHeading normal-case'>{title}</h1>
          }
        </div>
      </div>
      <div className='container mx-auto py-10 lg:py-16 flex px-4'>
        <div className='w-full lg:w-1/2 lg:pr-12'>
          <div className='body-text text-lg'>
            {ReactHtmlParser(text)}
          </div>
        </div>
        {bannerVideoVisibility && bannerVideoLink ? (
          <div className='lg:w-1/2 hidden lg:block'>
            <div className='banner-video-container'>
              <iframe
                width='560'
                height='315'
                src='https://www.youtube.com/embed/es9nCRxS_e0'
                frameborder='0'
                allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                allowfullscreen
              ></iframe>
            </div>
          </div>
        ) : (
          <div className='banner-img lg:w-1/2 hidden lg:block'>
            <img src={image} alt='banner-image' />
          </div>
        )}
      </div>
    </>
  );
};

export default FirstContent;
