import React from 'react';

const PhysicianList = ({ physicians }) => {
  return (
    <div className='container mx-auto px-4'>
      <div className='divider'></div>

      <h3 className='heading'>Associated Physician</h3>

      <div className='flex flex-wrap -mx-5'>
        {physicians.map((item, index) => (
          <div
            className={`w-full sm:w-1/2 lg:w-1/3 px-5 mb-10 ${
              physicians.length > 3 ? 'lg:mb-12' : 'lg:mb-0'
            }`}
            key={index}
          >
            <div className='w-full rounded-lg shadow-md'>
              <div className='w-full pb-full rounded-t-lg relative'>
                <img
                  src={item.image}
                  className='absolute w-full h-full object-cover object-center rounded-t-lg'
                  alt='profile-img'
                />
              </div>

              <div className='py-4 px-5'>
                <h4 className='mb-0'>{item.name}</h4>
                <p className='body-text'>{item.post}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className='divider'></div>
    </div>
  );
};

export default PhysicianList;
