import React from 'react';
import { useFormContext } from 'react-hook-form';

import commonConstants from '../../constants/commonConstant';
const { DEPENDENT_TYPES } = commonConstants;

const RelationshipRadioButton = ({
  whiteBg,
  isSelected,
  type,
  onClick,
  buttonClassName = 'md:py-1',
}) => (
  <button
    data-cy={`relationship-${type}`}
    className={`small-radio-btn ${
      !whiteBg && 'bg-white'
    } w-full ${buttonClassName}
       ${isSelected && 'bg-holyCityMedSecondary font-bold'}`}
    onClick={onClick}
    type="button"
  >
    {type}
  </button>
);

const FormRelationshipInput = ({ whiteBg, buttonClassName = '' }) => {
  const {
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const value = watch('relationship');

  const handleClick = (value) => {
    setValue('relationship', value);
    clearErrors('relationship');
  };

  return (
    <>
      <p className="body-text text-sm mb-1">Relationship to Primary Member</p>
      <div className="w-full flex align-items-center -mx-1">
        {DEPENDENT_TYPES.map((item) => (
          <div className="w-1/3 px-1" key={item.value}>
            <RelationshipRadioButton
              type={item.text}
              whiteBg={whiteBg}
              isSelected={value === item.value}
              onClick={() => handleClick(item.value)}
              buttonClassName={buttonClassName}
            />
          </div>
        ))}
      </div>
      {!!errors.relationship && (
        <p className="text-red-600 text-sm mt-3">
          Please select the relationship.
        </p>
      )}
    </>
  );
};

FormRelationshipInput.defaultProps = {
  isRequired: false,
  isDisabled: false,
};

export default FormRelationshipInput;
