import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';


const OptionBox = (props) => {
    const { option, title, activeOption, changeActiveOption } = props;

    const checkActive = (option, activeOption) => {
        if (option === null) {
            return activeOption === 'Others';
        }
        return activeOption === option;
    }

    return (
        <div
            className="w-full sm:w-1/2 lg:w-1/4 px-2"
            onClick={() => changeActiveOption(option ? option : 'Others')}
        >
            <div
                className={`help-center-option-box ${
                    (checkActive(option, activeOption)) ? 'relative border-extended bg-white' : null
                    } bg-white`}
            >
                <p
                    className={`help-center-option-text ${
                        (checkActive(option, activeOption)) ? 'text-extended' : null
                        }`}
                >
                    {title}
                </p>
                {(checkActive(option, activeOption)) ? (
                    <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="text-4xl text-extended absolute top-0 left-0 pl-3 pt-3"
                    />
                ) : null}
            </div>
        </div>
    );
}

export default OptionBox;