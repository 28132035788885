import React, { useCallback, useEffect, useState } from 'react';
import VitafyLogo from '../../assets/images/logo.svg';
import MaintenanceBypassModalForm from './MaintenanceBypassForm';

const supportContact = process.env.REACT_APP_SUPPORT_CONTACT_EMAIL;

const Maintenance = ({ setIsMaintenanceMode }) => {
  const [showBypassForm, setShowBypassForm] = useState(false);

  const handleKeyPress = useCallback((event) => {
    if (event.key === 'E') {
      // Show bypass form when 'E' key is pressed
      setShowBypassForm((prevState) => !prevState);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <>
      <div
        className="w-full flex flex-col justify-center items-center p-6"
        style={{ height: '100vh' }}
      >
        <img src={VitafyLogo} alt="Vitafy-logo" className="mb-6" />

        <h1 className="text-3xl font-medium text-titleColor text-center mb-4">
          We'll back soon
        </h1>
        <p className="text-md text-bodyColor text-center">
          Vitafy is down for scheduled maintenance. Please check back soon.
        </p>
        <p className="text-md text-bodyColor text-center">
          If you have any question, please contact us at{' '}
          <a
            href={`mailto:${supportContact}`}
            className="no-underline focus:outline-none inline-block text-secondary"
          >
            {supportContact}
          </a>
        </p>
      </div>

      {showBypassForm && (
        <MaintenanceBypassModalForm
          setIsMaintenanceMode={setIsMaintenanceMode}
        />
      )}
    </>
  );
};

export default Maintenance;
