import React from "react";
import NumberFormat from "react-number-format";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

const MaskedCurrencyInput = ({ input, setInput, label, icon, error }) => {
  return (
    <div className="mdp-input-main mt-2 mb-1 ">
      <div className="mdp-input-box">
        <NumberFormat
          id="masked-currency-input"
          thousandSeparator={true}
          prefix={'$'}
          value={input}
          style={{ textIndent: icon ? "2rem" : "" }}
          className="mdp-input bg-transparent text-titleColor font-heebo"
          onValueChange={(value) => setInput(value.formattedValue)}
        />
        <label
          htmlFor="masked-currency-input"
          className={`mdp-label ${icon ? "ml-10" : null}`}
        >
          {label}
        </label>
        {icon && (
          <FontAwesomeIcon
            icon={icon}
            className="mdp-icon"
          />
        )}

        {error && (
          <FontAwesomeIcon
            icon={faExclamationCircle}
            className="text-red-500"
            style={{
              position: "absolute",
              top: "10px",
              right: "0px",
              fontSize: "20px",
            }}
          />
        )}

        <div
          className={`${error ? "mdp-input-error-underline" : "mdp-input-underline"
            }`}
        />
      </div>
    </div>
  );
};

export default MaskedCurrencyInput;