import React from 'react';
import ReactTooltip from 'react-tooltip';
import { truncateText } from '../util/miscellaneousUtil';

const TruncateWithToolTip = ({ text, char = 30, textClassName = '' }) => {
  return (
    <>
      {text.length > char ? (
        <>
          <p data-tip data-for='txt' className={`body-text text-sm ${textClassName}`}>{truncateText(text, char)}</p>
          <ReactTooltip id='txt' type='dark'>
            <span className='body-text text-sm text-white'>{text}</span>
          </ReactTooltip>
        </>
      ) : (
          text
        )}
    </>
  );
}

export default TruncateWithToolTip;