import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMapMarkerAlt,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import { LocationAutocomplete, CloudProvidersAutocomplete, Loader } from '../../../common';
import { useForm } from 'react-hook-form';
import placeholder from '../../../assets/images/placeholder-hospital.png';
import ProviderAutoComplete from '../../providerAutoComplete';

const AddDpcForm = (props) => {
  // const dpcCount = [1, 2]; // just to simulate multiple DPCs in search result
  const {
    setValue,
    clearError,
  } = useForm();
  const { directPrimaryCare, dpcSearchList, openModalName, dpcLoading } = props;
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchLocation, setSearchLocation] = useState('');
  const [searchCity, setSearchCity] = useState('');
  const [searchState, setSearchState] = useState('');
  const [searchZip, setSearchZip] = useState('');
  const [searchLatitude, setSearchLatitude] = useState('');
  const [searchLongitude, setSearchLongitude] = useState('');
  const [dpcSearched, setDpcSearched] = useState(dpcSearchList && dpcSearchList.length > 0 ? true : false);
  const handleInputChange = (
    e,
    field,
    plan = undefined,
    planField = undefined
  ) => {
    let value = e.target ? e.target.value : e;
    if ((e.target ? e.target.validity.valid : e) || value == '') {
      setValue(field, value);
      if (field == 'searchKeyword') {
        setSearchKeyword(value);
      } else if (field == 'searchLocation') {
        setSearchLocation(value);
        setSearchCity('');
        setSearchState('');
        setSearchZip('');
        setSearchLatitude('');
        setSearchLongitude('');
      }
      if (plan) {
        plan[planField] = value;
      }
      clearError(field);
    }
  };
  const onSelectHandler = (
    plan,
    selectedItem,
    fields = [],
    setValueFields = [],
    search = false,
    selectDpc = false,
    selectFromDropdown = false
  ) => {
    if (!search) {
      plan.planDescription = selectedItem[fields[0]];
      plan.referenceId = selectedItem[fields[1]];
      plan.cost = fields[2] ? selectedItem[fields[2]] : 0;
      plan.npi = fields[3] ? selectedItem[fields[3]] : null;
      plan.isActive = true;
      props.savePlan(props[openModalName], selectDpc ? selectedItem : null, selectFromDropdown);
    }
    setValueFields.map((x, index) => {
      let value = selectedItem[fields[index]]
      setValue(x, value);
      clearError(x);
      if (x == 'searchCity') {
        setSearchCity(value);
      } else if (x == 'searchState') {
        setSearchState(value);
      } else if (x == 'searchZip') {
        setSearchZip(value);
      } else if (x == 'searchLatitude') {
        setSearchLatitude(value);
      } else if (x == 'searchLongitude') {
        setSearchLongitude(value);
      } else if (x == 'searchKeyword') {
        setSearchKeyword(value);
      }
    });
  };
  const searchDpc = () => {
    if (searchKeyword || searchLocation) {
      let searchParams = {
        searchKeyword, searchLocation, searchCity, searchState, searchZip, searchLatitude, searchLongitude
      }
      props.searchDpc(searchParams);
      setDpcSearched(true);
    } else {
      setDpcSearched(false);
      return;
    }
  };
  const autocompleteInputHandler = (keyword) => {
    let params = {
      isAutoComplete: true,
      keyword
    };
    if (props.searchLocation?.lng && Math.round(props.searchLocation.lng) != 85) {//second condition => neglect lat long of ktm
      params = {
        ...params,
        latitude: props.searchLocation.lat,
        longitude: props.searchLocation.lng
      }
    }
    return props.searchProviders(params);
  }
  const searchProviders = (autocomplete = true) => {
    if (searchKeyword || searchLocation) {
      let searchParams = {
        keyword: searchKeyword, latitude: searchLatitude, longitude: searchLongitude
      }
      props.searchProviders(searchParams, autocomplete);
      setDpcSearched(true);
    } else {
      setDpcSearched(false);
      return;
    }
  };
  return (
    <>
      {/* SEARCH FORM STARTS HERE */}
      <div className='flex flex-wrap -mx-2'>
        <div className='w-full sm:w-5/12 sm:pl-2'>
          {/* <CloudProvidersAutocomplete
            placeholder='Search DPC Name'
            inputRequired={false}
            onSelectHandler={(e) =>
              onSelectHandler(
                directPrimaryCare,
                e,
                ['displayName', 'providerId', '', 'npi'],
                ['searchKeyword'],
                false,
                true,
                true
              )
            }
            handleInputChange={(e) => handleInputChange(e, 'searchKeyword')}
            displayNameValue={searchKeyword}
            category='dpc'
            iconType='dpc'
          /> */}
          <ProviderAutoComplete
            onSelectHandler={(e) =>
              onSelectHandler(
                directPrimaryCare,
                e,
                ['displayName', 'providerId', '', 'npi'],
                ['searchKeyword'],
                false,
                true,
                true
              )}
            handleInputChange={(e) => handleInputChange(e, 'searchKeyword')}
            placeholder='Search DPC Name'
            displayNameValue={searchKeyword}
            searchForAutoComplete={autocompleteInputHandler}
            inputRequired={false}
          />
        </div>
        <div className='w-full my-2 text-center sm:hidden'>
          <p className='font-heebo text-bodyColor text-lg'>OR</p>
        </div>
        <div className='w-full sm:w-7/12 sm:px-2'>
          <div className='flex -mx-2'>
            <div className='px-2 hidden sm:flex items-center'>
              <span className='font-heebo text-bodyColor text-lg'>OR</span>
            </div>
            <div className='w-3/5 px-0 flex-grow'>
              {/* Enter Location */}
              <LocationAutocomplete
                placeholder='Search by Location'
                inputRequired={false}
                iconType='location'
                onSelectHandler={(e) =>
                  onSelectHandler(
                    null,
                    e,
                    ['displayName', 'city', 'st', 'zip', 'lat', 'lng'],
                    ['searchLocation', 'searchCity', 'searchState', 'searchZip', 'searchLatitude', 'searchLongitude'],
                    true
                  )
                }
                handleInputChange={(e) =>
                  handleInputChange(e, 'searchLocation')
                }
                displayNameValue={searchLocation}
              />
            </div>
            <div className='w-1/5 px-2'>
              {/* Search Button */}
              <button
                className='bg-secondary hover:bg-hovered w-full h-12 rounded-md shadow-md focus:outline-none'
                type='button'
                // onClick={() => searchDpc()}
                onClick={() => searchProviders(false)}
              >
                <FontAwesomeIcon icon={faSearch} className='text-white' />
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* SEARCH FORM ENDS HERE */}

      {dpcSearched ? (
        <>
          <div className='divider-line bg-gray-500'></div>

          <div className='w-full bg-bgColor p-3 enrollment-add-modal-height'>
            {/* SINGLE DPC SEARCH RESULT STARTS HERE */}
            {dpcLoading ?
              <Loader
                hScreen={false}
              />
              : <>
                {dpcSearchList && dpcSearchList.length > 0 ? (
                  dpcSearchList.map((x) => (
                    <div
                      className='flex justify-between items-center p-4 bg-white cursor-pointer shadow-md md:shadow-md hover:shadow-lg mb-5'
                      key={x.providerId}
                    >
                      <div className='flex items-center'>
                        <img
                          src={x.imageUrl ? x.imageUrl : placeholder}
                          alt='dpc-logo'
                          className='w-12 sm:w-16 rounded-full block mr-4'
                        />
                        <div>
                          <h6 className='text-base sm:text-lg'>
                            {/* {vitafyUtils.getDisplayName(x)} */}
                            {x.providerName}
                          </h6>
                          <FontAwesomeIcon
                            icon={faMapMarkerAlt}
                            className='text-primary mr-1'
                          />
                          <p className='body-text inline text-sm sm:text-base'>
                            {x.address && (
                              <>
                                {x.address.addressLine1} <br />
                                {x.address.city}, {x.address.state} {x.address.zip}
                              </>
                            )}
                          </p>
                          {/* button visible for smaller screens */}
                          <button
                            className='small-button sm:hidden mt-2'
                            type='button'
                            onClick={() =>
                              onSelectHandler(
                                directPrimaryCare,
                                x,
                                ['displayName', 'providerId', '', 'npi'],
                                ['directPrimaryCareDescription'],
                                false,
                                true
                              )
                            }
                          >
                            {' '}
                        Select{' '}
                          </button>
                        </div>
                      </div>
                      {/* button visible for larger screens */}
                      <button
                        className='small-button hidden sm:block'
                        type='button'
                        onClick={() =>
                          onSelectHandler(
                            directPrimaryCare,
                            x,
                            ['displayName', 'providerId', '', 'npi'],
                            ['directPrimaryCareDescription'],
                            false,
                            true
                          )
                        }
                      >
                        {' '}
                    Select{' '}
                      </button>
                    </div>
                  ))
                ) : (
                    <>
                      <p>No results found</p>
                    </>
                  )}
              </>
            }
            {/* SINGLE DPC SEARCH RESULT ENDS HERE */}
          </div>
        </>
      ) : null}
    </>
  );
};

export default AddDpcForm;
