import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { showToast } from '../../service/toasterService';
import ReactLoading from 'react-loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMapMarkerAlt,
  faMobileAlt,
  faEnvelope,
  faPaperPlane,
} from '@fortawesome/free-solid-svg-icons';

import * as service from '../../service';
import { Loader, FilledInput } from '../../common';
import commonConstants from '../../constants/commonConstant';

const { PRIVACY_POLICY, TERMS_OF_USE } = commonConstants.VITAFY_AGREEMENTS;

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      sending: false,
      about: {},
      quickLinks: [],
      email: '',
      emailNotPresentError: false,
      phone: '',
    };
  }

  componentDidMount() {
    this.getAbout();
    this.getFooterContent();
  }

  getAbout = () => {
    this.setState({ loading: true });

    service
      .getAbout()
      .then((result) => {
        if (result.status === 'success') {
          this.setState({
            about: result.data.find((item) => item.slug === 'about-us'),
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.log('Error in get footer (About Content)', error);
        this.setState({ loading: false, error: true });
      });
  };

  getFooterContent = () => {
    this.setState({ loading: true });
    service
      .getFooterContent()
      .then((result) => {
        if (result.status === 'success') {
          this.setState({
            quickLinks: result.data.find((item) => item.slug === 'quick-links')
              ?.metadata?.quick_links_list,
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.log('Error in get footer (Quick Links)', error);
        this.setState({ loading: false, error: true });
      });
  };

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value, emailNotPresentError: false });
  };
  handlePhoneChange = (e) => {
    this.setState({ phone: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    // console.log('Form Submit');
    if (this.state.email === '') {
      this.setState({ emailNotPresentError: true });
    } else {
      const dataToSend = {
        email: this.state.email,
        phone: this.state.phone,
      };

      this.setState({ sending: true });

      service
        .subscribeNewsletter(dataToSend, true)
        .then((result) => {
          this.setState({ sending: false });
          if (result.data === 'success') {
            showToast('success', 'Thank you for subscribing!');
            //reset the form
            this.setState({ email: '', phone: '' });
          } else {
            showToast('error', 'Oops. Something went wrong');
          }
        })
        .catch((error) => {
          console.log('Error in get newsletter subscribe', error);
          this.setState({ sending: false });
        });
    }
  };

  render() {
    const useDevLinkForQuickLink =
      process.env.REACT_APP_ENV.toUpperCase() !== 'PRODUCTION';

    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className={`${this.props.bgWhite ? 'bg-white' : 'bg-bgColor'}`}>
          <div className="divider"></div>

          <div className="container mx-auto px-4 mb-4 md:mb-6">
            {/* SUBSCRIBER NEWSLETTER STARTS HERE */}
            <h3 className="heading">Subscribe Newsletter</h3>
            <form onSubmit={this.handleSubmit}>
              <div className="flex flex-wrap md:-mx-1 lg:-mx-2">
                <div className="w-full md:w-2/5 md:px-1 lg:px-2 mb-4 md:mb-5 lg:mb-5">
                  <FilledInput
                    input={this.state.email}
                    setInput={this.handleEmailChange}
                    placeholder="Email"
                    type="email"
                    id="newsletter-email"
                    iconName={faEnvelope}
                  />
                  {/* Helper Text: Use this for warning, error or for additional information with appropriate color */}
                  <span
                    className={`font-heebo text-sm text-errorColor pt-0 ml-10 ${
                      this.state.emailNotPresentError ? 'block' : 'hidden'
                    }`}
                  >
                    Please enter your email
                  </span>
                </div>
                <div className="w-full md:w-2/5 md:px-1 lg:px-2 mb-4 md:mb-5 lg:mb-5">
                  <FilledInput
                    input={this.state.phone}
                    setInput={this.handlePhoneChange}
                    placeholder="Phone"
                    type="number"
                    id="newsletter-phone"
                    iconName={faMobileAlt}
                  />
                </div>
                <div className="w-full md:w-1/5 md:px-1 lg:px-2 mb-6 md:mb-5 lg:mb-5">
                  <button
                    className="mid-button  shadow transition duration-500 ease-in-out  hover:shadow-lg  my-0 w-full lg:px-5 xl:w-auto"
                    type="submit"
                  >
                    Subscribe
                    {this.state.sending ? (
                      <ReactLoading
                        className="inline-block ml-3 pt-1"
                        type="spin"
                        color="white"
                        height={'20px'}
                        width={'20px'}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faPaperPlane}
                        className="text-white inline-block ml-3 sm:hidden lg:inline"
                      />
                    )}
                  </button>
                </div>
              </div>
            </form>
            {/* SUBSCRIBER NEWSLETTER ENDS HERE */}

            <div className="flex flex-wrap lg:-mx-2 mt-8">
              {/* ABOUT VITAFY STARTS HERE */}
              <div className="w-full lg:w-3/6 px-2 mb-5 lg:mb-0">
                <h6 className="mb-4">About Vitafy</h6>
                <div className="lg:pr-24">
                  <div className="body-text">
                    {ReactHtmlParser(
                      this.state.about && this.state.about.content
                    )}
                  </div>
                </div>
              </div>
              {/* ABOUT VITAFY ENDS HERE */}
              {/* CONTACT INFO STARTS HERE */}
              <div className="w-full md:w-1/3 lg:w-2/6 px-2 mb-5 md:mb-0">
                <h6 className="mb-4">Contact Information</h6>
                <div className="flex mb-2">
                  <div>
                    <FontAwesomeIcon
                      icon={faMobileAlt}
                      className="block mt-1 text-primary"
                    />
                  </div>
                  <a
                    href="tel:8664848239"
                    className="body-text pl-3 no-underline focus:outline-none"
                  >
                    (866) 4-VITAFY <br />
                    (866) 484-8239
                  </a>
                </div>
                <div className="flex mb-2">
                  <div>
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="block mt-1 text-primary"
                    />
                  </div>
                  <a
                    href="mailto:info@vitafyhealth.com"
                    className="body-text pl-2 focus:outline-none no-underline"
                  >
                    info@vitafyhealth.com
                  </a>
                </div>
                <div className="flex mb-2">
                  <div>
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      className="block mt-1 text-primary"
                    />
                  </div>
                  <p className="body-text pl-2 focus:outline-none no-underline">
                    4845 Johnston Oehler Road,
                    <br />
                    Suite D, Charlotte, NC 28269
                  </p>
                </div>
                {/* <div className='w-full mt-4'>
                  <a href='#' className='outline-none no-underline'>
                    <FontAwesomeIcon
                      icon={faFacebookF}
                      className='text-primary text-xl hover:text-secondary mr-2'
                    />
                  </a>
                  <a href='#' className='outline-none no-underline ml-3 mr-2'>
                    <FontAwesomeIcon
                      icon={faTwitter}
                      className='text-primary text-xl hover:text-secondary'
                    />
                  </a>
                  <a href='#' className='outline-none no-underline ml-3'>
                    <FontAwesomeIcon
                      icon={faGooglePlusG}
                      className='text-primary text-xl hover:text-secondary'
                    />
                  </a>
                </div> */}
              </div>
              {/* CONTACT INFO ENDS HERE */}
              {/* QUICK LINKS STARTS HERE */}
              <div className="w-1/2 md:w-1/3 lg:w-1/6 px-2 md:mb-0 flex md:justify-end">
                <div>
                  <h6 className="mb-4">Quick Links</h6>
                  <ul className="list-none">
                    {this.state.quickLinks?.map((item, index) => (
                      <li key={index}>
                        <a
                          href={
                            useDevLinkForQuickLink && item.dev_link
                              ? item.dev_link
                              : item.link
                          }
                          className="link text-bodyColor hover:text-secondary"
                        >
                          {item.text}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              {/* QUICK LINKS ENDS HERE */}
            </div>
          </div>

          <div className="inner-divider"></div>

          {/* COPYRIGHT INFO & PRIVACY POLICY | TERMS OF USE STARTS HERE */}
          <div className="w-100 bg-white">
            <div className="container mx-auto flex flex-wrap justify-between items-center py-3 px-4">
              <p className="w-full body-text lg:w-3/4 text-sm mb-2 lg:mb-0 text-center lg:text-left">
                &copy; {new Date().getFullYear()} copyright Vitafy LLC. Powered
                by{' '}
                <a href="#" className="font-bold outline-none no-underline">
                  Vitafy360
                </a>
              </p>

              <div className="w-full lg:w-1/4 flex justify-center lg:block lg:text-right">
                <a
                  href={PRIVACY_POLICY}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="no-underline body-text text-sm font-bold hover:text-secondary"
                >
                  Privacy Policy <span>|</span>{' '}
                </a>
                <a
                  href={TERMS_OF_USE}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="no-underline body-text text-sm font-bold hover:text-secondary"
                >
                  Terms of Use
                </a>
              </div>
            </div>
          </div>
          {/* COPYRIGHT INFO & PRIVACY POLICY | TERMS OF USE STARTS HERE */}
        </div>
      </>
    );
  }
}

export default withRouter(Footer);
