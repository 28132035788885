import React from 'react';

const OverlayCta = (props) => {
  return (
    <div
      className="overlay-cta w-full rounded-md px-4 xl:px-10 py-8 bg-blue-100"
      style={{
        backgroundImage: `linear-gradient(
      rgba(255, 255, 255, 0.65),
      rgba(255, 255, 255, 0.65)
    ),
    url(https://images.unsplash.com/photo-1519494026892-80bbd2d6fd0d?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1035&q=80)`
      }}
    >
      <h3 className='font-heebo font-bold text-xl md:text-2xl text-titleColor z-10 text-center'>
        Are you a DPC?
    </h3>
      <p className='body-text text-base mt-1 mb-4 text-center'>Our agents help you find clients.</p>
      <button
        className='small-button py-2 px-8 text-base mx-auto'
        onClick={props.openRequestDemoModal}
      >Contact Us</button>
    </div>
  );
}

export default OverlayCta;