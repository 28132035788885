import _truncate from 'lodash/truncate';
import DOMPurify from 'dompurify';

DOMPurify.addHook('afterSanitizeAttributes', function (node) {
  // set all elements owning target to target=_blank
  if ('target' in node) {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener');
  }
});

// Parse HtML (coming from Cosmic)
export const parseHtml = (html, chars) => {
  if (chars) {
    return _truncate(DOMPurify.sanitize(html), { length: chars });
  } else {
    return DOMPurify.sanitize(html);
  }
};

// change to DateTime format
export const changeToDateTime = (date) => {
  if (date) {
    if (new Date(date) != 'Invalid Date') return new Date(date);
    else return null;
  } else {
    return null;
  }
};

/**
 * Get substring between first and second occurence of given character
 *
 * @example
 * Input: '/tenantCode/asd/def'
 * Output: 'tenantCode'
 *
 * @param {string} text
 * @param {string} char
 * @returns {string}
 */
export const getSubstringBetweenChars = (text, char = '') => {
  if (!char || !text) return '';

  let arr = text.split('/');

  if (arr.length < 1) return '';

  return arr[0];
};

/**
 * Check if every values of an object is empty or null
 *
 * @param {any} obj
 * @returns {boolean}
 */
export const checkIfObjHasAllEmptyOrNullValues = (obj) =>
  Object.values(obj).every((x) => x === null || x === '');

export const getTextFromLangDict = (
  langDict,
  { key, groupCode, tenantCode }
) => {
  if (!langDict) return '';
  return (
    langDict[`_${groupCode}_${tenantCode}${key}`] ||
    langDict[`_${groupCode}${key}`] ||
    langDict[key] ||
    ''
  );
};
