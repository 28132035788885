import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import { Modal } from '../../../../common';
import {
  selectDependents,
  selectInformationTab,
  selectOfferings,
  selectSelectedOfferings,
  setSelectedOfferings,
} from '../../../../stores/membership';
import {
  checkIsIndividual,
  formatCurrency,
  getFullName,
} from '../../../../util/vitafyUtil';
import commonConstants from '../../../../constants/commonConstant';
import { _cloneDeep } from '../../../../util/lodashUtil';
import { store } from '../../../../store';
import { getTextFromLangDict } from '../../../../util';

const {
  BILLING_INTERVAL,
  BILLING_TYPE,
  OFFERING_TYPE_PRIMARY,
  OFFERING_TYPE_DEPENDENT,
} = commonConstants;

const SwitchOfferingModal = ({ title, closeHandler, dependentIdForSwitch }) => {
  const dispatch = useDispatch();

  const primaryIndividual = useSelector(selectInformationTab);
  const selectedOfferings = useSelector(selectSelectedOfferings);
  const offerings = useSelector(selectOfferings);
  const dependents = useSelector(selectDependents);

  // Context Store
  const state = useContext(store);
  const { globalState } = state;
  const { langDict, groupCode, tenantCode } = globalState;

  const [localOfferings, setLocalOfferings] = useState([]); // local state to account for offering change

  const isIndividual = checkIsIndividual(primaryIndividual.type);
  const primaryIndividualFullName = getFullName({
    firstName: primaryIndividual.firstName,
    lastName: primaryIndividual.lastName,
    middleName: primaryIndividual.middleName,
  });

  useEffect(() => {
    if (!offerings || !selectedOfferings) {
      return;
    }

    let list = _cloneDeep(offerings);
    list = list.map((offering) => ({
      ...offering,
      isChecked: dependentIdForSwitch
        ? selectedOfferings.some(
            (item) =>
              item.offeringType === OFFERING_TYPE_DEPENDENT &&
              item.tenantOfferingId === offering.tenantOfferingId &&
              item.dependent?.clientId === dependentIdForSwitch
          )
        : selectedOfferings.some(
            (item) =>
              item.offeringType === OFFERING_TYPE_PRIMARY &&
              item.tenantOfferingId === offering.tenantOfferingId
          ),
    }));
    setLocalOfferings(list);
  }, [offerings, dependentIdForSwitch, selectedOfferings]);

  const onSelectOffering = (tenantOfferingId) => {
    setLocalOfferings((prevState) =>
      prevState.map((item) => ({
        ...item,
        isChecked: item.tenantOfferingId === tenantOfferingId,
      }))
    );
  };

  /**
   * If primary or dependent selects a family offering, only one family offering is selected (goes for the primary).
   *
   * If primary selects a individual offering given that previous offerings is family offering, all of its dependent will
   * get the same offering chosen by the primary
   *
   * If primary selects a individual offering given that previous offerings is not family offering, only the offering of
   * the primary will be updated.
   *
   * If dependent selects a individual offering, only the offering of the dependent will be updated.
   *
   */
  const onSubmit = () => {
    const selected = localOfferings.find((item) => item.isChecked);
    let newSelectedOfferings = [];

    const isGroupOfferingToSelect = selected.billingType === BILLING_TYPE.GROUP;
    const isFamilyOfferingToSelect =
      selected.billingType === BILLING_TYPE.FAMILY;
    const isIndividualOfferingToSelectForDependent =
      selected.billingType === BILLING_TYPE.INDIVIDUAL &&
      !!dependentIdForSwitch;
    const isIndividualOfferingToSelectForPrimary =
      (selected.billingType === BILLING_TYPE.INDIVIDUAL ||
        selected.billingType === BILLING_TYPE.GROUP) &&
      !dependentIdForSwitch &&
      selectedOfferings.some(
        (item) => item.billingType !== BILLING_TYPE.FAMILY
      );
    const isIndividualOfferingToSelectForPrimaryPriorFamily =
      selected.billingType === BILLING_TYPE.INDIVIDUAL &&
      !dependentIdForSwitch &&
      selectedOfferings.some(
        (item) => item.billingType === BILLING_TYPE.FAMILY
      );

    if (isFamilyOfferingToSelect || isGroupOfferingToSelect) {
      newSelectedOfferings = [
        {
          ...selected,
          offeringType: OFFERING_TYPE_PRIMARY,
          dependent: null,
        },
      ];
    } else if (isIndividualOfferingToSelectForPrimaryPriorFamily) {
      newSelectedOfferings = [
        {
          ...selected,
          offeringType: OFFERING_TYPE_PRIMARY,
          dependent: null,
        },
      ];
      dependents.forEach((item) => {
        newSelectedOfferings.push({
          ...selected,
          offeringType: OFFERING_TYPE_DEPENDENT,
          dependent: {
            clientId: item.clientId,
            firstName: item.firstName,
            middleName: item.middleName,
            lastName: item.lastName,
          },
        });
      });
    } else if (isIndividualOfferingToSelectForPrimary) {
      newSelectedOfferings = selectedOfferings.map((item) => {
        if (item.offeringType === OFFERING_TYPE_DEPENDENT) return { ...item };
        return {
          ...selected,
          offeringType: OFFERING_TYPE_PRIMARY,
          dependent: null,
        };
      });
    } else if (isIndividualOfferingToSelectForDependent) {
      newSelectedOfferings = selectedOfferings.map((item) => {
        if (item.dependent?.clientId === dependentIdForSwitch) {
          return {
            ...selected,
            offeringType: OFFERING_TYPE_DEPENDENT,
            dependent: {
              clientId: item.dependent.clientId,
              firstName: item.dependent.firstName,
              middleName: item.dependent.middleName,
              lastName: item.dependent.lastName,
            },
          };
        }
        return { ...item };
      });
    }

    dispatch(setSelectedOfferings(newSelectedOfferings));
    closeHandler();
  };

  // TODO: Disable update btn if the user has not selected new offering
  const disableUpdateBtn = false;

  return (
    <Modal
      modalTitle={title ? title : ''}
      maxWidth="max-w-xl"
      marginTop="mt-32"
      hideHeader={true}
      showCloseButton={false}
      closeHandler={closeHandler}
      removeBodyBottomPadding={true}
      restrictModalHeight={true}
      show
    >
      <div className="mt-4">
        <h6 className="text-xl text-titleColor mb-1">
          {isIndividual
            ? getTextFromLangDict(langDict, {
                key: '_SWITCH_MEMBERSHIP_PLAN_FOR',
                groupCode,
                tenantCode,
              })?.replace('{{name}}', primaryIndividualFullName)
            : getTextFromLangDict(langDict, {
                key: '_SWITCH_MEMBERSHIP_PLAN',
                groupCode,
                tenantCode,
              })}
        </h6>
        {/* <p className="body-text text-sm mb-6">
          {!switchOfferingForDependent && dependentPresent
            ? 'As you are switching the offering for primary member, those for dependents will change accordingly.'
            : 'Your checkout amount will change accordingly.'}
        </p> */}
        <p className="body-text text-sm mb-6">
          {getTextFromLangDict(langDict, {
            key: '_SWITCH_MEMBERSHIP_PLAN_NOTE',
            groupCode,
            tenantCode,
          })}
        </p>

        <div className="flex flex-wrap items-center -mx-2">
          {localOfferings?.map((service) => (
            <div
              key={service.tenantOfferingId}
              className="mb-5 w-full md:w-1/2 px-2"
            >
              <input
                id={`plan-radio-${service.tenantOfferingId}`}
                type="radio"
                name="pricing-plan-radio"
                value={service.tenantOfferingId}
                checked={service.isChecked}
                onChange={() => onSelectOffering(service.tenantOfferingId)}
                className="hidden"
              />
              <label
                htmlFor={`plan-radio-${service.tenantOfferingId}`}
                className={`relative flex flex-col justify-center items-center cursor-pointer body-text font-sm px-4 py-4 rounded ${
                  service.isChecked
                    ? 'bg-holyCityMedSecondary text-titleColor'
                    : 'bg-bgColor text-bodyColor'
                }`}
                style={{ minHeight: '7.6rem' }}
              >
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className={`text-white absolute ${
                    !service.isChecked && 'hidden'
                  }`}
                  style={{ top: '8px', left: '8px' }}
                />
                <p className="text-sm text-center">{service.name}</p>
                <p className="text-xl font-bold">
                  {formatCurrency(service.price, null, 2)}&nbsp;
                </p>
                <p className="text-xs font-normal">
                  per {service.billingType?.toLowerCase()}{' '}
                  {BILLING_INTERVAL[service.billingInterval]?.toLowerCase()}
                </p>
                <p className="text-xs mt-1">
                  {service.registrationFee && service.registrationFee > 0 ? (
                    <>
                      Reg Fee:{' '}
                      <strong>
                        {formatCurrency(service.registrationFee, null, 0)}
                      </strong>
                    </>
                  ) : null}
                </p>
              </label>
            </div>
          ))}
        </div>

        <div className="flex justify-end mt-10 sticky bottom-0 bg-white pb-4 pt-2">
          <button
            className="small-button px-4 py-3 mr-4 bg-transparent text-dedicatedDpcSecondary hover:bg-gray-300"
            onClick={closeHandler}
            type="button"
          >
            {getTextFromLangDict(langDict, {
              key: '_CANCEL',
              groupCode,
              tenantCode,
            })}
          </button>
          <button
            className={`small-button px-10 py-3 bg-dedicatedDpcSecondary hover:bg-dedicatedDpcHovered ${
              disableUpdateBtn && 'btn-disabled'
            }`}
            type="button"
            onClick={onSubmit}
          >
            {getTextFromLangDict(langDict, {
              key: '_UPDATE',
              groupCode,
              tenantCode,
            })}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default SwitchOfferingModal;
