import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// export default function Modal({modalTitle, showModal, setShowModal, children}) {
export class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: props.show || false,
    };
  }
  toggle = () => {
    this.setState(
      {
        showModal: !this.state.showModal,
      },
      () => {
        if (!this.state.showModal && this.props?.reset) {
          this.props.reset();
        }
      }
    );
  };
  render() {
    const {
      maxWidth,
      marginTop,
      hideHeader = false,
      showCloseButton = false,
      removeBodyBottomPadding = false,
      restrictModalHeight = false,
    } = this.props;
    return (
      <>
        {this.state.showModal ? (
          <>
            <div className="justify-center items-start flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div
                className={`relative w-full sm:w-11/12 md:w-9/12 lg:w-1/2 my-4 mx-auto px-5 md:px-0 ${
                  maxWidth ? maxWidth : 'max-w-3xl'
                } ${marginTop ? marginTop : ''}`}
              >
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  {!hideHeader && (
                    <div className="flex items-start justify-between py-4 px-4 md:px-6 border-b border-solid border-gray-300 rounded-t">
                      <h5 className="font-heebo text-2xl font-semibold text-primary">
                        <FontAwesomeIcon
                          icon={this.props.iconName}
                          className="text-primary mr-3"
                        />
                        {/* {modalTitle} */}
                        {this.props.modalTitle}
                      </h5>
                      <button
                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        // onClick={() => setShowModal(false)}
                        onClick={this.toggle}
                      >
                        <span className="bg-transparent text-titleColor opacity-5 h-6 w-6 text-2xl md:text-3xl block outline-none focus:outline-none hover:text-extended">
                          ×
                        </span>
                      </button>
                    </div>
                  )}
                  {/*body*/}
                  <div
                    className={`relative pt-4 px-4 md:px-6 flex-auto ${
                      !removeBodyBottomPadding && 'pb-4'
                    } ${restrictModalHeight && 'max-modal-body-height'}`}
                  >
                    <div className="my-2">{this.props.children}</div>
                  </div>
                  {showCloseButton && (
                    <button
                      className="mid-button bg-dedicatedDpcSecondary hover:bg-dedicatedDpcHovered  mb-5 block mx-auto"
                      onClick={() => {
                        this.toggle();
                        if (typeof this.props.closeHandler === 'function') {
                          this.props.closeHandler();
                        }
                      }}
                    >
                      {' '}
                      Ok
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </>
    );
  }
}

export default Modal;
