import React from 'react';
import { BANK_ACCOUNT_TYPES } from '../constants/payment';

const AccountTypeSelect = ({ value, onChange, onBlur, isDisabled = false }) => {
  return (
    <select
      id="accountType"
      placeholder="Account Type"
      className="w-full py-3 px-4 text-base rounded-sm border-2 border-gray-200 focus:border-primary focus:outline-none disabled:bg-white"
      value={value}
      onChange={(event) => onChange(event.target.value)}
      onBlur={onBlur}
      disabled={isDisabled}
    >
      <option value="" disabled>
        Select Account Type
      </option>
      {BANK_ACCOUNT_TYPES.map((item) => (
        <option value={item.value} key={item.value}>
          {item.text}
        </option>
      ))}
    </select>
  );
};

export default AccountTypeSelect;
