import React from 'react';
import Fade from 'react-reveal/Fade';
import { parseHtml } from '../../util';
import facebookImg from '../../assets/images/facebook.png';
import TwitterImg from '../../assets/images/twitter.png';
import LinkedInImg from '../../assets/images/linkedin.png';

const Team = ({ team }) => {
  const displayTeam =
    team &&
    team.map((item, index) => {
      return (
        <React.Fragment key={index}>
          <Fade>
            <div className='flex flex-wrap md:-mx-8 mb-20'>
              <div className='w-full md:w-2/5 md:px-8 mb-8 md:mb-0'>
                <div className='rounded-lg shadow overflow-hidden'>
                  <img
                    className='w-full rounded-sm'
                    src={item.metadata && item.metadata.image.url}
                    alt='team-image'
                  />
                  <div className='my-6 flex justify-center -mx-4'>
                    <div>
                      {item.metadata.facebook ? (
                        <a
                          href={item.metadata.facebook}
                          target='_blank'
                          className='outline-none  inline-block px-4'
                        >
                          <img
                            src={facebookImg}
                            className='w-10 inline-block'
                            alt='facebook-link'
                          />
                        </a>
                      ) : null}

                      {item.metadata.twitter ? (
                        <a
                          href={item.metadata.twitter}
                          target='_blank'
                          className='outline-none  inline-block px-4'
                        >
                          <img
                            src={TwitterImg}
                            className='w-10 inline-block'
                            alt='twitter-link'
                          />
                        </a>
                      ) : null}

                      {item.metadata.linkedin ? (
                        <a
                          href={item.metadata.linkedin}
                          target='_blank'
                          className='outline-none  inline-block px-4'
                        >
                          <img
                            src={LinkedInImg}
                            className='w-10 inline-block'
                            alt='linkedin-link'
                          />
                        </a>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-3/5 md:px-8'>
                <div className='w-full'>
                  <h4 className='font-heebo font-bold text-3xl'>
                    {item.title}
                  </h4>
                  <p className='font-heebo text-lg text-bodyColor mb-6'>
                    {item.metadata.designation}
                  </p>
                  <div
                    className='body-text'
                    dangerouslySetInnerHTML={{
                      __html: parseHtml(item.content),
                    }}
                  />
                </div>
              </div>
            </div>
          </Fade>
        </React.Fragment>
      );
    });

  return (
    <div className='container mx-auto px-4 overflow-hidden'>
      <div className='divider'></div>
      <h3 className='heading'>Our Team</h3>
      {displayTeam}
    </div>
  );
};

export default Team;
