/**
 * @author Slesha Tuladhar <slesha@noveltytechnology.com>
 */

import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import cloneDeep from 'lodash/cloneDeep';
import { isEmpty } from 'lodash';

export const _debounce = (func, wait) => {
  return debounce(func, wait);
};

export const _isEqual = (value1, value2) => {
  return isEqual(value1, value2);
};

export const _uniqBy = (array, iteratee) => {
  return uniqBy(array, iteratee);
};

export const _sortBy = (array, iteratee) => {
  return sortBy(array, iteratee);
};

export const _cloneDeep = (data) => {
  return cloneDeep(data);
};

export const _isEmpty = (data) => {
  return isEmpty(data);
};
