import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { checkIfObjHasAllEmptyOrNullValues } from '../../util';

import { countryCode } from '../../constants/countryCode';
import FormGoogleAutocompleteInput from './FormGoogleAutocompleteInput';
import FormTextField from './FormTextField';

const FormAddressInput = ({ isRequired = false }) => {
  const { setValue, watch } = useFormContext();

  const onPopulateFields = (addr) => {
    if (checkIfObjHasAllEmptyOrNullValues(addr)) return;

    setValue('addressLine1', addr.addressLine1?.trim());
    setValue('addressLine2', addr.addressLine2?.trim());
    setValue('city', addr.city);
    setValue('state', addr.state);
    setValue('zip', addr.zip);
    setValue('lat', addr.lat);
    setValue('lng', addr.lng);
  };

  const selectedCountryCode = watch('countryCode') || countryCode.USA;
  const isUSA = selectedCountryCode === countryCode.USA;
  const isCanada = selectedCountryCode === countryCode.CANADA;

  const getAddressLine1Label = () => {
    if (isUSA) return 'Address Line 1';
    return 'Street Address/P.O Box';
  };

  const getAddressLine2Label = () => {
    if (isUSA) return 'Address Line 2';
    return 'Building/Unit';
  };

  const getStateLabel = () => {
    if (isUSA) return 'State';
    return 'Province/Region';
  };

  const getZipLabel = () => {
    if (isUSA) return 'Zip';
    return 'Postal/Pin Code';
  };

  return (
    <>
      <div className="flex flex-wrap -mx-4">
        <div className="w-full md:w-1/2 px-4">
          {isUSA || isCanada ? (
            <FormGoogleAutocompleteInput
              selectedCountryCode={selectedCountryCode}
              icon={faMapMarkerAlt}
              label={
                isRequired
                  ? `${getAddressLine1Label()} *`
                  : getAddressLine1Label()
              }
              onPopulateFields={onPopulateFields}
            />
          ) : (
            <FormTextField
              name="addressLine1"
              label={
                isRequired
                  ? `${getAddressLine1Label()} *`
                  : getAddressLine1Label()
              }
            />
          )}
        </div>
        <div className="w-full md:w-1/2 px-4">
          <FormTextField name="addressLine2" label={getAddressLine2Label()} />
        </div>
      </div>
      <div className="flex flex-wrap -mx-4">
        <div className="w-full md:w-1/2 px-4">
          <FormTextField name="city" label={isRequired ? 'City *' : 'City'} />
        </div>
        <div className=" w-full md:w-1/2 px-4">
          <div className="flex -mx-4">
            <div className="w-1/2 px-4">
              <FormTextField
                name="state"
                label={isRequired ? `${getStateLabel()} *` : getStateLabel()}
              />
            </div>
            <div className="w-1/2 px-4">
              <FormTextField
                name="zip"
                label={isRequired ? `${getZipLabel()} *` : getZipLabel()}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

FormAddressInput.defaultProps = {
  isRequired: false,
  allDisabled: false,
};

export default FormAddressInput;
