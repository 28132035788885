import React, { useState } from 'react';
import withSizes from 'react-sizes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMobileAlt,
  faMapMarkerAlt,
  faArrowRight
} from '@fortawesome/free-solid-svg-icons';
import {
  faBuilding,
  faIdCard,
  faUser,
  faEnvelope
} from '@fortawesome/free-regular-svg-icons';
import { useForm } from "react-hook-form";
import {
  MaskedPhoneInput,
  MDInput,
  PlaceAutocomplete,
  ButtonLoader,
  MaskedTaxIdInput,
  MaskedNpiInput
} from '../../common';
import * as vitafyUtils from '../../util/vitafyUtil';
import commonConstants from "../../constants/commonConstant";

const ProviderInformation = (props) => {

  const {
    register,
    errors,
    setValue,
    clearError,
    handleSubmit,
    getValues,
  } = useForm();

  const {
    data,
    address,
    formRef,
    chosenPricingPlan
  } = props;

  const [enableContinueBtn, setEnableContinueBtn] = useState(true);

  const formBuilder = () => {
    register({ name: "providerName" }, { required: true });
    register({ name: "taxId" },
      {
        validate: (value) => {
          if (!value) return true;
          return !value.includes("_");
        },
        required: false
      });
    register({ name: "npi" },
      {
        validate: (value) => {
          if (!value) return true;
          return !value.includes("_");
        },
        required: false
      });
    register(
      { name: "email" },
      {
        required: false,
        pattern: /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/i,
      }
    );
    register({ name: "phone" },
      {
        validate: (value) => {
          if (!value) return true;
          return !value.includes("_");
        },
        required: true
      }
    );
    register({ name: "contactFirstName" }, { required: true });
    register({ name: "contactLastName" }, { required: true });
    register({ name: "addressLine1" });
    register({ name: "addressLine2" });
    register({ name: "city" });
    register({ name: "state" });
    register({ name: "zip" }, { maxLength: 5, required: false });
    register({ name: "lat" });
    register({ name: "lng" });
    setValue([
      { providerName: data.providerName },
      { taxId: data.taxId },
      { npi: data.npi },
      { email: data.email ? data.email : "" },
      { phone: data.phone },
      { contactFirstName: data.contactFirstName },
      { contactLastName: data.contactLastName },
      { addressLine1: address ? address.addressLine1 : "" },
      { addressLine2: address ? address.addressLine2 : "" },
      { city: address ? address.city : "" },
      { state: address ? address.state : "" },
      { zip: address ? address.zip : "" },
      { lat: address?.lat ? address.lat : null },
      { lng: address?.lng ? address.lng : null },
    ]);
  };

  React.useEffect(() => {
    formBuilder();
  }, []);

  const handleInputChange = (e, field) => {
    let value = e.target ? e.target.value : e;
    if ((e.target ? e.target.validity.valid : e) || value === "") {
      setValue(field, value);

      if (commonConstants.ADDRESS_FIELDS.indexOf(field) > -1) {
        address[field] = value;
      } else {
        data[field] = value;
      }
      clearError(field);
      setEnableContinueBtn(true);
    }
  };

  const setGoogleAutocompleteAddress = (addr) => {
    for (const field in addr) {
      if (addr.hasOwnProperty(field)) {
        address[field] = addr[field];
        const element = addr[field];
        setValue(field, element);
        clearError(field);
      }
      setEnableContinueBtn(true);
    }
  };

  const formSubmit = (formValues) => {
    formValues.phone = formValues.phone
      ? vitafyUtils.unmaskPhone(formValues.phone)
      : null;
    props.submit(formValues);
    setEnableContinueBtn(false);
  }

  const isBasicPlan = chosenPricingPlan.plan && chosenPricingPlan.plan.toLowerCase() === 'basic';

  return (
    <div>

      <h4 className={`font-heebo text-titleColor font-bold text-xl mt-8 mb-5 md:mt-10 md:mb-6`}>Provider Information</h4>

      <form ref={formRef} onSubmit={handleSubmit(formSubmit)}>
        <MDInput
          id="providerName"
          label="Provider Name *"
          icon={!props.isMobile ? faBuilding : null}
          name="providerName"
          value={data?.providerName ? data.providerName : ""}
          inputChange={(e) => handleInputChange(e, "providerName")}
          error={errors.providerName}
        />
        <div className='flex -mx-4'>
          <div className='w-1/2 px-4'>
            <MaskedTaxIdInput
              input={data?.taxId ? data.taxId : null}
              setInput={(e) => handleInputChange(e, "taxId")}
              name="taxId"
              label="Tax ID"
              icon={!props.isMobile ? faIdCard : null}
              error={errors.taxId}
            />
          </div>
          <div className='w-1/2 px-4'>
            <MaskedNpiInput
              input={data?.npi ? data.npi : null}
              setInput={(e) => handleInputChange(e, "npi")}
              name="npi"
              label="NPI"
              icon={!props.isMobile ? faIdCard : null}
              error={errors.npi}
            />
          </div>
        </div>
        <div className='flex flex-wrap -mx-4'>
          <div className='w-full lg:w-1/2  px-4'>
            <MDInput
              id="contactFirstName"
              label="Contact First Name *"
              icon={!props.isMobile ? faUser : null}
              name="contactFirstName"
              value={data?.contactFirstName ? data.contactFirstName : ""}
              inputChange={(e) => handleInputChange(e, "contactFirstName")}
              error={errors.contactFirstName}
            />
          </div>
          <div className='w-full lg:w-1/2 px-4'>
            <MDInput
              id="contactLastName"
              label="Contact Last Name *"
              name="contactLastName"
              value={data?.contactLastName ? data.contactLastName : ""}
              inputChange={(e) => handleInputChange(e, "contactLastName")}
              error={errors.contactLastName}
            />
          </div>
        </div>
        <div className='flex flex-wrap -mx-4'>
          <div className='w-full lg:w-3/5  px-4'>
            <MDInput
              id="email"
              label="Email"
              icon={!props.isMobile ? faEnvelope : null}
              name="email"
              value={data?.email ? data.email : ""}
              inputChange={(e) => handleInputChange(e, "email")}
              error={errors.email}
            />
          </div>
          <div className='w-full lg:w-2/5  px-4'>
            <MaskedPhoneInput
              input={data?.phone ? data.phone : null}
              setInput={(e) => handleInputChange(e, "phone")}
              name="phone"
              label="Phone *"
              icon={!props.isMobile ? faMobileAlt : null}
              error={errors.phone}
            />
          </div>
        </div>
        <div className='flex flex-wrap -mx-4'>
          <div className='w-full lg:w-1/2 px-4'>
            <PlaceAutocomplete
              id="individual-address"
              addressLine1={address?.addressLine1 ? address.addressLine1 : ""}
              setGoogleAutocompleteAddress={(e) =>
                setGoogleAutocompleteAddress(e)
              }
              label="Address Line 1"
              icon={!props.isMobile ? faMapMarkerAlt : null}
            />
          </div>
          <div className='w-full lg:w-1/2 px-4'>
            <MDInput
              id="addressLine2"
              label="Address Line 2"
              name="addressLine2"
              value={address && address.addressLine2 ? address.addressLine2 : ""}
              inputChange={(e) => handleInputChange(e, "addressLine2")}
              error={errors.addressLine2}
            />
          </div>
        </div>
        <div className='flex -mx-4'>
          <div className='w-1/2 px-4'>
            <MDInput
              id="city"
              label="City"
              name="city"
              value={address && address.city ? address.city : ""}
              inputChange={(e) => handleInputChange(e, "city")}
              error={errors.city}
            />
          </div>
          <div className='w-1/2 px-4'>
            <div className='flex -mx-4'>
              <div className='w-1/2 px-4'>
                <MDInput
                  id="state"
                  label="State"
                  name="state"
                  value={address && address.state ? address.state : ""}
                  inputChange={(e) => handleInputChange(e, "state")}
                  error={errors.state}
                />
              </div>
              <div className='w-1/2 px-4'>
                <MDInput
                  id="zip"
                  label="Zip"
                  name="zip"
                  type='number'
                  value={address && address.zip ? address.zip : ""}
                  inputChange={(e) => handleInputChange(e, "zip")}
                  error={errors.zip}
                />
              </div>
            </div>
          </div>
        </div>
        <button
          className={`mid-button float-right w-full md:w-auto flex justify-center md:justify-end rounded-md mt-5 
            ${((props.isProviderInfoSubmitting || props.step === 2) && !enableContinueBtn) && 'btn-disabled'}`}
          type='submit'
        >
          <div className='flex items-center'>
            {props.isProviderInfoSubmitting ? (
              <div className='inline mr-2'>

                <ButtonLoader />
              </div>
            ) : null}
            {isBasicPlan ? 'Register' : 'Continue'}
            <FontAwesomeIcon
              icon={faArrowRight}
              className='text-white ml-3'
            />
          </div>
        </button>
      </form>
    </div>
  );
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 768,
})

export default withSizes(mapSizesToProps)(ProviderInformation);