import * as httpService from './httpService';
import APICONSTANTS from '../constants';
import langConstant from '../constants/langConstant'

export function fetchLangDictionary(lang = langConstant.EN, queryParams) {
  return httpService.get(`${APICONSTANTS.LANG_DICTIONARY}/${lang}/tenant`, queryParams)
    .then(response => {
      return response.data;
    }).catch(error => {
      throw error;
    });
}