import React, { useContext } from 'react';
import commonConstants from '../../../constants/commonConstant';
import { tenantCodeEnum } from '../../../constants/tenantCodeConstant';
import { store } from '../../../store';
import { getTextFromLangDict } from '../../../util';

const { TENANT_SETTINGS_CODE, TENANT_SETTINGS_STATUS, SKIP_PAYMENT_FOR_NOW } =
  commonConstants;

const PaymentMethod = (props) => {
  const {
    data,
    changePaymentMethod,
    tenantSettings,
    includeBank = false,
    includeCash = false,
    includeOther = false,
    whiteBg = true,
  } = props;
  const { paymentMethod } = data.payment;

  // Context Store
  const state = useContext(store);
  const { globalState } = state;
  const { langDict, groupCode, tenantCode } = globalState;

  const cardSetting = tenantSettings?.billings?.find(
    (item) => item.code === TENANT_SETTINGS_CODE.CARD
  );
  const bankSetting = tenantSettings?.billings?.find(
    (item) => item.code === TENANT_SETTINGS_CODE.BANKING
  );
  const cashSetting = tenantSettings?.billings?.find(
    (item) => item.code === TENANT_SETTINGS_CODE.CASH
  );

  const otherSetting = tenantSettings?.billings?.find(
    (item) => item.code === TENANT_SETTINGS_CODE.OTHERS
  );

  const showSkipForNow = tenantCode === tenantCodeEnum.IHS;

  return (
    <>
      <h5 className="dedicated-form-title mb-6">
        {getTextFromLangDict(langDict, {
          key: '_SELECT_PAYMENT_METHOD',
          groupCode,
          tenantCode,
        })}
      </h5>

      {cardSetting?.status === TENANT_SETTINGS_STATUS.ENABLED && (
        <button
          data-cy="card-payment"
          className={`mid-radio-btn py-4 px-5 ${!whiteBg && 'bg-white'} ${
            paymentMethod === 'Card' && 'bg-holyCityMedSecondary font-bold'
          }`}
          onClick={() => changePaymentMethod('Card')}
          type="button"
        >
          {getTextFromLangDict(langDict, {
            key: '_PAY_WITH_CARD',
            groupCode,
            tenantCode,
          })}
        </button>
      )}
      {includeBank &&
        bankSetting?.status === TENANT_SETTINGS_STATUS.ENABLED && (
          <button
            data-cy="bank-payment"
            className={`mid-radio-btn py-4 px-5 ml-4 ${
              !whiteBg && 'bg-white'
            } ${
              paymentMethod === 'Bank' && 'bg-holyCityMedSecondary font-bold'
            }`}
            onClick={() => changePaymentMethod('Bank')}
          >
            {getTextFromLangDict(langDict, {
              key: '_PAY_WITH_BANK',
              groupCode,
              tenantCode,
            })}
          </button>
        )}
      {includeCash &&
        cashSetting?.status === TENANT_SETTINGS_STATUS.ENABLED && (
          <button
            data-cy="cash-payment"
            className={`mid-radio-btn py-4 px-5 ml-4 ${
              !whiteBg && 'bg-white'
            } ${
              paymentMethod === 'Cash' && 'bg-holyCityMedSecondary font-bold'
            }`}
            onClick={() => changePaymentMethod('Cash')}
            type="button"
          >
            <span className="hidden md:inline">{cashSetting.name}</span>
            <span className="inline md:hidden">
              {' '}
              {getTextFromLangDict(langDict, {
                key: '_PAY_WITH_CASH',
                groupCode,
                tenantCode,
              })}
            </span>
          </button>
        )}

      {includeOther &&
        otherSetting?.status === TENANT_SETTINGS_STATUS.ENABLED && (
          <button
            className={`mid-radio-btn py-4 px-5 ml-4 ${
              !whiteBg && 'bg-white'
            } ${
              paymentMethod === 'Other' && 'bg-holyCityMedSecondary font-bold'
            }`}
            onClick={() => changePaymentMethod('Other')}
            type="button"
          >
            {getTextFromLangDict(langDict, {
              key: '_PAY_WITH_OTHERS',
              groupCode,
              tenantCode,
            })}
          </button>
        )}
      {showSkipForNow && (
        <button
          className={`mid-radio-btn py-4 px-5 ml-4 ${!whiteBg && 'bg-white'} ${
            paymentMethod === SKIP_PAYMENT_FOR_NOW &&
            'bg-holyCityMedSecondary font-bold'
          }`}
          onClick={() => changePaymentMethod(SKIP_PAYMENT_FOR_NOW)}
          type="button"
        >
          <span className="inline">
            {getTextFromLangDict(langDict, {
              key: '_SKIP_FOR_NOW',
              groupCode,
              tenantCode,
            })}
          </span>
        </button>
      )}
    </>
  );
};

export default PaymentMethod;
