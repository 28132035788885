/**
 * @author Slesha Tuladhar <slesha@noveltytechnology.com>
 */

import React from 'react';
import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const ToastMessage = ({ type, message }) => {
  return (
    <div className='flex items-top'>
      <FontAwesomeIcon
        icon={type === 'success' ? faCheckCircle : faExclamationCircle}
        className='mr-2 mt-1'
      />
      <p className='mb-0'>{message}</p>
    </div>
  );
};

export const showToast = (type = 'success', message) => (
  <div>
    {type === 'success' &&
      toast.info(<ToastMessage type={type} message={message} />, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      })}
    {type === 'warning' &&
      toast.warning(<ToastMessage type={type} message={message} />, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      })}
    {type === 'error' &&
      toast.error(<ToastMessage type={type} message={message} />, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      })}

    {/* <ToastContainer /> */}
  </div>
);
