import React from 'react';

const ScrollToBottomDetection = ({
  containerClassName,
  containerStyle,
  children,
  onScrollBottomDetection,
}) => {
  const handleScroll = (event) => {
    const bottom =
      event.target.scrollHeight - event.target.scrollTop <=
      event.target.clientHeight + 50; // 50 is the offset
    if (bottom) {
      onScrollBottomDetection();
    }
  };

  return (
    <div
      className={`${containerClassName ? containerClassName : ''}`}
      style={containerStyle}
      onScroll={handleScroll}
    >
      {children}
    </div>
  );
};

export default ScrollToBottomDetection;
