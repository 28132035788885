import React, { useContext } from 'react';

import { store } from '../../store';

const Banner = ({ displayName = '', tagLine, locationCount }) => {
  const state = useContext(store);
  const { langDict, primaryLocation } = state.globalState;

  const threeCols = primaryLocation?.configuration?.services?.length === 3;

  const renderedDisplayName =
    displayName ||
    `${langDict?._WELCOME_TO} ${primaryLocation?.businessName?.toUpperCase()}`;

  return (
    <div
      className={`bg-dedicatedDpcPrimary 
      ${
        locationCount
          ? 'hcm-landing-banner'
          : `${threeCols ? 'sim-dedicated-lp-banner' : 'dedicated-lp-banner'}`
      } `}
    >
      <h1 className="dedicated-lp-heading">{renderedDisplayName}</h1>
      <h4 className="dedicated-lp-tagline block"> {tagLine}</h4>
      {locationCount && (
        <>
          <p className="body-text text-white text-center text-base mt-6 mb-2">
            {langDict?._SELECT_LOCATION}
          </p>
          <p className="body-text text-white text-center text-sm mt-2">
            ( {langDict?._LOCATION_INCLUDED} )
          </p>
        </>
      )}
    </div>
  );
};

export default Banner;
