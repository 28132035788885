import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faCalendarAlt,
  faIdCardAlt,
  faEnvelope,
  faMobileAlt,
  faMapMarkerAlt,
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons';
import { MDInput, MaskedDateInput, MaskedPhoneInput, PlaceAutocomplete } from '../../common';
import * as momentUtil from '../../util/momentUtil';

import { useForm } from 'react-hook-form';

const PriceListForm = (props) => {
  const { register, errors, setValue, clearError, handleSubmit, } = useForm();
  const { data, address, changeCheckoutReady } = props;

  const formBuilder = () => {
    register({ name: 'firstName' }, { required: true });
    register({ name: 'lastName' }, { required: true });
    register(
      { name: 'dob' },
      {
        validate: (value) =>
          data && data.dob
            ? momentUtil.momentIsValid(data.dob) &&
            momentUtil.momentIsSameorBefore(data.dob)
            : true,
        required: true,
      }
    );
    register({ name: 'last4SSN' }, {  validate: (value) => (!value || (value && value.length === 4)) });
    register(
      { name: "email" },
      {
        required: true,
        pattern: /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/i,
      }
    );
    register({ name: "phone" });
    register({ name: "addressLine1" });
    register({ name: "addressLine2" });
    register({ name: "city" });
    register({ name: "state" });
    register({ name: "zip" });
    register({ name: "lat" });
    register({ name: "lng" });
    register({ name: "note" });
    setValue([
      { firstName: data.firstName },
      { lastName: data.lastName },
      { dob: data.dob },
      { email: data.email },
      { last4SSN: data.last4SSN },
      { last4SSN: data.email },
      { last4SSN: data.phone },
      { addressLine1: address ? address.addressLine1 : "" },
      { addressLine2: address ? address.addressLine2 : "" },
      { city: address ? address.city : "" },
      { state: address ? address.state : "" },
      { zip: address ? address.zip : "" },
      { lat: address?.lat ? address.lat : null },
      { lng: address?.lng ? address.lng : null },
      { note: data.note ? data.note : '' }
    ]);
  };

  React.useEffect(() => {
    formBuilder();
  }, []);

  const handleInputChange = (e, field) => {
    let value = e.target ? e.target.value : e;
    if ((e.target ? e.target.validity.valid : e) || value == '') {
      setValue(field, value);
      data[field] = value;
      clearError(field);
    }
  };

  const setGoogleAutocompleteAddress = (addr) => {
    for (const field in addr) {
      if (addr.hasOwnProperty(field)) {
        address[field] = addr[field];
        const element = addr[field];
        setValue(field, element);
        clearError(field);
      }
    }
  };

  const formSubmit = () => {
    changeCheckoutReady();
  };

  return (
    <form onSubmit={handleSubmit(formSubmit)}>
      <div className="flex flex-wrap md:-mx-5 mb-1 mt-8">
        {/* First Name */}
        <div className="w-full md:w-1/2 md:px-5 mb-0 md:mb-0">
          <MDInput
            id="firstName"
            label="First Name"
            icon={faUser}
            name="firstName"
            value={data?.firstName ? data.firstName : ''}
            inputChange={(e) => handleInputChange(e, 'firstName')}
            error={errors.firstName}
          />
        </div>
        {/* Last Name */}
        <div className="w-full md:w-1/2 md:px-5">
          <MDInput
            id="lastName"
            label="Last Name"
            name="lastName"
            value={data?.lastName ? data.lastName : ''}
            inputChange={(e) => handleInputChange(e, 'lastName')}
            error={errors.lastName}
          />
        </div>
      </div>

      <div className="flex flex-wrap md:-mx-5 mb-1 mt-3">
        {/* Date */}
        <div className="w-full md:w-1/2 md:px-5 mb-0 md:mb-0">
          <MaskedDateInput
            id='date'
            icon={faCalendarAlt}
            input={
              data && data.dob
                ? momentUtil.momentIsValid(data.dob)
                  ? momentUtil.momentViewFormat(data.dob)
                  : data.dob
                : null
            }
            setInput={(e) => handleInputChange(e, 'dob')}
            name='dob'
            label='Date of Birth'
            error={errors.dob}
          />
        </div>
        {/* Last Four SSN */}
        <div className="w-full md:w-1/2 md:px-5">
          <MDInput
            id="last4ssn"
            label="Last Four SSN"
            name="last4ssn"
            icon={faIdCardAlt}
            value={data?.last4SSN ? data.last4SSN : ''}
            inputChange={(e) => handleInputChange(e, 'last4SSN')}
            error={errors.last4SSN}
          />
        </div>
      </div>

      <div className="flex flex-wrap md:-mx-5 mb-1 mt-3">
        {/* Email */}
        <div className="w-full md:w-1/2 md:px-5 mb-0 md:mb-0">
          <MDInput
            id="email"
            label="Email"
            name="email"
            icon={faEnvelope}
            value={data?.email ? data.email : ''}
            inputChange={(e) => handleInputChange(e, 'email')}
            error={errors.email}
          />
        </div>
        {/* Phone */}
        <div className="w-full md:w-1/2 md:px-5">
          <MaskedPhoneInput
            input={data && data.phone ? data.phone : null}
            setInput={(e) => handleInputChange(e, 'phone')}
            name='phone'
            label='Phone'
            icon={faMobileAlt}
          />
        </div>
      </div>

      <div className="flex flex-wrap md:-mx-5 mb-2">
        {/* Address Line 1 */}
        <div className="w-full md:w-1/2 md:px-5 mb-3 md:mb-0">
          <PlaceAutocomplete
            id="address"
            addressLine1={address?.addressLine1 ? address.addressLine1 : ''}
            setGoogleAutocompleteAddress={(e) =>
              setGoogleAutocompleteAddress(e)
            }
            label="Address Line 1"
            icon={faMapMarkerAlt}
          />
        </div>
        {/* Address Line 2 */}
        <div className="w-full md:w-1/2 md:px-5">
          <MDInput
            id="addressLine2"
            label="Address Line 2"
            name="addressLine2"
            value={address && address.addressLine2 ? address.addressLine2 : ""}
            inputChange={(e) => handleInputChange(e, "addressLine2")}
            error={errors.addressLine2}
          />
        </div>
      </div>

      <div className="flex flex-wrap md:-mx-5 mb-16 md:mb-0">
        {/* City */}
        <div className="w-full md:w-1/2 md:px-5 mb-3 md:mb-0">
          <MDInput
            id="city"
            label="City"
            name="city"
            value={address && address.city ? address.city : ""}
            inputChange={(e) => handleInputChange(e, "city")}
            error={errors.city}
          />
        </div>

        <div className="w-full md:w-1/2 md:px-5">
          <div className="h-10 flex -mx-5">
            {/* State */}
            <div className="w-1/2 px-5">
              <MDInput
                id="state"
                label="State"
                name="state"
                value={address && address.state ? address.state : ""}
                inputChange={(e) => handleInputChange(e, "state")}
                error={errors.state}
              />
            </div>

            <div className="w-1/2 px-5">
              <MDInput
                id="zip"
                label="Zip"
                name="zip"
                value={address && address.zip ? address.zip : ""}
                inputChange={(e) => handleInputChange(e, "zip")}
                error={errors.zip}
              />
            </div>
          </div>
        </div>
      </div>

      <MDInput
        id="note"
        label="Note"
        value={data?.note ? data.note : ''}
        inputChange={(e) => handleInputChange(e, 'note')}
        error={errors.note}
      />

      <div className='w-full flex justify-end mt-10'>
        <button
          className={`mid-button mt-6 mb-1`}
        >
          <div className="flex items-center">
            Continue
              <FontAwesomeIcon icon={faArrowRight} className="text-white ml-3" />
          </div>
        </button>
      </div>
    </form>
  );
};

export default PriceListForm;
