import React from 'react';
import NumberFormat from 'react-number-format';

const MaskedCardCVCInput = ({
  value,
  onChange,
  onBlur,
  isDisabled = false,
}) => {
  return (
    <NumberFormat
      className="w-full py-3 px-4 text-base rounded-sm border-2 border-gray-200 focus:border-titleColor focus:outline-none disabled:bg-white"
      format="####"
      placeholder="CVV"
      value={value}
      onValueChange={onChange}
      onBlur={onBlur}
      disabled={isDisabled}
    />
  );
};

export default MaskedCardCVCInput;
