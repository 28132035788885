import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import withSizes from 'react-sizes';
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons';

import {
  momentIsBeforeNMonth,
  momentIsValid,
  momentViewFormat,
} from '../../../../util/momentUtil';

import { MaskedDateInput } from '../../../../common';
import {
  selectBenefitStartDate,
  setBenefitStartDate,
} from '../../../../stores/membership';
import { store } from '../../../../store';
import { getTextFromLangDict } from '../../../../util';

const BenefitStartDateInput = ({ isMobile }) => {
  const dispatch = useDispatch();

  const startDate = useSelector(selectBenefitStartDate);

  // Context Store
  const state = useContext(store);
  const { globalState } = state;
  const { groupCode, langDict, tenantCode } = globalState;

  const onChange = (value) => {
    dispatch(setBenefitStartDate(value));
  };

  const isValid = startDate && !momentIsBeforeNMonth(startDate, 1);

  return (
    <div className="flex flex-wrap justify-between items-center">
      <h5 className="dedicated-form-title mb-8">
        {getTextFromLangDict(langDict, {
          key: '_WHEN_WOULD_YOU_LIKE_TO_START_YOUR_BENEFIT',
          groupCode,
          tenantCode,
        })}
      </h5>
      <div className="mb-2" style={{ width: '215px' }}>
        <MaskedDateInput
          id="benefit-start-date"
          input={
            startDate
              ? momentIsValid(startDate)
                ? momentViewFormat(startDate)
                : startDate
              : null
          }
          setInput={onChange}
          name="benefitStartDate"
          label="Start Date *"
          icon={!isMobile ? faCalendarCheck : null}
          error={!isValid}
        />
      </div>
    </div>
  );
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 768,
});

export default withSizes(mapSizesToProps)(BenefitStartDateInput);
