import React from 'react';
import {
  faBuilding,
  faUser,
  faEnvelope,
  faMobileAlt,
  faMapMarkerAlt,
} from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import * as momentUtil from '../../../util/momentUtil';
import { MaskedPhoneInput, PlaceAutocomplete, MDInput } from '../../../common';
import commonConstants from '../../../constants/commonConstant';
import * as vitafyUtils from '../../../util/vitafyUtil';

const ClientInfoCompanyForm = (props) => {
  const { register, errors, setValue, clearError, handleSubmit } = useForm();
  const { client, address } = props;
  const formBuilder = () => {
    register({ name: 'clientType' });
    register({ name: 'salesAgent' });
    register({ name: 'businessName' }, { required: true });
    register({ name: 'firstName' }, { required: true });
    register({ name: 'lastName' }, { required: true });
    register({ name: 'dob' });
    register({ name: 'last4SSN' }, { maxLength: 4 });
    register({ name: 'gender' });
    register({ name: 'addressLine1' });
    register({ name: 'addressLine2' });
    register({ name: 'city' });
    register({ name: 'state' });
    register({ name: 'zip' });
    register({ name: 'lat' });
    register({ name: 'lng' });
    register(
      { name: 'email' },
      {
        required: true,
        pattern: /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/i,
      }
    );
    register({ name: 'phone' });
    register({ name: 'totalMembers' });
    register({ name: 'effStartDate' });
    register({ name: 'note' });
    register({ name: 'referringAgent' })
    setValue([
      { clientType: client.clientType ? client.clientType : 'Company' },
      { salesAgent: client.salesAgent },
      { businessName: client.businessName },
      { firstName: client.firstName },
      { lastName: client.lastName },
      { last4SSN: client.last4SSN },
      { dob: client.dob },
      { gender: client.gender },
      { addressLine1: address ? address.addressLine1 : '' },
      { addressLine2: address ? address.addressLine2 : '' },
      { city: address ? address.city : '' },
      { state: address ? address.state : '' },
      { zip: address ? address.zip : '' },
      { lat: address?.lat ? address.lat : null },
      { lng: address?.lng ? address.lng : null },
      { phone: client.phone },
      { email: client.email ? client.email : '' },
      { totalMembers: client.totalMembers },
      { effStartDate: momentUtil.momentSaveFormat(client?.effStartDate) },
      { note: client.note },
      { referringAgent: client.referringAgent },
    ]);
  };
  React.useEffect(() => {
    formBuilder();
  }, []);
  React.useLayoutEffect(() => {
    formBuilder();
  }, [client.clientType]);
  const handleInputChange = (e, field) => {
    let value = e.target ? e.target.value : e;
    if ((e.target ? e.target.validity.valid : e) || value == '') {
      setValue(field, value);
      if (commonConstants.ADDRESS_FIELDS.indexOf(field) > -1) {
        address[field] = value;
      } else {
        client[field] = value;
      }
      clearError(field);
    }
  };
  const formSubmit = (formValues) => {
    formValues.agentClientId = client.agentClientId;
    formValues.id = client.id;
    formValues.address = {
      addressLine1: formValues.addressLine1,
      addressLine2: formValues.addressLine2 ? formValues.addressLine2 : null,
      city: formValues.city,
      state: formValues.state,
      zip: formValues.zip,
      lat: formValues.lat,
      lng: formValues.lng,
    };
    formValues.last4SSN = null;
    formValues.gender = null;
    formValues.dob = null;
    formValues.agentClientType =
      client.agentClientId || client.agent?.agentId
        ? formValues.clientType
        : null;
    // formValues.salesAgent = client.agentClientId ? client.salesAgent : client.agent?.agentId ? vitafyUtils.getDisplayName(client.agent) : null;
    formValues.salesAgent = client.agentClientId
      ? client.salesAgent
      : client.agent?.agentId
        ? vitafyUtils.getFullName(client.agent)
        : null;
    formValues.phone = formValues.phone
      ? vitafyUtils.unmaskPhone(formValues.phone)
      : null;
    props.submit(2, formValues);
  };
  const setGoogleAutocompleteAddress = (addr) => {
    for (const field in addr) {
      if (addr.hasOwnProperty(field)) {
        address[field] = addr[field];
        const element = addr[field];
        setValue(field, element);
        clearError(field);
      }
    }
  };
  return (
    <form
      ref={props.clientInfoRef}
      onSubmit={handleSubmit(formSubmit)}
      className="pt-2 py-6 md:py-3"
    >
      {/* Company Name */}
      <MDInput
        id="businessName"
        label="Company Name"
        icon={faBuilding}
        name="businessName"
        value={client?.businessName ? client.businessName : ''}
        inputChange={(e) => handleInputChange(e, 'businessName')}
        error={errors.businessName}
      />

      <div className="flex flex-wrap md:-mx-5">
        {/* Contact First Name */}
        <div className="w-full md:w-1/2 md:px-5 mb-0 md:mb-0">
          <MDInput
            id="firstName"
            label="Contact First Name"
            icon={faUser}
            name="firstName"
            value={client?.firstName ? client.firstName : ''}
            inputChange={(e) => handleInputChange(e, 'firstName')}
            error={errors.firstName}
          />
        </div>
        {/* Contact Last Name */}
        <div className="w-full md:w-1/2 md:px-5">
          <MDInput
            id="lastName"
            label="Contact Last Name"
            name="lastName"
            value={client?.lastName ? client.lastName : ''}
            inputChange={(e) => handleInputChange(e, 'lastName')}
            error={errors.lastName}
          />
        </div>
      </div>

      <div className="flex flex-wrap md:-mx-5">
        {/* Email */}
        <div className="w-full md:w-1/2 md:px-5">
          <MDInput
            id="email"
            label="Email"
            name="email"
            icon={faEnvelope}
            value={client?.email ? client.email : ''}
            inputChange={(e) => handleInputChange(e, 'email')}
            error={errors.email}
          />
        </div>
        {/* Phone */}
        <div className="w-full md:w-1/2 md:px-5">
          <MaskedPhoneInput
            input={client && client.phone ? client.phone : null}
            setInput={(e) => handleInputChange(e, 'phone')}
            name="phone"
            label="Phone"
            icon={faMobileAlt}
          />
        </div>
      </div>

      <div className="flex flex-wrap md:-mx-5">
        {/* Address Line 1 */}
        <div className="w-full md:w-1/2 md:px-5">
          <PlaceAutocomplete
            // updateInputAddress={setAddress}
            id="company-address"
            addressLine1={address?.addressLine1 ? address.addressLine1 : ''}
            setGoogleAutocompleteAddress={(e) =>
              setGoogleAutocompleteAddress(e)
            }
            label="Address Line 1"
            icon={faMapMarkerAlt}
          />
        </div>
        {/* Address Line 2 */}
        <div className="w-full md:w-1/2 md:px-5">
          <MDInput
            id="addressLine2"
            label="Address Line 2"
            name="addressLine2"
            value={address && address.addressLine2 ? address.addressLine2 : ''}
            inputChange={(e) => handleInputChange(e, 'addressLine2')}
            error={errors.addressLine2}
          />
        </div>
      </div>

      <div className="flex flex-wrap md:-mx-5">
        {/* City */}
        <div className="w-full md:w-1/2 md:px-5">
          <MDInput
            id="city"
            label="City"
            name="city"
            value={address && address.city ? address.city : ''}
            inputChange={(e) => handleInputChange(e, 'city')}
            error={errors.city}
          />
        </div>

        <div className="w-full md:w-1/2 md:px-5">
          <div className="h-10 flex -mx-5">
            {/* State */}
            <div className="w-1/2 px-5">
              <MDInput
                id="state"
                label="State"
                name="state"
                value={address && address.state ? address.state : ''}
                inputChange={(e) => handleInputChange(e, 'state')}
                error={errors.state}
              />
            </div>

            <div className="w-1/2 px-5">
              <MDInput
                id="zip"
                label="Zip"
                name="zip"
                value={address && address.zip ? address.zip : ''}
                inputChange={(e) => handleInputChange(e, 'zip')}
                error={errors.zip}
              />
            </div>
          </div>
        </div>
      </div>
      <MDInput
        id="referringAgent"
        label="Referring Agent"
        name="referringAgent"
        value={client?.referringAgent ? client.referringAgent : ''}
        inputChange={(e) => handleInputChange(e, 'referringAgent')}
        error={errors.referringAgent}
      />
    </form>
  );
};

export default ClientInfoCompanyForm;
