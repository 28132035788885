import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons';
import NotFoundImg from '../../assets/images/page-not-found.png';
class PageNotFound extends Component {
  render() {
    return (
      <div className='w-full h-screen flex justify-center items-center '>
        <div className='-mt-8'>
          <img src={NotFoundImg} alt='not-found' className='w-40 md:w-64 mx-auto mb-8' />
          <h4 className='font-heebo text-extended text-xl md:text-3xl text-center pt-0 '>Page Not Found</h4>
          <div className='mt-4 w-3/4 mx-auto'>
            <p className='font-heebo text-bodyColor text-lg md:text-xl text-center'>We couldn't find the page you're looking for.
                Please use one of the following links to navigate back to safe harbor.</p>
          </div>
          <div className='md:flex md:justify-center md:items-center mt-8 lg:mt-0'>
            <button
              className='big-button block mx-auto md:mx-0  shadow transition duration-500 ease-in-out  hover:shadow-lg focus:outline-none mb-6 md:mb-0'
              onClick={() => this.props.history.push('/')}
            >
              <FontAwesomeIcon
                icon={faHome}
                className='inline-block mr-2 '
              />
            Take Me Home
          </button>
            <button
              className='big-button block mx-auto md:mx-0 md:ml-10 shadow transition duration-500 ease-in-out bg-gray-200 text-titleColor hover:shadow-lg hover:bg-gray-300 focus:outline-none'
              onClick={() => this.props.history.push('/contact-us')}
            >
              <FontAwesomeIcon
                icon={faEnvelopeOpenText}
                className='inline-block mr-2'
              />
            Contact Us
          </button>
          </div>
        </div>
      </div >
    );
  }
}

export default PageNotFound;
