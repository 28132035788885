export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader?.result?.toString().split(',')[1]);
    reader.onerror = (error) => reject(error);
  });

export const getExtensionTypeOfFile = (name) =>
  name.slice(name.lastIndexOf('.'));

export const getFilenameWithoutExtension = (name) =>
  name.slice(0, name.lastIndexOf('.'));

export const getFileNameWithExtension = (originalName, name) => {
  const extension = getExtensionTypeOfFile(originalName);
  return `${name}${extension}`;
};
