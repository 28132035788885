import React from 'react';

const ProgressBar = ({ current, total }) => {
  const percentage = !!(current && total)
    ? Math.round((current / total) * 100)
    : 0;

  return (
    <>
      <div className="w-full bg-white rounded-full" style={{ height: '8px' }}>
        <div
          className="bg-successBg rounded-full"
          style={{
            height: '8px',
            width: `${percentage}%`,
            transition: 'all 0.5s ease 0s',
          }}
        ></div>
      </div>
      <div className="mt-2">
        <p className="body-text text-sm">
          {current}/{total}
        </p>
      </div>
    </>
  );
};

export default ProgressBar;
