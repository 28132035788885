import React, { useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteDependent,
  selectDependents,
  selectInformationTab,
} from '../../../../stores/membership';

import { DedicatedFormModal, ButtonLoader } from '../../../../common';
import AddDependentForm from './AddDependentForm';
import * as clientService from '../../../../service/clientService';
import { showToast } from '../../../../service/toasterService';
import { getFullName } from '../../../../util/vitafyUtil';
import BenefitStartDateInput from './BenefitStartDateInput';
import { store } from '../../../../store';
import { getTextFromLangDict } from '../../../../util';
import ClientDisplay from '../../common/ClientDisplay';
import AddClientCard from '../../common/AddClientCard';

const IndividualMembership = ({ whiteBg }) => {
  const dispatch = useDispatch();

  // Redux Store
  const informationTab = useSelector(selectInformationTab);
  const dependents = useSelector(selectDependents);

  // Context Store
  const state = useContext(store);
  const { globalState } = state;
  const { groupCode, langDict, tenantCode } = globalState;

  const [showAddDependentModal, setShowAddDependentModal] = useState(false);
  const [dependentToEdit, setDependentToEdit] = useState(null);

  const [dependentToRemove, setDependentToRemove] = useState(null); // serves as flag to show/hide confirmation modal as well
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onAddDependentClick = () => {
    setShowAddDependentModal(true);
  };

  const onCancelAddDependent = () => {
    setShowAddDependentModal(false);
    setDependentToEdit(null);
  };

  const onEditDependentClick = (data) => {
    setDependentToEdit(data);
    setShowAddDependentModal(true);
  };

  const onRemoveDependentClick = (data) => {
    setDependentToRemove(data);
  };

  const onCancelRemoveDependent = () => {
    setDependentToRemove(null);
  };

  const onConfirmRemoveDependent = async () => {
    try {
      setIsSubmitting(true);
      const response = await clientService.removeClient(
        dependentToRemove.clientId
      );
      dispatch(
        deleteDependent({
          clientId: response.data.clientId,
        })
      );
      setDependentToRemove(null); // closes the modal
    } catch (err) {
      showToast('error', err.response.data.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <p className="body-text text-sm mb-4">
        {getTextFromLangDict(langDict, {
          key: '_YOU_CAN_MANAGE_YOUR_DEPENDENTS_FROM_THE_PORTAL_POST_ENROLLMENT',
          groupCode,
          tenantCode,
        })}
      </p>
      <div className="flex flex-wrap -mx-3">
        <div className="w-full md:w-1/2 px-3 mb-4 md:mb-6">
          {/* Primary Individual */}
          <ClientDisplay
            fullName={getFullName({
              firstName: informationTab.firstName,
              lastName: informationTab.lastName,
            })}
            email={informationTab.email}
            phone={informationTab.phone}
            countryCode={informationTab?.countryCode}
            relationship="Primary Member"
            gender={informationTab.gender}
            dob={informationTab.dob}
            address={{
              addressLine1: informationTab.addressLine1,
              addressLine2: informationTab.addressLine2,
              city: informationTab.city,
              state: informationTab.state,
              zip: informationTab.zip,
            }}
            bloodGroup={informationTab.bloodGroup}
            isPrimary
            whiteBg
            isEditable={false}
            onEditClick={() => {}}
            onDeleteClick={() => {}}
          />
        </div>
        {/* Dependent(s) */}
        {dependents?.map((dependent, index) => (
          <div className="w-full md:w-1/2 px-3 mb-4 md:mb-6" key={index}>
            <ClientDisplay
              fullName={getFullName({
                firstName: dependent.firstName,
                lastName: dependent.lastName,
              })}
              email={dependent.email}
              phone={dependent.phone}
              countryCode={dependent?.countryCode}
              relationship={dependent.relationship}
              gender={dependent.gender}
              dob={dependent.dob}
              address={
                dependent.address || {
                  addressLine1: dependent.addressLine1,
                  addressLine2: dependent.addressLine2,
                  city: dependent.city,
                  state: dependent.state,
                  zip: dependent.zip,
                }
              }
              bloodGroup={dependent.bloodGroup}
              isPrimary={false}
              whiteBg={whiteBg}
              isEditable
              onEditClick={() => onEditDependentClick(dependent)}
              onDeleteClick={() => onRemoveDependentClick(dependent)}
            />
          </div>
        ))}
        <div className="w-full md:w-1/2 px-3 mb-4 md:mb-6">
          <AddClientCard
            onAddClick={onAddDependentClick}
            whiteBg={whiteBg}
            titleDictKey="_WOULD_YOU_LIKE_TO_ADD_DEPENDENT"
            buttonTextDictKey="_ADD"
          />
        </div>
      </div>

      <div className="form-section-divider-md"></div>

      <BenefitStartDateInput />

      {showAddDependentModal && (
        <DedicatedFormModal
          modalTitle={
            dependentToEdit?.clientId
              ? getTextFromLangDict(langDict, {
                  key: '_EDIT_DEPENDENT',
                  groupCode,
                  tenantCode,
                })
              : getTextFromLangDict(langDict, {
                  key: '_ADD_DEPENDENT',
                  groupCode,
                  tenantCode,
                })
          }
          reset={onCancelAddDependent}
          show
        >
          <AddDependentForm
            data={dependentToEdit}
            closeModal={onCancelAddDependent}
          />
        </DedicatedFormModal>
      )}

      {dependentToRemove?.clientId && (
        <DedicatedFormModal
          modalTitle={getTextFromLangDict(langDict, {
            key: '_DELETE_DEPENDENT',
            groupCode,
            tenantCode,
          })}
          reset={onCancelRemoveDependent}
          show
        >
          <div className="modal-body">
            <p>
              {getTextFromLangDict(langDict, {
                key: '_DELETE_DEPENDENT_CONFIRMATION',
                groupCode,
                tenantCode,
              })?.replace(
                '{{name}}',
                dependentToRemove &&
                  getFullName({
                    firstName: dependentToRemove.firstName,
                    lastName: dependentToRemove.lastName,
                    middleName: dependentToRemove.middleName,
                  })
              )}
            </p>
          </div>
          <div className="modal-footer">
            <button
              className={`mid-button px-4 py-3 bg-transparent text-dedicatedDpcSecondary hover:bg-gray-300 ${
                isSubmitting && 'btn-disabled'
              }`}
              type="button"
              onClick={onCancelRemoveDependent}
            >
              {getTextFromLangDict(langDict, {
                key: '_CANCEL',
                groupCode,
                tenantCode,
              })}
            </button>
            <button
              className={`mid-button px-10 py-3 bg-dedicatedDpcSecondary hover:bg-dedicatedDpcHovered flex justify-center items-center ${
                isSubmitting && 'btn-disabled'
              }`}
              type="button"
              onClick={onConfirmRemoveDependent}
            >
              {isSubmitting ? (
                <div className="inline mr-2">
                  <ButtonLoader />
                </div>
              ) : null}
              <span>
                {getTextFromLangDict(langDict, {
                  key: '_CONFIRM',
                  groupCode,
                  tenantCode,
                })}
              </span>
            </button>
          </div>
        </DedicatedFormModal>
      )}
    </>
  );
};

export default IndividualMembership;
