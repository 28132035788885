import React, { Component } from "react";
import { ReactTitle } from "react-meta-tags";

import {
  Header,
  BannerWithSearch,
  ProviderAutoComplete,
  LocationAutocomplete,
  Loader,
  ValueProp,
  RequestDemo,
  Footer,
} from "../../common";
import Intro from "./Intro";
import TopDpc from "./TopDpc";
import Cta from "./Cta";
import Map from "./Map";

import * as networkProviderService from "../../service/networkProviderService";
import { showToast } from "../../service/toasterService";
import * as service from "../../service";

import { getDistance, getSeoOptmizedUrl } from "../../util/vitafyUtil";
import * as locationUtil from "../../util/locationUtil";

import commonConstants from "../../constants/commonConstant";
import ctaModalTitle from "../../constants/ctaModalTitle";

class DpcLanding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      cosmicContent: {
        intro: {},
        valueProps: {},
        overlayCta: [],
        map: {},
        seo: {},
      },
      searchLocation: "",
      searchZipCode: null,
      searchKeyword: "",
      searchCode: null,
      searchCity: null,
      searchState: null,
      searchPrimaryCode: null,
      searchLatitude: null,
      searchLongitude: null,
      pageLimit: 10,
      pageOffset: 0,
      dpcSearchKeyword: "",
      dpcSearchLat: null,
      dpcSearchLng: null,
      dpcListForMap: [],
      topDpcList: [],
      requestDemoModalTitle: ctaModalTitle.CONTACT_US,
    };
    this.newRequestDemoModal = React.createRef();
  }

  componentDidMount() {
    this.getCosmicContent();
    this.fetchDpcForMap();
    this.fetchTopDpc();

    // Ask location permission
    navigator.geolocation.getCurrentPosition(this.setCurrentLocation, () => {
      //in case of location denial
      let location = commonConstants.DEFAULT_LOCATION;
      this.setState({
        searchLocation: location.city + ", " + location.state,
        searchZipCode: location.zip,
        searchCity: location.city,
        searchState: location.state,
        searchLatitude: location.latitude,
        searchLongitude: location.longitude,
      });
    });
  }

  setCurrentLocation = async (location) => {
    let rLocation = await locationUtil.getLocation(location);
    this.setState({
      searchLocation: rLocation.locationName,
      searchZipCode: rLocation.zip,
      searchCity: rLocation.city,
      searchState: rLocation.state,
      searchLatitude: rLocation.latitude,
      searchLongitude: rLocation.longitude,
    });
  };

  handleInputChange = (e, field, clearFields = []) => {
    let value = e.target ? e.target.value : e;
    if ((e.target ? e.target.validity.valid : e) || value == "") {
      let newState = {
        [field]: value,
      };
      clearFields.map((x) => (newState[x] = null));
      this.setState((prevState) => ({
        ...prevState,
        ...newState,
      }));
    }
  };

  onSelectHandler = (selectedItem, fields = [], setValueFields = []) => {
    let newState = {};
    setValueFields.map(
      (x, index) => (newState[x] = selectedItem[fields[index]])
    );
    this.setState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  providerAutocompleteOnSelectHandler = (
    selectedItem,
    fields = [],
    setValueFields = []
  ) => {
    // if user selects particular provider from the autocomplete,
    // direct to the provider detail page directly
    if (selectedItem.providerId) {
      const seoOptimizedUrl = getSeoOptmizedUrl(selectedItem.displayName);
      this.props.history.push(`/${seoOptimizedUrl}/${selectedItem.providerId}`);
      return;
    }
    // if provider isn't selected from the autocomplete,
    // store the keyword
    let newState = {};
    setValueFields.map(
      (x, index) => (newState[x] = selectedItem[fields[index]])
    );
    this.setState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  searchHandler = () => {
    if (
      !(
        (this.state.searchLatitude && this.state.searchLongitude) ||
        this.state.dpcSearchKeyword
      )
    ) {
      showToast(
        "warning",
        "Please provide either DPC name or location to search."
      );
      return;
    }
    this.props.history.push({
      pathname: "/direct-primary-care/search",
      state: {
        searchLocation: this.state.searchLocation,
        searchZipCode: this.state.searchZipCode,
        searchKeyword: this.state.searchKeyword,
        searchCode: this.state.searchCode,
        searchCity: this.state.searchCity,
        searchState: this.state.searchState,
        searchPrimaryCode: this.state.searchPrimaryCode,
        searchLatitude: this.state.searchLatitude,
        searchLongitude: this.state.searchLongitude,
        pageLimit: this.state.pageLimit,
        pageOffset: this.state.pageOffset,
        dpcSearchKeyword: this.state.dpcSearchKeyword,
        dpcSearchLat: this.state.dpcSearchLat,
        dpcSearchLng: this.state.dpcSearchLng,
      },
    });
  };

  getCosmicContent = () => {
    this.setState({ loading: true });
    service
      .getDpcCosmicContent()
      .then((response) => {
        if (response.status === "success") {
          const dpcLanding = response.data.find(
            (item) => item.slug === "dpc-landing"
          ).metadata;
          if (dpcLanding) {
            this.setState((prevState) => ({
              cosmicContent: {
                ...prevState.cosmicContent,
                intro: dpcLanding.intro,
                valueProps: dpcLanding.value_props,
                overlayCta: dpcLanding.overlay_cta,
                map: dpcLanding.map,
                seo: dpcLanding.seo,
              },
            }));
          }
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        console.log("Error in get DPC Landing cosmic content", error);
        this.setState({ loading: false });
      });
  };

  fetchDpcForMap = () => {
    this.setState({ loading: true });
    let queryParams = {
      category: "dpcDirectory",
      network: "VITAFY",
      entityCode: 2,
      map: true,
    };
    networkProviderService
      .fetchNetworkProvider(queryParams)
      .then((response) => {
        if (response.status === "success") {
          this.setState({
            dpcListForMap: response.data?.providerList?.slice(0, 800),
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.log(
          "Error in get Network Providers for displaying on the map",
          error
        );
        this.setState({ loading: false });
      });
  };

  fetchTopDpc = () => {
    this.setState({ loading: true });
    let queryParams = {
      category: "dpcDirectory",
      entityCode: 2,
      registeredProvider: true,
    };
    networkProviderService
      .fetchNetworkProvider(queryParams)
      .then((response) => {
        if (response.status === "success") {
          this.setState({
            topDpcList: response.data?.providerList
              ?.filter((provider) => provider.isRegistered === true)
              .slice(0, 4),
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.log(
          "Error in get Network Providers for displaying on the map",
          error
        );
        this.setState({ loading: false });
      });
  };

  searchProviders = (params) => {
    params.category = "dpcDirectory";
    params.entityCode = 2;
    return new Promise((resolve, reject) => {
      networkProviderService
        .fetchNetworkProvider(params)
        .then((response) => {
          if (response.status === "success") {
            response.data.providerList.map((x) => {
              x.providerName = x.displayName;
              if (x.practiceAddress) {
                x.lat = +x.practiceAddress.lat;
                x.lng = +x.practiceAddress.lng;
              }
              if (!params.isAutoComplete)
                x.distance = x.practiceAddress
                  ? getDistance(
                      x.practiceAddress.lat,
                      x.practiceAddress.lng,
                      params.latitude,
                      params.longitude
                    )
                  : null;
              return x;
            });
            !params.isAutoComplete
              ? resolve(response.data)
              : resolve(response.data.providerList);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <ReactTitle
          title={
            this.state.cosmicContent.seo?.metadata?.page_title ||
            commonConstants.SEO.DEFAULT_PAGE_TITLE
          }
        />

        <Header />

        <BannerWithSearch
          title={this.state.cosmicContent.intro?.title}
          subtitle={this.state.cosmicContent.intro?.tagline}
          subtitleVisibility={
            this.state.cosmicContent.intro?.tagline_visibility
          }
          leftSearch={
            <ProviderAutocompleteInput
              handleInputChange={this.handleInputChange}
              onSelectHandler={this.providerAutocompleteOnSelectHandler}
              searchProviders={this.searchProviders}
              dpcSearchKeyword={this.state.dpcSearchKeyword}
            />
          }
          rightSearch={
            <LocationAutocompleteInput
              handleInputChange={this.handleInputChange}
              onSelectHandler={this.onSelectHandler}
              searchLocation={this.state.searchLocation}
            />
          }
          searchHandler={() => this.searchHandler(true)}
          showOr={true}
        />
        <div className="inner-divider"></div>

        <Intro data={this.state.cosmicContent.intro} />
        <div className="inner-divider"></div>

        <ValueProp
          title={this.state.cosmicContent.valueProps.missionStatement}
          valuePropsTitle={
            this.state.cosmicContent.valueProps.value_props_title
          }
          valuePropsTitleVisibility={
            this.state.cosmicContent.valueProps.value_props_title_visibility
          }
          values={this.state.cosmicContent.valueProps.value_props}
          bgColor={true}
          bgColorClassName="bg-bgColor"
          btnOnClick="/"
          handleActionBtnClick={() => {
            this.setState({ requestDemoModalTitle: ctaModalTitle.CONTACT_US });
            this.newRequestDemoModal.current.toggle();
          }}
        />
        <div className="divider"></div>

        {this.state.topDpcList && this.state.topDpcList.length > 0 && (
          <>
            <TopDpc dpcList={this.state.topDpcList} />
            <div className="divider"></div>
          </>
        )}

        <Cta
          ctaList={this.state.cosmicContent.overlayCta}
          handleActionBtnClick={() => {
            this.setState({ requestDemoModalTitle: ctaModalTitle.CONTACT_US });
            this.newRequestDemoModal.current.toggle();
          }}
        />
        <div className="divider"></div>

        <Map
          title={this.state.cosmicContent?.map?.title}
          subtitle={this.state.cosmicContent?.map?.subtitle}
          dpcList={this.state.dpcListForMap}
        />

        <RequestDemo
          modalTitle={this.state.requestDemoModalTitle}
          formModal={this.newRequestDemoModal}
        />

        <Footer />
      </>
    );
  }
}

const ProviderAutocompleteInput = (props) => {
  const autocompleteInputHandler = (keyword) => {
    let params = {
      isAutoComplete: true,
      keyword,
    };
    if (
      props.searchLocation?.lng &&
      Math.round(props.searchLocation.lng) != 85
    ) {
      //second condition => neglect lat long of ktm
      params = {
        ...params,
        latitude: props.searchLocation.lat,
        longitude: props.searchLocation.lng,
      };
    }
    return props.searchProviders(params);
  };
  return (
    <ProviderAutoComplete
      onSelectHandler={(e) =>
        props.onSelectHandler(
          e,
          ["displayName", "lat", "lng"],
          ["dpcSearchKeyword", "dpcSearchLat", "dpcSearchLng"]
        )
      }
      handleInputChange={(e) => props.handleInputChange(e, "dpcSearchKeyword")}
      placeholder="Search DPC Name"
      displayNameValue={props.dpcSearchKeyword}
      searchForAutoComplete={autocompleteInputHandler}
      inputRequired={false}
    />
  );
};

const LocationAutocompleteInput = (props) => {
  return (
    <LocationAutocomplete
      placeholder="Search by Location"
      inputRequired={false}
      iconType="location"
      onSelectHandler={(e) =>
        props.onSelectHandler(
          e,
          ["displayName", "zip", "st", "city", "lat", "lng"],
          [
            "searchLocation",
            "searchZipCode",
            "searchState",
            "searchCity",
            "searchLatitude",
            "searchLongitude",
          ]
        )
      }
      handleInputChange={(e) =>
        props.handleInputChange(e, "searchLocation", [
          "searchCity",
          "searchState",
          "searchZipCode",
        ])
      }
      displayNameValue={props.searchLocation}
    />
  );
};

export default DpcLanding;
