import { configureStore } from '@reduxjs/toolkit';

import membershipReducer from './membership';
import questionaireReducer from './questionaire';

export const store = configureStore({
  reducer: {
    membership: membershipReducer,
    questionaire: questionaireReducer,
  },
});
