import React from 'react';
import { formatCurrency } from '../../../../util/vitafyUtil';

const MembershipRates = ({ rateToShow = [] }) => {
  const getBillingInterval = (interval) => {
    const upperCasedInterval = interval.toUpperCase();
    if (upperCasedInterval === 'MONTHLY') return 'Monthly';
    else if (
      upperCasedInterval === 'ONETIME' ||
      upperCasedInterval === 'ONE TIME'
    )
      return 'One Time';
    else if (upperCasedInterval === 'YEARLY') return 'Yearly';
    else if (
      upperCasedInterval === 'BIYEARLY' ||
      upperCasedInterval === 'BI-YEARLY'
    )
      return 'Bi-yearly';
  };

  return (
    <div
      className="rounded-md bg-white shadow-md py-4 px-3"
      style={{ width: '20rem', maxHeight: '400px', overflowY: 'auto' }}
    >
      <div className="flex items-center">
        <div className="w-3/4">
          <p className="body-text font-bold text-titleColor text-sm">Name</p>
        </div>
        <div className="w-1/4">
          <p className="body-text font-bold text-titleColor text-sm">Price</p>
        </div>
      </div>
      <hr className="mt-3" />
      {rateToShow?.map((rate, index) => (
        <div className="flex items-start mt-2" key={index}>
          <div className="w-3/4">
            <p className="body-text text-bodyColor text-sm">{rate.name}</p>
          </div>
          <div className="w-1/4">
            <p className="body-text font-bold text-titleColor text-sm">
              {formatCurrency(rate.price, null, 2)}{' '}
              <span className="block font-normal text-bodyColor">
                ({getBillingInterval(rate.billingInterval)})
              </span>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MembershipRates;
