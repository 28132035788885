import * as httpService from './httpService';
import APICONSTANTS from '../constants';
// import mockData from '../assets/data/questionaire.json';

export function fetchQuestionaireByCode(code) {
  return httpService
    .get(`${APICONSTANTS.QUESTIONAIRES}/${code}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });

  // return mockData;
}

export function fetchQuestionaireAnswers(clientId) {
  return httpService
    .get(`${APICONSTANTS.QUESTIONAIRE_ANSWERS.replace(':clientId', clientId)}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function saveQuestionaireResponse(data) {
  return httpService
    .post(`${APICONSTANTS.QUESTIONAIRE_RESPONSES}`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function updateQuestionaireResponse(qResId, data) {
  return httpService
    .put(`${APICONSTANTS.QUESTIONAIRE_RESPONSES}/${qResId}`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}
