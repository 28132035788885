import React from 'react';
import withSizes from 'react-sizes';
import ProviderInformation from './ProviderInformation';
import PaymentSummary from './PaymentSummary';
import {
  SimpleBanner
} from '../../common';
import PlanInfo from './PlanInfo';

const Checkout = (props) => {

  const { step, handleChangeStep, bannerContent } = props;

  return (
    <>
      <SimpleBanner
        title={bannerContent?.title}
        tagline={bannerContent?.tagline}
        taglineVisibility={bannerContent?.taglineVisibility}
        uppercase={true}
      />

      <div className='container mx-auto px-4' ref={props.formInputViewRef}>
        {/* Plan Info is shown at top in smaller screens */}
        <div className='md:hidden'>
          <PlanInfo
            chosenPricingPlan={props.chosenPricingPlan}
            paymentInterval={props.paymentInterval}
          />
        </div>

        <div className='flex flex-wrap'>
          <div className="w-full md:w-1/2">
            {(step === 1 || !props.isMobile) &&
              <ProviderInformation
                data={props.data}
                address={props.address}
                formRef={props.providerInfoFormRef}
                submit={props.handleProviderInfoSubmit}
                chosenPricingPlan={props.chosenPricingPlan}
                isProviderInfoSubmitting={props.isProviderInfoSubmitting}
                step={step}
              />
            }

            {step === 2 && props.isMobile &&
              <PaymentSummary
                paymentEnvironment={props.paymentEnvironment}
                handleSubmitCheckout={props.handleSubmitCheckout}
                iAgreeTerms={props.data.iAgreeTerms}
                handleChangeIAgreeTerms={props.handleChangeIAgreeTerms}
                tmpPaymentData={props.tmpPaymentData}
                handleChangeTmpPaymentData={props.handleChangeTmpPaymentData}
                errorMessage={props.errorMessage}
                handleRemoveErrorMessage={props.handleRemoveErrorMessage}
                paymentInterval={props.paymentInterval}
                handleChangePaymentInterval={props.handleChangePaymentInterval}
                isSubmitting={props.isSubmitting}
                chosenPricingPlan={props.chosenPricingPlan}
                paymentMethod={props.paymentMethod}
                handleChangePaymentMethod={props.handleChangePaymentMethod}
                handleCheckPromoCode={props.handleCheckPromoCode}
                handleChangePromoCode={props.handleChangePromoCode}
                promoCode={props.promoCode}
                mobileView={true}  // changes regarding padding and others are made in mobile view
                step={step}
                handleChangeStep={handleChangeStep}
              />
            }


          </div>
          <div className="w-full hidden md:block md:w-1/2 md:pl-6 lg:pl-12 xl:pr-24 mt-8 md:mt-0">
            <PaymentSummary
              paymentEnvironment={props.paymentEnvironment}
              handleSubmitCheckout={props.handleSubmitCheckout}
              iAgreeTerms={props.data.iAgreeTerms}
              handleChangeIAgreeTerms={props.handleChangeIAgreeTerms}
              tmpPaymentData={props.tmpPaymentData}
              handleChangeTmpPaymentData={props.handleChangeTmpPaymentData}
              errorMessage={props.errorMessage}
              handleRemoveErrorMessage={props.handleRemoveErrorMessage}
              paymentInterval={props.paymentInterval}
              handleChangePaymentInterval={props.handleChangePaymentInterval}
              isSubmitting={props.isSubmitting}
              chosenPricingPlan={props.chosenPricingPlan}
              paymentMethod={props.paymentMethod}
              handleChangePaymentMethod={props.handleChangePaymentMethod}
              handleCheckPromoCode={props.handleCheckPromoCode}
              handleChangePromoCode={props.handleChangePromoCode}
              promoCode={props.promoCode}
              mobileView={false}
              step={step}
            />
          </div>
        </div>
      </div>
    </>
  );
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 768,
})

export default withSizes(mapSizesToProps)(Checkout);