import React, { useContext, useState, useEffect } from 'react';
import withSizes from 'react-sizes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { FormErrorMessage } from '../../../../common';
import { store } from '../../../../store';
import { getCardConnectIframeBaseUrl } from '../../../../util/vitafyUtil';

const parameters =
  'formatinput=true&cardinputmaxlength=19&cardnumbernumericonly=true&useexpiry=true&usecvv=true&orientation=custom&placeholder=Card Number&placeholdercvv=CVV&placeholdermonth=MM&placeholderyear=YYYY&invalidinputevent=true&enhancedresponse=true';
const encodedStylesMobile =
  "body %7B font-family: 'Heebo', sans-serif; margin-left: 0;%0A%7D%0Ainput %7B border: 1.5px solid %23d4d4d4; height: 40px; background-color: %23fafafa; font-size: 16px; text-indent: 16px; margin-top: 10px; color: %23333;%0A%7D input:focus %7B outline: none !important; border: 1.5px solid %2300a1be; box-shadow: 0 0 3px %23b0b0b0; background-color: %23ffffff;%0A%7D select %7B border: 1.5px solid %23d4d4d4; height: 44.5px; background-color: %23fafafa; font-size: 16px; margin-top: 10px; color: %23333; text-indent: 8px;%0A%7D select:focus %7B outline: none !important; border: 1.5px solid %2300a1be; box-shadow: 0 0 3px %23b0b0b0; background-color: %23ffffff;%0A%7D select:not(:-internal-list-box) %7B overflow: visible !important;%0A%7D %23cccardlabel,%0A%23ccexpirylabel,%0A%23cccvvlabel %7B display: none;%0A%7D %23ccnumfield %7B width: 100%25; display: block; margin-bottom: 0.4rem;%0A%7D %23ccexpirymonth %7B width: 4rem; margin-right: 1rem;%0A%7D %23ccexpiryyear %7B width: 5rem; margin-right: 1rem;%0A%7D";
const encodedStyles =
  "body %7B font-family: 'Heebo', sans-serif; margin-left: 0;%0A%7D%0Ainput %7B border: 1.5px solid %23d4d4d4; height: 40px; background-color: %23fafafa; font-size: 16px; text-indent: 16px; margin-top: 10px; color: %23333;%0A%7D input:focus %7B outline: none !important; border: 1.5px solid %2300a1be; box-shadow: 0 0 3px %23b0b0b0; background-color: %23ffffff;%0A%7D select %7B border: 1.5px solid %23d4d4d4; height: 44.5px; background-color: %23fafafa; font-size: 16px; margin-top: 10px; color: %23333; text-indent: 8px;%0A%7D select:focus %7B outline: none !important; border: 1.5px solid %2300a1be; box-shadow: 0 0 3px %23b0b0b0; background-color: %23ffffff;%0A%7D select:not(:-internal-list-box) %7B overflow: visible !important;%0A%7D %23cccardlabel,%0A%23ccexpirylabel,%0A%23cccvvlabel %7B display: none;%0A%7D %23ccnumfield %7B width: 100%25; display: block; margin-bottom: 0.4rem; margin-right: 15px;%0A%7D %23ccexpirymonth %7B width: 6.5rem; margin-right: 1rem;%0A%7D %23ccexpiryyear %7B width: 8.5rem; margin-right: 1rem;%0A%7D%0A%23tokenform %7Bdisplay: flex; %7D";

const CardPayment = ({
  isMobile,
  clearError,
  filledCardData,
  onChangeData,
  onAddNewCardClick,
  cardErrorMessage,
  errorMessage,
  isSubmitting,
}) => {
  // Context Store
  const state = useContext(store);
  const { globalState } = state;
  const { merchantProfile } = globalState;

  const baseUrl = getCardConnectIframeBaseUrl(
    merchantProfile?.paymentEnvironment
  );

  const [validation, setValidation] = useState({
    requiredInput: false,
    errorMsg: '',
    isFormSubmitted: false,
  });

  const clearCardErrorMessage = () => {
    clearError();
  };

  const changeCardData = (data) => {
    onChangeData('card', data);
  };

  const listener = (event) => {
    if (typeof event.data == 'string') {
      // 1004 -> Card Number Required
      // 1005 -> CVC Required
      // 1006 -> Expiry Required

      // 1001 -> Invalid Card Number
      // 1002 -> Invalid CVC
      // 1003 -> Invalid Expiry
      try {
        const response = JSON.parse(event.data);

        if (response.errorCode !== '0') {
          const inputRequired =
            response.errorCode === '1004' ||
            response.errorCode === '1005' ||
            response.errorCode === '1006';
          const inputInvalid =
            response.errorCode === '1001' ||
            response.errorCode === '1002' ||
            response.errorCode === '1003';

          setValidation((prevState) => ({
            ...prevState,
            requiredInput: inputRequired,
            errorMsg: inputInvalid ? response.errorMessage : '',
          }));
        } else {
          changeCardData({
            token: response.token,
            expiry: response.expiry,
          });
          clearCardErrorMessage();
        }
      } catch (e) {
        console.log('Card Connect JSON Parse error', e);
      }
    }
  };

  const getFormattedCardNumber = (token) => {
    if (!token) return '';
    return token.slice(1, 3) + '*********' + token.slice(-4);
  };

  const getFormattedExpiryDate = (expiry) => {
    if (!expiry) return '';
    let month = expiry.slice(4);
    month = month && month.length === 1 ? '0' + month : month;
    let year = expiry.slice(2, 4);
    return month + '/' + year;
  };

  useEffect(() => {
    window.addEventListener('message', listener);
    clearCardErrorMessage();
  }, []);

  useEffect(() => {
    return () => {
      window.removeEventListener('message', listener);
    };
  }, []);

  return (
    <>
      <div className="mt-1 md:mt-2">
        {filledCardData?.token && filledCardData?.expiry && (
          <div className="mt-4 mb-10">
            <div className="flex justify-between items-center">
              <p className="body-text mb-3 font-semibold">
                Use recently entered Card Information
              </p>
              <button
                className={`px-4 py-2 rounded-md hover:bg-orange-200 cursor-pointer transition-all duration-150 ${
                  isSubmitting && 'btn-disabled'
                }`}
                onClick={onAddNewCardClick}
                type="button"
              >
                <p className="text-secondary font-medium">
                  <FontAwesomeIcon icon={faCreditCard} className="mr-2" />
                  Edit Card
                </p>
              </button>
            </div>
            <p className="body-text mb-1">
              Card Number&nbsp; :{' '}
              <span className="text-titleColor">
                {getFormattedCardNumber(filledCardData?.token)}
              </span>
            </p>
            <p className="body-text">
              Expiration &nbsp; &nbsp; &nbsp; &nbsp;:{' '}
              <span className="text-titleColor">
                {getFormattedExpiryDate(filledCardData?.expiry)}
              </span>
            </p>
          </div>
        )}

        <iframe
          style={{
            display:
              filledCardData?.token && filledCardData?.expiry
                ? 'none'
                : 'block',
          }}
          title="iframe"
          id="tokenframe"
          name="tokenframe"
          frameBorder="0"
          scrolling="no"
          width="100%"
          height={isMobile ? '280' : '75'}
          src={`${baseUrl}/itoke/ajax-tokenizer.html?&${parameters}&css=${
            isMobile ? encodedStylesMobile : encodedStyles
          }`}
        />

        <div className="mb-2">
          {/* Error/Warning Message */}
          {validation.requiredInput && (
            <FormErrorMessage
              type="warning"
              message="Please fill all the card information."
            />
          )}
          {validation.invalidInput || cardErrorMessage ? (
            <FormErrorMessage
              type="warning"
              message={
                cardErrorMessage
                  ? cardErrorMessage
                  : 'Please make sure that the provided card informations are correct.'
              }
            />
          ) : null}
          {errorMessage ? (
            <FormErrorMessage type="error" message={`Error: ${errorMessage}`} />
          ) : null}
        </div>
      </div>
    </>
  );
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 768,
});

export default withSizes(mapSizesToProps)(CardPayment);
