import React, { useContext } from 'react';
import NumberFormat from 'react-number-format';
import { Checkbox } from '../../../../common';
import { store } from '../../../../store';
import { getTextFromLangDict } from '../../../../util';
import * as vitafyUtils from '../../../../util/vitafyUtil';

const Service = (props) => {
  const {
    data,
    selectedServices,
    services,
    handleSelectService,
    priceForOthers,
    changePriceForOthers,
    othersPriceInputError,
    handleIsMemberCheckboxClick,
  } = props;
  const { isMemberInput } = data;

  // Context Store
  const state = useContext(store);
  const { globalState } = state;
  const { groupCode, langDict, tenantCode } = globalState;

  return (
    <>
      <h5 className="dedicated-form-title mb-6">
        {getTextFromLangDict(langDict, {
          key: '_SELECT_TYPE_OF_SERVICE',
          groupCode,
          tenantCode,
        })}
      </h5>
      {services &&
        services.map((service, index) => {
          const isSelected = selectedServices.some(
            (item) => item.tenantOfferingId === service.tenantOfferingId
          );
          return (
            <button
              className={`mid-radio-btn w-full md:w-auto mb-4 mr-4 ${
                isSelected && 'font-bold bg-holyCityMedSecondary'
              }`}
              key={index}
              onClick={() => handleSelectService(service)}
            >
              {service.name} -{' '}
              {service.name && service.name.toLowerCase() !== 'others' ? (
                <strong>
                  <span
                    className={`${
                      isMemberInput && service.memberPrice < service.price
                        ? 'strike text-bodyColor font-normal mr-2'
                        : `${isMemberInput && 'hidden'}`
                    }`}
                  >
                    {vitafyUtils.formatCurrency(service.price, null, 0, 0)}
                  </span>
                  <span className={`${!isMemberInput && 'hidden'}`}>
                    {vitafyUtils.formatCurrency(
                      service.memberPrice ? service.memberPrice : 0,
                      null,
                      0,
                      0
                    )}
                  </span>
                </strong>
              ) : (
                <>
                  {isSelected ? (
                    <NumberFormat
                      id="masked-currency-input"
                      thousandSeparator={true}
                      prefix={'$'}
                      value={priceForOthers}
                      className={`bg-white py-1 w-20 px-2 rounded-sm text-sm font-heebo text-titleColor focus:outline-none border focus:border-holyCityMedPrimary
                      ${othersPriceInputError && 'border-red-500'}`}
                      onValueChange={(value) =>
                        changePriceForOthers(value.formattedValue)
                      }
                      autoFocus={true}
                      allowNegative={false}
                    />
                  ) : (
                    // No distinction between memberPrice and price for Others
                    <strong>
                      {vitafyUtils.formatCurrency(
                        isMemberInput
                          ? service.memberPrice
                            ? service.memberPrice
                            : 0
                          : service.price,
                        null,
                        0,
                        0
                      )}
                    </strong>
                  )}
                </>
              )}
            </button>
          );
        })}
      <h5 className="mt-4 mb-4 text-sm md:text-base">
        {getTextFromLangDict(langDict, {
          key: '_CLICK_HERE_IF_YOU_ARE_MEMBER_TO_SEE_PRICING',
          groupCode,
          tenantCode,
        })}
      </h5>
      <Checkbox
        checked={isMemberInput}
        setChecked={(e) => handleIsMemberCheckboxClick(e)}
        className="inline"
      >
        <p className="font-heebo text-sm xl:text-base text-titleColor mb-0 select-none inline-block font-bold">
          {getTextFromLangDict(langDict, {
            key: '_I_AM_A_MEMBER',
            groupCode,
            tenantCode,
          })}
        </p>
      </Checkbox>
    </>
  );
};

export default Service;
