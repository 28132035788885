import React, { useContext } from 'react';

import { store } from '../../store';
import { Loader, ClientStory } from '../../common';
import Enrollment from './Enrollment';
import Banner from './Banner';
import { getTextFromLangDict } from '../../util';

const DedicatedTenant = () => {
  const state = useContext(store);
  const { globalState } = state;
  const { primaryLocation, isLoading, langDict, groupCode, tenantCode } =
    globalState;

  if (isLoading || !primaryLocation || !langDict) return <Loader />;

  const testimonials = primaryLocation.metaData?.testimonials;

  return (
    <>
      {/* <Banner
        info={info}
        tagline={info.tagline}
        image={image}
        content={info.note || info.description}
        agentInfo={agentInfo}
        agentEnrollment={agentEnrollment}
      /> */}
      <Banner />
      <Enrollment />
      <div className="divider"></div>
      <ClientStory
        clientStories={testimonials}
        partners={[]}
        bgColor={false}
        comingFromClientPages={true}
        title={getTextFromLangDict(langDict, {
          key: '_TESTIMONIAL_TITLE',
          groupCode,
          tenantCode,
        })}
      />
    </>
  );
};

export default DedicatedTenant;
