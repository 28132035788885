import React from 'react';
import { parseHtml } from '../../util';

const Intro = ({ data }) => {
  return (
    <div className='container mx-auto px-4'>
      <h3 className='heading'>{data.title}</h3>
      <div className='flex lg:-mx-4'>
        <div className='w-full lg:w-3/4 lg:px-4'>
          <div
            className={`body-text text-base lg:text-xl leading-relaxed`}
            dangerouslySetInnerHTML={{
              __html: parseHtml(data.content),
            }}
          />
        </div>
        <div className='hidden lg:block lg:w-1/4 lg:px-4'>
          <img
            src={data.image && data.image.url}
            className='object-cover w-full h-48 rounded-sm'
            alt="dpc"
          />
        </div>
      </div>

    </div>
  );
}

export default Intro;