import React from 'react';
import { useForm } from 'react-hook-form';
import * as momentUtil from '../../util/momentUtil';
import * as vitafyUtil from '../../util/vitafyUtil';
import { MDInput, MaskedDateInput } from '../../common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faUser,
  faCalendarAlt,
  faIdCardAlt,
  faSearch,
  faEnvelope,
  faMobileAlt,
  faMapMarkerAlt,
} from '@fortawesome/free-solid-svg-icons';
import { ButtonLoader } from '../../common/loader';
import Layout from './Layout';
import PlaceholderImg from '../../assets/images/placeholder.png';

const MemberCheckin = (props) => {
  const {
    isSearched,
    searchSubmit,
    returnToSearch,
    searchFields,
    memberSearchedResults,
    memberSearching,
    submitCheckin,
    checkinSubmitting,
    checkinSuccessful,
  } = props;

  const handleSearchSubmit = (formValues) => {
    searchSubmit(formValues);
  };
  const handleReturnToSearch = () => {
    returnToSearch();
  };

  return (
    <Layout
      left={<LeftContent />}
      right={
        !isSearched ? (
          <SearchForm
            searchSubmit={handleSearchSubmit}
            searchFields={searchFields}
            memberSearching={memberSearching}
          />
        ) : (
            <SearchResult
              returnToSearch={handleReturnToSearch}
              memberSearchedResults={memberSearchedResults}
              submitCheckin={submitCheckin}
              checkinSubmitting={checkinSubmitting}
              checkinSuccessful={checkinSuccessful}
            />
          )
      }
    />
  );
};

/**
 * Component to render title and decription
 */
const LeftContent = () => {
  return (
    <div className='flex flex-col lg:block'>
      <h3 className='heading'>Member Check-in</h3>
      <p className='body-text text-titleColor mr-10'>
        {' '}
        Patients and their DPC Doctor contract directly without added costs and
        interference from middlemen. Patients join a DPC practice as a member
        and receive ongoing primary care services.
      </p>
    </div>
  );
};

/**
 * Component to render search form
 */
const SearchForm = (props) => {
  const { register, errors, setValue, clearError, handleSubmit } = useForm();
  const { searchFields, memberSearching } = props;

  const formBuilder = () => {
    register({ name: 'firstName' });
    register({ name: 'lastName' });
    register(
      { name: 'dob' },
      {
        validate: (value) =>
          searchFields && searchFields.dob
            ? momentUtil.momentIsValid(searchFields.dob) &&
            momentUtil.momentIsSameorBefore(searchFields.dob)
            : true,
        required: true,
      }
    );
    register({ name: 'last4SSN' }, { validate: (value) => (!value || (value && value.length === 4)) });
    setValue([
      { firstName: searchFields.firstName },
      { lastName: searchFields.lastName },
      { dob: searchFields.dob },
      { last4SSN: searchFields.last4SSN },
    ]);
  };

  React.useEffect(() => {
    formBuilder();
  }, []);

  const handleInputChange = (e, field) => {
    let value = e.target ? e.target.value : e;
    if ((e.target ? e.target.validity.valid : e) || value == '') {
      setValue(field, value);
      searchFields[field] = value;
      clearError(field);
    }
  };

  const formSubmit = (formValues) => {
    formValues.dob = searchFields.dob
      ? momentUtil.momentSaveFormat(searchFields.dob)
      : null;

    props.searchSubmit(formValues);
  };

  return (
    <>
      <h4 className='text-2xl font-heebo font-light text-bodyColor'>
        Search Records
      </h4>

      <form onSubmit={handleSubmit(formSubmit)}>
        <div className='flex flex-wrap md:-mx-5 mb-1 mt-6'>
          {/* First Name */}
          <div className='w-full md:w-1/2 md:px-5 mb-0 md:mb-0'>
            <MDInput
              id='firstName'
              label='First Name'
              icon={faUser}
              name='firstName'
              value={searchFields?.firstName ? searchFields.firstName : ''}
              inputChange={(e) => handleInputChange(e, 'firstName')}
              error={errors.firstName}
            />
          </div>
          {/* Last Name */}
          <div className='w-full md:w-1/2 md:px-5'>
            <MDInput
              id='lastName'
              label='Last Name'
              name='lastName'
              value={searchFields?.lastName ? searchFields.lastName : ''}
              inputChange={(e) => handleInputChange(e, 'lastName')}
            //   error={errors.lastName}
            />
          </div>
        </div>

        <div className='flex flex-wrap md:-mx-5 mb-1 mt-3'>
          {/* Date of Birth*/}
          <div className='w-full md:w-1/2 md:px-5 mb-0 md:mb-0'>
            <MaskedDateInput
              id='date'
              icon={faCalendarAlt}
              input={
                searchFields && searchFields.dob
                  ? momentUtil.momentIsValid(searchFields.dob)
                    ? momentUtil.momentViewFormat(searchFields.dob)
                    : searchFields.dob
                  : null
              }
              setInput={(e) => handleInputChange(e, 'dob')}
              name='dob'
              label='Date of Birth'
              error={errors.dob}
            />
          </div>
          {/* Last Four SSN */}
          <div className='w-full md:w-1/2 md:px-5'>
            <MDInput
              id='last4SSN'
              label='Last Four SSN'
              name='last4SSN'
              icon={faIdCardAlt}
              value={searchFields?.last4SSN ? searchFields.last4SSN : ''}
              inputChange={(e) => handleInputChange(e, 'last4SSN')}
              error={errors.last4SSN}
            />
          </div>
        </div>

        <button
          className={`mid-button mt-6 mb-1 ${
            memberSearching ? 'btn-disabled' : null
            }`}>
          <div className='flex items-center'>
            {memberSearching ? (
              <div className='mr-2'>
                <ButtonLoader />
              </div>
            ) : null}
            <div>
              {!memberSearching && (
                <FontAwesomeIcon icon={faSearch} className='text-white mr-3' />
              )}
              Search
            </div>
          </div>
        </button>
      </form>
    </>
  );
};

/**
 * Component to render search result (right part)
 */
const SearchResult = (props) => {
  const {
    memberSearchedResults,
    submitCheckin,
    checkinSubmitting,
    checkinSuccessful,
  } = props;
  const memberDetail = memberSearchedResults && memberSearchedResults[0];
  return (
    <>
      <h4 className='text-2xl font-heebo font-light text-bodyColor'>
        Search Result
      </h4>

      <div className='w-full bg-white mt-10 flex flex-wrap p-2 '>
        <div className='w-full md:w-3/4 '>
          <div className='flex flex-wrap items-center'>
            <div className='relative w-32 pb-32 md:w-40 md:pb-40 overflow-hidden bg-gray-200 mx-auto md:mx-0'>
              <img
                src={PlaceholderImg}
                className='absolute w-full h-full object-cover'
                alt='member'
              />
            </div>
            <div className='flex-grow ml-10 mt-4 md:mt-0'>
              <h6 className='mb-3'>{`${memberDetail.firstName} ${memberDetail.lastName}`}</h6>
              <div className='flex items-start mb-2'>
                <div className='w-8'>
                  <FontAwesomeIcon
                    icon={faMobileAlt}
                    className='text-lg text-primary block '
                  />
                </div>
                <p className='body-text text-sm'>
                  {memberDetail.phone
                    ? vitafyUtil.getPhoneDisplayText(memberDetail.phone, memberDetail?.countryCode)
                    : 'N/A'}
                </p>
              </div>
              <div className='flex items-start mb-2'>
                <div className='w-8'>
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className='text-lg text-primary block  '
                  />
                </div>
                <p className='body-text text-sm'>
                  {memberDetail.email ? memberDetail.email : 'N/A'}
                </p>
              </div>
              <div className='flex items-start mb-2'>
                <div className='w-8'>
                  <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    className='text-lg text-primary block  '
                  />
                </div>
                <p className='body-text text-sm'>
                  {memberDetail.address
                    ? vitafyUtil.getAddressDisplayName(
                      memberDetail.address,
                      true
                    )
                    : 'N/A'}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='w-full md:w-1/4 h-40 flex justify-center items-center '>
          <button
            className={`mid-button ${
              checkinSubmitting || checkinSuccessful ? 'btn-disabled' : null
              }`}
            type='submit'
            onClick={() => submitCheckin(memberDetail)}>
            <div className='flex items-center'>
              {checkinSubmitting ? (
                <div className='mr-0'>
                  <ButtonLoader />
                </div>
              ) : null}
              <div>{checkinSuccessful ? 'Checked-in' : 'Check-in'}</div>
            </div>
          </button>
        </div>
      </div>

      <div
        className='flex justify-start items-center text-secondary mt-10 cursor-pointer'
        onClick={() => props.returnToSearch()}>
        <FontAwesomeIcon
          icon={faArrowLeft}
          className='text-lg secondary block'
        />
        <p className='body-text ml-3 text-secondary font-sm font-bold'>
          {' '}
          Go Back to Search
        </p>{' '}
      </div>
    </>
  );
};

export default MemberCheckin;
