/**
 * If the received pricing array consists of both offeringTypes (Ancillary and Membership), 
 * then, display the list by category
 * else, display the list (and keep the title generic)
 * 
 * Display public pricings only 
 */

import React from 'react';
import * as vitafyUtils from '../../util/vitafyUtil';
import commonConstant from '../../constants/commonConstant';

const Pricing = (props) => {
  let { pricings } = props;
  pricings = pricings && pricings.filter(item => (item.price !== 0) && item.isPublic).sort((a, b) => a.name?.toLowerCase().localeCompare(b.name?.toLowerCase()));
  const ancillaryOfferings = pricings.filter(item => item.type === commonConstant.OFFERING_TYPES.ANCILLARY).sort((a, b) => a.name?.toLowerCase().localeCompare(b.name?.toLowerCase()));
  const membershipOfferings = pricings.filter(item => item.type === commonConstant.OFFERING_TYPES.MEMBERSHIP).sort((a, b) => a.name?.toLowerCase().localeCompare(b.name?.toLowerCase()));
  const isAncillaryPresent = ancillaryOfferings && ancillaryOfferings.length > 0;
  const isMembershipPresent = membershipOfferings && membershipOfferings.length > 0;

  return (
    <>
      <h4 className='text-lg mb-5'>Pricing</h4>
      {isMembershipPresent &&
        <>
          <h4 className={`text-base font-bold text-bodyColor mb-4`}>Membership</h4>
          {membershipOfferings.map((pricing, index) =>
            <div key={index}>
              <div className='flex justify-between items-center'>
                <p className='body-text '>{pricing.name}</p>
                <p className='body-text text-bodyColor'>{vitafyUtils.formatCurrency(pricing.price, null, 0)}{' '}
                  {(pricing.billingInterval?.toUpperCase() !== 'ONETIME') ? vitafyUtils.capitalizeFirstLetterOfEachWord(pricing.billingInterval) : null}</p>
              </div>
              {index !== membershipOfferings.length - 1 && <hr className='my-3' />}
            </div>
          )}
        </>
      }
      {isAncillaryPresent &&
        <>
          <h4 className={`text-base font-bold text-bodyColor mb-4 ${isMembershipPresent && 'mt-8'}`}>Additional Services</h4>
          {ancillaryOfferings.map((pricing, index) =>
            <div key={index}>
              <div className='flex justify-between items-center'>
                <p className='body-text '>{pricing.name}</p>
                <p className='body-text text-bodyColor'>{vitafyUtils.formatCurrency(pricing.price, null, 0)}{' '}
                  {(pricing.billingInterval?.toUpperCase() !== 'ONETIME') ? vitafyUtils.capitalizeFirstLetterOfEachWord(pricing.billingInterval) : null}</p>
              </div>
              {index !== ancillaryOfferings.length - 1 && <hr className='my-3' />}
            </div>
          )}
        </>
      }
      {pricings && pricings.length < 1 &&
        <div className="body-text">No information available</div>
      }
    </>
  );
}

export default Pricing;