import React, { useContext } from 'react';
import { store } from '../../../store';
import { getTextFromLangDict } from '../../../util';

import { Confirmation } from '../common';
import SearchForm from './SearchForm';
import SearchResult from './SearchResult';

const FormBody = (props) => {
  const { showResult, showConfirmation } = props;

  // Context Store
  const state = useContext(store);
  const { globalState } = state;
  const { groupCode, langDict, tenantCode } = globalState;

  return (
    <>
      {!showConfirmation ? (
        <>
          {!showResult ? (
            <SearchForm
              data={props.searchData}
              searchFormRef={props.searchFormRef}
              submit={props.handleSearchSubmit}
              errorObj={props.errorObj}
              handleRemoveErrorMessage={props.handleRemoveErrorMessage}
            />
          ) : (
            <SearchResult
              data={props.resultData}
              isSubmitting={props.isSubmitting}
              errorObj={props.errorObj}
            />
          )}
        </>
      ) : (
        <Confirmation
          title={getTextFromLangDict(langDict, {
            key: '_MEMBER_CHECK_IN_CONFIRMATION_TITLE',
            groupCode,
            tenantCode,
          })}
          email={props.resultData.email}
          displayName={props.info.displayName}
        />
      )}
    </>
  );
};

export default FormBody;
