import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import { Header, Footer } from '../../common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMobileAlt,
  faEnvelope,
  faUser,
  faArrowRight,
  faCommentAlt,
  faChevronDown,
  faUserTie,
} from '@fortawesome/free-solid-svg-icons';
import * as service from '../../service';

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sending: false,
      name: '',
      email: '',
      profession: '',
      message: '',
    };
  }

  handleFullNameChange = (e) => {
    this.setState({ name: e.target.value });
  };
  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });
  };
  handleTypeChange = (e) => {
    this.setState({ profession: e.target.value });
  };
  handleMessageChange = (e) => {
    this.setState({ message: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const dataToSend = {
      name: this.state.name,
      email: this.state.email,
      profession: this.state.profession,
      message: this.state.message,
    };

    this.setState({ sending: true });

    service
      .submitContactForm(dataToSend, true)
      .then((result) => {
        this.setState({ sending: false });
        if (result.data === 'success') {
          toast.info('Thank you for reaching out!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          //reset the form
          this.setState({ name: '', email: '', profession: '', message: '' });
        } else {
          toast.error('Oops. Something went wrong', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        console.log('Error in post request demo', error);
        this.setState({ sending: false });
      });
  };

  render() {
    return (
      <>
        <Header />
        <div className='w-full pt-24 lg:pt-32 pb-12 lg:pb-20 bg-extended'>
          <div className='max-w-screen-xl mx-auto px-4 lg:px-0'>
            <h1 className='firstContentHeading'>Get in touch</h1>
          </div>
        </div>
        <div className='max-w-screen-xl mx-auto py-10 lg:py-16 flex flex-wrap px-4 lg:px-0'>
          <div className='w-full md:w-1/2 lg:pr-12'>
            <h4>Contact Information</h4>
            {/* <div className='flex mb-4 mt-5'>
              <div>
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  className='contact-info-icon'
                />
              </div>
              <p className='contact-info'>
                Vitafy LLC <br />
                Charlotte, NC
              </p>
            </div> */}
            <div className='flex mb-4'>
              <div>
                <FontAwesomeIcon
                  icon={faMobileAlt}
                  className='contact-info-icon'
                />
              </div>
              <a href='tel:8664848239' className='contact-info font-bold'>
                (866) 4-VITAFY <br /> (866) 484-8239
              </a>
            </div>
            <div className='flex mb-4'>
              <div>
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className='contact-info-icon'
                />
              </div>
              <a
                href='mailto:info@vitafyhealth.com'
                className='contact-info font-bold focus:outline-none no-underline'
              >
                info@vitafyhealth.com
              </a>
            </div>
          </div>
          <div className='w-full md:w-1/2 xl:pl-32  mt-12 lg:mt-0'>
            <div className='negative-margin'>
              <div className='h-32 w-full bg-lighterExtended px-5 lg:px-8 flex items-center rounded-t-lg shadow-lg '>
                <h6 className='text-white font-heebo font-normal text-xl lg:text-2xl'>
                  If you have any question and suggestion, feel free to contact
                  us
                </h6>
              </div>
              <div className='px-5 lg:px-8 py-12 w-full bg-white rounded-b-lg shadow-lg'>
                <form onSubmit={this.handleSubmit}>
                  {/* Full Name */}
                  <div className='w-full mb-6'>
                    <label
                      className='block font-heebo text-md text-bodyColor mb-2'
                      htmlFor='full-name'
                    >
                      Full Name
                    </label>
                    <div className='relative'>
                      <FontAwesomeIcon
                        icon={faUser}
                        className='contact-form-icon'
                      />
                      <input
                        id='full-name'
                        type='text'
                        placeholder=''
                        className='text-input'
                        value={this.state.name}
                        onChange={this.handleFullNameChange}
                        required
                      />
                    </div>
                    {/* <p className='text-red-500 text-xs italic'>
                    Please fill out this field.
                  </p> */}
                  </div>
                  {/* Email */}
                  <div className='w-full mb-6 '>
                    <label
                      className='block font-heebo text-md text-bodyColor mb-2'
                      htmlFor='email'
                    >
                      Email
                    </label>
                    <div className='relative'>
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        className='contact-form-icon'
                      />
                      <input
                        id='email'
                        type='email'
                        placeholder=''
                        className='text-input'
                        value={this.state.email}
                        onChange={this.handleEmailChange}
                        required
                      />
                    </div>
                    {/* <p className='text-red-500 text-xs italic'>
                    Please fill out this field.
                  </p> */}
                  </div>
                  {/* User Type */}
                  <div className='w-full mb-6'>
                    <label
                      className='block font-heebo text-md text-bodyColor mb-2'
                      htmlFor='user-type'
                    >
                      Type
                    </label>
                    <div className='relative'>
                      <FontAwesomeIcon
                        icon={faUserTie}
                        className='contact-form-icon'
                      />
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className='contact-form-icon-select'
                        style={{ right: '20px' }}
                      />
                      <select
                        name='type'
                        className='text-input'
                        value={this.state.profession}
                        onChange={this.handleTypeChange}
                      >
                        <option aria-label='None' value='' />
                        <option value='Agents/Broker'>Agents/Broker</option>
                        <option value='Consumer'>Consumer</option>
                        <option value='Direct Primary'>Direct Primary</option>
                        <option value='Other'>Other</option>
                        <option value='Provider'>Provider</option>
                      </select>
                    </div>
                    {/* <p className='text-red-500 text-xs italic'>
                    Please fill out this field.
                  </p> */}
                  </div>
                  {/* Message */}
                  <div className='w-full mb-6'>
                    <label
                      className='block font-heebo text-md text-bodyColor mb-2'
                      htmlFor='message'
                    >
                      Message
                    </label>
                    <div className='relative'>
                      <FontAwesomeIcon
                        icon={faCommentAlt}
                        className='contact-form-icon'
                      />
                      <textarea
                        id='message'
                        rows='5'
                        // type='email'
                        placeholder=''
                        className='text-input'
                        value={this.state.message}
                        onChange={this.handleMessageChange}
                        required
                      />
                    </div>
                    {/* <p className='text-red-500 text-xs italic'>
                    Please fill out this field.
                  </p> */}
                  </div>
                  <button
                    className='bg-secondary hover:bg-hovered ease-out block text-white font-heebo text-lg px-6 py-3 rounded-full outine-none w-full md:w-auto focus:outline-none'
                    type='submit'
                  >
                    {' '}
                    Submit
                    {this.state.sending ? (
                      <ReactLoading
                        className='inline-block ml-3 pt-1'
                        type='spin'
                        color='white'
                        height={'20px'}
                        width={'20px'}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        className='text-white text-md ml-3'
                      />
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className='inner-divider'></div>

        <Footer />

        <ToastContainer
          position='top-right'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover
        />
      </>
    );
  }
}

export default Contact;
