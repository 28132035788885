import React, { useState } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import 'react-app-polyfill/stable';

import AppWrapper from './common/AppWrapper';
import { StripeWrapper } from './common/StripeWrapper';
import CacheBuster from './CacheBuster';

import Maintenance from './components/maintenance';
import commonConstants from './constants/commonConstant';
import { StateProvider } from './store.js';
import { store } from './stores';

import './assets/styles/tailwind.generated.css';
// import './assets/styles/utilities.generated.css';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import 'react-toastify/dist/ReactToastify.css';
import { showMaintenancePage } from './util/vitafyUtil';

const { VERSION } = commonConstants;

function App() {
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(
    showMaintenancePage()
  );

  console.log('---------------------VERSION---------------------', VERSION); // Change version on every deployment so that changes can be verified.
  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }

        if (isMaintenanceMode) {
          return <Maintenance setIsMaintenanceMode={setIsMaintenanceMode} />;
        }

        return (
          <Router>
            <StateProvider>
              <StripeWrapper>
                <Provider store={store}>
                  <AppWrapper />
                </Provider>
              </StripeWrapper>
            </StateProvider>
          </Router>
        );
      }}
    </CacheBuster>
  );
}

export default App;
