import React, { Component } from "react";
import { ReactTitle } from "react-meta-tags";

import { Header, Footer, Value, Partner } from "../../common";
import { FirstContent } from "../../common";
import * as service from "../../service";
import { Loader } from "../../common";
import CustomersWeServe from "./CustomersWeServe";
import Team from "./Team";
import commonConstants from "../../constants/commonConstant";

class WhoWeAre extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      firstContent: {},
      customersWeServe: {},
      keyValues: [],
      team: [],
      partners: [],
      seo: {},
    };
  }

  componentDidMount() {
    this.getContent();
    this.getTeam();
    this.getPartners();
  }

  getContent = () => {
    this.setState({ loading: true });

    service
      .getWhoWeAreContent()
      .then((result) => {
        if (result.status === "success") {
          this.setState({
            keyValues: result.data.find(
              (item) => item.slug === "who-we-are-key-values"
            ),
            firstContent: result.data.find(
              (item) => item.slug === "who-we-are"
            ),
            customersWeServe: result.data.find(
              (item) => item.slug === "customers-we-serve"
            ),
            seo: result.data.find((item) => item.slug === "who-we-are-page-seo")
              ?.metadata?.seo,
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.log("Error in get whoWeAreContent", error);
        this.setState({ loading: false, error: true });
      });
  };

  getTeam = () => {
    this.setState({ loading: true });

    service
      .getTeam()
      .then((result) => {
        if (result.status === "success") {
          this.setState({
            team: result.data,
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.log("Error in getTeam", error);
        this.setState({ loading: false, error: true });
      });
  };

  getPartners = () => {
    this.setState({ loading: true });

    service
      .getPartners()
      .then((result) => {
        if (result.status === "success") {
          this.setState({
            partners: result.data.find((item) => item.slug === "partners"),
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.log("Error in get homeContent", error);
        this.setState({ loading: false, error: true });
      });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }
    return (
      <div>
        <ReactTitle
          title={
            this.state.seo?.metadata?.page_title ||
            commonConstants.SEO.DEFAULT_PAGE_TITLE
          }
        />

        <Header />
        <FirstContent
          title={this.state.firstContent && this.state.firstContent.title}
          text={this.state.firstContent && this.state.firstContent.content}
          image={
            this.state.firstContent.metadata &&
            this.state.firstContent.metadata.banner_image.url
          }
          tagline={
            this.state.firstContent.metadata &&
            this.state.firstContent.metadata.tagline
          }
          taglineVisibility={
            this.state.firstContent.metadata &&
            this.state.firstContent.metadata.tagline_visibility
          }
        />

        <div className="inner-divider"></div>

        <Value
          title={this.state.keyValues.content}
          values={
            this.state.keyValues.metadata &&
            this.state.keyValues.metadata.vitafy_values
          }
          bgColor={false}
        />

        <CustomersWeServe
          title={
            this.state.customersWeServe && this.state.customersWeServe.title
          }
          text={
            this.state.customersWeServe.metadata &&
            this.state.customersWeServe.metadata.text
          }
        />

        <Team team={this.state.team} />

        {this.state.partners.metadata &&
        this.state.partners.metadata.entire_section_visibility === "On" ? (
          <div className="container mx-auto px-4">
            <Partner
              partners={
                this.state.partners.metadata &&
                this.state.partners.metadata.partners_images
              }
            />
          </div>
        ) : null}

        <div className="divider"></div>

        <Footer />
      </div>
    );
  }
}

export default WhoWeAre;
