import { faEdit, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Popover } from 'react-tiny-popover';
import ReactTooltip from 'react-tooltip';

import commonConstants from '../../../../constants/commonConstant';
import { tenantCodeEnum } from '../../../../constants/tenantCodeConstant';
import { store } from '../../../../store';
import {
  selectBenefitStartDate,
  selectInformationTab,
  selectNoOfEmployee,
  selectOfferings,
  selectSelectedOfferings,
} from '../../../../stores/membership';
import { getTextFromLangDict, parseHtml } from '../../../../util';
import { momentViewFormat } from '../../../../util/momentUtil';
import {
  checkIsCompany,
  checkIsIndividual,
  formatCurrency,
  getFullName,
} from '../../../../util/vitafyUtil';
import MembershipRates from './MembershipRates';
import SwitchOfferingModal from './SwitchOfferingModal';

const { OFFERING_TYPE_PRIMARY, BILLING_TYPE } = commonConstants;

const PaymentSummary = () => {
  // Redux Store
  const primaryIndividual = useSelector(selectInformationTab);
  const selectedOfferings = useSelector(selectSelectedOfferings);
  const offerings = useSelector(selectOfferings);
  const noOfEmployee = useSelector(selectNoOfEmployee);
  const benefitStartDate = useSelector(selectBenefitStartDate);

  // Context Store
  const state = useContext(store);
  const { globalState } = state;
  const { langDict, groupCode, tenantCode } = globalState;

  const [showSwitchOfferingModal, setShowSwitchOfferingModal] = useState(false);
  // holds the clientId of the dependent (dependentId) if switch offering is for dependent
  // holds '' if switch offfering is for primary
  const [dependentIdForSwitch, setDependentIdForSwitch] = useState('');
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const isIndividual = checkIsIndividual(primaryIndividual.type);
  const isGroup = checkIsCompany(primaryIndividual.type);

  const isGroupBillingType = useMemo(() => {
    return selectedOfferings.some(
      (item) => item.billingType === BILLING_TYPE.GROUP
    );
  }, [selectedOfferings]);

  const primaryIndividualFullName = getFullName({
    firstName: primaryIndividual.firstName,
    lastName: primaryIndividual.lastName,
    middleName: primaryIndividual.middleName,
  });

  const checkIsOfferingTypePrimary = (type) => {
    return type === OFFERING_TYPE_PRIMARY;
  };

  const checkIfRegistrationFeeExists = (value) => {
    return !!value && parseInt(value) > 0;
  };

  const getNameToShowBesideOffering = (offering) => {
    if (offering.billingType === BILLING_TYPE.FAMILY) {
      return primaryIndividualFullName;
    }

    const isOfferingTypePrimary = checkIsOfferingTypePrimary(
      offering.offeringType
    );

    if (isOfferingTypePrimary) {
      return primaryIndividualFullName;
    }

    if (isOfferingTypePrimary) {
      return primaryIndividualFullName;
    }

    return getFullName({
      firstName: offering.dependent?.firstName,
      lastName: offering.dependent?.lastName,
      middleName: offering.dependent?.middleName,
    });
  };

  const totalAmount = useMemo(() => {
    let totalAmount = selectedOfferings?.reduce((total, current) => {
      return (
        total +
        (parseInt(current.registrationFee) > 0
          ? +current.registrationFee + current.price
          : current.price)
      );
    }, 0);

    // No need to multiply the cost by total members if the group offering is of type 'Group'
    if (
      isGroup &&
      !selectedOfferings.some((item) => item.billingType === BILLING_TYPE.GROUP)
    ) {
      totalAmount = totalAmount * +noOfEmployee;
    }

    return formatCurrency(totalAmount, null, 2);
  }, [isGroup, noOfEmployee, selectedOfferings]);

  const onSwitchOfferingClick = (dependentId) => {
    if (dependentId) {
      setDependentIdForSwitch(dependentId);
    }
    setShowSwitchOfferingModal(true);
  };

  const onCloseSwitchOfferingModal = () => {
    setDependentIdForSwitch('');
    setShowSwitchOfferingModal(false);
  };

  const getOfferingAmtToDisplay = (offering) => {
    if (isIndividual || (isGroup && isGroupBillingType)) {
      return formatCurrency(offering.price, null, 2);
    }

    return formatCurrency(offering.price * +noOfEmployee, null, 2);
  };

  const getRegistrationAmtToDisplay = (offering) => {
    if (isIndividual || (isGroup && isGroupBillingType)) {
      return formatCurrency(+offering.registrationFee, null, 2);
    }

    return formatCurrency(+offering.registrationFee * +noOfEmployee, null, 2);
  };

  const displayEditIcon =
    offerings?.length > 1 && tenantCode !== tenantCodeEnum.IHS;
  const hideRateInfo = tenantCode === tenantCodeEnum.IHS;

  return (
    <>
      <div className="flex justify-between items-start  mb-6">
        <h5 className="dedicated-form-title">
          {getTextFromLangDict(langDict, {
            key: '_YOU_ARE_ENROLLING_FOR_THE_FOLLOWING_SERVICES',
            groupCode,
            tenantCode,
          })}
        </h5>
        {!hideRateInfo && (
          <Popover
            isOpen={isPopoverOpen}
            positions={['bottom', 'top', 'left', 'right']} // preferred positions by priority
            content={<MembershipRates rateToShow={offerings} />}
            onClickOutside={() => setIsPopoverOpen(false)}
            padding={10}
          >
            <p className="body-text text-sm text-titleColor ml-2">
              {isIndividual
                ? getTextFromLangDict(langDict, {
                    key: '_FAMILY_AND_INDIVIDUAL_RATES',
                    groupCode,
                    tenantCode,
                  })
                : getTextFromLangDict(langDict, {
                    key: '_COMPANY_AND_GROUP_RATES',
                    groupCode,
                    tenantCode,
                  })}

              <FontAwesomeIcon
                icon={faInfoCircle}
                className="text-dedicatedDpcPrimary cursor-pointer text-base ml-2"
                onClick={() => setIsPopoverOpen(!isPopoverOpen)}
              />
            </p>
          </Popover>
        )}
      </div>

      {selectedOfferings?.map((offering) => {
        const doesRegistrationFeeExist = checkIfRegistrationFeeExists(
          offering.registrationFee
        );

        return (
          <div
            key={`${offering.tenantOfferingId}-${
              offering.dependent?.clientId || 'primary'
            }`}
          >
            <div
              className={`flex justify-between items-start ${
                doesRegistrationFeeExist ? 'mb-1' : 'mb-3'
              }`}
            >
              <div>
                {isIndividual ? (
                  <p className="body-text text-titleColor font-bold inline">
                    {checkIsOfferingTypePrimary(offering.offeringType)
                      ? 'Primary'
                      : `Dependent`}{' '}
                    ({getNameToShowBesideOffering(offering)}) - {offering.name}
                  </p>
                ) : (
                  <p className="body-text text-titleColor font-bold inline">
                    {offering.name}
                  </p>
                )}
                {displayEditIcon && (
                  <>
                    <FontAwesomeIcon
                      data-tip
                      data-for="edit-offering"
                      icon={faEdit}
                      className="inline text-dedicatedDpcPrimary ml-2 cursor-pointer"
                      onClick={() =>
                        onSwitchOfferingClick(offering.dependent?.clientId)
                      }
                    />
                    <ReactTooltip id="edit-offering" type="dark">
                      <span className="body-text text-sm text-white">
                        {getTextFromLangDict(langDict, {
                          key: '_SWITCH_MEMBERSHIP_PLAN',
                          groupCode,
                          tenantCode,
                        })}
                      </span>
                    </ReactTooltip>
                  </>
                )}
              </div>
              <div>
                <p className="body-text text-titleColor font-bold text-right">
                  {getOfferingAmtToDisplay(offering)}
                </p>
              </div>
            </div>
            {doesRegistrationFeeExist && (
              <div className="flex justify-between items-start mb-3">
                <div>
                  {isIndividual ? (
                    <p className="body-text text-titleColor font-bold inline-block">
                      {offering.offeringType === OFFERING_TYPE_PRIMARY
                        ? 'Primary'
                        : `Dependent`}{' '}
                      ({getNameToShowBesideOffering(offering)}) - Registration
                      Fee
                    </p>
                  ) : (
                    <>
                      <p className="body-text text-titleColor font-bold inline-block">
                        Registration Fee
                      </p>
                      <p className="body-text text-xs text-left">
                        {getTextFromLangDict(langDict, {
                          key: '_ESTIMATED_NO_OF_EMPLOYEES',
                          groupCode,
                          tenantCode,
                        })}
                        : {noOfEmployee}
                      </p>
                    </>
                  )}
                </div>
                <div>
                  <p className="body-text text-titleColor font-bold text-right">
                    {getRegistrationAmtToDisplay(offering)}
                  </p>

                  {isGroup && !isGroupBillingType && (
                    <p className="body-text text-xs text-right">
                      ({formatCurrency(offering.price, null, 0)} x{' '}
                      {noOfEmployee}{' '}
                      {`+ ${formatCurrency(
                        offering.registrationFee,
                        null,
                        0
                      )} x ${noOfEmployee}`}
                      )
                    </p>
                  )}
                </div>
              </div>
            )}

            {!doesRegistrationFeeExist && isGroup && (
              <div className="flex justify-between items-start mb-3">
                <p className="body-text text-xs text-left">
                  {getTextFromLangDict(langDict, {
                    key: '_ESTIMATED_NO_OF_EMPLOYEES',
                    groupCode,
                    tenantCode,
                  })}
                  : {noOfEmployee}
                </p>
                {!isGroupBillingType && (
                  <p className="body-text text-xs text-right">
                    ({formatCurrency(offering.price, null, 0)} x {noOfEmployee})
                  </p>
                )}
              </div>
            )}
          </div>
        );
      })}

      <hr className="w-full h-1.5px bg-bgColor my-4" />

      <p className="body-text text-titleColor font-bold text-lg flex justify-end">
        {!isIndividual && 'Estimated '}Total:&nbsp;&nbsp;&nbsp;
        {totalAmount}
      </p>

      <p
        className="body-text text-sm text-titleColor mt-6"
        dangerouslySetInnerHTML={{
          __html: parseHtml(
            getTextFromLangDict(langDict, {
              key: '_SERVICE_MEMBERSHIP_CHECKOUT_ENROLLMENT_NOTE',
              groupCode,
              tenantCode,
            })?.replace(
              '{{startDate}}',
              `<strong>${momentViewFormat(benefitStartDate)}</strong>`
            )
          ),
        }}
      ></p>

      {showSwitchOfferingModal && (
        <SwitchOfferingModal
          closeHandler={onCloseSwitchOfferingModal}
          dependentIdForSwitch={dependentIdForSwitch} // switch offering for primary if !dependentId
        />
      )}
    </>
  );
};

export default PaymentSummary;
