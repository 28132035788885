import MemberCheckinIconDpc from '../assets/images/dpc-svg/member-check-in.svg';
import WalkinVideoVisitIconDpc from '../assets/images/dpc-svg/walk-in-video-visit.svg';
import RequestAppointmentIconDpc from '../assets/images/dpc-svg/request-appointment.svg';
import MembershipIconDpc from '../assets/images/dpc-svg/membership.svg';

// Note: The key should be changed if the service codes are changes
const serviceMap = {
  SERVICES_MEMBER_CHECK_IN: {
    code: 'SERVICES_MEMBER_CHECK_IN',
    icon: MemberCheckinIconDpc,
    title: '_{{groupCode}}_SERVICE_MEMBER_CHECK_IN',
    tenantSpecificTitle:
      '_{{groupCode}}_{{tenantCode}}_SERVICE_MEMBER_CHECK_IN',
    defaultTitle: '_SERVICE_MEMBER_CHECK_IN',
    description: '_{{groupCode}}_SERVICE_MEMBER_CHECK_IN_NOTE',
    tenantSpecificDescription:
      '_{{groupCode}}_{{tenantCode}}_SERVICE_MEMBER_CHECK_IN_NOTE',
    defaultDescription: '_SERVICE_MEMBER_CHECK_IN_NOTE',
    route: '/:tenantCode/service/member-check-in',
  },
  SERVICES_REQUEST_APPOINTMENT: {
    code: 'SERVICES_REQUEST_APPOINTMENT',
    icon: RequestAppointmentIconDpc,
    title: '_{{groupCode}}_SERVICE_REQUEST_APPOINTMENT',
    tenantSpecificTitle:
      '_{{groupCode}}_{{tenantCode}}_SERVICE_REQUEST_APPOINTMENT',
    defaultTitle: '_SERVICE_REQUEST_APPOINTMENT',
    description: '_{{groupCode}}_SERVICE_REQUEST_APPOINTMENT_NOTE',
    tenantSpecificDescription:
      '_{{groupCode}}_{{tenantCode}}_SERVICE_REQUEST_APPOINTMENT_NOTE',
    defaultDescription: '_SERVICE_REQUEST_APPOINTMENT_NOTE',
    route: '/:tenantCode/service/request-appointment',
  },
  SERVICES_ADDITIONAL_SERVICES: {
    code: 'SERVICES_ADDITIONAL_SERVICES',
    icon: WalkinVideoVisitIconDpc,
    title: '_{{groupCode}}_SERVICE_ADDITIONAL_SERVICES',
    tenantSpecificTitle:
      '_{{groupCode}}_{{tenantCode}}_SERVICE_ADDITIONAL_SERVICES',
    defaultTitle: '_SERVICE_ADDITIONAL_SERVICES',
    description: '_{{groupCode}}_SERVICE_ADDITIONAL_SERVICES_NOTE',
    tenantSpecificDescription:
      '_{{groupCode}}_{{tenantCode}}_SERVICE_ADDITIONAL_SERVICES_NOTE',
    defaultDescription: '_SERVICE_ADDITIONAL_SERVICES_NOTE',
    route: '/:tenantCode/service/walk-in-video-visit',
  },
  SERVICES_MEMBERSHIP_ENROLLMENT: {
    code: 'SERVICES_MEMBERSHIP_ENROLLMENT',
    icon: MembershipIconDpc,
    title: '_{{groupCode}}_SERVICE_MEMBERSHIP_ENROLLMENT',
    tenantSpecificTitle:
      '_{{groupCode}}_{{tenantCode}}_SERVICE_MEMBERSHIP_ENROLLMENT',
    defaultTitle: '_SERVICE_MEMBERSHIP_ENROLLMENT',
    description: '_{{groupCode}}_SERVICE_MEMBERSHIP_ENROLLMENT_NOTE',
    tenantSpecificDescription:
      '_{{groupCode}}_{{tenantCode}}_SERVICE_MEMBERSHIP_ENROLLMENT_NOTE',
    defaultDescription: '_SERVICE_MEMBERSHIP_ENROLLMENT_NOTE',
    route: '/:tenantCode/service/membership',
  },
};

export default serviceMap;
