import React, { Component } from 'react';
import {
  Header,
  Footer
} from '../../common';
import Checkout from './Checkout';

class SmartAdvisor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        payment: {},
        iAgreeTerms: false,
        tenantId: '',
        merchantId: ''
      },
      address: {},
    }
  }
  render() {
    return (
      <>
        <Header />
        <Checkout
          data={this.state.data}
          address={this.state.address}
        />
      </>
    );
  }
}

export default SmartAdvisor;