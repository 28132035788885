import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

/**
 * Component for Material Design looklike Input Element.
 *
 * @props
 * id(string)
 * name(string) -> name attribute
 * type(string) -> input type (text/email/number)
 * value(string) -> value for the input element
 * inputChange(method) -> onChange method handler
 * icon(string) -> Font Awesome icon name (if not present, there won't be text-indent on the input element)
 * error(boolean) -> error state
 */

const MDInput = React.forwardRef((props, ref) => {
  const {
    id,
    name,
    type = 'text',
    value,
    label,
    inputChange,
    icon,
    error,
    disabled = false,
    ...rest
  } = props;
  return (
    <div className="md-input-main">
      <div className="md-input-box">
        <input
          id={id}
          ref={ref}
          name={name}
          value={value}
          onChange={(e) => inputChange && inputChange(e)}
          type={type}
          className="md-input bg-transparent text-titleColor font-heebo text-base"
          placeholder=" "
          style={{ textIndent: icon ? '2rem' : '' }}
          disabled={disabled}
          autoComplete="nope"
          {...rest}
        />
        <label
          htmlFor={id}
          className={`md-label text-base ${icon ? 'ml-8' : null}`}
        >
          {label}
        </label>
        {icon && <FontAwesomeIcon icon={icon} className="md-icon" />}
        {error && (
          <FontAwesomeIcon
            icon={faExclamationCircle}
            className="text-red-500"
            style={{
              position: 'absolute',
              top: '10px',
              right: '0px',
              fontSize: '16px',
            }}
          />
        )}
        <div
          className={`${
            error ? 'md-input-error-underline' : 'md-input-underline'
          }`}
        />
      </div>
    </div>
  );
});

export default MDInput;
