import React, { Component } from 'react';
import {
  Header,
  Banner,
  Loader,
  ValueProp,
  Benefit,
  CallToAction,
  ClientStory,
  Footer
} from '../../common';
import * as service from '../../service';
import AgentSlider from './AgentSlider';

class SmartAdvisor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cosmicContent: {
        banner: {},
        valueProps: {},
        agentList: [],
        benefits: [],
        cta: {},
        clientStories: []
      },
      loading: false
    }
  }

  componentDidMount() {
    this.getCosmicContent();
  }

  getCosmicContent = () => {
    this.setState({ loading: true });
    service
      .getAgentNetworkCosmicContent()
      .then((response) => {
        if (response.status === 'success') {
          const banner = response.data.find((item) => item.slug === 'banner-section').metadata;
          const valueProps = response.data.find((item) => item.slug === 'value-props-section').metadata;
          const agentList = response.data.find((item) => item.slug === 'agents-section').metadata;
          const benefits = response.data.find((item) => item.slug === 'benefits-section').metadata;
          const cta = response.data.find((item) => item.slug === 'cta-section').metadata;
          const clientStories = response.data.find((item) => item.slug === 'client-stories-section').metadata;
          this.setState(prevState => ({
            cosmicContent: {
              ...prevState.cosmicContent,
              banner: banner,
              valueProps: valueProps,
              agentList: agentList && agentList.agents,
              benefits: benefits && benefits.benefits,
              cta: cta,
              clientStories: clientStories && clientStories.client_stories
            }
          }))
        }
        this.setState({ loading: false });
      })
      .catch((error) => {
        console.log('Error in get Agent Network cosmic content', error);
        this.setState({ loading: false });
      });
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <Header />

        <Banner
          banner={this.state.cosmicContent.banner}
          titleClassName='uppercase'
          encloseImage={false}
          imageContainerClassName='hidden lg:block'
        />

        <div className='h-8 lg:hidden'></div>

        <ValueProp
          title={this.state.cosmicContent.valueProps.mission_statement}
          valuePropsTitle=''
          valuePropsTitleVisibility={false}
          values={this.state.cosmicContent.valueProps.value_props}
          bgColor={false}
          btnOnClick={true}
          actionBtnTxt='Join the team'
          handleActionBtnClick={() => this.props.history.push('/')}
        />

        <AgentSlider
          agentList={this.state.cosmicContent.agentList}
        />

        {this.state.cosmicContent.benefits !== undefined ? (
          <>
            <div className='divider'></div>
            <Benefit benefits={this.state.cosmicContent.benefits} />
            <div className='divider'></div>
          </>
        ) : null}

        <CallToAction
          title={this.state.cosmicContent.cta.title}
          description={this.state.cosmicContent.cta.description}
          actionButtonText={this.state.cosmicContent.cta.action_button_text}
          actionButtonLink={this.state.cosmicContent.cta.action_button_link}
        />

        <div className="inner-divider"></div>

        <ClientStory
          clientStories={this.state.cosmicContent.clientStories}
          partners={[]}
          visibility={false}
          bgColor={false}
        />

        <Footer />
      </>
    );
  }
}

export default SmartAdvisor;