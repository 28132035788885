import React, { useEffect, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import withSizes from 'react-sizes';
import {
  faBirthdayCake,
  faEnvelope,
  faIdCard,
  faUser,
} from '@fortawesome/free-solid-svg-icons';

import {
  FormTextField,
  FormAddressInput,
  FormMaskedDateInput,
  FormMaskedLast4SSNInput,
  FormGenderInput,
  FormSelectBloodGroup,
} from '../../../../common';

import { getIndividualSchema } from '../../../../schemas/membership';
import {
  selectInformationTab,
  setInformationTabValues,
} from '../../../../stores/membership';
import { store } from '../../../../store';
import { formatIndividualDemographicPayload } from '../../../../util/vitafyUtil';
import { checkEnrollmentTenantSetting } from '../../../../util/tenantSettingUtil';
import { getTextFromLangDict } from '../../../../util';
import SelectServices from './SelectServices';
import commonConstants from '../../../../constants/commonConstant';
import FormCountryPhoneInput from '../../../../common/form/FormCountryPhoneInput';
import { countryCode } from '../../../../constants/countryCode';
import { tenantCodeEnum } from '../../../../constants/tenantCodeConstant';

const { TENANT_SETTINGS_CODE, TENANT_SETTINGS_STATUS, REFERRED_BY_TYPE } =
  commonConstants;
const { TENANT_SERVICE, BLOOD_GROUP } = TENANT_SETTINGS_CODE;

const defaultValues = {
  firstName: '',
  middleName: '',
  lastName: '',
  dob: '',
  lastSSN: '',
  gender: '',
  email: '',
  phone: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zip: '',
  lat: '',
  lng: '',
  services: [],
  bloodGroup: '',
  countryCode: countryCode.USA,
  phoneExt: '1',
  textEnabled: false,
};

const IndividualForm = ({
  individualInfoFormRef,
  isMobile,
  whiteBg,
  onSubmit,
  referredBy,
  isClientAffiliate,
}) => {
  // Redux Store
  const informationTab = useSelector(selectInformationTab);

  // Context Store
  const state = useContext(store);
  const { globalState } = state;
  const { tenantId, groupCode, langDict, tenantSettings, tenantCode } =
    globalState;

  const showBloodGroupInput = useMemo(() => {
    return checkEnrollmentTenantSetting(BLOOD_GROUP, { tenantSettings });
  }, [tenantSettings]);

  const methods = useForm({
    resolver: yupResolver(
      getIndividualSchema({ isBloodGroupRequired: showBloodGroupInput })
    ),
    defaultValues,
  });
  const { getValues, handleSubmit, reset } = methods;

  const dispatch = useDispatch();

  const formSubmit = (formValues) => {
    dispatch(setInformationTabValues(getValues()));

    const payload = formatIndividualDemographicPayload({
      ...formValues,
      tenantId,
      type: informationTab.type,
      clientId: informationTab.clientId,
      clientEnrollmentId: informationTab.clientEnrollmentId,
      referredByType: isClientAffiliate
        ? REFERRED_BY_TYPE.CLIENT
        : REFERRED_BY_TYPE.AGENT,
      referredById: referredBy.value,
      referredByName: referredBy.label,
      groupCode,
    });

    onSubmit(payload);
  };

  /**
   * Prepopulate the data if exists
   */
  useEffect(() => {
    if (informationTab) {
      reset({
        firstName: informationTab.firstName ?? '',
        middleName: informationTab.middleName ?? '',
        lastName: informationTab.lastName ?? '',
        dob: informationTab.dob ?? '',
        lastSSN: informationTab.lastSSN ?? '',
        gender: informationTab.gender ?? '',
        email: informationTab.email ?? '',
        phone: informationTab.phone ?? '',
        addressLine1: informationTab.addressLine1 ?? '',
        addressLine2: informationTab.addressLine2 ?? '',
        city: informationTab.city ?? '',
        state: informationTab.state ?? '',
        zip: informationTab.zip ?? '',
        lat: informationTab.lat ?? '',
        lng: informationTab.lng ?? '',
        services: informationTab.services ?? [],
        bloodGroup: informationTab.bloodGroup ?? '',
        countryCode: informationTab?.countryCode || countryCode.USA,
        phoneExt: informationTab?.phoneExt ?? '1',
        textEnabled: informationTab?.textEnabled ?? false,
      });
    }
  }, [informationTab, reset]);

  /**
   * Update the store on unmount so that entered data are persisted
   */
  useEffect(() => {
    return () => {
      dispatch(
        setInformationTabValues({
          ...getValues(),
          referredByName: referredBy.label,
          referredById: referredBy.value,
        })
      );
    };
  }, [dispatch, getValues, referredBy]);

  const showSelectServices =
    tenantSettings?.cases?.find((item) => item.code === TENANT_SERVICE)
      ?.status === TENANT_SETTINGS_STATUS.ENABLED;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(formSubmit)} ref={individualInfoFormRef}>
        {/* Personal Information */}
        <h5 className="dedicated-form-title mb-3">
          {getTextFromLangDict(langDict, {
            key: '_ENTER_YOUR_PERSONAL_INFORMATION',
            groupCode,
            tenantCode,
          })}
        </h5>
        <div className="flex flex-wrap -mx-4">
          <div className="w-full md:w-1/3 px-4">
            <FormTextField
              name="firstName"
              label="First Name *"
              icon={isMobile ? '' : faUser}
            />
          </div>
          <div className="w-full md:w-1/3 px-4">
            <FormTextField name="middleName" label="Middle Name" />
          </div>
          <div className="w-full md:w-1/3 px-4">
            <FormTextField name="lastName" label="Last Name *" />
          </div>
        </div>
        <div className="flex flex-wrap -mx-4">
          <div className="w-full md:w-1/3 px-4">
            <FormMaskedDateInput
              name="dob"
              label="Date of Birth *"
              icon={isMobile ? '' : faBirthdayCake}
            />
          </div>
          <div className="w-full md:w-1/3 px-4">
            <FormMaskedLast4SSNInput
              helperText="This field is optional"
              name="lastSSN"
              label="Last Four SSN"
              icon={isMobile ? '' : faIdCard}
              showHelperText={tenantCode === tenantCodeEnum.GC}
            />
          </div>
          <FormGenderInput name="gender" whiteBg={whiteBg} />
        </div>
        {showBloodGroupInput && (
          <div className="flex flex-wrap -mx-4 mt-3 md:mt-0">
            <div className="w-full md:w-1/3 px-4">
              <FormSelectBloodGroup />
            </div>
          </div>
        )}

        <div className="form-section-divider-md"></div>

        {/* Contact Information */}
        <h5 className="dedicated-form-title mb-3">
          {getTextFromLangDict(langDict, {
            key: '_ENTER_YOUR_CONTACT_INFORMATION',
            groupCode,
            tenantCode,
          })}
        </h5>
        <div className="flex flex-wrap -mx-4">
          <div className="w-full md:w-1/2 px-4">
            <FormTextField
              name="email"
              label="Email *"
              icon={isMobile ? '' : faEnvelope}
            />
          </div>
          <div className="w-full flex md:w-1/2 px-4">
            <FormCountryPhoneInput label="Phone *" />
          </div>
        </div>
        <FormAddressInput isRequired />
        {showSelectServices && <SelectServices />}
      </form>
    </FormProvider>
  );
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 768,
});

export default withSizes(mapSizesToProps)(IndividualForm);
