import React, { useState } from 'react';
import withSizes from 'react-sizes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserTie,
  faBuilding,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';
import ClientInfoCompanyForm from './ClientInfoCompanyForm';
import ClientInfoIndividualForm from './ClientInfoIndividualForm';

const ClientInformation = (props, { isMobile }) => {
  const { client, address } = props;
  const [registerCategory, setRegisterCategory] = useState(1);
  React.useEffect(() => {
    setRegisterCategory(
      client &&
        client.clientType &&
        client.clientType.toLowerCase() === 'individual'
        ? 2
        : 1
    );
  }, []);
  React.useLayoutEffect(() => {
    setRegisterCategory(
      client &&
        client.clientType &&
        client.clientType.toLowerCase() === 'individual'
        ? 2
        : 1
    );
  }, [client.clientType]);
  return (
    <>
      <h4>Client Information</h4>
      <div className='flex -mx-4'>
        <div className='w-1/2 px-4'>
          <div
            className={`client-info-option-box ${
              registerCategory === 1 ? 'border-extended shadow-focus relative' : null
              }`}
            onClick={() => !client.id && (setRegisterCategory(1), (client.clientType = 'Company'))}
          >
            <div className='w-1/4 px-2 text-right hidden sm:block'>
              <FontAwesomeIcon
                icon={faBuilding}
                className={`client-info-option-icon ${
                  registerCategory === 1 ? 'text-extended' : null
                  }`}
              />
            </div>
            <div className='w-3/4 px-2'>
              <p
                className={`body-text client-info-option-text-color text-xs md:text-base ${
                  registerCategory === 1 ? 'text-extended' : null
                  }`}
              >
                Register as
              </p>
              <p
                className={`uppercase font-bold client-info-option-text-color text-sm md:text-base ${
                  registerCategory === 1 ? 'text-extended' : null
                  }`}
              >
                Company {!isMobile ? ' & Groups' : null}
              </p>
            </div>
            {registerCategory === 1 ? <FontAwesomeIcon
              icon={faCheckCircle}
              className='text-4xl text-extended absolute top-0 left-0 pl-3 pt-3'
            /> : null}
          </div>
        </div>
        <div className='w-1/2 px-4'>
          <div
            className={`client-info-option-box ${
              registerCategory === 2 ? 'border-extended shadow-focus relative' : null
              }`}
            onClick={() => !client.id && (setRegisterCategory(2), (client.clientType = 'Individual'))}
          >
            <div className='w-1/4 px-2 text-right hidden sm:block'>
              <FontAwesomeIcon
                icon={faUserTie}
                className={`client-info-option-icon ${
                  registerCategory === 2 ? 'text-extended' : null
                  }`}
              />
            </div>
            <div className='w-3/4 px-2'>
              <p
                className={`body-text client-info-option-text-color text-xs md:text-base ${
                  registerCategory === 2 ? 'text-extended' : null
                  }`}
              >
                Register as
              </p>
              <p
                className={`uppercase font-bold client-info-option-text-color text-sm md:text-base ${
                  registerCategory === 2 ? 'text-extended' : null
                  }`}
              >
                Individual {!isMobile ? ' & Family' : null}
              </p>
            </div>
            {registerCategory === 2 ? <FontAwesomeIcon
              icon={faCheckCircle}
              className='text-4xl text-extended absolute top-0 left-0 pl-3 pt-3'
            /> : null}

          </div>
        </div>
      </div>

      {/* Forms (Register as Individual or Company) */}
      <div className='mt-8'>
        {registerCategory === 1 ? (
          <ClientInfoCompanyForm
            client={client}
            address={address}
            clientInfoRef={props.clientInfoRef}
            submit={props.submit}
          />
        ) : (
            <ClientInfoIndividualForm
              client={client}
              address={address}
              clientInfoRef={props.clientInfoRef}
              submit={props.submit}
            />
          )}
      </div>
    </>
  );
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 640,
});

export default withSizes(mapSizesToProps)(ClientInformation);
