/**
 * @author Slesha Tuladhar <slesha@noveltytechnology.com>
*/

import React, { Component } from 'react'
import SearchAutocomplete from './searchAutocomplete';
import * as networkProviderService from '../service/networkProviderService';
import { showToast } from '../service/toasterService';
import { getDisplayName } from '../util/vitafyUtil';
import { _debounce } from '../util/lodashUtil';
import { escapeRegexChars } from "../util/escapeRegexCharsUtil.js";

class ProvidersAutocomplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      suggestions: [],
      displayNameValue: this.props.displayNameValue ? this.props.displayNameValue : '',
      selectedItem: {},
      searchAll: this.props.searchAll ? this.props.searchAll : false,
      type: this.props.type ? this.props.type : ''
    };
    this.onSuggestionsFetchRequested = _debounce(this.onSuggestionsFetchRequested.bind(this), 300);
  }
  getSuggestions = (value) => {
    const escapedValue = escapeRegexChars(value ? value.trim() : '');
    const regex = new RegExp('^' + escapedValue, 'i');
    return this.state.list.filter(x => regex.test(x.displayName));
  }
  getSuggestionDisplayName = (suggestion) => suggestion.displayName;
  renderSuggestion = (suggestion) => {
    return (
      <span>{suggestion.displayName}</span>
    );
  }
  onInputChange = (event, { newValue }) => {
    this.setState({
      displayNameValue: newValue,
    }, () => {
      this.props.handleInputChange(this.state.displayNameValue);
    });
  };
  onSuggestionsFetchRequested = async ({ value }) => {
    let searchParams = {
      isGenericSearch: true,
      searchKeyword: value,
      searchAll: this.state.searchAll,
      type: this.state.type
    };
    const list = await this.searchProviders(searchParams);
    this.setState({
      list
    }, () => {
      this.setState({
        suggestions: this.getSuggestions(value)
      });
    });
  };
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };
  onSuggestionSelected = (event, { suggestion }) => {
    this.setState({
      displayNameValue: suggestion.displayName,
      selectedItem: suggestion
    }, () => {
      this.onSelectHandler();
    });
  };
  shouldRenderSuggestions = (value) => {
    return value.trim().length > 1;
  }
  searchProviders = (searchParams) => {
    return new Promise((resolve, reject) => {
      networkProviderService.searchNetworkProvider(searchParams)
        .then(response => {
          const res = searchParams.type === 'dpc' ? response.data.rows.reverse() : response.data.data.reverse();
          res.map(x => {
            x.displayName = getDisplayName(x);
            return x;
          })
          resolve(res);
        }).catch(err => {
          showToast('error', 'Error searching providers');
          console.log('Error searching providers', err);
          reject(err);
        })
    })
  }
  onSelectHandler = () => {
    this.props.onSelectHandler(this.state.selectedItem);
  }
  render() {
    const autosuggestInputs = {
      suggestions: this.state.suggestions,
      shouldRenderSuggestions: this.shouldRenderSuggestions,
      onSuggestionsFetchRequested: this.onSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.onSuggestionsClearRequested,
      onSuggestionSelected: this.onSuggestionSelected,
      getSuggestionDisplayName: this.getSuggestionDisplayName,
      renderSuggestion: this.renderSuggestion,
      inputProps: {
        value: this.state.displayNameValue,
        onChange: this.onInputChange,
        placeholder: this.props.placeholder,
        required: this.props.inputRequired,
      },
    }
    return (
      <SearchAutocomplete
        autosuggestInputs={autosuggestInputs}
        iconType={this.props.iconType}
      />
    );
  }
}

export default ProvidersAutocomplete;