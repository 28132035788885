import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle
} from '@fortawesome/free-regular-svg-icons';
import { faStar, faCheck } from '@fortawesome/free-solid-svg-icons';
import * as vitafyUtils from '../../util/vitafyUtil';

const Pricing = (props) => {
  const { pricing } = props;
  const { basic, premium, professional } = pricing;
  const [pricedAnnually, setPricedAnnually] = useState(true);
  const [activePlanMobile, setActivePlanMobile] = useState('professional');

  const history = useHistory();

  const features = [
    { name: 'Join free-market community', basic: true, premium: true, professional: true },
    { name: 'Dedicated provider profile', basic: true, premium: true, professional: true },
    { name: 'List services & pricing', basic: true, premium: true, professional: true },
    { name: 'Appear in local searches', basic: true, premium: true, professional: true },
    { name: 'Featured provider profile', basic: false, premium: true, professional: true },
    { name: 'Transparent Pricing Marketplace', basic: false, premium: true, professional: true },
    { name: 'Online appointment request', basic: false, premium: true, professional: true },
    { name: 'Phone, chat and email support', basic: false, premium: true, professional: true },
    { name: 'Practice Management Software', basic: false, premium: false, professional: true, textBold: true },
    { name: 'Dedicated Landing Page', basic: false, premium: false, professional: true },
    { name: 'Customize group & individual plans', basic: false, premium: false, professional: true },
    { name: 'Enroll families & individuals', basic: false, premium: false, professional: true },
    { name: 'Enroll companies & groups', basic: false, premium: false, professional: true },
    { name: 'Accept walk-in patients', basic: false, premium: false, professional: true },
    { name: 'Accept online & in-person payments', basic: false, premium: false, professional: true },
    { name: 'Automated billing', basic: false, premium: false, professional: true },
    { name: 'Self-service employer portal', basic: false, premium: false, professional: true },
    { name: 'Self-service member portal', basic: false, premium: false, professional: true },
    { name: 'Manage and charge for ancillary services', basic: false, premium: false, professional: true },
    { name: 'Generate financial & transaction reports', basic: false, premium: false, professional: true },
    { name: 'Generate membership and activity reports', basic: false, premium: false, professional: true },
  ]

  const featuresComingSoon = [
    { name: 'Virtual visit / Telemedicine (in beta)', basic: false, premium: false, professional: false },
    { name: 'Specialty referral system (in beta)', basic: false, premium: false, professional: false },
    { name: 'Mobile app', basic: false, premium: false, professional: false },
    { name: 'Integration to 3rd party systems', basic: false, premium: false, professional: false },
    { name: 'Patient experience survey', basic: false, premium: false, professional: false },
    { name: 'Savings report', basic: false, premium: false, professional: false }
  ]

  const showPlan = [
    // 'basic',
    // 'premium',
    'professional'
  ]

  // const recommendedPlan = 'professional';
  const recommendedPlan = '';


  const displayFeatures = features && features.map((feature, index) =>
    <>
      <div className='flex my-2 -mx-4 justify-between' key={index}>
        <div className='w-1/4 px-4'>
          <p className={`body-text font-sm ${feature.textBold && 'text-titleColor font-bold'}`}> {feature.name} </p>
        </div>
        {showPlan?.map(plan =>
          <div className={`w-1/4 px-4 flex justify-center items-center`}>
            {feature[plan] && <FontAwesomeIcon icon={faCheck} className='text-green-500 text-base block' />}
          </div>
        )}
      </div>
      <hr />
    </>
  )

  const displayFeaturesMobile = features && features.map((feature, index) =>
    <>
      <div className='flex my-2 justify-between' key={index}>
        <div className='flex-grow pr-8'>
          <p className={`body-text font-sm ${feature.textBold && 'text-titleColor font-bold'}`}> {feature.name} </p>
        </div>
        <div className='flex justify-center items-center'>
          {feature[activePlanMobile] && <FontAwesomeIcon icon={faCheck} className='text-green-500 text-base block' />}
        </div>
      </div>
      <hr />
    </>
  )

  const displayFeaturesComingSoon = featuresComingSoon && featuresComingSoon.map((feature, index) =>
    <>
      <div className='flex my-2 -mx-4 justify-between' key={index}>
        <div className='w-1/4 px-4'>
          <p className='body-text font-sm opacity-50'> {feature.name} </p>
        </div>
        {showPlan?.map(plan =>
          <div className={`w-1/4 px-4 flex justify-center items-center`}>
            {feature[plan] && <FontAwesomeIcon icon={faCheck} className='text-green-500 text-base block' />}
          </div>
        )}
      </div>
      <hr />
    </>
  )

  const displayFeaturesComingSoonMobile = featuresComingSoon && featuresComingSoon.map((feature, index) =>
    <>
      <div className='flex my-2 justify-between z-0' key={index}>
        <div className='flex-grow pr-8'>
          <p className={`body-text font-sm opacity-50`}> {feature.name} </p>
        </div>
        <div className='flex justify-center items-center'>
          {feature[activePlanMobile] && <FontAwesomeIcon icon={faCheck} className='text-green-500 text-base block' />}
        </div>
      </div>
      <hr />
    </>
  )

  const handleChoosePlanMobile = () => {
    let chosenPlan;
    if (activePlanMobile === 'basic') chosenPlan = basic;
    else if (activePlanMobile === 'premium') chosenPlan = premium;
    else if (activePlanMobile === 'professional') chosenPlan = professional;
    if (!chosenPlan) return;
    const interval = pricedAnnually ? 'annually' : 'monthly';
    history.push({
      pathname: '/direct-primary-care/register',
      search: `?plan=${chosenPlan.plan}&interval=${interval}`,
    })
  }

  const checkIsRecommendedPlan = (plan) => {
    return plan?.toLowerCase() === recommendedPlan?.toLowerCase();
  }

  return (
    <>
      <div className='container mx-auto px-4'>
        <h3 className='heading-center mx-auto text-center block mb-5'>Choose the best plan</h3>
        {showPlan.length > 1 &&
          <h5 className='body-text text-xl text-center mb-6 md:mb-8 lg:mb-12'>Flexible options to suit your needs</h5>
        }
      </div>
      {/* Pricing Options and features for larger screeens */}
      <div className='hidden lg:block container mx-auto px-4'>
        <div className='sticky-pricing-header'>
          <hr />
          <div className={`flex my-10 -mx-4 bg-white justify-between`}>
            <div className='w-1/4 px-4'>
              <h4 className={` text-titleColor text-lg`}>
                Billing Option
              </h4>
              <div className='mt-6'>
                <div className="flex items-center mr-4 mb-4" >
                  <input id="annually-radio" type="radio" name="pricing-plan-radio" className="hidden" checked={pricedAnnually === true} onClick={() => setPricedAnnually(true)} />
                  <label for="annually-radio" className="flex items-center cursor-pointer body-text font-sm">
                    <span className="w-6 h-6  inline-block mr-2 rounded-full border border-grey flex-no-shrink"></span>
                    Annually</label>
                </div>
                <div className="flex items-center mb-4" >
                  <input id="monthly-radio" type="radio" name="pricing-plan-radio" className="hidden" checked={pricedAnnually === false} onClick={() => setPricedAnnually(false)} />
                  <label for="monthly-radio" className="flex items-center cursor-pointer body-text font-sm">
                    <span className="w-6 h-6 inline-block mr-2 rounded-full border border-grey flex-no-shrink"></span>
                    Monthly</label>
                </div>
              </div>
            </div>
            {showPlan?.map(plan =>
              <div className={`w-1/4 px-4 ${checkIsRecommendedPlan(plan) ? 'relative' : ''}`}>
                {checkIsRecommendedPlan(plan) &&
                  <div className='absolute left-0 right-0 flex justify-center items-center' style={{ top: '-15%' }}>
                    <FontAwesomeIcon icon={faStar} className='text-secondary mr-1 text-base block' />
                    <p className='body-text text-sm text-secondary'>
                      Recommended
                    </p>
                  </div>
                }
                <div className='flex flex-col justify-between items-center'>
                  <h4 className={`${checkIsRecommendedPlan(plan) ? 'text-primary' : 'text-titleColor'} uppercase`}>
                    {plan}
                  </h4>
                  {plan.toLowerCase() === 'basic'
                    ? <h2 className={`font-heebo font-bold ${checkIsRecommendedPlan(plan) ? 'text-primary' : 'text-titleColor'} text-5xl mt-2 mb-6`}>
                      Free
                    </h2>
                    : <div className='mt-1 mb-4'>
                      <h2 className={`font-heebo font-bold ${checkIsRecommendedPlan(plan) ? 'text-primary' : 'text-titleColor'} text-5xl text-center`}>
                        {pricedAnnually ? vitafyUtils.formatCurrency(eval(plan)?.annual_cost, null, 0)
                          : vitafyUtils.formatCurrency(eval(plan)?.monthly_cost, null, 0)}
                      </h2>
                      <p className='body-text text-sm text-center -mt-2'>
                        per month {plan.toLowerCase() === 'professional' ? '+ $0.25 pmpm' : ''}
                      </p>
                    </div>
                  }
                  <button
                    className='small-button block text-base py-2 px-8'
                    onClick={() => {
                      const interval = pricedAnnually ? 'annually' : 'monthly';
                      history.push({
                        pathname: '/direct-primary-care/register',
                        search: `?plan=${eval(plan)?.plan}&interval=${interval}`
                      })
                    }}
                  >
                    <FontAwesomeIcon icon={faCheckCircle} className='text-white mr-2' />
                    Choose plan
                  </button>
                </div>
              </div>
            )}
            {/* </div> */}
          </div>
          <hr />
        </div>
        <h4 className='my-4'> Features</h4>
        <hr />
        {displayFeatures}
        <p className='body-text my-2 text-titleColor font-bold text-base'> Coming Soon</p>
        <hr />
        {displayFeaturesComingSoon}
      </div>
      {/* Pricing Options and features for smaller screens */}
      <div className='block lg:hidden'>
        <div className='container mx-auto px-4'>
          <h4 className={` text-titleColor text-lg text-center`}>
            Billing Option
          </h4>
          <div className='flex justify-center items-center mt-6'>
            <div className="flex items-center mr-10" >
              <input id="annually-mobile-radio" type="radio" name="pricing-plan-mobile-radio" className="hidden" checked={pricedAnnually === true} onClick={() => setPricedAnnually(true)} />
              <label for="annually-mobile-radio" className="flex items-center cursor-pointer body-text font-sm">
                <span className="w-6 h-6  inline-block mr-2 rounded-full border border-grey flex-no-shrink"></span>
                Annually</label>
            </div>
            <div className="flex items-center" >
              <input id="monthly-mobile-radio" type="radio" name="pricing-plan-mobile-radio" className="hidden" checked={pricedAnnually === false} onClick={() => setPricedAnnually(false)} />
              <label for="monthly-mobile-radio" className="flex items-center cursor-pointer body-text font-sm">
                <span className="w-6 h-6 inline-block mr-2 rounded-full border border-grey flex-no-shrink"></span>
                Monthly</label>
            </div>
          </div>
        </div>
        <div className='mt-10 flex md:-mx-1/2 mb-6 sticky-pricing-header-mobile'>
          {showPlan?.map(plan =>
            <div className={`${checkIsRecommendedPlan(plan) ? 'w-2/4' : 'w-1/4'} md:w-1/3 md:px-1/2`}>
              <div
                className={`pricing-plan 
                ${activePlanMobile?.toLowerCase() === plan?.toLowerCase() ? 'bg-bgColorDarker active-pricing-plan' : 'bg-bgColor'} 
                ${checkIsRecommendedPlan(plan) ? 'py-2' : 'py-8'} 
                ${plan.toLowerCase() !== 'basic' && 'pb-4'}`}
                onClick={() => setActivePlanMobile(plan)}
              >
                {checkIsRecommendedPlan(plan) &&
                  <div className='flex justify-center items-center mb-1'>
                    <FontAwesomeIcon icon={faStar} className='text-secondary mr-1 text-base block' />
                    <p className='body-text text-sm text-secondary'>
                      Recommended</p>
                  </div>
                }
                <h4 className={`text-sm ${checkIsRecommendedPlan(plan) ? 'text-primary' : 'text-titleColor'} uppercase`}>
                  {plan}
                </h4>
                {plan.toLowerCase() === 'basic'
                  ? <h2 className={`font-heebo font-bold ${checkIsRecommendedPlan(plan) ? 'text-primary' : 'text-titleColor'} text-2xl`}>
                    Free
                  </h2>
                  : <>
                    <h2 className={`font-heebo font-bold ${checkIsRecommendedPlan(plan) ? 'text-primary' : 'text-titleColor'} text-2xl`}>
                      {pricedAnnually ? vitafyUtils.formatCurrency(eval(plan)?.annual_cost, null, 0)
                        : vitafyUtils.formatCurrency(eval(plan)?.monthly_cost, null, 0)}
                    </h2>
                    <p className='body-text text-xs text-center -mt-1'>
                      per month {plan.toLowerCase() === 'professional' ? '+ $0.25 pmpm' : ''}
                    </p>
                  </>
                }
              </div>
            </div>
          )}
        </div>
        <div className='container mx-auto px-4'>
          <h4 className='my-4 text-xl'> Features</h4>
          {displayFeaturesMobile}
          <p className='body-text my-2 text-titleColor font-bold text-base'> Coming Soon</p>
          <hr />
          {displayFeaturesComingSoonMobile}

          <button
            className='small-button block text-base py-2 px-8 w-full mt-6'
            onClick={handleChoosePlanMobile}
          >
            <FontAwesomeIcon icon={faCheckCircle} className='text-white mr-2' />
            Choose plan
          </button>
        </div>
      </div>
    </>
  );
}

export default Pricing;