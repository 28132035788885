import React, { useContext } from 'react';

import { DedicatedFormModal } from '../../../common';
import serviceMap from '../../../constants/serviceMap';

import { store } from '../../../store';
import { parseHtml } from '../../../util';

const DisclaimerModal = ({
  modalRef,
  serviceCode,
  handleContinue,
  handleCancel,
}) => {
  const state = useContext(store);
  const { globalState } = state;
  const { primaryLocation, langDict, isLoading } = globalState;

  const groupCode = primaryLocation?.groupCode;

  if (!langDict || !serviceCode) {
    return <>...</>;
  }

  const disclaimerTitle =
    langDict[
      `_${groupCode}_SERVICE_${serviceCode.substring(9)}_ATTENTION_TITLE`
    ] ?? langDict._SERVICE_MEMBERSHIP_ENROLLMENT_ATTENTION_TITLE;
  const disclaimerMsg =
    langDict[`_${groupCode}_SERVICE_${serviceCode.substring(9)}_ATTENTION`] ??
    langDict._SERVICE_MEMBERSHIP_ENROLLMENT_ATTENTION;

  const showContinueButton =
    serviceCode?.toLowerCase() ===
    serviceMap.SERVICES_MEMBERSHIP_ENROLLMENT.code.toLowerCase();

  return (
    <DedicatedFormModal modalTitle={disclaimerTitle} ref={modalRef}>
      <div className="modal-body">
        {isLoading ? (
          <span>Loading...</span>
        ) : (
          <div
            className="body-text text-titleColor"
            dangerouslySetInnerHTML={{
              __html: parseHtml(disclaimerMsg),
            }}
          />
        )}
      </div>
      <div className="modal-footer flex items-center justify-between">
        <button
          className="mid-button px-4 py-3 bg-transparent text-dedicatedDpcSecondary hover:bg-gray-300"
          onClick={handleCancel}
          type="button"
        >
          Close
        </button>
        {showContinueButton && (
          <button
            className="mid-button px-4 py-3 bg-dedicatedDpcSecondary hover:bg-dedicatedDpcHovered"
            onClick={handleContinue}
            type="button"
          >
            Continue
          </button>
        )}
      </div>
    </DedicatedFormModal>
  );
};

export default DisclaimerModal;
