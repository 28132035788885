/**
 * Provider Registration Process is bit different for 'Basic Plan'
 * 'Basic Plan' doesn't contain any payment method. The formSubmit btn is placed inside 'Provider Information'
 * Under the corresponding 'Payment Summary' section, options to switch to 'Premium' or 'Professional' plan is shown.
 */

import React, { Component } from "react";
import { ReactTitle } from "react-meta-tags";

import * as enrollmentService from "../../service/enrollmentService";
import * as salesLeadService from "../../service/salesLeadService";
import * as service from "../../service";

import { Header, Footer, Loader } from "../../common";
import Checkout from "./Checkout";
import PaymentConfirmation from "./PaymentConfirmation";

import commonConstants from "../../constants/commonConstant";

const PARTIAL_REGISTRATION_STATIC_DATA = {
  STATUS_IN_PROGRESS: "In Progress",
  STATUS_COMPLETED: "Completed",
  TYPE: "Company",
  NOTES: "Provider Registration",
  REFERRED_BY: "Website", // equivalent to source in portal
};
class DpcRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      id: null, // for updating the provider info while checkout
      paymentMethod: "card",
      data: {
        payment: {},
        iAgreeTerms: false,
        tenantId: "",
        tenantCode: "VITAFYHEALTH",
        id: null, // Stores id from the sales lead (save) for updating exsting record
      },
      address: {},
      cardErrorMessage: "",
      errorMessage: "", // Error Message from Server,
      tmpPaymentData: {
        card: {
          token: "",
          expiry: "",
        },
        bank: {
          token: "",
        },
      },
      paymentInterval: "monthly",
      paymentEnvironment: null,
      isSubmitting: false,
      isProviderInfoSubmitting: false,
      checkoutSuccess: false,
      chosenPricingPlan: {},
      promoCode: {
        promoCode: null,
        isChecking: false,
        success: false,
        checked: false,
        discountAmount: null,
      },
      providerInfoSubmitted: false, // providerInfoSubmit request is not made if set to true
      pricing: {},
      bannerContent: {},
      seo: {},
      loading: true,
    };
    this.providerInfoFormRef = React.createRef();
    this.formInputViewRef = React.createRef();
  }

  componentDidMount() {
    this.getBannerContent();
    this.getCosmicContent()
      .then((response) => {
        if (response.status === "success") {
          const dpcSales = response.data.find(
            (item) => item.slug === "dpc-sales"
          ).metadata;
          const dpcRegister = response.data.find(
            (item) => item.slug === "dpc-register"
          ).metadata;
          if (dpcSales || dpcRegister) {
            this.setState(
              {
                pricing: {
                  basic: dpcSales.pricing.find(
                    (item) => item.plan.toLowerCase() == "basic"
                  ),
                  premium: dpcSales.pricing.find(
                    (item) => item.plan.toLowerCase() == "premium"
                  ),
                  professional: dpcSales.pricing.find(
                    (item) => item.plan.toLowerCase() == "professional"
                  ),
                },
                seo: dpcRegister.seo,
              },
              () => {
                this.getChosenPlanFromQueryParams();
              }
            );
          }
        }
      })
      .catch((error) => {
        console.log(
          "Error while fetching merchantProfile by referenceId",
          error
        );
      });
  }

  getChosenPlanFromQueryParams = () => {
    const query = new URLSearchParams(this.props.location.search);
    const plan = query.get("plan");
    const interval = query.get("interval");

    if (!plan || !interval) {
      this.props.history.push("/404");
      return;
    }

    this.setState((prevState) => ({
      chosenPricingPlan: prevState.pricing[plan.toLowerCase()],
      paymentInterval: interval,
    }));
    this.fetchTenantByCode();
  };

  fetchTenantByCode = () => {
    enrollmentService
      .fetchTenantByCode(this.state.data.tenantCode)
      .finally(() => {
        this.setState({ loading: false });
      })
      .then((response) => {
        if (response.status === "success") {
          this.setState(
            (prevState) => ({
              data: { ...prevState.data, tenantId: response.data.tenantId },
            }),
            () => this.fetchPaymentEnvironment(this.state.data.tenantId)
          );
        }
      })
      .catch((error) =>
        console.log("Error while fetching tenant by code", error)
      );
  };

  fetchPaymentEnvironment = (tenantId) => {
    enrollmentService
      .fetchMerchantProfileByReferenceId(tenantId)
      .then((response) => {
        if (response.status === "success") {
          const environment = response.data && response.data.environment;
          this.setState({ paymentEnvironment: environment });
        }
      })
      .catch((error) =>
        console.log(
          "Error while fetching merchantProfile by referenceId",
          error
        )
      );
  };

  getBannerContent = () => {
    this.setState({ loading: true });
    service
      .getDpcCosmicContent()
      .then((response) => {
        if (response.status === "success") {
          const dpcRegister = response.data.find(
            (item) => item.slug === "dpc-register"
          ).metadata;
          if (dpcRegister) {
            this.setState({
              bannerContent: {
                title: dpcRegister.banner?.title,
                tagline: dpcRegister.banner?.tagline,
                taglineVisibility: dpcRegister.banner?.tagline_visibility,
              },
            });
          }
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        console.log("Error in get DPC Landing cosmic content", error);
        this.setState({ loading: false });
      });
  };

  getCosmicContent = () => {
    return new Promise((resolve, reject) => {
      this.setState({ loading: true });
      service
        .getDpcCosmicContent()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  handleChangeIAgreeTerms = (value) => {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        iAgreeTerms: value,
      },
    }));
  };

  handleChangeTmpPaymentData = (type, data) => {
    if (type === "card") {
      this.setState((prevState) => ({
        tmpPaymentData: {
          ...prevState.tmpPaymentData,
          card: {
            token: data.token,
            expiry: data.expiry,
          },
        },
      }));
    } else if (type === "bank") {
      this.setState((prevState) => ({
        tmpPaymentData: {
          ...prevState.tmpPaymentData,
          bank: {
            token: data.token,
          },
        },
      }));
    }
  };

  handleChangePaymentInterval = (value) => {
    if (!value) return;
    this.setState({
      paymentInterval: value.toLowerCase(),
    });
  };

  handleRemoveErrorMessage = () => {
    this.setState({ errorMessage: "" });
  };

  handleChangePaymentMethod = (value) => {
    if (!value) return;
    this.setState((prevState) => ({
      paymentMethod: value.toLowerCase(),
      errorMessage: "",
      data: {
        ...prevState.data,
        iAgreeTerms: false,
      },
    }));
  };

  handleChangePromoCode = () => {
    this.setState((prevState) => ({
      promoCode: {
        ...prevState.promoCode,
        checked: false,
        success: false,
        isChecking: false,
      },
    }));
  };

  handleCheckPromoCode = (promoCode) => {
    if (!promoCode) return;
    this.setState((prevState) => ({
      promoCode: {
        ...prevState.promoCode,
        isChecking: true,
      },
    }));
    enrollmentService
      .checkPromoCode(promoCode)
      .then((response) => {
        if (response.status === "success") {
          const receivedPromoCode =
            response.data &&
            response.data.find((item) => item.code === promoCode);
          // Include discount only if the promoCode is active
          if (receivedPromoCode && receivedPromoCode.isActive) {
            this.setState((prevState) => ({
              promoCode: {
                ...prevState.promoCode,
                success: true,
                discountAmount: receivedPromoCode.discount,
                promoCode: receivedPromoCode.code,
              },
            }));
          } else {
            this.setState((prevState) => ({
              promoCode: {
                ...prevState.promoCode,
                success: false,
              },
            }));
          }
        }
        this.setState((prevState) => ({
          promoCode: {
            ...prevState.promoCode,
            isChecking: false,
            checked: true,
          },
        }));
      })
      .catch((error) => {
        console.log(error);
        this.setState((prevState) => ({
          promoCode: {
            ...prevState.promoCode,
            isChecking: false,
          },
        }));
      });
  };

  /**
   * Form Submit Handler for Provider Information
   * Saves the provider information in its state (data)
   * Send the POST request to save provider info
   * Shows confirmation and exists if the plan is 'Basic'
   * If plan is Premium or Professional, changes the step to 2
   *
   * Saves the partial registration data in sales lead (for teant: VitafyHealth)
   * Step 1 -> status = 'In Progress'
   *           status = 'Completed' for Basic Plan (as there is not payment information for basic plan)
   * Step 2 -> status = 'Completed'
   * Use type as 'Company'
   * Don't create new record if user moves back to step 1 and proceeds again, update the existing record by id in that case
   * Saving Partial Registraiton Data to Sales lead is done by Registration and Checkout (from server-side)
   */
  handleProviderInfoSubmit = (formValues) => {
    this.setState(
      (prevState) => ({
        data: {
          ...prevState.data,
          ...formValues,
          planType: prevState.chosenPricingPlan.plan,
        },
      }),
      () => {
        // Multi step form in mobile view. No need to submit provider Info when user goes back and forth between steps
        // for sales lead, update the existing record
        if (this.state.providerInfoSubmitted) {
          const status = PARTIAL_REGISTRATION_STATIC_DATA.STATUS_IN_PROGRESS;
          this.updatePartialRegistrationToSalesLead(this.state.data, status);
          this.handleChangeStep(2);
          return;
        }

        const isBasic =
          this.state.chosenPricingPlan.plan &&
          this.state.chosenPricingPlan.plan.toLowerCase() === "basic";

        this.setState({ isProviderInfoSubmitting: true });
        this.handleRegisterProvider(this.state.data)
          .finally(() => this.setState({ isProviderInfoSubmitting: false }))
          .then((response) => {
            if (response.status === "success") {
              if (isBasic) this.setState({ checkoutSuccess: true });
              // same state variable is used for Basic Plan even if it doesn't contain checkout
              else {
                this.setState({
                  id: response.data.id,
                  providerInfoSubmitted: true,
                });
                this.handleChangeStep(2);
              }
            }
          })
          .catch((error) => {
            console.log("Error while registering provider", error);
          });

        const status = isBasic
          ? PARTIAL_REGISTRATION_STATIC_DATA.STATUS_COMPLETED
          : PARTIAL_REGISTRATION_STATIC_DATA.STATUS_IN_PROGRESS;
        this.savePartialRegistrationToSalesLead(this.state.data, status);
      }
    );
  };

  // register provider info only (no payment summary)
  handleRegisterProvider = (formValues) => {
    return new Promise((resolve, reject) => {
      enrollmentService
        .registerProvider(formValues)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // Checkout form submit
  // Provider Info is also attached along with payment summary
  handleSubmitCheckout = () => {
    console.log("HandleSubmit Checkout");
    let checkoutData = { ...this.state.data };
    // append the id got from the response of provider info submit as checkout submit is PUT
    checkoutData.id = this.state.id;
    // append the card/bank information (token and expiry)
    if (this.state.paymentMethod.toLowerCase() === "card") {
      checkoutData.payment = {
        paymentType: "Card",
        token: this.state.tmpPaymentData.card.token,
        expiry: this.state.tmpPaymentData.card.expiry,
        environment: this.state.paymentEnvironment,
      };
    } else if (this.state.paymentMethod.toLowerCase() === "bank") {
      checkoutData.payment = {
        paymentType: "Bank",
        token: this.state.tmpPaymentData.bank.token,
        environment: this.state.paymentEnvironment,
      };
    }
    // append pricing interval (monthly/annually) and its respective costs
    checkoutData.paymentInterval = this.state.paymentInterval;
    checkoutData.setupCost = this.state.chosenPricingPlan.one_time_setup_fee;
    checkoutData.subscriptionAmount = this.state.paymentInterval
      ? this.state.chosenPricingPlan.monthly_cost
      : this.state.chosenPricingPlan.annualCost;
    // Send discountAmt and promoCode if available
    if (this.state.promoCode.checked && this.state.promoCode.success) {
      checkoutData.discountAmount = this.state.promoCode.discountAmount;
      checkoutData.promoCode = this.state.promoCode.promoCode;
    } else {
      checkoutData.discountAmount = null;
      checkoutData.promoCode = null;
    }
    checkoutData.tenantId = this.state.data.tenantId;

    this.setState({ isSubmitting: true });
    enrollmentService
      .submitProviderRegistrationCheckout(checkoutData)
      .finally(() => {
        this.setState({ isSubmitting: false });
      })
      .then((response) => {
        if (response.status === "success") {
          this.setState({ checkoutSuccess: true });
          const status = PARTIAL_REGISTRATION_STATIC_DATA.STATUS_COMPLETED;
          this.updatePartialRegistrationToSalesLead(this.state.data, status);
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          errorMessage: error.response.data.message,
        });
      });
  };

  handleChangeStep = (step) => {
    this.setState({ step: parseInt(step) });
    this.formInputViewRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  savePartialRegistrationToSalesLead = (data, status) => {
    const isBasic =
      this.state.chosenPricingPlan.plan &&
      this.state.chosenPricingPlan.plan.toLowerCase() === "basic";
    const dataToSend = {
      type: PARTIAL_REGISTRATION_STATIC_DATA.TYPE,
      status: status,
      notes: `${PARTIAL_REGISTRATION_STATIC_DATA.NOTES} | ${
        this.state.chosenPricingPlan.plan
      } ${!isBasic ? `| ${this.state.paymentInterval}` : ""}`,
      referredBy: PARTIAL_REGISTRATION_STATIC_DATA.REFERRED_BY,
      tenantCode: data.tenantCode,
      contactFirstName: data.contactFirstName,
      contactLastName: data.contactLastName,
      businessName: data.providerName, // businessName ~ providerName
      phone: data.phone,
      email: data.email,
      addressLine1: data.addressLine1,
      addressLine2: data.addressLine2,
      city: data.city,
      state: data.state,
      zip: data.zip,
      lat: data.lat,
      lng: data.lng,
    };
    salesLeadService
      .savePartialEnrollmentToSalesLead(dataToSend)
      .then((response) => {
        if (response.status === "success") {
          this.setState((prevState) => ({
            data: {
              ...prevState.data,
              id: response.data.id,
            },
          }));
        }
      })
      .catch((error) => {
        console.log(
          "Error while saving partial enrollment to sales lead",
          error
        );
      });
  };

  updatePartialRegistrationToSalesLead = (data, status) => {
    const isBasic =
      this.state.chosenPricingPlan.plan &&
      this.state.chosenPricingPlan.plan.toLowerCase() === "basic";
    const dataToSend = {
      id: this.state.data.id,
      type: PARTIAL_REGISTRATION_STATIC_DATA.TYPE,
      status: status,
      notes: `${PARTIAL_REGISTRATION_STATIC_DATA.NOTES} | ${
        this.state.chosenPricingPlan.plan
      } ${!isBasic ? `| ${this.state.paymentInterval}` : ""}`,
      referredBy: PARTIAL_REGISTRATION_STATIC_DATA.REFERRED_BY,
      tenantCode: data.tenantCode,
      contactFirstName: data.contactFirstName,
      contactLastName: data.contactLastName,
      businessName: data.providerName, // businessName ~ providerName
      phone: data.phone,
      email: data.email,
      addressLine1: data.addressLine1,
      addressLine2: data.addressLine2,
      city: data.city,
      state: data.state,
      zip: data.zip,
      lat: data.lat,
      lng: data.lng,
    };
    salesLeadService
      .updatePartialEnrollmentToSalesLead(dataToSend)
      .catch((error) => {
        console.log(
          "Error while updating partial enrollment to sales lead",
          error
        );
      });
  };

  render() {
    if (this.state.loading) return <Loader />;

    return (
      <>
        <ReactTitle
          title={
            this.state.seo?.metadata?.page_title ||
            commonConstants.SEO.DEFAULT_PAGE_TITLE
          }
        />

        <Header />
        {!this.state.checkoutSuccess ? (
          <Checkout
            data={this.state.data}
            address={this.state.address}
            paymentInterval={this.state.paymentInterval}
            handleChangePaymentInterval={this.handleChangePaymentInterval}
            cardErrorMessage={this.state.cardErrorMessage}
            errorMessage={this.state.errorMessage}
            handleRemoveErrorMessage={this.handleRemoveErrorMessage}
            isSubmitting={this.state.isSubmitting}
            paymentEnvironment={this.state.paymentEnvironment}
            providerInfoFormRef={this.providerInfoFormRef}
            handleSubmitCheckout={this.handleSubmitCheckout}
            handleChangeIAgreeTerms={this.handleChangeIAgreeTerms}
            tmpPaymentData={this.state.tmpPaymentData}
            handleChangeTmpPaymentData={this.handleChangeTmpPaymentData}
            chosenPricingPlan={this.state.chosenPricingPlan}
            paymentMethod={this.state.paymentMethod}
            handleChangePaymentMethod={this.handleChangePaymentMethod}
            promoCode={this.state.promoCode}
            handleChangePromoCode={this.handleChangePromoCode}
            handleCheckPromoCode={this.handleCheckPromoCode}
            handleProviderInfoSubmit={this.handleProviderInfoSubmit}
            step={this.state.step}
            handleChangeStep={this.handleChangeStep}
            isProviderInfoSubmitting={this.state.isProviderInfoSubmitting}
            formInputViewRef={this.formInputViewRef}
            bannerContent={this.state.bannerContent}
          />
        ) : (
          <PaymentConfirmation
            isBasic={
              this.state.chosenPricingPlan.plan &&
              this.state.chosenPricingPlan.plan.toLowerCase() === "basic"
            }
          />
        )}

        <div className="inner-divider"></div>
        <Footer />
      </>
    );
  }
}

export default DpcRegister;
