import React, { useContext } from 'react';
import {
  faArrowLeft,
  faArrowRight,
  faClipboardCheck,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import usePrevNextControl from './usePrevNextControl';
import { ButtonLoader } from '../../common';
import { useSelector } from 'react-redux';
import { selectActiveQuestion } from '../../stores/questionaire';
import { getTextFromLangDict, parseHtml } from '../../util';
import { store } from '../../store';

const SubmitConfirmation = ({ handleQuestionnaireSubmitSuccess }) => {
  const { isNextButtonEnabled, onPrevClick, onNextClick, isSubmitting } =
    usePrevNextControl({ handleQuestionnaireSubmitSuccess });

  const state = useContext(store);
  const { globalState } = state;
  const { langDict, groupCode, tenantCode } = globalState;

  const activeQuestion = useSelector(selectActiveQuestion);

  return (
    <div className="py-10">
      <div>
        <FontAwesomeIcon
          className="block text-dedicatedDpcPrimary text-4xl text-center mx-auto mb-6"
          icon={faClipboardCheck}
        />

        <div
          className="text-titleColor font-medium text-xl text-center mb-4"
          dangerouslySetInnerHTML={{
            __html: parseHtml(activeQuestion.title),
          }}
        />

        {activeQuestion.description && (
          <div
            className="body-text text-center"
            dangerouslySetInnerHTML={{
              __html: parseHtml(activeQuestion.description),
            }}
          />
        )}

        <button
          className={`mid-button bg-dedicatedDpcSecondary hover:bg-dedicatedDpcHovered flex justify-center items-center mx-auto mt-10 mb-4 ${
            !isNextButtonEnabled() && 'btn-disabled'
          }`}
          type="button"
          onClick={onNextClick}
        >
          {isSubmitting ? (
            <div className="inline mr-2">
              <ButtonLoader />
            </div>
          ) : null}
          {getTextFromLangDict(langDict, {
            key: '_BBHB_ELIGIBILITY_QUESTIONNAIRE_SUBMIT_BUTTON_TEXT',
            groupCode,
            tenantCode,
          })}
          <FontAwesomeIcon className="ml-2" icon={faArrowRight} />
        </button>

        <button
          className={`mid-button px-4 py-3 bg-transparent text-titleColor hover:bg-gray-300 mx-auto ${
            isSubmitting && 'btn-disabled'
          }`}
          type="button"
          onClick={onPrevClick}
        >
          <FontAwesomeIcon
            className="text-titleColor mr-2"
            icon={faArrowLeft}
          />
          back
        </button>
      </div>
    </div>
  );
};

export default SubmitConfirmation;
