import React, { useContext } from 'react';
import { store } from '../../../../store';
import { getTextFromLangDict } from '../../../../util';
import * as vitafyUtils from '../../../../util/vitafyUtil';

const PaymentSummary = ({ isMember, totalPrice, selectedServices }) => {
  // Context Store
  const state = useContext(store);
  const { globalState } = state;
  const { groupCode, langDict, tenantCode } = globalState;

  return (
    <>
      <h5 className="dedicated-form-title mb-6">
        {getTextFromLangDict(langDict, {
          key: '_YOU_ARE_PAYING_FOR_THE_FOLLOWING_SERVICES',
          groupCode,
          tenantCode,
        })}
      </h5>
      {selectedServices &&
        selectedServices.map((service, index) => (
          <div className="flex justify-between items-center mb-3" key={index}>
            <p className="body-text text-titleColor font-bold">
              {service.name}
            </p>
            <p className="body-text text-titleColor font-bold">
              {vitafyUtils.formatCurrency(
                isMember ? service.memberPrice : service.price,
                null,
                2
              )}
            </p>
          </div>
        ))}
      <hr className="w-full h-1.5px bg-bgColor my-4" />
      <p className="body-text text-titleColor font-bold text-lg flex justify-end">
        {getTextFromLangDict(langDict, {
          key: '_TOTAL',
          groupCode,
          tenantCode,
        })}
        &nbsp;&nbsp;&nbsp;{vitafyUtils.formatCurrency(totalPrice, null, 2)}
      </p>
    </>
  );
};

export default PaymentSummary;
