import React, { useContext, useEffect, useState, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import withSizes from 'react-sizes';
import {
  faBirthdayCake,
  faEnvelope,
  faIdCard,
  faUser,
} from '@fortawesome/free-solid-svg-icons';

import {
  FormTextField,
  FormAddressInput,
  FormMaskedDateInput,
  FormMaskedLast4SSNInput,
  FormGenderInput,
  FormCountryPhoneInput,
  FormSelectBloodGroup,
  Checkbox,
} from '../../../../common';
import { store } from '../../../../store';
import { getTextFromLangDict } from '../../../../util';
import { getSession } from '../../../../util/storageUtil';
import { _isEqual } from '../../../../util/lodashUtil';
import commonConstants from '../../../../constants/commonConstant';
import { unmaskPhone } from '../../../../util/vitafyUtil';

const { MEMBER_TYPE } = commonConstants;

const DemographicInputs = ({ isMobile, showBloodGroupInput }) => {
  // Context Store
  const contextStore = useContext(store);
  const { globalState } = contextStore;
  const { groupCode, tenantCode, langDict } = globalState;

  const { watch, setValue } = useFormContext();

  const watchedPrimaryMember = watch('primaryMember');
  const watchedMemberType = watch('memberType');
  const isDependent = watchedMemberType === MEMBER_TYPE.DEPENDENT;

  const [sameAsPrimaryContact, setSameAsPrimaryContact] = useState(false);

  // Pick the primary member from the list stored in the session storage
  const primaryMember = useMemo(() => {
    if (!watchedPrimaryMember?.clientId) {
      return null;
    }
    try {
      const groupMembers = JSON.parse(getSession('groupMembers'));
      return groupMembers?.find(
        (item) => item.clientId === watchedPrimaryMember.clientId
      );
    } catch {
      console.log('Error parsing values from the session storage');
      return null;
    }
  }, [watchedPrimaryMember]);

  const {
    addressLine1,
    addressLine2,
    city,
    state,
    zip,
    lat,
    lng,
    email,
    phone,
    phoneExt,
    countryCode,
    textEnabled,
  } = watch();

  // Side effect to set the boolean value for setIsPrimary checkbox
  useEffect(() => {
    if (!primaryMember) {
      setSameAsPrimaryContact(false);
      return;
    }

    const dependentContact = {
      addressLine1,
      addressLine2,
      city,
      state,
      zip,
      lat,
      lng,
      email,
      phone: phone ? unmaskPhone(phone) : '',
      phoneExt,
      countryCode,
      textEnabled,
    };
    const primaryContact = {
      addressLine1: primaryMember.address?.addressLine1,
      addressLine2: primaryMember.address?.addressLine2,
      city: primaryMember.address?.city,
      state: primaryMember.address?.state,
      zip: primaryMember.address?.zip,
      lat: primaryMember.address?.lat,
      lng: primaryMember.address?.lng,
      email: primaryMember.email,
      phone: primaryMember.phone,
      phoneExt: primaryMember?.phoneExt,
      countryCode: primaryMember?.countryCode,
      textEnabled: primaryMember.textEnabled,
    };
    if (_isEqual(dependentContact, primaryContact)) {
      setSameAsPrimaryContact(true);
    } else {
      setSameAsPrimaryContact(false);
    }
  }, [
    addressLine1,
    addressLine2,
    city,
    state,
    zip,
    lat,
    lng,
    email,
    phone,
    primaryMember,
    phoneExt,
    countryCode,
    textEnabled,
  ]);

  const prepopulateContactWithPrimary = (value) => {
    setSameAsPrimaryContact(value);
    if (!value || !primaryMember) return;

    setValue('email', primaryMember.email);
    setValue('phone', primaryMember.phone);
    setValue('addressLine1', primaryMember.address?.addressLine1);
    setValue('addressLine2', primaryMember.address?.addressLine2);
    setValue('city', primaryMember.address?.city);
    setValue('state', primaryMember.address?.state);
    setValue('zip', primaryMember.address?.zip);
    setValue('lat', primaryMember.address?.lat);
    setValue('lng', primaryMember.address?.lng);
    setValue('countryCode', primaryMember?.countryCode);
    setValue('phoneExt', primaryMember?.phoneExt);
    setValue('textEnabled', primaryMember?.textEnabled);
  };

  const showSameAsPrimaryContactCheckbox = !!primaryMember && isDependent;

  return (
    <>
      {/* Personal Information */}
      <h5 className="dedicated-form-title mb-3">
        {getTextFromLangDict(langDict, {
          key: isDependent
            ? '_ENTER_DEPENDENT_PERSONAL_INFORMATION'
            : '_ENTER_PERSONAL_INFORMATION',
          groupCode,
          tenantCode,
        })}
      </h5>
      <div className="flex flex-wrap -mx-4">
        <div className="w-full md:w-1/3 px-4">
          <FormTextField
            name="firstName"
            label="First Name *"
            icon={isMobile ? '' : faUser}
          />
        </div>
        <div className="w-full md:w-1/3 px-4">
          <FormTextField name="middleName" label="Middle Name" />
        </div>
        <div className="w-full md:w-1/3 px-4">
          <FormTextField name="lastName" label="Last Name *" />
        </div>
      </div>
      <div className="flex flex-wrap -mx-4">
        <div className="w-full md:w-1/3 px-4">
          <FormMaskedDateInput
            name="dob"
            label="Date of Birth"
            icon={isMobile ? '' : faBirthdayCake}
          />
        </div>
        <div className="w-full md:w-1/3 px-4">
          <FormMaskedLast4SSNInput
            name="lastSSN"
            label="Last Four SSN"
            icon={isMobile ? '' : faIdCard}
          />
        </div>
        <FormGenderInput name="gender" whiteBg />
      </div>
      {showBloodGroupInput && (
        <div className="flex flex-wrap -mx-4">
          <div className="w-full md:w-1/3 px-4">
            <FormSelectBloodGroup />
          </div>
        </div>
      )}

      <div className="form-section-divider-md"></div>

      {/* Contact Information */}
      <div className="flex flex-wrap justify-between items-center mb-5">
        <h5
          className={`dedicated-form-title ${
            showSameAsPrimaryContactCheckbox ? 'mb-3' : 'mb-4'
          }  md:mb-0`}
        >
          {getTextFromLangDict(langDict, {
            key: isDependent
              ? '_ENTER_DEPENDENT_CONTACT_INFORMATION'
              : '_ENTER_CONTACT_INFORMATION',
            groupCode,
            tenantCode,
          })}
        </h5>
        {showSameAsPrimaryContactCheckbox && (
          <Checkbox
            checked={sameAsPrimaryContact}
            setChecked={(e) => prepopulateContactWithPrimary(e)}
            className="inline"
          >
            <p className="font-heebo text-sm  text-titleColor mb-0 select-none inline-block">
              {getTextFromLangDict(langDict, {
                key: '_SAME_AS_PRIMARY_CONTACT',
                groupCode,
                tenantCode,
              })}
            </p>
          </Checkbox>
        )}
      </div>
      <div className="flex flex-wrap -mx-4 mt-2">
        <div className="w-full md:w-1/2 px-4">
          <FormTextField
            name="email"
            label="Email *"
            icon={isMobile ? '' : faEnvelope}
          />
        </div>

        <div className="flex w-full md:w-1/2 px-4">
          <FormCountryPhoneInput label="Phone *" />
        </div>
      </div>
      <FormAddressInput />
    </>
  );
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 768,
});

export default withSizes(mapSizesToProps)(DemographicInputs);
