import React, { useContext } from 'react';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import commonConstants from '../../../../constants/commonConstant';
import { store } from '../../../../store';
import { getMiscTenantSetting } from '../../../../util/tenantSettingUtil';

const { DEPENDENT_TYPES, TENANT_SETTINGS_CODE } = commonConstants;

const RelationshipRadioGroup = () => {
  const {
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  // Context Store
  const state = useContext(store);
  const { globalState } = state;
  const { tenantSettings } = globalState;

  const dependentRelationshipFromSettings = getMiscTenantSetting(
    TENANT_SETTINGS_CODE.DEPENDENT_RELATIONSHIP,
    { tenantSettings }
  );

  const relationship = watch('relationship');

  const onChange = (newValue) => {
    setValue('relationship', newValue);
    clearErrors('relationship');
  };

  const availableDependentTypes = useMemo(() => {
    return DEPENDENT_TYPES.filter((item) =>
      dependentRelationshipFromSettings?.some(
        (r) => r.toLowerCase() === item.value.toLowerCase()
      )
    );
  }, [dependentRelationshipFromSettings]);

  return (
    <>
      {availableDependentTypes?.map((item, index) => (
        <button
          className={`mid-radio-btn px-3 md:px-10 ${
            relationship === item.value && 'font-bold bg-holyCityMedSecondary'
          } ${index !== 0 && 'ml-4'}`}
          onClick={() => onChange(item.value)}
          type="button"
          key={index}
        >
          {item.text}
        </button>
      ))}
      {!!errors.relationship && (
        <p className="text-red-600 text-sm mt-3">
          Please select the relationship.
        </p>
      )}
    </>
  );
};

export default RelationshipRadioGroup;
