import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import withSizes from 'react-sizes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDollarSign
} from '@fortawesome/free-solid-svg-icons';
import { parseHtml } from '../../util';
import { formatCurrency } from '../../util/vitafyUtil';
import SavingsChartPlaceholder from '../../assets/images/savings-chart.png';
import AppStoreImg from '../../assets/images/appstore-badge.png';
import PlayStoreImg from '../../assets/images/playstore-badge.png';

const Savings = (props) => {
  const { sliderContent, mobileAppLinkContent, openDisclaimerModal } = props;
  const [activeSlide, setActiveSlide] = useState({
    slide: null,
    vitafyCost: null,
    stateAvg: null,
    nationalAvg: null,
    chart: null
  })

  const adjustSavingContent = (oldIndex, newIndex) => {
    let activeSliderContent = sliderContent && sliderContent.find((item, index) => index === newIndex);
    if (!activeSliderContent) activeSliderContent = sliderContent[sliderContent.length - 1];
    setActiveSlide({
      slide: newIndex,
      vitafyCost: activeSliderContent?.cost?.vitafy_cost,
      stateAvg: activeSliderContent?.cost?.state_avg,
      nationalAvg: activeSliderContent?.cost?.national_avg,
      chart: activeSliderContent?.chart?.url
    });
  }

  const settings = {
    className: 'procedureSlider',
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 10000,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => <ul style={{ marginBottom: props.isMobile ? '5px' : '-30px' }}> {dots} </ul>,
    beforeChange: adjustSavingContent
  };

  useEffect(() => {
    if (sliderContent?.length > 0) {
      const firstSlideCost = sliderContent[0].cost;
      const firstSlideChart = sliderContent[0].chart;
      setActiveSlide({
        slide: 0,
        vitafyCost: firstSlideCost?.vitafy_cost,
        stateAvg: firstSlideCost?.state_avg,
        nationalAvg: firstSlideCost?.national_avg,
        chart: firstSlideChart?.url
      });
    }
  }, [sliderContent]);

  console.log('activeSlide', activeSlide);

  return (
    <>
      <div className='container mx-auto px-4' style={{ marginBottom: '-18rem' }}>
        <div className='flex flex-wrap md:flex-row-reverse shadow-allRound'>
          <div className='w-full md:w-1/2 bg-white pt-6 md:pt-8 pb-5'>
            <div className='flex justify-between items-center px-5 xl:pl-16 md:pr-5 xl:pr-24'>
              <div className='flex items-center w-1/2'>
                <FontAwesomeIcon icon={faDollarSign} className='block text-4xl text-secondary mr-4' />
                <div>
                  <p className='text-bodyColor text-sm'>Vitafy Cost</p>
                  <p className='text-titleColor text-2xl font-bold'>{formatCurrency(activeSlide.vitafyCost, true, 0)}</p>
                </div>
              </div>

              <div className='w-1/2 xl:pl-12'>
                <div className='flex justify-between items-center w-full'>
                  <p className='text-sm text-bodyColor'>State Avg</p>
                  <p className='text-sm text-bodyColor font-bold'>{formatCurrency(activeSlide.stateAvg, true, 0)}</p>
                </div>
                <div className='flex  justify-between items-center w-full'>
                  <p className='text-sm text-bodyColor'>National Avg</p>
                  <p className='text-sm text-bodyColor font-bold'>{formatCurrency(activeSlide.nationalAvg, true, 0)}</p>
                </div>
              </div>
            </div>
            <div className='savings-chart-wrapper-height'>
              <img
                src={activeSlide.chart}
                alt='saving-chart'
                className='block w-full mx-auto mt-10 mb-5 px-5 md:pl-16 md:pr-24'
              />
            </div>
          </div>

          <div className='w-full md:w-1/2 md:pr-5 xl:pr-12  bg-white'>
            <div className='h-full bg-extended px-6 md:px-16 xl:px-20 pt-10 pb-10 md:pb-0 md:pt-16 lg:pt-20 xl:pt-32'>
              <Slider {...settings}>
                {sliderContent && sliderContent.map((item, index) =>
                  <div className='focus:outline-none' key={index}>
                    <img
                      className='h-12 md:h-20 block mx-auto'
                      src={item.image.url}
                      alt='procedure-slider'
                    />
                    <h5 className='font-heebo text-lg md:text-xl lg:text-2xl text-white font-semibold my-5 text-center'>{item.title}</h5>
                    <div
                      className={`font-heebo text-sm text-white hidden lg:block`}
                      dangerouslySetInnerHTML={{
                        __html: parseHtml(item.content),
                      }}
                    />
                  </div>
                )}
              </Slider>
            </div>
          </div>
        </div>
        <p className='body-text text-sm leading-relaxed mt-6'>
          **Savings is based on state average
          <span className='inline text-sm text-secondary cursor-pointer' onClick={() => openDisclaimerModal()}> (See full disclaimer)</span>
        </p>
      </div>

      <div className='bg-bgColor w-full pt-64 z-n1'>
        <div className='divider'></div>
        <div className='container mx-auto px-4'>
          <h3 className='heading-center block mx-auto text-3xl mb-8 text-center'>{mobileAppLinkContent.title}</h3>
          <p className='body-text text-base md:text-md lg:text-xl leading-relaxed text-center  lg:px-24'>
            {mobileAppLinkContent.content}
          </p>
          <div className='flex justify-center -mx-3 mt-6'>
            <div className='px-3'>
              <a href="https://apps.apple.com/us/app/vitafy-health/id1515063567" target='_blank'>
                <img
                  src={AppStoreImg}
                  className='h-12'
                  alt="appstore-badge" />
              </a>
            </div>
            <div className='px-3'>
              <a href="https://play.google.com/store/apps/details?id=com.noveltytechnology.vitafyhealth&hl=en&gl=US" target='_blank'>
                <img
                  src={PlayStoreImg}
                  className='h-12'
                  alt="playstore-badge" />
              </a>
            </div>
          </div>
        </div>
        <div className='divider'></div>
      </div>
    </>
  );
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 760,
})


export default withSizes(mapSizesToProps)(Savings);