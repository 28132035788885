import React, { Component } from 'react';
import { Loader } from '..';

import * as marketplaceService from '../../service/marketplaceService';
import HeatMapPlaceholder from '../../assets/images/heatmap-placeholder.png';

const google = window.google;

const HeatmapPlaceholderStyles = {
  height: '85vh',
  backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.65), rgba(255, 255, 255, 0.65)), url(${HeatMapPlaceholder})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover'
}

class HeatMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      providerLocations: [],
      categories: [],
      isLoading: true,
      isCategoryLoading: true
    }
  }

  componentDidMount() {
    this.fetchProviderLocations();
  }

  getPoints = () => {
    const { providerLocations } = this.state;
    if (!providerLocations || providerLocations.length < 1) return [];
    let heatMapData = [];
    providerLocations.map(item => {
      heatMapData.push({ location: new google.maps.LatLng(item.latitude, item.longitude), weight: item.count });
      // heatMapData.push(new google.maps.LatLng(item.latitude, item.longitude));
    })
    return heatMapData;
  }

  changeGradient = () => {
    return [
      "rgba(255, 255, 255, 0)",
      "rgba(234, 110, 44,0.6)",
      "rgba(234, 110, 44,0.8)",
      "rgba(234, 110, 44,0.9)",
      "rgba(234, 110, 44,1)",
      "rgba(234, 110, 44,1)",
      "rgba(234, 110, 44,1)",
      "rgba(234, 110, 44,1)",
      "rgba(234, 110, 44,1)",
      "rgba(234, 110, 44,1)",
      "rgba(234, 110, 44,1)",
      "rgba(234, 110, 44,1)",
      "rgba(234, 110, 44,1)",
      "rgba(234, 110, 44,1)",
      "rgba(234, 110, 44,1)"
    ];
  }

  initMap = () => {
    const map = new google.maps.Map(document.getElementById('map'), {
      zoom: 5,
      center: { lat: 39.833333, lng: -98.583333 },
      // mapTypeId: google.maps.MapTypeId.SATELLITE, 4
      styles: [
        { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
        { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
        { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
        {
          featureType: "administrative.locality",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563" }]
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563" }]
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [{ color: "#263c3f" }]
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [{ color: "#6b9a76" }]
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [{ color: "#38414e" }]
        },
        {
          featureType: "road",
          elementType: "geometry.stroke",
          stylers: [{ color: "#212a37" }]
        },
        {
          featureType: "road",
          elementType: "labels.text.fill",
          stylers: [{ color: "#9ca5b3" }]
        },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [{ color: "#746855" }]
        },
        {
          featureType: "road.highway",
          elementType: "geometry.stroke",
          stylers: [{ color: "#1f2835" }]
        },
        {
          featureType: "road.highway",
          elementType: "labels.text.fill",
          stylers: [{ color: "#f3d19c" }]
        },
        {
          featureType: "road.highway",
          elementType: "labels",
          stylers: [
            {
              visibility: "off"
            }
          ]
        },
        {
          featureType: "road",
          elementType: "labels",
          stylers: [
            {
              visibility: "off"
            }
          ]
        },
        {
          featureType: "transit",
          elementType: "geometry",
          stylers: [{ color: "#2f3948" }]
        },
        {
          featureType: "transit.station",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563" }]
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [{ color: "#17263c" }]
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [{ color: "#515c6d" }]
        },
        {
          featureType: "water",
          elementType: "labels.text.stroke",
          stylers: [{ color: "#17263c" }]
        }
      ]
    });

    const heatmap = new google.maps.visualization.HeatmapLayer({
      data: this.getPoints(),
      map: map,
      gradient: this.changeGradient(),
      radius: 20,
      opacity: 1
    });
  }

  fetchProviderLocations = async (categories = null) => {
    try {
      const queryParams = {
        map: 'heatmap',
        categories: categories  // in string (like Lab,Procedure)
      }
      const response = await marketplaceService.getCostLocations(queryParams);
      this.setState({ providerLocations: response.data, isLoading: false }, () => {
        this.initMap();
      });
    } catch (err) {
      console.log('Error fetching provider locations', err);
      this.setState({ isLoading: false });
    }
  }

  render() {
    return (
      <>
        {this.state.isLoading &&
          <div
            className='w-full bg-gray-200 flex justify-center items-center'
            style={HeatmapPlaceholderStyles}>
            <Loader hScreen={false} />
          </div>
        }
        <div id='map' style={{ width: '100%', height: this.state.isLoading ? '0vh' : '85vh' }}></div>
      </>
    );
  }
}

export default HeatMap;