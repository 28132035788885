import React from 'react';
import { useSelector } from 'react-redux';
import { QUESTION_TYPE } from '../../constants/questionaire';
import { selectActiveQuestion } from '../../stores/questionaire';
import { parseHtml } from '../../util';
import AgreeDisagreeInput from './AgreeDisagreeInput';
import CheckboxInput from './Checkbox';
import DateInput from './DateInput';
import FileInput from './FileInput';
import RadioInput from './RadioInput';
import TextInput from './TextInput';
import SubmitConfirmation from './SubmitConfirmation';

const Question = ({ handleQuestionnaireSubmitSuccess }) => {
  const activeQuestion = useSelector(selectActiveQuestion);
  const type = activeQuestion?.type;

  const renderedQuestionInfo = (
    <>
      <h3 className="heading text-2xl font-medium mb-8">
        {activeQuestion.title}
      </h3>
      <div className="mb-10">
        <div
          className="body-text text-titleColor text-lg font-medium"
          dangerouslySetInnerHTML={{
            __html: parseHtml(activeQuestion.question),
          }}
        />

        {activeQuestion.description && (
          <div
            className="body-text text-bodyColor text-md font-normal mt-2"
            dangerouslySetInnerHTML={{
              __html: parseHtml(activeQuestion.description),
            }}
          />
        )}
      </div>
    </>
  );

  return (
    <div className="mb-6">
      {type !== QUESTION_TYPE.SUBMIT_CONFIRMATION && renderedQuestionInfo}

      {type === QUESTION_TYPE.RADIO && <RadioInput />}
      {type === QUESTION_TYPE.TEXT && <TextInput />}
      {type === QUESTION_TYPE.DATE && <DateInput />}
      {type === QUESTION_TYPE.CHECKBOX && <CheckboxInput />}
      {type === QUESTION_TYPE.ATTACHMENT && <FileInput />}
      {type === QUESTION_TYPE.AGREE_DISAGREE && <AgreeDisagreeInput />}
      {type === QUESTION_TYPE.SUBMIT_CONFIRMATION && (
        <SubmitConfirmation
          handleQuestionnaireSubmitSuccess={handleQuestionnaireSubmitSuccess}
        />
      )}
    </div>
  );
};

export default Question;
