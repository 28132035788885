import React, { useEffect, useContext } from 'react';

import { store } from '../../store';

import { Loader } from '../../common';
import { Banner } from './common';
import MemberCheckin from './member-check-in';
import RequestAppointment from './request-appointment';
import WalkinVideoVisit from './walk-in-video-visit';
import Membership from './membership';

const DedicatedDpcHelpCenter = (props) => {
  const state = useContext(store);
  const { globalState } = state;
  const {
    tenantCode,
    groupCode,
    tenantId,
    providerDetail,
    tenantSettings,
    isLoading,
    merchantProfile,
    langDict,
  } = globalState;

  useEffect(() => {
    const param = props.match.params?.service?.toLowerCase();
    // if the route param matches neither of the service's param, redirect to Page Not Found
    if (
      param !== 'member-check-in' &&
      param !== 'covid-test' &&
      param !== 'request-appointment' &&
      param !== 'walk-in-video-visit' &&
      param !== 'membership'
    ) {
      props.history.push('/404');
    }
  }, []);

  const activeService = props.match.params?.service?.toLowerCase();

  if (isLoading) return <Loader />;

  return (
    <>
      <Banner activeService={activeService} />
      <div className="mt-10">
        {activeService === 'member-check-in' && (
          <MemberCheckin
            tenantId={tenantId}
            tenantCode={tenantCode}
            groupCode={groupCode}
            langDict={langDict}
            providerDetail={providerDetail}
            isLoading={isLoading}
          />
        )}
        {activeService === 'request-appointment' && (
          <RequestAppointment
            tenantCode={tenantCode}
            providerDetail={providerDetail}
            isLoading={isLoading}
          />
        )}
        {activeService === 'walk-in-video-visit' && (
          <WalkinVideoVisit
            tenantId={tenantId}
            tenantCode={tenantCode}
            groupCode={groupCode}
            langDict={langDict}
            providerDetail={providerDetail}
            tenantSettings={tenantSettings}
            paymentEnvironment={merchantProfile.paymentEnvironment}
            isLoading={isLoading}
          />
        )}
        {activeService === 'membership' && <Membership />}
      </div>
    </>
  );
};

export default DedicatedDpcHelpCenter;
