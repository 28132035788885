import React from 'react';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import Fade from 'react-reveal/Fade';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const Benefit = (props) => {
  const { benefits, showLinks, handleLinkClick } = props;
  const display =
    benefits &&
    benefits.map((item, index) => {
      return (
        <React.Fragment key={index}>
          <Fade>
            <div className={`flex flex-wrap items-center md:-mx-10 ${index !== (benefits.length - 1) ? 'mb-20 lg:mb-24' : 'mb-0 lg:mb-0'}`}>
              <div
                className={`w-full md:w-1/2 md:px-10 ${index % 2 !== 0 ? 'md:block' : 'md:hidden'
                  }`}
              >
                <img
                  src={item.metadata.image.url}
                  className="mb-8 w-full lg:w-auto px-8 lg:h-63 mx-auto md:mb-0 rounded-md"
                  alt="image"
                />
              </div>
              <div className="w-full md:w-1/2 md:px-10">
                <h3
                  className={`heading  ${index % 2 === 0 ? 'secondary-bar' : null
                    }`}
                >
                  {item.metadata && item.metadata.display_title}
                </h3>
                <div className="font-heebo text-bodyColor">
                  {ReactHtmlParser(item.content)}
                </div>
                {showLinks &&
                  <p
                    className='link mt-6 cursor-pointer'
                    onClick={() => handleLinkClick(item.slug)}
                  >
                    <span className='link-hover text-lg'>Learn more </span>{' '}
                    <FontAwesomeIcon icon={faArrowRight} />
                  </p>
                }
              </div>
              <div
                className={`w-full  hidden md:px-10 ${index % 2 === 0 ? 'md:block md:w-1/2' : 'md:hidden'
                  }`}
              >
                <img
                  className="rounded-md w-full lg:w-auto px-8 lg:h-63 mx-auto"
                  src={item.metadata.image.url}
                  alt="image"
                />
              </div>
            </div>
          </Fade>
        </React.Fragment>
      );
    });

  return (
    <>
      <div className="container mx-auto px-4 overflow-hidden">{display}</div>
    </>
  );
};

export default Benefit;
