import React, { useEffect, useState, useContext } from 'react';
import Select from 'react-select';

import * as tenantService from '../../../../service/tenantService';
import { store } from '../../../../store';
import { useFormContext } from 'react-hook-form';

const SelectServices = () => {
  const { watch, setValue } = useFormContext();

  // Context Store
  const state = useContext(store);
  const { globalState } = state;
  const { tenantId } = globalState;

  const [serviceList, setServiceList] = useState([]);

  const servicesWatched = watch('services');

  useEffect(() => {
    const fetchServices = (tenantId) => {
      const params = {
        limit: 999,
        offset: 0,
        sortBy: 'name',
        sortOrder: 'ASC',
      };

      tenantService
        .fetchServices(tenantId, params) //'7EB541E4-91A9-4DEB-BB7E-55813D3CA140'
        .then((resp) => {
          setServiceList(
            resp.data.rows
              .filter((x) => x.name)
              .map((x) => {
                return {
                  label: x.name,
                  value: x.name,
                };
              })
          );
        })
        .catch((err) => {
          console.log('Error fetching tenant services', err);
        });
    };

    if (tenantId) {
      fetchServices(tenantId);
    }
  }, [tenantId]);

  const onChange = (services) => {
    setValue('services', services);
  };

  return (
    <>
      <h5 className="dedicated-form-title mb-3">
        Select one or more services that you are looking for:
      </h5>
      <Select
        value={servicesWatched}
        options={serviceList}
        isMulti
        onChange={onChange}
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      />
    </>
  );
};

export default SelectServices;
